<div class="d-flex flex-column h-100">
  <header [ngClass]="{'mb-4': !data.isMobile}">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">
        {{ "shared.dialogs.report_history.report_history_title" | translate }}
      </h1>

      <button
        (click)="onClose()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    @if (!data.isMobile) {
      <p>
        {{
          "shared.dialogs.report_history.report_history_description" | translate
        }}
      </p>
    }
  </header>

  <mat-dialog-content class="report-history-content">
    @if (data.files && data.files.length > 0) {
      <div
        class="table-container pt-3 my-2"
        [ngClass]="{'custom-mobile-table': data?.isMobile}"
      >
        <table
          mat-table
          [dataSource]="data.files"
          class="table table-borderless mb-0 wsm-table-header-border"
          matSort
          matSortActive="lastModifiedDate"
          matSortDirection="desc"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "shared.dialogs.report_history.file_name_label" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-file-pdf me-4 file-title-icon"></i>
                <span>
                  {{ element.title }}
                </span>
              </div>
            </td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              {{ "shared.dialogs.report_history.download_label" | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-center">
              <button
                (click)="getReportPdf(element.filePath, element.fileName)"
                class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
              >
                <i class="fa-solid fa-arrow-down-to-line"></i>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    }

    @if (!data.files || data.files?.length === 0) {
      <div class="wsm-alert-message mt-3">
        <strong>{{
          "shared.dialogs.report_history.no_pdf_description" | translate
        }}</strong>
      </div>
    }
  </mat-dialog-content>
</div>
