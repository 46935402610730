import {
  Component,
  EventEmitter,
  input,
  InputSignal,
  Output,
} from '@angular/core';
import {IConnectionVideo} from '../../models';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelContent } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { SafeResourcePipe } from '../../pipes/safe-resource.pipe';

@Component({
    selector: 'app-demo-video',
    templateUrl: './demo-video.component.html',
    styleUrl: './demo-video.component.scss',
    standalone: true,
    imports: [
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatExpansionPanelContent,
        TranslateModule,
        SafeResourcePipe,
    ],
})
export class DemoVideoComponent {
  // Inputs / Outputs
  provider: InputSignal<string> = input<string>('');
  @Output() videoExpanded = new EventEmitter<boolean>();

  // Properties
  public videos: IConnectionVideo[] = [
    {
      provider: 'call-rail',
      url: 'https://www.youtube.com/embed/xerLyOKiYeQ?si=eLmNaIxXv2BPUpPP',
    },
  ];

  // State
  public panelOpenState = false;

  public onPanelStateChange(event: boolean): void {
    this.videoExpanded.emit(event);
  }
}
