@for (message of messages; track message) {
  <section class="notification-container mb-2">
    @if (status) {
      <div
        class="left-status-bar"
        [ngClass]="{'warning': status === 'warning', 'info': status === 'info'}"
      ></div>
    }
    {{ message.message | translate }}
  </section>
}
