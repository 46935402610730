import {Component, Input} from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        NgClass,
        TranslateModule,
    ],
})
export class InfoCardComponent {
  // Inputs
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() path: string;
}
