<div class="d-flex">
  @for (connection of connections | slice: 0 : 5; track connection) {
    <img
      [src]="connection?.cardLogo || connection?.logos?.cardLogo"
      class="connection-logo"
      [ngClass]="{
        'not-linked': type === 'page' && !connection?.linkAccount,
        'template-card-logo': type === 'template',
        'widget-logo': type === 'widget',
        'select-input': type === 'select'
      }"
      alt="Connection Logo"
    />
  }

  @if (connections?.length! > 5) {
    <div
      class="show-more"
      [ngClass]="{'widget-show-more': type === 'widget'}"
      [satPopoverAnchor]="popIcons"
      (mouseenter)="popIcons.open()"
      (mouseleave)="popIcons.close()"
    >
      <span>+{{ connections?.length! - 5 }}</span>
    </div>
  }

  <sat-popover
    #popIcons
    horizontalAlign="center"
    verticalAlign="below"
    panelClass="custom-pop-icon"
  >
    <div class="name-wrapper">
      <div class="popover-name-arrow"></div>
      <div>
        @for (connection of connections | slice: 5; track connection) {
          <div class="my-1 d-flex align-items-center justify-content-start">
            <img
              [src]="connection?.cardLogo || connection?.logos?.logo"
              width="14"
              height="14"
              class="me-1"
              alt="Connection Logo"
            />
            <span class="me-1">{{ connection.name }}</span>
          </div>
        }
      </div>
    </div>
  </sat-popover>

  @if (isCustom) {
    <img
      src="/assets/img/svg/custom-calculation.svg"
      class="connection-logo"
      alt="custom calculation logo"
      width="14"
      height="14"
    />
  }
</div>
