<header class="mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h2 m-0 me-4">
      {{ "shared.dialogs.connection_login.login_in_title" | translate }}
      {{ data.service.name }}
    </h1>
    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>

  @if (data?.service?.provider === "call-rail") {
    <app-demo-video
      [provider]="data?.service?.provider!"
      (videoExpanded)="onVideoExpanded($event)"
    />
  }
</header>

<mat-dialog-content class="connection-form-content">
  <form
    id="connection-form"
    [formGroup]="connectionForm"
    (ngSubmit)="onSubmit()"
  >
    @if (!isUpdating) {
      <div class="w-50 mb-3" [ngClass]="{'w-100': isMobile}">
        <label for="user-email">{{
          "shared.dialogs.connection_login.email_label" | translate
        }}</label>
        <input
          id="user-email"
          formControlName="userEmail"
          class="wsm-input validate w-100"
          type="text"
        />
        @if (
          connectionForm.get("userEmail")?.errors?.["required"] &&
          connectionForm.get("userEmail")?.dirty
        ) {
          <div class="mt-1">
            <span class="invalid-info">
              {{ "common.errors.field_required" | translate }}
            </span>
          </div>
        }
      </div>
    }
    <div class="w-100">
      <label for="auth-token">{{
        "shared.dialogs.connection_login.basic_token_label" | translate
      }}</label>
      <textarea
        id="auth-token"
        formControlName="authToken"
        class="wsm-input validate w-100"
        type="text"
        rows="4"
      ></textarea>
      @if (
        connectionForm.get("authToken")?.errors?.["required"] &&
        connectionForm.get("authToken")?.dirty
      ) {
        <div class="mt-1">
          <span class="invalid-info">{{
            "common.errors.field_required" | translate
          }}</span>
        </div>
      }
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="wsm-btn wsm-btn-primary" type="submit" form="connection-form">
    @if (isConnecting) {
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
      </span>
    }
    @if (!isConnecting) {
      <span>{{
        "shared.dialogs.connection_login.connect_button" | translate
      }}</span>
    }
  </button>
</mat-dialog-actions>
