<header class="mb-1">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h2 m-0">
      {{ "shared.dialogs.new_company.new_company_title" | translate }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
  <p>{{ "shared.dialogs.new_company.new_company_description" | translate }}</p>
  <p class="mt-3">
    <small class="d-flex wsm-text-grayed">
      {{ "common.fields_required" | translate }}
    </small>
  </p>
</header>

<mat-dialog-content class="custom-client-container">
  <app-create-client
    [clientFromCache]="data?.clientFromCache"
    (clientCreated)="onCreateClient($event)"
    (clientFormChanged)="clientFormChanged($event)"
  />
</mat-dialog-content>
