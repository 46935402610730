import {updateState, withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed} from '@angular/core';
import {signalStore, type, withComputed, withMethods} from '@ngrx/signals';
import {
  addEntity,
  entityConfig,
  removeEntity,
  updateAllEntities,
  withEntities,
} from '@ngrx/signals/entities';
import {ReportPage} from 'src/app/shared/models/report/report.model';

type ReportPageEntityType = Omit<ReportPage, 'widgets'>;
const ReportPageEntityConfig = entityConfig({
  entity: type<ReportPageEntityType>(),
  collection: '_reportPages',
  selectId: (reportPage) => reportPage.id,
});

export const ReportPageStoreSignal = signalStore(
  {providedIn: 'root'},
  withDevtools('ReportPageStore'),
  withEntities(ReportPageEntityConfig),
  withComputed(({_reportPagesEntities}) => ({
    activePage: computed(() => {
      return _reportPagesEntities().find((page) => page.isActive);
    }),
  })),
  withMethods((store) => ({
    addPage(reportPage: ReportPage): void {
      _addPage(store, reportPage);
    },
    updateActivePage(reportPage: ReportPage): void {
      _updateActivePage(store, reportPage);
    },
    removePage(pageId: string): void {
      _removePage(store, pageId);
    },
  }))
);

function _addPage(store, reportPage: ReportPage): void {
  const newPage = _getPageWithoutWidgets(reportPage);
  updateState(
    store,
    '[ReportPage] Add Page',
    addEntity(newPage, ReportPageEntityConfig)
  );
}

function _updateActivePage(store, reportPage: ReportPage): void {
  const newPage = _getPageWithoutWidgets(reportPage);
  updateState(
    store,
    '[ReportPage] Update Active Page',
    updateAllEntities(
      (page) => ({
        isActive: page.id === newPage.id,
        ...newPage,
      }),
      ReportPageEntityConfig
    )
  );
}

function _removePage(store, pageId: string): void {
  updateState(
    store,
    '[ReportPage] Remove Page',
    removeEntity(pageId, ReportPageEntityConfig)
  );
}

function _getPageWithoutWidgets(reportPage: ReportPage): ReportPageEntityType {
  const {widgets, ...newPage} = reportPage;
  return newPage;
}
