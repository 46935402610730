import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  input,
  output,
  signal,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService, ReportService} from 'src/app/core/services';
import {CreateReportFromComponent} from '../create-report-from/create-report-from.component';
import {TemplateDialogPreviewComponent} from 'src/app/shared/dialogs/template-dialog-preview/template-dialog-preview.component';
import {FeaturedTemplatePayload, ReportTemplate} from 'src/app/shared/models';
import { NgClass } from '@angular/common';
import { TemplateGalleryCardComponent } from '../../../../shared/components/template-gallery-card/template-gallery-card.component';

@Component({
    selector: 'app-template-carousel',
    templateUrl: './template-gallery-carousel.component.html',
    styleUrls: ['./template-gallery-carousel.component.scss'],
    standalone: true,
    imports: [NgClass, TemplateGalleryCardComponent],
})
export class TemplateGalleryCarouselComponent implements OnInit, AfterViewInit {
  @ViewChild('tList', {static: false}) tList: ElementRef<any>;

  templateCards = input.required<ReportTemplate[]>();
  from = input<'carousel'>();
  refreshCarousel = output<boolean>();

  public showControls = signal<boolean>(false);
  public rightControl = signal<boolean | null>(null);
  public leftControl = signal<boolean>(true);

  public favoriteMessage = signal<string>('');
  public unfavoriteMessage = signal<string>('');

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onShowControls();
    }, 0);
  }

  onNext(): void {
    const eList = this.tList.nativeElement;
    const widthList = Math.floor(eList.clientWidth);
    const scrollWidth = Math.floor(eList.scrollWidth);

    this.tList.nativeElement.scrollBy({
      top: 0,
      left: +300,
      behavior: 'smooth',
    });
    this.onHandleControls('right');
  }

  onPrev(): void {
    const eList = this.tList.nativeElement;
    const widthList = Math.floor(eList.clientWidth);
    const scrollWidth = Math.floor(eList.scrollWidth);
    this.onHandleControls('left');
    this.tList.nativeElement.scrollBy({
      top: 0,
      left: -300,
      behavior: 'smooth',
    });
  }

  onShowControls(): void {
    const element = this.tList.nativeElement as HTMLElement;
    if (element.scrollWidth === element.clientWidth) {
      return;
    }
    this.showControls.set(true);
  }

  onHandleControls(control?: string): void {
    const eList = this.tList.nativeElement;
    const widthList = Math.floor(eList.clientWidth);
    const scrollWidth = Math.floor(eList.scrollWidth);
    let listScroll = Math.floor(eList.scrollLeft);
    const maxScrollLeft = scrollWidth - widthList;
    if (control === 'right') {
      listScroll += 300;
    }
    if (control === 'left') {
      listScroll -= 300;
    }
    this.leftControl.set(listScroll < 0);
    this.rightControl.set(scrollWidth - widthList === listScroll - 300);
  }

  onTemplateFavorite(event): void {
    const payload = {
      favorite: !event.favorite,
    } as FeaturedTemplatePayload;

    this.reportService.setFeaturedTemplate(event.id, payload).subscribe({
      next: (res) => {
        this.notificationService.success(
          !event.favorite ? this.favoriteMessage() : this.unfavoriteMessage(),
          3000
        );
        this.updateFavoriteList(event.id);
      },
      error: (err) => {
        this.notificationService.error(err.message!, 3000);
      },
    });
  }

  updateFavoriteList(templateId: string): void {
    // this.templateCards = this.templateCards.filter( t => t.id !== templateId);
    this.refreshCarousel.emit(true);
  }

  onTemplateSelected(event): void {
    const dialogRef = this.dialog.open(CreateReportFromComponent, {
      data: {
        template: event,
        from: 'template',
      },
      autoFocus: false,
      maxWidth: '100vw',
      width: '45vw',
      height: '82vh',
      disableClose: true,
      restoreFocus: false,
      position: {
        top: '4rem',
      },
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((res) => {
      dialogSub.unsubscribe();
    });
  }

  onTemplatePreview(event): void {
    const dialogRef = this.dialog.open(TemplateDialogPreviewComponent, {
      data: {...event},
      autoFocus: false,
      maxWidth: '100vw',
      width: '55vw',
      height: '70vh',
      disableClose: true,
      restoreFocus: false,
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((res) => {
      dialogSub.unsubscribe();
    });
  }

  getTranslations(): void {
    this.favoriteMessage.set(
      this.translate.instant('templates.notification.favorite')
    );
    this.unfavoriteMessage.set(
      this.translate.instant('templates.notification.unfavorite')
    );
  }
}
