<section class="container-fluid">
  <div class="row overlay-container content-center">
    <ng-lottie height="300px" [options]="options"> </ng-lottie>
    <h1 class="mb-2 text-nowrap text-center">Sorry... Please Try Again</h1>
    <div class="loading-message text-center">
      <p class="mb-2">
        It looks like Clicky couldn't find the page you requested.
      </p>
      <p>Please return to the homepage or contact support.</p>
    </div>
  </div>
</section>
