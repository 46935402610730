import {Component, Input, OnInit, output} from '@angular/core';

import {PageTemplate, ReportTemplate, User, WidgetTemplate} from '../../models';
import {checkCharacterLimit} from '../../helpers';
import {NgClass, SlicePipe} from '@angular/common';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {MatTooltip} from '@angular/material/tooltip';
import {ConnectionLogosComponent} from '../connection-logos/connection-logos.component';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {TranslateModule} from '@ngx-translate/core';
import {TruncatePipe} from '../../pipes/truncate-string.pipe';

@Component({
  selector: 'app-template-gallery-card',
  templateUrl: './template-gallery-card.component.html',
  styleUrls: ['./template-gallery-card.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatTooltip,
    ConnectionLogosComponent,
    SatPopoverModule,
    SlicePipe,
    TranslateModule,
    TruncatePipe,
  ],
})
export class TemplateGalleryCardComponent implements OnInit {
  // Inputs / Outputs
  @Input() templateCard: ReportTemplate | PageTemplate | WidgetTemplate;
  @Input() type: string;
  @Input() currentUser: User;
  @Input() from = '';
  @Input() isSelected?: boolean;
  @Input() isSpinning = false;
  @Input() isFromStarted = false;
  templateSelected = output<ReportTemplate | PageTemplate | WidgetTemplate>();
  templateDeleted = output<string>();
  templatePreview = output<ReportTemplate | PageTemplate | WidgetTemplate>();
  templateFavorite = output<ReportTemplate | PageTemplate | WidgetTemplate>();
  templateEdit = output<ReportTemplate | PageTemplate | WidgetTemplate>();

  // Properties
  public goalTypeSlice = 2;

  // State
  public canDelete: boolean;
  public canEditIsGlobal: boolean;
  public isInvisible = true;
  public lockGlobal: boolean;
  public areShortLabels: boolean;

  constructor() {}

  ngOnInit(): void {
    setTimeout((): void => {
      this.isInvisible = false;
    }, 500);

    if (this.currentUser) {
      this.canDelete = !!(
        this.currentUser.email === this.templateCard.createdBy?.email ||
        this.currentUser.isAdmin
      );
    }

    this.onHandleCanEdit();

    if (this.templateCard.goalType) {
      this.areShortLabels = checkCharacterLimit(this.templateCard.goalType, 22);
      this.goalTypeSlice = checkCharacterLimit(
        this.templateCard.goalType.slice(0, 2),
        26
      )
        ? 1
        : 2;
    }
  }

  onHandleCanEdit(): void {
    const reportTemplateCard = this.templateCard as ReportTemplate;
    this.lockGlobal = reportTemplateCard?.isGlobal || false;
    if (reportTemplateCard.isGlobal) {
      this.canEditIsGlobal = !!this.currentUser?.isSuperAdmin;
    }
  }

  onSelectCardTemplate(): void {
    (this.from === 'wizard' || this.isFromStarted) &&
      this.templateSelected.emit(this.templateCard);
  }

  onSelectTemplate(): void {
    this.templateSelected.emit(this.templateCard);
  }

  onDeleteTemplate(): void {
    this.templateDeleted.emit(this.templateCard._id as string);
  }

  onPreviewTemplate(): void {
    this.templatePreview.emit(this.templateCard);
  }

  onFavoriteTemplate(): void {
    if (this.isFromStarted) {
      return;
    }
    this.templateCard.updatingFavorite = true;
    this.templateFavorite.emit(this.templateCard);
  }

  onTemplateEdit(): void {
    this.templateEdit.emit(this.templateCard);
  }
}
