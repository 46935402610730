import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {formatDate, getCurrencySymbol} from '@angular/common';
import {
  ColorService,
  HelperService,
  isValidDate,
  setWidgetFontSize,
} from 'src/app/shared/helpers';
import {ScopeField, Widget} from '../../../../models';
import {Subscription} from 'rxjs';
import {ReportService} from '../../../../../core/services';
import {FusionChartsModule} from 'angular-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import '../../../../../../assets/libs/fusioncharts/custom-themes/wsm_theme.js';
@Component({
  standalone: true,
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  imports: [FusionChartsModule],
})
export class ChartComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: FormGroup;

  // State
  private initialized = false;
  rendered = true;

  // Properties
  chart: any;
  subs: Subscription = new Subscription();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private helperService: HelperService,
    private colorService: ColorService,
    private reportService: ReportService
  ) {
    // Use fcRoot function to inject FusionCharts library, and the modules you want to use
    FusionChartsModule.fcRoot(FusionCharts, Charts, Widgets, PowerCharts);
  }

  ngOnInit(): void {
    this.initialized = true;
    if (
      this.widget?.chartType === 'doughnut2d' &&
      this.widget?.legendPosition === 'hidden' &&
      this.widget.chartData
    ) {
      this.widget.chartData.chartData.chart.showLegend = 0;
      this.widget.chartData.chartData.chart.chartTopMargin = 10;
    }

    if (this.widget.chartData) {
      const bgColorType = this.colorService.getColorType(
        this.widget.chartData?.chartData.chart.bgColor
      );

      // If the widget has transparency set a hex color to the background
      // because the FusionCharts bgAlpha property only works with hex
      if (this.widget.theme.transparency) {
        this.widget.chartData.chartData.chart.bgColor = '#ffffff';
      }

      // If the widget has a rgba color, convert to hexadecimal
      // because the FusionCharts bgAlpha property only works with hex
      if (bgColorType === 'rgba') {
        const rgbaColor = this.widget.chartData?.chartData.chart.bgColor;
        this.widget.chartData.chartData.chart.bgColor =
          this.colorService.rgbaToHex(rgbaColor);
      }
    }

    this.subs.add(
      this.reportService.presentationMode.subscribe((value: boolean): void => {
        setWidgetFontSize(value, this.widget);
        this.changeDetector.detectChanges();
      })
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Check if chart was rendered...
      if (this.chart && !this.chart?.disposed) {
        // If the chart rendering fails, retry again...
        if (!this.chart?.hasRendered()) {
          this.rendered = false;
          this.changeDetector.detectChanges();
          this.rendered = true;
        }
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }

    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.updateChartConfig();
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue !== undefined
    ) {
      if (changes.widgetStyleForm.currentValue.type === 'style') {
        this.updateChartConfig();
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChartReady($event): void {
    // Storing the chart instance
    this.chart = $event.chart;

    // Update Chart Config
    this.updateChartConfig();
  }

  private updateChartConfig(): void {
    this.updateChartDateValues();
    this.updateWidgetData();
    this.setLegendPosition();
    this.setChartLabel();
    this.setChartPlot();
    this.setChartAxis();
    this.setChartDataValue();
  }

  private setLegendPosition(): void {
    if (this.chart && this.widget?.legendPosition && this.widget.chartData) {
      switch (this.widget.legendPosition) {
        case 'top':
        case 'bottom':
        case 'right':
        case 'left':
          this.widget.chartData.chartData.chart.showLegend = 1;
          this.widget.chartData.chartData.chart.legendPosition =
            this.widget.legendPosition;
          this.changeDetector.detectChanges();
          break;
        case 'hidden':
          this.widget.chartData.chartData.chart.showLegend = 0;
          if (this.widget.chartType === 'doughnut2d') {
            this.widget.chartData.chartData.chart.chartTopMargin = 10;
          }
          break;
      }

      if (this.widget.chartData?.chartType === 'funnel') {
        if (
          this.widget.legendPosition === 'right' ||
          this.widget.legendPosition === 'left'
        ) {
          this.widget.chartData.chartData.chart.chartLeftMargin = 0;
          this.widget.chartData.chartData.chart.chartRightMargin = 0;
        } else {
          this.widget.chartData.chartData.chart.chartLeftMargin = -80;
          this.widget.chartData.chartData.chart.chartRightMargin = 110;
        }
      }
    }
  }

  private setChartAxis(): void {
    const conditions = [
      this.widget.chartData?.chartType !== 'pie2d',
      this.widget.chartData?.chartType !== 'doughnut2d',
      this.widget.chartData?.chartType !== 'funnel',
      typeof this.widget.showAxis === 'boolean',
      this.chart,
    ];

    if (!conditions.includes(false) && this.widget.chartData) {
      this.widget.chartData.chartData.chart.showYAxisValues = this.widget
        ?.showAxis
        ? 1
        : 0;
      this.widget.chartData.chartData.chart.showDivLineSecondaryValue = this
        .widget?.showAxis
        ? 1
        : 0;
      this.widget.chartData.chartData.chart.showSecondaryLimits = this.widget
        ?.showAxis
        ? 1
        : 0;
    }
  }

  private setChartPlot(): void {
    if (
      this.chart &&
      this.widget.chartData?.chartType === 'doughnut2d' &&
      typeof this.widget.dataPlot === 'boolean'
    ) {
      this.widget.chartData.chartData.chart.showLabels = this.widget.dataPlot
        ? 0
        : 1;
      this.widget.chartData.chartData.chart.showValues = this.widget.dataPlot
        ? 1
        : 1;
      this.widget.chartData.chartData.chart.labelPosition = this.widget.dataPlot
        ? 'inside'
        : 'outside';
      this.widget.chartData.chartData.chart.valuePosition = this.widget.dataPlot
        ? 'inside'
        : 'outside';

      if (
        this.widget.dataPlot &&
        this.widget?.chartData?.chartData?.data?.length === 1
      ) {
        this.widget.chartData.chartData.chart.showLabels = 0;
        this.widget.chartData.chartData.chart.showValues = 0;
      }

      if (
        this.widget.dataPlot &&
        this.widget?.chartData?.chartData?.data?.length > 0 &&
        this.widget?.metrics &&
        this.widget?.metrics?.length > 0
      ) {
        this.widget.chartData?.chartData.data.forEach((data) => {
          data.displayValue =
            this.widget.metrics &&
            `${this.helperService.updateMetricData(
              this.widget?.metrics[0].dataType as string,
              data?.value,
              data?.currency,
              this.widget?.metrics[0].shortenNumbers
            )}`;
        });
      }
    }
  }

  private setChartLabel(): void {
    if (
      this.widget.chartData?.chartType === 'pie2d' ||
      this.widget.chartData?.chartType === 'doughnut2d'
    ) {
      if (
        this.widget.chartData &&
        this.widget?.chartData?.chartData?.data?.length > 0 &&
        this.widget?.metrics &&
        this.widget?.metrics?.length > 0 &&
        this.widget?.dimensions &&
        this.widget?.dimensions?.length > 0
      ) {
        this.widget.chartData?.chartData.data.forEach((data) => {
          const labelName: string = this.getDataLabel(data);
          if (this.widget.chartData) {
            this.widget.chartData.chartData.chart.labelPosition = 'outside';
            this.widget.chartData.chartData.chart.showLabels = 1;
            this.widget.chartData.chartData.chart.showValues = 1;
          }

          data.displayValue =
            this.widget.metrics &&
            `${labelName}, ${this.helperService.updateMetricData(
              this.widget.metrics[0].dataType as string,
              data?.value,
              data?.currency,
              this.widget?.metrics[0].shortenNumbers
            )}`;
        });
      }
    }
  }

  private updateWidgetData(): void {
    if (
      this.widget.chartData?.chartType === 'multiaxisline' &&
      this.widget?.metrics
    ) {
      this.widget.metrics.forEach((metric: ScopeField) => {
        if (!this.widget.chartData?.chartData?.axis) {
          return;
        }
        this.widget.chartData?.chartData?.axis.forEach((axis) => {
          if (axis.title !== metric.name) {
            return;
          }
          if (metric.dataType === 'currency' && axis?.numberPrefix) {
            axis.numberPrefix = `${getCurrencySymbol(
              axis.numberPrefix.trim(),
              'wide'
            )}`;
          }
          axis.dataset[0].data.forEach((data) => {
            data.toolText =
              metric.dataType &&
              `$label, $seriesName ${this.helperService.updateMetricData(
                metric.dataType,
                data?.value,
                data?.currency,
                metric.shortenNumbers
              )}`;
          });
        });
      });
    }

    if (
      this.widget.chartData?.chartType === 'mscombidy2d' &&
      this.widget?.metrics
    ) {
      this.widget.metrics.forEach((metric: ScopeField): void => {
        if (!this.widget.chartData?.chartData?.dataset) {
          return;
        }

        this.widget.chartData?.chartData?.dataset?.forEach((serie): void => {
          if (serie.seriesName !== metric.name) {
            return;
          }

          serie?.data?.forEach((data): void => {
            data.toolText =
              metric.dataType &&
              `$label, $seriesName ${this.helperService.updateMetricData(
                metric.dataType,
                data?.value,
                data?.currency,
                metric.shortenNumbers
              )}`;
          });
        });
      });

      this.updatePrefixSuffix();
    }

    if (
      (this.widget.chartData?.chartType === 'msbar2d' ||
        this.widget.chartData?.chartType === 'mscolumn2d' ||
        this.widget.chartData?.chartType === 'stackedbar2d' ||
        this.widget.chartData?.chartType === 'stackedcolumn2d' ||
        this.widget.chartData?.chartType === 'msarea') &&
      this.widget.metrics &&
      this.widget?.metrics?.length > 0
    ) {
      if (this.widget.chartData?.chartType === 'msarea') {
        this.widget.chartData.chartData.chart.numberPrefix = getCurrencySymbol(
          this.widget.chartData.chartData.chart.numberPrefix,
          'wide'
        );
      }

      this.widget.metrics?.forEach((metric: ScopeField): void => {
        if (!this.widget.chartData?.chartData.dataset) {
          return;
        }
        this.widget.chartData?.chartData.dataset.forEach((dataset): void => {
          if (
            dataset.seriesName === metric.name ||
            dataset?.metricName === metric?.name
          ) {
            dataset.data.forEach((data) => {
              data.toolText =
                metric.dataType &&
                `$label, $seriesName: ${this.helperService.updateMetricData(
                  metric.dataType,
                  data?.value,
                  data?.currency,
                  metric.shortenNumbers
                )}`;
            });
          }
        });
      });
    }

    if (this.widget.chartData?.chartType === 'funnel') {
      if (
        this.widget?.chartData?.chartData?.data?.length > 0 &&
        this.widget.metrics &&
        this.widget?.metrics?.length > 0
      ) {
        this.widget.chartData?.chartData.data.forEach((data, i): void => {
          if (data?.realValue) {
            const number =
              this.widget.metrics &&
              this.helperService.updateMetricData(
                this.widget.metrics[i].dataType as string,
                +data?.realValue,
                data?.currency,
                this.widget?.metrics[i].shortenNumbers
              );
            data.tooltext = `${number}`;
            data.displayValue = `${data?.label}, ${number}`;
          }
        });
      }
    }

    if (
      this.widget.chartData?.chartType === 'pie2d' ||
      this.widget.chartData?.chartType === 'doughnut2d'
    ) {
      if (this.widget?.chartData?.chartData?.data?.length > 0) {
        let valueTotal = 0;
        let totalCount = 0;
        let currentCurrency = '';

        this.widget.chartData.chartData.data.forEach((data) => {
          if (
            this.widget?.metrics &&
            this.widget?.metrics?.length > 0 &&
            (data?.value || data?.value === 0)
          ) {
            valueTotal += data.value;
            totalCount += 1;
            if (!currentCurrency) {
              currentCurrency = data?.currency;
            }

            const formattedNumber =
              this.widget.metrics &&
              this.helperService.updateMetricData(
                this.widget.metrics[0].dataType as string,
                data?.value,
                data?.currency,
                this.widget?.metrics[0].shortenNumbers
              );

            data.tooltext = `${data?.label}, ${this.widget?.metrics[0].name}, ${formattedNumber}`;
          }
        });

        if (
          this.widget?.metrics &&
          this.widget?.metrics?.length > 0 &&
          this.widget?.chartData?.chartData?.chart?.defaultCenterLabel
        ) {
          if (this.widget.metrics[0].aggregation === 'avg') {
            valueTotal = valueTotal / totalCount;
          }
          this.widget.chartData.chartData.chart.defaultCenterLabel = `${this.helperService.updateMetricData(
            this.widget.metrics[0].dataType as string,
            valueTotal,
            currentCurrency,
            this.widget?.metrics[0].shortenNumbers
          )}`;
        }

        if (this.widget?.chartData?.chartData?.chart?.centerLabel) {
          delete this.widget.chartData.chartData.chart.centerLabel;
        }
      }
    }
  }

  private setChartDataValue(): void {
    if (
      (this.widget.chartData?.chartType === 'pie2d' ||
        this.widget.chartData?.chartType === 'doughnut2d' ||
        this.widget.chartData?.chartType === 'funnel') &&
      this.widget?.dataValue === 'percentageTotal'
    ) {
      this.widget.chartData.chartData.chart.showPercentValues = 1;
      this.widget.chartData.chartData.chart.showPercentInTooltip = 0;
      this.widget.chartData.chartData.chart.decimals = 0;

      if (
        this.widget?.chartData?.chartData?.data?.length > 0 &&
        this.widget?.metrics &&
        this.widget?.metrics?.length > 0
      ) {
        let sum = 0;
        for (let i = 0; i < this.widget.chartData?.chartData.data.length; i++) {
          sum += this.widget.chartData?.chartData.data[i].value;
        }

        this.widget.chartData?.chartData.data.forEach((data): void => {
          if (this.widget.chartData?.chartType !== 'funnel') {
            data.valuePosition = this.widget.dataPlot ? 'inside' : 'outside';
            const percent = (
              Math.round((data.value / sum) * 100 * 10) / 10
            ).toFixed(0);
            data.displayValue = `${
              this.widget.dataPlot ? '' : this.getDataLabel(data) + ', '
            }${percent}%`;
          } else {
            data.displayValue = `${data.label} ${data?.percentageValue || ''}`;
          }
        });
      }
    }

    if (
      (this.widget.chartData?.chartType === 'pie2d' ||
        this.widget.chartData?.chartType === 'doughnut2d' ||
        this.widget.chartData?.chartType === 'funnel') &&
      this.widget?.dataValue === 'metricValue'
    ) {
      this.widget.chartData.chartData.chart.showPercentValues = 0;
    }
  }

  private getDataLabel(chartData: any): string {
    let labelName: string;
    switch (this.widget?.dataLabel) {
      case 'metric':
        labelName = (this.widget.metrics && this.widget?.metrics[0].name) || '';
        break;
      case 'dimension':
        labelName = chartData?.label;
        break;
      default:
        labelName = chartData?.label;
        break;
    }
    return labelName;
  }

  updatePrefixSuffix(): void {
    let setSecondary = false;
    let setPrimary = false;

    // Update Chart Config Suffix & Prefix
    if (this.widget.metrics && this.widget.chartData) {
      for (const metric of this.widget.metrics) {
        if (metric?.useSecondaryAxis && !setSecondary) {
          if (metric?.dataType === 'currency') {
            this.widget.chartData.chartData.chart.sNumberPrefix = `${getCurrencySymbol(
              this.widget?.chartData?.chartData?.chart?.sNumberPrefix?.trim() ||
                'USD',
              'wide'
            )}`;
            this.widget.chartData.chartData.chart.sNumberSuffix = '';
          } else if (metric?.dataType === 'percentage') {
            this.widget.chartData.chartData.chart.sNumberPrefix = '';
            this.widget.chartData.chartData.chart.sNumberSuffix = '%';
          } else {
            this.widget.chartData.chartData.chart.sNumberPrefix = '';
            this.widget.chartData.chartData.chart.sNumberSuffix = '';
          }
          setSecondary = true;
        }

        if (!metric?.useSecondaryAxis && !setPrimary) {
          if (metric?.dataType === 'currency') {
            this.widget.chartData.chartData.chart.numberPrefix = `${getCurrencySymbol(
              this.widget?.chartData?.chartData?.chart?.numberPrefix?.trim() ||
                'USD',
              'wide'
            )}`;
            this.widget.chartData.chartData.chart.numberSuffix = '';
          } else if (metric?.dataType === 'percentage') {
            this.widget.chartData.chartData.chart.numberPrefix = '';
            this.widget.chartData.chartData.chart.numberSuffix = '%';
          } else {
            this.widget.chartData.chartData.chart.numberPrefix = '';
            this.widget.chartData.chartData.chart.numberSuffix = '';
          }
          setPrimary = true;
        }
      }
    }
  }

  private updateChartDateValues(): void {
    if (this.widget?.dimensions && this.widget?.dimensions?.length > 0) {
      const dateData: ScopeField | undefined = this.widget.dimensions.find(
        (item: ScopeField): boolean => item.name === 'Date'
      );

      if (
        dateData &&
        dateData?.dateConfiguration &&
        (dateData?.dateConfiguration?.dateGroup === 'dayHours' ||
          dateData?.dateConfiguration?.dateGroup === 'dateHourMinute')
      ) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const locale = Intl.DateTimeFormat().resolvedOptions().locale;

        if (
          this.widget.chartData?.chartType === 'pie2d' ||
          this.widget.chartData?.chartType === 'doughnut2d' ||
          this.widget.chartData?.chartType === 'funnel'
        ) {
          if (this.widget?.chartData?.chartData?.data?.length > 0) {
            for (const item of this.widget.chartData.chartData.data) {
              if (isValidDate(item?.label)) {
                item.label = formatDate(
                  item?.label,
                  dateData?.dateConfiguration?.dateFormat === 'dayHourStandard'
                    ? 'h:mm a'
                    : 'H:mm',
                  locale,
                  userTimeZone
                );
              }
            }
          }
        }

        const supportedChartTypes: string[] = [
          'mscolumn2d',
          'mscombidy2d',
          'msbar2d',
          'msarea',
          'stackedbar2d',
          'stackedcolumn2d',
          'multiaxisline',
        ];

        const chartType = this.widget.chartData?.chartType;

        if (chartType && supportedChartTypes.includes(chartType)) {
          const chartData = this.widget.chartData?.chartData;

          if (chartData?.categories) {
            chartData.categories.forEach((category): void => {
              const categoryLabels = category?.category;

              if (categoryLabels && categoryLabels.length > 0) {
                categoryLabels.forEach((item): void => {
                  if (isValidDate(item?.label)) {
                    item.label = formatDate(
                      item?.label,
                      dateData?.dateConfiguration?.dateFormat ===
                        'dayHourStandard'
                        ? 'h:mm a'
                        : 'H:mm',
                      locale,
                      userTimeZone
                    );
                  }
                });
              }
            });
          }
        }
      }
    }
  }
}
