import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Signal,
  ViewChild,
  effect,
  signal,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {
  DialogService,
  NotificationService,
  ReportService,
} from 'src/app/core/services';
import * as models from '../../models';
import { SatPopover, SatPopoverModule } from '@ncstate/sat-popover';
import { NgClass } from '@angular/common';
import { TabsComponent } from '../../components/tabs/tabs/tabs.component';
import { TabItemComponent } from '../../components/tabs/tab-item/tab-item.component';
import { TabLabelComponent } from '../../components/tabs/tab-label/tab-label.component';
import { RouterLink } from '@angular/router';
import { CardCarouselComponent } from '../../mobile/components/card-carousel/card-carousel.component';
import { LoadingComponent } from '../../components/loading/loading.component';
import { DateAsAgoPipe } from '../../pipes/date-as-ago.pipe';

export type DataQueueStatus =
  | 'DONE'
  | 'SYNCED'
  | 'ERROR'
  | 'AUTHENTICATION_ERROR'
  | 'CREDENTIALS_NOT_WORKING'
  | 'RUNNING'
  | 'COMPILING'
  | 'PENDING_COMPILATION'
  | 'QUEUE'
  | 'PENDING'
  | 'ACCOUNT_UNLINKED';

@Component({
    selector: 'app-data-queue-dialog',
    templateUrl: './data-queue-dialog.component.html',
    styleUrls: ['./data-queue-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatDialogContent,
        TabsComponent,
        TabItemComponent,
        TabLabelComponent,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        RouterLink,
        SatPopoverModule,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        CardCarouselComponent,
        LoadingComponent,
        TranslateModule,
        DateAsAgoPipe,
    ],
})
export class DataQueueDialogComponent implements OnInit, AfterViewInit {
  // Children
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) connectionsTable: MatTable<any>;
  // Tab Variables
  connections: models.Connection[];

  // Table Properties
  connectionDataSource = new MatTableDataSource<models.ServiceAccount>();
  columnsToDisplay: string[] = [];
  pageSizeOptions: number[] = [5, 10, 15, 20];

  // State Variables
  isLoading = true;
  selectedConnection = signal<models.Connection>({}); //Remember to create connection model for this specific case.
  reportInQueue = false;
  isPopInfoActive = false;

  // Variables
  clients: any[] = [];
  cardData: models.CardData;
  subs: Subscription = new Subscription();

  dataQueueStatus: models.DataQueueStatus;

  // Data
  public statusCssClass: Record<DataQueueStatus, string> = {
    'DONE': 'active',
    'SYNCED': 'active',
    'ERROR': 'error',
    'AUTHENTICATION_ERROR': 'error',
    'CREDENTIALS_NOT_WORKING': 'error',
    'ACCOUNT_UNLINKED': 'error',
    'RUNNING': 'in-progress',
    'COMPILING': 'in-progress',
    'PENDING_COMPILATION': 'in-progress',
    'QUEUE': 'queue',
    'PENDING': 'queue',
  };

  public statusLabelTranslations: Record<DataQueueStatus, string> = {
    'DONE': 'common.status.done',
    'SYNCED': 'common.status.done',
    'ERROR': 'common.status.error',
    'AUTHENTICATION_ERROR': 'common.status.authentication_error',
    'CREDENTIALS_NOT_WORKING': 'common.status.credential_error',
    'RUNNING': 'common.status.running',
    'COMPILING': 'common.status.compiling',
    'PENDING_COMPILATION': 'common.status.ready_to_process',
    'QUEUE': 'common.status.queue',
    'PENDING': 'common.status.queue',
    'ACCOUNT_UNLINKED': 'common.status.account_unlinked',
  };

  public statusTooltipMessageTranslation: Record<DataQueueStatus, string[]> = {
    'DONE': ['common.status.synced_info'],
    'SYNCED': ['common.status.synced_info'],
    'ERROR': [
      'common.status.credential_info_html_begin',
      'common.status.credential_info_html_link',
      'common.status.credential_info_html_end',
    ],
    'AUTHENTICATION_ERROR': [
      'common.status.credential_info_html_begin',
      'common.status.credential_info_html_link',
      'common.status.credential_info_html_end',
    ],
    'CREDENTIALS_NOT_WORKING': [
      'common.status.credential_info_html_begin',
      'common.status.credential_info_html_link',
      'common.status.credential_info_html_end',
    ],
    'RUNNING': ['common.status.running_info'],
    'COMPILING': ['common.status.compiling_info'],
    'PENDING_COMPILATION': ['common.status.pending_compilation_info'],
    'QUEUE': ['common.status.queue_info'],
    'PENDING': ['common.status.queue_info'],
    'ACCOUNT_UNLINKED': ['common.status.unlinked_info'],
  };

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DataQueueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    effect(() => {
      if (this.selectedConnection().serviceAccounts) {
        this.setColumnsToDisplay();
        this.connectionDataSource.data =
          this.selectedConnection().serviceAccounts!;
      }
    });
  }

  ngOnInit(): void {
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef
          .backdropClick()
          .subscribe((): void => this.dialogService.closeDialog(this.dialogRef))
      );
    }
    this.getDataQueue();
  }

  getDataQueue(): void {
    this.reportService.getDataQueue(this.data.report.id).subscribe({
      next: (res) => {
        this.dataQueueStatus = {
          processError: res.processError,
          processPending: res.processPending,
          processQueue: res.processQueue,
          processStatus: '',
        };
        this.reportInQueue = res.processQueue;
        this.connections = res.connections;
        if (this.connections?.length > 0) {
          this.connections
            .filter((c) => c.name !== undefined)
            .sort((a, b) => a.name!.localeCompare(b.name!));
          this.selectedConnection.set(this.connections[0]);
        } else {
          this.clients = res.clients;
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.notificationService.error(err.error.error.message, 5000);
      },
    });
  }

  ngAfterViewInit(): void {
    this.connectionDataSource.paginator = this.paginator;
    this.connectionDataSource.sort = this.sort;
  }

  selectConnection(connectionIndex: number): void {
    this.selectedConnection.set(this.connections[connectionIndex]);
    if (this.data.isMobile) {
      this.sortDataForCardCarousel();
    }
  }

  private setColumnsToDisplay(): void {
    this.columnsToDisplay = [];
    this.columnsToDisplay = (
      this.selectedConnection().tableData as Array<any>
    ).map((el) => el.key);

    this.columnsToDisplay.unshift('clientName');
    this.columnsToDisplay.push(...['status', 'lastUpdated']);
  }

  private sortDataForCardCarousel(): void {
    const elements = this.selectedConnection().serviceAccounts;
    const tableDataLabels =
      this.selectedConnection().tableData &&
      this.selectedConnection().tableData !== undefined
        ? this.selectedConnection().tableData?.map((l) => {
            return {key: l.key, label: l.label};
          })
        : [];

    const labels: models.CardLabel[] = [
      {
        key: 'clientName',
        label: this.translate.instant('shared.dialogs.data_queue.client_name'),
      },
      ...tableDataLabels!,
      {
        key: 'status',
        label: this.translate.instant(
          'shared.dialogs.manage_credentials.services_table.status'
        ),
      },
      {
        key: 'lastUpdated',
        label: this.translate.instant(
          'shared.dialogs.manage_credentials.services_table.last_updated'
        ),
      },
    ];

    if (elements) {
      this.cardData = {labels, elements};
    }
  }

  onClose(): void {
    this.dialogService.closeDialog(this.dialogRef, this.dataQueueStatus);
  }

  public onLeaveStatus(popInfo: SatPopover): void {
    setTimeout((): void => {
      if (!this.isPopInfoActive) {
        popInfo?.close();
      }
    }, 150);
  }
}
