<section (click)="$event.stopPropagation()" class="px-3 py-2">
  <header class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="h6 m-0">
        {{ "reporting.dynamic_date_range.title" | translate }}
      </h6>

      @if (origin === "dialog") {
        <button
          [mat-dialog-close]="false"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      }
    </div>
  </header>

  <form [formGroup]="dateRangeForm">
    <div class="row">
      <div class="col-12 mb-3">
        <label for="dateType">
          {{
            "reporting.dynamic_date_range.label_date_type" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <mat-select
          [class.border-danger]="
            dateRangeForm.get('dateType')?.errors?.required &&
            dateRangeForm.get('dateType')?.touched
          "
          [panelClass]="'wsm-custom-select'"
          class="wsm-input w-100"
          disableOptionCentering
          formControlName="dateType"
          id="dateType"
          placeholder="{{
            'reporting.dynamic_date_range.placeholder_date_type' | translate
          }}"
        >
          @if (currentDateRange?.current) {
            <mat-option [value]="'current'">
              {{
                "reporting.dynamic_date_range.option_current_date" | translate
              }}
            </mat-option>
          }
          @if (currentDateRange?.previous) {
            <mat-option [value]="'previous'">
              {{
                "reporting.dynamic_date_range.option_previous_date" | translate
              }}
            </mat-option>
          }
        </mat-select>
      </div>

      <div class="col-12 mb-3">
        <label for="dateShow">
          {{
            "reporting.dynamic_date_range.label_date_show" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <mat-select
          [class.border-danger]="
            dateRangeForm.get('dateShow')?.errors?.required &&
            dateRangeForm.get('dateShow')?.touched
          "
          [panelClass]="'wsm-custom-select'"
          class="wsm-input w-100"
          disableOptionCentering
          formControlName="dateShow"
          id="dateShow"
          placeholder="{{
            'reporting.dynamic_date_range.placeholder_date_show' | translate
          }}"
        >
          <mat-option [value]="'start'">{{
            "reporting.dynamic_date_range.option_start_date" | translate
          }}</mat-option>
          <mat-option [value]="'end'">{{
            "reporting.dynamic_date_range.option_end_date" | translate
          }}</mat-option>
          <mat-option [value]="'both'">{{
            "reporting.dynamic_date_range.option_start_end_date" | translate
          }}</mat-option>
        </mat-select>
      </div>

      <div class="col-12 mb-3">
        <label for="dateFormat">
          {{
            "reporting.dynamic_date_range.label_date_format" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <input
          [class.border-danger]="
            dateRangeForm.get('dateFormat')?.errors?.required &&
            dateRangeForm.get('dateFormat')?.touched
          "
          [formControl]="dynamicDateFilterCtrl"
          [matAutocomplete]="auto"
          class="wsm-input w-100"
          id="dateFormat"
          placeholder="{{
            'reporting.dynamic_date_range.placeholder_date_format' | translate
          }}"
          type="text"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayWith.bind(this)"
          autoActiveFirstOption
          class="wsm-custom-select"
        >
          @for (
            dynamicDate of filteredDynamicDates | async;
            track dynamicDate
          ) {
            <mat-option [value]="dynamicDate">
              {{ dynamicDate.name }} ({{
                today | date: dynamicDate?.pipeFormat
              }})
            </mat-option>
          }
        </mat-autocomplete>
      </div>

      <div class="col-12 d-flex justify-content-end">
        <button
          (click)="onSave()"
          [disabled]="dateRangeForm.invalid"
          class="wsm-btn wsm-btn-primary"
        >
          {{ "reporting.dynamic_date_range.add_button" | translate }}
        </button>
      </div>
    </div>
  </form>
</section>
