import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {
  ApiService,
  NotificationService,
  ReportingStore,
  ReportService,
} from 'src/app/core/services';
import {HelperService} from 'src/app/shared/helpers';
import {ReportPage, ReportTheme} from 'src/app/shared/models';
import {TranslateService} from '@ngx-translate/core';
import {ThemeBuilderComponent} from '../../../../shared/components/theme-builder/theme-builder.component';
import {LoadingComponent} from '../../../../shared/components/loading/loading.component';

@Component({
  selector: 'app-report-page-settings',
  templateUrl: './report-page-settings.component.html',
  standalone: true,
  imports: [ThemeBuilderComponent, LoadingComponent],
})
export class ReportPageSettingsComponent implements OnInit {
  public theme: ReportTheme = {};
  public pageTheme: ReportTheme;
  public page: ReportPage;
  public newPage: ReportPage;
  public reportId: string;
  public loading: boolean;
  public backgroundFile: File | null;
  public logoFile: File | null;
  public loadingImage = true;
  public isPageImage: boolean;
  public canPopulateForm: boolean;
  public noDispatchInputFile: boolean; // if is not required dispatch the resFile inputUpload output the first time.

  constructor(
    public dialogRef: MatDialogRef<ReportPageSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    public apiService: ApiService,
    private notificationService: NotificationService,
    private reportService: ReportService,
    private reportStore: ReportingStore,
    private helper: HelperService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.page = this.data.page;
    this.isPageImage =
      this.data?.page?.theme && this.data?.page?.theme?.backgroundImage;
    this.noDispatchInputFile = !this.isPageImage; // if there is not an image into page theme
    this.reportId = this.data.reportId;
    this.pageTheme = this.data.page.theme ?? {};
    Object.assign(this.theme, this.data.theme);
    this.canPopulateForm = Boolean(this.data.page.theme);
    this.pageTheme.name = this.page.name;
    if (
      this.theme?.backgroundImage ||
      this.page.theme?.backgroundImage ||
      this.theme?.logo
    ) {
      this.loadingImage = true;
      if (this.isPageImage) {
        this.getBackgroundImageByPage();
      } else {
        this.handleGetImages();
      }
    } else {
      this.loadingImage = false;
    }
  }

  onSetTheme(event): void {
    if (event) {
      this.loading = true;
      const payload = {backgroundColor: event.widgetBackgroundColor, ...event};
      payload.fontSize = event.fontSize.size;
      const rPage = {...this.page};
      rPage.theme = {...payload};
      rPage.name = payload.name;
      delete rPage.theme?.name;
      this.newPage = {...rPage};
      if (
        this.newPage.theme?.colorPalette &&
        this.newPage.theme?.colorPalette.length === 0
      ) {
        delete this.newPage.theme?.colorPalette;
      }
      this.newPage.theme = this.newPage.theme
        ? this.helper.clearPayload(this.newPage.theme)
        : {};
      this.onApplySettings();
    }
    if (!event) {
      this.dialogRef.close();
    }
  }

  handleGetImages(): void {
    this.loadingImage = true;
    if (
      this.theme?.logo &&
      (this.theme?.backgroundImage || this.theme?.customThemeBackgroundImage)
    ) {
      this.getLogoAndBackground();
    }

    if (this.theme?.logo && !this.theme?.backgroundImage) {
      const logo$ = this.apiService.getImage(
        this.theme.logo || '',
        'reportThemeLogo'
      );
      logo$.subscribe({
        next: (res) => {
          this.logoFile = new File([res], this.theme.logo || '');
          this.loadingImage = false;
        },
        error: (err) => {
          this.notificationService.error(err.message, 5000);
          this.loadingImage = false;
          console.error(err);
        },
      });
    }

    if (
      (this.theme?.backgroundImage || this.theme?.customThemeBackgroundImage) &&
      !this.theme?.logo
    ) {
      this.getBackgroundImage();
    }

    if (
      !this.theme?.backgroundImage ||
      !this.theme?.customThemeBackgroundImage ||
      !this.theme?.logo
    ) {
      if (!this.theme.backgroundImage) {
        this.backgroundFile = null;
      }
      if (!this.theme.logo) {
        this.logoFile = null;
      }
      if (!this.theme.customThemeBackgroundImage) {
        this.backgroundFile = null;
      }
      this.loadingImage = false;
    }
  }

  getLogoAndBackground(): void {
    this.loadingImage = true;
    const param =
      this.theme.name === 'Custom Theme'
        ? {
            image: this.theme.customThemeBackgroundImage,
            type: 'customThemeBackgroundImage',
            id: this.reportId,
          }
        : {
            image: this.theme.backgroundImage,
            type: 'reportThemeBackground',
            id: this.reportId,
          };
    const logo$ = this.apiService.getImage(
      this.theme.logo || '',
      'reportThemeLogo',
      this.theme._id
    );
    const background$ = this.apiService.getImage(
      param.image,
      param.type,
      this.reportId
    );

    const combine = combineLatest([logo$, background$]);

    combine.subscribe({
      next: ([logo, background]) => {
        if (logo && background) {
          this.logoFile = new File([logo], this.theme.logo || '');
          this.backgroundFile = new File(
            [background],
            this.theme.backgroundImage || this.theme.customThemeBackgroundImage
          );
          this.loadingImage = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.notificationService.error(err.error.error_description, 5000);
        this.loadingImage = false;
      },
    });
  }

  getBackgroundImage(): void {
    this.loadingImage = true;
    const param = this.theme.customThemeBackgroundImage
      ? {
          image: this.theme.customThemeBackgroundImage,
          type: 'customThemeBackgroundImage',
          id: this.reportId,
        }
      : {
          image: this.theme.backgroundImage,
          type: 'reportThemeBackground',
          id: this.reportId,
        };
    const background$ = this.apiService.getImage(
      param.image,
      param.type,
      this.reportId
    );
    background$.subscribe({
      next: (res) => {
        this.backgroundFile = new File(
          [res],
          this.theme.backgroundImage || this.theme.customThemeBackgroundImage
        );
        this.loadingImage = false;
      },
      error: (err) => {
        this.notificationService.error(err.error.error_description, 5000);
        this.loadingImage = false;
        console.error(err);
      },
    });
  }

  getBackgroundImageByPage(): void {
    this.reportService
      .getPictureByPage(this.reportId, this.page.id, false)
      .subscribe({
        next: (res) => {
          this.backgroundFile = new File(
            [res],
            this.page.theme?.backgroundImage
          );
          this.loadingImage = false;
        },
        error: (err) => {
          console.error(err);
          this.loadingImage = false;
          this.notificationService.error(err.message, 5000);
        },
      });
  }
  onApplySettings(): void {
    if (
      this.newPage?.theme?.backgroundImage &&
      this.newPage?.theme?.backgroundImageFile &&
      this.page.theme?.backgroundImage !== this.newPage.theme.backgroundImage
    ) {
      this.cleanPageStoreImage();
      this.uploadImage(
        this.newPage.theme?.backgroundImageFile,
        this.reportId,
        this.page.id
      );
    } else {
      this.cleanPageStoreImage();
      this.updateReportPage();
    }
  }

  cleanPageStoreImage(): void {
    this.reportStore.deletePage({
      reportId: this.reportId,
      pageId: this.page.id,
      backgroundImage: null,
      backgroundImageType: 'page',
    });
  }

  uploadImage(imageFile?: File, reportId?: string, pageId?: string): void {
    this.reportService
      .uploadPictureByPage(imageFile!, this.reportId, this.page.id)
      .subscribe({
        next: (res: any) => {
          this.newPage.theme!.backgroundImage = res?.file;
          const reader = new FileReader();
          reader.readAsDataURL(imageFile!);
          reader.onload = (): void => {
            const imgSrc = reader.result;
            this.reportStore.updatePages({
              reportId: this.reportId,
              pageId: this.page.id,
              backgroundImage: imgSrc,
              backgroundImageType: 'page',
            });
          };
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error(err.error.error_description, 5000);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
          this.updateReportPage();
        },
      });
  }

  public updateReportPage(): void {
    this.loading = true;
    Object.keys(this.newPage).forEach((key) => {
      switch (key) {
        case 'name':
          break;
        case 'theme':
          break;
        case '_id':
          break;
        case 'id':
          break;
        default:
          delete this.newPage[key];
      }
    });
    this.reportService
      .updatePage(this.reportId, this.page.id, this.newPage)
      .subscribe({
        next: (res) => {
          this.dialogRef.close(res);
        },
        error: (err) => {
          this.notificationService.error(err.error.error_description, 5000);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
          this.translate
            .get('notifications.report_page_updated')
            .subscribe((res: string) => {
              this.notificationService.success(res, 5000);
            });
        },
      });
  }

  onCloseDialog(event): void {
    this.dialogRef.close();
  }
}
