<div class="position-relative d-flex flex-column h-100">
  <header>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">
        {{ "reporting.widgets.table.ad_preview" | translate }}
      </h1>

      <button
        (click)="onClose()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
  </header>

  <mat-dialog-content class="ad-preview-details">
    <div [innerHTML]="data.adPreview | safeHtml"></div>
  </mat-dialog-content>
</div>
