<section class="container-fluid px-0 py-1 h-100">
  <div class="row h-100">
    <div
      [ngStyle]="{'order': brandingItemsOrder?.logo}"
      [ngClass]="{
        'justify-content-end': brandingItemsOrder?.logo === 3,
        'justify-content-center': brandingItemsOrder?.logo === 2,
        'brand-logo': brandingItemsOrder?.logo !== 2
      }"
      class="col-4 branding-container"
    >
      <div
        class="branding-logo"
        [ngStyle]="{
          'background-position':
            brandingItemsOrder?.logo === 1
              ? 'left'
              : brandingItemsOrder?.logo === 3
                ? 'right'
                : 'center'
        }"
        [style.background-image]="
          'url(' +
          (widget.logo
            ? widget.logo
            : '../../../../../../assets/img/svg/logo.svg') +
          ')'
        "
      >
        <!-- <ng-container *ngIf="widget?.logo"> -->
        <!-- <img class="img-fluid" src="{{ widget.logo }}" alt="Logo" /> -->
        <!-- </ng-container> -->
      </div>
    </div>

    <div
      [ngClass]="{
        'justify-content-center': brandingItemsOrder?.link === 2,
        'brand-text': brandingItemsOrder?.logo !== 2
      }"
      [ngStyle]="{'order': brandingItemsOrder?.link}"
      class="col-4 branding-container"
    >
      <div
        [ngStyle]="{'color': theme.websiteColor || '#6c757d'}"
        [ngClass]="{'mobile-logo-text-size': isMobile}"
      >
        <span
          [ngClass]="{
            'flex-row-reverse': widget.widgetIcon?.position === 'right'
          }"
          class="d-flex gap-1 align-items-center"
        >
          @if (widget?.widgetIcon) {
            <i [class]="widget.widgetIcon?.faClass"></i>
          }
          {{ widget.brandingText }}&#8203;
        </span>
      </div>
    </div>

    <div
      [ngClass]="{
        'justify-content-end': brandingItemsOrder?.pageNumber === 3,
        'brand-page-number': brandingItemsOrder?.logo !== 2
      }"
      [ngStyle]="{'order': brandingItemsOrder?.pageNumber}"
      class="col-4 branding-container"
    >
      <span
        [ngStyle]="{'color': theme?.websiteColor || '#6c757d'}"
        [ngClass]="{'mobile-logo-text-size': isMobile}"
        class="logo-text-size"
      >
        {{ pageNumber }}
      </span>
    </div>
  </div>
</section>
