<!-- Desktop Table -->
<section [hidden]="isMobile && !largeMobile" class="w-100 h-100 overflow-auto">
  <div class="overlay-container">
    <table
      [hidden]="widget.tableData?.rows?.length === 0"
      [dataSource]="tableDataSource"
      [matSortActive]="widget.sortField ? widget.sortField : ''"
      [matSortDirection]="widget.sortDirection ? widget.sortDirection : ''"
      mat-table
      matSort
    >
      @for (column of widget?.tableData?.columns; track column.key) {
        <ng-container [matColumnDef]="column.key">
          <!-- Table Header (Title)-->
          <th
            *matHeaderCellDef
            class="widget-table-header"
            mat-header-cell
            mat-sort-header
            [ngStyle]="{
              'background-color': theme.tableHeaderBgColor
                ? theme.tableHeaderBgColor
                : theme.pageColor,
              'border-bottom-color': theme.tableHeaderBorderColor
                ? theme.tableHeaderBorderColor
                : theme.pageColor,
              'color': theme.bodyColor,
            }"
            [ngClass]="{'print-table-header': isPrint}"
          >
            {{ column.label }}
          </th>
          <!-- Table Content -->
          <td
            *matCellDef="let element"
            mat-cell
            [ngStyle]="{
              'color': theme.bodyColor,
              'border-bottom-color': theme.tableRowBorderColor
                ? theme.tableRowBorderColor
                : theme.pageColor,
              'background-color':
                widget?.tableData?.rows!.indexOf(element) % 2 === 0
                  ? theme.tableRowBgColor
                  : stripeColor,
            }"
          >
            <app-table-row
              [widget]="widget"
              [isPrint]="isPrint"
              [reportTheme]="reportTheme"
              [pageTheme]="pageTheme"
              [element]="element"
              [column]="column"
              [columnKey]="column.key"
              [columnDataType]="column.dataType"
              [theme]="theme"
            >
            </app-table-row>
          </td>
          <!-- Table Footer (Total) -->
          <td
            mat-footer-cell
            *matFooterCellDef="let row"
            [hidden]="
              this.widget?.totalPosition !== 'bottom' || isObjectEmpty(totalRow)
            "
            [ngStyle]="{
              'color': theme.bodyColor,
              'border-top-color': theme.tableRowBorderColor
                ? theme.tableRowBorderColor
                : theme.pageColor,
              'border-bottom-color': theme.tableRowBorderColor
                ? theme.tableRowBorderColor
                : theme.pageColor,
              'background-color':
                widget?.tableData?.rows!.indexOf(row) % 2 === 0
                  ? theme.tableRowBgColor
                  : stripeColor,
            }"
          >
            @if (totalRow[column.key] && !isObjectEmpty(totalRow)) {
              <app-table-row
                [widget]="widget"
                [isPrint]="isPrint"
                [reportTheme]="reportTheme"
                [pageTheme]="pageTheme"
                [element]="totalRow"
                [column]="column"
                [columnKey]="column.key"
                [columnDataType]="column.dataType"
                [theme]="theme"
                [isTotalRow]="true"
              >
              </app-table-row>
            }
          </td>
        </ng-container>
      }

      <!-- Table Header (Total) -->
      @for (column of widget?.tableData?.columns; track column.key) {
        <ng-container [matColumnDef]="column.key + '_'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [hidden]="
              this.widget?.totalPosition !== 'top' || isObjectEmpty(totalRow)
            "
            [ngStyle]="{
              'color': theme.bodyColor,
              'border-bottom-color': theme.tableRowBorderColor
                ? theme.tableRowBorderColor
                : theme.pageColor,
              'background-color':
                widget?.tableData?.rows!.indexOf(column) % 2 === 0
                  ? theme.tableRowBgColor
                  : stripeColor,
            }"
          >
            @if (totalRow[column.key] && !isObjectEmpty(totalRow)) {
              <app-table-row
                [widget]="widget"
                [isPrint]="isPrint"
                [reportTheme]="reportTheme"
                [pageTheme]="pageTheme"
                [element]="totalRow"
                [column]="column"
                [columnKey]="column.key"
                [columnDataType]="column.dataType"
                [theme]="theme"
                [isTotalRow]="true"
              >
              </app-table-row>
            }
          </th>
        </ng-container>
      }

      <!-- Table Config -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-header-row
        *matHeaderRowDef="totalHeaderColumns"
        [hidden]="
          this.widget?.totalPosition !== 'top' || isObjectEmpty(totalRow)
        "
        [ngClass]="{
          'tr-top':
            this.widget?.totalPosition === 'top' &&
            widget?.tableData?.totalRow?.length! > 0,
        }"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns"
        [hidden]="
          this.widget?.totalPosition !== 'bottom' || isObjectEmpty(totalRow)
        "
        [ngClass]="{
          'tr-bottom':
            this.widget.totalPosition === 'bottom' &&
            widget?.tableData?.totalRow?.length! > 0,
        }"
      ></tr>
    </table>
    @if (widget.tableData?.rows?.length === 0) {
      <div
        class="wsm-alert-message"
        [ngClass]="isMobile ? 'mx-3 mt-3' : 'mt-3'"
      >
        <strong>{{ "reporting.widgets.no_data_found" | translate }}</strong>
      </div>
    }
  </div>
</section>

<!-- Mobile Table -->
@if (isMobile && widget?.tableData?.rows?.length !== 0 && !largeMobile) {
  <section class="mb-3">
    <app-card-carousel
      [cardData]="{
        labels: (widget?.tableData)!.columns,
        elements: (widget?.tableData)!.rows,
      }"
      [indicatorsOn]="true"
      [indicatorNumber]="5"
      [isPrint]="isPrint"
      [usePagination]="true"
      [totalRecords]="widget.totalRecords!"
      [themes]="{theme: theme, reportTheme: reportTheme, pageTheme: pageTheme}"
      [widget]="widget"
      (recordLimitReached)="onRecordLimitReached()"
    >
    </app-card-carousel>
  </section>
}
