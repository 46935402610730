import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {MatDialogConfig} from '@angular/material/dialog';
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  Observable,
  of,
  map,
  Subscription,
  fromEvent,
} from 'rxjs';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {
  ApiService,
  ClientService,
  DialogService,
  MobileService,
  NotificationService,
} from 'src/app/core/services';
import {ManageCredentialsDialogComponent} from 'src/app/shared/dialogs';
import {CustomError, ServiceAccount} from 'src/app/shared/models';
import {PlanType} from '../../../../enums';
import {wrapGrid} from 'animate-css-grid';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {MobileAccountSelectorComponent} from '../../../../mobile';
import {GetStartedStoreSignal} from '../../../../../modules/get-started/state';
import {canUser, setCharacterEscape} from '../../../../helpers';
import {NgClass, SlicePipe} from '@angular/common';
import {ServiceAccountCardComponent} from '../service-account-card/service-account-card.component';
import {LoadingComponent} from '../../../loading/loading.component';
import {AccountSelectorComponent} from '../../account-selector/account-selector.component';
import {AlphanumericDirective} from '../../../../directives/alphanumeric.directive';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';

@Component({
  selector: 'app-service-account-cards',
  templateUrl: './service-account-cards.component.html',
  styleUrls: ['./service-account-cards.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ServiceAccountCardComponent,
    LoadingComponent,
    AccountSelectorComponent,
    AlphanumericDirective,
    InfiniteScrollDirective,
    SlicePipe,
    TranslateModule,
  ],
})
export class ServiceAccountCardsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Inputs / Outputs
  @Input() accountCards: ServiceAccount[] | undefined;
  @Input() companyId: string | undefined;
  @Input() userPlanType: PlanType;
  @Input() isFromStarted = false;

  // Store
  public getStartedStore = inject(GetStartedStoreSignal);

  // Children
  @ViewChild('inputSearch', {static: false}) inputSearch: ElementRef;
  @ViewChild('accountGrid', {static: false}) accountGrid: ElementRef;

  // Service Account Autocomplete
  accountsLoading = false;
  accountsNoResults: boolean;
  accounts: ServiceAccount[];
  toHighlight: string;
  filteredAccounts;
  accountList: ServiceAccount[] = [];
  scrollLimit = 20;

  // Properties
  searchAccount: UntypedFormControl = new UntypedFormControl('');
  // getStartedSate: GetStartedState;
  subs: Subscription = new Subscription();

  // State
  isLoading: boolean;
  isEditMode = false;
  panelOpen = false;
  isMobile: boolean;
  isIndividual = false;

  constructor(
    private translate: TranslateService,
    private bottomSheet: MatBottomSheet,
    private apiService: ApiService,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private mobileService: MobileService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.isIndividual = canUser('isIndividual', this.userPlanType);

    if (this.isIndividual) {
      this.getServiceAccounts('');
    } else {
      this.setUpAutocomplete();
    }

    // Listening Subs
    this.subs.add(
      this.mobileService.getMobileBehavior().subscribe((res: boolean): void => {
        this.isMobile = res;
      })
    );

    this.subs.add(
      this.mobileService.scroll$.subscribe((): void => {
        if (this.isMobile) {
          this.scrollLimit += 10;
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.isIndividual) {
      fromEvent(this.inputSearch?.nativeElement, 'keyup')
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap((): void => {
            const search: string = this.inputSearch?.nativeElement?.value
              ? this.inputSearch.nativeElement.value.trim()
              : '';
            this.getServiceAccounts(search);
          })
        )
        .subscribe();
    } else {
      const grid = this.accountGrid?.nativeElement;
      if (grid) {
        wrapGrid(grid);
      }
    }
  }

  openManageCredentialDialog(): void {
    const config: MatDialogConfig = {
      data: {
        userPlanType: this.userPlanType,
        isMobile: this.isMobile,
      },
      minWidth: this.isMobile ? '100vw' : '65vw',
      minHeight: this.isMobile ? '100vw' : '65vh',
      height: this.isMobile ? '100vh' : '',
    };

    this.dialogService.openDialog(ManageCredentialsDialogComponent, config);
  }

  setUpAutocomplete(): void {
    // Initialize autocomplete for owner and industry
    this.filteredAccounts = this.searchAccount?.valueChanges.pipe(
      tap(() => {
        this.accountsLoading = true;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val): Observable<any> => {
        if (val.trim() === '' || val?.trim()?.length < 4) {
          this.accountsNoResults = false;
          this.accountsLoading = false;
          return of([]);
        }
        return this.filter(val);
      })
    );

    this.subs.add(this.filteredAccounts);
  }

  filter(val: string): Observable<any> {
    this.toHighlight = val;
    // Call the service which makes the http-request
    this.accountsLoading = false;
    return this.apiService.getServiceAccountsTypeAhead(val).pipe(
      map((response) => {
        this.accountsNoResults = response.length === 0;
        return response;
      })
    );
  }

  removeServiceAccount(accountInfo): void {
    if (this.isEditMode || this.isIndividual) {
      const found = this.accountCards?.find(
        (acc: ServiceAccount) => acc.accountId === accountInfo.id
      );
      found && this.accountCards?.splice(this.accountCards.indexOf(found), 1);
      this.accountCards = [...(this.accountCards as Array<ServiceAccount>)];

      if (this.isIndividual) {
        this.saveAccounts();
      }
    }
  }

  saveAccounts(): void {
    const accountArray: string[] = [];
    this.accountCards?.forEach((account) => {
      account._id && accountArray.push(account._id);
    });

    this.isLoading = true;
    const clientInfo = {
      serviceAccounts: accountArray,
    };

    this.companyId &&
      this.clientService
        .updateClientDetails(this.companyId, clientInfo)
        .subscribe({
          next: (res): void => {
            if (this.isIndividual) {
              this.accountCards = res.serviceAccounts || [];
              this.checkSelectedAccounts();
            } else {
              this.accountCards = res.serviceAccounts;
            }

            if (this.isFromStarted) {
              const hasAccountCards =
                this.accountCards && this.accountCards.length > 0;
              const validSteps: number[] = this.getStartedStore
                .validSteps()
                .filter((step: number): boolean => step !== 2);

              if (hasAccountCards) {
                validSteps.push(2);
              }

              this.getStartedStore.updateStartedMethod({validSteps});
            }
          },
          error: (err: CustomError): void => {
            this.notificationService.error(err.message, 5000);
            this.isLoading = false;
          },
          complete: (): void => {
            this.isLoading = false;
            this.notificationService.success(
              this.translate.instant('notifications.account_updated'),
              5000
            );
          },
        });
  }

  getServiceAccounts(search: string): void {
    this.isLoading = true;
    const params: HttpParams = new HttpParams({
      fromObject: {
        'filter[]': [
          `name contains ${setCharacterEscape(search.trim() || '')}`,
        ],
      },
    });

    this.accountList = [];
    this.subs.add(
      this.apiService.getServiceAccounts(params).subscribe({
        next: (res): void => {
          this.accountList = [...res];
          this.checkSelectedAccounts();
          this.isLoading = false;
        },
        error: (err): void => {
          this.isLoading = false;
          this.notificationService.error(err?.message, 5000);
        },
        complete: (): void => {
          this.isLoading = false;
        },
      })
    );
  }

  onAccountSelected(event: ServiceAccount): void {
    const selectedAccount: ServiceAccount = event;
    if (
      this.accountCards?.some(
        (sa: ServiceAccount) => sa?._id === selectedAccount._id
      )
    ) {
      this.notificationService.warning(
        this.translate.instant('notifications.account_exists'),
        5000
      );
    } else {
      if (!this.accountCards) {
        this.accountCards = [];
      }
      this.accountCards.push(event);
      this.saveAccounts();
    }
  }

  checkSelectedAccounts(): void {
    if (
      this.accountCards &&
      this.accountCards?.length > 0 &&
      this.accountList?.length > 0
    ) {
      this.accountList?.forEach((a: ServiceAccount, i: number): void => {
        a.selected = this.accountCards?.some(
          (sa: ServiceAccount): boolean => sa._id === a._id
        );

        if (
          this.accountCards?.some(
            (sa: ServiceAccount): boolean => sa._id === a._id
          )
        ) {
          this.accountList?.splice(i, 1);
          this.accountList?.unshift(a);
        }
      });
    }
  }

  onScroll(): void {
    if (!this.isMobile || this.isFromStarted) {
      this.scrollLimit += 10;
    }
  }

  onEditAccounts(): void {
    if (this.isMobile) {
      const config: MatBottomSheetConfig = {
        data: {selectedAccounts: this.accountCards},
        panelClass: 'custom-sheet',
      };

      const bottomSheetRef: MatBottomSheetRef<MobileAccountSelectorComponent> =
        this.bottomSheet.open(MobileAccountSelectorComponent, config);
      bottomSheetRef.afterDismissed().subscribe((data) => {
        if (data?.action === 'save') {
          this.saveAccounts();
        }
      });
    } else {
      if (!this.isEditMode) {
        this.isEditMode = true;
        this.panelOpen = true;
      }
    }
  }

  onPanelClosed(action: string): void {
    if (action === 'save') {
      this.saveAccounts();
    }
    this.panelOpen = false;
  }

  // Destroy Account Selector Component after slide out animation.
  onTransitionEnd(event: TransitionEvent, elementId: string): void {
    if ((event.target as HTMLElement).id !== elementId) {
      return;
    }
    if (this.panelOpen) {
      return;
    }
    this.isEditMode = false;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
