import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

import {
  EmailSchedule,
  Report,
  SfReportToTask,
  ShareEmailPayload,
} from '../../models';
import {C, COMMA, ENTER} from '@angular/cdk/keycodes';
import {
  MatChipInputEvent,
  MatChipGrid,
  MatChipOption,
  MatChipRemove,
  MatChipInput,
} from '@angular/material/chips';
import {
  DialogService,
  ImportService,
  NotificationService,
  ReportService,
} from 'src/app/core/services';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {
  checkIfArray,
  getDaysInMonth,
  getFrequencies,
  getHours,
  getWeekDays,
} from '../../helpers';
import {
  Alignment,
  Bold,
  ClassicEditor,
  Essentials,
  Italic,
  Link,
  List,
  ListProperties,
  Paragraph,
  Underline,
  Undo,
} from 'ckeditor5';
import {MatIcon} from '@angular/material/icon';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {LowercaseDirective} from '../../directives/lowercase.directive';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {NgClass} from '@angular/common';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';

@Component({
  selector: 'app-share-report-dialog',
  templateUrl: './share-report-dialog.component.html',
  styleUrls: ['./share-report-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    MatChipGrid,
    MatChipOption,
    MatIcon,
    MatChipRemove,
    MatChipInput,
    NgxIntlTelInputModule,
    LowercaseDirective,
    CKEditorModule,
    NgClass,
    MatSelect,
    MatOption,
    MatDialogActions,
    TranslateModule,
  ],
})
export class ShareReportDialogComponent implements OnInit, OnDestroy {
  // Form
  shareReportForm: UntypedFormGroup;

  // CKEditor properties
  public Editor = ClassicEditor;
  editorConfig = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'link',
      'alignment',
      'bulletedList',
      'numberedList',
    ],
    plugins: [
      Essentials,
      Bold,
      Italic,
      Paragraph,
      Underline,
      Undo,
      Alignment,
      Link,
      List,
      ListProperties,
    ],
  };

  // State Variables
  isLoading: boolean;

  // Properties
  subs: Subscription = new Subscription();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  removable = true;
  emails: string[] = [];
  ccEmails: string[] = [];
  sfData: SfReportToTask;
  report: Report;
  orgId: string;
  hours: string[] = getHours();
  frequencies = getFrequencies();
  weekDays = getWeekDays();

  date = new Date();
  monthDays: number[] = getDaysInMonth(
    this.date.getFullYear(),
    this.date.getMonth() + 1
  );

  constructor(
    public dialogRef: MatDialogRef<ShareReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private importService: ImportService,
    private translate: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe((): void => {
          this.dialogService.closeDialog(this.dialogRef);
        })
      );
    }
    this.report = this.data.report;

    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      this.orgId = JSON.parse(currentUser).organization.id;
    }

    this.buildShareReportForm();

    this.subs.add(
      this.shareReportForm
        .get('frequency')
        ?.valueChanges.subscribe((value): void => {
          switch (value) {
            case 'Daily':
              this.shareReportForm
                .get('sendTime')
                ?.setValidators([Validators.required]);
              this.shareReportForm.get('dayOfTheWeek')?.clearValidators();
              this.shareReportForm.get('dayOfTheMonth')?.clearValidators();
              break;
            case 'Weekly':
              this.shareReportForm
                .get('sendTime')
                ?.setValidators([Validators.required]);
              this.shareReportForm
                .get('dayOfTheWeek')
                ?.setValidators([Validators.required]);
              this.shareReportForm.get('dayOfTheMonth')?.clearValidators();
              break;
            case 'Monthly':
              this.shareReportForm
                .get('sendTime')
                ?.setValidators([Validators.required]);
              this.shareReportForm
                .get('dayOfTheMonth')
                ?.setValidators([Validators.required]);
              this.shareReportForm.get('dayOfTheWeek')?.clearValidators();
              break;
            default:
              this.shareReportForm.get('sendTime')?.clearValidators();
              this.shareReportForm.get('dayOfTheWeek')?.clearValidators();
              this.shareReportForm.get('dayOfTheMonth')?.clearValidators();
              break;
          }

          this.shareReportForm.get('sendTime')?.updateValueAndValidity();
          this.shareReportForm.get('dayOfTheWeek')?.updateValueAndValidity();
          this.shareReportForm.get('dayOfTheMonth')?.updateValueAndValidity();
        })
    );
  }

  buildShareReportForm(): void {
    this.shareReportForm = new UntypedFormGroup({
      email: new UntypedFormControl(
        '',
        this.data.type === 'email'
          ? [Validators.required, Validators.email]
          : null
      ),
      carbonCopy: new UntypedFormControl(
        '',
        this.data.type === 'email' ? [Validators.email] : null
      ),
      subject: new UntypedFormControl(
        '',
        this.data.type === 'email' ? Validators.required : null
      ),
      content: new UntypedFormControl(
        '',
        this.data.type === 'email' ? Validators.required : null
      ),
      frequency: new UntypedFormControl(
        {
          value: this.data.type === 'salesforce' ? 'Monthly' : '',
          disabled: this.data.type === 'salesforce',
        },
        this.data.type === 'email' ? Validators.required : null
      ),
      sendTime: new UntypedFormControl(
        '',
        this.data.type === 'salesforce' ? Validators.required : null
      ),
      dayOfTheWeek: new UntypedFormControl(''),
      dayOfTheMonth: new UntypedFormControl(
        '',
        this.data.type === 'salesforce' ? Validators.required : null
      ),
    });

    if (this.data.type === 'salesforce') {
      if (this.data.sfData) {
        this.sfData = this.data.sfData;
        let sendTime: number | string =
          this.sfData.executionSchedule.selectedExecutionHour;

        if (sendTime >= 12) {
          sendTime = sendTime === 12 ? `${sendTime}PM` : `${sendTime - 12}PM`;
        } else {
          sendTime = sendTime === 0 ? '12AM' : `${sendTime}AM`;
        }

        this.shareReportForm.patchValue({
          sendTime: sendTime || '',
          dayOfTheMonth:
            this.sfData.executionSchedule.selectedExecutionMonthDay,
        });
      }
    }

    if (this.data.type === 'email') {
      if (this.data.report.schedule) {
        this.emails =
          this.report.schedule?.emailAddresses.to &&
          checkIfArray(this.report?.schedule?.emailAddresses?.to)
            ? [...this.report.schedule.emailAddresses.to]
            : [];
        this.ccEmails =
          this.report.schedule?.emailAddresses.cc &&
          checkIfArray(this.report?.schedule?.emailAddresses?.cc)
            ? [...this.report.schedule.emailAddresses.cc]
            : [];

        let sendTime = '';
        const scheduleSendTime = this.report.schedule?.schedule.sendTime;

        if (scheduleSendTime !== undefined) {
          if (scheduleSendTime >= 12) {
            sendTime =
              scheduleSendTime === 12
                ? `${scheduleSendTime}PM`
                : `${scheduleSendTime - 12}PM`;
          } else {
            sendTime =
              scheduleSendTime === 0 ? '12AM' : `${scheduleSendTime}AM`;
          }
        }

        this.shareReportForm.patchValue({
          subject: this.report?.schedule?.subject
            ? this.report?.schedule?.subject
            : '',
          content: this.report?.schedule?.description
            ? this.report.schedule.description
            : '',
          frequency: this.report?.schedule?.schedule.frequency
            ? this.report.schedule.schedule.frequency
            : '',
          sendTime,
          dayOfTheWeek: this.report.schedule?.schedule?.dayOfTheWeek
            ? this.report.schedule.schedule.dayOfTheWeek
            : '',
          dayOfTheMonth: this.report?.schedule?.schedule.dayOfTheMonth
            ? this.report.schedule.schedule.dayOfTheMonth
            : '',
        });

        this.updateEmailValidators();
      }
    }
  }

  // MAT CHIP METHODS
  add(event: MatChipInputEvent, type): void {
    if (type === 'email' && this.shareReportForm.get('email')?.invalid) {
      this.shareReportForm.get('email')?.markAsTouched();
      return;
    }

    if (type === 'cc' && this.shareReportForm.get('carbonCopy')?.invalid) {
      this.shareReportForm.get('carbonCopy')?.markAsTouched();
      return;
    }

    const value = (event.value || '').trim();
    const isNotRepeated = !this.checkRepeatedEmail(
      type === 'email' ? this.emails : this.ccEmails,
      value
    );
    if (value && isNotRepeated) {
      switch (type) {
        case 'email':
          this.emails.push(value);
          break;
        case 'cc':
          this.ccEmails.push(value);
          break;
      }
      // Clear the input value
      this.shareReportForm
        .get(type === 'email' ? 'email' : 'carbonCopy')
        ?.setValue(null);
      this.updateEmailValidators();
    } else if (!isNotRepeated) {
      this.translate
        .get('notifications.email_exists')
        .subscribe((res: string) => {
          this.notificationService.error(res, 5000);
        });
    }
  }

  checkRepeatedEmail(emails: string[], currentEmail: string): boolean {
    return emails.includes(currentEmail);
  }

  remove(email: string, type: string): void {
    const index =
      type === 'email'
        ? this.emails.indexOf(email)
        : this.ccEmails.indexOf(email);
    if (index >= 0) {
      if (type === 'email') {
        this.emails.splice(index, 1);
      } else {
        this.ccEmails.splice(index, 1);
      }
    }
    this.updateEmailValidators();
  }

  updateEmailValidators(): void {
    if (this.emails?.length > 0) {
      this.shareReportForm.get('email')?.clearValidators();
      this.shareReportForm.get('email')?.setValidators([Validators.email]);
      this.shareReportForm.get('email')?.updateValueAndValidity();
    } else {
      this.shareReportForm
        .get('email')
        ?.setValidators([Validators.required, Validators.email]);
      this.shareReportForm.get('email')?.updateValueAndValidity();
    }

    if (this.ccEmails?.length > 0) {
      this.shareReportForm.get('carbonCopy')?.clearValidators();
      this.shareReportForm.get('carbonCopy')?.setValidators([Validators.email]);
      this.shareReportForm.get('carbonCopy')?.updateValueAndValidity();
    } else {
      this.shareReportForm.get('carbonCopy')?.setValidators([Validators.email]);
      this.shareReportForm.get('carbonCopy')?.updateValueAndValidity();
    }
  }

  onSubmit(status?: string): void {
    if (this.shareReportForm.invalid) {
      this.shareReportForm.markAsDirty();
      this.shareReportForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    const frequency = this.shareReportForm.get('frequency')?.value;
    let sendTime = this.shareReportForm.get('sendTime')?.value;
    let payload = {};

    if (sendTime.endsWith('AM')) {
      sendTime =
        parseInt(sendTime.substring(0, sendTime.length - 2), 10) === 12
          ? 0
          : parseInt(sendTime.substring(0, sendTime.length - 2), 10);
    } else {
      sendTime =
        parseInt(sendTime.substring(0, sendTime.length - 2), 10) === 12
          ? 12
          : parseInt(sendTime.substring(0, sendTime.length - 2), 10) + 12;
    }

    const schedule: EmailSchedule = {
      frequency,
      sendTime,
    };

    switch (frequency) {
      case 'Weekly':
        schedule.dayOfTheWeek = this.shareReportForm.get('dayOfTheWeek')?.value;
        break;
      case 'Monthly':
        schedule.dayOfTheMonth =
          this.shareReportForm.get('dayOfTheMonth')?.value;
        break;
    }

    if (this.data.type === 'email') {
      payload = {
        to: this.emails,
        cc: this.ccEmails,
        subject: this.shareReportForm.get('subject')?.value?.trim(),
        description: this.shareReportForm.get('content')?.value,
        schedule,
      };

      this.reportService
        .saveShareEmail(payload as ShareEmailPayload, this.data.report.id)
        .subscribe({
          next: (res): void => {
            this.report.schedule = res.schedule;
          },
          error: (err): void => {
            this.isLoading = false;
            this.notificationService.error(err.error.error.message, 5000);
          },
          complete: (): void => {
            this.isLoading = false;
            const toast: string =
              schedule.frequency === 'Now'
                ? 'The email will be sent shortly.'
                : 'Email schedule saved.';
            this.notificationService.success(toast, 5000);
            this.dialogService.closeDialog(this.dialogRef, this.report);
          },
        });
    } else {
      payload = {
        organizationId: this.orgId,
        jobExecutionMonthDay: schedule.dayOfTheMonth,
        jobExecutionHour: sendTime,
        executionMode: 'RUN_MONTHLY',
        type: 'salesforce-report-to-task',
        entity: 'report',
        status,
        reportId: this.report.id,
      };

      if (!this.report.syncToolId) {
        this.importService.shareToCrm(payload).subscribe({
          next: (res) => {
            this.report.syncToolId = res.id;
          },
          error: (err) => {
            this.isLoading = false;
            this.notificationService.error(err.error.error.message, 5000);
          },
          complete: () => {
            this.isLoading = false;
            this.dialogService.closeDialog(this.dialogRef, this.report);
            this.translate
              .get('notifications.schedule_enabled')
              .subscribe((res: string) => {
                this.notificationService.success(res, 5000);
              });
          },
        });
      } else {
        this.importService
          .updateSOQLTool(payload, this.report.syncToolId)
          .subscribe({
            error: (err) => {
              this.isLoading = false;
              this.notificationService.error(err.error.error.message, 5000);
            },
            complete: () => {
              this.isLoading = false;
              this.dialogService.closeDialog(this.dialogRef);
              this.translate
                .get('notifications.schedule_updated')
                .subscribe((res: string) => {
                  this.notificationService.success(res, 5000);
                });
            },
          });
      }
    }
  }

  onClose(): void {
    this.dialogService.closeDialog(this.dialogRef);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
