import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {AuthenticationService} from '../../../../core/services';
import {canUser, getSidenavLinks, HelperService} from '../../../helpers';
import {SidenavLink} from '../../../models';
import {PlanType} from '../../../enums';
import {RouterLinkActive, RouterLink} from '@angular/router';
import {BillingPlanStore} from 'src/app/shared/state';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  standalone: true,
  imports: [RouterLinkActive, RouterLink],
})
export class TabBarComponent implements OnInit, OnDestroy {
  // Store
  billingPlanStore = inject(BillingPlanStore);

  // Properties
  sidenavLinks: Array<SidenavLink> = getSidenavLinks();
  userPlan: PlanType = this.helperService.getUserPlan();
  subs: Subscription = new Subscription();

  constructor(
    public authService: AuthenticationService,
    private helperService: HelperService
  ) {
    const watchPlan = toObservable(this.billingPlanStore.getPlan);
    this.subs.add(watchPlan.subscribe(() => this.updateLinksPlan()));
  }

  ngOnInit(): void {
    this.updateLinksPlan();
  }

  private updateLinksPlan(): void {
    if (canUser('isIndividual', this.userPlan)) {
      this.sidenavLinks = this.sidenavLinks.filter(
        (link: SidenavLink): boolean => link.path != 'clients'
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
