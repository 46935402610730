<div class="d-flex gap-2">
  <button (click)="imageInput.click()" class="wsm-btn wsm-btn-primary">
    Upload Image
  </button>
  @if (imageName) {
    <button
      (click)="$event.stopPropagation(); removeImage()"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
    >
      <i class="fa-regular fa-trash"></i>
    </button>
  }
</div>

<input
  #imageInput
  [hidden]="true"
  type="file"
  accept="image/*"
  (change)="handleFileInput($event)"
/>
