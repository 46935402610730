import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {
  BillingPlan,
  ClientData,
  CustomError,
  IClientForm,
  Industry,
  OrganizationCurrency,
} from '../../models';
import {
  ClientService,
  NotificationService,
  TrackingService,
} from '../../../core/services';
import {CurrencyService} from '../../helpers/currency.service';
import {GetStartedStoreSignal} from '../../../modules/get-started/state';
import {IndustryGroup} from 'src/app/modules/companies/components/company-info/company-info.component';
import {NgClass, AsyncPipe} from '@angular/common';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatSelect, MatSelectTrigger} from '@angular/material/select';
import {MatOption, MatOptgroup} from '@angular/material/core';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {BehaviorInputDirective} from '../../directives/behavior-input.directive';
import {HighlightTextPipe} from '../../pipes/highlight.pipe';
import {AsPipe} from '../../pipes/as-type.pipe';
import {BillingPlanStore} from '../../state';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatSelect,
    MatOption,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOptgroup,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    BehaviorInputDirective,
    MatSelectTrigger,
    AsyncPipe,
    TranslateModule,
    HighlightTextPipe,
    AsPipe,
  ],
})
export class CreateClientComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Input() isFromDialog = true;
  @Input() isFromStarted = false;
  @Input() creatingClient: Observable<void>;
  @Input() clientFromCache: IClientForm | undefined;
  @Output() clientCreated: EventEmitter<string> = new EventEmitter<string>();
  @Output() validClientForm = new EventEmitter<boolean>();
  @Output() clientFormChanged = new EventEmitter<IClientForm>();

  // Store
  private billingPlanStore = inject(BillingPlanStore);
  public getStartedStore = inject(GetStartedStoreSignal);

  // Properties
  newCompanyForm: UntypedFormGroup;
  currencyList: OrganizationCurrency[];
  marketingObjectives: {value: string; desc: string; ngxId: string}[];
  fieldTypes: string[] = ['industry', 'parentCompany'];
  filteredOptions: Observable<Industry[] | ClientData[] | IndustryGroup[]>[] =
    [];
  ClientDataModel!: ClientData;
  IndustryModel!: Industry;
  IndustryGroupModel!: IndustryGroup;
  toHighlight: string;
  industries: Industry[];
  selectedIndustry: Industry;
  selectedIndustryFullName: string;
  selectedParent: ClientData;
  company: ClientData;
  availableClients: string;
  billingInfo: BillingPlan | null;
  // getStartedSate: GetStartedState;
  subs: Subscription = new Subscription();

  // State
  isLoading: boolean;
  industriesLoading: boolean;
  parentLoading: boolean;
  industriesNoResults: boolean;
  parentNoResults: boolean;

  constructor(
    private translate: TranslateService,
    public clientService: ClientService,
    private currencyService: CurrencyService,
    private notificationService: NotificationService,
    private trackingService: TrackingService
  ) {
    const watchPlan = toObservable(this.billingPlanStore.getPlan);
    this.subs.add(
      watchPlan.subscribe((res): void => {
        this.billingInfo = res;

        if (this.billingInfo && this.billingInfo?.activeClients >= 0) {
          this.availableClients = `${
            (this.billingInfo?.clientLimit as number) -
            this.billingInfo?.activeClients
          }/${this.billingInfo?.clientLimit} `;
        }
      })
    );
  }

  ngOnInit(): void {
    this.billingInfo = this.billingPlanStore?.getPlan();
    this.currencyList = this.currencyService.getCurrencies();
    this.marketingObjectives = this.clientService.getMarketingObjectives();

    this.initializeForm();
    this.setUpAutocompletes();
    this.listeningSubs();

    if (this.clientFromCache) {
      this.newCompanyForm.patchValue({
        ...this.clientFromCache,
      });
    }
  }

  private initializeForm(): void {
    this.newCompanyForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      website: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(
          /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i
        ),
      ]),
      externalId: new UntypedFormControl(''),
      currency: new UntypedFormControl('USD', Validators.required),
      parentCompany: new UntypedFormControl(''),
      industry: new UntypedFormControl('', Validators.required),
      lifecycle: new UntypedFormControl('CUSTOMER', Validators.required),
      marketingObjective: new UntypedFormControl([]),
    });

    this.subs.add(
      this.newCompanyForm.valueChanges.subscribe((value): void => {
        this.validClientForm.emit(this.newCompanyForm.valid);
        this.clientFormChanged.emit(value);
      })
    );
  }

  private listeningSubs(): void {
    this.subs.add(
      this.creatingClient?.subscribe((): void => {
        this.onSubmit();
      })
    );
  }

  private setUpAutocompletes(): void {
    // Initialize autocomplete for owner and industry
    this.fieldTypes.forEach((fieldType: string, index: number): void => {
      this.filteredOptions[index] = this.newCompanyForm
        .get(fieldType)
        ?.valueChanges.pipe(
          tap((): void => {
            switch (fieldType) {
              case 'industry':
                this.industriesLoading = true;
                break;
              case 'parentCompany':
                this.parentLoading = true;
                break;
            }
          }),
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(
            (
              val: any
            ): Observable<Industry[] | ClientData[] | IndustryGroup[]> => {
              if (!val.trim()) {
                this.industriesNoResults = false;
                this.industriesLoading = false;
                this.parentNoResults = false;
                this.parentLoading = false;
                return of([]);
              }
              return this.filter(val, fieldType) || of([]);
            }
          )
        ) as Observable<Industry[] | ClientData[] | IndustryGroup[]>;
    });
  }

  private filter(
    val: string,
    type: string
  ): Observable<Industry[] | ClientData[] | IndustryGroup[]> | undefined {
    this.toHighlight = val;
    const industryGroup: IndustryGroup[] = [];
    // Call the service which makes the http-request
    switch (type) {
      case 'parentCompany':
        this.parentLoading = false;
        return this.clientService.getClientsTypeAhead(val).pipe(
          map((response) => {
            this.parentNoResults = response.clients?.length === 0;
            return response.clients.filter((option) =>
              option.name.toLowerCase().includes(val.toLocaleLowerCase())
            );
          })
        );
      case 'industry':
        this.industriesLoading = false;
        return this.clientService.getIndustriesTypeAhead(val).pipe(
          map((industryArray: Industry[]) => {
            this.industries = industryArray;
            this.industriesNoResults = industryArray?.length === 0;
            industryArray.forEach((option: Industry): void => {
              let newObject: IndustryGroup;
              if (
                !industryGroup.some(
                  (item: any): boolean => item.category === option.category
                )
              ) {
                newObject = {
                  category: option.category as string,
                  subcategories: [option.subcategory as string],
                };
                industryGroup.push(newObject);
              } else {
                const categoryIndex = industryGroup.indexOf(
                  industryGroup.find(
                    (item) => item.category === option.category
                  ) as IndustryGroup
                );
                industryGroup[categoryIndex].subcategories.push(
                  option.subcategory as string
                );
              }
            });

            return industryGroup
              .map((group) => ({
                category: group.category,
                subcategories: group.subcategories,
              }))
              .filter((group) => group.subcategories?.length > 0);
          })
        );
    }
  }

  public onIndustrySelect(event: MatAutocompleteSelectedEvent): void {
    this.selectedIndustry = this.industries.find((industry: Industry) => {
      return (
        industry.category === event.option.value.category &&
        industry.subcategory === event.option.value.subcategory
      );
    }) as Industry;
    this.selectedIndustryFullName = `${this.selectedIndustry.category} | ${this.selectedIndustry.subcategory}`;
    this.newCompanyForm
      .get('industry')
      ?.setValue(this.selectedIndustryFullName);
  }

  public onParentCompanySelect(event: MatAutocompleteSelectedEvent): void {
    this.selectedParent = event.option.value;
    this.newCompanyForm
      .get('parentCompany')
      ?.setValue(this.selectedParent.name);
  }

  onSubmit(): void {
    if (this.newCompanyForm.invalid) {
      Object.values(this.newCompanyForm.controls).forEach((control): void => {
        const controlKey = Object.keys(this.newCompanyForm.controls).find(
          (name: string): boolean =>
            control === this.newCompanyForm.controls[name]
        );
        if (!control.value) {
          control.markAsDirty();
          control.markAsTouched();
        } else if (controlKey === 'industry' && !this.selectedIndustry) {
          control.setValue('');
        }
      });
    } else {
      if (!this.selectedIndustry) {
        this.newCompanyForm.get('industry')?.setValue('');
        this.onSubmit();
      } else {
        this.createClient();
      }
    }
  }

  private createClient(): void {
    this.isLoading = true;
    const newCompany = {
      name: this.newCompanyForm.get('name')?.value?.trim(),
      website: this.newCompanyForm.get('website')?.value,
      industry: this.selectedIndustry._id,
      marketingObjective: this.newCompanyForm.get('marketingObjective')?.value,
      currency: this.newCompanyForm.get('currency')?.value,
      externalId: this.newCompanyForm.get('externalId')?.value,
      parentId:
        this.newCompanyForm.get('parentCompany')?.value?.trim() &&
        this.selectedParent
          ? this.selectedParent._id
          : null,
      businessType: null,
      parentName: null,
      shareWithTeams: false,
      lifecycle: this.newCompanyForm.get('lifecycle')?.value,
      status: 'ACTIVE',
    };

    let allowCreation = true;
    if (!this.isFromDialog && this.getStartedStore?.companyId()) {
      allowCreation = false;
    }

    if (allowCreation) {
      this.clientService.addNewClient(newCompany).subscribe({
        next: (res: ClientData): void => {
          this.company = res;
        },
        error: (err: CustomError): void => {
          this.notificationService.error(err?.message || '', 5000);
          this.isLoading = false;
        },
        complete: (): void => {
          this.trackingService.trackEvent('Create Client');
          this.isLoading = false;
          if (!this.isFromStarted) {
            this.notificationService.success(
              this.translate.instant('notifications.company_created'),
              5000
            );
          }
          this.clientCreated.emit(this.company._id);
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
