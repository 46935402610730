import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {
  HelperService,
  isValidDate,
  setWidgetFontSize,
} from 'src/app/shared/helpers';
import {ChartData, ScopeField, Widget} from 'src/app/shared/models';
import {ReportService} from '../../../../../core/services';
import {Subscription} from 'rxjs';
import {FusionChartsModule} from 'angular-fusioncharts';
import bar2d from 'fusioncharts/fusioncharts.charts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import FusionCharts from 'fusioncharts';
import '../../../../../../assets/libs/fusioncharts/custom-themes/wsm_theme.js';
import {formatDate, formatPercent} from '@angular/common';

@Component({
  selector: 'app-biggest-changes',
  standalone: true,
  templateUrl: './biggest-changes.component.html',
  styleUrls: ['./biggest-changes.component.scss'],
  imports: [FusionChartsModule],
})
export class BiggestChangesComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: FormGroup;

  // State
  private initialized = false;
  public rendered = true;

  // Properties
  public chart: any;
  subs: Subscription = new Subscription();
  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private helperService: HelperService,
    private reportService: ReportService
  ) {
    // Use fcRoot function to inject FusionCharts library, and the modules you want to use
    FusionChartsModule.fcRoot(FusionCharts, bar2d, Widgets);
  }

  ngOnInit(): void {
    this.initialized = true;

    this.subs.add(
      this.reportService.presentationMode.subscribe((value: boolean): void => {
        setWidgetFontSize(value, this.widget);
        this.changeDetector.detectChanges();
      })
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // Check if chart was rendered...
      if (this.chart && !this.chart?.disposed) {
        // If the chart rendering fails, retry again...
        if (!this.chart?.hasRendered()) {
          this.rendered = false;
          this.changeDetector.detectChanges();
          this.rendered = true;
        }
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }

    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.handleChartInfo();
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue !== undefined
    ) {
      changes.widgetStyleForm.currentValue.type === 'style' &&
        this.handleChartInfo();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChartReady(event): void {
    this.chart = event.chart;
    this.updateChartDateValues();
    this.handleChartInfo();
  }

  // show delta or delta percent
  handleChartInfo(): void {
    const pivotData = this.widget.chartData?.data;

    this.widget.chartData!.data = pivotData.reduce((acc, current) => {
      const pivot = {
        ...current,
        value:
          this.widget?.valueToShow === 'percent'
            ? current.deltaPercent
            : current.delta,
        displayValue:
          this.widget?.valueToShow === 'percent'
            ? this.setDisplayValue(current.deltaPercent, 'percent')
            : this.setDisplayValue(current.delta, 'decimal'),
      };

      pivot.toolText = pivot?.displayValue;

      acc = [...acc, pivot];
      return acc;
    }, []);

    if (this.widget.chartData) {
      this.widget.chartData.chart.placeValuesInside =
        this.widget.placeValuesInside;
      this.widget.chartData.chart.yAxisName =
        !this.widget.hideXAxisName && this.widget.chartData?.metricName;
    }
  }

  private setDisplayValue(value: number, type: 'decimal' | 'percent'): string {
    switch (type) {
      case 'percent':
        return formatPercent(value, this.userLocale, '1.2') as string;
      default:
        if (this.widget.metrics && this.widget.metrics.length > 0) {
          const metric: ScopeField = this.widget.metrics[0];
          return this.helperService.updateMetricData(
            metric?.dataType || '',
            value,
            '',
            metric?.shortenNumbers
          );
        }
        return value.toString();
    }
  }

  private updateChartDateValues(): void {
    const dimensions: ScopeField[] | undefined = this.widget?.dimensions;
    const chartData: ChartData | undefined = this.widget?.chartData;

    if (dimensions && dimensions?.length > 0 && chartData?.data?.length > 0) {
      const dateData: ScopeField | undefined = dimensions.find(
        (item: ScopeField): boolean => item.name === 'Date'
      );

      if (
        dateData &&
        dateData.dateConfiguration &&
        dateData.dateConfiguration.dateGroup === 'dayHours' &&
        chartData?.data &&
        this.widget.widgetType === 'biggestchanges'
      ) {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        for (const item of chartData.data) {
          if (isValidDate(item?.label)) {
            item.label = formatDate(
              item?.label,
              dateData.dateConfiguration.dateFormat === 'dayHourStandard'
                ? 'h:mm a'
                : 'H:mm',
              this.userLocale,
              userTimeZone
            );
          }
        }
      }
    }
  }
}
