<div class="d-flex h-100">
  <div class="d-flex align-items-center justify-content-center w-100">
    <section class="container-fluid">
      <ng-lottie height="300px" [options]="options"> </ng-lottie>

      <div class="text-center">
        <h1>{{ title | translate }}</h1>
        <p>{{ message | translate }}</p>
        @if (buttonText) {
          <button (click)="toggleAction()" class="wsm-btn wsm-btn-primary mt-3">
            {{ buttonText | translate }}
          </button>
        }
      </div>
    </section>
  </div>
</div>
