@if (widget.rankingData?.keywords?.length! !== 0) {
  <div class="d-flex flex-column justify-content-between">
    <mat-chip-listbox>
      @for (keyword of widget.rankingData?.keywords; track keyword) {
        <mat-chip-option
          class="keyword-chip wsm-mat-chip"
          [satPopoverAnchor]="popRankingInfo"
          (mouseenter)="popRankingInfo.open()"
          (mouseleave)="popRankingInfo.close()"
          (click)="popRankingInfo.toggle()"
          [style.border-color]="keyword.borderColor"
        >
          <span
            [style.color]="
              theme?.bodyColor ? theme?.bodyColor : reportTheme?.bodyColor
            "
          >
            {{ keyword?.keyword }}</span
          >
          <sat-popover
            #popRankingInfo
            horizontalAlign="center"
            verticalAlign="above"
          >
            <app-popover-content
              [popoverData]="keyword.data"
              [pipeType]="widget.rankingData?.dataType!"
            />
          </sat-popover>
        </mat-chip-option>
      }
    </mat-chip-listbox>
  </div>
}

@if (widget.rankingData?.keywords?.length! === 0) {
  <div class="wsm-alert-message mt-3">
    <strong>{{ "reporting.widgets.no_data_found" | translate }}</strong>
  </div>
}
