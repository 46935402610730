<div class="info-wrapper">
  <div class="popover-arrow"></div>

  <div class="metric-header mb-2">
    <label class="mb-1 text-align-end">{{ popoverData.metricName }}</label>
  </div>

  <div class="d-flex justify-content-between align-items-center w-100">
    <label [ngClass]="{'mb-0': !popoverData.previousValue}">
      {{
        popoverData.previousValue
          ? ("reporting.widgets.popover.current_period" | translate)
          : popoverData.metricName
            ? popoverData.metricName
            : ("reporting.widgets.popover.current_value" | translate)
      }}
    </label>
    <label [ngClass]="{'mb-0': !popoverData.previousValue}">
      @switch (pipeType) {
        @case ("integer") {
          <span>{{ popoverData.value | number: "1.2-2" }}</span>
        }
        @case ("decimal") {
          <span>{{ popoverData.value | number: "1.2-2" }}</span>
        }
        @case ("percentage") {
          <span>{{ popoverData.value | percent: "1.2-2" }}</span>
        }
        @case ("currency") {
          <span>
            {{
              popoverData.value
                | currency
                  : (popoverData.currency ? popoverData.currency : "USD")
            }}
          </span>
        }
        @default {
          <span>--</span>
        }
      }
    </label>
  </div>

  @if (popoverData?.previousValue || popoverData?.previousValue === 0) {
    <div class="d-flex justify-content-between w-100">
      <label>{{
        "reporting.widgets.popover.previous_period" | translate
      }}</label>
      <label>
        @switch (pipeType) {
          @case ("integer") {
            <span>{{ popoverData.previousValue | number: "1.2-2" }}</span>
          }
          @case ("decimal") {
            <span>{{ popoverData.previousValue | number: "1.2-2" }}</span>
          }
          @case ("percentage") {
            <span>{{ popoverData.previousValue | percent: "1.2-2" }}</span>
          }
          @case ("currency") {
            <span>
              {{
                popoverData.previousValue
                  | currency
                    : (popoverData.currency ? popoverData.currency : "USD")
              }}
            </span>
          }
          @default {
            <span>--</span>
          }
        }
      </label>
    </div>
  }

  @if (popoverData.delta && popoverData.previousValue) {
    <div class="d-flex justify-content-between w-100">
      <label>{{ "reporting.widgets.popover.change" | translate }}</label>
      <label>
        @switch (pipeType) {
          @case ("integer") {
            <span>{{ popoverData.delta | number: "1.2-2" }}</span>
          }
          @case ("decimal") {
            <span>{{ popoverData.delta | number: "1.2-2" }}</span>
          }
          @case ("percentage") {
            <span>{{ popoverData.delta | percent: "1.2-2" }}</span>
          }
          @case ("currency") {
            <span>
              {{
                popoverData.delta
                  | currency
                    : (popoverData.currency ? popoverData.currency : "USD")
              }}
            </span>
          }
          @default {
            <span>--</span>
          }
        }
      </label>
    </div>
  }

  @if (popoverData?.deltaPercent || popoverData?.deltaPercent === 0) {
    <div
      class="metric total d-flex justify-content-end"
      [ngClass]="{
        'tooltip-positive': popoverData.isPositive && !popoverData.isNeutral,
        'tooltip-negative': !popoverData.isPositive && !popoverData.isNeutral,
        'tooltip-neutral': popoverData.isNeutral
      }"
    >
      @if (!popoverData.isNeutral) {
        <span
          class="me-1 fa-regular"
          [ngClass]="
            formatDiff(popoverData.deltaPercent | percent) > 0
              ? 'fa-arrow-up'
              : 'fa-arrow-down'
          "
        >
        </span>
      }
      <span>{{ popoverData.deltaPercent | percent: "1.2-2" }}</span>
    </div>
  }
</div>
