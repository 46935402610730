import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationCurrency} from '../models';

@Injectable({providedIn: 'root'})
export class CurrencyService {
  private currencies: OrganizationCurrency[] = [
    {translateId: '', code: 'AFN', name: 'Afghan Afghani'},
    {translateId: '', code: 'AED', name: 'United Arab Emirates Dirham'},
    {translateId: '', code: 'ALL', name: 'Albanian Lek'},
    {translateId: '', code: 'AMD', name: 'Armenian Dram'},
    {translateId: '', code: 'ANG', name: 'Netherlands Antillean Guilder'},
    {translateId: '', code: 'AOA', name: 'Angolan Kwanza'},
    {translateId: '', code: 'ARS', name: 'Argentine Peso'},
    {translateId: '', code: 'AUD', name: 'Australian Dollar'},
    {translateId: '', code: 'AWG', name: 'Aruban Florin'},
    {translateId: '', code: 'AZN', name: 'Azerbaijani Manat'},
    {translateId: '', code: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark'},
    {translateId: '', code: 'BBD', name: 'Barbadian Dollar'},
    {translateId: '', code: 'BDT', name: 'Bangladeshi Taka'},
    {translateId: '', code: 'BGN', name: 'Bulgarian Lev'},
    {translateId: '', code: 'BHD', name: 'Bahraini Dinar'},
    {translateId: '', code: 'BIF', name: 'Burundian Franc'},
    {translateId: '', code: 'BMD', name: 'Bermudan Dollar'},
    {translateId: '', code: 'BND', name: 'Brunei Dollar'},
    {translateId: '', code: 'BOB', name: 'Bolivian Boliviano'},
    {translateId: '', code: 'BRL', name: 'Brazilian Real'},
    {translateId: '', code: 'BSD', name: 'Bahamian Dollar'},
    {translateId: '', code: 'BTC', name: 'Bitcoin'},
    {translateId: '', code: 'BTN', name: 'Bhutanese Ngultrum'},
    {translateId: '', code: 'BWP', name: 'Botswanan Pula'},
    {translateId: '', code: 'BYN', name: 'Belarusian Ruble'},
    {translateId: '', code: 'BZD', name: 'Belize Dollar'},
    {translateId: '', code: 'CAD', name: 'Canadian Dollar'},
    {translateId: '', code: 'CDF', name: 'Congolese Franc'},
    {translateId: '', code: 'CHF', name: 'Swiss Franc'},
    {translateId: '', code: 'CLF', name: 'Chilean Unit of Account (UF)'},
    {translateId: '', code: 'CLP', name: 'Chilean Peso'},
    {translateId: '', code: 'CNH', name: 'Chinese Yuan (Offshore)'},
    {translateId: '', code: 'CNY', name: 'Chinese Yuan'},
    {translateId: '', code: 'COP', name: 'Colombian Peso'},
    {translateId: '', code: 'CRC', name: 'Costa Rican Colón'},
    {translateId: '', code: 'CUC', name: 'Cuban Convertible Peso'},
    {translateId: '', code: 'CUP', name: 'Cuban Peso'},
    {translateId: '', code: 'CVE', name: 'Cape Verdean Escudo'},
    {translateId: '', code: 'CZK', name: 'Czech Republic Koruna'},
    {translateId: '', code: 'DJF', name: 'Djiboutian Franc'},
    {translateId: '', code: 'DKK', name: 'Danish Krone'},
    {translateId: '', code: 'DOP', name: 'Dominican Peso'},
    {translateId: '', code: 'DZD', name: 'Algerian Dinar'},
    {translateId: '', code: 'EGP', name: 'Egyptian Pound'},
    {translateId: '', code: 'ERN', name: 'Eritrean Nakfa'},
    {translateId: '', code: 'ETB', name: 'Ethiopian Birr'},
    {translateId: '', code: 'EUR', name: 'Euro'},
    {translateId: '', code: 'FJD', name: 'Fijian Dollar'},
    {translateId: '', code: 'FKP', name: 'Falkland Islands Pound'},
    {translateId: '', code: 'GBP', name: 'British Pound Sterling'},
    {translateId: '', code: 'GEL', name: 'Georgian Lari'},
    {translateId: '', code: 'GGP', name: 'Guernsey Pound'},
    {translateId: '', code: 'GHS', name: 'Ghanaian Cedi'},
    {translateId: '', code: 'GIP', name: 'Gibraltar Pound'},
    {translateId: '', code: 'GMD', name: 'Gambian Dalasi'},
    {translateId: '', code: 'GNF', name: 'Guinean Franc'},
    {translateId: '', code: 'GTQ', name: 'Guatemalan Quetzal'},
    {translateId: '', code: 'GYD', name: 'Guyanaese Dollar'},
    {translateId: '', code: 'HKD', name: 'Hong Kong Dollar'},
    {translateId: '', code: 'HNL', name: 'Honduran Lempira'},
    {translateId: '', code: 'HRK', name: 'Croatian Kuna'},
    {translateId: '', code: 'HTG', name: 'Haitian Gourde'},
    {translateId: '', code: 'HUF', name: 'Hungarian Forint'},
    {translateId: '', code: 'IDR', name: 'Indonesian Rupiah'},
    {translateId: '', code: 'ILS', name: 'Israeli New Sheqel'},
    {translateId: '', code: 'IMP', name: 'Manx pound'},
    {translateId: '', code: 'INR', name: 'Indian Rupee'},
    {translateId: '', code: 'IQD', name: 'Iraqi Dinar'},
    {translateId: '', code: 'IRR', name: 'Iranian Rial'},
    {translateId: '', code: 'ISK', name: 'Icelandic Króna'},
    {translateId: '', code: 'JEP', name: 'Jersey Pound'},
    {translateId: '', code: 'JMD', name: 'Jamaican Dollar'},
    {translateId: '', code: 'JOD', name: 'Jordanian Dinar'},
    {translateId: '', code: 'JPY', name: 'Japanese Yen'},
    {translateId: '', code: 'KES', name: 'Kenyan Shilling'},
    {translateId: '', code: 'KGS', name: 'Kyrgystani Som'},
    {translateId: '', code: 'KHR', name: 'Cambodian Riel'},
    {translateId: '', code: 'KMF', name: 'Comorian Franc'},
    {translateId: '', code: 'KPW', name: 'North Korean Won'},
    {translateId: '', code: 'KRW', name: 'South Korean Won'},
    {translateId: '', code: 'KWD', name: 'Kuwaiti Dinar'},
    {translateId: '', code: 'KYD', name: 'Cayman Islands Dollar'},
    {translateId: '', code: 'KZT', name: 'Kazakhstani Tenge'},
    {translateId: '', code: 'LAK', name: 'Laotian Kip'},
    {translateId: '', code: 'LBP', name: 'Lebanese Pound'},
    {translateId: '', code: 'LKR', name: 'Sri Lankan Rupee'},
    {translateId: '', code: 'LRD', name: 'Liberian Dollar'},
    {translateId: '', code: 'LSL', name: 'Lesotho Loti'},
    {translateId: '', code: 'LYD', name: 'Libyan Dinar'},
    {translateId: '', code: 'MAD', name: 'Moroccan Dirham'},
    {translateId: '', code: 'MDL', name: 'Moldovan Leu'},
    {translateId: '', code: 'MGA', name: 'Malagasy Ariary'},
    {translateId: '', code: 'MKD', name: 'Macedonian Denar'},
    {translateId: '', code: 'MMK', name: 'Myanma Kyat'},
    {translateId: '', code: 'MNT', name: 'Mongolian Tugrik'},
    {translateId: '', code: 'MOP', name: 'Macanese Pataca'},
    {translateId: '', code: 'MRO', name: 'Mauritanian Ouguiya (pre-2018)'},
    {translateId: '', code: 'MRU', name: 'Mauritanian Ouguiya'},
    {translateId: '', code: 'MUR', name: 'Mauritian Rupee'},
    {translateId: '', code: 'MVR', name: 'Maldivian Rufiyaa'},
    {translateId: '', code: 'MWK', name: 'Malawian Kwacha'},
    {translateId: '', code: 'MXN', name: 'Mexican Peso'},
    {translateId: '', code: 'MYR', name: 'Malaysian Ringgit'},
    {translateId: '', code: 'MZN', name: 'Mozambican Metical'},
    {translateId: '', code: 'NAD', name: 'Namibian Dollar'},
    {translateId: '', code: 'NGN', name: 'Nigerian Naira'},
    {translateId: '', code: 'NIO', name: 'Nicaraguan Córdoba'},
    {translateId: '', code: 'NOK', name: 'Norwegian Krone'},
    {translateId: '', code: 'NPR', name: 'Nepalese Rupee'},
    {translateId: '', code: 'NZD', name: 'New Zealand Dollar'},
    {translateId: '', code: 'OMR', name: 'Omani Rial'},
    {translateId: '', code: 'PAB', name: 'Panamanian Balboa'},
    {translateId: '', code: 'PEN', name: 'Peruvian Nuevo Sol'},
    {translateId: '', code: 'PGK', name: 'Papua New Guinean Kina'},
    {translateId: '', code: 'PHP', name: 'Philippine Peso'},
    {translateId: '', code: 'PKR', name: 'Pakistani Rupee'},
    {translateId: '', code: 'PLN', name: 'Polish Zloty'},
    {translateId: '', code: 'PYG', name: 'Paraguayan Guarani'},
    {translateId: '', code: 'QAR', name: 'Qatari Rial'},
    {translateId: '', code: 'RON', name: 'Romanian Leu'},
    {translateId: '', code: 'RSD', name: 'Serbian Dinar'},
    {translateId: '', code: 'RUB', name: 'Russian Ruble'},
    {translateId: '', code: 'RWF', name: 'Rwandan Franc'},
    {translateId: '', code: 'SAR', name: 'Saudi Riyal'},
    {translateId: '', code: 'SBD', name: 'Solomon Islands Dollar'},
    {translateId: '', code: 'SCR', name: 'Seychellois Rupee'},
    {translateId: '', code: 'SDG', name: 'Sudanese Pound'},
    {translateId: '', code: 'SEK', name: 'Swedish Krona'},
    {translateId: '', code: 'SGD', name: 'Singapore Dollar'},
    {translateId: '', code: 'SHP', name: 'Saint Helena Pound'},
    {translateId: '', code: 'SLL', name: 'Sierra Leonean Leone'},
    {translateId: '', code: 'SOS', name: 'Somali Shilling'},
    {translateId: '', code: 'SRD', name: 'Surinamese Dollar'},
    {translateId: '', code: 'SSP', name: 'South Sudanese Pound'},
    {
      translateId: '',
      code: 'STD',
      name: 'São Tomé and Príncipe Dobra (pre-2018)',
    },
    {translateId: '', code: 'STN', name: 'São Tomé and Príncipe Dobra'},
    {translateId: '', code: 'SVC', name: 'Salvadoran Colón'},
    {translateId: '', code: 'SYP', name: 'Syrian Pound'},
    {translateId: '', code: 'SZL', name: 'Swazi Lilangeni'},
    {translateId: '', code: 'THB', name: 'Thai Baht'},
    {translateId: '', code: 'TJS', name: 'Tajikistani Somoni'},
    {translateId: '', code: 'TMT', name: 'Turkmenistani Manat'},
    {translateId: '', code: 'TND', name: 'Tunisian Dinar'},
    {translateId: '', code: 'TOP', name: 'Tongan Pa`anga'},
    {translateId: '', code: 'TRY', name: 'Turkish Lira'},
    {translateId: '', code: 'TTD', name: 'Trinidad and Tobago Dollar'},
    {translateId: '', code: 'TWD', name: 'New Taiwan Dollar'},
    {translateId: '', code: 'TZS', name: 'Tanzanian Shilling'},
    {translateId: '', code: 'UAH', name: 'Ukrainian Hryvnia'},
    {translateId: '', code: 'UGX', name: 'Ugandan Shilling'},
    {translateId: '', code: 'USD', name: 'United States Dollar'},
    {translateId: '', code: 'UYU', name: 'Uruguayan Peso'},
    {translateId: '', code: 'UZS', name: 'Uzbekistan Som'},
    {translateId: '', code: 'VEF', name: 'Venezuelan Bolívar Fuerte (Old)'},
    {translateId: '', code: 'VES', name: 'Venezuelan Bolívar Soberano'},
    {translateId: '', code: 'VND', name: 'Vietnamese Dong'},
    {translateId: '', code: 'VUV', name: 'Vanuatu Vatu'},
    {translateId: '', code: 'WST', name: 'Samoan Tala'},
    {translateId: '', code: 'XAF', name: 'CFA Franc BEAC'},
    {translateId: '', code: 'XAG', name: 'Silver Ounce'},
    {translateId: '', code: 'XAU', name: 'Gold Ounce'},
    {translateId: '', code: 'XCD', name: 'East Caribbean Dollar'},
    {translateId: '', code: 'XDR', name: 'Special Drawing Rights'},
    {translateId: '', code: 'XOF', name: 'CFA Franc BCEAO'},
    {translateId: '', code: 'XPD', name: 'Palladium Ounce'},
    {translateId: '', code: 'XPF', name: 'CFP Franc'},
    {translateId: '', code: 'XPT', name: 'Platinum Ounce'},
    {translateId: '', code: 'YER', name: 'Yemeni Rial'},
    {translateId: '', code: 'ZAR', name: 'South African Rand'},
    {translateId: '', code: 'ZMW', name: 'Zambian Kwacha'},
    {translateId: '', code: 'ZWL', name: 'Zimbabwean Dollar'},
  ];

  constructor(private translateService: TranslateService) {}

  public getCurrencies(): OrganizationCurrency[] {
    this.currencies.forEach((c) => {
      if (c.name === 'Bitcoin') {
        c.translateId = 'settings.organization.currency.BTC';
      } else if (c.name === 'Euro') {
        c.translateId = 'settings.organization.currency.EUR';
      } else {
        c.translateId =
          'settings.organization.currency.' + c.name.split(' ').join('_');
      }
    });

    this.currencies.forEach((c) => {
      this.translateService.get(c.translateId).subscribe({
        next: (res) => (c.name = res),
      });
    });

    return this.currencies;
  }
}
