import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CreateReportFromComponent} from 'src/app/modules/reporting/components';
import {ReportTemplate} from 'src/app/shared/models';
import { TemplatePreviewComponent } from '../../components/template-preview/template-preview.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-template-dialog-preview',
    templateUrl: './template-dialog-preview.component.html',
    styleUrls: ['./template-dialog-preview.component.scss'],
    standalone: true,
    imports: [
        MatDialogContent,
        TemplatePreviewComponent,
        MatDialogActions,
        TranslateModule,
    ],
})
export class TemplateDialogPreviewComponent implements OnInit {
  public loadingTemplate: boolean;

  constructor(
    private dialogRef: MatDialogRef<TemplateDialogPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportTemplate,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    //
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onTemplatePreview(): void {
    const dialogRef = this.dialog.open(CreateReportFromComponent, {
      data: {
        template: this.data,
        from: 'template',
      },
      autoFocus: false,
      maxWidth: '100vw',
      width: '45vw',
      height: '82vh',
      disableClose: true,
      restoreFocus: false,
    });

    this.dialogRef.close();

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogSub.unsubscribe();
    });
  }

  getLoadingTemplateStatus(event: boolean): void {
    this.loadingTemplate = event;
  }
}
