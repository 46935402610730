@if (showProgress) {
  @if (files && files.length > 0) {
    <ul class="d-flex flex-column pl-0 mb-2">
      @for (file of files; track file; let i = $index) {
        <li class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="wsm-text-white">
              <p class="mb-0" [ngClass]="{'font-small': size === 'small'}">
                {{ file?.name }}
              </p>
              <small>{{ formatBytes(file?.size, 2) }}</small>
            </div>
          </div>
          @if (file?.progress < 100) {
            <i
              class="fa-solid fa-circle-notch wsm-text-white fa-2x fa-spin"
            ></i>
          } @else {
            <button
              (click)="deleteFile(i)"
              class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
            >
              <i class="fa-solid fa-close"></i>
            </button>
          }
        </li>
      }
    </ul>
  }
}
<div
  [ngClass]="{'dropzone-disable': disabledInput}"
  class="dropzone"
  (fileDropped)="onFileDropped($event)"
>
  <input
    #fileDropRef
    id="fileDropRef"
    type="file"
    [multiple]="multiple"
    [accept]="accept"
    (change)="fileBrowseHandler($any($event.target).files)"
    [disabled]="disabledInput"
  />
  <div [ngClass]="{small: size === 'small', normal: size === 'normal'}">
    <i class="wsm-text-white fa-regular fa-arrow-up-from-line fa-4x"></i>
    @if (!multiple) {
      @if (!disabledInput) {
        <p class="wsm-text-white mb-0">
          {{ "common.drag_and_drop.single.begin_text" | translate }}
        </p>
      }
      @if (!disabledInput) {
        <p class="highlighted">
          {{ "common.drag_and_drop.single.end_text" | translate }}
        </p>
      }
      @if (disabledInput) {
        <p class="wsm-text-white">
          {{ "common.drag_and_drop.single.remove_file" | translate }}
        </p>
      }
    }
    @if (multiple) {
      @if (!disabledInput) {
        <p class="wsm-text-white">
          {{ "common.drag_and_drop.multiple.begin_text" | translate }}
        </p>
      }
      @if (!disabledInput) {
        <p class="highlighted">
          {{ "common.drag_and_drop.multiple.end_text" | translate }}
        </p>
      }
      @if (disabledInput) {
        <p class="wsm-text-white">
          {{ "common.drag_and_drop.multiple.remove_file" | translate }}
        </p>
      }
    }
  </div>
</div>
