<header [ngClass]="{'mb-4': !data.isMobile}">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h2 m-0">
      {{ data.title }}
    </h1>

    <button
      mat-dialog-close
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content class="message-content">
  <p [ngStyle]="{'font-size': data.isMobile ? '14px' : ''}">
    {{ data.content }}
  </p>
</mat-dialog-content>
