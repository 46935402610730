<div class="d-flex flex-column h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">
        {{ "shared.components.date_picker.date_range_title" | translate }}
      </h1>

      <button
        mat-dialog-close
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
  </header>

  <mat-dialog-content class="date-picker-content">
    <app-date-picker-menu
      [showClear]="data.origin === 'widget'"
      [currentOption]="data.currentOption"
      [compareOption]="data.compareOption"
      [selectedComparisonRange]="data.selectedComparisonRange"
      [selectedRange]="data.selectedRange"
      [relativeRange]="data.relativeRange"
      [origin]="data.origin"
      [isMobile]="data.isMobile"
      (rangeSelection)="onDateSelection($event)"
    />
  </mat-dialog-content>
</div>
