import {
  Component,
  HostListener,
  inject,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import {LoadingService, MobileService} from '../../../core/services';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, filter} from 'rxjs';
import {AdblockDetector, getPlainUrl} from '../../helpers';
import {TopNotificationComponent} from '../top-notification/top-notification.component';
import {ProfileNavbarComponent} from '../profile-navbar/profile-navbar.component';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';
import {NgClass, AsyncPipe} from '@angular/common';
import {TabBarComponent} from '../../mobile/components/tab-bar/tab-bar.component';
import {LoadingComponent} from '../loading/loading.component';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
  standalone: true,
  imports: [
    TopNotificationComponent,
    ProfileNavbarComponent,
    InfiniteScrollDirective,
    NgClass,
    RouterOutlet,
    TabBarComponent,
    LoadingComponent,
    AsyncPipe,
  ],
})
export class BodyComponent implements OnInit {
  // Inputs
  @Input() collapsed = false;
  @Input() screenWidth = 0;
  @Input() hideSidenav = false;
  @Input() isShareReport = false;
  @Input() hideMobileSidenav = false;

  // Services
  private adBlockDetect = inject(AdblockDetector);

  // Properties
  popupMessage = '';
  adRoutes: string[] = ['/reports'];
  connectionRoutes: string[] = ['/connections'];

  // State
  showBurgerMenu = false;
  isMobile: boolean;
  disabledScroll: boolean;
  fromShareableLink: boolean;
  showAdNotification = false;
  showConnectionNotification = false;
  loading$ = this.loader.loading$;

  constructor(
    public loader: LoadingService,
    private mobileService: MobileService,
    private router: Router,
    private translate: TranslateService,
    private ngZone: NgZone
  ) {
    this.mobileService.getMobileBehavior().subscribe((res) => {
      this.isMobile = res;
    });
    this.mobileService.disabledScroll$.subscribe((res) => {
      this.disabledScroll = res;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.showBurgerMenu =
      window.innerWidth <= 1360 || window.innerHeight <= 675 || this.isMobile;
  }

  ngOnInit(): void {
    this.fromShareableLink = this.router.url.includes('/view-reports');
    this.showBurgerMenu =
      window.innerWidth <= 1360 || window.innerHeight <= 675 || this.isMobile;

    this.checkAdBlocker();
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        distinctUntilChanged(
          (previous: NavigationEnd, current: NavigationEnd) => {
            /* Avoid to show notification twice with params and fragments (except 'reports') */
            const prevUrl = getPlainUrl(previous.url, []);
            const currentUrl = getPlainUrl(current.url, []);
            const prevRedirectsUrl = getPlainUrl(
              previous.urlAfterRedirects,
              []
            );
            const currentRedirectsUrl = getPlainUrl(
              current.urlAfterRedirects,
              []
            );

            return (
              prevUrl === currentUrl && prevRedirectsUrl === currentRedirectsUrl
            );
          }
        )
      )
      .subscribe((): void => {
        this.checkAdBlocker();
        this.checkConnectionPath();
      });
  }

  public onScroll(): void {
    this.mobileService.setScroll = true;
  }

  private checkAdBlocker(): void {
    const showAdNotification = this.adRoutes.some(
      (route) =>
        this.router.url.includes(route) && this.router.url.includes('edit')
    );
    this.showAdNotification =
      this.adBlockDetect.detect() &&
      showAdNotification &&
      this.adBlockDetect.getLocalDetection();

    if (this.showAdNotification) {
      this.popupMessage = this.translate.instant(
        'notifications.ad_blocker_detected'
      );

      setTimeout((): void => {
        // Close notification after 10 seconds
        this.onCloseNotification(false);
      }, 10000);
    }
  }

  private checkConnectionPath(): void {
    const showConnectionNotification = this.connectionRoutes.some(
      (route) =>
        this.router.url.includes(route) &&
        this.router.url.includes('connections')
    );

    const local = localStorage.getItem('showConnectionNotification');
    const showPopup = local !== null ? local.toString() !== '0' : true;

    this.showConnectionNotification = showConnectionNotification && showPopup;

    if (this.showConnectionNotification) {
      this.popupMessage = this.translate.instant(
        'notifications.gau_deprecated'
      );

      setTimeout((): void => {
        // Close notification after 10 seconds
        this.onCloseNotification(false);
      }, 10000);
    }
  }

  public onCloseNotification(value: boolean): void {
    this.setAnimationHideAd();
    setTimeout(() => {
      if (this.showConnectionNotification && value) {
        localStorage.setItem('showConnectionNotification', `${value ? 0 : 1}`);
      }
      if (this.showAdNotification && value) {
        this.ngZone.run((): void => {
          this.adBlockDetect.saveLocalDetection(this.showAdNotification);
        });
      }

      this.showAdNotification = false;
      this.showConnectionNotification = false;
    }, 200);
  }

  private setAnimationHideAd(): void {
    const element = document.getElementById('top-ad-notification');
    if (element) {
      element.classList.add('animate__fadeOutUp');
    }
  }
}
