import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {
  ConnectionsService,
  LoadingService,
  MobileService,
  NotificationService,
  ServiceAuthService,
} from 'src/app/core/services';
import {ConnectionLoginDialogComponent} from 'src/app/shared/dialogs';
import {Connection, ConnectionFilter} from 'src/app/shared/models';
import {Subscription} from 'rxjs';
import {PlanType} from '../../../enums';
import {canUser} from '../../../helpers';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { NgClass } from '@angular/common';
import { ConnectionLoginCardComponent } from '../connection-login-card/connection-login-card.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-connection-login',
    templateUrl: './connection-login.component.html',
    styleUrls: ['./connection-login.component.scss'],
    standalone: true,
    imports: [
        MatSelect,
        MatOption,
        NgClass,
        ConnectionLoginCardComponent,
        TranslateModule,
    ],
})
export class ConnectionLoginComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Input() userPlanType: PlanType;
  @Output() loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Properties
  connections: Connection[];
  filters: ConnectionFilter[] = [
    {label: 'All Connections', id: 'all', isActive: true},
    {label: 'Pay Per Click', id: 'ppc'},
    {label: 'SEO', id: 'seo'},
    {label: 'Social Media', id: 'social'},
    {label: 'Lead Management', id: 'lead_management'},
  ];
  filteredConnections: Connection[];
  subs: Subscription = new Subscription();

  // State
  isIndividual = false;
  isMobile: boolean;

  constructor(
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    private serviceAuthService: ServiceAuthService,
    private loader: LoadingService,
    private router: Router,
    private dialog: MatDialog,
    private mobileService: MobileService
  ) {}

  ngOnInit(): void {
    this.isIndividual = canUser('isIndividual', this.userPlanType);
    this.getConnections();

    this.subs.add(
      this.mobileService.getMobileBehavior().subscribe((res: boolean) => {
        this.isMobile = res;
        if (this.isMobile) {
          this.selectFilter(this.filters[0]);
        }
      })
    );
  }

  getConnections(): void {
    this.loadingState.emit(true);
    this.connectionsService.getConnectionsServices().subscribe({
      next: (res: Connection[]): void => {
        this.connections = res;
        this.filteredConnections = this.connections;
      },
      error: (err) => {
        this.loader.hide('body');
        this.loadingState.emit(false);
        this.notificationService.error(err?.message, 5000);
      },
      complete: () => {
        this.loadingState.emit(false);
        this.loader.hide('body');
      },
    });
  }

  selectFilter(filter: ConnectionFilter): void {
    this.filters.forEach(
      (filter: ConnectionFilter): boolean => (filter.isActive = false)
    );
    filter.isActive = true;
    if (filter.id === 'all') {
      this.filteredConnections = this.connections;
    } else {
      this.filteredConnections = this.connections.filter(
        (con: Connection): boolean => con.category === filter.id
      );
    }
  }

  signIn(connection: Connection): void {
    if (connection?.provider?.startsWith('call')) {
      const config: MatDialogConfig = {
        width: this.isMobile ? '100vw' : '35vw',
        height: this.isMobile ? '100vh' : '44vh',
        maxWidth: this.isMobile ? '100vw' : '80vw',
        data: {
          service: connection,
          isMobile: this.isMobile,
        },
      };
      const dialogRef: MatDialogRef<ConnectionLoginDialogComponent> =
        this.dialog.open(ConnectionLoginDialogComponent, config);

      const dialogSub: Subscription = this.router.events.subscribe((): void => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((): void => {
        dialogSub.unsubscribe();
      });
    } else {
      connection.isConnecting = true;
      localStorage.setItem(
        'currentUrl',
        decodeURIComponent(window.location.pathname)
      );
      this.serviceAuthService
        .openSignInDialog(connection?.provider as string)
        .subscribe({
          error: (): void => {
            connection.isConnecting = false;
          },
          complete: (): void => {
            connection.isConnecting = false;
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
