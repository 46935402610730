<section class="criteria-container">
  @for (
    condition of criteriaForm.get("conditions")!["controls"];
    track condition;
    let i = $index
  ) {
    <div class="row align-items-start mb-3 flex-nowrap" [formGroup]="condition">
      <!-- Field -->
      <div class="col-3 form-group">
        <label for="fieldOne">{{
          "company_selection.section.criteria.field_label" | translate
        }}</label>
        <mat-select
          id="fieldOne"
          formControlName="fieldOne"
          (selectionChange)="disableOptionFromList($event, i)"
          disableOptionCentering
          class="wsm-input"
          [panelClass]="'wsm-custom-select'"
          placeholder="{{
            'company_selection.section.criteria.field_placeholder' | translate
          }}"
        >
          @for (item of fields; track item) {
            <mat-option
              [value]="item.name"
              [disabled]="selectedFields.includes(item.name)"
            >
              {{ item.label }}
            </mat-option>
          }
        </mat-select>
      </div>
      <!-- Operator -->
      <div class="col-3 form-group">
        <label for="operator">{{
          "company_selection.section.criteria.operator_label" | translate
        }}</label>
        <mat-select
          id="operator"
          formControlName="operator"
          (selectionChange)="onChangeOperator(i)"
          disableOptionCentering
          class="wsm-input"
          [panelClass]="'wsm-custom-select'"
          placeholder="{{
            'company_selection.section.criteria.operator_placeholder'
              | translate
          }}"
        >
          @for (item of getLogicOperators(condition); track item) {
            <mat-option [value]="item.operatorName">
              {{ item.operatorLabel }}
            </mat-option>
          }
        </mat-select>
      </div>
      <!-- Values -->
      <div class="col-4">
        <!-- Generic Value -->
        @if (
          !condition.get("fieldOne").value ||
          condition.get("fieldOne").value === "name" ||
          condition.get("fieldOne").value === "website" ||
          condition.get("operator").value === "like"
        ) {
          <div class="form-group">
            <label>
              {{ "shared.components.general.value_label" | translate }}</label
            >
            <input
              type="text"
              class="wsm-input"
              placeholder="{{
                'company_selection.section.criteria.value_placeholder'
                  | translate
              }}"
              formControlName="fieldTwo"
            />
          </div>
        }
        <!-- Parent Company Value -->
        @if (
          condition.get("fieldOne").value === "parentName" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label for="fieldTwo">{{
              "company_selection.section.criteria.search_company_label"
                | translate
            }}</label>
            <i
              [ngClass]="{'d-none': !parentCompaniesLoading}"
              class="fa-solid fa-circle-notch fa-spin ac-loading"
            ></i>
            <input
              type="text"
              class="wsm-input w-100"
              placeholder="{{
                'company_selection.section.criteria.search_company_placeholder'
                  | translate
              }}"
              formControlName="fieldTwo"
              #trigger
              [matAutocomplete]="parentAutocomplete"
            />
            <mat-autocomplete
              #parentAutocomplete="matAutocomplete"
              [panelWidth]="'unset'"
            >
              @for (option of filteredOptions[i] | async; track option) {
                @if ((option | as: ClientDataModel).name) {
                  <mat-option
                    [value]="(option | as: ClientDataModel).name"
                    [innerHtml]="
                      (option | as: ClientDataModel).name
                        | highlight: toHighlight
                    "
                  >
                  </mat-option>
                }
              }
            </mat-autocomplete>
          </div>
          @if (parentCompaniesNoResults) {
            <span class="invalid-info">
              {{
                "company_selection.section.criteria.no_company_found"
                  | translate
              }}
            </span>
          }
        }
        <!-- Business Type Value -->
        @if (
          condition.get("fieldOne").value === "businessType" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label for="fieldTwo">{{
              "company_selection.section.criteria.business_type_label"
                | translate
            }}</label>
            <mat-select
              class="wsm-input"
              formControlName="fieldTwo"
              [panelClass]="'wsm-custom-select'"
              multiple
              id="fieldTwo"
              placeholder="{{
                'company_selection.section.criteria.business_type_placeholder'
                  | translate
              }}"
            >
              @for (item of clientService.getBusinessTypes(); track item) {
                <mat-option [value]="item.value">
                  {{ item.ngxId | translate }}
                </mat-option>
              }
            </mat-select>
          </div>
        }
        <!-- Owner Value -->
        @if (
          condition.get("fieldOne").value === "owner" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label for="fieldTwo">{{
              "company_selection.section.criteria.owner_label" | translate
            }}</label>
            <i
              [ngClass]="{'d-none': !ownersLoading}"
              class="fa-solid fa-circle-notch fa-spin wsm-text-white ac-loading"
            ></i>
            <input
              type="text"
              class="wsm-input w-100"
              placeholder="{{
                'company_selection.section.criteria.owner_placeholder'
                  | translate
              }}"
              formControlName="fieldTwo"
              [matAutocomplete]="ownerAutocomplete"
            />
            <mat-autocomplete
              #ownerAutocomplete="matAutocomplete"
              [panelWidth]="'unset'"
            >
              @for (option of filteredOptions[i] | async; track option) {
                @if ((option | as: UserModel).name) {
                  <mat-option
                    [value]="(option | as: UserModel).name"
                    [innerHtml]="
                      (option | as: UserModel).name! | highlight: toHighlight
                    "
                  >
                  </mat-option>
                }
              }
            </mat-autocomplete>
          </div>
          @if (ownersNoResults) {
            <span class="invalid-info">
              {{
                "company_selection.section.criteria.no_user_found" | translate
              }}
            </span>
          }
        }
        <!-- Currency Value -->
        @if (
          condition.get("fieldOne").value === "currency" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label>{{
              "company_selection.section.criteria.currency_label" | translate
            }}</label>
            <mat-select
              class="wsm-input"
              formControlName="fieldTwo"
              [panelClass]="'wsm-custom-select'"
              multiple
              placeholder="{{
                'company_selection.section.criteria.currency_placeholder'
                  | translate
              }}"
            >
              @for (item of clientService.getCurrencyList(); track item) {
                <mat-option [value]="item.value">
                  {{ item.viewValue }}
                </mat-option>
              }
            </mat-select>
          </div>
        }
        <!-- Lifecycle Value -->
        @if (
          condition.get("fieldOne").value === "lifecycle" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label>{{
              "company_selection.section.criteria.lifecycle_label" | translate
            }}</label>
            <mat-select
              class="wsm-input"
              formControlName="fieldTwo"
              [panelClass]="'wsm-custom-select'"
              multiple
              placeholder="{{
                'company_selection.section.criteria.lifecycle_placeholder'
                  | translate
              }}"
            >
              @for (item of clientService.getLifeCycle(); track item) {
                <mat-option [value]="item.value">
                  {{ item.viewValue }}
                </mat-option>
              }
            </mat-select>
          </div>
        }
        <!-- Client Status Value -->
        @if (
          condition.get("fieldOne").value === "status" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label>{{
              "company_selection.section.criteria.status_label" | translate
            }}</label>
            <mat-select
              class="wsm-input"
              formControlName="fieldTwo"
              [panelClass]="'wsm-custom-select'"
              multiple
              placeholder="{{
                'company_selection.section.criteria.status_placeholder'
                  | translate
              }}"
            >
              @for (item of clientService.getClientStatus(); track item) {
                <mat-option [value]="item.value">
                  {{ item.viewValue }}
                </mat-option>
              }
            </mat-select>
          </div>
        }
        <!-- Industry Value -->
        @if (
          condition.get("fieldOne").value === "industry" &&
          condition.get("operator").value !== "like"
        ) {
          <div class="form-group">
            <label>{{
              "company_selection.section.criteria.industry_label" | translate
            }}</label>
            <i
              [ngClass]="{'d-none': !industriesLoading}"
              class="fa-solid fa-circle-notch fa-spin wsm-text-white ac-loading"
            ></i>
            <input
              type="text"
              class="wsm-input w-100"
              placeholder="{{
                'company_selection.section.criteria.industry_placeholder'
                  | translate
              }}"
              formControlName="fieldTwo"
              [matAutocomplete]="industryAutocomplete"
            />
            <mat-autocomplete
              #industryAutocomplete="matAutocomplete"
              class="mat-menu-gray"
              [panelWidth]="'unset'"
              (optionSelected)="onIndustrySelect($event, i)"
            >
              @for (option of filteredOptions[i] | async; track option) {
                @if ((option | as: IndustryGroupModel).category) {
                  <mat-optgroup
                    [label]="(option | as: IndustryGroupModel).category"
                  >
                    @for (
                      subcategory of (option | as: IndustryGroupModel)
                        .subcategories;
                      track subcategory
                    ) {
                      <mat-option
                        [value]="{
                          category: (option | as: IndustryGroupModel).category,
                          subcategory
                        }"
                        [innerHTML]="subcategory | highlight: toHighlight"
                      >
                      </mat-option>
                    }
                  </mat-optgroup>
                }
              }
            </mat-autocomplete>
          </div>
          @if (industriesNoResults) {
            <span class="invalid-info">
              {{
                "company_selection.section.criteria.no_industry_found"
                  | translate
              }}
            </span>
          }
        }
      </div>
      <!-- Delete Condition -->
      <div class="col-2 mt-4">
        @if (i > 0) {
          <button
            (click)="removeCondition(i)"
            class="ms-4 wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
          >
            <i class="fa-regular fa-trash"></i>
          </button>
        }
      </div>
    </div>
  }

  <div class="row">
    <div class="col-4">
      <button
        (click)="addCondition()"
        class="wsm-btn wsm-btn-outline wsm-btn-small mb-3 ms-1"
      >
        <i class="fa-regular fa-plus"></i>&nbsp;
        {{ "company_selection.section.criteria.add_condition" | translate }}
      </button>
    </div>
  </div>
</section>
