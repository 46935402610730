<div class="overlay-container position-relative">
  <header class="d-flex justify-content-between mb-3">
    <div class="d-flex flex-column ps-2">
      <h4>{{ data.name }}</h4>
      <p>{{ data.description }}</p>
    </div>
    <button
      (click)="closeDialog()"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </header>

  <mat-dialog-content>
    <app-template-preview
      (loadingTemplateStatus)="getLoadingTemplateStatus($event)"
      [reportTemplateId]="data.id"
    ></app-template-preview>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-end gap-4">
    <button (click)="closeDialog()" class="wsm-btn wsm-btn-outline">
      {{ "shared.components.template_preview.cancel" | translate }}
    </button>
    <button class="wsm-btn wsm-btn-primary" (click)="onTemplatePreview()">
      {{ "shared.components.template_preview.use_template" | translate }}
    </button>
  </mat-dialog-actions>

  @if (loadingTemplate) {
    <div class="loader-container">
      <div class="spinner-ripple">
        <div class="circle">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  }
</div>
