import {Component} from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'app-shared-error',
    templateUrl: './shared-error.component.html',
    standalone: true,
    imports: [LottieComponent],
})
export class SharedErrorComponent {
  options: AnimationOptions = {
    path: '/assets/lotties/broken-clicky.json',
  };
}
