import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subject, Subscription, takeUntil} from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import {
  DynamicMetric,
  ScopeField,
  Widget,
  WidgetConfiguration,
} from '../../models';
import {ScopeService} from '../../helpers';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatOption } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ConnectionLogosComponent } from '../../components/connection-logos/connection-logos.component';
import { MatSelect } from '@angular/material/select';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-dynamic-metrics',
    templateUrl: './dynamic-metrics.component.html',
    styleUrls: ['./dynamic-metrics.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        NgxIntlTelInputModule,
        MatAutocomplete,
        MatOption,
        MatTooltip,
        ConnectionLogosComponent,
        MatSelect,
        MatDialogActions,
        AsyncPipe,
        TranslateModule,
    ],
})
export class DynamicMetricsComponent implements OnInit, OnDestroy {
  // Properties
  subs: Subscription = new Subscription();
  dataForm: UntypedFormGroup;
  widget: Widget;
  fields: ScopeField[] = [];
  metrics: ScopeField[] = [];
  selectedMetric: ScopeField;

  public filteredMetrics: ReplaySubject<ScopeField[]> = new ReplaySubject<
    ScopeField[]
  >(1);
  protected onDestroySubject = new Subject<void>();
  public fieldFilterCtrl: UntypedFormControl = new UntypedFormControl({
    value: '',
  });

  // State Variables
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DynamicMetricsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DynamicMetric,
    public scopeService: ScopeService
  ) {}

  ngOnInit(): void {
    this.widget = this.data?.widget;
    this.fields = this.data?.fields;
    this.metrics = this.fields.filter(
      (field) => this.data?.metricTypes.includes(field.dataType!)
    );

    this.dataForm = new UntypedFormGroup({
      metric: new UntypedFormControl(
        {value: '', disabled: true},
        Validators.required
      ),
      aggregation: new UntypedFormControl(
        {value: '', disabled: true},
        Validators.required
      ),
      valueType: new UntypedFormControl('current'),
    });

    if (this.metrics) {
      this.listenControlSub();
      this.dataForm.get('metric')?.enable();
      this.fieldFilterCtrl.enable();
    } else {
      this.dataForm.get('metric')?.disable();
      this.fieldFilterCtrl.disable();
    }

    this.getWidgetFiltered();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  private listenControlSub(): void {
    this.fieldFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe((value) => {
        if (typeof value !== 'string') {
          this.dataForm.get('metric')?.setValue(value);
        }

        if (!value) {
          if (this.metrics) {
            this.filteredMetrics.next(this.metrics.slice());
          }
          return;
        } else {
          value = typeof value === 'string' ? value.toLowerCase() : value;
        }

        this.filteredMetrics.next(
          this.metrics.filter(
            (field: {name: string}) =>
              field.name.toLowerCase().indexOf(value) > -1
          )
        );
      });
  }

  onMetricChange(event: MatAutocompleteSelectedEvent): void {
    if (event?.option?.value) {
      this.dataForm.get('aggregation')?.enable();
      this.dataForm.get('aggregation')?.setValue('');
      this.selectedMetric = this.metrics.filter(
        (field: ScopeField) => field.id === event?.option?.value?.id
      )[0];
      if (this.selectedMetric && this.selectedMetric.defaultAggregation) {
        this.dataForm
          .get('aggregation')
          ?.setValue(this.selectedMetric.defaultAggregation);
      }
    } else {
      this.dataForm.get('aggregation')?.disable();
    }
  }

  displayMetric(filter: ScopeField): string {
    return filter && filter.name ? filter.name : '';
  }

  onSave(): void {
    if (this.dataForm.invalid) {
      return;
    }

    let richTextValue: string;
    let metricName = '';

    this.metrics.forEach((metric: ScopeField) => {
      if (metric.id === this.dataForm.get('metric')?.value?.id) {
        metricName = metric.id!;
      }
    });

    switch (this.dataForm.get('valueType')?.value) {
      case 'previous':
        richTextValue = `${metricName}_${this.dataForm.get('aggregation')
          ?.value}_previous`;
        break;
      case 'delta':
        richTextValue = `${metricName}_${this.dataForm.get('aggregation')
          ?.value}_delta`;
        break;
      case 'deltaPercent':
        richTextValue = `${metricName}_${this.dataForm.get('aggregation')
          ?.value}_deltaPercent`;
        break;
      default:
        richTextValue = `${metricName}_${this.dataForm.get('aggregation')
          ?.value}`;
        break;
    }

    const selection = {
      id: `${Date.now()}`,
      value: richTextValue,
    };

    this.selectedMetric.aggregation = this.dataForm.get('aggregation')?.value;
    this.setMetricOnArray(this.selectedMetric);
    this.dialogRef.close(selection);
  }

  setMetricOnArray(selectedMetric: ScopeField): void {
    const existOnArray = this.widget.metrics?.some(
      (metric) => metric.id === selectedMetric.id
    );

    if (!existOnArray) {
      this.widget.metrics?.push(selectedMetric);
    }

    this.getWidgetFiltered();
  }

  getWidgetFiltered(): void {
    const [fields, scope] = this.scopeService.onFieldSelectionChanged(
      this.widget,
      this.fields
    );
    this.fields = fields;
    this.widget.scope = scope;
    this.setWidgetFilteredFields();
  }

  setWidgetFilteredFields(search = ''): void {
    const widgetConfiguration: WidgetConfiguration = {
      widget: this.widget,
      fields: this.fields,
      locationDimension: [],
      allowDuplicate: true,
      search,
      from: 'widget',
    };

    this.metrics =
      this.scopeService.filterMetricsAndDimensionsByFields(
        widgetConfiguration
      )[1];
    this.filteredMetrics.next(this.metrics);
  }
}
