<div class="row mx-0 pt-3">
  <div class="col-12 px-1 mb-3">
    <mat-button-toggle-group
      [formControl]="shapeTypeCtrl"
      appearance="legacy"
      aria-label="Shapes"
    >
      <mat-button-toggle class="ps-1 my-1" value="round">
        {{
          "reporting.widget_editor.style_form.shapes.corner_label" | translate
        }}
      </mat-button-toggle>
      <mat-button-toggle class="pe-1 my-1" value="shapes">
        {{
          "reporting.widget_editor.style_form.shapes.shapes_label" | translate
        }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  @if (shapeTypeCtrl.value === "round") {
    <div class="col-12 px-1 mb-3">
      <mat-radio-group
        [formControl]="cornerTypeCtrl"
        aria-label="Select an option"
        class="ms-2"
      >
        <mat-radio-button class="me-3" value="all">
          {{
            "reporting.widget_editor.style_form.shapes.all_corners_label"
              | translate
          }}
        </mat-radio-button>
        <mat-radio-button class="me-3" value="custom">
          {{
            "reporting.widget_editor.style_form.shapes.custom_corners_label"
              | translate
          }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <form [formGroup]="cornerForm">
      <div class="row">
        @if (cornerTypeCtrl.value === "all") {
          <div class="col-12 mb-1">
            <div class="position-relative input-icon">
              <input
                appNumbersOnly
                class="wsm-input custom-input btn-corner ms-1"
                formControlName="allCorners"
                type="text"
              />
              <span>PX</span>
            </div>
          </div>
        }
        @if (cornerTypeCtrl.value === "custom") {
          <div class="col-12">
            <div class="d-inline-flex flex-column">
              <div class="position-relative input-icon">
                <input
                  appNumbersOnly
                  class="wsm-input custom-input btn-corner top-left-corner ms-2 mb-2"
                  formControlName="topLeftCorner"
                  type="text"
                />
                <span>PX</span>
              </div>
              <div class="position-relative input-icon">
                <input
                  appNumbersOnly
                  class="wsm-input custom-input btn-corner bottom-left-corner ms-2 mb-2"
                  formControlName="bottomLeftCorner"
                  type="text"
                />
                <span>PX</span>
              </div>
            </div>
            <div class="d-inline-flex flex-column">
              <div class="position-relative input-icon">
                <input
                  appNumbersOnly
                  class="wsm-input custom-input btn-corner top-right-corner ms-2 mb-2"
                  formControlName="topRightCorner"
                  type="text"
                />
                <span>PX</span>
              </div>
              <div class="position-relative input-icon">
                <input
                  appNumbersOnly
                  class="wsm-input custom-input btn-corner bottom-right-corner ms-2 mb-2"
                  formControlName="bottomRightCorner"
                  type="text"
                />
                <span>PX</span>
              </div>
            </div>
          </div>
        }
      </div>
    </form>
  }

  @if (shapeTypeCtrl.value === "shapes") {
    <div class="col-12 px-1 mb-3">
      <div class="shape-container">
        @for (shape of geometricShapes; track shape) {
          <div
            (click)="onShapeSelect(shape.class)"
            [ngClass]="{
              'active-shape': this.widget.theme?.shapeClass === shape.class
            }"
            class="shape-box"
          >
            <div [class]="shape.class"></div>
            <span>{{ shape.translation | translate }}</span>
          </div>
        }
      </div>
    </div>
  }
</div>
