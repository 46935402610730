import {Component, computed, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthenticationService} from 'src/app/core/services';
import {environment} from 'src/environments/environment';
import {NgClass} from '@angular/common';
import {LoadingComponent} from '../loading/loading.component';
import {UnauthorizedPageComponent} from '../unauthorized-page/unauthorized-page.component';
import {PlanSelectionComponent} from '../plan-selection/plan-selection.component';
import {TranslateModule} from '@ngx-translate/core';
import {BillingPlanStore} from '../../state';

@Component({
  selector: 'app-trial-expired',
  templateUrl: './trial-expired.component.html',
  styleUrls: ['./trial-expired.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    LoadingComponent,
    UnauthorizedPageComponent,
    PlanSelectionComponent,
    TranslateModule,
  ],
})
export class TrialExpiredComponent implements OnInit {
  private authService = inject(AuthenticationService);
  // Store
  private billingPlanStore = inject(BillingPlanStore);

  // Properties
  step = 1;
  title: string;
  message: string;
  buttonText: string;
  lottiePath: string;
  billingStatus = this.billingPlanStore.getPlan;

  // State
  isLoading: boolean;
  isExternalUser: boolean;

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.isExternalUser = JSON.parse(
      localStorage.getItem('currentUser')!
    )?.roles.includes('ROLE_EXTERNAL');
    if (this.isExternalUser) {
      this.title = 'common.unauthorized_page.free_trial.title_external';
      this.message = 'common.unauthorized_page.free_trial.message_external';
      this.lottiePath = '/assets/lotties/broken-clicky.json';
    } else if (this.billingStatus()?.status === 'EXPIRED') {
      this.title = 'common.unauthorized_page.free_trial.title';
      this.message = 'common.unauthorized_page.free_trial.message';
      this.buttonText = 'common.unauthorized_page.free_trial.call_to_action';
      this.lottiePath = '/assets/lotties/sleeping-clicky.json';
    } else {
      this.title = 'common.unauthorized_page.plan_inactive.title';
      this.message = 'common.unauthorized_page.plan_inactive.message';
      this.buttonText = 'common.unauthorized_page.plan_inactive.call_to_action';
      this.lottiePath = '/assets/lotties/sleeping-clicky.json';
    }
  }

  public finishLoading(): void {
    this.isLoading = false;
  }

  public goToPlanSelection(): void {
    this.step = 2;
    this.isLoading = true;
  }

  logout(): void {
    this.authService.clearSession();
    window.location.replace(environment.fullApiUrl + '/api/v2/logout');
  }
}
