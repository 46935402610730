import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {Widget} from 'src/app/shared/models/widget/widget.model';
import {WidgetService} from 'src/app/core/services/widget.service';
import {CdkMenu, CdkMenuItem} from '@angular/cdk/menu';
import {IWidgetContextMenu} from '../../../models';

@Component({
  selector: 'app-widget-action',
  templateUrl: './widget-action.component.html',
  styleUrls: ['./widget-action.component.scss'],
  standalone: true,
  imports: [MatTooltip, TranslateModule, CdkMenu, CdkMenuItem],
})
export class WidgetActionComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() widget: Widget;
  public isEditMode = input<boolean>(false);
  public onHoverActions = output<void>();
  public onLeaveActions = output<void>();

  private widgetService = inject(WidgetService);

  ngOnInit(): void {
    this.widgetService.editTitleWidgetTitle$.subscribe({
      next: (res) => {
        this.widget.isEditingInline = res.isEdit
          ? this.widget.id === res.widgetId
          : false;
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
    }
  }

  public onContextMenu(action: string): void {
    const data: IWidgetContextMenu = {
      action,
      type: 'widget',
      widget: this.widget,
    };
    this.widgetService.onContextMenu(data);
  }
}
