<mat-dialog-content>
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h6 m-0">
        {{
          "shared.dialogs.demo_video.title"
            | translate: {connection: data?.name || ""}
        }}
      </h1>

      <div class="d-flex flex-row">
        @if (!data?.isMobile) {
          <button
            class="wsm-btn wsm-btn-outline-icon wsm-btn-really-tiny-icon me-1"
            (click)="moveTopRight()"
          >
            <i class="fa-solid fa-arrow-up-right"></i>
          </button>
          <button
            class="wsm-btn wsm-btn-outline-icon wsm-btn-really-tiny-icon me-1"
            (click)="moveBottomRight()"
          >
            <i class="fa-solid fa-arrow-down-right"></i>
          </button>
        }
        <button
          [mat-dialog-close]="true"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-really-tiny-icon"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      </div>
    </div>
  </header>

  <iframe
    [src]="data.url | safeResource"
    title="Connection Settings"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
    class="w-100 h-100"
    style="min-height: 260px"
  >
  </iframe>
</mat-dialog-content>
