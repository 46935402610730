import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {lastValueFrom} from 'rxjs';

import {NotificationService, ReportService} from '../../../../core/services';
import {
  CustomError,
  Report,
  ReportPage,
  ReportTheme,
  Widget,
} from '../../../../shared/models';
import { NgStyle } from '@angular/common';
import { WidgetComponent } from '../../../../shared/components/widgets/widget/widget.component';
import { AsPipe } from '../../../../shared/pipes/as-type.pipe';

// [x]: Signals implemented
@Component({
    selector: 'app-report-preview',
    templateUrl: './report-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./report-preview.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        WidgetComponent,
        AsPipe,
    ],
})
export class ReportPreviewComponent implements OnInit {
  //  Properties
  reportId = signal<string>('');
  widgetId = signal<string>('');
  widget = signal<Widget | null>(null);
  widgetHeight = signal<number>(0);
  widgetWidth = signal<number>(0);
  reportTheme = signal<ReportTheme>({});
  pageTheme = signal<ReportTheme>({});
  WidgetModel!: Widget;

  // State
  isLoaded = signal<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private reportService: ReportService
  ) {}

  async ngOnInit(): Promise<void> {
    this.reportId.set(this.route.snapshot.paramMap.get('id') ?? '');
    this.widgetId.set(this.route.snapshot.paramMap.get('wid') ?? '');

    await this.getReport();
    await this.getWidget();
  }

  private async getReport(): Promise<void> {
    const report$ = this.reportService.getReport(this.reportId());
    await lastValueFrom(report$)
      .then((res: Report) => {
        this.reportTheme.set(res.theme);
      })
      .catch((err: CustomError) => {
        this.notificationService.error(err?.message as string, 3000);
      });
  }

  private async getWidget(): Promise<void> {
    if (this.reportId().length > 0 && this.widgetId().length > 0) {
      const widget$ = this.reportService.getReportWidget(
        this.reportId(),
        this.widgetId()
      );
      await lastValueFrom(widget$)
        .then(async (res: Widget) => {
          this.widget.set(res);
          if (this.widget()) {
            // Set Theme
            const currentWidget = this.widget() as Widget;
            if (!currentWidget.theme || Array.isArray(currentWidget.theme)) {
              currentWidget.theme = {};
            }

            await this.getReportPage(currentWidget?.pageId as string);

            this.widgetHeight.set(
              currentWidget.position.height * 35.72 +
                5 * (currentWidget.position.height - 1)
            );
            this.widgetWidth.set(
              currentWidget.position.width * 36.41 +
                5 * (currentWidget.position.width - 1)
            );
            this.isLoaded.set(true);
          }
        })
        .catch((err: CustomError) => {
          this.notificationService.error(err?.message as string, 3000);
          this.isLoaded.set(true);
        });
    }
  }

  private async getReportPage(pageId: string): Promise<void> {
    const page$ = this.reportService.getReportPage(this.reportId(), pageId);
    await lastValueFrom(page$)
      .then((page: ReportPage) => {
        page.theme && this.pageTheme.set(page.theme);
      })
      .catch((err: CustomError) => {
        this.notificationService.error(err?.message as string, 3000);
      });
  }
}
