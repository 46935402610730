<div
  class="ac-menu"
  cdkMenu
  (mouseenter)="onHoverActions.emit()"
  (mouseleave)="onLeaveActions.emit()"
  id="widgetContextMenu"
>
  @if (isEditMode() && !widget.isEmpty) {
    <!-- Copy Style -->
    <button
      class="ac-menu-item"
      cdkMenuItem
      (click)="onContextMenu('copyStyle')"
    >
      <span class="text">
        <i class="fa-regular fa-paint-roller icon"></i>
        {{ "context_menu.copy_widget_style" | translate }}
      </span>
    </button>
    <!-- Paste Style -->
    <button
      class="ac-menu-item"
      cdkMenuItem
      (click)="onContextMenu('pasteStyle')"
    >
      <span class="text">
        <i class="fa-regular fa-clipboard icon"></i>
        {{ "context_menu.paste_widget_style" | translate }}
      </span>
    </button>
    <!-- Edit Title -->
    @if (widget.widgetType === "title") {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="onContextMenu('editTitle')"
      >
        <span class="text">
          <i class="fa-solid fa-text icon"></i>
          {{ "context_menu.widget_title" | translate }}
        </span>
      </button>
    }

    <!-- Edit -->
    <button
      class="ac-menu-item edit-widget-context-menu"
      cdkMenuItem
      (click)="onContextMenu('edit')"
    >
      <span class="text">
        <i class="fa-solid fa-gear icon edit-widget-context-menu-icon"></i>
        {{ "reporting.widgets.actions.edit_widget" | translate }}
      </span>
    </button>

    <!-- Move to Top -->
    @if (widget.widgetType === "branding" && widget.position.y !== 0) {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="onContextMenu('moveTop')"
      >
        <span class="text">
          <i class="fa-regular fa-circle-up icon"></i>
          {{ "context_menu.move_top" | translate }}
        </span>
      </button>
    }

    <!-- Move to Bottom -->
    @if (
      widget.widgetType === "branding" &&
      !(18 - widget.position.y === widget.position.height) &&
      !(32 - widget.position.y === widget.position.height)
    ) {
      <button
        class="ac-menu-item"
        cdkMenuItem
        (click)="onContextMenu('moveBottom')"
      >
        <span class="text">
          <i class="fa-regular fa-circle-down icon"></i>
          {{ "context_menu.move_bottom" | translate }}
        </span>
      </button>
    }
  }

  <!-- Duplicate -->
  @if (widget.widgetType !== "branding") {
    <button
      class="ac-menu-item"
      cdkMenuItem
      (click)="onContextMenu('duplicate')"
    >
      <span class="text">
        <i class="fa-regular fa-copy icon"></i>
        {{ "context_menu.duplicate_widget" | translate }}
      </span>
    </button>
  }

  <!-- Delete -->
  <button class="ac-menu-item" cdkMenuItem (click)="onContextMenu('delete')">
    <span class="text">
      <i class="fa-regular fa-trash-can icon"></i>
      {{ "context_menu.delete_widget" | translate }}
    </span>
  </button>

  <!-- Add to Library -->
  @if (widget.widgetType !== "branding") {
    <button class="ac-menu-item" cdkMenuItem (click)="onContextMenu('library')">
      <span class="text">
        <i class="fa-regular fa-book-open-reader icon"></i>
        {{ "context_menu.library_widget" | translate }}
      </span>
    </button>
  }
</div>
