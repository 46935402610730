import {Component, Inject, OnInit, Optional} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
  ApiService,
  DialogService,
  NotificationService,
} from 'src/app/core/services';
import {User} from '../../models';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {Subscription} from 'rxjs';
import { NgClass } from '@angular/common';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { LowercaseDirective } from '../../directives/lowercase.directive';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-new-user-dialog',
    templateUrl: './new-user-dialog.component.html',
    styleUrls: ['./new-user-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgxIntlTelInputModule,
        LowercaseDirective,
        MatCheckbox,
        MatDialogActions,
        TranslateModule,
    ],
})
export class NewUserDialogComponent implements OnInit {
  // State Variables
  isLoading = false;

  // Properties
  userForm: UntypedFormGroup;
  user: User;
  isEdit: boolean;
  public isExternal: boolean;
  subs: Subscription = new Subscription();

  constructor(
    private apiService: ApiService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    @Optional() public dialogRef: MatDialogRef<NewUserDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA || MAT_BOTTOM_SHEET_DATA) public data
  ) {}

  ngOnInit(): void {
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe((res) => {
          this.dialogService.closeDialog(this.dialogRef);
        })
      );
    }
    if (this.data) {
      this.user = this.data.user;
      this.isEdit = Boolean(this.user);
      this.isExternal = this.data.externalUser;
    }
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(
        this.user ? this.user.firstName : null,
        Validators.required
      ),
      lastName: new UntypedFormControl(
        this.user ? this.user.lastName : null,
        Validators.required
      ),
      email: new UntypedFormControl(this.user ? this.user.email : null, [
        Validators.required,
        Validators.email,
      ]),
      isAdmin: new UntypedFormControl(this.user ? this.user.isAdmin : false),
    });
  }

  onSubmit(): void {
    if (this.userForm.invalid) {
      Object.values(this.userForm.controls).forEach((control) => {
        if (!control.value) {
          control.markAsDirty();
        }
      });
    } else {
      if (this.isEdit) {
        this.updateUser();
      } else {
        this.addNewUser();
      }
    }
  }

  addNewUser(): void {
    const newUser = this.userForm.value;
    Object.keys(newUser).map(
      (u) =>
        (newUser[u] =
          typeof newUser[u] === 'string' ? newUser[u].trim() : newUser[u])
    );
    this.isLoading = true;

    if (this.isExternal) {
      newUser.externalUser = true;
      newUser.sendWelcomeEmail = true;
    }

    this.apiService.createUser(newUser).subscribe({
      next: (res) => {
        this.user = res;
      },
      error: (error) => {
        this.notificationService.error(error.error.message, 5000);
        this.isLoading = false;
      },
      complete: () => {
        this.translate
          .get('notifications.user_created')
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });

        if (this.data?.externalUser) {
          this.closeDialog({
            type: 'userCreated',
            user: this.user,
          });
        } else {
          this.closeDialog(true);
        }
      },
    });
  }

  updateUser(): void {
    this.isLoading = true;
    const userInfo = this.userForm.value;
    Object.keys(userInfo).map(
      (u) =>
        (userInfo[u] =
          typeof userInfo[u] === 'string' ? userInfo[u]?.trim() : userInfo[u])
    );

    this.apiService.updateUserDetails(this.user._id!, userInfo).subscribe({
      error: (err) => {
        this.isLoading = false;
        this.translate
          .get('notifications.error_updating_user', {message: err?.message})
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
      },
      complete: () => {
        this.isLoading = false;
        this.translate
          .get('notifications.user_updated', {username: this.user.name})
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
        this.closeDialog(true);
      },
    });
  }

  closeDialog(emitValue?): void {
    this.dialogService.closeDialog(this.dialogRef, emitValue);
  }
}
