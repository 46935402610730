import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-unauthorized-page',
    templateUrl: './unauthorized-page.component.html',
    styleUrls: ['./unauthorized-page.component.scss'],
    standalone: true,
    imports: [LottieComponent, TranslateModule],
})
export class UnauthorizedPageComponent implements OnInit {
  //Inputs/Outputs
  @Input() title: string;
  @Input() message: string;
  @Input() buttonText: string;
  @Input() lottiePath: string;
  @Output() actionToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public options: AnimationOptions;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.options = {
      path: this.lottiePath,
    };
  }

  toggleAction(): void {
    this.actionToggled.emit(true);
  }
}
