<div
  class="report-toolbar"
  data-cy-reportToolbar="report-toolbar"
  [ngClass]="{'external-toolbar': isExternal}"
>
  <div
    class="d-flex align-items-center gap-2"
    [ngClass]="{'w-100 justify-content-between': isMobile}"
  >
    @if (!isExternal && !isMobile) {
      <!-- Toggle Edit Mode -->
      <button
        id="toggle-edit-mode-btn"
        class="wsm-btn wsm-btn-icon wsm-btn-small"
        data-cy-editModebtn="toggle-edit-mode-btn"
        (click)="toggleEditMode()"
        matTooltip="{{
          (isEditMode
            ? 'reporting.reportToolbar.preview_label'
            : 'reporting.reportToolbar.edit_label'
          ) | translate
        }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
      >
        <i class="fa-regular" [ngClass]="isEditMode ? 'fa-eye' : 'fa-edit'"></i>
      </button>
      <!-- Save Report Button -->
      <button
        (click)="saveMode()"
        [disabled]="!hasChanges() || isReportLoading"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        data-cy-saveReportbtn="save-report-btn"
        matTooltip="{{ 'reporting.reportToolbar.save_label' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
      >
        <i class="fa-regular fa-floppy-disk"></i>
      </button>
      <!-- Undo Change Button -->
      <button
        (click)="changePageState('undo')"
        [disabled]="pageStatesLength - pageStateIndex < 1 || isReportLoading"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        data-cy-undoChangebtn="undo-change-btn"
        [matTooltip]="undoTooltip"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
      >
        <i class="fa-regular fa-arrow-rotate-left"></i>
      </button>
      <!-- Redo Change Button -->
      <button
        (click)="changePageState('redo')"
        [disabled]="
          pageStatesLength - pageStateIndex > pageStatesLength ||
          pageStatesLength - pageStateIndex + 1 === pageStatesLength ||
          isReportLoading
        "
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        data-cy-redoChangebtn="redo-change-btn"
        [matTooltip]="redoTooltip"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
      >
        <i class="fa-regular fa-arrow-rotate-right"></i>
      </button>
    }

    <!-- Edit mode only buttons -->
    @if (isEditMode) {
      <!-- Add Widget Button -->
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon d-none d-lg-block"
        data-cy-addWidgetbtn="add-widget-btn"
        [matMenuTriggerFor]="widgetMenu"
        matTooltip="{{
          'reporting.reportToolbar.new_widget_label' | translate
        }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        [disabled]="isReportLoading"
        [ngClass]="{'action-disabled': isReportLoading}"
      >
        <i class="fa-regular fa-plus"></i>
      </button>
      <!-- Reorder Pages Button -->
      <button
        (click)="openReorderPages()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon d-none d-lg-block"
        data-cy-reorderPagebtn="reorder-page-btn"
        matTooltip="{{
          'reporting.reportToolbar.reorder_pages_label' | translate
        }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        [disabled]="isReportLoading"
        [ngClass]="{'action-disabled': isReportLoading}"
      >
        <i class="fa-regular fa-arrow-right-arrow-left"></i>
      </button>
    }

    <!-- Save Report as Template Button -->
    @if (!isExternal && !isMobile) {
      <button
        (click)="onSaveTemplate()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon d-none d-lg-block"
        data-cy-saveReportbtn="save-report-btn"
        matTooltip="{{
          'reporting.reportToolbar.save_report_template_label' | translate
        }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        [disabled]="isReportLoading"
        [ngClass]="{'action-disabled': isReportLoading}"
      >
        <i class="fa-regular fa-folders"></i>
      </button>
    }

    <!-- Report Settings Button -->
    @if (!isExternal && !isMobile) {
      <button
        (click)="onEditReportModal()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon d-none d-lg-block"
        data-cy-reportSettingbtn="report-settings-btn"
        matTooltip="{{ 'reporting.reportToolbar.settings_label' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        [disabled]="isReportLoading"
        [ngClass]="{'action-disabled': isReportLoading}"
      >
        <i class="fa-regular fa-gear"></i>
      </button>
    }

    <!-- Separator -->
    @if (!isExternal && !isMobile) {
      <div class="separator mx-3 d-none d-lg-block"></div>
    }
    <!-- --------- -->

    <!-- Report History Button -->
    <button
      (click)="openReportHistory()"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      data-cy-reportHistorybtn="report-history-btn"
      matTooltip="{{
        'reporting.reportToolbar.report_history_label' | translate
      }}"
      matTooltipPosition="below"
      matTooltipClass="wsm-tooltip tooltip-below"
      [disabled]="isReportLoading"
      [ngClass]="{'action-disabled': isReportLoading}"
    >
      <i class="fa-regular fa-clock-rotate-left"></i>
    </button>

    <!-- Presentation Mode Button -->
    @if (!isMobile) {
      <button
        (click)="openFullScreen()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        matTooltip="{{ 'reporting.reportToolbar.present_label' | translate }}"
        data-cy-presentationModebtn="presentation-mode-btn"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        [disabled]="isReportLoading"
        [ngClass]="{'action-disabled': isReportLoading}"
      >
        <i class="fa-regular fa-presentation-screen"></i>
      </button>
    }

    <!-- Share Report Button -->
    @if (!isExternal) {
      <button
        #shareReportTrigger="matMenuTrigger"
        [matMenuTriggerFor]="shareReportMenu"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        data-cy-shareReportbtn="share-report-btn"
        [ngClass]="{
          'menu-open': shareReportTrigger.menuOpen,
          'action-disabled': isReportLoading,
        }"
        matTooltip="{{ 'reporting.reportToolbar.share_label' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        [disabled]="isReportLoading"
      >
        <i class="fa-regular fa-share-nodes"></i>
      </button>
    }
    <mat-menu #shareReportMenu="matMenu" class="share-menu mt-3">
      <button (click)="openShareReportDialog('email')" mat-menu-item>
        {{ "reporting.reportToolbar.email_report_label" | translate }}
      </button>
      <button
        (click)="openAddUsersDialog()"
        mat-menu-item
        [disabled]="!canShareWith"
      >
        {{ "reporting.reportToolbar.add_users_label" | translate }}
        @if (!canShareWith) {
          <app-upgrade-button [showLabel]="false" />
        }
      </button>
      <button (click)="openShareLinkDialog()" mat-menu-item>
        {{ "reporting.reportToolbar.shareable_link" | translate }}
      </button>
    </mat-menu>

    <!-- Download Report Button -->
    <button
      (click)="getReportPdf()"
      id="downloadReportBtn"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon download-pdf-btn"
      data-cy-downloadReportbtn="download-report-btn"
      [disabled]="loadingPdf || isReportLoading"
      matTooltip="{{
        (loadingPdf
          ? 'reporting.reportToolbar.downloading_report_label'
          : 'reporting.reportToolbar.download_report_label'
        ) | translate
      }}"
      matTooltipPosition="below"
      matTooltipClass="wsm-tooltip tooltip-below"
      [ngClass]="{'action-disabled': isReportLoading}"
    >
      @if (!loadingPdf) {
        <i id="downloadReportIcon" class="fa-regular fa-arrow-down-to-line"></i>
      }
      @if (loadingPdf) {
        <i class="spinner-border spinner-border-sm spinner-text" role="status">
          <span class="visually-hidden">Loading...</span>
        </i>
      }
    </button>

    <!-- Data Options Button -->
    @if (!isExternal) {
      <button
        #dataMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="dataMenu"
        matTooltip="{{ 'reporting.reportToolbar.data_label' | translate }}"
        matTooltipPosition="below"
        matTooltipClass="wsm-tooltip tooltip-below"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon download-pdf-btn"
        data-cy-dataOptionsbtn="data-options-button"
        [ngClass]="{'action-disabled': isReportLoading}"
      >
        <div class="position-relative">
          <i class="fa-regular fa-arrows-rotate"></i>
          @if (
            dataQueueStatue().processPending || dataQueueStatue().processError
          ) {
            <div
              class="notification-bubble icon-corner"
              [ngClass]="{
                'warning': dataQueueStatue().processError,
                'counter':
                  (dataQueueStatue().processPending ||
                    dataQueueStatue().processQueue) &&
                  !dataQueueStatue().processError,
              }"
            >
              <i class="fa-solid fa-exclamation"></i>
            </div>
          }
        </div>
      </button>
    }
    <mat-menu #dataMenu="matMenu" class="mt-3">
      <button
        (click)="openDataQueue()"
        mat-menu-item
        class="d-flex align-items-center"
        data-cy-dataQueue="open-dataqueue-btn"
      >
        <span>{{
          "reporting.reportToolbar.data_queue_button" | translate
        }}</span>
        @if (
          dataQueueStatue().processPending || dataQueueStatue().processError
        ) {
          <div
            class="notification-bubble menu-item"
            [ngClass]="{
              'warning': dataQueueStatue().processError,
              'counter':
                dataQueueStatue().processPending &&
                !dataQueueStatue().processError,
            }"
          >
            <i class="fa-solid fa-exclamation"></i>
          </div>
        }
      </button>
      <button
        (click)="refreshData()"
        mat-menu-item
        class="d-flex align-items-center"
        data-cy-refreshDatabtn="refresh-data-btn"
        [disabled]="
          dataQueueStatue().processStatus === 'RUNNING' ||
          dataQueueStatue().processStatus === 'PENDING' ||
          dataQueueStatue().processStatus === 'QUEUE' ||
          isReportLoading ||
          refreshingData
        "
      >
        <span>{{
          "reporting.reportToolbar.sync_data_button" | translate
        }}</span>
      </button>
      <button
        (click)="clearReportCache()"
        mat-menu-item
        data-cy-clearReportCachebtn="clear-reportcache-btn"
      >
        {{ "reporting.reportToolbar.clear_cache_button" | translate }}
      </button>
    </mat-menu>
  </div>

  @if (!isMobile) {
    <div class="message-block me-2">
      @if (hasChanges() && !isReportLoading) {
        <div class="d-flex align-items-center">
          <i class="fa-regular fa-cloud-exclamation me-2"></i>
          <span>{{
            "reporting.reportToolbar.unsaved_changes_label" | translate
          }}</span>
        </div>
      }
      @if (isReportLoading) {
        <div class="pe-4">
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
            data-cy-reportLoading="is-report-loading"
          ></span>
          {{ "reporting.reportToolbar.loading_label" | translate }}
        </div>
      }
      @if (infoMessages.length !== 0) {
        <div
          class="message-icon-container"
          [satPopoverAnchor]="popInfo"
          (mouseenter)="popInfo.open()"
          (mouseleave)="popInfo.close()"
        >
          <i class="fa-regular fa-circle-info info-icon me-2"></i>
        </div>
        <sat-popover
          #popInfo
          horizontalAlign="end"
          verticalAlign="below"
          panelClass="custom-sat-popover"
        >
          <app-report-notification [status]="'info'" [messages]="infoMessages">
          </app-report-notification>
        </sat-popover>
      }
      @if (warningMessages.length !== 0) {
        <div
          class="message-icon-container"
          [satPopoverAnchor]="popWarning"
          (mouseenter)="popWarning.open()"
          (mouseleave)="popWarning.close()"
        >
          <i class="fa-regular fa-triangle-exclamation warning-icon me-2"></i>
        </div>
        <sat-popover
          #popWarning
          horizontalAlign="end"
          verticalAlign="below"
          panelClass="custom-sat-popover"
        >
          <app-report-notification
            [status]="'warning'"
            [messages]="warningMessages"
          >
          </app-report-notification>
        </sat-popover>
      }
    </div>
  }
</div>

<mat-menu
  #widgetMenu="matMenu"
  class="widget-list mt-3"
  data-cy-widgetList="widget-list"
>
  <app-widget-toolbar
    (widgetSelection)="onWidgetSelect($event)"
  ></app-widget-toolbar>
</mat-menu>
