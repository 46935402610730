import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  of,
  tap,
  Subscription,
} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {ApiService, NotificationService} from 'src/app/core/services';
import {CustomError, ServiceAccount} from 'src/app/shared/models';
import {NgClass} from '@angular/common';
import {AlphanumericDirective} from '../../../directives/alphanumeric.directive';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
} from '@angular/material/expansion';
import {MatCheckbox} from '@angular/material/checkbox';
import {HighlightTextPipe} from '../../../pipes/highlight.pipe';
import {ConfirmationDialogComponent} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    AlphanumericDirective,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatCheckbox,
    TranslateModule,
    HighlightTextPipe,
  ],
})
export class AccountSelectorComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() selectedAccounts: ServiceAccount[];
  @Input() isMobile: boolean;
  @Input() isFromStarted = false;
  @Output() panelClosed: EventEmitter<string> = new EventEmitter<string>();
  @Output() serviceUpdate = new EventEmitter<ServiceAccount[]>();

  // Form Controls
  searchAccountControl: UntypedFormControl = new UntypedFormControl('');

  // Properties
  serviceAccounts: ServiceAccount[] = [];
  categories: {id: string; label: string}[] = [
    {
      id: 'ppc',
      label: 'connections.manager.tabs.pay_per_click',
    },
    {
      id: 'seo',
      label: 'connections.manager.tabs.seo',
    },
    {
      id: 'social',
      label: 'connections.manager.tabs.social_media',
    },
    {
      id: 'lead_management',
      label: 'connections.manager.tabs.lead_management',
    },
  ];
  categoryCounter: object = {};
  toHighlight: string;
  accountSelection = new SelectionModel<any>(true, []);
  originalAccounts: ServiceAccount[];

  // State Variables
  isLoading = false;
  accountsLoading = false;
  expansionPanels: {expanded: boolean}[] = [];
  unsavedChanges = false;

  constructor(
    private notificationService: NotificationService,
    private apiService: ApiService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.originalAccounts = [...this.selectedAccounts];
    this.getAccounts();
    this.categories.forEach((category): void => {
      this.categoryCounter[category.id] = [];
      this.expansionPanels.push({expanded: false});
    });

    if (this.isMobile) {
      this.serviceAccounts = [...this.originalAccounts];
      this.setAccountCategory();
      this.selectManyAccounts();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAccounts && this.originalAccounts) {
      // Logic for when an account is removed from outside the selector.
      this.selectManyAccounts();
      this.unsavedChanges = true;
    }
  }

  getAccounts(): void {
    this.searchAccountControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((): boolean => (this.accountsLoading = true)),
        switchMap((val) => {
          if (!val.trim()) {
            return of(this.isMobile ? this.selectedAccounts : []);
          }
          this.toHighlight = val;
          return this.apiService.getServiceAccountsTypeAhead(val);
        })
      )
      .subscribe({
        next: (res): void => {
          this.serviceAccounts = res;
          this.setAccountCategory();
          this.selectManyAccounts();
          this.accountsLoading = false;
        },
        error: (err: CustomError): void => {
          this.notificationService.error(err?.message, 5000);
          this.accountsLoading = false;
        },
      });
  }

  private setAccountCategory(): void {
    this.serviceAccounts.forEach((sa: ServiceAccount): void => {
      sa.logo = this.getAccountTypeLogo(sa.type);

      if (
        sa.type === 'adwords' ||
        sa.type === 'bing' ||
        sa.type === 'facebook-ads' ||
        sa.type === 'linkedin-ads'
      ) {
        sa.category = 'ppc';
      }

      if (
        sa.type === 'google-analytics' ||
        sa.type === 'google-analytics-v4' ||
        sa.type === 'google-search-console' ||
        sa.type === 'semrush'
      ) {
        sa.category = 'seo';
      }

      if (
        sa.type === 'sm-instagram-insights' ||
        sa.type === 'instagram-insights' ||
        sa.type === 'youtube' ||
        sa.type === 'facebook-insights' ||
        sa.type === 'linkedin-insights'
      ) {
        sa.category = 'social';
      }

      if (sa.type === 'call-rail' || sa.type === 'call-tracking-metrics') {
        sa.category = 'lead_management';
      }
    });

    this.categories.forEach((category): void => {
      this.categoryCounter[category.id] = this.serviceAccounts.filter(
        (sa: ServiceAccount): boolean => sa.category === category.id
      );
    });
  }

  selectManyAccounts(): void {
    this.accountSelection.clear();
    if (this.selectedAccounts?.length > 0) {
      this.selectedAccounts.forEach((selectedAccount) => {
        const selectedFound = this.serviceAccounts.find(
          (sa) => sa.accountId === selectedAccount.accountId
        );
        if (selectedFound) {
          if (this.accountSelection.isSelected(selectedFound)) {
            return;
          }
          this.accountSelection.select(selectedFound);
        }
      });
    }
  }

  setExpansionStatus(status: boolean, index: number): void {
    this.expansionPanels[index].expanded = status;
  }

  getAccountTypeLogo(accountType: string): string | undefined {
    switch (accountType) {
      case 'adwords':
        return '/assets/img/svg/connections/normal/gads.svg';
      case 'bing':
        return '/assets/img/svg/connections/normal/mads.svg';
      case 'call-rail':
        return '/assets/img/svg/connections/normal/call-rail.svg';
      case 'call-tracking-metrics':
        return '/assets/img/svg/connections/normal/ctm.svg';
      case 'facebook-insights':
      case 'facebook-ads':
        return '/assets/img/svg/connections/normal/facebook.svg';
      case 'google-search-console':
        return '/assets/img/svg/connections/normal/google-search-console.svg';
      case 'google-analytics':
        return '/assets/img/svg/connections/normal/analytics.svg';
      case 'google-analytics-v4':
        return '/assets/img/svg/connections/normal/analytics.svg';
      case 'youtube':
        return '/assets/img/svg/connections/normal/youtube.svg';
      case 'instagram-insights':
        return '/assets/img/svg/connections/normal/instagram.svg';
      case 'linkedin-insights':
      case 'linkedin-ads':
        return '/assets/img/svg/connections/normal/linkedin-icon.svg';
      case 'semrush':
        return '/assets/img/svg/connections/normal/semrush.svg';
      default:
        break;
    }
  }

  selectAccount(serviceAccount: ServiceAccount): void {
    this.unsavedChanges = true;
    const found = this.selectedAccounts.find(
      (sa: ServiceAccount): boolean => sa.accountId === serviceAccount.accountId
    );
    if (found) {
      this.selectedAccounts.splice(this.selectedAccounts.indexOf(found), 1);
      this.accountSelection.deselect(serviceAccount);
    } else {
      this.accountSelection.select(serviceAccount);
      this.selectedAccounts.push(serviceAccount);
    }

    if (this.isFromStarted) {
      this.serviceUpdate.emit(this.selectedAccounts);
    }
  }

  warnUnsavedChanges(): void {
    if (this.unsavedChanges) {
      const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
        this.dialog.open(ConfirmationDialogComponent, {
          maxWidth: this.isMobile ? '100vw' : '35vw',
          width: this.isMobile ? '70vw' : '',
          data: {
            title: this.translate.instant(
              'company.account_selector.unsaved_changes_title'
            ),
            content: this.translate.instant(
              'company.account_selector.unsaved_changes_desc'
            ),
            button: this.translate.instant(
              'company.account_selector.keep_editing_button'
            ),
            confirmButton: this.translate.instant(
              'company.account_selector.discard_button'
            ),
            isDelete: false,
            showIcon: false,
          },
        });

      const dialogSub: Subscription = this.router.events.subscribe((): void => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.discardChanges();
          this.panelClosed.emit('discard');
        }
        dialogSub.unsubscribe();
      });
    } else {
      this.panelClosed.emit('discard');
    }
  }

  discardChanges(): void {
    const accountsToDelete: ServiceAccount[] = this.selectedAccounts.filter(
      (selectedAccount) => {
        return !this.originalAccounts.some(
          (ac: ServiceAccount): boolean =>
            ac.accountId === selectedAccount.accountId
        );
      }
    );

    accountsToDelete.forEach((account: ServiceAccount): void => {
      this.selectedAccounts.splice(this.selectedAccounts.indexOf(account), 1);
      this.accountSelection.deselect(account);
    });

    // Add original accounts to selected accounts array
    this.selectedAccounts.length = 0;
    this.originalAccounts.forEach((account: ServiceAccount): void => {
      this.selectedAccounts.push(account);
      this.accountSelection.select(
        this.serviceAccounts.find(
          (sa: ServiceAccount): boolean => sa.accountId === account.accountId
        )
      );
    });
    this.unsavedChanges = false;
  }

  saveChanges(): void {
    this.panelClosed.emit('save');
  }
}
