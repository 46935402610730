import {CommonModule} from '@angular/common';
import {Component, input, model, output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-top-notification',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatCheckboxModule, FormsModule],
  templateUrl: './top-notification.component.html',
  styleUrl: './top-notification.component.scss',
})
export class TopNotificationComponent {
  // Inputs / Outputs
  public message = input<string>('');
  public isMobile = input<boolean>(false);
  public onlyButton = input<boolean>(false);
  public closeNotification = output<boolean>();

  // Properties
  checked = model(false);

  onCloseNotification(): void {
    this.closeNotification.emit(this.checked());
  }

  onClose(): void {
    this.closeNotification.emit(true);
  }

  handleToggle(event: MatCheckboxChange): void {
    this.closeNotification.emit(event.checked);
  }
}
