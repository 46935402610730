<div
  class="pages-container px-2"
  #pagesContainer
  cdkDropList
  cdkDropListLockAxis="y"
  cdkDropListOrientation="vertical"
  cdkDropListAutoScrollStep="10"
  [cdkDropListDisabled]="!isEditMode || isReportLoading"
  (cdkDropListDropped)="drop($event)"
>
  @for (page of reportPages; track page.id; let i = $index) {
    <div
      [id]="'card' + i"
      class="card-container"
      [ngClass]="{
        'is-active': page.isActive,
        'edit-mode': isEditMode,
        'dragging': isDragging,
        'menu-open': menuOpen,
      }"
      cdkDrag
      cdkDragBoundary=".pages-container"
      [cdkDragDisabled]="!page.isActive"
      (cdkDragMoved)="onPageDrag($event)"
      (click)="$event.stopPropagation(); selectPage(page, i)"
    >
      <app-navigator-card
        class="flex-grow-1"
        [page]="page"
        [reportId]="reportId"
        [isEditMode]="isEditMode"
        [index]="i"
        [canEditInline]="!!page.isActive && (isEditMode || isExternalUser)"
      >
      </app-navigator-card>
      @if (
        page.isActive && from === "report" && (isEditMode || !isExternalUser)
      ) {
        <button
          #pageMenu="matMenuTrigger"
          (click)="$event.stopPropagation()"
          (menuOpened)="menuOpen = pageMenu.menuOpen"
          (menuClosed)="menuOpen = pageMenu.menuOpen"
          [matMenuTriggerFor]="pageSettingsMenu"
          [disabled]="isReportLoading"
          [ngClass]="{'action-disabled': isReportLoading}"
          class="page-settings btn wsm-button mb-0 d-flex p-0 me-1"
          type="button"
        >
          <span class="fa-regular fa-chevron-down" title="Page Options"></span>
        </button>
      }
      <mat-menu
        #pageSettingsMenu="matMenu"
        class="mat-menu-gray menu-items-end"
      >
        @if (!isExternalUser) {
          <button (click)="onEditPageSettings()" mat-menu-item>
            {{
              "shared.components.report_template_navigator.settings_button"
                | translate
            }}
          </button>
        }
        @if (!isExternalUser) {
          <button (click)="onDuplicatePage(page)" mat-menu-item>
            {{
              "shared.components.report_template_navigator.duplicate_button"
                | translate
            }}
          </button>
        }
        @if (!isExternalUser) {
          <button (click)="onHidePage(page)" mat-menu-item>
            {{
              (page.isVisible
                ? "shared.components.report_template_navigator.hide_page_button"
                : "shared.components.report_template_navigator.show_page_button"
              ) | translate
            }}
          </button>
        }
        @if (!isExternalUser) {
          <button (click)="onSaveTemplate()" mat-menu-item>
            {{
              "shared.components.report_template_navigator.add_to_library_button"
                | translate
            }}
          </button>
        }
        @if (this.reportPages.length !== 1 && !isExternalUser) {
          <button (click)="onDeletePage(page)" mat-menu-item>
            {{
              "shared.components.report_template_navigator.delete_button"
                | translate
            }}
          </button>
        }
      </mat-menu>
      @if (!page.isVisible) {
        <div class="visibility-overlay">
          <i class="fa-regular fa-eye-slash"></i>
        </div>
      }
    </div>
  }
</div>
