<div class="carousel-wrapper">
  @if (showControls()) {
    <div [ngClass]="{'d-none': leftControl()}" class="control left-control">
      <button
        [disabled]="leftControl()"
        (click)="onPrev()"
        class="wsm-btn wsm-btn-round-icon"
      >
        <i class="fa-regular fa-arrow-left"></i>
      </button>
    </div>
  }
  <div
    #tList
    class="template-list"
    [ngClass]="{
      'pe-0 ps-0': !showControls(),
      'h-16-5 pb-5': from() === 'carousel'
    }"
  >
    @for (tpl of templateCards(); track tpl) {
      <app-template-gallery-card
        class="template-item"
        [templateCard]="tpl"
        [type]="'report'"
        [from]="'main'"
        (templateFavorite)="onTemplateFavorite($event)"
        (templateSelected)="onTemplateSelected($event)"
        (templatePreview)="onTemplatePreview($event)"
      >
      </app-template-gallery-card>
    }
  </div>
  @if (showControls()) {
    <div [ngClass]="{'d-none': rightControl()}" class="control right-control">
      <button
        [disabled]="rightControl()"
        (click)="onNext()"
        class="wsm-btn wsm-btn-round-icon"
      >
        <i class="fa-regular fa-arrow-right"></i>
      </button>
    </div>
  }
</div>
