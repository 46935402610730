import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import {
  ApiService,
  DialogService,
  NotificationService,
  ReportService,
  TrackingService,
} from 'src/app/core/services';
import {AddUserAccesPayload, CustomError, User} from '../../models';
import { NgClass, AsyncPipe } from '@angular/common';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatOption } from '@angular/material/core';
import { LoadingComponent } from '../../components/loading/loading.component';
import { HighlightTextPipe } from '../../pipes/highlight.pipe';
import { AsPipe } from '../../pipes/as-type.pipe';

@Component({
    selector: 'app-share-to-user-dialog',
    templateUrl: './share-to-user-dialog.component.html',
    styleUrls: ['./share-to-user-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogContent,
        NgClass,
        FormsModule,
        MatAutocompleteTrigger,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatAutocomplete,
        MatOption,
        MatDialogActions,
        LoadingComponent,
        AsyncPipe,
        TranslateModule,
        HighlightTextPipe,
        AsPipe,
    ],
})
export class ShareToUserDialogComponent implements OnInit, OnDestroy {
  // State variables
  isLoading: boolean;
  usersLoading: boolean;
  usersDeleted = false;

  // Properties
  subs: Subscription = new Subscription();
  users: User[] = [];

  // Filter properties
  externalUserSearch: UntypedFormControl = new UntypedFormControl();
  searchLoading: boolean;
  searchNoResults: boolean;
  filteredUsers: Observable<User[]>;
  UserModel!: User;
  toHighlight: string;

  constructor(
    public dialogRef: MatDialogRef<ShareToUserDialogComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe((): void => {
          this.onClose();
        })
      );
    }
    this.getUsersWithAccess();

    // Fire typeahead request
    this.filteredUsers = this.externalUserSearch?.valueChanges.pipe(
      tap((): void => {
        this.searchLoading = true;
      }),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val): Observable<User[]> => {
        if (!val.trim) {
          this.searchNoResults = false;
          return of([]);
        }
        return this.filterUsers(val) as Observable<User[]>;
      })
    );
  }

  getUsersWithAccess(): void {
    this.usersLoading = true;
    if (this.data.isMobile && this.users.length === 0) {
      this.isLoading = true;
    }
    this.reportService.getUsersWithAccess(this.data.reportId).subscribe({
      next: (res: User[]): void => {
        this.users = res;
      },
      error: (err) => {
        this.usersLoading = false;
        this.isLoading = false;
        this.notificationService.error(err?.message, 5000);
      },
      complete: (): void => {
        if (this.data.user) {
          this.users.push(this.data.user);
        }
        this.usersLoading = false;
        this.isLoading = false;
      },
    });
  }

  openCreateUserDialog(): void {
    this.onClose({openCreateUser: true});
  }

  onRemoveUser(user: User): void {
    const userIndex = this.users.indexOf(user);
    this.users.splice(userIndex, 1);
    this.usersDeleted = true;
  }

  onShareReport(): void {
    this.isLoading = true;
    const users = {
      users: this.users.map((user: User) => user._id),
    } as AddUserAccesPayload;

    this.reportService.addUserAccess(users, this.data.reportId).subscribe({
      error: (err: CustomError): void => {
        this.isLoading = false;
        this.notificationService.error(err?.message as string, 5000);
      },
      complete: (): void => {
        this.trackingService.trackEvent('Share Report to External User');
        this.isLoading = false;
        this.usersDeleted = false;
        this.translate
          .get('notifications.access_list_updated')
          .subscribe((res: string): void => {
            this.notificationService.success(res, 5000);
          });
        this.onClose();
      },
    });
  }

  filterUsers(val: string): Observable<User[]> | undefined {
    this.toHighlight = val;
    this.searchLoading = false;
    return this.apiService.getExternalUsersTypeAhead(val).pipe(
      map((response) => {
        this.searchNoResults = response.length === 0;
        return response.filter((option) =>
          option.name.toLowerCase().includes(val.toLocaleLowerCase())
        );
      })
    );
  }

  onUserSelect(event: MatAutocompleteSelectedEvent): void {
    const externalUser: User = event.option.value;
    if (!this.users.some((user) => user._id === externalUser._id)) {
      this.users.push(externalUser);
    } else {
      this.translate
        .get('notifications.user_exists')
        .subscribe((res: string) => {
          this.notificationService.error(res, 5000);
        });
    }
    this.searchLoading = false;
    this.externalUserSearch.setValue('');
  }

  onClose(emitValue?: any): void {
    this.dialogService.closeDialog(this.dialogRef, emitValue);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
