import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appMaxLength]',
    standalone: true,
})
export class MaxLengthDirective {
  @Input() appMaxLength;
  excludedKeys = [107, 109, 187, 189, 9];
  inputType: string;
  element: ElementRef;

  constructor(private el: ElementRef) {
    this.element = el;
    this.inputType = this.el.nativeElement.attributes.type.value;
  }

  @HostListener('keydown', ['$event']) onKeydown(event): void {
    const value = event.target.value;
    const maxLength = parseInt(this.appMaxLength);
    if (
      (value.length > maxLength - 1 && event.keyCode !== 8) ||
      (this.inputType === 'number' && this.excludedKeys.includes(event.keyCode))
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.keyCode === 8) {
      this.element.nativeElement.value = '';
      this.element.nativeElement.dispatchEvent(new Event('input'));
    }
  }
}
