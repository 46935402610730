import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-editable-title',
  templateUrl: './editable-title.component.html',
  styleUrls: ['./editable-title.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    CdkTrapFocus,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgTemplateOutlet,
    TranslateModule,
  ],
})
export class EditableTitleComponent implements OnInit, OnChanges {
  //Inputs/Outputs
  @Input() titleContent: string;
  @Input() editMode: boolean;
  @Output() titleSave: EventEmitter<string> = new EventEmitter<string>();

  @Input() useAs: 'header' | 'body';
  @Input() inputSize: 'w-50' | 'w-100' = 'w-50';

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  //State Variables
  isEditing: boolean;
  valueChanged: boolean;

  //Form Controls
  title: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.pattern(/^(?!\s)(?=.*\S{3,})(?!^\s).+$/),
  ]);

  //Subscriptions
  subs: Subscription = new Subscription();

  constructor() {
    //
  }

  ngOnInit(): void {
    this.title.setValue(this.titleContent);

    this.subs.add(
      this.title.valueChanges.subscribe({
        next: (value: string) => {
          this.valueChanged = value !== this.titleContent;
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.title.setValue(this.titleContent);
    }
  }

  editTitle(event: Event): void {
    if (
      (event.type === 'click' || event.type === 'blur') &&
      this.isEditing &&
      this.valueChanged
    ) {
      this.titleSave.emit(this.title.value);
    }
    this.isEditing = !this.isEditing;
  }
}
