<div class="col-12 px-1 mb-3" [formGroup]="widgetDataForm">
  <label for="chartType">{{
    "reporting.widget_editor.data_form.label_chart_type" | translate
  }}</label>
  <mat-select
    #chartType
    [class.border-danger]="
      widgetDataForm.get('chartType')?.errors?.required &&
      widgetDataForm.get('chartType')?.touched
    "
    [panelClass]="'wsm-custom-select'"
    class="wsm-input"
    [disableOptionCentering]="true"
    [formControlName]="'chartType'"
    id="chartType"
    placeholder="{{
      'reporting.widget_editor.data_form.placeholder_chart_type' | translate
    }}"
  >
    @for (chartType of chartTypes; track chartType) {
      <mat-option [value]="chartType.id">
        {{ chartType.name | translate }}
      </mat-option>
    }
  </mat-select>
</div>
