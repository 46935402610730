import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  merge,
  startWith,
  Subscription,
  switchMap,
} from 'rxjs';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {
  WidgetService,
  ReportService,
  NotificationService,
} from 'src/app/core/services';
import {
  WidgetTemplate,
  PageTemplate,
  ReportTheme,
  User,
  TemplateGoal,
} from '../../models';
import {setCharacterEscape} from '../../helpers';
import {MatSelect} from '@angular/material/select';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatOption} from '@angular/material/core';
import {AlphanumericDirective} from '../../directives/alphanumeric.directive';
import {TemplateGalleryCardComponent} from '../../components/template-gallery-card/template-gallery-card.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-template-library-dialog',
  templateUrl: './template-library-dialog.component.html',
  styleUrls: ['./template-library-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogClose,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatOption,
    AlphanumericDirective,
    MatDialogContent,
    TemplateGalleryCardComponent,
    MatDialogActions,
    MatPaginator,
    TranslateModule,
  ],
})
export class TemplateLibraryDialogComponent implements OnInit, OnDestroy {
  // Children pages/elements
  @ViewChild('filterInput', {static: true}) filterInput: ElementRef;
  @ViewChild('templatePaginator', {static: true}) paginator: MatPaginator;

  // State Variables
  isLoading: boolean;

  // Properties
  user: User = JSON.parse(localStorage.getItem('currentUser') as string);
  templates: (WidgetTemplate | PageTemplate)[];
  templateCount: number;
  selectedTemplate: WidgetTemplate | PageTemplate;
  goals: Array<TemplateGoal> = [
    {
      label: 'Traffic',
      id: 'traffic',
      translation: 'shared.dialogs.template_library.traffic_label',
    },
    {
      label: 'Lead Generation',
      id: 'lead_generation',
      translation: 'shared.dialogs.template_library.lead_generation_label',
    },
    {
      label: 'Ecommerce',
      id: 'ecommerce',
      translation: 'shared.dialogs.template_library.ecommerce_label',
    },
  ];
  subs: Subscription = new Subscription();

  // Controls
  page: UntypedFormControl = new UntypedFormControl(1);
  categoryFilter = new UntypedFormControl('');
  loadingTemplatePreview = false;

  constructor(
    private widgetService: WidgetService,
    private reportService: ReportService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TemplateLibraryDialogComponent>,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    switch (this.data.type) {
      case 'widget':
        this.getWidgetTemplates();
        break;
      case 'page':
        this.getPageTemplates();
        break;
    }
  }

  getWidgetTemplates(): void {
    this.subs.add(
      merge(
        this.paginator.page,
        this.categoryFilter.valueChanges,
        fromEvent(this.filterInput.nativeElement, 'keydown')
      )
        .pipe(
          startWith({}),
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(() => {
            this.isLoading = true;
            let owner = '';
            let filter =
              this.categoryFilter.value.length !== 0
                ? this.categoryFilter.value.toString().replaceAll(',', ' ')
                : '';
            if (filter.includes('user')) {
              owner = this.user.id!;
              filter = filter.replace('user', '');
            }

            const params = {
              limit: this.paginator.pageSize,
              page: this.paginator.pageIndex + 1,
              filter: `name contains ${setCharacterEscape(this.filterInput.nativeElement.value)}, goalType in ${setCharacterEscape(filter)}, createdBy equals ${setCharacterEscape(owner)}`,
              pageId: this.data.pageId,
              reportId: this.data.reportId,
            };

            return this.widgetService.getWidgetTemplates(params);
          })
        )
        .subscribe({
          next: (res): void => {
            this.templates = res.data;
            this.templateCount = res.total;
            this.isLoading = false;
          },
          error: (): void => {
            this.isLoading = false;
          },
        })
    );
  }

  getPageTemplates(): void {
    this.subs.add(
      merge(
        this.paginator.page,
        this.categoryFilter.valueChanges,
        fromEvent(this.filterInput.nativeElement, 'keydown')
      )
        .pipe(
          startWith({}),
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(() => {
            this.isLoading = true;
            let owner = '';
            let filter =
              this.categoryFilter.value.length !== 0
                ? this.categoryFilter.value.toString().replaceAll(',', ' ')
                : '';
            if (filter.includes('user')) {
              owner = this.user.id!;
              filter = filter.replace('user', '');
            }

            const params = {
              limit: this.paginator.pageSize,
              page: this.paginator.pageIndex + 1,
              filter: `name contains ${this.filterInput.nativeElement.value}, goalType in ${filter}, createdBy equals ${owner}`,
              orientation: this.data.orientation,
            };

            return this.reportService.getAllPageTemplates(params);
          })
        )
        .subscribe({
          next: (res) => {
            this.templates = res.data;
            this.templateCount = res.total;
            this.isLoading = false;
          },
        })
    );
  }

  addTemplate(selectedTemplate): void {
    const keys = Object.entries(selectedTemplate.theme as ReportTheme);
    keys.forEach(([property, value]) => {
      if (property !== 'transparency' && selectedTemplate.theme) {
        delete selectedTemplate.theme[property];
      }
    });
    const templateData =
      this.data?.type === 'widget'
        ? {...this.data.page, widgets: [selectedTemplate]}
        : {reportPage: selectedTemplate};
    if (this.data?.type === 'widget') {
      this.getWidgetPreview(templateData);
    }
    if (this.data?.type !== 'widget') {
      this.dialogRef.close(templateData);
    }
  }

  getWidgetPreview(templateData): void {
    this.isLoading = true;
    this.loadingTemplatePreview = true;
    this.subs.add(
      this.widgetService
        .previewData(this.data.reportId, this.data.pageId, templateData)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            this.loadingTemplatePreview = false;
            this.dialogRef.close({widget: res.widgets[0]});
          },
          error: (err) => {
            this.isLoading = false;
            this.loadingTemplatePreview = false;
            this.notificationService.error(
              err.message || 'Something went wrong',
              5000
            );
          },
        })
    );
  }

  removeTemplate(templateId: string): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant(
            'shared.dialogs.template_library.delete_template_title'
          ),
          content: this.translate.instant(
            'shared.dialogs.template_library.delete_template_info'
          ),
          button: 'No',
        },
      });

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    this.subs.add(
      dialogRef.afterClosed().subscribe((res): void => {
        if (res) {
          switch (this.data.type) {
            case 'page':
              this.reportService.deletePageTemplate(templateId).subscribe({
                next: (): void => {
                  this.getPageTemplates();
                },
              });
              break;
            case 'widget':
              this.widgetService.deleteWidgetTemplate(templateId).subscribe({
                next: (): void => {
                  this.getWidgetTemplates();
                },
              });
              break;
          }
        }
        dialogSub.unsubscribe();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
