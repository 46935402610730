<section
  class="modern-audio-player"
  [ngStyle]="{background: reportTheme?.backgroundColor}"
>
  <div class="play-pause-btn">
    <i
      class="fa-regular fa-xs"
      [ngClass]="isPlaying ? 'fa-pause' : 'fa-play'"
      [ngStyle]="{color: reportTheme?.bodyColor}"
      (click)="togglePlay()"
    >
    </i>
  </div>

  <div class="controls" [ngStyle]="{color: reportTheme?.bodyColor}">
    <span class="current-time me-1">{{ currentDuration }}</span>
    <mat-slider
      min="0"
      step="1"
      [max]="audio?.duration"
      [disabled]="!audio?.duration"
      [appSliderColor]="reportTheme?.bodyColor || 'white'"
      (input)="skipAround($event)"
    >
      <input matSliderThumb [value]="audio?.currentTime" />
    </mat-slider>
    <span>{{ audioDuration ? audioDuration : "00:00" }}</span>
  </div>

  <div class="volume">
    <i
      class="fa-regular"
      [ngStyle]="{color: reportTheme?.bodyColor}"
      [ngClass]="audio?.muted ? 'fa-volume-slash' : 'fa-volume-high'"
      (click)="muteAudio()"
    >
    </i>

    <div class="volume-controls" [ngClass]="{hidden: !isVolumeOpen}">
      <div class="slider" data-direction="vertical">
        <div class="progress-bar" #volumeProgress>
          <div class="pin"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="show-player">
    <div
      class="fa-regular fa-eye-slash btn-hide ms-2"
      [ngStyle]="{color: reportTheme?.bodyColor}"
      title="Hide Player"
      (click)="closePlayer()"
    ></div>
  </div>
</section>
