import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {ImageWidget, Widget} from 'src/app/shared/models';
import {NgStyle} from '@angular/common';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class ImageComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: any;

  // State
  private initialized = false;

  // Properties
  imageData: ImageWidget = {};
  imageSrc: string | null;
  imageError = false;
  exampleImage =
    'https://triton-cdn-files.s3-us-west-2.amazonaws.com/sample-image.png';

  ngOnInit(): void {
    Object.assign(this.imageData, this.widget.imageData);

    if (!this.widget.imageData?.imageSrc) {
      this.widget.imageData!.imageSrc = this.exampleImage;
      this.imageSrc = this.exampleImage;
    }

    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }

    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      if (this.widget.imageData?.imageSrc) {
        this.imageSrc = this.widget.imageData.imageSrc;
      }

      if (this.widget?.imageData?.imageFit) {
        this.imageData.imageFit = this.widget.imageData.imageFit;
      }
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue !== undefined
    ) {
      if (changes.widgetStyleForm.currentValue.type === 'style') {
        if (changes.widgetStyleForm.currentValue.imageData) {
          if (
            changes.widgetStyleForm.currentValue.imageData.imageFit !== null &&
            changes.widgetStyleForm.currentValue.imageData.imageFit !== ''
          ) {
            this.widget.imageData!.imageFit =
              changes.widgetStyleForm.currentValue.imageData.imageFit;
          }

          if (
            changes.widgetStyleForm.currentValue.imageData.imageSrc !== null &&
            !changes.widgetStyleForm.currentValue.imageData.fileName
          ) {
            const imgSrc =
              changes.widgetStyleForm.currentValue.imageData.imageSrc;
            if (this.isAValidUrl(imgSrc)) {
              this.widget.imageData!.imageSrc =
                changes.widgetStyleForm.currentValue.imageData.imageSrc;
              this.imageSrc = this.widget.imageData!.imageSrc;
            } else {
              this.widget.imageData!.imageSrc = this.exampleImage;
              this.imageSrc = this.exampleImage;
            }
            this.imageError = false;
          }

          if (
            changes.widgetStyleForm.currentValue.imageData.imageSrc !== null &&
            changes.widgetStyleForm.currentValue.imageData.fileName
          ) {
            this.widget.imageData!.fileName =
              changes.widgetStyleForm.currentValue.imageData.fileName;
            this.widget.imageData!.imageSrc =
              changes.widgetStyleForm.currentValue.imageData.imageSrc;
            this.imageSrc =
              changes.widgetStyleForm.currentValue.imageData.imageSrc;
            this.imageError = false;
          }
        }
        Object.assign(this.imageData, this.widget.imageData);
      }
    }
  }

  onImgError(): void {
    this.widget.imageData!.imageSrc = this.exampleImage;
    this.imageSrc = this.exampleImage;
  }

  isAValidUrl(url: string): boolean {
    const urlRegex =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return urlRegex.test(url);
  }
}
