import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ThemeSettingComponent} from '../../../theme/pages/theme-settings/theme-settings.component';

@Component({
  selector: 'app-theme-from-report',
  templateUrl: './theme-from-report.component.html',
  standalone: true,
  imports: [ThemeSettingComponent],
})
export class ThemeFromReportComponent implements OnInit {
  public isFromReport: boolean;
  constructor(
    public dialogRef: MatDialogRef<ThemeFromReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.isFromReport = this.data.isFromReport;
    }
  }

  onClose(event): void {
    this.dialogRef.close(event);
  }
}
