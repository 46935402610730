<header class="mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h2 m-0">
      {{
        (data.type === "widget"
          ? "shared.dialogs.template_library.widget_library_title"
          : "shared.dialogs.template_library.page_library_title"
        ) | translate
      }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
  <p>
    {{ "shared.dialogs.template_library.template_description" | translate }}
  </p>
</header>

<div class=""></div>
<div class="controls-container mb-4">
  <mat-select
    [formControl]="categoryFilter"
    class="wsm-input me-3"
    [style.width]="'35%'"
    [multiple]="true"
    placeholder="{{
      'shared.dialogs.template_library.filter_by_placeholder' | translate
    }}"
  >
    <mat-option [value]="'user'">{{
      "shared.dialogs.template_library.created_by_me_label" | translate
    }}</mat-option>
    @for (goal of goals; track goal) {
      <mat-option [value]="goal.id">{{
        goal.translation | translate
      }}</mat-option>
    }
  </mat-select>
  <div class="wsm-input-group-prepend">
    <i class="fa-regular fa-magnifying-glass ps-1"></i>
    <input
      #filterInput
      appAlphanumeric
      class="wsm-input"
      placeholder="{{
        'shared.dialogs.template_library.search_placeholder' | translate
      }}"
      type="text"
    />
  </div>
  <span class="mdi mdi-close ms-3 close-dialog-btn" mat-dialog-close></span>
</div>

<mat-dialog-content class="library-content">
  @if (!isLoading) {
    <div class="templates-grid pb-3">
      @for (template of templates; track template) {
        <app-template-gallery-card
          [templateCard]="template"
          [type]="'page'"
          [currentUser]="user"
          (templateSelected)="addTemplate($event)"
          (templateDeleted)="removeTemplate($event)"
        >
        </app-template-gallery-card>
      }
    </div>
  }

  @if (
    templates?.length === 0 &&
    !isLoading &&
    filterInput.value === "" &&
    categoryFilter.value === ""
  ) {
    <div class="wsm-alert-message mt-3">
      @if (data.type === "widget") {
        <strong>
          {{
            "shared.dialogs.template_library.no_widget_description" | translate
          }}
          <i class="fa-regular fa-book-open-reader"></i
          >{{ "shared.dialogs.template_library.no_widget_action" | translate }}
        </strong>
      }
      @if (data.type === "page") {
        <strong>
          {{
            "shared.dialogs.template_library.no_page_description" | translate
          }}
          <i class="fa-regular fa-book-open-reader"></i
          >{{ "shared.dialogs.template_library.no_page_action" | translate }}
        </strong>
      }
    </div>
  }

  @if (
    templates?.length === 0 &&
    !isLoading &&
    (filterInput.value !== "" || categoryFilter.value !== "")
  ) {
    <div class="wsm-alert-message mt-3">
      <strong>{{
        "shared.dialogs.template_library.no_templates_label" | translate
      }}</strong>
    </div>
  }

  @if (isLoading) {
    <div class="loader-container d-flex flex-column">
      @if (loadingTemplatePreview) {
        <h6 class="wsm-text-grayed">
          {{
            "shared.dialogs.template_library.preview_template_loading"
              | translate
          }}
        </h6>
      }
      <div class="spinner-ripple">
        <div class="circle">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <mat-paginator
    class="modal-paginator"
    #templatePaginator
    [pageSizeOptions]="[10, 20, 30]"
    [pageSize]="10"
    [length]="templateCount"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</mat-dialog-actions>
