import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { FormControl, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {fromEvent, merge, of, retry, startWith, Subscription} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import {DomSanitizer} from '@angular/platform-browser';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import { MatRadioChange, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import {
  ApiService,
  AuthenticationService,
  ClientService,
  NotificationService,
  ReportService,
} from '../../../core/services';
import {
  BillingPlan,
  ClientData,
  Company,
  CustomError,
  IOrganizationData,
  IOrganizationList,
  MirrorClient,
  MoveReportPayload,
  Report,
  User,
} from '../../models';
import { MatChipListbox, MatChip } from '@angular/material/chips';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgClass, SlicePipe, TitleCasePipe } from '@angular/common';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { LoadingComponent } from '../../components/loading/loading.component';

@Component({
    selector: 'app-export-to-dialog',
    templateUrl: './export-to-dialog.component.html',
    styleUrls: ['./export-to-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        MatChipListbox,
        MatChip,
        MatMenuTrigger,
        SatPopoverModule,
        MatMenu,
        MatRadioGroup,
        FormsModule,
        NgxIntlTelInputModule,
        MatRadioButton,
        MatCheckbox,
        NgClass,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        MatCellDef,
        MatCell,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatPaginator,
        LoadingComponent,
        MatDialogActions,
        SlicePipe,
        TitleCasePipe,
        TranslateModule,
    ],
})
export class ExportToDialogComponent implements OnInit, OnDestroy {
  // Children
  @ViewChild(MatSort, {static: false}) sortClient: MatSort;
  @ViewChild('clientPaginator', {static: false}) paginatorClient: MatPaginator;
  @ViewChild('orgPaginator', {static: false}) orgPaginator: MatPaginator;
  @ViewChild('companyInput', {static: false}) companyInput: ElementRef;
  @ViewChild('instanceInput', {static: false}) instanceInput: ElementRef;

  // State
  isLoading = false;
  tableIsLoading = false;
  partnerInstance = false;
  generalError = false;

  // Properties
  orgList: Array<IOrganizationData> = [];
  clientList: Array<ClientData> = [];
  clientsCount: number;
  orgCount: number;
  report: Report;
  partnerInfo: IOrganizationData;
  isExport = false;
  reportName = new UntypedFormControl();
  clientFilter = new UntypedFormControl('specific');
  replaceReportCtrl = new UntypedFormControl();
  reportClient: MirrorClient;

  selectionCompany = new SelectionModel<string>(false, []);
  selectedCompany: Company[] = [];
  displayedColumnsCompany: string[] = ['name', 'owner'];
  dataSource = new MatTableDataSource<any>();
  pageSizeOptions = [5, 10, 50, 100];

  private businessTypes = this.clientService.getBusinessTypes();
  private marketingObjective = this.clientService.getMarketingObjectives();
  private lifeCycleList = this.clientService.getLifeCycle();

  filteredReports: Array<any> = [];
  private subs: Subscription = new Subscription();
  currentUser = localStorage.getItem('currentUser');
  user: User;

  // Organization Filters
  public statusCtrl = new FormControl<string>('ALL');
  public planCtrl = new FormControl<string[]>([]);
  public statusFilter = 'ALL';
  public allPlans = true;
  public savePlans = true;
  public planFilters: Array<{name: string; selected: boolean; value: string}> =
    [
      {name: 'Basic', selected: false, value: 'BASIC'},
      {name: 'Individual', selected: false, value: 'INDIVIDUAL'},
      {name: 'Pro', selected: false, value: 'PRO'},
      {name: 'Premium', selected: false, value: 'PREMIUM'},
      {name: 'In-House', selected: false, value: 'INHOUSE'},
      {name: 'Agency', selected: false, value: 'AGENCY'},
      {name: 'Freelance Pro', selected: false, value: 'FREELANCE'},
      {name: 'Agency Plan (Courtesy)', selected: false, value: 'FREE'},
      {name: 'Enterprise', selected: false, value: 'ENTERPRISE'},
    ];

  constructor(
    public dialogRef: MatDialogRef<ExportToDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private apiService: ApiService,
    public clientService: ClientService,
    private reportService: ReportService,
    private domSanitizationService: DomSanitizer,
    private authService: AuthenticationService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.allPlans && this.setAllPlans(true);
    this.planCtrl.setValue(this.planFilters.map((plan) => plan.value));
    this.isExport = !!this.data?.isExport;
    this.report = this.data?.report;
    this.getOrgList();

    if (this.report?.companiesMatching[0]?.value?.length === 1) {
      this.getClient();
    }

    if (this.currentUser !== null) {
      this.user = JSON.parse(this.currentUser);
    } else {
      this.getUserData();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getUserData(): void {
    this.authService
      .getUserData()
      .pipe(retry(3))
      .subscribe({
        next: (res: User): void => {
          this.user = res;
        },
      });
  }

  getReportList(): void {
    merge(this.replaceReportCtrl.valueChanges)
      .pipe(
        startWith({}),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(() => {
          if (typeof this.replaceReportCtrl.value === 'string') {
            return this.reportService.getOrgReports(
              this.replaceReportCtrl.value,
              this.partnerInfo?.id
            );
          } else {
            return of([]);
          }
        })
      )
      .subscribe({
        next: (res): void => {
          if (typeof this.replaceReportCtrl.value === 'string') {
            this.filteredReports = res?.data || [];
          }
        },
      });
  }

  getOrgList(): void {
    this.isLoading = true;
    if (!this.partnerInstance) {
      this.changeDetector.detectChanges();
    }

    merge(
      fromEvent(this.instanceInput.nativeElement, 'keydown'),
      this.orgPaginator.page,
      this.statusCtrl.valueChanges,
      this.planCtrl.valueChanges
    )
      .pipe(
        startWith({}),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((event) => {
          this.isLoading = true;
          if (event instanceof KeyboardEvent) {
            this.orgPaginator.firstPage();
          }

          const queryParams: HttpParams = this.filterQueryBuilder();

          return this.apiService.getOrgList(queryParams);
        })
      )
      .subscribe({
        next: (res: IOrganizationList): void => {
          this.orgList = res?.data ? res.data : [];
          this.orgCount = res?.total;
          this.isLoading = false;
          this.updateOrgLogo();
        },
        error: (err): void => {
          this.isLoading = false;
          this.notificationService.error(err?.message as string, 5000);
        },
      });
  }

  updateOrgLogo(): void {
    this.orgList.forEach((org: IOrganizationData): void => {
      if (org.logo) {
        org.logo = this.domSanitizationService.bypassSecurityTrustUrl(
          'data:image/jpg;base64,' + org.logo
        );
      }
    });
  }

  getClient(): void {
    this.subs.add(
      this.reportService.getReportClient(this.report?.id).subscribe({
        next: (res): void => {
          this.reportClient = res;

          if (
            this.reportClient?.marketingObjective &&
            this.reportClient?.marketingObjective?.length > 0 &&
            this.marketingObjective?.length > 0
          ) {
            const objectives: string[] = this.reportClient.marketingObjective;
            objectives?.forEach((obj: string, index: number): void => {
              const objective = this.marketingObjective.filter(
                (mo) => mo.value === obj
              );
              if (this.reportClient.marketingObjective) {
                this.reportClient.marketingObjective[index] =
                  objective?.length > 0 ? objective[0].desc?.trim() : obj;
              }
            });
          }

          if (
            this.reportClient?.businessType &&
            this.businessTypes?.length > 0
          ) {
            this.businessTypes.forEach((type): void => {
              if (this.reportClient.businessType === type?.value) {
                this.reportClient.businessType = type?.desc?.trim();
              }
            });
          }

          if (this.reportClient?.lifeCycle && this.lifeCycleList?.length > 0) {
            this.lifeCycleList.forEach((type): void => {
              if (this.reportClient.lifeCycle === type?.value) {
                this.reportClient.lifeCycle = type?.viewValue?.trim();
              }
            });
          }
        },
        error: (err): void => {
          this.notificationService.error(err?.message, 5000);
          this.generalError = true;
        },
      })
    );
  }

  getClientList(): void {
    this.tableIsLoading = true;
    merge(
      this.sortClient.sortChange,
      fromEvent(this.companyInput.nativeElement, 'keydown'),
      this.paginatorClient.page
    )
      .pipe(
        startWith({}),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((event) => {
          this.tableIsLoading = true;
          if (event instanceof KeyboardEvent) {
            this.paginatorClient.firstPage();
          }
          const params = {
            sortBy: this.sortClient.active
              ? `${this.sortClient.active} ${this.sortClient.direction}`
              : null,
            filter: `name like ${this.companyInput.nativeElement.value || ''}`,
            pageSize: this.paginatorClient.pageSize,
            page: this.paginatorClient.pageIndex + 1,
          };

          return this.clientService.getInstanceClients(
            this.partnerInfo.id,
            params
          );
        })
      )
      .subscribe({
        next: (res: any): void => {
          this.clientList = res?.data ? res.data : [];
          this.clientsCount = res.total;
          this.dataSource.data = this.clientList;
          this.tableIsLoading = false;
        },
        error: (err: CustomError): void => {
          this.tableIsLoading = false;
          this.notificationService.error(err?.message, 5000);
        },
      });
  }

  onSelectCompany(company: Company): void {
    if (company.id != null) {
      this.selectionCompany.select(company.id);
    }
    this.selectedCompany = [company];
  }

  isCompanySelected(companyId): any {
    return !!this.selectedCompany.find((c: any) => c.id === companyId);
  }

  onInstanceSelect(org: IOrganizationData): void {
    if (this.isExport) {
      this.partnerInfo = org;
      this.partnerInstance = true;
      this.dialogRef.updateSize('50vw', '720px');
      this.changeDetector.detectChanges();
      this.getClientList();
      this.getReportList();
    } else {
      localStorage.setItem('loginFromDomain', window.location.hostname);
      this.impersonate(org.supportUserEmail, org.plan);
    }
  }

  impersonate(email: string, plan: BillingPlan): void {
    const session = encodeURIComponent(localStorage.getItem('session')!);
    const loginFromDomain = encodeURIComponent(
      localStorage.getItem('loginFromDomain')!
    );
    const billingInfo = JSON.stringify(plan);
    const payload = {
      email,
      loginFromDomain,
    };

    this.apiService.orgImpersonate(payload).subscribe({
      next: (res): void => {
        this.dialogRef.close();
        window.location.replace(
          `${res.redirectURI}&session=${session}&billingInfo=${billingInfo}`
        );
      },
      error: (error): void => {
        this.notificationService.error(error?.message, 5000);
      },
    });
  }

  onExportReport(): void {
    switch (this.clientFilter.value) {
      case 'mirrorCompany':
        this.moveClient();
        break;
      case 'allCompanies':
        if (this.reportName.value) {
          this.moveReport();
        }
        break;
      case 'specific':
        if (this.selectedCompany?.length > 0 && this.reportName.value) {
          this.moveReport();
        }
        break;
    }
  }

  private moveReport(clientId?: string): void {
    const payload: MoveReportPayload = {
      reportId: this.report?.id,
      reportName: this.reportName.value.trim(),
      companiesMatching:
        this.clientFilter.value === 'allCompanies'
          ? []
          : [
              {
                field: 'id',
                operator: 'in',
                value: clientId
                  ? [clientId]
                  : this.selectedCompany[0].id
                    ? [this.selectedCompany[0].id]
                    : [], //[clientId ? clientId : this.selectedCompany[0]?.id],
              },
            ],
      orgId: this.partnerInfo.id,
    };

    if (this.replaceReportCtrl.value) {
      payload.targetReportId = this.replaceReportCtrl?.value?.id;
    }

    this.isLoading = true;
    this.subs.add(
      this.reportService.moveReport(payload).subscribe({
        next: (): void => {
          this.isLoading = false;
          this.translate
            .get('notifications.report_exported')
            .subscribe((res: string) => {
              this.notificationService.success(res, 5000);
            });
          this.dialogRef.close();
        },
        error: (err): void => {
          this.isLoading = false;
          this.notificationService.error(err?.message, 5000);
        },
      })
    );
  }

  private moveClient(): void {
    const payload = {
      orgId: this.partnerInfo.id,
      clientId: this.reportClient?.id,
    };
    this.isLoading = true;

    this.subs.add(
      this.clientService.moveReportClient(payload).subscribe({
        next: (res): void => {
          this.isLoading = false;
          this.moveReport(res?.clientId);
        },
        error: (err): void => {
          this.isLoading = false;
          this.notificationService.error(err?.message, 5000);
        },
      })
    );
  }

  clientFilterChange(event: MatRadioChange): void {
    switch (event.value) {
      case 'allCompanies':
        this.selectionCompany.clear();
        this.selectedCompany = [];
        this.dialogRef.updateSize('50vw', '358px');
        this.changeDetector.detectChanges();
        break;
      default:
        this.dialogRef.updateSize('50vw', '720px');
        this.changeDetector.detectChanges();
        break;
    }
  }

  displayReport(report: Report): string {
    return report && report.name ? report.name : '';
  }

  onBackInstance(): void {
    this.partnerInstance = false;
    this.selectionCompany.clear();
    this.selectedCompany = [];
    this.clientList = [];
    this.clientsCount = 0;
    this.dataSource.data = this.clientList;
    this.filteredReports = [];
    this.getOrgList();
    this.dialogRef.updateSize('50vw', '616px');
    this.changeDetector.detectChanges();
  }

  public onSetStatusFilter(): void {
    this.statusCtrl.setValue(this.statusFilter);
    this.orgPaginator.firstPage();
  }

  public onSetPlanFilter(): void {
    const selectedPlans = this.planFilters
      .filter((plan) => plan.selected)
      .map((plan) => plan.value);

    this.planCtrl.setValue(selectedPlans);
    this.orgPaginator.firstPage();
  }

  public setAllPlans(completed: boolean): void {
    this.allPlans = completed;
    this.savePlans = completed;
    this.planFilters.forEach((plan) => (plan.selected = completed));
  }

  public updateAllSelected(): void {
    this.allPlans =
      this.planFilters != null && this.planFilters.every((t) => t.selected);
    this.savePlans = this.planFilters.some((plan) => plan.selected);
  }

  public somePlansSelected(): boolean {
    return (
      this.planFilters.filter((t) => t.selected).length > 0 && !this.allPlans
    );
  }

  public getPlanLabel(value: string): string {
    const currentPlan = this.planFilters.find((plan) => plan.value === value);
    return currentPlan?.name || '';
  }

  private filterQueryBuilder(): HttpParams {
    const nameFilter: string = this.instanceInput.nativeElement.value
      ? `name contains ${this.instanceInput.nativeElement.value}`
      : '';

    const statusFilter: string =
      this.statusCtrl.value === 'ALL'
        ? 'in ACTIVE INACTIVE'
        : this.statusCtrl.value === 'ACTIVE'
          ? 'equals ACTIVE'
          : 'equals INACTIVE';

    const selectedPlans =
      this.planCtrl?.value && this.planCtrl?.value?.length > 0
        ? this.planCtrl.value.join(' ')
        : '';

    const planFilters = 'paymentSetting.product.type in ' + selectedPlans;

    return new HttpParams()
      .append('filter', `${nameFilter},status ${statusFilter},${planFilters}`)
      .append('page', `${this.orgPaginator.pageIndex + 1}`)
      .append('limit', `${this.orgPaginator.pageSize}`);
  }
}
