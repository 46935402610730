<section class="w-100 h-100" (appResized)="onGridResize()">
  @if (widget.query && widget?.ads?.length <= 0) {
    <div class="d-flex align-items-center justify-content-center h-100">
      <span class="wsm-text-grayed">
        {{ "reporting.widgets.adGallery.no_ads" | translate }}
      </span>
    </div>
  } @else {
    @if (!isMobile) {
      <div
        id="ad-gallery"
        class="horizontal-content-masonry"
        [ngClass]="{'dummy-data': !widget?.query}"
      >
        @for (ad of widget.ads; track $index) {
          <article
            class="horizontal-content-item"
            [ngClass]="{'metric-box': widget?.query}"
          >
            <app-ad-preview
              [widget]="widget"
              [ad]="ad"
              [theme]="theme"
              [isExternalUser]="isExternalUser"
              [helpUrl]="helpUrl"
            />
          </article>
        }
      </div>
    }
    @if (isMobile) {
      <app-card-carousel
        [cardData]="adCardData"
        [indicatorsOn]="true"
        [themes]="{theme: theme, reportTheme: {}, pageTheme: {}}"
      />
    }
  }
</section>
