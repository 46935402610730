<div class="d-flex flex-column h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      @if (addingFilter) {
        <div class="d-flex align-items-center">
          <button
            (click)="addingFilter = false"
            class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
          >
            <i class="fa-regular fa-arrow-left"></i>
          </button>
          <h1 class="h3 m-0">
            {{
              "reporting.widget_editor.data_form.add_filter_label" | translate
            }}
          </h1>
        </div>
      }

      @if (!addingFilter) {
        <h1 class="h3 m-0">
          @if (!data.isMobile) {
            <span>
              {{ "shared.dialogs.filter_logic.title" | translate }}
            </span>
          }
          @if (data.isMobile) {
            <span>
              {{ "reporting.widget_editor.data_form.filter_label" | translate }}
            </span>
          }
        </h1>
      }

      <button
        [mat-dialog-close]="false"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
  </header>

  <mat-dialog-content class="filter-logic-content">
    @if (!addingFilter) {
      <button
        (click)="addGroup()"
        class="wsm-btn wsm-btn-outline my-1 ms-1 me-2"
      >
        {{ "shared.dialogs.filter_logic.create_group_button" | translate }}
      </button>
      <!-- Add Filter Button for desktop mode -->
      @if (!data.isMobile) {
        <button
          [matMenuTriggerData]="{index: -1}"
          [matMenuTriggerFor]="filterMenu"
          class="wsm-btn wsm-btn-primary my-1"
        >
          {{ "shared.dialogs.filter_logic.add_filter_button" | translate }}
        </button>
      }
      <!-- Add Filter Button for mobile mode -->
      @if (data.isMobile) {
        <button
          (click)="editFilter({index: -1}, 'new')"
          class="wsm-btn wsm-btn-primary my-1"
        >
          {{ "shared.dialogs.filter_logic.add_filter_button" | translate }}
        </button>
      }
      @if (filters?.length! > 0) {
        <div cdkDropListGroup class="mt-3">
          @for (filter of filters; track filter; let j = $index) {
            <div
              #filterList
              (cdkDropListDropped)="drop($event)"
              [cdkDropListData]="filter"
              cdkDropList
              cdkDropListOrientation="horizontal"
              class="filter-list"
            >
              <div
                class="d-inline-flex justify-content-center align-items-center me-2"
                style="min-height: 40px"
              >
                <span class="badge text-uppercase ms-1 me-2">
                  {{ filter.operator }}
                </span>
                @if (j !== 0) {
                  <button
                    type="button"
                    class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
                    (click)="deleteGroup(filter)"
                  >
                    <i class="fa-regular fa-trash"></i>
                  </button>
                }
              </div>
              @if (filter?.expressions?.length! <= 0) {
                <div class="drag-drop-title">
                  <small>
                    {{
                      "shared.dialogs.filter_logic.drag_drop_info" | translate
                    }}
                  </small>
                </div>
              }
              @for (f of filter.expressions; track f; let i = $index) {
                <!-- Mat Chips for Desktop Mode -->
                @if (!data.isMobile) {
                  <mat-chip
                    (removed)="removeFilter(f, j)"
                    [matMenuTriggerData]="{
                      index: j,
                      subIndex: i,
                      currentFilter: f
                    }"
                    [matMenuTriggerFor]="filterMenu"
                    [removable]="true"
                    cdkDrag
                    [cdkDragData]="{value: f, listIndex: j, itemIndex: i}"
                    [disableRipple]="true"
                    class="wsm-mat-chip d-inline-flex me-2 my-1"
                  >
                    {{ f.fieldName }}
                    <app-filter-operator [filter]="f" />
                    @if (f.value) {
                      <span>{{ f.value | joinStringArray }}</span>
                    }
                    @if (f.labels) {
                      <span>{{ f.labels | joinStringArray }}</span>
                    }
                    <mat-icon matChipRemove>close</mat-icon>
                  </mat-chip>
                }
                <!-- Mat Chips for Mobile Mode -->
                @if (data.isMobile) {
                  <mat-chip
                    (removed)="removeFilter(f, j)"
                    (click)="
                      editFilter(
                        {index: j, subIndex: i, currentFilter: f},
                        'edit'
                      )
                    "
                    [removable]="true"
                    cdkDrag
                    [cdkDragData]="{value: f, listIndex: j, itemIndex: i}"
                    [disableRipple]="true"
                    class="wsm-mat-chip d-inline-flex me-2 my-1 align-items-center"
                    [ngClass]="{'mobile-chip': data.isMobile}"
                  >
                    {{ f.fieldName }}
                    <app-filter-operator [filter]="f" />
                    @if (f.value) {
                      <span> {{ f.value | joinStringArray }} </span>
                    }
                    @if (f.labels) {
                      <span> {{ f.labels | joinStringArray }} </span>
                    }
                    <mat-icon matChipRemove class="align-items-center">
                      close
                    </mat-icon>
                  </mat-chip>
                }
              }
            </div>
          }
        </div>
      }
    }

    @if (addingFilter) {
      <app-filter-selection
        (filterCompleted)="
          onFilterApplied($event, filterData.index, filterData.subIndex)
        "
        (formStatusChanged)="filterFormStatus = $event"
        [availableFilters]="availableFilters"
        [currentFilter]="filterData.currentFilter"
        [isMobile]="data.isMobile"
        [externalApply]="addFilter"
        [isFromPage]="data.isPage"
        [connections]="data?.connections || []"
        (possibleValuesChanged)="possibleValuesChanged($event)"
      />
    }
  </mat-dialog-content>

  <mat-menu #filterMenu="matMenu" class="menu-container">
    <ng-template
      let-currentFilter="currentFilter"
      let-index="index"
      let-subIndex="subIndex"
      matMenuContent
    >
      <app-filter-selection
        (filterCompleted)="onFilterApplied($event, index, subIndex)"
        [availableFilters]="availableFilters"
        [currentFilter]="currentFilter"
        [isFromPage]="data.isPage"
        [connections]="data?.connections || []"
        (possibleValuesChanged)="possibleValuesChanged($event)"
      />
    </ng-template>
  </mat-menu>

  <mat-dialog-actions class="d-flex justify-content-end">
    @if (!addingFilter) {
      <button
        (click)="onSave()"
        class="wsm-btn wsm-btn-primary"
        [ngClass]="{'flex-grow-1': data.isMobile}"
      >
        <span>
          {{
            "reporting.widget_editor.data_form.filter_selection.apply_button"
              | translate
          }}
        </span>
      </button>
    }
    @if (addingFilter) {
      <button
        (click)="addSelectedFilter()"
        class="wsm-btn wsm-btn-primary"
        [ngClass]="{'flex-grow-1': data.isMobile}"
        [disabled]="filterFormStatus === 'INVALID'"
      >
        <span>
          {{ "reporting.dynamic_date_range.add_button" | translate }}
        </span>
      </button>
    }
  </mat-dialog-actions>
</div>
