import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControlStatus, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  lastValueFrom,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import {
  IPossibleValueParams,
  IScopeFilter,
  PossibleValue,
  ReportFilter,
  ScopeField,
  Response,
} from '../../models';
import {checkIfArray, ScopeService} from '../../helpers';
import {PossibleValuesService} from '../../../core/services';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ConnectionLogosComponent } from '../connection-logos/connection-logos.component';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-filter-selection',
    templateUrl: './filter-selection.component.html',
    styleUrls: ['./filter-selection.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        MatTooltip,
        ConnectionLogosComponent,
        MatSelect,
        NgClass,
        MatSelectTrigger,
        MatButtonToggleGroup,
        MatButtonToggle,
        AsyncPipe,
        TranslateModule,
    ],
})
export class FilterSelectionComponent implements OnInit, OnChanges, OnDestroy {
  // Services
  public scopeService: ScopeService = inject(ScopeService);
  private possibleValesService = inject(PossibleValuesService);

  // Input / Outputs
  @Input() availableFilters: ScopeField[];
  @Input() currentFilter: ReportFilter = {} as ReportFilter;
  @Input() isMobile: boolean;
  @Input() isFromPage = false;
  @Input() externalApply: boolean;
  @Input() connections: Array<string> = [];
  @Output() filterCompleted = new EventEmitter<IScopeFilter>();
  @Output() formStatusChanged = new EventEmitter();
  @Output() possibleValuesChanged = new EventEmitter();

  // State
  showConditions: boolean;
  showContains: boolean;
  allowPropagation = false;
  gettingPossibleValues = false;

  // Properties
  newFilterForm: UntypedFormGroup;
  public selectedFilter: ScopeField | undefined;
  public filteredFields: ReplaySubject<ScopeField[]> = new ReplaySubject<
    ScopeField[]
  >(1);
  public fieldFilterCtrl: UntypedFormControl = new UntypedFormControl();
  protected onDestroySubject: Subject<void> = new Subject<void>();
  public currentPossibleValues: string[] = [];
  private subs: Subscription = new Subscription();

  ngOnInit(): void {
    this.newFilterForm = new UntypedFormGroup({
      filterIsNew: new UntypedFormControl(!this.currentFilter),
      filterField: new UntypedFormControl('', [Validators.required]),
      filterOperator: new UntypedFormControl('', [Validators.required]),
      filterValue: new UntypedFormControl('', [Validators.required]),
    });

    this.newFilterForm.get('filterField')?.valueChanges.subscribe((): void => {
      this.newFilterForm.removeControl('filterValue');
      this.newFilterForm.addControl(
        'filterValue',
        new UntypedFormControl('', [Validators.required])
      );
    });

    if (this.currentFilter) {
      this.newFilterForm
        .get('filterField')
        ?.setValue(
          this.isFromPage
            ? this.currentFilter.fieldName
            : this.currentFilter.fieldId
        );
      this.newFilterForm
        .get('filterOperator')
        ?.setValue(this.currentFilter.operator);
      this.newFilterForm
        .get('filterValue')
        ?.setValue(
          this.currentFilter.value
            ? this.currentFilter.value
            : this.currentFilter.labels
        );
      const currentFltCurrentValue = this.setFilterValue(
        this.currentFilter.fieldName
      );
      this.fieldFilterCtrl.setValue(currentFltCurrentValue);
      this.onFilterSelection(this.currentFilter.fieldName, false);
    }

    this.filteredFields.next(
      this.availableFilters?.length > 0 ? this.availableFilters.slice() : []
    );

    this.subs.add(
      this.fieldFilterCtrl.valueChanges
        .pipe(takeUntil(this.onDestroySubject))
        .subscribe((): void => {
          this.filterData('field');
        })
    );

    if (this.isMobile) {
      this.subs.add(
        this.newFilterForm.statusChanges.subscribe(
          (res: FormControlStatus): void => {
            this.formStatusChanged.emit(res);
          }
        )
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.externalApply?.currentValue) {
      this.onFilterApplied();
    }
  }

  public async onFilterSelection(
    value: ScopeField | string,
    clearOperator?: boolean
  ): Promise<void> {
    if (value) {
      if (clearOperator) {
        this.clearFilterOperatorInput();
      }
      const toEval = typeof value === 'string' ? value : value.name;
      const currentFilter = this.availableFilters?.find(
        (f: ScopeField): boolean => f.name === toEval
      );

      if (currentFilter) {
        this.selectedFilter = this.availableFilters?.find(
          (f: ScopeField): boolean => f.name === toEval
        );

        if (this.selectedFilter?.dataType === 'string') {
          this.showConditions = false;
          this.showContains = true;
        } else if (
          this.selectedFilter?.dataType === 'integer' ||
          this.selectedFilter?.dataType === 'percentage' ||
          this.selectedFilter?.dataType === 'decimal'
        ) {
          this.showConditions = true;
          this.showContains = false;
        } else {
          this.showConditions = true;
          this.showContains = true;
        }

        /* Already has possible values */
        if (
          currentFilter?.possibleValues ||
          currentFilter?.possibleValues?.length > 0
        ) {
          this.currentPossibleValues = currentFilter.possibleValues || [];
        } else {
          this.currentPossibleValues = [];
        }

        /* Get current filter possible values */
        if (currentFilter?.hasPossibleValues && this.connections?.length > 0) {
          if (
            !currentFilter?.possibleValues ||
            currentFilter?.possibleValues?.length <= 0
          ) {
            await this.getFilterValues(currentFilter.name, this.connections);
          } else {
            this.currentPossibleValues = currentFilter?.possibleValues || [];
          }
        }

        if (this.selectedFilter) {
          this.selectedFilter = {
            ...this.selectedFilter,
            possibleValues: [...this.currentPossibleValues],
          };
        }

        this.allowPropagation =
          this.selectedFilter?.dataType === 'integer' ||
          this.selectedFilter?.dataType === 'percentage' ||
          this.selectedFilter?.dataType === 'decimal' ||
          this.selectedFilter?.dataType === 'currency' ||
          this.selectedFilter?.dataType === 'time';

        if (this.allowPropagation) {
          this.newFilterForm.addControl(
            'filterLevel',
            new UntypedFormControl('')
          );
          this.newFilterForm
            .get('filterLevel')
            ?.setValue(
              this.currentFilter ? this.currentFilter.isPropagable : false
            );
        } else {
          this.newFilterForm.removeControl('filterLevel');
        }

        if (
          !this.selectedFilter?.possibleValues ||
          (this.selectedFilter.possibleValues &&
            this.selectedFilter.possibleValues.length === 0)
        ) {
          this.selectedFilter = undefined;
        }
      }
    } else {
      this.selectedFilter = undefined;
      this.currentPossibleValues = [];
      this.showConditions = true;
      this.showContains = true;
    }
  }

  private filterData(type: string): void {
    // Get the search keyword
    let search: any;

    if (type === 'field') {
      search = this.fieldFilterCtrl.value;

      if (typeof search !== 'string') {
        this.newFilterForm.controls.filterField.setValue(search);
      }
    }

    if (!search) {
      if (this.availableFilters) {
        this.filteredFields.next(this.availableFilters.slice());
      }
      return;
    } else {
      search = typeof search === 'string' ? search.toLowerCase() : search;
    }

    // Filter the data
    if (type === 'field') {
      this.filteredFields.next(
        this.availableFilters.filter(
          (field) => field.name.toLowerCase().indexOf(search) > -1
        )
      );
    }
  }

  onFilterApplied(): void {
    if (this.newFilterForm.invalid) {
      return;
    }
    const form = this.newFilterForm.getRawValue();
    form.filterValue =
      typeof form.filterValue === 'string'
        ? form.filterValue?.trim()
        : form.filterValue;
    this.filterCompleted.emit(form);
    this.newFilterForm.reset();
    this.newFilterForm.removeControl('filterValue');
    this.newFilterForm.addControl(
      'filterValue',
      new UntypedFormControl(false, [Validators.required])
    );
    this.allowPropagation = false;
    this.newFilterForm.get('filterIsNew')?.setValue(!this.currentFilter);
    this.fieldFilterCtrl.setValue(null);
  }

  clearFilterOperatorInput(): void {
    this.newFilterForm.get('filterValue')?.setValue(null);
  }

  setFilterValue(name: string): ScopeField | undefined {
    return this.availableFilters?.find(
      (s: ScopeField): boolean => s.name === name
    );
  }

  displayFilter(filter: ScopeField): string {
    return filter && filter.name ? filter.name : '';
  }

  private async getFilterValues(
    scopeName: string,
    connections: string[] = []
  ): Promise<void> {
    this.newFilterForm.get('filterValue')?.disable();
    const params: IPossibleValueParams = {
      name: scopeName,
      connections,
    };
    this.gettingPossibleValues = true;
    const values$: Observable<Response> =
      this.possibleValesService.getPossiblesValuesByName(params);
    await lastValueFrom(values$)
      .then((res: Response): void => {
        this.gettingPossibleValues = false;
        this.currentPossibleValues = res.data;
        this.availableFilters = this.availableFilters?.map(
          (filter: ScopeField) => {
            if (filter.name === scopeName) {
              filter = {
                ...filter,
                possibleValues: res?.data || [],
              };
            }
            return filter;
          }
        );

        this.possibleValuesChanged.emit(this.availableFilters);

        this.newFilterForm.get('filterValue')?.enable();
      })
      .catch((): void => {
        this.currentPossibleValues = [];
        this.newFilterForm.get('filterValue')?.enable();
        this.gettingPossibleValues = false;
      });
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
    this.subs.unsubscribe();
  }
}
