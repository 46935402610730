import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {ReportTheme} from '../../../../models';
import { NgStyle, NgClass } from '@angular/common';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { SliderColorDirective } from '../../../../directives/slider-color.directive';

@Component({
    selector: 'app-modern-player',
    templateUrl: './modern-player.component.html',
    styleUrls: ['./modern-player.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        MatSlider,
        SliderColorDirective,
        MatSliderThumb,
    ],
})
export class ModernPlayerComponent implements OnInit, OnDestroy {
  // Inputs / Output
  @Input() audioUrl: string;
  @Input() reportTheme: ReportTheme;
  @Input() getTimeCodeFromNum: any;
  @Output() hidePlayer = new EventEmitter();

  @ViewChild('volumeProgress', {static: true})
  volumeProgress: ElementRef<HTMLElement>;

  // State
  isPlaying = false;
  isVolumeOpen = false;

  // Properties
  audio = new Audio();
  audioDuration: string;
  currentDuration = '00:00';

  ngOnInit(): void {
    if (this.audioUrl) {
      this.audio.src = this.audioUrl;

      this.audio.addEventListener(
        'loadeddata',
        () => {
          this.audioDuration = this.getTimeCodeFromNum(this.audio.duration);
        },
        false
      );

      this.checkAudioEnd();
      this.updateProgress();
    }
  }

  ngOnDestroy(): void {
    if (this.audioUrl) {
      this.audio.pause();
    }
  }

  skipAround(event: any): void {
    if (this.audioUrl) {
      if (event?.value) {
        this.audio.currentTime = event.value;
      }
    }
  }

  togglePlay(): void {
    if (this.audioUrl) {
      if (this.audio.paused) {
        this.audio.play().then();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    }
  }

  muteAudio(): void {
    if (this.audioUrl) {
      this.audio.muted = !this.audio.muted;
    }
  }

  updateProgress(): void {
    setInterval(() => {
      this.currentDuration = this.getTimeCodeFromNum(this.audio.currentTime);
    }, 500);
  }

  checkAudioEnd(): void {
    this.audio.addEventListener(
      'ended',
      () => {
        this.audio.currentTime = 0;
        this.isPlaying = false;
      },
      false
    );
  }

  closePlayer(): void {
    this.hidePlayer.emit(this.audioUrl);
  }
}
