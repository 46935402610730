import {Component} from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
    standalone: true,
    imports: [LottieComponent],
})
export class ErrorPageComponent {
  options: AnimationOptions = {
    path: '/assets/lotties/broken-clicky.json',
  };
}
