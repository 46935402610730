import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  Input,
  inject,
} from '@angular/core';
import {LowerCasePipe, NgClass, SlicePipe} from '@angular/common';
import {
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {interval, Subscription, switchMap} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {BillingService, NotificationService} from 'src/app/core/services';
import {BillingPlan, Product, Response} from '../../models';
import {
  MatButtonToggleGroup,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {BillingPlanStore} from '../../state';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
  providers: [LowerCasePipe],
  standalone: true,
  imports: [
    NgClass,
    MatButtonToggleGroup,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatButtonToggle,
    SlicePipe,
    TranslateModule,
  ],
})
export class PlanSelectionComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Input() isMobile: boolean;
  @Output() productsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() disabledCancelButton = new EventEmitter<boolean>(false);
  @Output() paymentCompleted = new EventEmitter<boolean>(false);

  // Store
  private billingPlanStore = inject(BillingPlanStore);

  // Properties
  products: Product[];
  paymentLink: string;
  billingInfo: BillingPlan | null;
  paymentStatusSub: Subscription;
  subs: Subscription = new Subscription();
  billingCycle: UntypedFormControl = new UntypedFormControl('monthly');

  constructor(
    private billingService: BillingService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private lowerCasePipe: LowerCasePipe,
    private router: Router
  ) {
    translate.onLangChange.subscribe((): void => {
      this.productsLoaded.emit(true);
      this.getPaymentProducts();
    });
  }

  ngOnInit(): void {
    this.billingInfo = this.billingPlanStore?.getPlan();
    this.getPaymentProducts();
    this.getPaymentLink();

    const watchPlan = toObservable(this.billingPlanStore.plan);
    this.subs.add(
      watchPlan.subscribe((res: BillingPlan | null) => (this.billingInfo = res))
    );
  }

  private getPaymentProducts(): void {
    this.billingService.getPaymentProducts().subscribe({
      next: (res: Response): void => {
        this.products = res.data;
        this.products.forEach((product: Product): void => {
          product.isSelected = this.billingInfo?.productId === product.id;
          if (this.billingInfo?.recurrence) {
            this.billingCycle.setValue(
              this.lowerCasePipe.transform(this.billingInfo?.recurrence)
            );
          }
        });
      },
      error: (err): void => {
        this.productsLoaded.emit(false);
        this.notificationService.error(err.message, 5000);
      },
      complete: (): void => {
        this.productsLoaded.emit(false);
      },
    });
  }

  getPaymentLink(disable?: boolean, product?: Product): void {
    this.billingService.getPaymentLink().subscribe({
      next: (res) => {
        this.paymentLink = res.data.paymentLink;
      },
      error: (err) => {
        this.notificationService.error(err.message, 5000);
      },
      complete: () => {
        if (!disable && product) {
          this.disabledCancelButton.emit(false);
          this.disableButton(disable);
          product.isLoading = false;
        }
      },
    });
  }

  filterByBillingCycle(): Product[] {
    return this.products?.filter(
      (product) =>
        this.lowerCasePipe.transform(product.recurrence) ===
        this.lowerCasePipe.transform(this.billingCycle.value)
    );
  }

  goToPaymentLink(): void {
    this.checkPaymentStatus();
    window.open(this.paymentLink, '_blank');
  }

  changePlan(product): void {
    const payload = {
      immediately: false,
      planId: product.id,
    };

    this.disabledCancelButton.emit(true);
    this.disableButton(true);
    product.isLoading = true;

    this.billingService.changePlan(payload).subscribe({
      next: (res: Response): void => {
        const plan: BillingPlan = res?.data?.plan || {};
        this.billingPlanStore.updateBillingPlanMethod(plan);
        const productId = plan?.productId;
        this.products.forEach((product) => {
          product.isSelected = productId === product.id;
        });
      },
      error: (err): void => {
        product.isLoading = false;
        this.disableButton(false);
        if (err.error?.errors?.length > 0) {
          err.error.errors.forEach((error) => {
            this.notificationService.error(error.message, 5000);
          });
        } else {
          this.notificationService.error(err.error.message, 5000);
        }
      },
      complete: (): void => {
        this.getPaymentLink(false, product);
      },
    });
  }

  selectPlan(product: Product): void {
    const payload = {
      planId: product.id,
    };

    this.disableButton(true);
    product.isLoading = true;

    this.billingService.signUpAfterCancel(payload).subscribe({
      next: (res: Response): void => {
        const plan: BillingPlan = res?.data?.plan || {};
        this.billingPlanStore.updateBillingPlanMethod(plan);
        const productId = plan?.productId;
        this.products.forEach((product: Product): void => {
          product.isSelected = productId === product.id;
        });
        this.paymentLink = res.data.paymentLink;
      },
      error: (err): void => {
        product.isLoading = false;
        this.disableButton(false);
        if (err.error?.errors?.length > 0) {
          err.error.errors.forEach((error) => {
            this.notificationService.error(error.message, 5000);
          });
        } else {
          this.notificationService.error(err.error.message, 5000);
        }
      },
      complete: (): void => {
        product.isLoading = false;
        this.disableButton(false);
        this.notificationService.info('Plan selected, Pay subscription');
      },
    });
  }

  checkPaymentStatus(): void {
    const currentPaymentStatus = this.billingPlanStore.plan()?.status;
    this.disableButton(true);
    this.paymentStatusSub = interval(5000)
      .pipe(switchMap(() => this.billingService.getPaymentDetails()))
      .subscribe({
        next: (res) => {
          const paymentStatus = res.data.plan.status;
          if (paymentStatus !== currentPaymentStatus) {
            const plan: BillingPlan = res?.data?.plan || {};
            this.billingPlanStore.updateBillingPlanMethod(plan);
            const org = JSON.parse(localStorage.getItem('organization')!);
            org.status = 'ACTIVE';
            localStorage.setItem('organization', JSON.stringify(org));
            this.paymentCompleted.emit(true);
            this.disableButton(false);
            this.paymentStatusSub?.unsubscribe();
            if (
              this.router.url === '/canceled' ||
              this.router.url === '/expired'
            ) {
              this.router.navigate(['/home']).then();
            } else {
              this.notificationService.success(
                'Payment information updated.',
                5000
              );
            }
          }
        },
        error: (err) => {
          this.notificationService.error(err.error.message);
          this.paymentStatusSub?.unsubscribe();
        },
      });
    this.subs.add(this.paymentStatusSub);
  }

  disableButton(disable): void {
    this.products.forEach((p) => {
      p.isDisabled = disable;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
