<section
  [ngClass]="{'d-none': geoData.length === 0, 'd-block': geoData.length !== 0}"
  [id]="widget.id"
  class="w-100 h-100"
>
  <div class="w-100 h-100" #mapContainer></div>
</section>

@if (geoData?.length === 0) {
  <div class="wsm-alert-message mt-3">
    <strong>{{ "reporting.widgets.no_data_found" | translate }}</strong>
  </div>
}
