import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { LangChangeEvent, TranslateService, TranslateModule } from '@ngx-translate/core';

import {AuthenticationService} from '../../../../../core/services';
import {HelperService} from '../../../../helpers';
import {ReportTheme, Widget} from '../../../../models';
import { NgStyle, PercentPipe, DatePipe } from '@angular/common';
import { AudioPlayerComponent } from '../../../audio-player/audio-player.component';
import { WidgetPopoverComponent } from '../../widget-popover/widget-popover.component';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';
import { TransformDatePipe } from '../../../../pipes/transform-date.pipe';

@Component({
    selector: 'app-table-row',
    templateUrl: './table-row.component.html',
    styleUrls: ['./table-row.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        AudioPlayerComponent,
        WidgetPopoverComponent,
        PercentPipe,
        DatePipe,
        TranslateModule,
        SafeHtmlPipe,
        TransformDatePipe,
    ],
})
export class TableRowComponent implements OnInit, OnChanges {
  // Inputs
  @Input() widget: Widget;
  @Input() isPrint: boolean;
  @Input() reportTheme: ReportTheme;
  @Input() pageTheme: ReportTheme;
  @Input() element: any;
  @Input() column: any;
  @Input() columnKey: string;
  @Input() columnDataType: string;
  @Input() theme: ReportTheme = {};
  @Input() isTotalRow? = false;

  // Properties
  public helpUrl: string;
  public isExternalUser: boolean;
  audioList = {};
  public userTimeZone = '';
  public customFieldsArray: Array<{key: string; value: string}>;

  constructor(
    private translate: TranslateService,
    private helperService: HelperService,
    private authService: AuthenticationService
  ) {
    this.isExternalUser = this.authService.checkRole(['ROLE_EXTERNAL']);
    translate.onLangChange.subscribe((event: LangChangeEvent): void => {
      this.helpUrl = this.helperService.getSupportUrl(event.lang);
    });
  }

  ngOnInit(): void {
    this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.helpUrl = this.helperService.getSupportUrl(this.translate.currentLang);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.convertCustomFieldsToArray();
    }
  }

  public playAudio(audioData: any): void {
    if (audioData) {
      if (!this.audioList[`${audioData}`]) {
        this.audioList[`${audioData}`] = true;
      }
    }
  }

  public checkAudio(audioData: any): boolean {
    return !!this.audioList[`${audioData}`];
  }

  public hideAudioPlayer(event: any): void {
    this.audioList[`${event?.audioUrl}`] = false;
  }

  /**
   * Custom Field - CTM
   */
  private convertCustomFieldsToArray(): void {
    if (
      this.column.key === 'customFields' &&
      this.element['customFields']?.value &&
      !this.isTotalRow
    ) {
      this.customFieldsArray = Object.keys(
        this.element['customFields']?.value
      ).map((key) => {
        return {
          key: key.replace(/_/g, ' '),
          value: this.element['customFields']?.value[key],
        };
      });
    }
  }
}
