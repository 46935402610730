import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
    selector: '[appBInput]',
    standalone: true
})
export class BehaviorInputDirective implements AfterViewInit, OnChanges {
  @Input() inputType?: 'input' | 'select' | 'inputIcon';
  @Input() inputStatus?: 'edit' | 'loading' | 'error' | 'success' | 'default';
  @Input() selectValue: any;

  public editIcon: HTMLElement;
  public isCursorOnInput = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.createSuccessIcon();
    this.createLoadingIcon();
    this.createEditIcon();
    this.getEditIcon();
    this.handleEditIcon();
  }

  ngOnChanges(): void {
    this.handleInputType();
  }

  set val(value: any) {
    this.elementRef.nativeElement.value = value;
  }

  get val(): any {
    return this.elementRef.nativeElement.value;
  }

  @HostListener('change', ['event'])
  change(event: MouseEvent): void {
    this.handleEditIcon();
  }

  @HostListener('focus', ['event'])
  onFocus(event: FocusEvent): void {
    this.hideEditIcon();
  }

  @HostListener('focusin', ['event'])
  onFocusin(event: FocusEvent): void {
    this.isCursorOnInput = true;
    this.hideEditIcon();
  }

  @HostListener('focusout', ['event'])
  onFocusOut(event: FocusEvent): void {
    this.isCursorOnInput = false;
    this.handleEditIcon();
  }

  @HostListener('mouseover', ['event'])
  onMouseOver(event: MouseEvent): void {
    this.hideEditIcon();
  }

  @HostListener('mouseenter', ['event'])
  onMouseEnter(event: MouseEvent): void {
    this.hideEditIcon();
  }

  @HostListener('mouseleave', ['event'])
  onMouseLeave(event: MouseEvent): void {
    this.handleEditIcon();
  }

  handleInitClass(): void {
    this.handleEditIcon();
  }

  getEditIcon(): void {
    this.editIcon = this.elementRef.nativeElement.parentNode.lastChild;
  }

  createEditIcon(): void {
    const iTag = document.createElement('i');
    iTag.classList.add('fa-regular', 'fa-pen');
    this.elementRef.nativeElement.parentNode.appendChild(iTag);
  }

  createLoadingIcon(): void {
    const iLoading = document.createElement('i');
    iLoading.classList.add(
      'fa-solid',
      'fa-spinner',
      'fa-spin',
      'wsm-text-white',
      'd-none'
    );
    this.elementRef.nativeElement.parentNode.appendChild(iLoading);
  }

  createSuccessIcon(): void {
    const iLoading = document.createElement('i');
    iLoading.classList.add(
      'fa-solid',
      'fa-check',
      'wsm-text-success',
      'd-none'
    );
    this.elementRef.nativeElement.parentNode.appendChild(iLoading);
  }

  handleEditIcon(): void {
    if (
      (this?.val || this?.selectValue) &&
      !this.isCursorOnInput &&
      this.inputStatus !== 'loading'
    ) {
      this.showEditIcon();
    } else {
      this.hideEditIcon();
    }
  }

  hideEditIcon(): void {
    if (this.inputType === 'select') {
      if (this.inputStatus) {
        this.inputStatus === 'default' && this.showCaretIcon();
      } else {
        this.showCaretIcon();
      }
    }
    if (this.inputType === 'inputIcon' && this.inputStatus !== 'loading') {
      this.showIconInput();
    }
    if (this.editIcon) {
      this.editIcon.classList.remove('d-block');
      this.editIcon.classList.add('d-none');
      this.setDefaultBackground();
    }
  }

  showEditIcon(): void {
    if (this.inputType === 'select') {
      this.hideCaretIcon();
    }
    if (this.inputType === 'inputIcon') {
      this.hideIconInput();
    }

    if (this.editIcon) {
      this.renderer.addClass(this.elementRef.nativeElement, 'bg-transparent');
      this.renderer.addClass(this.elementRef.nativeElement, 'border-0');
      this.editIcon.classList.remove('d-none');
      this.editIcon.classList.add('d-block');
    }
  }

  setDefaultBackground(): void {
    this.renderer.removeClass(this.elementRef.nativeElement, 'bg-transparent');
    this.renderer.removeClass(this.elementRef.nativeElement, 'border-0');
  }

  // MAT-SELECT
  getCaret(): any {
    const matSelect = this.elementRef.nativeElement;
    return matSelect.firstChild.children[1];
  }

  showCaretIcon(): void {
    const caret = this.getCaret();
    caret.classList.add('d-inline-flex');
    caret.classList.remove('d-none');
  }

  hideCaretIcon(): void {
    const caret = this.getCaret();
    caret.classList.add('d-none');
    caret.classList.remove('d-inline-flex');
  }

  // INPUT WITH ICON
  showIconInput(): void {
    const iconInput = this.getIconInput();
    iconInput.classList.add('d-inline-flex');
    iconInput.classList.remove('d-none');
  }

  hideIconInput(): void {
    const iconInput = this.getIconInput();
    iconInput.classList.add('d-none');
    iconInput.classList.remove('d-inline-flex');
  }

  getIconInput(): any {
    return this.elementRef.nativeElement.parentNode.firstChild;
  }

  // STATUS ICON
  getStatusIcon(className: string): any {
    const parentChildren = this.elementRef.nativeElement.parentNode;
    return parentChildren.querySelector(`.${className}`);
  }

  // LOADING STATUS ICON
  showLoadingIcon(): void {
    const loadingIcon = this.getStatusIcon('fa-spinner');
    loadingIcon.classList.add('d-inline-flex');
    loadingIcon.classList.remove('d-none');
  }

  hideLoadingIcon(): void {
    const loadingIcon = this.getStatusIcon('fa-spinner');
    if (loadingIcon) {
      loadingIcon.classList.add('d-none');
      loadingIcon.classList.remove('d-inline-flex');
    }
  }

  // SUCCESS STATUS ICON
  showSuccessIcon(): void {
    const successIcon = this.getStatusIcon('fa-check');
    successIcon.classList.add('d-inline-flex');
    successIcon.classList.remove('d-none');
  }

  hideSuccessIcon(): void {
    const successIcon = this.getStatusIcon('fa-check');
    if (successIcon) {
      successIcon.classList.add('d-none');
      successIcon.classList.remove('d-inline-flex');
    }
  }

  handleInputType(): void {
    if (this.inputType === 'inputIcon') {
      switch (this.inputStatus) {
        case 'default':
          this.showIconInput();
          this.hideLoadingIcon();
          break;
        case 'loading':
          this.hideIconInput();
          this.hideEditIcon();
          this.showLoadingIcon();
          break;
        case 'edit':
          this.hideIconInput();
          this.hideLoadingIcon();
          this.showEditIcon();
          break;
      }
    }
    if (this.inputType === 'input' || this.inputType === 'select') {
      switch (this.inputStatus) {
        case 'edit':
          this.hideLoadingIcon();
          this.hideSuccessIcon();
          this.showEditIcon();
          break;
        case 'loading':
          this.inputType === 'select' && this.hideCaretIcon();
          this.hideEditIcon();
          this.showLoadingIcon();
          break;
        case 'success':
          this.inputType === 'select' && this.hideCaretIcon();
          this.hideLoadingIcon();
          this.showSuccessIcon();
          break;
        case 'default':
          this.hideEditIcon();
          this.hideLoadingIcon();
          this.hideSuccessIcon();
          break;
      }
    }
  }
}
