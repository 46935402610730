import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  input,
  signal,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {NotificationService, ReportService} from 'src/app/core/services';
import {PlanType} from 'src/app/shared/enums';
import {HelperService} from 'src/app/shared/helpers';
import {
  CloneReportPayload,
  CreateReportFromTemplatePayload,
  Report,
} from 'src/app/shared/models';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { NgClass } from '@angular/common';
import { CompanySelectionComponent } from '../../../../shared/components/company-selection/company-selection.component';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

// [x]: Signals implemented
@Component({
    selector: 'app-create-report-from',
    templateUrl: './create-report-from.component.html',
    styleUrls: ['./create-report-from.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        NgClass,
        CompanySelectionComponent,
        MatDialogActions,
        LoadingComponent,
        TranslateModule,
    ],
})
export class CreateReportFromComponent implements OnInit {
  isFromWizard = input<boolean>();
  @Input() conditionFromWizard?: Array<any>;
  @Input() dataType?: 'criteria' | 'specific' | 'all';

  @Output() setNameAndCondition = new EventEmitter<{
    reportName: string;
    conditions: Array<any>;
    dataType: 'criteria' | 'specific' | 'all';
  }>();

  public userPlan: PlanType = this.helperService.getUserPlan();
  public isIndividual: boolean;
  public report: Report;
  public template: Report;
  public from: 'report' | 'template';
  public generalLoading = signal<boolean>(false);
  public isSavingData = signal<boolean>(false);

  public cloneReportForm: UntypedFormGroup;
  public conditions: [] = [];
  public conditionsInvalid = true;
  conditionsIsTouched = false;

  public notiCloneReport: string;

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CreateReportFromComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.isIndividual = this.userPlan === PlanType.Individual;
    this.report = this.data.report;
    this.template = this.data.template;
    this.from = this.data.from;
    this.setDataType();
    this.getTranslations();
    this.buildCloneReportForm();
    if (this.isFromWizard()) {
      this.cloneReportForm.get('name')?.valueChanges.subscribe((name) => {
        this.setNameAndCondition.emit({
          reportName: name,
          conditions: this.conditions,
          dataType: this.dataType || 'specific',
        });
      });
    }
  }

  buildCloneReportForm(): void {
    this.cloneReportForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getConditions(event): void {
    this.dataType = event.dataType;
    this.conditionsIsTouched = true;
    if (event.dataType === 'criteria' || event.dataType === 'specific') {
      this.conditions = event.conditions;
      this.conditionsInvalid = this.conditions.length === 0;
    }
    if (event.dataType === 'all') {
      this.conditionsInvalid = false;
      this.conditions = event.conditions;
    }
    // only for create-report wizard
    if (this.isFromWizard()) {
      this.conditionFromWizard = this.conditions;
      this.setNameAndCondition.emit({
        reportName: this.cloneReportForm.get('name')
          ? this.cloneReportForm.get('name')?.value
          : '',
        conditions: this.conditions,
        dataType: this.dataType ? this.dataType : 'specific',
      });
    }
  }

  setLoading(event: boolean): void {
    setTimeout(() => {
      this.generalLoading.set(event);
    }, 0);
  }

  saveClone(): void {
    this.isSavingData.set(true);
    const payload = {
      name: this.cloneReportForm.get('name')?.value,
      companiesMatching: this.isIndividual ? [] : this.conditions,
      favorite: false,
      reportId: this.report.id,
    } as CloneReportPayload;

    this.reportService.cloneReport(payload, this.report.id).subscribe({
      next: () => {
        this.notificationService.success(this.notiCloneReport, 5000);
        this.isSavingData.set(false);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.isSavingData.set(false);
        this.notificationService.error(err.error.error_description, 5000);
      },
    });
  }

  createReportFromTemplate(): void {
    this.isSavingData.set(true);
    const payload = {
      name: this.cloneReportForm.get('name')?.value,
      companiesMatching: this.isIndividual ? [] : this.conditions,
      favorite: false,
    } as CreateReportFromTemplatePayload;

    this.reportService
      .createReportFromTemplate(payload, this.template.id)
      .subscribe({
        next: (res) => {
          this.report = res;
          this.notificationService.success(this.notiCloneReport, 5000);
          this.isSavingData.set(false);
          this.dialogRef.close(true);
          this.router.navigate([`/reports/${this.report.id}/edit`]);
        },
        error: (err) => {
          this.isSavingData.set(false);
          this.notificationService.error(err.error.error_description, 5000);
        },
      });
  }

  handleSubmit(): void {
    this.conditionsIsTouched = true;
    if (this.cloneReportForm.invalid && this.conditionsInvalid) {
      this.cloneReportForm.markAsDirty();
      this.cloneReportForm.markAsTouched();
      for (const ctrl in this.cloneReportForm.controls) {
        this.cloneReportForm.controls[ctrl].markAsDirty();
      }
    }

    if (this.cloneReportForm.valid && !this.conditionsInvalid) {
      if (this.from === 'report') {
        this.saveClone();
      }
      if (this.from === 'template') {
        this.createReportFromTemplate();
      }
    }
  }

  getTranslations(): void {
    this.translate
      .get('reporting.clone_report.notification.cloned')
      .subscribe({next: (res) => (this.notiCloneReport = res)});
    this.translate
      .get('reporting.template_gallery.notification.from_template')
      .subscribe({next: (res) => (this.notiCloneReport = res)});
  }

  setDataType(): void {
    if (this.report && this.report?.companyDataType) {
      this.dataType = this.data.report.companyDataType;
    }

    if (!this.report?.companyDataType) {
      if (this.report?.companiesMatching) {
        if (this.report.companiesMatching.length === 0) {
          this.dataType = 'all';
          this.conditionsInvalid = false;
        }
        if (this.report.companiesMatching.length !== 0) {
          if (this.report.companiesMatching[0].operator === 'in') {
            this.dataType = 'specific';
          } else {
            this.dataType = 'criteria';
          }
        }
      } else {
        this.dataType = 'specific';
      }
    }
  }
}
