<div
  class="page-list"
  [ngClass]="{'sticky-list': sticky}"
  [ngStyle]="{'display': isPresentationMode ? 'none' : 'flex'}"
>
  @if (isEditMode) {
    <button
      [matMenuTriggerFor]="newPageMenu"
      #newTabBtn="matMenuTrigger"
      class="fa-regular fa-file-plus pagination-control px-3"
      [ngClass]="{'menu-open': newTabBtn?.menuOpen}"
    ></button>
  }
  <mat-menu #newPageMenu="matMenu">
    <button (click)="addTab('newPage')" mat-menu-item>
      {{ "shared.components.general.new_page_label" | translate }}
    </button>
    <button (click)="addTab('newTemplatePage')" mat-menu-item>
      {{ "shared.components.general.from_library_label" | translate }}
    </button>
  </mat-menu>

  @if (showScroll && !isMobile) {
    <button
      id="leftButton"
      class="fa-regular fa-arrow-left pagination-control px-3"
      [disabled]="scrollLimitAt === 'left'"
      [ngClass]="{'pagination-disabled': scrollLimitAt === 'left'}"
      (click)="scrollList('left', 300)"
    ></button>
  }
  <div
    #tabList
    class="wsm-tab-label-container"
    cdkScrollable
    [ngClass]="{'mobile-tabs': isMobile}"
  >
    <div
      class="wsm-tab-list"
      #tabListContent
      cdkDropList
      cdkDropListLockAxis="x"
      cdkDropListOrientation="horizontal"
      [cdkDropListDisabled]="disableDrag"
      [cdkDropListAutoScrollStep]="10"
      (cdkDropListDropped)="drop($event)"
      (scroll)="onScroll()"
    >
      @for (tab of tabItems$ | async; track tab.keyId; let i = $index) {
        <div
          [id]="'tab' + i"
          (click)="$event.stopPropagation(); selectTab(tab)"
          [ngClass]="{
            'wsm-tab-active': tab.isActive,
            'edit-tab': isEditMode,
            'menu-open': tab.menuOpen,
          }"
          class="wsm-tab"
          matRipple
          cdkDrag
          cdkDragBoundary=".wsm-tab-label-container"
          [cdkDragDisabled]="!tab.isActive || disableDrag"
          [ngStyle]="{'pointer-events': disableTabs ? 'none' : 'auto'}"
        >
          @if (tab.labelComponent) {
            <ng-container *ngTemplateOutlet="tab.labelComponent.labelContent">
            </ng-container>
          }
          @if (!tab.labelComponent) {
            {{ tab.label }}
          }
        </div>
      }
    </div>
  </div>

  @if (showScroll && !isMobile) {
    <button
      id="rightButton"
      class="fa-regular fa-arrow-right pagination-control px-3"
      [disabled]="scrollLimitAt === 'right'"
      [ngClass]="{'pagination-disabled': scrollLimitAt === 'right'}"
      (click)="scrollList('right', 300)"
    ></button>
  }
  @if (!isMobile && showMenuPageList()) {
    <button
      id="pageListButton"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      class="fa-regular fa-bars pagination-control px-3"
      (click)="onShowPageList()"
    ></button>
    <ng-template
      #pageListOverlay
      cdkConnectedOverlay
      (backdropClick)="onShowPageList()"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="showPageList"
      [cdkConnectedOverlayPositions]="overlayPosition()"
      [cdkConnectedOverlayMinWidth]="'350px'"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayPanelClass]="[
        'wsm-bg-primary',
        'rounded-3',
        'wsm-mat-shadow',
      ]"
    >
      @if (showPageList) {
        <ng-container *ngTemplateOutlet="pageMenuList"> </ng-container>
      }
    </ng-template>
  }
</div>

@if (!hideBody) {
  <div class="tabs-body mt-4">
    @if (selectedTab && selectedTab.bodyComponent) {
      <ng-container *ngTemplateOutlet="selectedTab.bodyComponent.bodyContent">
      </ng-container>
    }
  </div>
}

<ng-template #pageMenuList>
  <div class="w-100 page-list-box">
    <div class="wsm-input-group-prepend w-100">
      <i class="fa-regular fa-magnifying-glass me-2"></i
      ><input
        #pageSearchInput
        autofocus
        type="text"
        class="wsm-input w-100"
        placeholder="Search page"
        [formControl]="pageSearchControl"
      />
    </div>
    @if (pageItemsFiltered().length > 0) {
      <div class="page-list-items">
        <mat-list class="d-flex flex-column gap-1" role="list">
          @for (item of pageItemsFiltered(); track item.id; let i = $index) {
            <mat-list-item
              [id]="'page-item-list-' + i"
              (click)="selectTabFromPageList(item)"
              class="page-menu-item"
              [ngClass]="{
                'page-menu-item-active': item.isActive,
                'page-menu-item-no-visible': !item.isVisible,
              }"
              role="listitem"
            >
              <div
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <span>
                  {{ item.name | truncate: 30 }}
                </span>
                @if (from === "report" && !isExternalUser()) {
                  <button
                    (click)="$event.stopPropagation(); onChangeVisibility(item)"
                    matTooltip="{{
                      (item.isVisible
                        ? 'reporting.report_builder.hide_page_label'
                        : 'reporting.report_builder.show_page_label'
                      ) | translate
                    }}"
                    matTooltipPosition="below"
                    [matTooltipPositionAtOrigin]=""
                    matTooltipClass="wsm-tooltip tooltip-below"
                    class="page-visibility-button"
                  >
                    <i
                      [ngClass]="{
                        'fa-eye-slash': !item.isVisible,
                        'fa-eye': item.isVisible,
                      }"
                      class="fa-regular fa-eye-slash"
                    ></i>
                  </button>
                }
              </div>
            </mat-list-item>
          }
        </mat-list>
      </div>
    }
    @if (pageItemsFiltered().length === 0) {
      <p class="text-center w-100">
        {{ "reporting.report_builder.page_item_not_found" | translate }}
      </p>
    }
  </div>
</ng-template>
