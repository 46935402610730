import {updateState, withDevtools} from '@angular-architects/ngrx-toolkit';
import {signalStore, type, withComputed, withMethods} from '@ngrx/signals';
import {entityConfig, setEntities, withEntities} from '@ngrx/signals/entities';
import {Dataset} from 'src/app/shared/models/dataset.model';

type DatasetEntityType = Required<Dataset>;
const DatasetEntityConfig = entityConfig({
  entity: type<DatasetEntityType>(),
  collection: '_datasets',
  selectId: (dataset) => dataset._id,
});

export const DatasetStoreSignal = signalStore(
  {providedIn: 'root'},
  withDevtools('DatasetStore'),
  withEntities(DatasetEntityConfig),
  withComputed(({_datasetsEntities}) => ({
    getAllDataSets: _datasetsEntities,
  })),
  withMethods((store) => ({
    setDatasets(datasets: DatasetEntityType[]): void {
      _setDatasets(store, datasets);
    },
  }))
);

function _setDatasets(store, datasets: DatasetEntityType[]): void {
  updateState(
    store,
    '[Dataset] Set Datasets',
    setEntities(datasets, DatasetEntityConfig)
  );
}
