<section class="widget-editor overlay-container position-relative">
  <div class="widget-editor-content d-flex">
    <div class="d-flex flex-column">
      <!-- Main Title -->
      <div class="d-flex justify-content-between align-items-center pe-3">
        <h2 class="bottom-sheet-title d-block w-100">
          {{ "reporting.widget_editor.title_edit" | translate }}
        </h2>
        @if (currentScope()) {
          <span class="wsm-tag success current-scope-pill"
            ><i class="fa-regular fa-circle-dot fa-beat"></i>&nbsp;{{
              "reporting.widget_editor.data_form.label_scope" | translate
            }}:&nbsp; {{ currentScope() }}</span
          >
        }
      </div>
      <p class="mt-2 mb-2">
        <small class="d-flex wsm-text-grayed">
          {{ "common.fields_required" | translate }}
        </small>
      </p>

      <!-- Data & Style Form-->
      <div class="bottom-sheet-form d-flex flex-column px-0">
        <!-- Tabs -->
        <mat-tab-group
          [selectedIndex]="this.formType === 'data' ? 0 : 1"
          (selectedTabChange)="onTabChanged($event)"
          [disableRipple]="true"
          class="form-tabs h-100"
          mat-stretch-tabs
        >
          <!-- Widget Data Form -->
          @if (
            widget.widgetType &&
            widget.widgetType !== "text" &&
            widget.widgetType !== "title" &&
            widget.widgetType !== "image" &&
            widget.widgetType !== "container" &&
            widget.widgetType !== "branding"
          ) {
            <mat-tab
              label="{{ 'reporting.widget_editor.data_label' | translate }}"
            >
              <ng-template matTabContent>
                <ng-container>
                  <app-widget-data
                    [widget]="widget"
                    [widgetDataForm]="widgetDataForm"
                    [datasets]="datasets"
                    [datasetFilterCtrl]="datasetFilterCtrl"
                    [newDimensionForm]="newDimensionForm"
                    [newMeasureForm]="newMeasureForm"
                    [ckEditorCtrl]="ckEditorCtrl"
                    [reportPage]="reportPage"
                    [filterApplied]="filterApplied"
                    [previewed]="previewed"
                    [sortChange]="sortChange"
                    [loading]="loading"
                    (dataFormPrevious)="setPreviousDataForm($event)"
                    (sendFilters)="setFilterData($event)"
                    (saveWidget)="saveWidget()"
                  />
                </ng-container>
              </ng-template>
            </mat-tab>
          }

          <!-- Widget Style Form -->
          <mat-tab
            label="{{ 'reporting.widget_editor.style_label' | translate }}"
          >
            <ng-template matTabContent>
              <ng-container>
                <app-widget-style
                  [widget]="widget"
                  [widgetStyleForm]="widgetStyleForm"
                  [reportTheme]="reportTheme"
                  [theme]="theme"
                  [reportPage]="reportPage"
                  [formType]="formType"
                  [page]="data?.page!"
                  [pages]="data?.pages!"
                  [loading]="loading"
                  (saveWidget)="saveWidget()"
                  (styleFormPrevious)="setPreviousStyleForm($event)"
                />
              </ng-container>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

      <!-- Save Buttons -->
      <div class="d-flex justify-content-end align-items-center p-1 mt-2">
        <button class="wsm-btn wsm-btn-outline me-2" (click)="dismiss($event)">
          {{ "reporting.widget_editor.cancel_button" | translate }}
        </button>
        @if (formType === "data") {
          @if (widget.widgetType !== "scorecard") {
            <button
              [disabled]="
                widgetDataForm.invalid ||
                (widget.widgetType !== 'gallery' &&
                  widget.widgetType !== 'dynamictext' &&
                  widget.widgetType !== 'text' &&
                  widget.widgetType !== 'title' &&
                  widget.widgetType !== 'image' &&
                  widget.widgetType !== 'container' &&
                  widget.widgetType !== 'branding' &&
                  widget?.metrics?.length! <= 0) ||
                ((widget?.chartType === 'stackedcolumn2d' ||
                  widget?.chartType === 'stackedbar2d') &&
                  widget?.dimensions?.length! < 2) ||
                (widget.widgetType !== 'scorecard' &&
                  widget.chartType !== 'funnel' &&
                  widget.widgetType !== 'dynamictext' &&
                  widget.widgetType !== 'gallery' &&
                  widget.widgetType !== 'gauge' &&
                  widget.widgetType !== 'current-goal' &&
                  widget.widgetType !== 'branding' &&
                  widget.widgetType !== 'container' &&
                  widget.widgetType !== 'image' &&
                  widget.widgetType !== 'image' &&
                  widget.widgetType !== 'title' &&
                  widget.widgetType !== 'text' &&
                  widget?.dimensions?.length! < 1) ||
                loading ||
                disableSave
              "
              class="wsm-btn wsm-btn-primary me-2"
              (click)="saveWidget()"
              type="button"
            >
              {{ "reporting.widget_editor.apply_button" | translate }}
            </button>
          }
          <!--ADR-2948: Row Count Feature-->
          @if (widget.widgetType === "scorecard") {
            <button
              [disabled]="
                widgetDataForm.invalid ||
                (widget?.metrics?.length! < 1 &&
                  widget?.dimensions?.length! < 1) ||
                loading ||
                disableSave
              "
              class="wsm-btn wsm-btn-primary me-2"
              (click)="saveWidget()"
              type="button"
            >
              {{ "reporting.widget_editor.apply_button" | translate }}
            </button>
          }
        }
        @if (formType === "style") {
          <button
            [disabled]="widgetStyleForm.invalid || loading || disableSave"
            class="wsm-btn wsm-btn-primary me-2"
            (click)="saveWidget()"
            type="button"
          >
            {{ "reporting.widget_editor.apply_button" | translate }}
          </button>
        }
      </div>
    </div>

    <!-- Preview -->
    <div
      [ngStyle]="{'background-color': theme.pageColor}"
      class="bottom-sheet-preview position-relative"
      [ngClass]="{'ps-0': loading}"
    >
      <app-widget-preview
        [pageTheme]="data.page?.theme!"
        [page]="data.page"
        [reportId]="data.reportId"
        [reportTheme]="reportTheme"
        [theme]="data.theme"
        [widgetStyleForm]="widgetStyleForm"
        [showPreviewOverlay]="showPreviewOverlay"
        [widget]="widget"
        [height]="heightPreview"
        [width]="widthPreview"
        [sortChange]="sortChange"
        [datasetFilterCtrl]="datasetFilterCtrl"
        [filterApplied]="filterApplied"
        [showTableMessage]="showTableMessage()"
        (clickPreview)="previewData()"
        (widgetSortChanged)="onWidgetSort($event)"
      />
      <app-loading [isTable]="true" [generalLoading]="loadingPreview" />
    </div>
  </div>

  <app-loading [generalLoading]="loading" />
</section>
