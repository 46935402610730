import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {ReportPage, Widget} from '../../models';
import { DynamicDateRangeComponent } from '../../components/dynamic-date-range/dynamic-date-range.component';

@Component({
    selector: 'app-dynamic-date-range-text',
    templateUrl: './dynamic-date-range-text.component.html',
    standalone: true,
    imports: [DynamicDateRangeComponent],
})
export class DynamicDateRangeTextComponent implements OnInit {
  public widget: Widget;
  public reportPage: ReportPage;

  constructor(
    public dialogRef: MatDialogRef<DynamicDateRangeTextComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {widget: Widget; reportPage: ReportPage}
  ) {}

  ngOnInit(): void {
    this.widget = this.data?.widget;
    this.reportPage = this.data?.reportPage;
  }

  insertDateVariable(event: string, type: string): void {
    this.dialogRef.close({event, type});
  }
}
