import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {
  DialogService,
  NotificationService,
  ReportService,
} from 'src/app/core/services';
import {downloadURL} from '../../helpers';
import moment from 'moment';
import {ReportFile} from '../../models';
import { NgClass } from '@angular/common';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';

export interface HistoryDialogData {
  reportId: string;
  files: Array<ReportFile>;
  isMobile: boolean;
  overrideWidth: boolean;
}
@Component({
    selector: 'app-report-history-dialog',
    templateUrl: './report-history-dialog.component.html',
    styleUrls: ['./report-history-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatDialogContent,
        MatTable,
        MatSort,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        TranslateModule,
    ],
})
export class ReportHistoryDialogComponent implements OnInit, OnDestroy {
  // Table properties
  displayedColumns: string[] = ['name', 'actions'];

  // Properties
  pdfBlob: Blob;
  pdfContents: Uint8Array;
  pdfName: string;
  subs: Subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ReportHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HistoryDialogData
  ) {}

  ngOnInit(): void {
    this.sortHistory();
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe((res: MouseEvent): void => {
          this.dialogService.closeDialog(this.dialogRef);
        })
      );
    }
  }

  sortHistory(): void {
    this.data.files &&
      this.data.files.sort((a, b) =>
        moment(b.creationDate).diff(moment(a.creationDate))
      );
  }

  getReportPdf(filePath: string, fileName: string): void {
    const payload = {
      filepath: filePath,
    };
    this.reportService
      .getScheduledReport(this.data.reportId, payload)
      .subscribe({
        next: (res: Blob): void => {
          this.pdfBlob = res;
          const reader: FileReader = new FileReader();
          reader.readAsArrayBuffer(res);
          reader.onloadend = (): void => {
            this.pdfContents = new Uint8Array(reader.result as ArrayBuffer);
          };
        },
        error: (err) => {
          this.notificationService.error(err.message, 5000);
        },
        complete: (): void => {
          this.pdfName = fileName;
          this.downloadPdf();
        },
      });
  }

  downloadPdf(): void {
    if (this.pdfBlob) {
      const url = window.URL.createObjectURL(this.pdfBlob);
      this.downloadURL(url, this.pdfName);
      setTimeout(() => {
        return window.URL.revokeObjectURL(url);
      }, 1000);
    }
  }

  downloadURL(data: string, fileName: string): void {
    downloadURL(data, fileName);
  }

  onClose(): void {
    this.dialogService.closeDialog(this.dialogRef);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
