import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportTheme} from '../../models';
import { ClassicPlayerComponent } from './utils/classic-player/classic-player.component';
import { ModernPlayerComponent } from './utils/modern-player/modern-player.component';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    standalone: true,
    imports: [ClassicPlayerComponent, ModernPlayerComponent],
})
export class AudioPlayerComponent implements OnInit {
  // Inputs / Output
  @Input() audioUrl: string;
  @Input() reportTheme: ReportTheme;
  @Input() playerStyle: string;
  @Output() hidePlayer = new EventEmitter();

  ngOnInit(): void {
    if (!this.playerStyle) {
      this.playerStyle = 'classic';
    }
  }

  closePlayer($event: string): void {
    this.hidePlayer.emit({
      audioUrl: $event,
    });
  }

  get convertSeconds(): any {
    return this.getTimeCodeFromNum.bind(this);
  }

  getTimeCodeFromNum(duration: number): string {
    const secNum = duration;
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - hours * 3600) / 60);
    const seconds = +(secNum - hours * 3600 - minutes * 60).toFixed(0);

    if (hours === 0) {
      return `${minutes < 10 ? '0' + minutes : minutes}:${
        seconds < 10 ? '0' + seconds : seconds
      }`;
    }

    return `${hours < 10 ? '0' + hours : hours}:${
      minutes < 10 ? '0' + minutes : minutes
    }:${seconds < 10 ? '0' + seconds : seconds}`;
  }
}
