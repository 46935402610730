@if (cardData?.elements) {
  <div
    class="carousel-card-container"
    (swipe)="onSwipe($event)"
    [ngClass]="{'mt-3': widget && !widget.title}"
    [ngStyle]="{
      'background-color': themes.theme?.backgroundColor
        ? themes.theme.backgroundColor
        : 'var(--wsm-bg-tertiary)',
      'color': themes.theme?.bodyColor ? themes.theme.bodyColor : '',
      'box-shadow': widget ? 'unset' : 'var(--wsm-global-box-shadow)'
    }"
  >
    @for (card of cardData.elements; track card; let i = $index) {
      <div
        class="carousel-card"
        [ngClass]="{
          'px-3': widget,
          'p-3': !widget,
          'metric-box': card?.type === 'adPreview' && !card?.isDummy
        }"
        [ngStyle]="{'margin-left': card.marginLeft + '%'}"
      >
        @if (card?.type === "adPreview") {
          <!-- Ad Preview -->
          <app-ad-preview
            [widget]="card?.widget"
            [ad]="card?.['ad-' + i]"
            [theme]="card?.theme"
            [isExternalUser]="card?.isExternalUser"
            [helpUrl]="card?.helpUrl"
          />
        } @else {
          @for (label of cardData.labels; track label) {
            <div class="d-flex flex-column mb-3">
              <label
                class="card-label"
                [ngClass]="{'wsm-text-grayed': !widget}"
                [ngStyle]="{
                  'color': themes.theme?.titleColor
                    ? themes.theme.titleColor
                    : '',
                  'font-weight': widget ? 'bold' : ''
                }"
              >
                {{ label.label }}
              </label>
              <!-- Element Label Switch -->
              @if (!label.dataType) {
                <div>
                  @switch (label.key) {
                    <!-- Status -->
                    @case ("status") {
                      @if (label.key === "status") {
                        <div
                          class="status-indicator p-1 text-center"
                          [ngClass]="{
                            'active':
                              card.status === 'DONE' ||
                              card.status === 'SYNCED',
                            'error':
                              card.status === 'ERROR' ||
                              card.status === 'AUTHENTICATION_ERROR',
                            'in-progress': card.status === 'RUNNING',
                            'queue':
                              card.status === 'QUEUE' ||
                              card.status === 'PENDING' ||
                              !card.status
                          }"
                        >
                          @if (
                            card.status === "DONE" || card.status === "SYNCED"
                          ) {
                            {{ "common.status.done" | translate }}
                          }
                          @if (card.status === "ERROR") {
                            {{ "common.status.error" | translate }}
                          }
                          @if (card.status === "AUTHENTICATION_ERROR") {
                            {{
                              "common.status.authentication_error" | translate
                            }}
                          }
                          @if (card.status === "RUNNING") {
                            {{ "common.status.running" | translate }}
                          }
                          @if (
                            card.status === "QUEUE" || card.status === "PENDING"
                          ) {
                            {{ "common.status.queue" | translate }}
                          }
                          @if (!card.status) {
                            --
                          }
                        </div>
                      }
                    }
                    <!-- Picture -->
                    @case ("picture") {
                      <div class="d-flex align-items-center">
                        <div
                          class="avatar-container"
                          [style.background-color]="
                            card?.avatarBg ? card?.avatarBg : '#fc7282'
                          "
                        >
                          <img
                            [ngClass]="{
                              'avatar-local ': card?.avatarLocal,
                              'avatar-picture': !card?.avatarLocal
                            }"
                            [src]="card?.avatar"
                            alt="Avatar"
                          />
                        </div>
                        @if (card?.avatarName) {
                          <p class="card-text ms-2">
                            {{ card.avatarName }}
                          </p>
                        }
                      </div>
                    }
                    <!-- Logo -->
                    @case ("logo") {
                      <img [src]="card?.logo" width="25" alt="Platform Logo" />
                    }
                    <!-- Last Updated -->
                    @case ("lastUpdated") {
                      <p class="card-text">
                        {{
                          card.lastUpdated
                            ? (card.lastUpdated | dateAsAgo)
                            : "--"
                        }}
                      </p>
                    }
                    <!-- Default -->
                    @default {
                      <p class="card-text">
                        {{ card[label.key] ? card[label.key] : "--" }}
                      </p>
                    }
                  }
                </div>
              }
              <!-- Element Data Type Switch (For Tables) -->
              @if (label.dataType) {
                <div>
                  @switch (label.dataType) {
                    <!-- Audio Player -->
                    @case ("audio") {
                      @if (
                        card[label.key] !== "" &&
                        card[label.key]?.value !== "Total" &&
                        card[label.key]?.value !== "--"
                      ) {
                        @if (isPrint && card[label.key].value) {
                          <a
                            [href]="card[label.key].value"
                            class="text-decoration-none"
                            target="_blank"
                            [ngStyle]="{'color': themes.theme.bodyColor}"
                          >
                            <i
                              [ngStyle]="{'color': themes.theme.bodyColor}"
                              class="fa-regular fa-play me-1"
                              style="cursor: pointer"
                              title="Play Recording"
                            >
                            </i>
                            {{
                              "reporting.widgets.table.play_label" | translate
                            }}
                          </a>
                        }
                        @if (
                          !checkAudio(card[label.key].value) &&
                          card[label.key].value &&
                          !isPrint
                        ) {
                          <div
                            (click)="playAudio(card[label.key].value)"
                            class="btn-play p-1"
                          >
                            <span
                              [ngStyle]="{'color': themes.theme.bodyColor}"
                              class="fa-regular fa-play me-1"
                              title="Play Recording"
                            ></span>
                            {{
                              "reporting.widgets.table.play_label" | translate
                            }}
                          </div>
                        }
                        @if (!isPrint) {
                          @if (
                            checkAudio(card[label.key].value) &&
                            card[label.key].value
                          ) {
                            <app-audio-player
                              (hidePlayer)="hideAudioPlayer($event)"
                              [audioUrl]="card[label.key].value"
                              [playerStyle]="widget?.playerStyle!"
                              [reportTheme]="
                                themes.reportTheme || themes.pageTheme
                              "
                            >
                            </app-audio-player>
                          }
                        }
                        @if (!card[label.key].value) {
                          <div>
                            <span
                              [ngStyle]="{'color': themes.theme.bodyColor}"
                              class="no-record p-2"
                            >
                              {{
                                "reporting.widgets.table.no_audio_label"
                                  | translate
                              }}
                            </span>
                          </div>
                        }
                      }
                      @if (
                        card[label.key]?.value === "Total" ||
                        card[label.key]?.value === "--"
                      ) {
                        <span>{{ card[label.key]?.value }}</span>
                      }
                    }
                    <!-- Phone -->
                    @case ("phone") {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value }}
                      </p>
                    }
                    <!-- Date -->
                    @case ("date") {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        @if (label?.field?.dateConfiguration) {
                          @switch (
                            label?.field?.dateConfiguration?.dateFormat
                          ) {
                            @case ("dateHourMinute") {
                              {{
                                card[label.key].value
                                  | date: "yyyy-MM-dd h:mm a z"
                              }}
                            }
                            @case ("date") {
                              {{ card[label.key].value }}
                            }
                            @case ("dayHourStandard") {
                              {{ card[label.key].value }}
                            }
                            @case ("dayHours") {
                              {{ card[label.key].value | date: "h:mm a z" }}
                            }
                            @case ("shortDay") {
                              {{ card[label.key].value }}
                            }
                            @case ("longDay") {
                              {{ card[label.key].value }}
                            }
                            @case ("monthDay") {
                              {{ card[label.key].value | date: "MMM, dd" }}
                            }
                            @case ("monthDayYear") {
                              {{ card[label.key].value | date: "MMM dd, yyyy" }}
                            }
                            @case ("week") {
                              {{ card[label.key].value }}
                            }
                            @case ("month") {
                              {{ card[label.key].value }}
                            }
                            @case ("monthYear") {
                              {{ card[label.key].value }}
                            }
                            @case ("quarter") {
                              {{ card[label.key].value }}
                            }
                            @case ("year") {
                              {{ card[label.key].value }}
                            }
                          }
                        }
                        @if (!label?.field?.dateConfiguration) {
                          {{ card[label.key].value }}
                        }
                      </p>
                    }
                    <!-- Time -->
                    @case ("time") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value | transformDate }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- String -->
                    @case ("string") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Integer -->
                    @case ("integer") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Float -->
                    @case ("float") {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                    }
                    <!-- Decimal -->
                    @case ("decimal") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Currency -->
                    @case ("currency") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Percentage -->
                    @case ("percentage") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value | percent: "1.2-2" }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Ad Preview -->
                    @case ("adPreview") {
                      <button
                        class="wsm-btn wsm-btn-outline d-flex"
                        (click)="openAdPreview(card[label.key])"
                        [ngStyle]="{
                          'outline-color': themes.theme?.bodyColor,
                          'color': themes.theme?.bodyColor
                        }"
                      >
                        <i class="fa-solid fa-link-simple me-2"></i>
                        <span>Open Ad Preview</span>
                      </button>
                    }
                    <!-- Default -->
                    @default {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value }}
                      </p>
                    }
                  }
                </div>
              }
            </div>
          }
        }
        <ng-template #labels>
          @for (label of cardData.labels; track label) {
            <div class="d-flex flex-column mb-3">
              <label
                class="card-label"
                [ngClass]="{'wsm-text-grayed': !widget}"
                [ngStyle]="{
                  'color': themes.theme?.titleColor
                    ? themes.theme.titleColor
                    : '',
                  'font-weight': widget ? 'bold' : ''
                }"
              >
                {{ label.label }}
              </label>
              <!-- Element Label Switch -->
              @if (!label.dataType) {
                <div>
                  @switch (label.key) {
                    <!-- Status -->
                    @case ("status") {
                      @if (label.key === "status") {
                        <div
                          class="status-indicator p-1 text-center"
                          [ngClass]="{
                            'active':
                              card.status === 'DONE' ||
                              card.status === 'SYNCED',
                            'error':
                              card.status === 'ERROR' ||
                              card.status === 'AUTHENTICATION_ERROR',
                            'in-progress': card.status === 'RUNNING',
                            'queue':
                              card.status === 'QUEUE' ||
                              card.status === 'PENDING' ||
                              !card.status
                          }"
                        >
                          @if (
                            card.status === "DONE" || card.status === "SYNCED"
                          ) {
                            {{ "common.status.done" | translate }}
                          }
                          @if (card.status === "ERROR") {
                            {{ "common.status.error" | translate }}
                          }
                          @if (card.status === "AUTHENTICATION_ERROR") {
                            {{
                              "common.status.authentication_error" | translate
                            }}
                          }
                          @if (card.status === "RUNNING") {
                            {{ "common.status.running" | translate }}
                          }
                          @if (
                            card.status === "QUEUE" || card.status === "PENDING"
                          ) {
                            {{ "common.status.queue" | translate }}
                          }
                          @if (!card.status) {
                            --
                          }
                        </div>
                      }
                    }
                    <!-- Picture -->
                    @case ("picture") {
                      <div class="d-flex align-items-center">
                        <div
                          class="avatar-container"
                          [style.background-color]="
                            card?.avatarBg ? card?.avatarBg : '#fc7282'
                          "
                        >
                          <img
                            [ngClass]="{
                              'avatar-local ': card?.avatarLocal,
                              'avatar-picture': !card?.avatarLocal
                            }"
                            [src]="card?.avatar"
                            alt="Avatar"
                          />
                        </div>
                        @if (card?.avatarName) {
                          <p class="card-text ms-2">
                            {{ card.avatarName }}
                          </p>
                        }
                      </div>
                    }
                    <!-- Logo -->
                    @case ("logo") {
                      <img [src]="card?.logo" width="25" alt="Platform Logo" />
                    }
                    <!-- Last Updated -->
                    @case ("lastUpdated") {
                      <p class="card-text">
                        {{
                          card.lastUpdated
                            ? (card.lastUpdated | dateAsAgo)
                            : "--"
                        }}
                      </p>
                    }
                    <!-- Default -->
                    @default {
                      <p class="card-text">
                        {{ card[label.key] ? card[label.key] : "--" }}
                      </p>
                    }
                  }
                </div>
              }
              <!-- Element Data Type Switch (For Tables) -->
              @if (label.dataType) {
                <div>
                  @switch (label.dataType) {
                    <!-- Audio Player -->
                    @case ("audio") {
                      @if (
                        card[label.key] !== "" &&
                        card[label.key]?.value !== "Total" &&
                        card[label.key]?.value !== "--"
                      ) {
                        @if (isPrint && card[label.key].value) {
                          <a
                            [href]="card[label.key].value"
                            class="text-decoration-none"
                            target="_blank"
                            [ngStyle]="{'color': themes.theme.bodyColor}"
                          >
                            <i
                              [ngStyle]="{'color': themes.theme.bodyColor}"
                              class="fa-regular fa-play me-1"
                              style="cursor: pointer"
                              title="Play Recording"
                            >
                            </i>
                            {{
                              "reporting.widgets.table.play_label" | translate
                            }}
                          </a>
                        }
                        @if (
                          !checkAudio(card[label.key].value) &&
                          card[label.key].value &&
                          !isPrint
                        ) {
                          <div
                            (click)="playAudio(card[label.key].value)"
                            class="btn-play p-1"
                          >
                            <span
                              [ngStyle]="{'color': themes.theme.bodyColor}"
                              class="fa-regular fa-play me-1"
                              title="Play Recording"
                            ></span>
                            {{
                              "reporting.widgets.table.play_label" | translate
                            }}
                          </div>
                        }
                        @if (!isPrint) {
                          @if (
                            checkAudio(card[label.key].value) &&
                            card[label.key].value
                          ) {
                            <app-audio-player
                              (hidePlayer)="hideAudioPlayer($event)"
                              [audioUrl]="card[label.key].value"
                              [playerStyle]="widget?.playerStyle!"
                              [reportTheme]="
                                themes.reportTheme || themes.pageTheme
                              "
                            >
                            </app-audio-player>
                          }
                        }
                        @if (!card[label.key].value) {
                          <div>
                            <span
                              [ngStyle]="{'color': themes.theme.bodyColor}"
                              class="no-record p-2"
                            >
                              {{
                                "reporting.widgets.table.no_audio_label"
                                  | translate
                              }}
                            </span>
                          </div>
                        }
                      }
                      @if (
                        card[label.key]?.value === "Total" ||
                        card[label.key]?.value === "--"
                      ) {
                        <span>{{ card[label.key]?.value }}</span>
                      }
                    }
                    <!-- Phone -->
                    @case ("phone") {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value }}
                      </p>
                    }
                    <!-- Date -->
                    @case ("date") {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        @if (label?.field?.dateConfiguration) {
                          @switch (
                            label?.field?.dateConfiguration?.dateFormat
                          ) {
                            @case ("dateHourMinute") {
                              {{
                                card[label.key].value
                                  | date: "yyyy-MM-dd h:mm a z"
                              }}
                            }
                            @case ("date") {
                              {{ card[label.key].value }}
                            }
                            @case ("dayHourStandard") {
                              {{ card[label.key].value }}
                            }
                            @case ("dayHours") {
                              {{ card[label.key].value | date: "h:mm a z" }}
                            }
                            @case ("shortDay") {
                              {{ card[label.key].value }}
                            }
                            @case ("longDay") {
                              {{ card[label.key].value }}
                            }
                            @case ("monthDay") {
                              {{ card[label.key].value | date: "MMM, dd" }}
                            }
                            @case ("monthDayYear") {
                              {{ card[label.key].value | date: "MMM dd, yyyy" }}
                            }
                            @case ("week") {
                              {{ card[label.key].value }}
                            }
                            @case ("month") {
                              {{ card[label.key].value }}
                            }
                            @case ("monthYear") {
                              {{ card[label.key].value }}
                            }
                            @case ("quarter") {
                              {{ card[label.key].value }}
                            }
                            @case ("year") {
                              {{ card[label.key].value }}
                            }
                          }
                        }
                        @if (!label?.field?.dateConfiguration) {
                          {{ card[label.key].value }}
                        }
                      </p>
                    }
                    <!-- Time -->
                    @case ("time") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value | transformDate }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- String -->
                    @case ("string") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Integer -->
                    @case ("integer") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Float -->
                    @case ("float") {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                    }
                    <!-- Decimal -->
                    @case ("decimal") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Currency -->
                    @case ("currency") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].valueLabel }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Percentage -->
                    @case ("percentage") {
                      <p
                        class="mb-1"
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value | percent: "1.2-2" }}
                      </p>
                      <app-widget-popover
                        [pipeType]="label.dataType!"
                        [popoverData]="card[label.key]"
                        [theme]="themes.theme"
                      >
                      </app-widget-popover>
                    }
                    <!-- Ad Preview -->
                    @case ("adPreview") {
                      <button
                        class="wsm-btn wsm-btn-outline d-flex"
                        (click)="openAdPreview(card[label.key])"
                        [ngStyle]="{
                          'outline-color': themes.theme?.bodyColor,
                          'color': themes.theme?.bodyColor
                        }"
                      >
                        <i class="fa-solid fa-link-simple me-2"></i>
                        <span>Open Ad Preview</span>
                      </button>
                    }
                    <!-- Default -->
                    @default {
                      <p
                        [ngClass]="
                          card[label.key].class ? card[label.key].class : ''
                        "
                      >
                        {{ card[label.key].value }}
                      </p>
                    }
                  }
                </div>
              }
            </div>
          }
        </ng-template>
        <!-- Actions -->
        @if (card?.buttons?.length! > 0) {
          <div class="mt-3">
            @for (button of card.buttons; track button) {
              @switch (button.type) {
                @case ("checkbox") {
                  <div class="mb-3">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="onHandleCheckbox($event, button?.action, card)"
                      [checked]="button?.checked"
                    >
                      {{ button?.label }}
                    </mat-checkbox>
                  </div>
                }
                @case ("button") {
                  <div>
                    <button
                      class="{{
                        button?.class
                          ? button?.class
                          : 'wsm-btn wsm-btn-primary'
                      }}"
                      [ngClass]="button?.extraClass ? button?.extraClass : ''"
                      (click)="onHandleButton(button?.action, card)"
                      [disabled]="button?.disabled"
                    >
                      {{ button?.label }}
                    </button>
                  </div>
                }
              }
            }
          </div>
        }
      </div>
    }
  </div>
}

<!-- Dot indicators -->
<div class="d-flex align-items-center flex-column mt-3">
  @if (indicatorsOn && cardData?.elements && cardData?.elements?.length !== 0) {
    <div class="carousel-dot-container mb-2">
      @for (indicator of cardData.elements; track indicator; let i = $index) {
        <span
          [id]="'dot-' + i"
          [class]="getIndicatorClass(i) + ' carousel-dot'"
        >
        </span>
      }
      @if (
        usePagination &&
        selectedIndex === cardData.elements.length - 1 &&
        selectedIndex + 1 !== totalRecords
      ) {
        <span class="carousel-dot small"> </span>
      }
    </div>
  }

  @if (
    usePagination && cardData?.elements && cardData?.elements?.length !== 0
  ) {
    <span class="records-indicator">
      {{ selectedIndex + 1 }} - {{ cardData.elements.length }}
      {{ "common.of" | translate }} {{ totalRecords }}
    </span>
  }
</div>
