<div class="overlay-container">
  <app-theme-builder
    [fromParent]="'modal-page'"
    [themeToEdit]="pageTheme"
    [themeToPreview]="theme"
    [canPopulateForm]="canPopulateForm"
    [isSavingData]="loading"
    [parentBackgroundFile]="backgroundFile!"
    [noDispatchInputFile]="noDispatchInputFile"
    (closeDialog)="onCloseDialog($event)"
    (sendTheme)="onSetTheme($event!)"
  >
  </app-theme-builder>
  <app-loading [generalLoading]="loadingImage"></app-loading>
</div>
