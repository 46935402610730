@if (report) {
  <!-- Title & Toolbar -->
  <section
    class="d-flex align-items-center"
    [ngClass]="{'justify-content-between': isMobile}"
  >
    <app-editable-title
      [ngClass]="{'w-100': !isExternalUser}"
      [titleContent]="report.name"
      [editMode]="isEditMode()"
      [useAs]="'header'"
      (titleSave)="onReportNameChange($event)"
    />
    @if (isExternalUser) {
      <app-report-toolbar
        [dataQueueStatue]="dataQueueStatus"
        [report]="report"
        [selectedPage]="selectedPage"
        (presentationModeOn)="togglePresentationMode($event)"
      />
    }
  </section>
  <!-- Report Page -->
  <section
    class="report-container d-block pb-2 mt-3"
    [ngClass]="{'d-lg-flex': !isMobile, 'report-mobile-container': isMobile}"
  >
    <article
      class="d-block flex-column align-items-center flex-grow-1"
      [ngClass]="{'d-lg-flex': !isMobile}"
    >
      <!-- Toolbar (No external users) -->
      @if (!isExternalUser) {
        <div
          class="toolbar-container w-100"
          [ngClass]="{
            'mb-3': tabsOn(),
            'sticky-container': !isMobile && report.orientation === 'vertical',
          }"
        >
          <app-report-toolbar
            [dataQueueStatue]="dataQueueStatus"
            [report]="report"
            [selectedPage]="selectedPage"
            [pageStatesLength]="pageStates.length"
            [pageStateIndex]="pageStateIndex"
            [originalPageState]="originalPageState"
            (reportSettingsUpdated)="onReportSettingUpdate($event)"
            (editModeChange)="onEditModeToggle($event)"
            (widgetSave)="saveMode($event)"
            (presentationModeOn)="togglePresentationMode($event)"
            (widgetSelection)="onAddWidget($event)"
            (shareReportUpdated)="onShareUpdate($event)"
            (pagesReordered)="onEditPageOrder($event)"
            (reportRefreshed)="getReport()"
            (pageStateChanged)="changePageState($event)"
          />
        </div>
      }
      <div
        class="report-page-container"
        [ngClass]="{
          'w-100 d-flex justify-content-end flex-column':
            tabsOn() && report.orientation === 'vertical',
          'flex-grow-1': report.orientation !== 'vertical',
        }"
        [ngStyle]="{
          'width':
            isMobile || (tabsOn() && report.orientation === 'vertical')
              ? 'auto'
              : '1360px',
        }"
      >
        <!-- Page Tabs -->
        @if (tabsOn() || isMobile) {
          <app-tabs
            [isEditMode]="isEditMode()"
            [from]="'report'"
            [selectedIndex]="selectedPage?.order || 0"
            [hideBody]="true"
            [disableDrag]="!isEditMode() || isReportLoading"
            [disableTabs]="isReportLoading"
            [reportInfo]="{
              reportPages: report.pages,
              selectedPage,
              originalPageState,
            }"
            [isMobile]="isMobile"
            (tabSelected)="selectTab($event)"
            (tabAdded)="addPage($event)"
            (tabsReordered)="onPageReorder($event)"
            (changeVisibility)="onHidePage($event)"
            [pageItems]="report.pages"
            [showMenuPageList]="true"
            [isExternalUser]="isExternalUser"
          >
            @for (page of report.pages; track page.id; let i = $index) {
              <app-tab-item [keyId]="page.id" [index]="i" [id]="page.id" #tab>
                <app-tab-label>
                  @if (selectedPage?.order === i && isEditMode()) {
                    <button
                      #pageMenu="matMenuTrigger"
                      (click)="$event.stopPropagation()"
                      (menuOpened)="tab.menuOpen = pageMenu.menuOpen"
                      (menuClosed)="tab.menuOpen = pageMenu.menuOpen"
                      [matMenuTriggerFor]="pageSettingsMenu"
                      [disabled]="isReportLoading"
                      [ngClass]="{'action-disabled': isReportLoading}"
                      class="page-settings btn wsm-button mb-0 d-flex px-1 py-1 me-1"
                      type="button"
                    >
                      <span
                        class="fa-regular fa-chevron-down"
                        title="Page Options"
                      >
                      </span>
                    </button>
                  }
                  <mat-menu #pageSettingsMenu="matMenu">
                    @if (!isExternalUser) {
                      <button
                        (click)="onEditPageSettings(selectedPage)"
                        mat-menu-item
                      >
                        {{
                          "reporting.report_builder.settings_label" | translate
                        }}
                      </button>
                    }
                    @if (!isExternalUser) {
                      <button (click)="duplicatePage(page.id)" mat-menu-item>
                        {{
                          "reporting.report_builder.duplicate_label" | translate
                        }}
                      </button>
                    }
                    <button (click)="onHidePage(page)" mat-menu-item>
                      {{
                        (page.isVisible
                          ? "reporting.report_builder.hide_page_label"
                          : "reporting.report_builder.show_page_label"
                        ) | translate
                      }}
                    </button>
                    @if (!isExternalUser) {
                      <button (click)="onSaveTemplate('page')" mat-menu-item>
                        {{
                          "reporting.report_builder.add_to_library_label"
                            | translate
                        }}
                      </button>
                    }
                    @if (this.report?.pages?.length !== 1 && !isExternalUser) {
                      <button (click)="deletePage(page.id)" mat-menu-item>
                        {{
                          "reporting.report_builder.delete_label" | translate
                        }}
                      </button>
                    }
                  </mat-menu>
                  {{ page.name | truncate: 30 }}
                  @if (!page.isVisible) {
                    <i class="fa-regular fa-eye-slash ms-2"> </i>
                  }
                  @if (!page.isVisible) {
                    <div class="visibility-overlay"></div>
                  }
                </app-tab-label>
              </app-tab-item>
            }
          </app-tabs>
        }
        <!-- Page -->
        @if (selectedPage) {
          <app-report-page
            [ngStyle]="{
              'margin': report.orientation === 'vertical' ? '0 auto' : '',
              'width':
                tabsOn() && report.orientation === 'vertical' ? '100%' : 'auto',
            }"
            [reportId]="report.id"
            [page]="selectedPage"
            [pages]="report.pages"
            [orientation]="report.orientation"
            [parentTheme]="report.theme"
            [isEditMode]="isEditMode()"
            [isPrint]="isPrint"
            [originalPageState]="originalPageState"
            [isMobile]="isMobile"
            (pageUpdated)="onPageUpdated($event)"
            (contextMenuAction)="handleContextMenuAction($event)"
          />
        }
      </div>
    </article>
    <!-- Page Navigator -->
    @if (!tabsOn() && !isMobile) {
      <article class="navigator-container py-3 este">
        <div class="navigator-header ms-4 mb-3">
          <h2 class="h4 mb-0">
            {{ "reporting.report_builder.navigator_title" | translate }}
          </h2>
          @if (isEditMode()) {
            <button
              class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon me-3"
              #pageMenu="matMenuTrigger"
              [matMenuTriggerFor]="newPageMenu"
              [disabled]="isReportLoading"
              [ngClass]="{
                'menu-open': pageMenu.menuOpen,
                'action-disabled': isReportLoading,
              }"
            >
              <i class="fa-regular fa-file-plus"></i>
            </button>
          }
          <mat-menu #newPageMenu="matMenu" class="mat-menu-gray menu-items-end">
            <button (click)="addPage('newPage')" mat-menu-item>
              {{ "reporting.report_builder.new_page_label" | translate }}
            </button>
            <button
              id="btnFromLibraryNavigator"
              (click)="addPage('newTemplatePage')"
              mat-menu-item
            >
              {{ "reporting.report_builder.from_library_label" | translate }}
            </button>
          </mat-menu>
        </div>
        <app-report-template-navigator
          [reportPages]="report.pages"
          [from]="'report'"
          [isEditMode]="isEditMode()"
          [reportId]="report.id"
          [selectedPage]="selectedPage"
          [originalPageState]="originalPageState"
          (pagesReordered)="onPageReorder($event)"
          (pageSelected)="selectPage($event)"
          (openPageSettings)="onEditPageSettings(selectedPage)"
          (pageDuplicated)="duplicatePage($event)"
          (saveAsTemplate)="onSaveTemplate($event)"
          (pageDeleted)="deletePage($event)"
        >
        </app-report-template-navigator>
      </article>
    }
  </section>
}

@if (!report && noAccess) {
  <app-unauthorized-page
    [title]="'common.unauthorized_page.title'"
    [message]="'common.unauthorized_page.first_message'"
  >
  </app-unauthorized-page>
}
