<header class="mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h3 m-0">
      {{ "settings.settings.sso.dialog_title" | translate }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content>
  <label>{{ "settings.settings.sso.entity_id_label" | translate }}</label>
  <p class="wsm-text-grayed mb-3" [innerText]="data.entityId"></p>
  <hr />

  <label>{{ "settings.settings.sso.login_url_label" | translate }}</label>
  <p class="wsm-text-grayed mb-3" [innerText]="data.login"></p>
  <hr />

  <label>{{ "settings.settings.sso.logout_url_label" | translate }}</label>
  <p class="wsm-text-grayed mb-3" [innerText]="data.logout"></p>
  <hr />
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button (click)="onClose()" class="wsm-btn wsm-btn-primary my-2">OK</button>
</mat-dialog-actions>
