<section class="form-container">
  <form
    (click)="$event.stopPropagation()"
    (ngSubmit)="onFilterApplied()"
    [formGroup]="newFilterForm"
    id="newFilterForm"
  >
    <input
      formControlName="filterIsNew"
      id="filterIsNew"
      name="filterIsNew"
      type="hidden"
    />

    <div class="row mx-0 py-3">
      <p class="mb-1">
        <small class="d-flex wsm-text-grayed">
          {{ "common.fields_required" | translate }}
        </small>
      </p>
      <div class="col-12 mb-3">
        <label for="filterField">
          {{
            "reporting.widget_editor.data_form.filter_selection.choose_field_label"
              | translate
          }}&nbsp; <span class="required-mark">*</span></label
        >
        <input
          type="text"
          class="wsm-input w-100"
          id="filterField"
          placeholder="{{
            'reporting.widget_editor.data_form.filter_selection.dimension_placeholder'
              | translate
          }}"
          [formControl]="fieldFilterCtrl"
          [matAutocomplete]="filters"
        />
        <mat-autocomplete
          (optionSelected)="onFilterSelection($event.option.value, true)"
          [displayWith]="displayFilter"
          #filters="matAutocomplete"
          autoActiveFirstOption
        >
          @for (field of filteredFields | async; track field) {
            <mat-option
              [value]="field"
              [disabled]="field.isDisabled"
              [matTooltip]="scopeService.showTooltip(field)"
              matTooltipClass="wsm-tooltip tooltip-right"
              matTooltipPosition="right"
            >
              <span class="d-block mb-1">{{ field.name }}</span>
              <app-connection-logos
                [connections]="field?.connections!"
                [isCustom]="field?.isCustom"
                [type]="'widget'"
              />
            </mat-option>
          }
        </mat-autocomplete>
      </div>

      <div class="col-12 mb-3">
        <label for="filterOperator">
          {{
            "reporting.widget_editor.data_form.filter_selection.operator_label"
              | translate
          }}&nbsp; <span class="required-mark">*</span></label
        >
        <mat-select
          (selectionChange)="clearFilterOperatorInput()"
          [panelClass]="'wsm-custom-select'"
          class="wsm-input w-100"
          formControlName="filterOperator"
          id="filterOperator"
          placeholder="{{
            'reporting.widget_editor.data_form.filter_selection.operator_placeholder'
              | translate
          }}"
        >
          @if (selectedFilter) {
            <mat-option [value]="'in'">
              {{ "operators.equals_to" | translate }}
            </mat-option>
          }
          @if (!selectedFilter) {
            <mat-option [value]="'eq'">
              {{ "operators.equals_to" | translate }}
            </mat-option>
          }
          @if (!selectedFilter) {
            <mat-option [value]="'ne'">
              {{ "operators.not_equals_to" | translate }}
            </mat-option>
          }
          @if (selectedFilter) {
            <mat-option [value]="'nin'">
              {{ "operators.not_equals_to" | translate }}
            </mat-option>
          }
          @if (showContains) {
            <mat-option [value]="'like'">
              {{ "operators.contains" | translate }}
            </mat-option>
          }
          @if (showContains) {
            <mat-option [value]="'notLike'">
              {{ "operators.not_contains" | translate }}
            </mat-option>
          }
          @if (showConditions) {
            <mat-option [value]="'gt'">
              {{ "operators.greater_than" | translate }}
            </mat-option>
          }
          @if (showConditions) {
            <mat-option [value]="'gte'">
              {{ "operators.greater_than_equals" | translate }}
            </mat-option>
          }
          @if (showConditions) {
            <mat-option [value]="'lt'">
              {{ "operators.less_than" | translate }}
            </mat-option>
          }
          @if (showConditions) {
            <mat-option [value]="'lte'">
              {{ "operators.less_than_equals" | translate }}
            </mat-option>
          }
        </mat-select>
      </div>

      @if (newFilterForm.get("filterOperator")?.value) {
        <div class="col-12 mb-3">
          <label for="filterValue">
            {{
              "reporting.widget_editor.data_form.filter_selection.value_label"
                | translate
            }}&nbsp;
            <span class="required-mark">*</span>
          </label>
          @if (
            selectedFilter &&
            (selectedFilter?.possibleValues?.length! > 0 ||
              selectedFilter.hasPossibleValues) &&
            ["in", "eq", "ne", "nin"].includes(
              newFilterForm.get("filterOperator")?.value
            )
          ) {
            <div class="wsm-input-group-prepend w-100">
              <mat-select
                id="select-filter-value"
                [panelClass]="'wsm-custom-select'"
                class="wsm-input"
                formControlName="filterValue"
                [multiple]="true"
                placeholder="{{
                  'reporting.widget_editor.data_form.filter_selection.value_placeholder'
                    | translate
                }}"
                [disableOptionCentering]="true"
                [ngClass]="{'custom-select-loading ': gettingPossibleValues}"
              >
                <mat-select-trigger>
                  {{
                    newFilterForm?.controls?.filterValue?.value!
                      ? (newFilterForm?.controls?.filterValue?.value)![
                          newFilterForm?.controls?.filterValue?.value?.length -
                            1
                        ]
                      : ""
                  }}
                  @if (
                    newFilterForm?.controls?.filterValue?.value! &&
                    newFilterForm?.controls?.filterValue?.value?.length! > 1
                  ) {
                    <span class="additional-selection">
                      (+{{
                        newFilterForm?.controls?.filterValue?.value?.length! - 1
                      }}
                      more)
                    </span>
                  }
                </mat-select-trigger>
                @for (
                  possibleValue of selectedFilter?.possibleValues;
                  track possibleValue
                ) {
                  <mat-option [value]="possibleValue">
                    {{ possibleValue }}
                  </mat-option>
                }
              </mat-select>
              <i
                class="fa-solid fa-spinner fa-spin wsm-text-white"
                aria-hidden="true"
                [ngClass]="gettingPossibleValues ? 'd-block' : 'd-none'"
              >
              </i>
            </div>
          } @else {
            <input
              autocomplete="off"
              class="wsm-input w-100"
              formControlName="filterValue"
              id="filterValue"
              name="title"
              placeholder="{{
                'reporting.widget_editor.data_form.filter_selection.value_placeholder_input'
                  | translate
              }}"
              required
              type="text"
            />
          }
        </div>
      }

      @if (allowPropagation) {
        <div class="col-12 mb-3">
          <label>{{
            "reporting.widget_editor.data_form.filter_selection.filter_level_label"
              | translate
          }}</label>
          <mat-button-toggle-group
            appearance="legacy"
            aria-label="Filter Level"
            formControlName="filterLevel"
          >
            <mat-button-toggle [value]="false">
              {{
                "reporting.widget_editor.data_form.filter_selection.aggregated_result_label"
                  | translate
              }}
            </mat-button-toggle>
            <mat-button-toggle [value]="true">
              {{
                "reporting.widget_editor.data_form.filter_selection.individual_result_label"
                  | translate
              }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      }

      @if (!isMobile) {
        <div class="col-12 d-flex justify-content-end">
          <button
            [disabled]="newFilterForm.invalid"
            class="wsm-btn wsm-btn-primary wsm-btn-small"
            form="newFilterForm"
            type="submit"
          >
            {{ "reporting.dynamic_date_range.add_button" | translate }}
          </button>
        </div>
      }
    </div>
  </form>
</section>
