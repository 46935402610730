import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatStepper, MatStepperIcon, MatStep } from '@angular/material/stepper';
import {Router} from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
  NotificationService,
  ReportService,
  TrackingService,
} from 'src/app/core/services';
import {PlanType} from 'src/app/shared/enums';
import {HelperService} from 'src/app/shared/helpers';
import {
  CreateReportFromTemplatePayload,
  CreateReportPayload,
  ReportTemplate,
} from 'src/app/shared/models';
import { StartAs, StartAsComponent } from './start-as/start-as.component';
import { ToCustomReportProperties, ToCustomReportComponent } from './to-custom-report/to-custom-report.component';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { ToSelectTemplateComponent } from './to-select-template/to-select-template.component';
import { CreateReportFromComponent } from '../create-report-from/create-report-from.component';
import { CompanySelectionComponent } from '../../../../shared/components/company-selection/company-selection.component';

declare global {
  interface Window {
    amplitude: any;
  }
}

const amplitude = window.amplitude;

@Component({
    selector: 'app-new-report-wizard',
    templateUrl: './new-report-wizard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./new-report-wizard.component.scss'],
    standalone: true,
    imports: [
        MatDialogContent,
        MatStepper,
        MatStepperIcon,
        MatStep,
        StartAsComponent,
        NgTemplateOutlet,
        ToSelectTemplateComponent,
        ToCustomReportComponent,
        CreateReportFromComponent,
        CompanySelectionComponent,
        MatDialogActions,
        NgClass,
        TranslateModule,
    ],
})
// [x]: Signals implemented
export class NewReportWizardComponent implements OnInit {
  public canGoToNextStep = signal<boolean>(false);
  public firstStepCompleted = signal<boolean>(true);
  public secondStepCompleted = signal<boolean>(false);
  public thirdStepCompleted = signal<boolean>(false);

  public userPlan = signal<PlanType>(this.helperService.getUserPlan());
  public isSavingData = signal<boolean>(false);

  //template or custom
  public startAs = signal<StartAs>('template');
  public toCustomReportProperties = signal<ToCustomReportProperties | null>(
    null
  );
  public dataTypeSelected = signal<'criteria' | 'specific' | 'all'>('specific');
  public reportName = signal<string>('');
  public conditionsSelected = signal<Array<any>>([]);

  //second step - template
  public templateSelected = signal<ReportTemplate | null>(null);

  public reportId = signal<string>('');

  constructor(
    private dialogRef: MatDialogRef<NewReportWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private router: Router,
    private helperService: HelperService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.onChangeStart();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  moveToNextStep(stepper: MatStepper): void {
    stepper.next();

    switch (this.startAs()) {
      case 'template':
        this.secondStepCompleted.set(!!this.templateSelected());
        break;
      case 'custom':
        this.secondStepCompleted.set(
          !!this.toCustomReportProperties() && this.areValidCustomProperties()
        );
        break;
      default:
        break;
    }
  }

  areValidCustomProperties(): boolean {
    for (const key in this.toCustomReportProperties()) {
      if (this.toCustomReportProperties() !== null) {
        if (
          !(this.toCustomReportProperties() as ToCustomReportProperties)[key]
        ) {
          return false;
        }
      }
    }

    return true;
  }

  moveToPreviousStep(stepper: MatStepper): void {
    stepper.previous();
  }

  onChangeStart(event?: StartAs): void {
    this.conditionsSelected.set(
      this.data && !this.data?.clientId
        ? []
        : [{field: 'id', operator: 'in', value: [this.data.clientId]}]
    );
  }

  onTemplateSelected(event: boolean): void {
    this.secondStepCompleted.set(event);
  }

  // for template
  getNameAndConditions(event: {
    reportName: string;
    conditions: Array<any>;
    dataType: 'criteria' | 'specific' | 'all';
  }): void {
    this.conditionsSelected.set(event.conditions);
    this.dataTypeSelected.set(event.dataType);
    this.reportName.set(event.reportName);

    if (this.userPlan() !== PlanType.Individual) {
      if (this.dataTypeSelected() !== 'all') {
        this.thirdStepCompleted.set(
          this.conditionsSelected().length > 0 &&
            !!this.reportName() &&
            this.reportName().length > 0
        );
      }
      if (this.dataTypeSelected() === 'all') {
        this.thirdStepCompleted.set(
          !!this.reportName() && this.reportName().length > 0
        );
      }
    }
    if (this.userPlan() === PlanType.Individual) {
      this.thirdStepCompleted.set(
        !!this.reportName() && this.reportName().trim().length > 0
      );
    }
  }

  // for custom
  onChangeToCustomReportProperties(event: boolean): void {
    this.secondStepCompleted.set(
      !!this.toCustomReportProperties() && this.areValidCustomProperties()
    );
  }

  getCustomReportConditions(event): void {
    if (this.data && !this.data.clientId) {
      this.conditionsSelected.set(event.conditions);
      this.dataTypeSelected.set(event.dataType);
      if (this.userPlan() !== PlanType.Individual) {
        if (this.dataTypeSelected() !== 'all') {
          this.thirdStepCompleted.set(this.conditionsSelected().length > 0);
        }
        if (this.dataTypeSelected() === 'all') {
          this.thirdStepCompleted.set(true);
        }
      }
      if (this.userPlan() === PlanType.Individual) {
        this.thirdStepCompleted.set(true);
      }
    }
  }

  onSubmitReport(): void {
    switch (this.startAs()) {
      case 'template':
        this.createReportFromTemplate();
        break;
      case 'custom':
        this.createNewReport();
        break;
    }
  }

  createReportFromTemplate(): void {
    this.isSavingData.set(true);
    const payload = {
      name: this.reportName(),
      companiesMatching:
        this.userPlan() === PlanType.Individual
          ? []
          : this.conditionsSelected(),
      favorite: false,
    } as CreateReportFromTemplatePayload;

    this.reportService
      .createReportFromTemplate(payload, this.templateSelected()?.id as string)
      .subscribe({
        next: (res) => {
          this.trackingService.trackEvent('Start Report From Template');
          this.notificationService.success(
            this.translate.instant(
              'reporting.create_report.notification.report_created'
            ),
            300
          );
          this.isSavingData.set(false);
          this.dialogRef.close(true);
          this.router.navigate([`/reports/${res.id}/edit`]);
        },
        error: (err) => {
          this.isSavingData.set(false);
          this.notificationService.error(err.error.error_description, 5000);
        },
      });
  }

  createNewReport(): void {
    this.isSavingData.set(true);
    const rpt = {
      name: this.toCustomReportProperties()
        ? this.toCustomReportProperties()?.name
        : '',
      orientation: this.toCustomReportProperties()?.orientation ?? 'horizontal',
      reportType: 'dashboard',
      companyDataType: this.dataTypeSelected(),
      companiesMatching:
        this.userPlan() === PlanType.Individual
          ? []
          : this.conditionsSelected(),
      theme: this.toCustomReportProperties()?.theme,
      favorite: false,
    } as CreateReportPayload;

    this.reportService.createReport(rpt).subscribe({
      next: (res) => {
        this.reportId.set(res.id);
      },
      error: (err) => {
        this.isSavingData.set(false);
        this.notificationService.error(err?.message, 5000);
      },
      complete: () => {
        this.trackingService.trackEvent('Start Custom Report');
        this.isSavingData.set(false);
        this.dialogRef.close();
        this.notificationService.success(
          this.translate.instant(
            'reporting.create_report.notification.report_created'
          ),
          3000
        );
        this.router.navigate([`/reports/${this.reportId()}/edit`]).then();
      },
    });
  }
}
