import { Command, Plugin, Widget, viewToModelPositionOutsideModelElement, toWidgetEditable, ButtonView } from 'ckeditor5';
var calendarPlusIcon = "<svg viewBox=\"-32 0 512 512\" style=\"height: 18px; width: 18px;\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M336 292v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76h-76c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12zm112-180v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z\"/></svg>";
class WSMRangeDateButtonCommand extends Command {
  execute({
    value
  }) {
    const editor = this.editor;
    editor.model.change(modelWriter => {
      // Create <wsm-date-range-button> element with name attribute...
      const wsmDateRangeButton = modelWriter.createElement('wsm-date-range-button', {
        name: value
      });
      // ... and insert it into the document.
      editor.model.insertContent(wsmDateRangeButton);
    });
  }
  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const isAllowed = selection.focus ? model.schema.checkChild(selection.focus.parent, 'wsm-date-range-button') : false;
    this.isEnabled = isAllowed;
  }
}
const WSMDATERANGEBUTTON = 'wsm-date-range-button';
class WsmDateRangeButtonEditing extends Plugin {
  static get requires() {
    return [Widget];
  }
  init() {
    this.defineSchema();
    this.defineConverters();
    this.editor.commands.add(WSMDATERANGEBUTTON, new WSMRangeDateButtonCommand(this.editor));
    this.editor.editing.mapper.on('viewToModelPosition', viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('wsmbutton-text')));
    this.editor.config.define('wsmDateRangeButtonProps', {
      types: ['name', 'date']
    });
    this.editor.config.define('wsmDateRangeButtonBrackets', {
      open: '{{',
      close: '}}'
    });
  }
  defineSchema() {
    const schema = this.editor.model.schema;
    schema.register(WSMDATERANGEBUTTON, {
      // Allow wherever text is allowed:
      allowWhere: '$text',
      // It will act as an inline node:
      isInline: true,
      // The inline widget is self-contained, so it cannot be split by the caret, and it can be selected:
      isObject: true,
      // The inline widget can have the same attributes as text (for example linkHref, bold):
      allowAttributesOf: '$text',
      // The placeholder can have many types, like date, name, surname, etc:
      allowAttributes: ['name']
    });
  }
  defineConverters() {
    const conversion = this.editor.conversion;
    const config = this.editor.config;
    conversion.for('editingDowncast').elementToElement({
      model: WSMDATERANGEBUTTON,
      view: (modelItem, writer) => {
        const viewWriter = writer.writer || writer;
        const widgetElement = createWsmRangeDateButtonView(modelItem, viewWriter);
        return toWidgetEditable(widgetElement, viewWriter);
      }
    });
    conversion.for('dataDowncast').elementToElement({
      model: WSMDATERANGEBUTTON,
      view: (modelItem, writer) => {
        const viewWriter = writer.writer || writer;
        return createWsmRangeDateButtonView(modelItem, viewWriter);
      }
    });
    function createWsmRangeDateButtonView(modelItem, viewWriter) {
      const name = modelItem.getAttribute('name');
      const wsmRangeDateButtonView = viewWriter.createContainerElement('span', {
        class: 'wsmbutton-text'
      }, {
        isAllowedInsideAttributeElement: true
      });
      const innerText = viewWriter.createText(config.get('wsmDateRangeButtonBrackets.open') + name + config.get('wsmDateRangeButtonBrackets.close'));
      viewWriter.insert(viewWriter.createPositionAt(wsmRangeDateButtonView, 0), innerText);
      return wsmRangeDateButtonView;
    }
  }
}
class WsmDateRangeButtonUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    editor.ui.componentFactory.add('wsm-date-range-button', locale => {
      const btn = new ButtonView(locale);
      btn.set({
        label: t('Date range'),
        icon: calendarPlusIcon,
        tooltip: 'Add date'
      });
      this.listenTo(btn, 'execute', () => {
        window.addEventListener('onClickWsmDateRangeBtnData', event => {
          if (event.detail && event.detail.value) {
            const data = event.detail.value || '';
            editor.execute('wsm-date-range-button', {
              value: data
            });
          }
        }, {
          once: true
        });
        // eslint-disable-next-line max-len
        window.dispatchEvent(new CustomEvent('onClickWsmDateRangeButton')); // Change the event name to match the one in the listener
      });
      return btn;
    });
  }
}
class WsmDateRangeButton extends Plugin {
  static get requires() {
    return [WsmDateRangeButtonEditing, WsmDateRangeButtonUI];
  }
  static get pluginName() {
    return 'wsm-date-range-button';
  }
  init() {}
}
class WSMButtonCommand extends Command {
  execute({
    value
  }) {
    const editor = this.editor;
    editor.model.change(modelWriter => {
      // Create <wsmbutton> elment with name attribute...
      const wsmbutton = modelWriter.createElement('wsmbutton', {
        name: value
      });
      // ... and insert it into the document.
      editor.model.insertContent(wsmbutton);
      // Put the selection on inserted element.
      // modelWriter.setSelection(wsmbutton, 'on');
    });
  }
  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const isAllowed = selection.focus ? model.schema.checkChild(selection.focus.parent, 'wsmbutton') : false;
    this.isEnabled = isAllowed;
  }
}
const WSMBTN = 'wsmbutton';
class WSMButtonEditing extends Plugin {
  static get requires() {
    return [Widget];
  }
  init() {
    this.defineSchema();
    this.defineConverters();
    this.editor.commands.add(WSMBTN, new WSMButtonCommand(this.editor));
    this.editor.editing.mapper.on('viewToModelPosition', viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('wsmbutton-text')));
    this.editor.config.define('wsmButtonProps', {
      types: ['name', 'date']
    });
    this.editor.config.define('wsmButtonBrackets', {
      open: '{{',
      close: '}}'
    });
  }
  defineSchema() {
    const schema = this.editor.model.schema;
    schema.register(WSMBTN, {
      // Allow wherever text is allowed:
      allowWhere: '$text',
      // It will act as an inline node:
      isInline: true,
      // The inline widget is self-contained, so it cannot be split by the caret, and it can be selected:
      isObject: true,
      // The inline widget can have the same attributes as text (for example linkHref, bold):
      allowAttributesOf: '$text',
      // The placeholder can have many types, like date, name, surname, etc:
      allowAttributes: ['name']
    });
  }
  defineConverters() {
    const conversion = this.editor.conversion;
    const config = this.editor.config;
    // conversion.for('upcast').elementToElement({
    //     view: {
    //         name: 'span',
    //         classes: ['wsmbutton-text'],
    //     },
    //     model: (viewElement, writer) => {
    //         const name = viewElement
    //             .getChild(0)
    //             .data.slice(
    //                 config.get('wsmButtonBrackets.open').length,
    //                 0 - config.get('wsmButtonBrackets.close').length
    //             );
    //
    //         const modelWriter = writer.writer || writer;
    //         return modelWriter.createElement(WSMBTN, {name});
    //     },
    // });
    conversion.for('editingDowncast').elementToElement({
      model: WSMBTN,
      view: (modelItem, writer) => {
        const viewWriter = writer.writer || writer;
        const widgetElement = createWSMButtonView(modelItem, viewWriter);
        return toWidgetEditable(widgetElement, viewWriter);
      }
    });
    conversion.for('dataDowncast').elementToElement({
      model: WSMBTN,
      view: (modelItem, writer) => {
        const viewWriter = writer.writer || writer;
        return createWSMButtonView(modelItem, viewWriter);
      }
    });
    // Helper method for both downcast converters.
    function createWSMButtonView(modelItem, viewWriter) {
      const name = modelItem.getAttribute('name');
      const wsmButtonView = viewWriter.createContainerElement('span', {
        class: 'wsmbutton-text'
      }, {
        isAllowedInsideAttributeElement: true
      });
      const innerText = viewWriter.createText(config.get('wsmButtonBrackets.open') + name + config.get('wsmButtonBrackets.close'));
      viewWriter.insert(viewWriter.createPositionAt(wsmButtonView, 0), innerText);
      return wsmButtonView;
    }
  }
}
class WsmButtonUI extends Plugin {
  init() {
    const editor = this.editor;
    const t = editor.t;
    editor.ui.componentFactory.add('wsmbutton', locale => {
      const btn = new ButtonView(locale);
      btn.set({
        label: t(' Insert Metric '),
        withText: true,
        tooltip: false
      });
      this.listenTo(btn, 'execute', () => {
        window.addEventListener('onClickWsmBtnData', event => {
          if (event.detail && event.detail.value) {
            const data = event.detail.value || '';
            editor.execute('wsmbutton', {
              value: data
            });
          }
        }, {
          once: true
        });
        window.dispatchEvent(new CustomEvent('onClickWsmBtn'));
      });
      return btn;
    });
  }
}
class WSMButton extends Plugin {
  // Array of plugins required by this plugin
  static get requires() {
    return [WSMButtonEditing, WsmButtonUI];
  }
  // Makes the plugin available in the PluginCollection.get('name')
  static get pluginName() {
    return 'WSMButton';
  }
  init() {}
}
const icons = {
  calendarPlusIcon
};
export { WSMButton, WsmDateRangeButton, icons };
