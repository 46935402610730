<section
  class="w-100 h-100"
  id="{{ 'widget-' + widget.id }}"
  [cdkContextMenuTriggerFor]="widgetMenu"
  [cdkContextMenuDisabled]="
    !isEditMode || isFromEditor || isEditTitle || widget?.isEmpty
  "
  (cdkContextMenuClosed)="onCloseContextMenu()"
  (mouseenter)="onWidgetActions('show')"
  (mouseleave)="onWidgetActions('close')"
  [ngClass]="{'user-flow-widget-select': isEditMode}"
>
  @if (
    isEditMode &&
    !isFromEditor &&
    widget?.filters?.length! > 0 &&
    !showActions()
  ) {
    <div
      class="widget-filters"
      matTooltip="{{ 'reporting.widgets.custom_filter' | translate }}"
      matTooltipPosition="above"
      matTooltipClass="wsm-tooltip tooltip-above"
    >
      <i class="fa-solid fa-filters"></i>
    </div>
  }

  @if (showActions() && isEditMode && !widget?.isEmpty && !isEditTitle) {
    <article
      class="action-container"
      [ngClass]="{
        'custom-edit-button':
          widget?.widgetType === 'branding' && widget?.position?.height! < 2,
      }"
    >
      <button
        (click)="onOpenCustomContextMenu($event)"
        (contextmenu)="onShowContextMenu($event)"
        class="mb-2 wsm-btn wsm-btn-outline-icon wsm-btn-small-icon action-btn"
      >
        <i class="fa-solid fa-pen icon"></i>
      </button>
    </article>
  }

  <mat-card
    #widgetCardElement
    class="widget-card"
    [ngClass]="{
      'no-border': theme.hideBorders,
      'no-padding': widget.widgetType === 'image',
      'no-margin': widget.widgetType === 'image',
      'widget-with-transparency': theme.transparency,
      'presentation-mode': isPresentationMode,
    }"
    [ngStyle]="{
      'background-color': theme.transparency
        ? 'transparent !important'
        : theme.backgroundColor || theme.widgetBackgroundColor,
      'border': '1px solid ' + theme.borderColor,
      'font-size': isPresentationMode ? presentationFontSize : theme.fontSize,
      'border-radius':
        widget?.shapeType === 'round'
          ? widget?.cornerType === 'all'
            ? theme?.allCorners + 'px'
            : theme?.topLeftCorner +
              'px ' +
              theme?.topRightCorner +
              'px ' +
              theme?.bottomRightCorner +
              'px ' +
              theme?.bottomLeftCorner +
              'px '
          : '4px',
    }"
  >
    @if (
      widget.widgetType !== "title" &&
      widget.widgetType !== "branding" &&
      widget.widgetType !== "image" &&
      widget.widgetType !== "scorecard" &&
      ((widget.widgetIcon && widget.widgetIcon?.faClass) || widget.title !== "")
    ) {
      <mat-card-header
        [ngStyle]="{
          'height':
            widget.title &&
            widget.widgetType === 'chart' &&
            widget.chartType === 'doughnut2d'
              ? '20%'
              : 'auto',
        }"
      >
        <mat-card-title
          class="d-flex"
          [ngStyle]="{'color': theme.titleColor}"
          [ngClass]="{
            'bold-text': this.theme?.labelBold === true,
            'italic-text': this.theme?.labelItalic === true,
            'justify-content-start': this.theme?.labelAlign === 'left',
            'justify-content-center': this.theme?.labelAlign === 'center',
            'justify-content-end': this.theme?.labelAlign === 'right',
            'justify-content-between': widget.widgetType === 'ranking',
          }"
        >
          <div
            [ngClass]="{
              'flex-row-reverse': widget.widgetIcon?.position === 'right',
            }"
            class="d-flex gap-2 align-items-center"
          >
            @if (widget?.widgetIcon && widget.widgetIcon?.faClass) {
              <i [class]="widget.widgetIcon?.faClass"></i>
            }
            {{
              widget?.isEditStyle || !widget.dynamicTitle
                ? widget?.title
                : widget.dynamicTitle
            }}
          </div>
          <!-- Ranking widget sort control (can be used with other widgets if deemed useful) -->
          @if (
            widget.metrics &&
            widget.metrics?.length !== 0 &&
            widget.widgetType === "ranking" &&
            widget["rankingData"]?.keywords?.length! !== 0
          ) {
            <div class="d-flex">
              <i
                class="fa-regular sort-icon me-2"
                [ngClass]="{
                  'fa-arrow-up': widget.sortDirection === 'asc',
                  'fa-arrow-down': widget.sortDirection === 'desc',
                }"
                [ngStyle]="{'color': theme.bodyColor}"
                (click)="
                  onWidgetSortChanged({widget: widget, type: 'sortChange'})
                "
              ></i>
              <span
                class="sort-metric"
                [ngClass]="{'pe-3': isEditMode && !isFromEditor}"
                [ngStyle]="{'color': theme.bodyColor}"
              >
                {{ "reporting.widgets.sorted_by" | translate }}
                {{ (widget?.metrics)![0]?.name }}
              </span>
            </div>
          }
        </mat-card-title>
      </mat-card-header>
    }

    <mat-card-content
      class="widget-content"
      [ngClass]="{
        'no-border': theme.hideBorders,
        'no-padding':
          widget.widgetType === 'image' ||
          (widget.widgetType === 'table' && isMobile),
        'no-margin': widget.widgetType === 'image',
        'mb-3': widget.widgetType === 'geoHeatMap',
        'mobile-content': isMobile,
        'geo-heat-map': widget.widgetType === 'geoHeatMap',
        'overflow-hidden':
          widget.widgetType === 'table' || widget.widgetType === 'geoHeatMap',
        'pt-3': widget.widgetType === 'table' && widget.title === '',
      }"
      [ngStyle]="{
        'overflow':
          widget.widgetType === 'title' || widget.widgetType === 'text'
            ? 'unset'
            : '',
      }"
    >
      @if (
        widget?.hasError &&
        (widget?.errorMessage || widget?.errorLabel) &&
        widget.widgetType !== "branding"
      ) {
        <app-widget-error
          [widget]="widget"
          [theme]="theme"
          [isMobile]="isMobile"
        >
        </app-widget-error>
      } @else {
        @if (widget.widgetType === "ranking") {
          <app-keyword
            [widget]="widget"
            [theme]="theme"
            [reportTheme]="reportTheme"
          ></app-keyword>
        }
        @if (widget.widgetType === "title") {
          <app-heading
            [widget]="widget"
            [theme]="theme"
            [isEditMode]="isEditMode"
            [mobileStatus]="mobileStatus"
          ></app-heading>
        }
        @if (widget.widgetType === "scorecard") {
          <app-scorecard [widget]="widget" [theme]="theme"></app-scorecard>
        }
        @if (
          widget.widgetType === "text" || widget.widgetType === "dynamictext"
        ) {
          <app-rich-text
            [widgetStyleForm]="widgetStyleForm"
            [widget]="widget"
            [theme]="theme"
            [page]="page"
            [isFromEditor]="isFromEditor"
          ></app-rich-text>
        }
        @if (widget.widgetType === "image") {
          <app-image
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
          ></app-image>
        }
        @if (widget.widgetType === "branding") {
          <app-branding
            [page]="page"
            [pages]="pages"
            [theme]="theme"
            [widgetStyleForm]="widgetStyleForm"
            [widget]="widget"
            [isMobile]="isMobile"
          >
          </app-branding>
        }
        @if (widget.widgetType === "gallery") {
          <app-ad-gallery
            [widget]="widget"
            [widgetForm]="widgetStyleForm"
            [theme]="theme"
          ></app-ad-gallery>
        }
        @if (widget.widgetType === "chart") {
          <app-chart
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
          ></app-chart>
        }
        @if (widget.widgetType === "table") {
          <app-table
            [widget]="widget"
            [theme]="theme"
            [widgetStyleForm]="widgetStyleForm"
            [page]="page"
            [pageTheme]="pageTheme"
            [reportId]="reportId"
            [reportTheme]="reportTheme"
            [isPrint]="isPrint"
            [isEditMode]="isEditMode"
            [isMobile]="isMobile"
            [largeMobile]="largeMobile"
            (tableSortChanged)="onWidgetSortChanged($event)"
            (recordLimitReached)="changePageOnLimitReached()"
          >
          </app-table>
        }
        @if (widget.widgetType === "biggestchanges") {
          <app-biggest-changes
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
          ></app-biggest-changes>
        }
        @if (widget.widgetType === "gauge") {
          <app-goal-tracker
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
            [theme]="theme"
          ></app-goal-tracker>
        }
        @if (widget.widgetType === "current-goal") {
          <app-goal-tracker
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
            [theme]="theme"
          ></app-goal-tracker>
        }
        @if (widget.widgetType === "timeline") {
          <app-goal-tracker
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
            [theme]="theme"
          ></app-goal-tracker>
        }
        @if (widget.widgetType === "geoHeatMap") {
          <app-geo-heat-map
            [isPrint]="isPrint"
            [widget]="widget"
            [widgetStyleForm]="widgetStyleForm"
            [isExternalUser]="isExternalUser"
            [isMobile]="isMobile"
          />
        }
        <app-loading
          [isTable]="true"
          [generalLoading]="isLoading"
        ></app-loading>
      }
    </mat-card-content>

    @if (
      (((widget.widgetType === "table" || widget.widgetType === "ranking") &&
        !isMobile) ||
        (isMobile && largeMobile && widget.widgetType === "table")) &&
      !(widget?.hasError && (widget?.errorMessage || widget?.errorLabel))
    ) {
      <mat-card-footer class="m-0">
        <mat-paginator
          [hidden]="
            isPrint ||
            widget.hidePaginator ||
            (widget.widgetType === 'table' &&
              widget.tableData?.rows &&
              widget.tableData?.rows?.length === 0)
          "
          [length]="widget?.totalRecords!"
          [ngStyle]="{'color': theme.bodyColor}"
          [style.--theme-body-color]="theme.bodyColor"
          [pageSizeOptions]="[5, 10, 50, 100]"
          [pageSize]="widget?.recordsPerPage!"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </mat-card-footer>
    }
  </mat-card>
</section>

<ng-template #widgetMenu>
  <app-widget-action
    [widget]="widget"
    [isEditMode]="isEditMode"
    (onHoverActions)="onWidgetActions('show')"
  />
</ng-template>
