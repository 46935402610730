<div class="overlay-container h-100">
  @if (!isFromWizard()) {
    <header>
      <div class="d-flex justify-content-between">
        @if (from === "report") {
          <h5>
            {{ "reporting.clone_report.title" | translate }}
          </h5>
        }
        @if (from === "template") {
          <h5>
            {{ "reporting.clone_report.noTitle" | translate }}
          </h5>
        }
        <button
          (click)="closeDialog()"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      </div>
    </header>
  }
  <div class="row mb-3">
    <form [formGroup]="cloneReportForm">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="">
          <label
            >{{
              "reporting.clone_report.form.report_name" | translate
            }}&nbsp;<span class="required-mark">*</span></label
          >
          <input
            formControlName="name"
            type="text"
            class="wsm-input invalid w-100"
            [ngClass]="{
              'border-danger':
                cloneReportForm.get('name')?.errors?.['required'] &&
                cloneReportForm.get('name')?.dirty
            }"
            data-cy-tableContent="table-content"
            placeholder="{{
              'reporting.clone_report.form.report_name_placeholder' | translate
            }}"
          />
          @if (
            cloneReportForm.get("name")?.errors?.["required"] &&
            cloneReportForm.get("name")?.dirty
          ) {
            <div class="mt-1">
              <span class="invalid-info">{{
                "common.errors.field_required" | translate
              }}</span>
            </div>
          }
        </div>
      </div>
    </form>
  </div>
  <div class="row mb-5">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <label
        >{{ "reporting.clone_report.form.client" | translate }}&nbsp;<span
          class="required-mark"
          >*</span
        ></label
      >
      @if (conditionsInvalid && conditionsIsTouched) {
        <div class="mt-1">
          <span class="invalid-info">{{
            "reporting.clone_report.form.client_required" | translate
          }}</span>
        </div>
      }
      <app-company-selection
        [companyDataType]="dataType"
        [conditionsFromWizard]="conditionFromWizard"
        [showTitle]="false"
        [report]="report || null"
        (specificLoadingState)="setLoading($event)"
        (sendConditions)="getConditions($event)"
      ></app-company-selection>
    </div>
  </div>
  @if (!isFromWizard()) {
    <mat-dialog-actions class="d-flex justify-content-end">
      <button
        id="startReportBtn"
        class="wsm-btn wsm-btn-primary"
        [disabled]="isSavingData()"
        (click)="handleSubmit()"
        data-cy-btn="startReportBtntst"
      >
        @if (!isSavingData()) {
          <span>
            {{ "reporting.clone_report.form.create_report" | translate }}
          </span>
        }
        @if (isSavingData()) {
          <div
            class="spinner-border spinner-border-sm text-light"
            data-cy-isSavingData="is-saving-data"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    </mat-dialog-actions>
  }
  <app-loading [generalLoading]="generalLoading()"></app-loading>
</div>
