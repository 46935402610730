<div class="row">
  <div
    [ngClass]="{'flex-column gap-1': isMobile}"
    class="d-flex justify-content-center align-items-center"
  >
    <label [ngClass]="{'me-2': !isMobile}" class="mb-0">{{
      "settings.billing.plan_management.billing_cycle" | translate
    }}</label>
    <mat-button-toggle-group
      [formControl]="billingCycle"
      (selectionchange)="filterByBillingCycle()"
      appearance="legacy"
      aria-label="Shapes"
    >
      <mat-button-toggle class="ps-1 my-1" value="monthly">
        {{ "settings.billing.plan_management.monthly" | translate }}
      </mat-button-toggle>
      <mat-button-toggle class="pe-1 my-1" value="yearly">
        {{ "settings.billing.plan_management.yearly" | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
    @if (billingCycle?.value === "yearly") {
      <small class="wsm-tag success ms-2">
        {{ "settings.billing.plan_management.discount" | translate }}
      </small>
    }
  </div>
</div>

<div class="row py-4">
  <div class="col-lg-10 m-auto">
    <div
      [ngClass]="
        products && products.length <= 4
          ? 'grid-container grid-column-2'
          : 'grid-container'
      "
    >
      @for (product of filterByBillingCycle(); track product) {
        <div class="card p-2 wsm-bg-tertiary">
          <div class="card-body">
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between align-items-end mb-2">
                <label class="wsm-text-white mb-2 product-label">{{
                  product?.name
                }}</label>
                @if (
                  (product?.type === "PRO" ||
                    product?.type === "PREMIUM" ||
                    product?.type === "ENTERPRISE") &&
                  !product?.isSelected
                ) {
                  <small class="wsm-tag info popular-tag">
                    {{
                      "settings.billing.plan_management.popular_tag" | translate
                    }}
                  </small>
                }
                @if (
                  product?.isSelected && billingInfo?.status !== "CANCELED"
                ) {
                  <small class="wsm-tag info selected-tag">
                    {{
                      "settings.billing.plan_management.selected_tag"
                        | translate
                    }}
                  </small>
                }
              </div>
              <span class="wsm-text-grayed">{{ product?.description }}</span>
            </div>
          </div>
          <div
            class="price-content"
            [ngClass]="{
              'selected': product?.isSelected && billingInfo?.status === 'PAID'
            }"
          >
            <div
              [ngClass]="{'d-flex justify-content-center': isMobile}"
              class="d-flex align-items-baseline"
            >
              <h1 class="wsm-text-white fw-light">${{ product?.price }}</h1>
              <small class="wsm-text-grayed"
                >/{{
                  "settings.billing.plan_management.month_label" | translate
                }}</small
              >
            </div>
            @if (product?.subProducts) {
              <ul class="sub-product-list">
                @for (sub of product?.subProducts | slice: 0 : 2; track sub) {
                  <li class="mb-2">
                    <small>{{ sub?.name }} ${{ sub?.price }} </small>
                  </li>
                }
              </ul>
            }
          </div>
          @if (!product?.isSelected || billingInfo?.status !== "PAID") {
            <div
              [ngClass]="{'flex-column gap-2': isMobile}"
              class="card-footer d-flex justify-content-center align-items-center mb-3"
            >
              @if (!product?.isSelected && billingInfo?.status !== "CANCELED") {
                <a
                  [ngClass]="{'w-100': isMobile, 'me-3': !isMobile}"
                  href="https://adclicks.app/pricing"
                  target="_blank"
                  class="wsm-btn wsm-btn-outline text-decoration-none"
                >
                  {{
                    "settings.billing.plan_management.learn_more" | translate
                  }}
                </a>
              }
              @if (!product?.isSelected && billingInfo?.status !== "CANCELED") {
                <button
                  [ngClass]="{'w-100': isMobile}"
                  class="wsm-btn wsm-btn-primary"
                  (click)="changePlan(product)"
                  [disabled]="product?.isDisabled"
                >
                  @if (!product?.isLoading) {
                    <span>
                      {{
                        "settings.billing.plan_management.change_plan"
                          | translate
                      }}
                    </span>
                  }
                  @if (product?.isLoading) {
                    <span
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                      ><span class="visually-hidden">Loading...</span>
                    </span>
                  }
                </button>
              }
              @if (billingInfo?.status === "CANCELED") {
                <button
                  [ngClass]="{'w-100': isMobile, 'w-75': !isMobile}"
                  class="wsm-btn wsm-btn-primary"
                  [disabled]="product?.isDisabled"
                  (click)="selectPlan(product)"
                >
                  @if (!product?.isLoading) {
                    <span>
                      {{
                        "settings.billing.plan_management.select_plan"
                          | translate
                      }}
                    </span>
                  }
                  @if (product?.isLoading) {
                    <span
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                      ><span class="visually-hidden">Loading...</span>
                    </span>
                  }
                </button>
              }
              @if (
                product?.isSelected &&
                billingInfo?.status !== "PAID" &&
                billingInfo?.status !== "CANCELED"
              ) {
                <button
                  [ngClass]="{'w-100': isMobile, 'w-75': !isMobile}"
                  class="wsm-btn wsm-btn-primary"
                  [disabled]="product?.isDisabled"
                  (click)="goToPaymentLink()"
                >
                  @if (!product?.isLoading) {
                    <span>
                      {{
                        "settings.billing.plan_management.pay_subscription"
                          | translate
                      }}
                    </span>
                  }
                  @if (product?.isLoading) {
                    <span
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                      ><span class="visually-hidden">Loading...</span>
                    </span>
                  }
                </button>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
