import {Component, inject, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {Router} from '@angular/router';

import {BillingService, NotificationService} from '../../../core/services';
import {BillingPlan} from '../../models';
import {MatRadioGroup, MatRadioButton} from '@angular/material/radio';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {TranslateModule} from '@ngx-translate/core';
import {BillingPlanStore} from '../../state';

@Component({
  selector: 'app-billing-cancellation-dialog',
  templateUrl: './billing-cancellation-dialog.component.html',
  styleUrls: ['./billing-cancellation-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    MatRadioGroup,
    NgxIntlTelInputModule,
    MatRadioButton,
    MatSelect,
    MatOption,
    MatDialogActions,
    TranslateModule,
  ],
})
export class BillingCancellationDialogComponent implements OnInit {
  // Store
  private billingPlanStore = inject(BillingPlanStore);

  constructor(
    public dialogRef: MatDialogRef<BillingCancellationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private billingService: BillingService,
    private notificationService: NotificationService
  ) {}

  cancelForm: UntypedFormGroup;
  isLoading: boolean;

  ngOnInit(): void {
    this.cancelForm = new UntypedFormGroup({
      immediately: new UntypedFormControl(false, [Validators.required]),
      reason: new UntypedFormControl(''),
      notes: new UntypedFormControl(''),
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.sendCancelRequest();
  }

  sendCancelRequest(): void {
    this.isLoading = true;
    this.billingService.cancelPlan(this.cancelForm.value).subscribe({
      next: (res) => {
        const plan: BillingPlan = res?.data?.plan || {};
        this.billingPlanStore.updateBillingPlanMethod(plan);
        const org = JSON.parse(localStorage.getItem('organization')!);
        if (this.cancelForm.get('immediately')?.value) {
          org.status = 'INACTIVE';
          localStorage.setItem('organization', JSON.stringify(org));
          this.router.navigate(['/canceled']).then();
        }
      },
      error: (err) => {
        this.notificationService.error(err.message, 5000);
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.dialogRef.close(true);
      },
    });
  }
}
