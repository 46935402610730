<section class="d-flex justify-content-between gap-5">
  <div class="d-flex flex-column gap-3">
    <div
      [ngClass]="{'active': startAs() === 'template'}"
      class="start-as"
      (click)="onChangeStart('template')"
    >
      <p class="fs-6">
        <i class="fa-regular fa-folder wsm-text-primary"></i>&nbsp;{{
          "reporting.create_report.wizard_modal.start_as.template.title"
            | translate
        }}
      </p>
      <p>
        {{
          "reporting.create_report.wizard_modal.start_as.template.description"
            | translate
        }}
      </p>
    </div>
    <div
      [ngClass]="{'active': startAs() === 'custom'}"
      class="start-as"
      data-cy-start-as="start-as-custom"
      (click)="onChangeStart('custom')"
    >
      <p class="fs-6">
        <i class="fa-regular fa-sparkles wsm-text-primary"></i>&nbsp;{{
          "reporting.create_report.wizard_modal.start_as.custom.title"
            | translate
        }}
      </p>
      <p>
        {{
          "reporting.create_report.wizard_modal.start_as.custom.description"
            | translate
        }}
      </p>
    </div>
  </div>
  <div class="divide"></div>
  <div class="d-flex flex-column gap-2 justify-content-center w-50">
    <p class="fs-6">{{ prosTitle() | translate }}</p>
    <ul class="d-flex flex-column gap-2 ps-3">
      <li>
        <p>{{ prosFirstItem() | translate }}</p>
      </li>
      <li>
        <p>{{ prosSecondItem() | translate }}</p>
      </li>
    </ul>
  </div>
</section>
