import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-wsm-drag-drop-file',
    templateUrl: './wsm-drag-drop-file.component.html',
    styleUrls: ['./wsm-drag-drop-file.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class WsmDragDropFileComponent implements OnInit, OnChanges {
  @Input() multiple: boolean; // enable multiple files
  @Input() accept: string; // file type accept
  @Input() showProgress: boolean; // show file list
  @Input() size: 'small' | 'normal';
  @Input() parentFiles?: any[];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() setFilesUploaded = new EventEmitter<any>(); // send the file list loaded
  @ViewChild('fileDropRef', {static: false}) fileDropRef: ElementRef;
  files: any[] = [];
  hintString: string;
  disabledInput: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentFiles) {
      if (
        changes.parentFiles.currentValue.every(
          (el) => typeof el !== 'undefined' && el !== null
        )
      ) {
        this.files = [...(this.parentFiles as any[])];
      }
      this.handleDisabledInput();
    }
  }

  ngOnInit(): void {
    this.handleHintText();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event): void {
    this.validateFiles($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files): void {
    this.changeDetectorRef.markForCheck();
    this.validateFiles(files);
  }

  deleteFile(index: number): void {
    if (this.files.length > 0) {
      this.files.splice(index, 1);
      this.setFilesUploaded.emit(this.files);
      this.fileDropRef.nativeElement.value = '';
      this.handleDisabledInput();
    }
  }

  validateFiles(files: Array<any>): void {
    const isAccept = this.handleAccept(files);
    if (this.accept && this.accept.trim().length > 0) {
      if (isAccept) {
        this.prepareFilesList(files);
      }
    } else {
      this.prepareFilesList(files);
    }
  }

  prepareFilesList(files: Array<any>): void {
    if (this.multiple) {
      for (const item of files) {
        item.progress = 0;
        this.files.push(item);
      }
    }
    if (!this.multiple) {
      this.deleteFile(0);
      for (const item of files) {
        item.progress = 0;
        this.files.push(item);
      }
    }

    this.uploadFilesSimulator(0);
  }

  uploadFilesSimulator(index: number): void {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index]?.progress === 100) {
            this.setFilesUploaded.emit(this.files);
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
            this.handleDisabledInput();
          } else {
            this.files[index].progress += 25;
          }
        }, 200);
      }
    }, 1000);
  }

  handleAccept(files: Array<any>): boolean {
    const filesTemp: any[] = [];

    for (const item of files) {
      filesTemp.push(item);
    }

    const countAccept = filesTemp.reduce((acc, file) => {
      if (this.accept.split(',').includes(file.type)) {
        acc++;
      }
      return acc;
    }, 0) as number;

    return countAccept === filesTemp.length;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  handleHintText(): void {
    if (!this.multiple) {
      this.hintString = `Drag file here or, n\\ click to browse for a file`;
    }
    if (this.multiple) {
      this.hintString = 'Drag files here or, click to browse for files';
    }
  }

  handleDisabledInput(): void {
    this.disabledInput = !this.multiple && this.files.length > 0;
  }
}
