<div class="date-picker-menu-content">
  <p class="mb-2">
    <small class="d-flex wsm-text-grayed">
      {{ "common.fields_required" | translate }}
    </small>
  </p>
  <div (click)="$event.stopPropagation()" class="flex-grow-1">
    <div class="col border-right">
      <label for="rangesControl" class="mb-2">
        {{ "shared.components.date_picker.date_range_label" | translate }}
      </label>
      <mat-select
        class="wsm-input"
        id="rangesControl"
        placeholder="{{
          'shared.components.date_picker.date_range_description' | translate
        }}"
        [formControl]="rangesControl"
        (selectionChange)="onQuickDateChange($event)"
      >
        @for (date of quickDateRanges; track date) {
          <mat-option [value]="date">
            {{ date.translate | translate }}
          </mat-option>
        }
      </mat-select>

      @if (currentOption === "RELATIVE_RANGE") {
        <div class="d-flex mb-3">
          <div class="">
            <label for="relativeToToggle" class="mt-3 mb-2">
              {{ "shared.components.date_picker.range_label" | translate }}
            </label>
            <mat-radio-group
              id="relativeToToggle"
              [formControl]="relativeToToggle"
              class="me-3"
            >
              <mat-radio-button value="months" class="me-3">
                {{ "shared.components.date_picker.months_option" | translate }}
              </mat-radio-button>
              <mat-radio-button value="days">
                {{ "shared.components.date_picker.days_option" | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          @if (relativeToToggle.value === "months") {
            <div class="">
              <label for="monthlyRangeToggle" class="mt-3 mb-2">
                {{
                  "shared.components.date_picker.relative_to_label" | translate
                }}
              </label>
              <mat-radio-group
                id="monthlyRangeToggle"
                [formControl]="monthlyRangeToggle"
              >
                <mat-radio-button value="lastMonths" class="me-3">
                  {{ "shared.components.date_picker.last_option" | translate }}
                </mat-radio-button>
                <mat-radio-button value="monthsAgo">
                  {{ "shared.components.date_picker.ago_option" | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          }
        </div>
        <div class="relative-date-labels">
          @if (monthlyRangeToggle.value === "lastMonths") {
            <label for="monthlyRange">
              {{ "shared.components.date_picker.last_option" | translate }}
              <input
                id="monthlyRange"
                type="number"
                min="0"
                oninput="validity.valid||(value='')"
                [formControl]="monthlyRange"
                class="wsm-input numeric-input mx-1 text-center"
              />
              {{
                "shared.components.date_picker.months_description" | translate
              }}
            </label>
          }
          @if (monthlyRangeToggle.value === "monthsAgo") {
            <label for="monthsAgo">
              <input
                id="monthsAgo"
                type="number"
                min="0"
                oninput="validity.valid||(value='')"
                [formControl]="monthsAgo"
                class="wsm-input numeric-input text-center me-1"
              />
              {{
                "shared.components.date_picker.months_ago_description"
                  | translate
              }}
            </label>
          }
          @if (relativeToToggle.value === "days") {
            <label for="dayRange">
              {{ "shared.components.date_picker.last_option" | translate }}
              <input
                id="dayRange"
                type="number"
                min="0"
                oninput="validity.valid||(value='')"
                [formControl]="dayRange"
                class="wsm-input numeric-input mx-1 text-center"
              />
              {{ "shared.components.date_picker.days_description" | translate }}
            </label>
          }
        </div>
      }

      <div class="d-flex mt-3 position-relative">
        <label for="rangeOneStart"
          >{{
            "shared.components.date_picker.from_label" | translate
          }}
          &nbsp;<span class="required-mark">*</span></label
        >
        <label for="rangeOneEnd" class="toLabel"
          >{{
            "shared.components.date_picker.to_label" | translate
          }}
          &nbsp;<span class="required-mark">*</span></label
        >
      </div>
      <div class="d-flex align-items-center mb-3">
        <mat-date-range-input
          [formGroup]="dateRangeOne"
          [rangePicker]="rangeOnePicker"
          [comparisonStart]="dateRangeTwo.value.start"
          [comparisonEnd]="dateRangeTwo.value.end"
        >
          <input
            id="rangeOneStart"
            (focus)="rangeOnePicker.open()"
            matStartDate
            class="ps-1"
            placeholder="{{
              'shared.components.date_picker.start_date_placeholder' | translate
            }}"
            formControlName="start"
          />

          <input
            id="rangeOneEnd"
            (dateChange)="onDateRangeInputChange($event)"
            (focus)="rangeOnePicker.open()"
            matEndDate
            placeholder="{{
              'shared.components.date_picker.end_date_placeholder' | translate
            }}"
            formControlName="end"
          />
        </mat-date-range-input>
        <!-- <mat-datepicker-toggle matSuffix [for]="rangeOnePicker"></mat-datepicker-toggle> -->
        <mat-date-range-picker #rangeOnePicker></mat-date-range-picker>
      </div>

      <mat-slide-toggle
        labelPosition="before"
        (change)="changeCompareToggle($event)"
        class="custom-toggle w-100"
        [formControl]="compareToggle"
        [hidden]="disableCompare"
      >
        {{ "shared.components.date_picker.compare_label" | translate }}
      </mat-slide-toggle>
    </div>

    @if (compareToggle.value) {
      <div class="col mt-3">
        <label for="compareControl" class="mb-2">
          {{ "shared.components.date_picker.date_range_label" | translate }}
        </label>
        <mat-select
          class="wsm-input"
          id="compareControl"
          [formControl]="compareControl"
          placeholder="{{
            'shared.dialogs.plan_cancelation.feedback_options.select_option'
              | translate
          }}"
          (selectionChange)="onComparisonDateChange($event)"
        >
          @for (date of quickComparisonRanges; track date) {
            @if (
              date.relative === "PREVIOUS_MONTH" &&
              (currentOption === "THIS_MONTH" || currentOption === "LAST_MONTH")
            ) {
              <mat-option [value]="date">
                {{ date.translate | translate }}
              </mat-option>
            }
            @if (date.relative !== "PREVIOUS_MONTH") {
              <mat-option [value]="date">
                {{ date.translate | translate }}
              </mat-option>
            }
          }
        </mat-select>
        <!-- <ng-container *ngIf="compareToggle.value">
                      <mat-button-toggle-group
                        class="w-100" vertical
                        [formControl]="compareControl"
                        (change)="onComparisonDateChange($event)">
                        <mat-button-toggle *ngFor="let date of quickComparisonRanges"
                          [value]="date" class="font-weight-bold">
                          <span>{{ date.label }}</span>
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </ng-container> -->
        <div class="mt-3">
          <div class="d-flex mt-3 position-relative">
            <label for="rangeTwoStart">
              {{ "shared.components.date_picker.from_label" | translate }}
              &nbsp;<span class="required-mark">*</span>
            </label>
            <label for="rangeTwoEnd" class="toLabel">
              {{ "shared.components.date_picker.to_label" | translate }}
              &nbsp;<span class="required-mark">*</span>
            </label>
          </div>
          <div class="d-flex align-items-center">
            <mat-date-range-input
              [formGroup]="dateRangeTwo"
              [rangePicker]="rangeTwoPicker"
              [comparisonStart]="dateRangeOne.value.start"
              [comparisonEnd]="dateRangeOne.value.end"
            >
              <input
                id="rangeTwoStart"
                (focus)="rangeTwoPicker?.open()"
                matStartDate
                class="ps-1"
                placeholder="{{
                  'shared.components.date_picker.start_date_placeholder'
                    | translate
                }}"
                formControlName="start"
              />
              <input
                id="rangeTwoEnd"
                (dateChange)="onDateRangeInputChange($event)"
                (focus)="rangeTwoPicker?.open()"
                matEndDate
                placeholder="{{
                  'shared.components.date_picker.end_date_placeholder'
                    | translate
                }}"
                formControlName="end"
              />
            </mat-date-range-input>
            <!-- <mat-datepicker-toggle matSuffix [for]="reangeTwoPicker"></mat-datepicker-toggle> -->
            <mat-date-range-picker #rangeTwoPicker></mat-date-range-picker>
          </div>
        </div>
      </div>
    }
  </div>

  <div
    class="d-flex align-items-center"
    [ngClass]="{
      'justify-content-end': origin !== 'page',
      'justify-content-between': origin === 'page',
      'mt-2': !isMobile,
      'mt-4': isMobile,
    }"
    (click)="$event.stopPropagation()"
  >
    @if (origin === "page" && !isMobile) {
      <mat-checkbox [formControl]="applyToAllToggle" disableRipple="true">
        {{ "shared.components.date_picker.apply_all_pages_label" | translate }}
      </mat-checkbox>
    }

    <div
      class="action-buttons"
      [ngClass]="{'ms-5': !isMobile, 'flex-grow-1': isMobile}"
    >
      @if (showClear) {
        <button
          class="wsm-btn wsm-btn-outline me-2"
          type="button"
          (click)="clearSelection()"
        >
          {{ "shared.components.date_picker.clear_button" | translate }}
        </button>
      }
      <button
        [disabled]="
          !dateRangeOne.valid || (!dateRangeTwo.valid && !dateRangeTwo.disabled)
        "
        class="wsm-btn wsm-btn-primary"
        [ngClass]="{'w-100': isMobile && !showClear}"
        (click)="emitSelection()"
      >
        {{ "shared.components.date_picker.apply_button" | translate }}
      </button>
    </div>
  </div>
</div>
