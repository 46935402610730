import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  model,
  output,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {
  debounceTime,
  filter,
  map,
  Observable,
  startWith,
  Subscription,
} from 'rxjs';
import {BrandingService} from 'src/app/core/services';
import {FontService} from 'src/app/shared/helpers';
import {FontSize, ReportTheme} from 'src/app/shared/models';
import {ThemeFromReportComponent} from '../../theme-from-report/theme-from-report.component';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface ToCustomReportProperties {
  name: string;
  fontSize: FontSize;
  orientation: 'horizontal' | 'vertical';
  theme: ReportTheme | null;
}

// [x]: Signals implemented
@Component({
    selector: 'app-to-custom-report',
    templateUrl: './to-custom-report.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./to-custom-report.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatRadioGroup,
        MatRadioButton,
        MatSelect,
        MatOption,
        MatAutocompleteTrigger,
        MatAutocomplete,
        AsyncPipe,
        TranslateModule,
    ],
})
export class ToCustomReportComponent implements OnInit {
  toCustomReportProperties = model<ToCustomReportProperties | null>();
  changeToCustomReportProperties = output<boolean>();

  public toCustomReportForm: FormGroup;
  public themeControl: UntypedFormControl = new UntypedFormControl();
  public themeSelected = new UntypedFormControl('', Validators.required);
  public fontSizes = signal<FontSize[]>([]);

  public themes = signal<ReportTheme[]>([]);
  public filteredThemes: Observable<ReportTheme[]>;

  public loadingThemes = signal<boolean>(false);
  public pickANewTheme = signal<boolean>(false);

  public subs = new Subscription();

  constructor(
    private fontSizeService: FontService,
    public dialog: MatDialog,
    private router: Router,
    private brandingService: BrandingService
  ) {}

  ngOnInit(): void {
    this.fontSizes.set(this.fontSizeService.getFontSizes);
    this.pickANewTheme.set(
      this.toCustomReportProperties()
        ? true
        : !this.toCustomReportProperties && false
    );
    this.buildToCustomReportForm();
    this.getThemes();
    this.toCustomReportProperties() && this.populateForm();

    this.filteredThemes = this.themeControl.valueChanges.pipe(
      startWith(''),
      filter((value) => typeof value === 'string'),
      map((value) => this._filterTheme(value || ''))
    );

    this.toCustomReportForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.toCustomReportProperties.update((props) => {
          if (props) {
            props.name = value.name && value.name;
            props.name = value.orientation && value.orientation;
            props.fontSize = value.fontSize && value.fontSize;
            props.theme = props.theme ? props.theme : null;
          }
          return props;
        });
        this.changeToCustomReportProperties.emit(true);
      });
  }

  buildToCustomReportForm(): void {
    this.toCustomReportForm = new FormGroup({
      name: new FormControl('', Validators.required),
      orientation: new FormControl('horizontal', Validators.required),
      fontSize: new FormControl(this.fontSizes()[1], Validators.required),
    });
  }

  populateForm(): void {
    this.toCustomReportForm.setValue({
      name: this.toCustomReportProperties()
        ? this.toCustomReportProperties()?.name
        : '',
      orientation: this.toCustomReportProperties()?.orientation,
      fontSize: this.fontSizes().filter(
        (f) => f.size === this.toCustomReportProperties()?.fontSize.size
      )[0],
    });
  }

  getThemes(): void {
    this.loadingThemes.set(true);
    this.brandingService.getReportThemes().subscribe({
      next: (res) => {
        if (res) {
          this.themes.set(res);
          this.themes.update((themeValue) => {
            themeValue.map(
              (t) =>
                (t.colors = [
                  t.backgroundColor!,
                  t.bodyColor!,
                  t.borderColor!,
                  t.pageColor!,
                  t.titleColor!,
                ])
            );
            return themeValue;
          });
          this.handleSetTheme();
          this.loadingThemes.set(false);
          this.hideShowCaret();
        }
      },
      error: () => {
        this.loadingThemes.set(false);
        this.hideShowCaret();
      },
    });
  }

  handleSetTheme(): void {
    if (
      this.toCustomReportProperties() &&
      this.toCustomReportProperties()?.theme
    ) {
      const currentTheme = this.themes()
        .filter((el) => el._id)
        .find((t) => t._id === this.toCustomReportProperties()!.theme!._id);
      this.themeSelected.setValue(currentTheme);
      this.themeControl.setValue('');
    }
  }

  hideShowCaret(): void {
    const caret = document.querySelector(
      '#cThemeSlt>.mat-select-trigger>.mat-select-arrow-wrapper'
    );
    this.loadingThemes()
      ? caret!.classList.add('d-none')
      : caret?.classList.remove('d-none');
  }

  private _filterTheme(name: string): ReportTheme[] {
    const filterValue = name.toLowerCase();

    return [
      ...this.themes().filter((t) =>
        t.name?.toLowerCase().includes(filterValue)
      ),
    ];
  }

  onShowThemeDialog(): void {
    const dialogRef = this.dialog.open(ThemeFromReportComponent, {
      data: {
        isFromReport: true,
      },
      autoFocus: false,
      maxWidth: '100vw',
      width: '85vw',
      height: '95vh',
      disableClose: true,
      position: {
        top: '1rem',
      },
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    this.subs.add(
      dialogRef.afterClosed().subscribe((res) => {
        if (res?.theme) {
          this.themes.set(res.theme);
          this.themeSelected.setValue(res.theme);
        }
        dialogSub.unsubscribe();
      })
    );
  }

  onChangeThemeFromList(event: MatAutocompleteSelectedEvent): void {
    this.themeSelected.setValue(event.option.value);
    this.themeControl.setValue('');
    this.pickANewTheme.set(true);
    const controls = this.toCustomReportForm.controls;

    this.toCustomReportProperties.update((props) => {
      props = {
        name: controls.name.value && controls.name.value,
        orientation: controls.orientation.value && controls.orientation.value,
        fontSize: controls.fontSize.value && controls.fontSize.value,
        theme: event.option.value,
      };
      return props;
    });
    this.changeToCustomReportProperties.emit(true);
  }
}
