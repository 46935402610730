<div class="d-flex flex-column h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">
        {{
          (data.type === "email"
            ? "shared.dialogs.share_report.share_report_title"
            : "shared.dialogs.share_report.schedule_title"
          ) | translate
        }}
      </h1>

      <button
        (click)="onClose()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>

    @if (data.type === "email") {
      <p>
        {{ "shared.dialogs.share_report.send_via_email" | translate }}
      </p>
    }
    @if (data.type === "salesforce") {
      <p>
        {{ "shared.dialogs.share_report.email_set_automatic" | translate }}
      </p>
    }

    <p class="mt-3">
      <small class="d-flex wsm-text-grayed">
        {{ "common.fields_required" | translate }}
      </small>
    </p>
  </header>

  <mat-dialog-content class="share-report-content">
    <form
      id="share-form"
      [formGroup]="shareReportForm"
      (ngSubmit)="onSubmit(data.type === 'salesforce' ? 'ACTIVE' : '')"
    >
      @if (data.type === "email") {
        <div class="row mx-0 mb-3">
          <div class="col-12 ps-0">
            <label
              >{{
                "shared.dialogs.share_report.email_to_label" | translate
              }}&nbsp;<span class="required-mark">*</span></label
            >
            <div
              class="wsm-input p-0"
              [class.border-danger]="
                shareReportForm.get('email')?.touched &&
                (shareReportForm.get('email')?.errors?.required ||
                  shareReportForm.get('email')?.errors?.email)
              "
            >
              <mat-chip-grid #emailChipList aria-label="Email list">
                @for (email of emails; track email) {
                  <mat-chip-option
                    class="widget-field"
                    [selectable]="false"
                    [removable]="removable"
                    (removed)="remove(email, 'email')"
                  >
                    {{ email }}
                    @if (removable) {
                      <mat-icon matChipRemove>cancel</mat-icon>
                    }
                  </mat-chip-option>
                }
                <input
                  formControlName="email"
                  appLowercase
                  placeholder="{{
                    'shared.dialogs.share_report.to_placeholder' | translate
                  }}"
                  class="wsm-input chip-input border-0 ps-1"
                  [matChipInputFor]="emailChipList"
                  [matChipInputAddOnBlur]="true"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 'email')"
                  type="text"
                  autocomplete="chrome-off"
                />
              </mat-chip-grid>
            </div>
            @if (
              shareReportForm.get("email")?.touched &&
              shareReportForm.get("email")?.errors?.email
            ) {
              <div class="invalid-info">
                {{ "common.errors.valid_email_required" | translate }}
              </div>
            }
            @if (
              shareReportForm.get("email")?.touched &&
              shareReportForm.get("email")?.errors?.required
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
        </div>
      }

      @if (data.type === "email") {
        <div class="row mx-0 mb-3">
          <div class="col-12 ps-0">
            <label for="carbon-copy">{{
              "shared.dialogs.share_report.cc_to_label" | translate
            }}</label>
            <div
              class="wsm-input p-0"
              [class.border-danger]="
                shareReportForm.get('carbonCopy')?.touched &&
                (shareReportForm.get('carbonCopy')?.errors?.required ||
                  shareReportForm.get('carbonCopy')?.errors?.email)
              "
            >
              <mat-chip-grid #ccChipList>
                @for (email of ccEmails; track email) {
                  <mat-chip-option
                    class="widget-field"
                    [selectable]="false"
                    [removable]="removable"
                    (removed)="remove(email, 'cc')"
                  >
                    {{ email }}
                    @if (removable) {
                      <mat-icon matChipRemove>cancel</mat-icon>
                    }
                  </mat-chip-option>
                }
                <input
                  placeholder="{{
                    'shared.dialogs.share_report.cc_placeholder' | translate
                  }}"
                  class="wsm-input chip-input border-0 ps-1"
                  appLowercase
                  formControlName="carbonCopy"
                  [matChipInputFor]="ccChipList"
                  [matChipInputAddOnBlur]="true"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 'cc')"
                  id="carbon-copy"
                  type="text"
                  autocomplete="chrome-off"
                />
              </mat-chip-grid>
            </div>
            @if (
              shareReportForm.get("carbonCopy")?.touched &&
              shareReportForm.get("carbonCopy")?.errors?.email
            ) {
              <div class="invalid-info">
                {{ "common.errors.valid_email_required" | translate }}
              </div>
            }
          </div>
        </div>
      }

      @if (data.type === "email") {
        <div class="row mx-0 mb-3">
          <div class="col-12 ps-0">
            <label for="subject"
              >{{
                "shared.dialogs.share_report.subject_label" | translate
              }}&nbsp;<span class="required-mark">*</span></label
            >
            <input
              id="subject"
              type="text"
              [class.border-danger]="
                shareReportForm.get('subject')?.touched &&
                shareReportForm.get('subject')?.errors?.required
              "
              class="wsm-input w-100"
              formControlName="subject"
              placeholder="{{
                'shared.dialogs.share_report.subject_placeholder' | translate
              }}"
              autocomplete="off"
            />
            @if (
              shareReportForm.get("subject")?.touched &&
              shareReportForm.get("subject")?.errors?.required
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
        </div>
      }

      @if (data.type === "email") {
        <div class="row mx-0 mb-3">
          <div class="col ps-0">
            <label for="content"
              >{{
                "shared.dialogs.share_report.message_label" | translate
              }}&nbsp;<span class="required-mark">*</span></label
            >
            <ckeditor
              id="content"
              [editor]="Editor"
              [config]="editorConfig"
              formControlName="content"
              [ngClass]="{
                'invalid-editor':
                  shareReportForm.get('content')?.touched &&
                  shareReportForm.get('content')?.errors?.required,
              }"
            >
            </ckeditor>
            @if (
              shareReportForm.get("content")?.touched &&
              shareReportForm.get("content")?.errors?.required
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
        </div>
      }

      <div class="row mx-0">
        @if (data.type !== "salesforce") {
          <label for="schedule" class="ps-0">
            {{
              "shared.dialogs.share_report.delivery_schedule_label" | translate
            }}&nbsp;<span class="required-mark">*</span>
          </label>
        }
        <div
          class="frequency-selector ps-0"
          [ngClass]="{'flex-column': data.isMobile}"
        >
          @if (data.type !== "salesforce") {
            <div class="col ps-0">
              <mat-select
                formControlName="frequency"
                class="wsm-input"
                [class.border-danger]="
                  shareReportForm.get('frequency')?.touched &&
                  shareReportForm.get('frequency')?.errors?.required
                "
                [panelClass]="'wsm-custom-select'"
                placeholder="{{
                  'shared.dialogs.share_report.select_schedule_placeholder'
                    | translate
                }}"
                id="schedule"
              >
                @for (frequency of frequencies; track frequency) {
                  <mat-option [value]="frequency.id">
                    {{ frequency.label | translate }}
                  </mat-option>
                }
              </mat-select>
            </div>
          }

          @if (
            shareReportForm.get("frequency")?.value !== "Now" &&
            shareReportForm.get("frequency")?.value !== ""
          ) {
            <div class="d-flex flex-column col ps-0">
              @if (data.type === "salesforce") {
                <label for="sendTime">
                  {{
                    "shared.dialogs.share_report.send_time_label" | translate
                  }}
                </label>
              }
              <mat-select
                formControlName="sendTime"
                class="wsm-input"
                [class.border-danger]="
                  shareReportForm.get('sendTime')?.touched &&
                  shareReportForm.get('sendTime')?.errors?.required
                "
                [panelClass]="'wsm-custom-select'"
                placeholder="{{
                  'shared.dialogs.share_report.select_time_placeholder'
                    | translate
                }}"
                id="sendTime"
              >
                @for (hour of hours; track hour) {
                  <mat-option [value]="hour">
                    {{ hour }}
                  </mat-option>
                }
              </mat-select>
            </div>
          }

          @if (shareReportForm.get("frequency")?.value === "Monthly") {
            <div class="d-flex flex-column col ps-0">
              @if (data.type === "salesforce") {
                <label for="dayOfTheMonth">
                  {{ "shared.dialogs.share_report.day_label" | translate }}
                </label>
              }
              <mat-select
                formControlName="dayOfTheMonth"
                class="wsm-input"
                [class.border-danger]="
                  shareReportForm.get('dayOfTheMonth')?.touched &&
                  shareReportForm.get('dayOfTheMonth')?.errors?.required
                "
                [panelClass]="'wsm-custom-select'"
                id="dayOfTheMonth"
                placeholder="{{
                  'shared.dialogs.share_report.day_placeholder' | translate
                }}"
              >
                @for (dayOfTheMonth of monthDays; track dayOfTheMonth) {
                  <mat-option [value]="dayOfTheMonth">
                    {{ dayOfTheMonth }}
                  </mat-option>
                }
              </mat-select>
            </div>
          }
          @if (shareReportForm.get("frequency")?.value === "Weekly") {
            <div class="col ps-0">
              <mat-select
                class="wsm-input"
                formControlName="dayOfTheWeek"
                placeholder="{{
                  'shared.dialogs.share_report.day_of_week_placeholder'
                    | translate
                }}"
              >
                @for (dayOfTheWeek of weekDays; track dayOfTheWeek) {
                  <mat-option [value]="dayOfTheWeek.id">
                    {{ dayOfTheWeek.label | translate }}
                  </mat-option>
                }
              </mat-select>
            </div>
          }
        </div>
        @if (
          shareReportForm.get("frequency")?.touched &&
          shareReportForm.get("frequency")?.errors?.required
        ) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      id="shareReportEmailBtn"
      class="wsm-btn wsm-btn-primary submit-btn"
      [ngClass]="{'w-100': data.isMobile}"
      form="share-form"
      type="submit"
    >
      @if (!isLoading) {
        <span>{{
          "shared.dialogs.share_report.enable_schedule_button" | translate
        }}</span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  </mat-dialog-actions>
</div>
