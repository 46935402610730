<div class="d-flex flex-column h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">
        {{ "shared.dialogs.share_to_user.share_report_title" | translate }}
      </h1>

      <button
        (click)="onClose()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    <p>
      {{ "shared.dialogs.share_to_user.share_report_description" | translate }}
    </p>
  </header>

  <mat-dialog-content class="external-users-details">
    <div class="row mx-0 mb-3">
      <p class="mb-2">
        {{ "shared.dialogs.share_to_user.choose_user_label" | translate }}
        <button
          id="createExternalUserBtn"
          class="wsm-btn wsm-btn-link d-inline-flex"
          (click)="openCreateUserDialog()"
        >
          {{ "shared.dialogs.share_to_user.create_new_user_label" | translate }}
        </button>
      </p>
      <div class="user-search-container">
        <div class="wsm-input-group-prepend w-100">
          <i
            [ngClass]="{'d-none': searchLoading}"
            class="fas fa-search ps-1"
          ></i>
          @if (searchLoading) {
            <i
              class="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </i>
          }
          <input
            type="text"
            class="wsm-input w-100"
            [formControl]="externalUserSearch"
            placeholder="{{
              'shared.dialogs.share_to_user.add_user_placeholder' | translate
            }}"
            autocomplete="off"
            [matAutocomplete]="externalUserAutocomplete"
          />
        </div>
        <mat-autocomplete
          #externalUserAutocomplete="matAutocomplete"
          class="mat-menu-gray"
          (optionSelected)="onUserSelect($event)"
        >
          @for (user of filteredUsers | async; track user) {
            <mat-option
              [value]="user | as: UserModel"
              class="external-user-option"
              [innerHtml]="
                (user | as: UserModel).name! | highlight: toHighlight
              "
            >
            </mat-option>
          }
        </mat-autocomplete>
        @if (searchNoResults) {
          <span class="invalid-info">
            {{ "shared.dialogs.share_to_user.no_user_found_label" | translate }}
          </span>
        }
      </div>
    </div>

    <div class="user-list-container" [ngClass]="usersLoading ? 'p-0' : 'p-3'">
      @if (!usersLoading) {
        <h2 class="h5">
          {{
            "shared.dialogs.share_to_user.user_with_access_title" | translate
          }}
        </h2>
        @if (users?.length !== 0) {
          @for (user of users; track user) {
            <div class="user-container py-2 px-2 w-100">
              <div class="d-flex">
                <img
                  src="/assets/img/default-guest-user.jpg"
                  alt="User image"
                  class="rounded-circle me-2 user-img"
                  width="40"
                  height="40"
                />
                <div class="d-flex flex-column user-info">
                  <span class="font-weight-bold">{{ user.name }}</span>
                  <p class="mb-0 user-email">{{ user.email }}</p>
                </div>
              </div>
              <button
                class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon me-3 remove-btn"
                (click)="onRemoveUser(user)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          }
        }
        @if (users?.length === 0) {
          <div class="wsm-alert-message mt-3">
            <strong>{{
              "shared.dialogs.share_to_user.no_user_added_label" | translate
            }}</strong>
          </div>
        }
      }

      @if (usersLoading) {
        <div class="loader-container">
          <div class="spinner-ripple">
            <div class="circle">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      }
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      id="shareReportUserBtn"
      class="wsm-btn wsm-btn-primary submit-btn mb-3"
      [ngClass]="{'w-100': data.isMobile}"
      type="submit"
      (click)="onShareReport()"
      [disabled]="users?.length! <= 0 && !usersDeleted"
    >
      @if (!isLoading) {
        <span>{{
          "shared.dialogs.share_to_user.save_button" | translate
        }}</span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  </mat-dialog-actions>
  @if (data.isMobile) {
    <app-loading [generalLoading]="isLoading"></app-loading>
  }
</div>
