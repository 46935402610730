<div
  class="w-100 h-100"
  (appResized)="resizeGauge($event)"
  [ngClass]="{
    'd-flex justify-content-center align-items-center':
      widget.widgetType === 'gauge' || widget.widgetType === 'current-goal',
  }"
>
  @if (widget.widgetType === "gauge" && renderedMarkers) {
    <ngx-gauge
      #gauge
      [type]="'semi'"
      [value]="widget.chartData?.data.goalProgress"
      [max]="100"
      [thick]="18"
      [cap]="'round'"
      [size]="size"
      [margin]="9"
      [markers]="markers"
      [foregroundColor]="gaugeColor.foregroundColor"
      [backgroundColor]="gaugeColor.backgroundColor"
    >
      <ngx-gauge-value>
        <span [ngStyle]="{'color': theme.bodyColor}" class="fs-1">
          {{
            !widget.showPercentage
              ? currentValue
              : widget.chartData?.data.goalProgress + "%"
          }}
        </span>
      </ngx-gauge-value>
    </ngx-gauge>
  }

  @if (widget.widgetType === "current-goal") {
    <div class="w-100 h-100 d-flex align-items-center justify-content-around">
      <div class="d-flex flex-column gap-5">
        <span class="d-flex flex-column gap-2">
          <span [ngStyle]="{'color': theme.bodyColor}" class="wrap-label">{{
            widget.resultValueLabel || "Results"
          }}</span>
          <span [ngStyle]="{'color': theme.bodyColor}" class="fs-1">{{
            currentVsGoal.currentValue
          }}</span>
        </span>
        <span class="d-flex flex-column gap-2">
          <span [ngStyle]="{'color': theme.bodyColor}" class="wrap-label">{{
            widget.goalAmountLabel || "Goal"
          }}</span>
          <span [ngStyle]="{'color': theme.bodyColor}" class="fs-1">{{
            currentVsGoal.goalValue
          }}</span>
        </span>
      </div>
      <div>
        <ngx-gauge
          [type]="'full'"
          [value]="widget.chartData?.data.goalProgress"
          [max]="100"
          [size]="size"
          [thick]="18"
          [cap]="'round'"
          [foregroundColor]="gaugeColor.foregroundColor"
          [backgroundColor]="gaugeColor.backgroundColor"
        >
          @if (!widget.showPercentage) {
            <ngx-gauge-value>
              <span [ngStyle]="{'color': theme.bodyColor}" class="fs-2">
                {{ widget.chartData?.data.goalProgress }}%
              </span>
            </ngx-gauge-value>
          }
        </ngx-gauge>
      </div>
    </div>
  }

  @if (widget.widgetType === "timeline") {
    <fusioncharts
      [dataSource]="widget.chartData?.chartData"
      [type]="widget.chartData?.chartType!"
      height="100%"
      width="100%"
    >
    </fusioncharts>
  }
</div>
