import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationStart,
  Router,
  UrlSegment,
} from '@angular/router';
import {retry, Subscription, take} from 'rxjs';
import {
  LoadingService,
  MobileService,
  NotificationService,
  ReportingDatasetsService,
  TrackingService,
} from 'src/app/core/services';
import {ReportService} from 'src/app/core/services/report.service';
import {WidgetService} from 'src/app/core/services/widget.service';
import {
  SaveAsTemplateDialogComponent,
  TemplateLibraryDialogComponent,
} from 'src/app/shared/dialogs';
import {
  AddPagePayload,
  CustomError,
  DataQueueStatus,
  Dataset,
  PageTemplate,
  Report,
  ReportPage,
  addTemplatePagePayload,
  IContextMenu,
  ImageData,
} from 'src/app/shared/models';
import {ReportPageSettingsComponent} from '../report-page-settings/report-page-settings.component';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {FontService, HelperService} from 'src/app/shared/helpers';
import {DOCUMENT, NgClass, NgStyle} from '@angular/common';
import {EditableTitleComponent} from '../../../../shared/components';
import {ReportToolbarComponent} from '../report-toolbar/report-toolbar.component';
import {TabsComponent} from '../../../../shared/components/tabs';
import {TabItemComponent} from '../../../../shared/components/tabs';
import {TabLabelComponent} from '../../../../shared/components/tabs';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {ReportPageComponent} from '../report-page/report-page.component';
import {ReportTemplateNavigatorComponent} from '../../../../shared/components';
import {UnauthorizedPageComponent} from '../../../../shared/components';
import {TruncatePipe} from '../../../../shared/pipes';
import {ConfirmationDialogComponent} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {DatasetStoreSignal} from '../../state/dataset.store';
import {ReportPageStoreSignal} from '../../state/report-page.store';
import {ReportPresetColorsStore} from '../../state/report-preset-colors.store';

@Component({
  selector: 'app-report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    EditableTitleComponent,
    ReportToolbarComponent,
    NgStyle,
    TabsComponent,
    TabItemComponent,
    TabLabelComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    ReportPageComponent,
    ReportTemplateNavigatorComponent,
    UnauthorizedPageComponent,
    TranslateModule,
    TruncatePipe,
  ],
})
export class ReportBuilderComponent implements OnInit, OnDestroy {
  // services
  private reportPageStore = inject(ReportPageStoreSignal);
  private presetsColorStore = inject(ReportPresetColorsStore);

  // State Variables
  isLoading = signal<boolean>(false);
  isEditMode = signal<boolean>(false);
  tabsOn = signal<boolean>(false);
  isPresentationMode = signal<boolean>(false);
  canNavigate = signal<boolean>(false);
  hasChanges: boolean;
  isExternalUser: boolean;
  isReportLoading = false;
  isBottomSheetOpen: boolean;
  isPrint = false;
  firstStateAdded = false;
  originalPageState: ReportPage;
  windowWidth: number = window.screen.width;

  // Properties
  report: Report;
  reportId: string;
  selectedPage: ReportPage;
  refreshPageIndex: number;
  subs: Subscription = new Subscription();
  pageStates: ReportPage[] = [];
  pageStateIndex = 1;
  noAccess: boolean;
  isMobile: boolean;
  getReportSub: Subscription;
  getDataQueueSub: Subscription;
  imageToPaste: ImageData | null;

  dataQueueStatus: DataQueueStatus = {
    processError: false,
    processPending: false,
    processQueue: false,
    processStatus: '',
  };

  // Host Listeners
  @HostListener('window:beforeunload', ['$event'])
  handleClose($event: BeforeUnloadEvent): void {
    if (this.hasChanges) {
      $event.returnValue = true;
      this.reportService.warnOfUnsavedChanges(
        undefined,
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report builder: before unload'
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event): void {
    this.tabsOn.set(
      $event.target.innerWidth <= 1750 ||
        $event.target.innerHeight <= 900 ||
        this.isMobile
    );
    this.windowWidth = $event.target.innerWidth;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // Code for changing page with arrow keys
    if (this.isPresentationMode() || !this.isEditMode()) {
      if (this.dialog.openDialogs.length !== 0) {
        return;
      }
      if (event.code === 'ArrowRight') {
        this.selectPage(this.selectedPage.order + 1, 'next');
      } else if (event.code === 'ArrowLeft') {
        this.selectPage(this.selectedPage.order - 1, 'previous');
      }
    }
    // Code for Undo/Redo
    if (
      this.isBottomSheetOpen ||
      this.dialog.openDialogs.length !== 0 ||
      this.isReportLoading
    ) {
      return;
    }

    if (event.code === 'KeyZ' && (event.ctrlKey || event.metaKey)) {
      if (this.pageStates.length < 1) {
        return;
      }
      if (event.shiftKey && this.pageStateIndex > 1) {
        this.changePageState('redo');
      } else if (
        !event.shiftKey &&
        this.pageStateIndex < this.pageStates.length
      ) {
        this.changePageState('undo');
      }
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullscreenChange(): void {
    if (!document.fullscreenElement) {
      const elem = document.getElementById('report-grid') as HTMLElement;
      elem.style.overflowY = 'hidden';
      this.reportService.setPresentationMode = false;
    } else {
      this.reportService.setPresentationMode = true;
    }
  }

  private datasetStoreSignal = inject(DatasetStoreSignal);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private loader: LoadingService,
    private widgetService: WidgetService,
    private titleService: Title,
    private fontService: FontService,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private mobileService: MobileService,
    private trackingService: TrackingService,
    private datasetService: ReportingDatasetsService
  ) {
    this.subs.add(
      router.events.subscribe((event): void => {
        if (event instanceof NavigationStart) {
          this.getReportSub.unsubscribe();
          this.getDataQueueSub.unsubscribe();
          if (
            this.hasChanges &&
            !this.canNavigate() &&
            !event.url.includes('reports') &&
            !event.url.includes('page-')
          ) {
            this.router
              .navigateByUrl(router.url, {skipLocationChange: true})
              .then();
            this.canNavigate.set(true);
            setTimeout(() =>
              this.reportService.warnOfUnsavedChanges(
                undefined,
                event.url,
                this.reportService.returnToOriginalPageState.bind(
                  this,
                  this.report.pages,
                  this.selectedPage,
                  this.originalPageState
                ),
                'report builder constructor router'
              )
            );
          }
        }
      })
    );

    this.mobileService.getMobileBehavior().subscribe((res: boolean): void => {
      this.isMobile = res;
    });
  }

  ngOnInit(): void {
    this.loader.show('body');
    this.document.addEventListener('paste', this.onPaste.bind(this));
    this.reportId = this.route.snapshot.paramMap.get('id') ?? '';
    this.isPrint = this.route.snapshot.url.some(
      (segment: UrlSegment): boolean => segment.path === 'print'
    );
    this.refreshPageIndex = this.route.snapshot.fragment
      ? parseInt(this.route.snapshot.fragment.slice(5), 10) - 1
      : 0;
    this.getReport();
    this.getDataQueueStatus();

    this.tabsOn.set(window.innerWidth <= 1750 || window.innerHeight <= 900);

    this.route.fragment.subscribe((pageNumber: string | null): void => {
      if (pageNumber) {
        this.refreshPageIndex = parseInt(pageNumber.slice(5), 10) - 1;
      }
    });

    this.reportService.hasChanges$.subscribe(
      (res: {hasChanges: boolean; skipPageState?: boolean}): void => {
        this.hasChanges = res.hasChanges;
        // Save page state for Undo/Redo everytime there is a change.
        if (res.hasChanges && !res.skipPageState) {
          if (this.pageStates.length >= 10) {
            this.pageStates.shift();
          }
          if (
            this.pageStates.length - this.pageStateIndex !==
            this.pageStates.length - 1
          ) {
            this.pageStates.splice(
              this.pageStates.length - this.pageStateIndex + 1
            );
          }
          this.pageStateIndex = 1;
          this.pageStates.push(
            this.helperService.copyObject(this.selectedPage) as ReportPage
          );
          this.ref.detectChanges();
        }
      }
    );

    this.subs.add(
      this.reportService.isReportLoading$.subscribe((res: boolean): void => {
        this.isReportLoading = res;
      })
    );

    this.reportService.isBottomSheetOpen$.subscribe((res: boolean): void => {
      this.isBottomSheetOpen = res;
    });

    if (localStorage.getItem('currentUser')) {
      this.isExternalUser = JSON.parse(
        localStorage.getItem('currentUser') as string
      ).roles.includes('ROLE_EXTERNAL');
    }

    if (!this.isExternalUser) {
      this.getAllDatasets();
    }
  }

  private getAllDatasets(): void {
    this.subs.add(
      this.datasetService.getAllDatasets().subscribe({
        next: (res: Array<Dataset>) => {
          this.datasetStoreSignal.setDatasets(res as Required<Dataset>[]);
        },
        error: (err: CustomError) => {
          this.notificationService.error(
            err.message ?? 'Something went wrong',
            5000
          );
        },
        complete: () => {},
      })
    );
  }

  getReport(): void {
    this.getReportSub = this.reportService.getReport(this.reportId).subscribe({
      next: (report): void => {
        this.report = report;
        this.presetsColorStore.addReportPresetColors(
          this.report.id,
          this.report?.theme?.colorPalette
        );
        this.presetsColorStore.updateActiveReport(this.report.id);
        if (this.isExternalUser) {
          this.report.pages.forEach((page: ReportPage, index: number): void => {
            page.order = index;
          });
        }
      },
      error: (err: CustomError): void => {
        if (err.status !== 406) {
          this.notificationService.error(err?.message ?? '', 5000);
        }
        this.isLoading.set(false);
        this.noAccess = err.status === 406;
        this.loader.hide('body');
      },
      complete: (): void => {
        this.loader.hide('body');
        this.titleService.setTitle(`AdClicks | ${this.report.name}`);
        this.selectPage(this.refreshPageIndex ?? 0);
        this.fontService.setReportFontSize(this.report.theme.fontSize);
      },
    });
  }

  getDataQueueStatus(): void {
    this.getDataQueueSub = this.reportService
      .getDataQueue(this.reportId)
      .subscribe({
        next: (downloadStatus) => {
          this.dataQueueStatus = {
            processError: downloadStatus.processError,
            processPending: downloadStatus.processPending,
            processQueue: downloadStatus.processQueue,
            processStatus: downloadStatus.processStatus
              ? downloadStatus.processStatus
              : '',
          };
        },
        error: (err: CustomError) => {
          if (err.status !== 406) {
            this.notificationService.error(err?.message ?? '', 5000);
          }
        },
      });
  }

  onReportNameChange(event): void {
    this.reportService.setIsLoadingValue = true;
    this.report.name = event;
    this.reportService
      .updateReport({name: this.report.name}, this.report.id)
      .subscribe({
        next: (): void => {},
        error: (err): void => {
          this.reportService.setIsLoadingValue = false;
          this.notificationService.error(err.error.error_description, 5000);
          this.isLoading.set(false);
        },
        complete: (): void => {
          this.reportService.setIsLoadingValue = false;
          this.isLoading.set(false);
          this.translate
            .get('notifications.report_name_updated')
            .subscribe((res: string): void => {
              this.notificationService.success(res, 5000);
            });
        },
      });
  }

  onEditModeToggle(event): void {
    this.widgetService.cascadeEditMode(event);
    this.isEditMode.set(event);
  }

  onEditPageOrder(report: Report): void {
    this.report = report;
    this.selectPage(
      this.report.pages.findIndex(
        (page: ReportPage): boolean => page.id === this.selectedPage.id
      )
    );
  }

  onPageReorder(event: CdkDragDrop<string[]>): void {
    this.reportService.setIsLoadingValue = true;
    moveItemInArray(this.report.pages, event.previousIndex, event.currentIndex);
    this.report.pages.forEach(
      (page: ReportPage) => (page.order = this.report.pages.indexOf(page))
    );
    this.selectedPage = this.report.pages[event.currentIndex];
    this.router
      .navigate([`/reports/${this.report.id}/edit`], {
        fragment: `page-${this.selectedPage.order + 1}`,
      })
      .then();

    const payload = {
      pageIndex: this.selectedPage.order,
      pages: this.report.pages,
    };

    this.reportService.reorderPages(this.reportId, payload).subscribe({
      error: (err): void => {
        this.reportService.setIsLoadingValue = false;
        this.notificationService.error(err.error.error.message, 5000);
      },
      complete: (): void => {
        this.reportService.setIsLoadingValue = false;
      },
    });
  }

  onPageUpdated(page: ReportPage): void {
    this.originalPageState = this.helperService.copyObject(page) as ReportPage;
    if (!this.firstStateAdded) {
      this.pageStates.push(this.helperService.copyObject(page) as ReportPage);
      this.firstStateAdded = true;
    }
    this.selectedPage = page;
  }

  public selectPage(pageIndex: number, direction?: string): void {
    if (!this.report.pages[pageIndex]) {
      return;
    }

    this.pageStates = [];
    this.pageStateIndex = 1;
    this.firstStateAdded = false;

    if (!this.report.pages[pageIndex].isVisible && this.isPresentationMode()) {
      switch (direction) {
        case 'next':
          this.selectPage(pageIndex + 1, 'next');
          break;
        case 'previous':
          this.selectPage(pageIndex - 1, 'previous');
          break;
      }
    } else {
      this.selectedPage = this.report.pages[pageIndex];
      this.originalPageState = this.helperService.copyObject(
        this.report.pages[pageIndex]
      ) as ReportPage;
      this.report.pages.forEach((page: ReportPage): void => {
        if (page.id === this.selectedPage.id) {
          return;
        }
        page.isActive = false;
      });
      this.selectedPage.isActive = true;
      this.reportPageStore.updateActivePage(this.selectedPage);
      this.router
        .navigate([`/reports/${this.report.id}/edit`], {
          fragment: `page-${this.selectedPage.order + 1}`,
        })
        .then();
    }
  }

  public selectTab(pageIndex: number, direction?: string): void {
    const reportPageId =
      this.report.pages[pageIndex]._id || this.report.pages[pageIndex].id;
    const selectedPageId = this.selectedPage._id || this.selectedPage.id;
    if (
      this.isReportLoading ||
      !this.report.pages[pageIndex] ||
      reportPageId === selectedPageId
    ) {
      return;
    }

    if (this.hasChanges) {
      this.reportService.warnOfUnsavedChanges(
        this.selectTab.bind(this, pageIndex, direction),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        )
      );
    } else {
      this.selectPage(pageIndex, direction);
    }
  }

  addPage(type: string): void {
    if (this.hasChanges) {
      this.reportService.warnOfUnsavedChanges(
        this.addPage.bind(this, type),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report builder addPAge'
      );
    } else {
      this.isLoading.set(true);
      switch (type) {
        case 'newPage':
          this.addNewPage();
          break;
        case 'newTemplatePage':
          this.openTemplateLibraryDialog('page');
          break;
      }
    }
  }

  addNewPage(): void {
    this.reportService.setIsLoadingValue = true;
    const payload = {
      reportId: this.reportId,
      pageIndex: this.selectedPage.order,
      pageId: this.report?.pages[this.selectedPage.order]?.id,
    } as AddPagePayload;

    this.reportService.addPage(payload).subscribe({
      next: (res: ReportPage): void => {
        this.report.pages.forEach((page: ReportPage): void => {
          if (page.order >= res.order) {
            page.order = page.order + 1;
          }
        });
        this.report.pages.splice(this.selectedPage.order + 1, 0, res);
      },
      error: (err: CustomError): void => {
        this.translate
          .get('notifications.error_adding_page')
          .subscribe((res: string): void => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
        this.reportService.setIsLoadingValue = false;
        this.isLoading.set(false);
      },
      complete: (): void => {
        this.isLoading.set(false);
        this.reportService.setIsLoadingValue = false;
        this.selectPage(this.selectedPage.order + 1);
      },
    });
  }

  changePageState(type: string): void {
    switch (type) {
      case 'redo':
        this.pageStateIndex -= 1;
        if (
          this.pageStates.length - this.pageStateIndex >
          this.pageStates.length
        ) {
          return;
        }
        this.selectedPage = JSON.parse(
          JSON.stringify(
            this.pageStates[this.pageStates.length - this.pageStateIndex]
          )
        );
        this.reportService.setChangeValue = {
          hasChanges: true,
          skipPageState: true,
          from: 'report-builder | redo',
        };
        break;
      case 'undo':
        this.pageStateIndex += 1;
        if (this.pageStates.length - this.pageStateIndex < 0) {
          return;
        }
        this.selectedPage = JSON.parse(
          JSON.stringify(
            this.pageStates[this.pageStates.length - this.pageStateIndex]
          )
        );
        this.reportService.setChangeValue = {
          hasChanges: true,
          skipPageState: true,
          from: 'report-builder | undo',
        };
        break;
    }
  }

  openTemplateLibraryDialog(type: string): void {
    const dialogRef = this.dialog.open(TemplateLibraryDialogComponent, {
      data: {
        type,
        reportId: this.reportId,
        pageId: this.selectedPage.id,
        orientation: this.report.orientation,
        page: this.selectedPage,
      },
      width: '75vw',
      height: '78vh',
    });

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((templateInfo): void => {
        if (templateInfo) {
          if (templateInfo?.widget) {
            this.trackingService.trackEvent('Add Widget From Library');
            this.widgetService.cascadeAddTemplateWidget(templateInfo.widget);
          }
          if (templateInfo?.reportPage) {
            this.addPageFromTemplate(templateInfo.reportPage);
          }
        }
        dialogSub.unsubscribe();
      });
  }

  addPageFromTemplate(reportPage: PageTemplate): void {
    this.reportService.setIsLoadingValue = true;
    const payload = {
      reportId: this.reportId,
      templateId: reportPage?._id || reportPage?.id,
      pageIndex: this.selectedPage.order,
    } as addTemplatePagePayload;

    this.isLoading.set(true);
    this.reportService.addPageTemplate(payload).subscribe({
      next: (res: ReportPage): void => {
        this.report.pages.splice(this.selectedPage.order + 1, 0, res);
      },
      error: (err: CustomError) => {
        this.reportService.setIsLoadingValue = false;
        this.notificationService.error(
          err?.message ?? 'Error adding page.',
          5000
        );
        this.isLoading.set(false);
      },
      complete: (): void => {
        this.isLoading.set(false);
        this.reportService.setIsLoadingValue = false;
        this.selectPage(this.selectedPage.order + 1);
      },
    });
  }

  duplicatePage(pageId: string): void {
    this.reportService.setIsLoadingValue = true;

    this.reportService.duplicatePage(this.reportId, pageId).subscribe({
      next: (res: ReportPage): void => {
        this.report.pages.splice(this.selectedPage.order + 1, 0, res);
      },
      error: (err: CustomError): void => {
        this.reportService.setIsLoadingValue = false;
        this.notificationService.error(
          err?.message ?? 'Error adding page.',
          5000
        );
      },
      complete: (): void => {
        this.reportService.setIsLoadingValue = false;
        this.report.pages[this.selectedPage.order + 1].isCached = true;
        this.selectPage(this.selectedPage.order + 1);
      },
    });
  }

  onSaveTemplate(type: string): void {
    const dialogRef = this.dialog.open(SaveAsTemplateDialogComponent, {
      data: {
        reportId: this.report.id,
        pageId: this.selectedPage.id,
        type,
        orientation: this.report.orientation,
      },
      width: '43vw',
      height: '60vh',
      autoFocus: false,
      closeOnNavigation: true,
    });

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((): void => {
      dialogSub.unsubscribe();
    });
  }

  deletePage(pageId: string): void {
    const deletePageDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('reporting.report_page.delete_page'),
        content: this.translate.instant(
          'reporting.report_page.delete_page_confirmation'
        ),
      },
    });

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      deletePageDialogRef.close();
    });

    deletePageDialogRef.afterClosed().subscribe((result): void => {
      dialogSub.unsubscribe();
      if (result !== true) {
        return;
      }
      this.isLoading.set(true);
      this.reportService.setIsLoadingValue = true;
      this.reportService
        .deletePage(this.reportId, pageId)
        .pipe(retry(3))
        .subscribe({
          next: (res: Report): void => {
            this.report = res;
          },
          error: (err): void => {
            this.notificationService.error(
              err?.message ?? 'Error deleting page.',
              5000
            );
            this.reportService.setIsLoadingValue = false;
            this.isLoading.set(false);
          },
          complete: (): void => {
            this.translate
              .get('notifications.report_page_deleted')
              .subscribe((res: string): void => {
                this.notificationService.success(res, 5000);
              });
            this.reportService.setIsLoadingValue = false;
            this.isLoading.set(false);
            this.selectPage(this.selectedPage.order - 1);
            this.reportPageStore.removePage(pageId);
          },
        });
    });
  }

  onAddWidget(widgetType: string): void {
    if (widgetType === 'widgetTemplate') {
      this.openTemplateLibraryDialog('widget');
    } else {
      this.trackingService.trackEvent(`Add ${widgetType} Widget`);
      this.isEditMode.set(true);
      this.widgetService.cascadeEditMode(true);
      this.widgetService.cascadeAddWidget({
        pageId: this.report.pages[this.selectedPage.order].id,
        widgetType,
      });
    }
  }

  onEditPageSettings(page: ReportPage): void {
    if (this.hasChanges) {
      this.reportService.warnOfUnsavedChanges(
        this.onEditPageSettings.bind(this, page),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.report.pages,
          this.selectedPage,
          this.originalPageState
        ),
        'report builder onEditPageSettings'
      );
    } else {
      const pageSandbox = JSON.parse(JSON.stringify(page));

      const dialogRef: MatDialogRef<ReportPageSettingsComponent> =
        this.dialog.open(ReportPageSettingsComponent, {
          data: {
            theme: this.report.theme,
            page: pageSandbox,
            reportId: this.reportId,
            currentPageIndex: page.order,
          },
          autoFocus: false,
          maxWidth: '100vw',
          width: '85vw',
          height: '90vh',
          position: {
            bottom: '0',
          },
        });

      const dialogSub: Subscription = this.router.events.subscribe((): void => {
        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe((res: ReportPage): void => {
        if (res) {
          this.report.pages[page.order] = res;
          this.report.pages[page.order].isActive = true;
          this.report.pages[page.order].isCached = true;
          this.selectedPage = this.report.pages[page.order];
        }
        dialogSub.unsubscribe();
      });
    }
  }

  onReportSettingUpdate(event: Report): void {
    this.report = event;
    this.report.pages[this.selectedPage.order].isActive = true;
    this.selectedPage = this.report.pages[this.selectedPage.order];
  }

  onShareUpdate(event: Report): void {
    this.report = event;
  }

  saveMode(event: boolean): void {
    this.widgetService.setSaveValue = event;
  }

  onHidePage(page: ReportPage): void {
    const hiddenPages: ReportPage[] = this.report.pages.filter(
      (page: ReportPage) => !page.isVisible
    );

    if (
      hiddenPages.length !== this.report.pages.length - 1 ||
      (hiddenPages.length === this.report.pages.length - 1 && !page.isVisible)
    ) {
      page.isVisible = !page.isVisible;
      if (this.isExternalUser) {
        return;
      }
      this.updateReportPageVisibility(page);
    } else {
      this.notificationService.warning(
        this.translate.instant('notifications.report_page_not_visible'),
        5000
      );
    }
  }

  togglePresentationMode(isPresentationMode: boolean): void {
    this.isPresentationMode.set(isPresentationMode);
    if (this.selectedPage && !this.selectedPage.isVisible) {
      this.selectPage(
        this.report.pages.find((page: ReportPage) => page.isVisible)
          ?.order as number
      );
    }
  }

  updateReportPage(page: ReportPage): void {
    this.reportService.setIsLoadingValue = true;
    this.reportService.updatePage(this.reportId, page.id, page).subscribe({
      next: (): void => {},
      error: (err: CustomError): void => {
        this.reportService.setIsLoadingValue = false;
        this.notificationService.error(err?.message, 5000);
      },
      complete: (): void => {
        this.reportService.setIsLoadingValue = false;
      },
    });
  }

  updateReportPageVisibility(page: ReportPage): void {
    this.reportService.setIsLoadingValue = true;
    this.reportService
      .updatePageVisibility(this.reportId, page.id, Boolean(page.isVisible))
      .subscribe({
        next: (): void => {},
        error: (err: CustomError): void => {
          this.reportService.setIsLoadingValue = false;
          this.notificationService.error(err?.message, 5000);
        },
        complete: (): void => {
          this.reportService.setIsLoadingValue = false;
        },
      });
  }

  handleContextMenuAction(event: IContextMenu): void {
    if (event.type === 'page') {
      switch (event.action) {
        case 'paste':
          this.pasteFromBrowser().then();
          break;
        case 'duplicate':
          this.duplicatePage(this.selectedPage?.id);
          break;
        case 'showHide':
          this.onHidePage(this.selectedPage);
          break;
        case 'delete':
          this.deletePage(this.selectedPage?.id);
          break;
        case 'library':
          this.onSaveTemplate('page');
          break;
      }
    }
  }

  async pasteFromBrowser(): Promise<void> {
    await navigator.permissions
      .query({name: 'clipboard-read' as PermissionName})
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard
            .read()
            .then((items): void => {
              if (
                items?.length > 0 &&
                items[0]?.types?.some((type) => type.startsWith('image/')) &&
                this.isEditMode()
              ) {
                if (items[0]?.types?.length > 0) {
                  items[0]
                    .getType(
                      items[0].types.find((type): boolean =>
                        type.startsWith('image/')
                      ) || ''
                    )
                    .then((blob: Blob): void => {
                      const reader = new FileReader();
                      reader.onload = (event): void => {
                        this.imageToPaste = {
                          imageSrc: event?.target?.result as string,
                          imageFit: 'cover',
                          fileName: 'file-from-web-paste.jpeg',
                        };
                        this.addWidgetOnPaste(
                          'image',
                          'paste',
                          this.imageToPaste
                        );
                      };
                      reader.readAsDataURL(blob);
                    });
                }
              }
            })
            .catch((): void => {
              this.notificationService.error(
                this.translate.instant('context_menu.failed_to_read_clipboard'),
                5000
              );
            });
        } else {
          this.notificationService.warning(
            this.translate.instant('context_menu.clipboard_not_access'),
            5000
          );
        }
      })
      .catch((): void => {
        console.error(
          this.translate.instant('context_menu.error_read_clipboard')
        );
      });
  }

  async onPaste(event: ClipboardEvent): Promise<void> {
    if (!this.isEditMode() || this.isExternalUser) {
      return;
    }

    this.imageToPaste = null; // Reset the image to paste

    await this.pasteFromBrowser();

    if (this.imageToPaste !== null) {
      return;
    }
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const items: DataTransferItemList = clipboardData.items;

      if (items.length > 0 && items[0].type.indexOf('image') !== -1) {
        const blob = items[0].getAsFile();

        if (blob) {
          const reader = new FileReader();

          reader.onload = (event: ProgressEvent<FileReader>): void => {
            if (event.target) {
              this.imageToPaste = {
                imageSrc: event.target.result as string,
                imageFit: 'cover',
                fileName: 'file-from-paste.jpeg',
              };
              this.addWidgetOnPaste('image', 'paste', this.imageToPaste);
            }
          };

          reader.readAsDataURL(blob);
        }
      }
    }
  }

  private addWidgetOnPaste(
    widgetType: string,
    origin: string,
    data: object
  ): void {
    this.trackingService.trackEvent(`Add ${widgetType} Widget`);
    this.isEditMode.set(true);
    this.widgetService.cascadeEditMode(true);
    this.widgetService.cascadeAddWidget({
      pageId: this.report.pages[this.selectedPage.order].id,
      widgetType,
      origin,
      data,
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.widgetService.cascadeEditMode(false);
    this.isEditMode.set(false);
  }
}
