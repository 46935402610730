<section class="w-100" id="widgetRichText" [ngClass]="{'h-100': !widget.title}">
  @if (widget.widgetType === "dynamictext" || widget.widgetType === "text") {
    <div
      [innerHTML]="htmlTextToDisplay"
      [ngStyle]="{'color': theme.bodyColor}"
      [ngClass]="{'is-mobile': isMobile, 'h-100': !widget.title}"
      class="d-flex flex-column justify-content-center w-100 rich-text-content"
    ></div>
  }
</section>
