import {Component, Input, OnInit} from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-report-notification',
    templateUrl: './report-notification.component.html',
    styleUrls: ['./report-notification.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class ReportNotificationComponent implements OnInit {
  // Inputs / Outputs
  @Input() status: string;
  @Input() messages: {type: string; message: string}[];

  constructor() {
    //
  }

  ngOnInit(): void {
    //
  }
}
