@if (showPreviewOverlay) {
  <div class="py-3">
    <!-- Preview Title -->
    <span
      [ngStyle]="{'color': theme.bodyColor}"
      class="d-flex align-items-center justify-content-center title-preview"
    >
      {{ "reporting.widget_editor.preview_description" | translate }}
      <button
        [ngStyle]="{'color': theme.titleColor}"
        (click)="previewData()"
        class="wsm-btn wsm-btn-link ms-1"
      >
        {{ "reporting.widget_editor.click_here_button" | translate }}</button
      >.
    </span>
  </div>
}

@if (showTableMessage()) {
  <div
    class="wsm-alert-message d-flex flex-row align-items-center animate__animated"
    id="table-preview-message"
  >
    <div class="me-3">
      <i class="fa-regular fa-triangle-exclamation"></i>
    </div>
    <span>{{ "reporting.widget_editor.table_columns_pdf" | translate }}</span>
    <div class="ms-1">
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
        (click)="onCloseMessage()"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
  </div>
}

<div
  [ngStyle]="{
    'width': width,
    'height': height,
    'background-color': theme.pageColor,
  }"
  [ngClass]="{'is-dirty': showPreviewOverlay}"
  class="widget-preview-content"
>
  <!-- Widget Preview -->
  <app-widget
    [pageTheme]="pageTheme"
    [page]="page"
    [reportId]="reportId"
    [reportTheme]="reportTheme"
    [widgetStyleForm]="widgetStyleForm?.valueChanges | async"
    [widget]="widget"
    [isFromEditor]="true"
    (widgetSortChanged)="onWidgetSortChanged($event)"
  />
</div>
