<div class="d-flex">
  <div class="w-100">
    <div class="wsm-input-group-prepend w-100">
      <i class="fa-regular fa-magnifying-glass"></i>
      <input
        #filterInput
        type="text"
        class="wsm-input w-100"
        placeholder="{{
          'company_selection.section.specific.placeholder.company_name'
            | translate
        }}"
      />
    </div>
  </div>
</div>
<div class="position-relative">
  <div class="table-container mt-3 pt-3">
    <div class="d-flex gap-3 align-items-center">
      <span class="wsm-text-white">
        {{
          (clientPills.length > 0
            ? "company_selection.section.specific.clients_selected"
            : "company_selection.section.specific.not_clients_selected"
          ) | translate: {clients: clientPills.length}
        }}
      </span>
      @if (clientPills && clientPills.length > 0) {
        <mat-chip-listbox #companiesChipList>
          @for (client of clientPills; track client) {
            <mat-chip-option class="wsm-mat-chip">
              {{ client.name }}
              <mat-icon (click)="removeClientChip(client)" matChipRemove
                >close</mat-icon
              >
            </mat-chip-option>
          }
        </mat-chip-listbox>
      }
    </div>
    <div class="table-content pt-3">
      <table
        [hidden]="dataSource.data.length === 0"
        mat-table
        matSort
        [dataSource]="dataSource"
        class="table table-borderless wsm-table-header-border"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selectClient(row._id) : null"
              [checked]="selectionClient.isSelected(row)"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
            {{ headerLabel[1] }}
          </th>
          <td mat-cell *matCellDef="let client">{{ client?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="owner">
            {{ headerLabel[2] }}
          </th>
          <td mat-cell *matCellDef="let client">
            {{ client?.ownerData?.name }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
            {{ "company.company_list.table_data.status" | translate }}
          </th>
          <td mat-cell *matCellDef="let client">
            <small
              class="wsm-tag w-50"
              [ngClass]="client.status === 'ACTIVE' ? 'success' : 'inactive'"
            >
              {{ client.status | titlecase }}
            </small>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [hidden]="dataSource.data.length === 0"
        #paginator
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        [length]="listCounter"
      ></mat-paginator>
      @if (dataSource.data.length === 0) {
        <span class="wsm-alert-message d-flex w-100">
          {{ "company_selection.section.specific.no_client_found" | translate }}
        </span>
      }
      <app-loading [isTable]="true" [generalLoading]="false"></app-loading>
    </div>
  </div>
  @if (loading) {
    <div class="loader-container parent-overlay">
      <div class="spinner-ripple">
        <div class="circle">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  }
</div>
