import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {NotificationService, ReportService} from 'src/app/core/services';
import {ReportPage} from 'src/app/shared/models';
import { NgClass } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { EditableTitleComponent } from '../editable-title/editable-title.component';
import { ConnectionLogosComponent } from '../connection-logos/connection-logos.component';
import { TruncatePipe } from '../../pipes/truncate-string.pipe';

@Component({
    selector: 'app-navigator-card',
    templateUrl: './navigator-card.component.html',
    styleUrls: ['./navigator-card.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        MatProgressBar,
        EditableTitleComponent,
        ConnectionLogosComponent,
        TruncatePipe,
    ],
})
export class NavigatorCardComponent implements OnInit, OnChanges {
  reportService = inject(ReportService);
  notificationService = inject(NotificationService);
  translate = inject(TranslateService);

  // Inputs / Outputs
  @Input() page: ReportPage;
  @Input() isEditMode: boolean;
  @Input() index: number;
  @Input() reportId: string;
  @Input() canEditInline: boolean;

  // Properties
  public subs: Subscription = new Subscription();

  // State
  public isReportLoading: boolean;
  public hasChanges: boolean;
  updatingName: boolean;
  isPageActive: boolean;

  ngOnInit(): void {
    this.subs.add(
      this.reportService.isReportLoading$.subscribe((res: boolean): void => {
        this.isReportLoading = Boolean(res);
      })
    );

    // Has Changes Subscription
    this.subs.add(
      this.reportService.hasChanges$.subscribe(
        (res: {hasChanges: boolean; skipPageState?: boolean}): void => {
          this.hasChanges = res.hasChanges;
        }
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.page = changes.page.currentValue;
      this.isPageActive = Boolean(this.page.isActive);
    }
  }

  onTitleReportChange(event: string): void {
    this.updatingName = true;
    const newPage = {...this.page, name: event} as ReportPage;
    this.reportService
      .updatePage(this.reportId, this.page.id, newPage)
      .subscribe({
        next: (res: ReportPage): void => {
          if (res) {
            this.page.name = res.name;
            this.updatingName = false;
          }
        },
        error: (err): void => {
          this.notificationService.error(err.error.error_description, 5000);
          this.updatingName = false;
        },
        complete: (): void => {
          this.translate
            .get('notifications.report_page_updated')
            .subscribe((res: string) => {
              this.notificationService.success(res, 5000);
            });
        },
      });
  }
}
