import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {MobileService} from 'src/app/core/services';
import {ReportTheme, ReportPage, Widget} from '../../../models';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { NgStyle, NgClass } from '@angular/common';
import { WidgetComponent } from '../../../components/widgets/widget/widget.component';

@Component({
    selector: 'app-mobile-grid',
    templateUrl: './mobile-grid.component.html',
    styleUrls: ['./mobile-grid.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        WidgetComponent,
    ],
})
export class MobileGridComponent implements OnInit, OnChanges, OnDestroy {
  //Inputs/Outputs
  @Input() theme: ReportTheme;
  @Input() isEditMode: boolean;
  @Input() page: ReportPage;
  @Input() pages: Array<ReportPage>;
  @Input() reportId: string;
  @Input() isLoading: boolean;
  @Input() isPrint: boolean;

  //Properties
  initialized = false;
  gridSquareSize = 36;
  gridItems: Widget[] = [];
  columnSpanWidgets: Widget[] = [];
  subs: Subscription = new Subscription();
  oneColumnWidgets: string[] = [
    'scorecard',
    'dynamictext',
    'current-goal',
    'gauge',
    'funnel',
    'pie2d',
    'msbar2d',
    'biggestchanges',
    'doughnut2d',
  ];

  //State Variables
  mobileStatus: {isMobile: boolean; largeMobile: boolean} = {
    isMobile: false,
    largeMobile: false,
  };

  constructor(
    private mobileService: MobileService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.mobileStatus.isMobile = true;
    this.subs.add(
      this.mobileService.mobileWindow$.subscribe((res) => {
        this.mobileStatus.largeMobile = res === 'large';
        this.setWidgetPosition();
      })
    );
    this.initialized = true;
    this.setWidgetPosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) {
      return;
    }
    if (changes.page) {
      this.gridItems = [];
      this.setWidgetPosition();
    }
  }

  setWidgetPosition(): void {
    this.columnSpanWidgets = [];
    if (this.page) {
      if (this.page.widgets?.length === 0) {
        this.addEmptyWidget();
      } else if (
        this.page.widgets?.every((w: Widget) => typeof w === 'object')
      ) {
        this.page.widgets?.forEach((widget: Widget) => {
          if (widget.widgetType !== 'container') {
            widget.position.columnSpan = this.assignColumnSpan(widget);
            this.columnSpanWidgets.push(widget);
          }
        });
        this.addWidgetsToGrid();
      }
    }
  }

  addEmptyWidget(): void {
    this.gridItems = [];
    const emptyWidget: Widget = {
      id: 'dummy',
      pageId: this.page.id,
      reportId: this.reportId,
      widgetType: 'text',
      title: '',
      isEmpty: true,
      theme: {alignItems: 'center', direction: 'column'},
      textData: {
        innerHtml: this.translate.instant(
          'reporting.report_grid.add_widget_info_mobile'
        ),
      },
      position: {y: 0, x: 0, width: 32, height: 18},
    };
    emptyWidget.position.columnSpan = this.assignColumnSpan(emptyWidget);
    this.gridItems.push(emptyWidget);
  }

  addWidgetsToGrid(): void {
    this.gridItems = [];

    this.columnSpanWidgets.forEach((widget: Widget, index) => {
      if (this.mobileStatus.largeMobile) {
        widget.position.columnSpan = this.isWidgetAloneInRow(
          this.columnSpanWidgets,
          index,
          2
        )
          ? 2
          : widget.position.columnSpan;
      }
      this.gridItems.push(widget);
    });
  }

  assignColumnSpan(widget: Widget): number {
    if (this.mobileStatus.largeMobile) {
      if (
        !this.oneColumnWidgets.includes(widget.chartType || widget.widgetType)
      ) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  isWidgetAloneInRow(
    widgets: Widget[],
    widgetIndex: number,
    columnCount: number
  ): boolean {
    const columnIndex =
      widgets
        .slice(0, widgetIndex)
        .reduce((acc, cur) => acc + cur.position.columnSpan!, 0) % columnCount;
    const nextWidgetIndex = widgetIndex + 1;
    const nextColumnIndex =
      widgets[nextWidgetIndex]?.position.columnSpan === 2
        ? 0
        : widgets
            .slice(0, nextWidgetIndex)
            .reduce((acc, cur) => acc + cur.position.columnSpan!, 0) %
          columnCount;
    return columnIndex === 0 && nextColumnIndex === 0;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
