import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {lastValueFrom, Observable} from 'rxjs';

import {
  Connection,
  Filter,
  IPossibleValueParams,
  ReportFilter,
  ReportPage,
  Response,
} from 'src/app/shared/models';
import {
  NotificationService,
  ReportService,
  DialogService,
  PossibleValuesService,
} from '../../../core/services';
import {checkIfArray} from '../../helpers';
import {FilterLogicComponent} from '../../dialogs';
import {NgClass} from '@angular/common';
import {MatChip, MatChipRemove} from '@angular/material/chips';
import {
  MatMenuTrigger,
  MatMenu,
  MatMenuContent,
  MatMenuItem,
} from '@angular/material/menu';
import {FilterOperatorComponent} from '../filter-operator/filter-operator.component';
import {MatIcon} from '@angular/material/icon';
import {FilterSelectionComponent} from '../filter-selection/filter-selection.component';
import {JoinStringArrayPipe} from '../../pipes/join-string-array.pipe';

@Component({
  selector: 'app-report-page-filters',
  templateUrl: './report-page-filters.component.html',
  styleUrls: ['./report-page-filters.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatChip,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    FilterOperatorComponent,
    MatIcon,
    MatChipRemove,
    FilterSelectionComponent,
    TranslateModule,
    JoinStringArrayPipe,
  ],
})
export class ReportPageFiltersComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() page: ReportPage;
  @Input() isLoading: boolean;
  @Input() origin: string;
  @Input() isMobile: boolean;
  @Output() bulkDeleteWidgets = new EventEmitter();

  // Properties
  isArray = checkIfArray;
  selectedFilter: Filter;
  public pageConnections: string[] = [];

  constructor(
    private notificationService: NotificationService,
    private reportService: ReportService,
    private dialogService: DialogService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private possibleValuesService: PossibleValuesService
  ) {}

  ngOnInit(): void {
    this.getPageConnections();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.page = changes.page.currentValue;
      this.getPageConnections();
      this.getPossibleValuesOnFilters().then();
    }
  }

  private getPageConnections(): void {
    if (this.page && this.page?.connections) {
      this.pageConnections = this.page.connections?.map(
        (c: Connection) => c.id!
      );
    }
  }

  addFilterLogic(): void {
    const onCloseActions = (res): void => {
      if (res) {
        this.page.filters = res;
        if (!this.isMobile) {
          this.reportService.setChangeValue = {
            hasChanges: true,
            from: 'filterlogic',
          };
        }
        this.bulkDeleteWidgets.emit(true);
      }
    };

    this.dialogService.openDialog(
      FilterLogicComponent,
      {
        data: {
          filters:
            this.page?.filters && this.page?.filters?.length >= 0
              ? [...this.page.filters]
              : [],
          availableFilters: this.page.availableFilters && [
            ...this.page.availableFilters,
          ],
          isMobile: this.isMobile,
          isPage: true,
          connections: this.pageConnections || [],
        },
        hasBackdrop: true,
        width: '800px',
      },
      onCloseActions.bind(this)
    );
  }

  onFilterApplied(data: any, index: number, subIndex?): void {
    const newFilterField = {} as ReportFilter;

    if (!this.page.filters || this.page.filters.length === 0) {
      this.page.filters = [];
    }

    this.page.availableFilters?.forEach((f: Filter) => {
      if (f.name === data?.filterField?.name || f.name === data?.filterField) {
        newFilterField.fieldId = null;
        newFilterField.fieldName = f.name;
        newFilterField.operator = data?.filterOperator;
        if (
          f.hasPossibleValues ||
          (f.possibleValues && f.possibleValues.length > 0)
        ) {
          if (typeof data.filterValue === 'string') {
            newFilterField.value = data.filterValue;
          }
          if (typeof data.filterValue !== 'string') {
            newFilterField.labels = data?.filterValue;
          }
        }

        if (!f.hasPossibleValues) {
          newFilterField.value = data.filterValue;
        }

        if (
          f.hasPossibleValues &&
          f.possibleValues &&
          f.possibleValues.length === 0
        ) {
          newFilterField.value = data?.filterValue;
        }

        if ('filterLevel' in data) {
          newFilterField.isPropagable = data?.filterLevel;
        }
      }
    });

    if (newFilterField.fieldId !== undefined) {
      if (data.filterIsNew) {
        this.page.filters = [...this.page.filters, newFilterField];
        this.notificationService.success(
          this.translate.instant('notifications.filter_applied'),
          5000
        );
      } else {
        if (index >= 0) {
          const newPageFilter: ReportFilter[] = [...this.page.filters];

          if (subIndex >= 0) {
            newPageFilter[index].expressions![subIndex] = newFilterField;
          } else {
            newPageFilter[index] = newFilterField;
          }

          this.page.filters = newPageFilter;
          this.notificationService.success(
            this.translate.instant('notifications.filter_updated'),
            5000
          );
        }
      }

      this.getPossibleValuesOnFilters().then();
    }

    this.bulkDeleteWidgets.emit(true);
  }

  removeFilter(
    filter: ReportFilter,
    index: number,
    orFilter?: ReportFilter
  ): void {
    if (filter) {
      if (
        filter?.expressions &&
        orFilter &&
        this.page?.filters &&
        this.page.filters[index]?.expressions
      ) {
        /* Remove 'or' filters */
        this.page.filters = this.page.filters.map((filter, i) =>
          i === index
            ? {
                ...filter,
                expressions: filter.expressions?.filter(
                  (fl: ReportFilter) => fl.fieldName !== orFilter.fieldName
                ),
              }
            : filter
        );

        this.reportService.setChangeValue = {
          hasChanges: true,
          skipPageState: true,
          from: 'removefilter',
        };
      } else {
        const indexToRemove = this.page?.filters?.indexOf(filter);

        if (typeof indexToRemove === 'number') {
          this.page.filters = (this.page?.filters || []).filter(
            (_, index): boolean => index !== indexToRemove
          );
        }
      }

      /* Remove empty arrays after 'or' delete */
      this.page.filters = this.page.filters?.filter(
        (item: ReportFilter) =>
          item.operator !== 'or' ||
          (item.operator === 'or' &&
            item?.expressions &&
            item?.expressions?.length > 0)
      );
      this.bulkDeleteWidgets.emit();
    }
  }

  private async getPossibleValuesOnFilters(): Promise<void> {
    if (!this.page || !this.page.availableFilters) {
      return;
    }

    /* Possible values with hasPossibleValues = true */
    const filtersWithPossibleValues: Filter[] =
      this.page.availableFilters.filter(
        (filter: Filter): boolean => filter.hasPossibleValues === true
      );

    /* Possible values in page and are in hasPossibleValues = true */
    const matchingFilters: ReportFilter[] = (this.page.filters || []).filter(
      (filter: ReportFilter) =>
        filtersWithPossibleValues.some(
          (possibleFilter: Filter): boolean =>
            possibleFilter.name === filter.fieldName
        )
    );

    for (const matchingFilter of matchingFilters) {
      const params: IPossibleValueParams = {
        name: matchingFilter.fieldName,
        connections: this.pageConnections,
      };

      if (this.pageConnections?.length > 0) {
        const values$: Observable<Response> =
          this.possibleValuesService.getPossiblesValuesByName(params);
        await lastValueFrom(values$).then((res: Response): void => {
          /* Update available filters in page */
          this.page.availableFilters = this.page.availableFilters?.map(
            (filter: Filter) => {
              if (filter.name === matchingFilter.fieldName) {
                filter = {
                  ...filter,
                  possibleValues: res.data,
                };
              }
              return filter;
            }
          );
        });
      }
    }
  }
}
