<div class="position-relative d-flex flex-column h-100">
  <header [ngClass]="{'mb-4': !data.isMobile}">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">
        {{ "shared.dialogs.data_queue.title" | translate }}
      </h1>

      <button
        (click)="onClose()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    @if (!data.isMobile) {
      <p>
        {{ "shared.dialogs.data_queue.description" | translate }}
      </p>
    }
  </header>

  <mat-dialog-content class="data-queue-details" [ngClass]="{'m-0': isLoading}">
    <!-- Tabs -->
    <app-tabs
      [hidden]="connectionDataSource.data.length === 0 || reportInQueue"
      [selectedIndex]="0"
      [disableDrag]="true"
      [isMobile]="data.isMobile"
      (tabSelected)="selectConnection($event)"
    >
      @for (connection of connections; track connection; let i = $index) {
        <app-tab-item [keyId]="connection.id!" [index]="i">
          <app-tab-label>
            {{ connection.name }}
            @if (connection.error || connection.inProgressTotal) {
              <div
                class="notification-bubble"
                [ngClass]="{
                  'warning': connection.error,
                  'counter': !connection.error,
                }"
              >
                @if (connection.inProgressTotal) {
                  <span>{{ connection.inProgressTotal }}</span>
                }
              </div>
            }
          </app-tab-label>
        </app-tab-item>
      }
    </app-tabs>

    <!-- Desktop -->
    @if (!data.isMobile || data.largeMobile) {
      <div [hidden]="connectionDataSource.data.length === 0 || reportInQueue">
        <div class="table-container pt-3">
          <div class="table-content">
            <table
              #connectionsTable
              mat-table
              [dataSource]="connectionDataSource"
              matSort
              class="table table-borderless wsm-table-header-border"
            >
              <ng-container matColumnDef="clientName">
                <th mat-header-cell *matHeaderCellDef>
                  {{ "shared.dialogs.data_queue.client_name" | translate }}
                </th>
                <td mat-cell *matCellDef="let serviceAccount">
                  <a [routerLink]="'/clients/' + serviceAccount.clientId">
                    {{ serviceAccount.clientName }}
                  </a>
                </td>
              </ng-container>
              @if (selectedConnection().tableData) {
                @for (column of selectedConnection().tableData; track $index) {
                  <ng-container [matColumnDef]="column.key">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ column.label }}
                    </th>
                    <td mat-cell *matCellDef="let serviceAccount">
                      {{ serviceAccount[column.key] }}
                    </td>
                  </ng-container>
                }
              }
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                  {{
                    "shared.dialogs.manage_credentials.services_table.status"
                      | translate
                  }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let serviceAccount"
                  class="text-center"
                >
                  <div
                    class="status-indicator p-1"
                    [class]="statusCssClass[serviceAccount.status]"
                    [satPopoverAnchor]="popInfo"
                    (mouseenter)="popInfo.open()"
                    (mouseleave)="onLeaveStatus(popInfo)"
                  >
                    @if (statusLabelTranslations[serviceAccount.status]) {
                      {{
                        statusLabelTranslations[serviceAccount.status]
                          | translate
                      }}
                    }
                  </div>
                  <!-- State Info -->
                  <sat-popover
                    #popInfo
                    horizontalAlign="center"
                    verticalAlign="below"
                  >
                    <div
                      class="connection-wrapper"
                      (mouseenter)="isPopInfoActive = true"
                      (mouseleave)="popInfo.close(); isPopInfoActive = false"
                    >
                      <div class="popover-name-arrow"></div>
                      <div>
                        @if (
                          statusCssClass[serviceAccount.status] !== "error"
                        ) {
                          <span>
                            @if (
                              statusTooltipMessageTranslation[
                                serviceAccount.status
                              ]
                            ) {
                              {{
                                statusTooltipMessageTranslation[
                                  serviceAccount.status
                                ][0] | translate
                              }}
                            }
                          </span>
                        }
                        @if (
                          statusCssClass[serviceAccount.status] === "error"
                        ) {
                          @if (serviceAccount.status === "ACCOUNT_UNLINKED") {
                            <span>
                              @if (
                                statusTooltipMessageTranslation[
                                  serviceAccount.status
                                ]
                              ) {
                                {{
                                  statusTooltipMessageTranslation[
                                    serviceAccount.status
                                  ][0] | translate
                                }}
                              }
                            </span>
                          }
                          @if (serviceAccount.status !== "ACCOUNT_UNLINKED") {
                            <p class="ps-3">
                              {{
                                statusTooltipMessageTranslation[
                                  serviceAccount.status
                                ][0] | translate
                              }}&nbsp;
                              <a
                                [routerLink]="['/connections']"
                                [queryParams]="{'sign-up': true}"
                                [fragment]="serviceAccount.type"
                                >{{
                                  statusTooltipMessageTranslation[
                                    serviceAccount.status
                                  ][1] | translate
                                }}</a
                              >&nbsp;
                              {{
                                statusTooltipMessageTranslation[
                                  serviceAccount.status
                                ][2] | translate
                              }}
                            </p>
                          }
                        }
                      </div>
                    </div>
                  </sat-popover>
                </td>
              </ng-container>
              <ng-container matColumnDef="lastUpdated">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                  {{
                    "shared.dialogs.manage_credentials.services_table.last_updated"
                      | translate
                  }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let serviceAccount"
                  class="text-center"
                >
                  {{
                    serviceAccount?.lastUpdated
                      ? (serviceAccount.lastUpdated | dateAsAgo)
                      : "--"
                  }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
            </table>

            @if (connectionDataSource.data.length === 0) {
              <div class="wsm-alert-message mt-3">
                <strong>
                  {{
                    "shared.dialogs.manage_credentials.services_table.no_services"
                      | translate
                  }}
                </strong>
              </div>
            }

            <mat-paginator
              [pageSizeOptions]="pageSizeOptions"
              [showFirstLastButtons]="true"
              [pageSize]="5"
              [length]="connectionDataSource.data.length"
            >
            </mat-paginator>
          </div>
        </div>
      </div>

      @if (reportInQueue) {
        <div class="wsm-alert-message">
          <i class="fa-regular fa-triangle-exclamation warning-icon me-2"></i>
          <span>{{
            "shared.dialogs.data_queue.data_in_queue" | translate
          }}</span>
        </div>
      }
    }

    <!-- Mobile -->
    @if (data.isMobile && !data.largeMobile) {
      <app-card-carousel
        [cardData]="cardData"
        [indicatorsOn]="true"
        [indicatorNumber]="3"
      >
      </app-card-carousel>
    }

    @if (clients?.length !== 0 && !reportInQueue) {
      <div class="wsm-alert-message">
        <i class="fa-regular fa-triangle-exclamation warning-icon me-2"></i>
        <span>{{ "shared.dialogs.data_queue.no_accounts" | translate }}</span>
        <div class="ms-3 mt-3">
          <h4 class="h4">
            {{ "shared.dialogs.data_queue.empty_clients" | translate }}
          </h4>
          <ul id="empty-clients-list">
            @for (client of clients; track client) {
              <a [routerLink]="'/clients/' + client.clientId">
                <li>{{ client.clientName }}</li>
              </a>
            }
          </ul>
        </div>
      </div>
    }
  </mat-dialog-content>

  <app-loading [generalLoading]="isLoading"></app-loading>
</div>
