<div class="d-flex align-items-center justify-content-between mb-1">
  @if (!isMetricLabel) {
    <label>{{
      "reporting.widget_editor.style_form.widget_title" | translate
    }}</label>
  }
  @if (isMetricLabel) {
    <label>{{
      "reporting.widget_editor.style_form.widget_metric_label" | translate
    }}</label>
  }

  <div>
    <!-- Bold -->
    <button
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon custom-tiny me-1"
      type="button"
      (click)="setTitleStyle('bold')"
      [ngClass]="{
        'active-style': widgetStyleForm.get('labelBold')?.value === true
      }"
    >
      <i class="fa-regular fa-bold fa-xs"></i>
    </button>
    <!-- Italic -->
    <button
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon custom-tiny me-1"
      type="button"
      (click)="setTitleStyle('italic')"
      [ngClass]="{
        'active-style': widgetStyleForm.get('labelItalic')?.value === true
      }"
    >
      <i class="fa-regular fa-italic fa-xs"></i>
    </button>

    <!-- Align -->
    @if (
      widget.widgetType !== "title" &&
      widget.widgetType !== "scorecard" &&
      widget.widgetType !== "ranking"
    ) {
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon custom-tiny"
        type="button"
        [matMenuTriggerFor]="alignMenu"
        [ngClass]="{
          'active-style': widgetStyleForm.get('labelAlign')?.value !== null
        }"
      >
        @switch (widgetStyleForm.get("labelAlign")?.value) {
          @case ("left") {
            <i class="fa-regular fa-align-left"></i>
          }
          @case ("center") {
            <i class="fa-regular fa-align-center"></i>
          }
          @case ("right") {
            <i class="fa-regular fa-align-right"></i>
          }
        }
      </button>
    }

    <mat-menu #alignMenu class="align-menu" xPosition="before">
      @if (widgetStyleForm.get("labelAlign")?.value !== "left") {
        <menu mat-menu-item (click)="setTitleStyle('left')">
          <i class="fa-regular fa-align-left"></i>
        </menu>
      }
      @if (widgetStyleForm.get("labelAlign")?.value !== "center") {
        <menu mat-menu-item (click)="setTitleStyle('center')">
          <i class="fa-regular fa-align-center"></i>
        </menu>
      }
      @if (widgetStyleForm.get("labelAlign")?.value !== "right") {
        <menu mat-menu-item (click)="setTitleStyle('right')">
          <i class="fa-regular fa-align-right"></i>
        </menu>
      }
    </mat-menu>
  </div>
</div>
