<header class="mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h3 m-0">
      {{ "shared.dialogs.dynamic_metric.title" | translate }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content>
  <form [formGroup]="dataForm">
    <div class="row mx-0 p-2">
      <!-- Metric -->
      <div class="col-12 mb-3">
        <label class="d-inline-block" for="metric">
          {{ "shared.dialogs.dynamic_metric.metric_label" | translate }}&nbsp;
          <span class="required-mark">*</span>
        </label>
        <input
          #metricSelect
          type="text"
          class="wsm-input w-100"
          [class.border-danger]="
            dataForm.get('metric')?.errors?.required &&
            dataForm.get('metric')?.touched
          "
          id="metric"
          placeholder="{{
            'shared.dialogs.dynamic_metric.dataset_placeholder' | translate
          }}"
          [formControl]="fieldFilterCtrl"
          [matAutocomplete]="filterMetrics"
        />
        <mat-autocomplete
          #filterMetrics="matAutocomplete"
          (optionSelected)="onMetricChange($event)"
          [displayWith]="displayMetric"
          autoActiveFirstOption
          class="custom-widget-autocomplete"
        >
          @for (metric of filteredMetrics | async; track metric) {
            <mat-option
              [value]="metric"
              [disabled]="metric.isDisabled"
              [matTooltip]="scopeService.showTooltip(metric)"
              matTooltipPosition="right"
              [title]="metric.name"
            >
              <span class="d-block mb-1">{{ metric.name }}</span>
              <app-connection-logos
                [connections]="metric?.connections!"
                [isCustom]="metric?.isCustom"
                [type]="'widget'"
              >
              </app-connection-logos>
            </mat-option>
          }
        </mat-autocomplete>
        <div
          [hidden]="
            !dataForm.get('metric')?.errors?.required ||
            !dataForm.get('metric')?.touched
          "
          class="wsm-text-danger wsm-caption"
        >
          {{ "reporting.widget_editor.data_form.label_required" | translate }}
        </div>
      </div>

      <!-- Aggregation -->
      <div class="col-12 mb-3">
        <label for="measureAggregation">
          {{
            "shared.dialogs.dynamic_metric.aggregation_label" | translate
          }}&nbsp; <span class="required-mark">*</span></label
        >
        <mat-select
          #aggregation
          [class.border-danger]="
            dataForm.get('aggregation')?.errors?.required &&
            dataForm.get('aggregation')?.touched
          "
          [panelClass]="'wsm-custom-select'"
          class="wsm-input w-100"
          [disableOptionCentering]="true"
          formControlName="aggregation"
          id="measureAggregation"
          placeholder="{{
            'shared.dialogs.dynamic_metric.aggregation_placeholder' | translate
          }}"
        >
          <mat-option [value]="'sum'">Sum</mat-option>
          <mat-option [value]="'avg'">Average</mat-option>
          <mat-option [value]="'count'">Count</mat-option>
          <mat-option [value]="'distinct'">Count Unique</mat-option>
          <mat-option [value]="'max'">Max</mat-option>
          <mat-option [value]="'min'">Min</mat-option>
        </mat-select>
        <div
          [hidden]="
            !dataForm.get('aggregation')?.errors?.required ||
            !dataForm.get('aggregation')?.touched
          "
          class="wsm-text-danger wsm-caption"
        >
          {{ "reporting.widget_editor.data_form.label_required" | translate }}
        </div>
      </div>

      <!-- Value to Show -->
      @if (
        widget.dateRange?.previous || data?.reportPage?.dateRange?.previous
      ) {
        <div class="col-12 mb-3">
          <label class="d-inline-block" for="valueType">
            {{ "shared.dialogs.dynamic_metric.value_show_label" | translate }}
          </label>
          <mat-select
            #valueToShow
            [panelClass]="'wsm-custom-select'"
            class="wsm-input w-100"
            [disableOptionCentering]="true"
            formControlName="valueType"
            id="valueType"
            placeholder="{{
              'shared.dialogs.dynamic_metric.value_show_placeholder' | translate
            }}"
          >
            <mat-option [value]="'current'">Current</mat-option>
            @if (
              widget.dateRange?.previous ||
              data?.reportPage?.dateRange?.previous
            ) {
              <mat-option [value]="'previous'">Previous</mat-option>
              <mat-option [value]="'delta'">Delta</mat-option>
              <mat-option [value]="'deltaPercent'">Delta Percent</mat-option>
            }
          </mat-select>
        </div>
      }
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
  <button
    (click)="onSave()"
    [disabled]="dataForm.invalid || widget?.dataset?.length! <= 0"
    class="wsm-btn wsm-btn-primary my-2"
  >
    {{ "shared.dialogs.dynamic_metric.save_button" | translate }}
  </button>
</mat-dialog-actions>
