@if (showAdNotification || showConnectionNotification) {
  <app-top-notification
    [message]="popupMessage"
    [isMobile]="isMobile"
    [onlyButton]="showConnectionNotification"
    (closeNotification)="onCloseNotification($event)"
  />
}

@if (!hideSidenav) {
  <app-profile-navbar [burgerMenu]="showBurgerMenu"></app-profile-navbar>
}

<section
  class="wsm-body"
  [ngClass]="{
    'container-fluid': !hideSidenav || isShareReport,
    'body-sidenav': !hideSidenav,
    'body-md-screen': collapsed && screenWidth <= 768 && screenWidth > 0,
    'burger-menu': showBurgerMenu && !hideSidenav && !isMobile,
    'wsm-mobile-body': isMobile,
    'wsm-mobile-full-body': isMobile && (fromShareableLink || hideMobileSidenav),
  }"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300"
  [infiniteScrollDisabled]="disabledScroll"
  (scrolled)="onScroll()"
  [scrollWindow]="false"
>
  <router-outlet></router-outlet>
</section>

@if (isMobile && !fromShareableLink && !hideMobileSidenav) {
  <app-tab-bar />
}

@if (loading$ | async) {
  <app-loading />
}
