import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {ColorService} from 'src/app/shared/helpers';
import {ReportTheme, Widget, WidgetKeyword} from '../../../../models';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { PopoverContentComponent } from '../../popover-content/popover-content.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-keyword',
    templateUrl: './keyword.component.html',
    styleUrls: ['./keyword.component.scss'],
    standalone: true,
    imports: [
        MatChipListbox,
        MatChipOption,
        SatPopoverModule,
        PopoverContentComponent,
        TranslateModule,
    ],
})
export class KeywordComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() theme: ReportTheme = {};
  @Input() reportTheme: ReportTheme;

  // Properties
  borderColor: string;

  constructor(private colorService: ColorService) {}

  ngOnInit(): void {
    this.setUpKeywords();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.setUpKeywords();
    }
  }

  setUpKeywords(): void {
    const rgbBorderColor = this.colorService.hexToRgb(
      this.theme?.colorPalette![0]
    );
    this.widget?.rankingData?.keywords?.forEach(
      (keyword: WidgetKeyword): void => {
        if (this.widget.metrics && this.widget.metrics?.length !== 0) {
          keyword.data.metricName = this.widget.metrics[0]?.name;
        }
        keyword.borderColor = `rgb(${rgbBorderColor?.r},${rgbBorderColor?.g},${rgbBorderColor?.b},${keyword.weight}) !important`;
      }
    );
  }
}
