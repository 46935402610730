import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {
  BrandingOrder,
  ReportPage,
  ReportTheme,
  Widget,
} from '../../../../models';
import { NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'app-branding',
    templateUrl: './branding.component.html',
    styleUrls: ['./branding.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass],
})
export class BrandingComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: FormGroup = new FormGroup({});
  @Input() page: ReportPage;
  @Input() pages: ReportPage[];
  @Input() theme: ReportTheme = {};
  @Input() isMobile: boolean;

  // Properties
  brandingItemsOrder: BrandingOrder;
  pageNumber: number;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.widget.widgetIcon = this.widget?.widgetIcon || null;

      this.updateBrandingItems(this.widget?.brandingStyle || '');
      this.setPageNumber();
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue !== undefined
    ) {
      if (changes.widgetStyleForm.currentValue.type === 'style') {
        if (changes.widgetStyleForm.currentValue?.brandingStyle) {
          this.updateBrandingItems(
            changes.widgetStyleForm.currentValue.brandingStyle
          );
        }
      }
    }
  }

  private setPageNumber(): void {
    if (this.pages?.length > 0) {
      const index = this.pages.findIndex(
        (page: ReportPage) => page.id === this.page.id
      );
      this.pageNumber = index + 1;
    } else {
      this.pageNumber = this.page?.order + 1;
    }
  }

  private updateBrandingItems(style: string): void {
    const styleMap: {[key: string]: BrandingOrder} = {
      'left': {logo: 1, link: 2, pageNumber: 3},
      'center': {logo: 2, link: 1, pageNumber: 3},
      'right': {logo: 3, link: 2, pageNumber: 1},
    };

    this.brandingItemsOrder = styleMap[style] || styleMap['left'];
  }
}
