<div class="container-fluid">
  @for (category of widgetCategories; track category) {
    <span class="wsm-overline d-block my-2 ms-1 wsm-text-white">
      {{ category.translation | translate }}
    </span>
    <div class="widget-grid">
      @for (type of category.types; track type) {
        <button
          [id]="
            type.type === 'widgetTemplate'
              ? 'btnFromLibraryWidgetToolbars'
              : type.type
          "
          class="item {{ type.state }}"
          [ngClass]="{'dark': isDark, 'light': !isDark}"
          (click)="onWidgetSelect(type.type)"
        >
          <i class="{{ type.icon }} mb-1"></i>
          <small>{{ type.translation | translate }}</small>
        </button>
      }
    </div>
  }
</div>
