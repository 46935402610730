<header class="mb-2">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h2 m-0">
      {{
        (isEdit
          ? "shared.dialogs.save_as_template.edit_template_title"
          : "shared.dialogs.save_as_template.save_template_title"
        ) | translate
      }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
  <p>
    {{
      "shared.dialogs.save_as_template.add_your_label"
        | translate: {type: data.type}
    }}
  </p>
  <p class="mt-3">
    <small class="d-flex wsm-text-grayed">
      {{ "common.fields_required" | translate }}
    </small>
  </p>
</header>

<mat-dialog-content class="template-details">
  <form
    id="template-form"
    [formGroup]="templateForm"
    (ngSubmit)="onSubmit()"
    class="overlay-container"
  >
    <div class="row mx-0 mb-3">
      <div class="col-12 ps-0">
        <label for="name"
          >{{
            "shared.dialogs.save_as_template.template_name_label" | translate
          }}&nbsp;<span class="required-mark">*</span></label
        >
        <input
          id="name"
          type="text"
          class="wsm-input w-100 validate"
          formControlName="name"
          placeholder="{{
            'shared.dialogs.save_as_template.template_name_placeholder'
              | translate
          }}"
          autocomplete="off"
        />
        @if (
          (templateForm.get("name")?.errors?.["required"] ||
            templateForm.get("name")?.errors?.["whitespace"]) &&
          templateForm.get("name")?.dirty
        ) {
          <div class="mt-1" @slideInOutAnimation>
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>
    </div>

    <div class="row mx-0 mb-3">
      <div class="col-12 ps-0">
        <label for="description"
          >{{
            "shared.dialogs.save_as_template.template_description_label"
              | translate
          }}&nbsp;<span class="required-mark">*</span></label
        >
        <textarea
          id="description"
          type="text"
          class="wsm-input w-100 validate"
          rows="5"
          formControlName="description"
          placeholder="{{
            'shared.dialogs.save_as_template.template_description_placeholder'
              | translate
          }}"
          autocomplete="off"
        >
        </textarea>
        @if (
          (templateForm.get("description")?.errors?.["required"] ||
            templateForm.get("name")?.errors?.["whitespace"]) &&
          templateForm.get("description")?.dirty
        ) {
          <div class="mt-1" @slideInOutAnimation>
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>
    </div>

    <div class="row mx-0 mb-3">
      <div class="col-12 position-relative ps-0">
        <label
          >{{
            "shared.dialogs.save_as_template.goals_label" | translate
          }}&nbsp;<span class="required-mark">*</span></label
        >
        <mat-chip-grid
          class="custom-template-label"
          matAutocompleteOrigin
          #origin="matAutocompleteOrigin"
          #chipList
          [ngClass]="{
            'validate':
              templateForm.get('goal')?.errors?.['required'] &&
              templateForm.get('goal')?.dirty
          }"
        >
          @for (goal of goals; track goal) {
            <mat-chip-row class="wsm-tag info">
              {{ goal }}
              <button
                (click)="remove(goal)"
                class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
              >
                <i class="fa-solid fa-close"></i>
              </button>
            </mat-chip-row>
          }
          <input
            [hidden]="goals.length === 3"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
            type="text"
            [matAutocomplete]="auto"
            placeholder="{{
              'shared.dialogs.save_as_template.new_goal_placeholder' | translate
            }}"
            class="wsm-input w-100"
            [matAutocompleteConnectedTo]="origin"
            [formControl]="goalInputControl"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectedGoal($event)"
        >
          @for (goal of filteredGoals | async; track goal) {
            <mat-option [value]="goal">
              {{ goal }}
            </mat-option>
          }
        </mat-autocomplete>
        <p class="labels-warning wsm-text-grayed mt-2">
          {{ "shared.dialogs.save_as_template.goals_label_info" | translate }}
        </p>

        @if (
          templateForm.get("goal")?.errors?.["required"] &&
          templateForm.get("goal")?.dirty
        ) {
          <div class="mt-1" @slideInOutAnimation>
            <span class="invalid-info">
              {{ "common.errors.field_required" | translate }}
            </span>
          </div>
        }
      </div>
    </div>

    <div class="row mx-0 mb-3">
      <div class="col-4 ps-0">
        <label for="language">{{
          "shared.dialogs.save_as_template.language_label" | translate
        }}</label>
        <mat-select
          disableOptionCentering
          formControlName="language"
          class="wsm-input"
          id="language"
          placeholder="{{
            'shared.dialogs.save_as_template.language_placeholder' | translate
          }}"
          [panelClass]="'wsm-custom-select'"
        >
          @for (item of languages; track item) {
            <mat-option [value]="item.value">
              {{ item.translation | translate }}
            </mat-option>
          }
        </mat-select>
      </div>
      @if (data.type && data.type === "report") {}
      <div class="col-4">
        <label for="orientation">{{
          "shared.dialogs.save_as_template.layout_label" | translate
        }}</label>
        <mat-select
          disableOptionCentering
          formControlName="orientation"
          class="wsm-input"
          id="orientation"
          [panelClass]="'wsm-custom-select'"
        >
          <mat-option [value]="'horizontal'">{{
            "shared.dialogs.save_as_template.layout_landscape" | translate
          }}</mat-option>
          <mat-option [value]="'vertical'">{{
            "shared.dialogs.save_as_template.layout_portrait" | translate
          }}</mat-option>
        </mat-select>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if (!isEdit) {
    <button
      id="saveTemplate"
      class="wsm-btn wsm-btn-primary submit-btn"
      form="template-form"
      type="submit"
    >
      @if (!isLoading) {
        <span>{{
          "shared.dialogs.save_as_template.save_template_button" | translate
        }}</span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  }

  @if (isEdit) {
    <button
      class="wsm-btn wsm-btn-primary submit-btn"
      form="template-form"
      type="submit"
    >
      @if (!isLoading) {
        <span>{{
          "shared.dialogs.save_as_template.update_template_button" | translate
        }}</span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  }
</mat-dialog-actions>
