<section class="container-fluid" [ngClass]="{'mt-4': !isMobile()}">
  @if (!isExternalUser() && !isMobile()) {
    <div
      class="row"
      [ngClass]="{'mb-5': countTemplates() === 0, 'mb-1': countTemplates() > 0}"
    >
      <div class="d-flex gap-sm-3 gap-xl-4 nrpt-tpl-bx">
        <button
          (click)="openNewReportModal()"
          data-cy="btn-create-report"
          class="btn btn-new-report"
        >
          <i class="fa-solid fa-circle-plus"></i>
          <span>{{ "reporting.create_report.title_create" | translate }}</span>
        </button>
        <div class="w-x mt-1 overlay-container">
          <div class="d-flex justify-content-between w-100">
            <h5>{{ "reporting.template_gallery.title" | translate }}</h5>
            <button
              (click)="goToLibrary()"
              class="wsm-btn wsm-btn-primary wsm-btn-small mb-2"
            >
              {{ "reporting.template_gallery.go_to_library" | translate }}
            </button>
          </div>
          @if (countTemplates() > 0 && !templateLoading()) {
            <app-template-carousel
              (refreshCarousel)="onRefreshCarousel($event)"
              [from]="'carousel'"
              [templateCards]="templates()"
            ></app-template-carousel>
          } @else {
            <app-cta-container>
              <div class="d-flex flex-column align-items-center mt-3 p-cta">
                <h6>
                  {{ "reporting.template_gallery.cta_message" | translate }}
                </h6>
              </div>
            </app-cta-container>
          }
          @if (templateLoading()) {
            <div class="loader-container">
              <div class="spinner-ripple">
                <div class="circle">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

  <div [ngClass]="{'mt-5': !countTemplates || templateLoading()}" class="row">
    <app-reporting-list [isMobile]="isMobile()"></app-reporting-list>
  </div>
</section>
