import {Component, Input, OnInit} from '@angular/core';

import {ReportTheme, RGBColor, Widget} from '../../../models';
import {ColorService} from '../../../helpers';
import { NgStyle, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-widget-error',
    templateUrl: './widget-error.component.html',
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        TranslateModule,
    ],
})
export class WidgetErrorComponent implements OnInit {
  // Inputs
  @Input() widget: Widget;
  @Input() theme: ReportTheme = {};
  @Input() isMobile: boolean;

  constructor(private colorService: ColorService) {}

  ngOnInit(): void {
    if (this.theme.bodyColor) {
      const rgb: RGBColor | null = this.colorService.hexToRgb(
        this.theme.bodyColor
      );
      this.theme.bgErrorColor = rgb
        ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2)`
        : this.theme.bodyColor;
    }
  }
}
