import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {
  MatDatepickerInputEvent,
  MatDateRangeInput,
  MatStartDate,
  MatEndDate,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSlideToggleChange,
  MatSlideToggle,
} from '@angular/material/slide-toggle';
import moment from 'moment';
import {MatSelect} from '@angular/material/select';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatRadioGroup, MatRadioButton} from '@angular/material/radio';
import {NgClass} from '@angular/common';
import {MatCheckbox} from '@angular/material/checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {DateRange} from 'src/app/shared/models/report/report.model';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface ISelection {
  range: {
    dates?: any;
    relative?: any;
    relativeTo?: any;
    monthRange?: any;
    dateCount?: any;
  };
  compare: {
    dates?: any;
    relative?: any;
  };
  applyToAll?: boolean;
  isCompare?: boolean;
}

@Component({
  selector: 'app-date-picker-menu',
  templateUrl: './date-picker-menu.component.html',
  styleUrls: ['./date-picker-menu.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  standalone: true,
  imports: [
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatOption,
    MatRadioGroup,
    MatRadioButton,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDateRangePicker,
    MatSlideToggle,
    NgClass,
    MatCheckbox,
    TranslateModule,
  ],
})
export class DatePickerMenuComponent implements OnInit, OnDestroy {
  //Inputs / Outputs
  @Input() showClear: boolean;
  @Input() currentOption: any = 'CUSTOM_DATE';
  @Input() compareOption: any = 'PREVIOUS_PERIOD';
  @Input() origin: string;
  @Input() relativeRange: {
    dateCount: number;
    relativeTo: string;
    monthRange?: string;
  };
  @Input() selectedRange: DateRange = {start: undefined, end: undefined};
  @Input() selectedComparisonRange: DateRange = {
    start: undefined,
    end: undefined,
  };
  @Input() isMobile: boolean;
  @Input() disableCompare = false;
  @Output() rangeSelection = new EventEmitter();

  //Forms and Controls
  dateRangeOne: UntypedFormGroup;
  dateRangeTwo: UntypedFormGroup;
  rangesControl: UntypedFormControl;
  compareControl: UntypedFormControl;
  compareToggle: UntypedFormControl;
  monthlyRange: UntypedFormControl;
  monthsAgo: UntypedFormControl;
  dayRange: UntypedFormControl;
  relativeToToggle: UntypedFormControl;
  monthlyRangeToggle: UntypedFormControl;
  applyToAllToggle: UntypedFormControl;

  // Properties
  quickDateRanges = [
    {
      label: 'Last 7 days',
      range: {
        end: moment().subtract(1, 'day'),
        start: moment().subtract(7, 'days'),
      },
      relative: 'LAST_7_DAYS',
      translate: 'shared.components.date_picker.last_seven_days',
    },
    {
      label: 'Last 14 days',
      range: {
        end: moment().subtract(1, 'day'),
        start: moment().subtract(14, 'days'),
      },
      relative: 'LAST_14_DAYS',
      translate: 'shared.components.date_picker.last_fourteen_days',
    },
    {
      label: 'Last 30 days',
      range: {
        end: moment().subtract(1, 'day'),
        start: moment().subtract(30, 'days'),
      },
      relative: 'LAST_30_DAYS',
      translate: 'shared.components.date_picker.last_thirty_days',
    },
    {
      label: 'Last Month',
      range: {
        end: moment().startOf('month').subtract(1, 'day'),
        start: moment().subtract(1, 'month').startOf('month'),
      },
      relative: 'LAST_MONTH',
      translate: 'shared.components.date_picker.last_month',
    },
    // {
    //   label: 'This Month',
    //   range: {end: moment().endOf('month'), start: moment().startOf('month')},
    //   relative: 'THIS_MONTH',
    //   translate: 'shared.components.date_picker.this_month'
    // },
    {
      label: 'This Month',
      range: {end: moment(), start: moment().startOf('month')},
      relative: 'THIS_MONTH',
      translate: 'shared.components.date_picker.this_month',
    },
    {
      label: 'This Year',
      range: {
        end: moment().subtract(1, 'day'),
        start: moment().startOf('year'),
      },
      relative: 'THIS_YEAR',
      translate: 'shared.components.date_picker.this_year',
    },
    {
      label: 'Relative Range',
      range: {end: null, start: null},
      relative: 'RELATIVE_RANGE',
      translate: 'shared.components.date_picker.relative_range',
    },
    {
      label: 'Custom Date',
      range: {end: null, start: null},
      relative: 'CUSTOM_DATE',
      translate: 'shared.components.date_picker.custom_date_option',
    },
  ];
  quickComparisonRanges = [
    {
      label: 'Previous Period',
      range: {end: null, start: null},
      relative: 'PREVIOUS_PERIOD',
      translate: 'shared.components.date_picker.previous_period_option',
    },
    {
      label: 'Previous Year',
      range: {end: null, start: null},
      relative: 'PREVIOUS_YEAR',
      translate: 'shared.components.date_picker.previous_year_option',
    },
    {
      label: 'Previous Month',
      range: {end: null, start: null},
      relative: 'PREVIOUS_MONTH',
      translate: 'shared.components.date_picker.previous_month_option',
    },
    {
      label: 'Custom Date',
      range: {end: null, start: null},
      relative: 'CUSTOM_DATE',
      translate: 'shared.components.date_picker.custom_date_option',
    },
  ];
  subs: Subscription = new Subscription();

  constructor() {
    //
  }

  ngOnInit(): void {
    this.dateRangeOne = new UntypedFormGroup(
      {
        start: new UntypedFormControl(
          this.selectedRange.start,
          Validators.required
        ),
        end: new UntypedFormControl(
          this.selectedRange.end,
          Validators.required
        ),
      },
      Validators.required
    );

    this.dateRangeTwo = new UntypedFormGroup({
      start: new UntypedFormControl(this.selectedComparisonRange.start),
      end: new UntypedFormControl(this.selectedComparisonRange.end),
    });

    this.monthlyRange = new UntypedFormControl(
      this.relativeRange?.monthRange &&
      this.relativeRange?.monthRange === 'lastMonths'
        ? this.relativeRange?.dateCount
        : ''
    );
    this.dayRange = new UntypedFormControl(
      this.relativeRange?.relativeTo === 'days'
        ? this.relativeRange?.dateCount
        : ''
    );
    this.monthsAgo = new UntypedFormControl(
      this.relativeRange?.monthRange &&
      this.relativeRange?.monthRange === 'monthsAgo'
        ? this.relativeRange?.dateCount
        : ''
    );
    this.rangesControl = new UntypedFormControl(
      this.quickDateRanges.find((elem) => elem.relative === this.currentOption)
    );
    this.compareControl = new UntypedFormControl(
      this.quickComparisonRanges.find(
        (elem) => elem.relative === this.compareOption
      )
    );
    this.compareToggle = new UntypedFormControl(
      !!this.selectedComparisonRange.start
    );
    this.relativeToToggle = new UntypedFormControl(
      this.relativeRange?.relativeTo
    );
    this.monthlyRangeToggle = new UntypedFormControl(
      this.relativeRange?.monthRange
    );
    this.applyToAllToggle = new UntypedFormControl(false);

    if (
      this.selectedRange.start !== null &&
      this.compareToggle.value === true &&
      this.compareOption !== 'CUSTOM_DATE'
    ) {
      this.dateRangeTwo.setValue(
        this.calculateCompareDate(this.compareOption, this.dateRangeOne.value)
      );
      this.dateRangeTwo.get('start')?.setValidators(Validators.required);
      this.dateRangeTwo.get('end')?.setValidators(Validators.required);
      this.dateRangeTwo.updateValueAndValidity();
    }

    this.listenMonthlyRange();
  }

  listenMonthlyRange(): void {
    this.subs.add(
      this.monthlyRange.valueChanges.subscribe((value) => {
        const range = {
          end: value ? moment().startOf('month').subtract(1, 'day') : null,
          start: value
            ? moment().startOf('month').subtract(parseInt(value, 10), 'months')
            : null,
        };
        this.dateRangeOne.setValue(range);
        if (this.compareOption && this.compareToggle.value === true) {
          this.dateRangeTwo.setValue(
            this.calculateCompareDate(
              this.compareOption,
              value ? range : this.dateRangeOne.value
            )
          );
        }
      })
    );

    this.subs.add(
      this.monthsAgo.valueChanges.subscribe((value) => {
        const range = {
          end: value
            ? moment().endOf('month').subtract(parseInt(value, 10), 'months')
            : null,
          start: value
            ? moment().startOf('month').subtract(parseInt(value, 10), 'months')
            : null,
        };
        this.dateRangeOne.setValue(range);
        if (this.compareOption && this.compareToggle.value === true) {
          this.dateRangeTwo.setValue(
            this.calculateCompareDate(
              this.compareOption,
              value ? range : this.dateRangeOne.value
            )
          );
        }
      })
    );

    this.subs.add(
      this.dayRange.valueChanges.subscribe((value) => {
        const range = {
          end: value ? moment().subtract(1, 'days') : null,
          start: value ? moment().subtract(parseInt(value, 10), 'days') : null,
        };
        this.dateRangeOne.setValue(range);
        if (this.compareOption && this.compareToggle.value === true) {
          this.dateRangeTwo.setValue(
            this.calculateCompareDate(
              this.compareOption,
              value ? range : this.dateRangeOne.value
            )
          );
        }
      })
    );

    this.subs.add(
      this.monthlyRangeToggle.valueChanges.subscribe((value) => {
        switch (value) {
          case 'lastMonths':
            this.monthsAgo.setValue('');
            break;
          case 'monthsAgo':
            this.monthlyRange.setValue('');
            break;
          default:
            this.monthsAgo.setValue('');
            this.monthlyRange.setValue('');
            break;
        }
      })
    );

    this.subs.add(
      this.relativeToToggle.valueChanges.subscribe((value) => {
        if (value === 'days') {
          this.monthlyRangeToggle.setValue('');
        } else {
          this.dayRange.setValue('');
        }
      })
    );
  }

  onQuickDateChange(event): void {
    const currentDateRange = event.value.range;

    this.dateRangeOne.setValue(currentDateRange);
    this.currentOption = event.value.relative;

    if (this.compareOption && this.compareToggle.value === true) {
      this.dateRangeTwo.setValue(
        this.calculateCompareDate(this.compareOption, currentDateRange)
      );
    }
  }

  onComparisonDateChange(event): void {
    this.compareOption = event.value.relative;

    if (this.dateRangeOne.value.start) {
      this.dateRangeTwo.setValue(
        this.calculateCompareDate(this.compareOption, this.dateRangeOne.value)
      );
    }
  }

  onDateRangeInputChange(event: MatDatepickerInputEvent<any>): void {
    if (event.targetElement.id.includes('rangeTwo')) {
      this.compareOption = 'CUSTOM_DATE';

      const btnOption = this.quickComparisonRanges.find(
        (elem) => elem.relative === this.compareOption
      );
      this.compareControl.setValue(btnOption);
    } else {
      this.currentOption = 'CUSTOM_DATE';
      const btnOption = this.quickDateRanges.find(
        (elem) => elem.relative === this.currentOption
      );
      this.rangesControl.setValue(btnOption);
    }
  }

  calculateCompareDate(option: string, currentRange): DateRange {
    const range: {
      start: moment.Moment | string | null;
      end: moment.Moment | string | null;
    } = {
      start: moment(),
      end: moment(),
    };

    Object.assign(range, currentRange);

    const dayDifference = moment(currentRange.end).diff(
      currentRange.start,
      'days'
    );
    switch (option) {
      case 'PREVIOUS_PERIOD':
        if (this.currentOption === 'LAST_MONTH') {
          range.start = moment(currentRange.start)
            .subtract(1, 'month')
            .startOf('month');
          range.end = moment(currentRange.start)
            .subtract(1, 'month')
            .endOf('month');
          break;
        }

        if (
          this.currentOption === 'RELATIVE_RANGE' &&
          this.monthlyRange.value
        ) {
          const lastMonths = this.monthlyRange.value;
          range.start = moment(currentRange.start).subtract(
            lastMonths,
            'month'
          );
          range.end = moment(currentRange.start)
            .subtract(1, 'month')
            .endOf('month');
          break;
        }

        if (this.currentOption === 'RELATIVE_RANGE' && this.monthsAgo.value) {
          range.start = moment(currentRange.start).subtract(1, 'month');
          range.end = moment(currentRange.start)
            .subtract(1, 'month')
            .endOf('month');
          break;
        }

        if (this.currentOption === 'RELATIVE_RANGE' && this.dayRange.value) {
          range.start = moment(currentRange.start).subtract(
            this.dayRange.value,
            'day'
          );
          range.end = moment(currentRange.start).subtract(1, 'day');
          break;
        }

        range.start = moment(currentRange.start).subtract(
          dayDifference + 1,
          'days'
        );
        range.end = moment(currentRange.start).subtract(1, 'days');
        break;
      case 'PREVIOUS_YEAR':
        range.start = moment(currentRange.start).subtract(1, 'year');
        range.end = moment(currentRange.end).subtract(1, 'year');
        break;
      case 'PREVIOUS_MONTH':
        range.start = moment(currentRange.start)
          .subtract(1, 'month')
          .startOf('month');
        if (
          moment(currentRange.end).isSame(
            moment(currentRange.end).endOf('month')
          )
        ) {
          range.end = moment(currentRange.end)
            .subtract(1, 'month')
            .endOf('month');
        } else {
          range.end = moment(currentRange.end).subtract(1, 'month');
        }
        break;
      default:
        break;
    }

    return range;
  }

  changeCompareToggle(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.dateRangeTwo.enable();
      this.dateRangeTwo.setValue(
        this.calculateCompareDate(this.compareOption, this.dateRangeOne.value)
      );
      this.dateRangeTwo.get('start')?.setValidators(Validators.required);
      this.dateRangeTwo.get('end')?.setValidators(Validators.required);
    } else {
      this.dateRangeTwo.reset();
      this.dateRangeTwo.disable();
    }

    this.dateRangeTwo.updateValueAndValidity();
  }

  clearSelection(): void {
    this.dateRangeOne.reset({
      start: null,
      end: null,
    });

    this.dateRangeTwo.reset({
      start: null,
      end: null,
    });

    this.rangeSelection.emit(null);
  }

  emitSelection(): void {
    const selection: ISelection = {
      range: {
        dates: this.dateRangeOne.value,
        relative: this.currentOption,
      },
      compare: {
        dates: this.dateRangeTwo.value,
        relative: this.compareOption,
      },
      applyToAll: false,
    };

    if (this.origin === 'page') {
      selection.applyToAll = this.applyToAllToggle.value;
    }

    if (this.currentOption === 'RELATIVE_RANGE') {
      selection.range.relativeTo = this.relativeToToggle.value;
      if (this.relativeToToggle.value === 'months') {
        selection.range.monthRange = this.monthlyRangeToggle.value;
        selection.range.dateCount =
          this.monthlyRangeToggle.value === 'lastMonths'
            ? this.monthlyRange.value
            : this.monthsAgo.value;
      } else {
        selection.range.dateCount = this.dayRange.value;
      }
    }

    selection.isCompare = this.compareToggle.value;
    this.rangeSelection.emit(selection);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
