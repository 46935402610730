import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaxLengthDirective } from '../../directives/max-length.directive';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-two-factor-code',
    templateUrl: './two-factor-code.component.html',
    styleUrls: ['./two-factor-code.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MaxLengthDirective,
        NgxIntlTelInputModule,
        TranslateModule,
    ],
})
export class TwoFactorCodeComponent implements OnInit, AfterViewInit {
  @ViewChildren('tfInput') twoFactorInputs: QueryList<ElementRef>;

  // Inputs
  @Input() codeValidity: string;
  @Input() isLoading: boolean;
  @Output() formValueChange = new EventEmitter();
  @Output() resendCode: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Form variables
  codeForm: UntypedFormGroup;
  digits: UntypedFormArray;

  ngOnInit(): void {
    this.codeForm = new UntypedFormGroup({
      digits: new UntypedFormArray([]),
    });

    this.digits = this.codeForm.get('digits') as UntypedFormArray;

    for (let i = 0; i < 6; i++) {
      this.digits.push(new UntypedFormControl('', Validators.required));
    }
  }

  ngAfterViewInit(): void {
    this.twoFactorInputs.forEach((input, index) => {
      const element = input.nativeElement;
      if (index === 0) {
        element.focus();
      }
      // Event listener to change focus on to next input or previous input.
      element.addEventListener('keyup', (event) => {
        if (element.value.length === 1) {
          // Focus on the next sibling
          element.nextElementSibling?.focus();
        }
        if (event.keyCode === 8) {
          // Focus on previous sibling
          element.previousElementSibling?.focus();
        }
      });

      // Event listener to prevent focus on mousedown except for the first input.
      element.addEventListener('mousedown', (event) => {
        if (
          (element.nextElementSibling?.value &&
            !element.previousElementSibling?.value &&
            index !== 0) ||
          (index === 0 && !element.nextElementSibling.value) ||
          (index === this.twoFactorInputs.length - 1 &&
            element.previousElementSibling?.value)
        ) {
          return;
        }
        event.preventDefault();
      });
    });
  }

  createDigit(): UntypedFormControl {
    return new UntypedFormControl('', [Validators.required]);
  }

  sendVerificationCode(): void {
    this.resendCode.emit(true);
  }

  emitFormValue(): void {
    const code = this.codeForm
      .get('digits')
      ?.value.toString()
      .replaceAll(',', '');
    this.formValueChange.emit(code);
  }
}
