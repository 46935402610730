import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import {
  DialogService,
  NotificationService,
  ReportService,
} from '../../../../core/services';
import {MatTooltip} from '@angular/material/tooltip';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-share-link-dialog',
    templateUrl: './share-link-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./share-link-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogContent,
        MatTooltip,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatSlideToggle,
        MatDialogActions,
        TranslateModule,
    ],
})
export class ShareLinkDialogComponent implements OnInit, OnDestroy {
  // ViewChild
  tooltip = viewChild<MatTooltip>('tooltip');
  // Properties
  linkCtrl: UntypedFormControl;
  accessCtrl: UntypedFormControl;
  reportId = signal<string>('');
  subs: Subscription = new Subscription();

  // State
  linkLoading = signal<boolean>(false);
  isCopied = signal<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<ShareLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private clipboard: Clipboard,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe(() => {
          this.closeDialog();
        })
      );
    }
    this.linkCtrl = new UntypedFormControl({value: '', disabled: true});
    this.accessCtrl = new UntypedFormControl(true);
    this.reportId.set(this.data?.reportId);
    this.getReportShareLink();
    this.onRemoveChange();
  }

  onRemoveChange(): void {
    this.accessCtrl.valueChanges.subscribe((value) => {
      if (value) {
        this.getReportShareLink();
      } else {
        this.removeReportShareLink();
      }
    });
  }

  getReportShareLink(): void {
    this.linkLoading.set(true);
    this.subs.add(
      this.reportService.getShareableLink(this.reportId()).subscribe({
        next: (res: {publicLink: string}): void => {
          this.linkCtrl.setValue(res.publicLink);
          this.onCopyLink();
        },
        error: (err): void => {
          this.linkLoading.set(false);
          this.notificationService.error(err?.message, 5000);
        },
        complete: (): void => {
          this.linkLoading.set(false);
        },
      })
    );
  }

  removeReportShareLink(): void {
    this.linkLoading.set(true);
    this.subs.add(
      this.reportService.removeShareableLink(this.reportId()).subscribe({
        next: (): void => {
          this.linkCtrl.setValue('');
          this.notificationService.success(
            this.translate.instant('notifications.shared_link_removed'),
            5000
          );
        },
        error: (err): void => {
          this.linkLoading.set(false);
          this.notificationService.error(err?.message, 5000);
        },
        complete: (): void => {
          this.linkLoading.set(false);
        },
      })
    );
  }

  onCopyLink(): void {
    this.isCopied.set(true);
    this.changeDetector.detectChanges();
    this.tooltip()?.show();
    this.clipboard.copy(this.linkCtrl.value);

    setTimeout(() => {
      this.isCopied.set(false);
      this.tooltip()?.hide();
    }, 2000);
  }

  closeDialog(emitValue?): void {
    this.dialogService.closeDialog(this.dialogRef, emitValue);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
