<section>
  <p>
    <small class="d-flex wsm-text-grayed mb-3">
      {{ "common.fields_required" | translate }}
    </small>
  </p>
  <div class="d-flex justify-content-between align-items-center mb-2">
    <p>
      {{
        "reporting.create_report.form.select_a_template" | translate
      }}&nbsp;<span class="required-mark fw-bolder">*</span>
    </p>
    <div class="wsm-input-group-prepend">
      <i class="fa-regular fa-magnifying-glass ps-1"></i>
      <input
        appAlphanumeric
        (keyup)="keyUp.next($event)"
        type="text"
        class="wsm-input"
        placeholder="{{ 'templates.form.search_placeholder' | translate }}"
      />
    </div>
  </div>
  <div
    class="select-template-section"
    [ngClass]="{'overlay-container': isLoadingTemplates}"
  >
    @if (!isLoadingTemplates && templates.length === 0) {
      <div
        class="wsm-alert-message info mt-3 w-100"
        [innerHTML]="'templates.no_templates_info' | translate"
      ></div>
    }
    <div
      class="gallery-wrapper"
      infiniteScroll
      [infiniteScrollDistance]="0.5"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="'.select-template-section'"
      [fromRoot]="true"
      [infiniteScrollDisabled]="isLoadingMoreTemplates()"
      (scrolled)="onScroll()"
    >
      @for (template of templates(); track template) {
        <app-template-gallery-card
          [templateCard]="template"
          [type]="'report'"
          [from]="'wizard'"
          [isSelected]="
            templateSelected()
              ? template?._id === templateSelected()?._id
              : false
          "
          (templateSelected)="onTemplateSelected($event)"
        ></app-template-gallery-card>
      }
    </div>
    @if (isLoadingMoreTemplates()) {
      <div class="d-flex justify-content-center pt-4 pe-4 wsm-text-grayed">
        <span
          class="spinner-grow spinner-grow-sm template-more-loading"
          role="status"
          aria-hidden="true"
        ></span>
        &nbsp;{{ "reporting.reportToolbar.loading_label" | translate }}
      </div>
    }
    <app-loading
      [generalLoading]="isLoadingTemplates() && !isLoadingMoreTemplates()"
    ></app-loading>
  </div>
</section>
