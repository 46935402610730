@if (!isLoading) {
  <gridster
    #gridster
    [options]="options"
    [ngClass]="{
      'horizontal-grid': orientation === 'horizontal',
      'vertical-grid': orientation === 'vertical',
      'bg-img': bckImageUrl,
    }"
    [ngStyle]="{
      'background-color': theme.pageColor,
      'background-image': bckImageUrl ? 'url(' + bckImageUrl + ')' : '',
    }"
  >
    @for (item of gridsterItems; track item?.widget?.id) {
      <gridster-item
        [item]="item"
        [ngClass]="{
          'active-grid-item':
            isEditMode &&
            item?.widget?.id !== 'dummy' &&
            item?.widget?.id === widgetActive?.widgetId,
          'move-item':
            isEditMode &&
            item?.widget?.id !== 'dummy' &&
            isMoving &&
            currentMove.widget?.id !== item?.widget?.id,
        }"
      >
        <app-widget
          [widget]="item.widget"
          [reportTheme]="theme"
          [page]="page"
          [isEditMode]="isEditMode"
          [pages]="pages"
          [reportId]="reportId"
          [isPrint]="isPrint"
          [orientation]="orientation"
          [eventMove]="moveSubject.asObservable()"
          (widgetSortChanged)="onWidgetSort($event)"
          (positionChanged)="moveWidget($event)"
          (actionsActive)="onActionChanged($event)"
          (editWidgetEmitter)="editWidget($event)"
        >
        </app-widget>
        @if (isEditMode && item?.widget?.id !== "dummy") {
          <section class="active-widget"></section>
        }
        @if (isEditMode && item?.widget?.id !== "dummy") {
          <section class="inactive"></section>
        }
      </gridster-item>
    }
  </gridster>
}

@if (isLoading) {
  <div
    id="loader-spinner"
    class="loader-container"
    [ngClass]="{
      'horizontal-grid': orientation === 'horizontal',
      'vertical-grid': orientation === 'vertical',
      'bg-img': bckImageUrl,
    }"
    [ngStyle]="{
      'background-color': theme.pageColor,
      'background-image': bckImageUrl ? 'url(' + bckImageUrl + ')' : '',
    }"
  >
    <div class="spinner-ripple">
      <div class="circle">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
}
