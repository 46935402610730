<!-- Google -->
@if (
  [
    "adwords",
    "google-analytics",
    "google-search-console",
    "google-analytics-v4",
    "youtube"
  ].includes(service?.provider!)
) {
  <div
    class="sign-in-button google mt-3 mt-lg-0"
    [ngClass]="'sign-in-' + service?.provider"
    (click)="onAddNewAccount()"
  >
    <div class="icon">
      <img src="assets/img/svg/google-icon.svg" width="35px" alt="Google" />
    </div>
    <span class="d-block my-2">
      {{ "shared.dialogs.manage_credentials.sign_in_google_label" | translate }}
    </span>
  </div>
}

<!-- Meta -->
@if (
  [
    "sm-facebook-ads",
    "facebook-ads",
    "sm-facebook-insights",
    "facebook-insights",
    "sm-instagram-insights",
    "instagram-insights"
  ].includes(service?.provider!)
) {
  <div
    class="sign-in-button facebook mt-3 mt-lg-0"
    [ngClass]="'sign-in-' + service?.provider"
    (click)="onAddNewAccount()"
  >
    <img src="assets/img/svg/login-facebook.svg" width="35px" alt="Facebook" />
    <span class="d-block my-2">{{
      "shared.dialogs.manage_credentials.login_facebook_label" | translate
    }}</span>
  </div>
}

<!-- Others -->
@if (
  ![
    "adwords",
    "google-analytics",
    "google-search-console",
    "facebook-ads",
    "sm-facebook-ads",
    "sm-facebook-insights",
    "facebook-insights",
    "sm-instagram-insights",
    "instagram-insights",
    "google-analytics-v4",
    "youtube"
  ].includes(service?.provider!)
) {
  <button
    class="wsm-btn wsm-btn-primary mt-3 mt-lg-0"
    (click)="onAddNewAccount()"
    [ngClass]="'sign-in-' + service?.provider"
  >
    @if (isConnecting && !isTokenAuth) {
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
      </span>
    }
    @if (!isConnecting) {
      <span>
        {{
          mainSignIn
            ? header
            : ("shared.dialogs.manage_credentials.add_new" | translate)
        }}
      </span>
    }
  </button>
}
