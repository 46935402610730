<label for="">{{
  "reporting.widget_editor.style_form.widget_icon" | translate
}}</label>
<div class="wsm-input-group-prepend w-100">
  <input
    type="text"
    [formControl]="iconControl"
    class="wsm-input w-100"
    [matAutocomplete]="iconsComplete"
    placeholder="{{
      'reporting.widget_editor.style_form.widget_icon_placeholder' | translate
    }}"
    appBInput
    [inputType]="'input'"
    [inputStatus]="inputStatus"
  />
</div>
<mat-autocomplete #iconsComplete [displayWith]="displayIcon">
  @for (icon of filteredIcons$ | async; track icon) {
    <mat-option [value]="icon">
      <span>
        <i [class]="icon.faClass"></i>
        {{ icon.label }}
      </span>
    </mat-option>
  }
</mat-autocomplete>
