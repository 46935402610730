import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appNumbersOnly]',
    standalone: true,
})
export class NumbersOnlyDirective {
  @Input() numbersOnly: boolean;
  navigationKeys: Array<string> = ['Backspace']; // Add keys as per requirement

  constructor(private element: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent): void {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey)
    ) {
      return; // Let it happen, don't do anything
    }

    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }
}
