<div class="breadcrumbs-container mb-4 d-flex align-items-center">
  <ol class="breadcrumb-list ms-2 mb-0">
    @for (breadcrumb of allBreadcrumbs; track breadcrumb; let i = $index) {
      <li class="d-flex align-items-center">
        <a
          class="mx-2"
          [routerLink]="breadcrumb.segments"
          [fragment]="breadcrumb.fragment"
          >{{ breadcrumb.label | translate }}</a
        >
        @if (i !== allBreadcrumbs.length - 1) {
          <i class="breadcrumb-separator fa-regular fa-slash-forward"></i>
        }
      </li>
    }
  </ol>
</div>
