import {Component, Input} from '@angular/core';
import {WidgetPopoverData} from 'src/app/shared/models';
import { NgClass, DecimalPipe, PercentPipe, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-popover-content',
    templateUrl: './popover-content.component.html',
    styleUrls: ['./popover-content.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        DecimalPipe,
        PercentPipe,
        CurrencyPipe,
        TranslateModule,
    ],
})
export class PopoverContentComponent {
  // Inputs / Outputs
  @Input() popoverData: WidgetPopoverData;
  @Input() pipeType: string;

  formatDiff(diffPercent: string | null): number {
    return parseFloat(diffPercent?.replace('%', '') as string);
  }
}
