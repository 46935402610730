<header>
  <h5 class="d-flex wsm-text-white mb-2">
    {{ "shared.dialogs.plan_cancelation.title" | translate }}
  </h5>
  <p class="wsm-text-secondary">
    {{ "shared.dialogs.plan_cancelation.description" | translate }}
  </p>
</header>

<mat-dialog-content class="mat-typography d-inline">
  <form
    [formGroup]="cancelForm"
    id="cancel-plan-form"
    class="w-100"
    autocomplete="off"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <mat-radio-group
            formControlName="immediately"
            aria-label="Select an option"
            class="d-flex justify-content-between ms-2"
          >
            <div class="w-100">
              <mat-radio-button [value]="true" class="col me-3">
                <h3 class="mb-0">
                  {{ "shared.dialogs.plan_cancelation.cancel_now" | translate }}
                </h3>
              </mat-radio-button>
              <p class="wsm-text-secondary ms-4 wrap-text">
                {{
                  "shared.dialogs.plan_cancelation.cancel_now_desc" | translate
                }}
              </p>
            </div>
            <div class="w-100">
              <mat-radio-button [value]="false" class="col me-3">
                <h3 class="mb-0">
                  {{
                    "shared.dialogs.plan_cancelation.cancel_after" | translate
                  }}
                </h3>
              </mat-radio-button>
              <p class="wsm-text-secondary ms-4 wrap-text">
                {{
                  "shared.dialogs.plan_cancelation.cancel_after_desc"
                    | translate
                }}
              </p>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="row py-4">
      <div class="col-md-6">
        <div class="row">
          <label for="reason" class="text-lg-start">
            {{ "shared.dialogs.plan_cancelation.feedback_label" | translate }}
          </label>
          <div class="col-sm-12">
            <mat-select
              formControlName="reason"
              id="reason"
              panelClass="wsm-bg-quaternary"
              [disableOptionCentering]="true"
              class="wsm-input validate"
            >
              <mat-option [value]="''">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.select_option"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'I do not see a return on my investment'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.no_investment_return"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'I\'m not using the tool'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.no_tool_usage"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'I don\'t understand the tool'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.no_tool_understanding"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'The tool lacks the features I need'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.tool_lacks_features"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'I need different integrations'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.need_more_integrations"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'I will use a free tool for reporting'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.free_tool"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'It is too expensive'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.too_expensive"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'Change in business initiatives'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.business_change"
                    | translate
                }}
              </mat-option>
              <mat-option [value]="'Other'">
                {{
                  "shared.dialogs.plan_cancelation.feedback_options.other"
                    | translate
                }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <label for="notes" class="text-lg-start">
            {{ "shared.dialogs.plan_cancelation.text_area_label" | translate }}
          </label>
          <div class="col-sm-12">
            <textarea
              type="text"
              id="notes"
              class="wsm-input w-100"
              formControlName="notes"
              cols="30"
              rows="5"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    (click)="onDismiss()"
    class="wsm-btn wsm-btn-outline me-3"
    [disabled]="isLoading"
  >
    {{
      (data.button ? data.button : "common.confirmation_dialog.cancel_button")
        | translate
    }}
  </button>
  <button
    (click)="onConfirm()"
    class="wsm-btn wsm-btn-primary"
    [disabled]="isLoading"
  >
    @if (!isLoading) {
      <span>
        {{
          (data.confirmButton
            ? data.confirmButton
            : "common.confirmation_dialog.accept_button"
          ) | translate
        }}
      </span>
    }
    @if (isLoading) {
      <span class="spinner-border spinner-border-sm text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </span>
    }
  </button>
</mat-dialog-actions>
