@if (popoverData?.previousValue || popoverData?.delta) {
  <div class="widget-popover">
    @if (!isFromGallery) {
      <span
        class="indicator {{
          theme.schemaIndicator
            ? 'indicator-' + theme.schemaIndicator
            : 'indicator-opt-1'
        }}"
        [ngClass]="{
          'positive': popoverData.isPositive && !popoverData.isNeutral,
          'negative': !popoverData.isPositive && !popoverData.isNeutral,
          'neutral': popoverData.isNeutral
        }"
        [satPopoverAnchor]="popInfo"
        (mouseenter)="popInfo.open()"
        (mouseleave)="popInfo.close()"
      >
        (
        @if (!popoverData.isNeutral) {
          <span
            class="me-1 fa-regular"
            [ngClass]="
              formatDiff(popoverData?.deltaPercent! | percent) > 0
                ? 'fa-arrow-up'
                : 'fa-arrow-down'
            "
          >
          </span>
        }
        <span>{{ popoverData.deltaPercent | percent: "1.2-2" }}</span>
        )
      </span>
    } @else {
      <div
        class="delta-box {{
          theme?.schemaIndicator
            ? 'schema-' + theme?.schemaIndicator
            : 'indicator-opt-1'
        }}"
      >
        <span
          class="metric-delta"
          [ngClass]="popoverData?.isPositive ? 'positive' : 'negative'"
          [satPopoverAnchor]="popInfo"
          (mouseenter)="popInfo.open()"
          (mouseleave)="popInfo.close()"
        >
          {{
            popoverData?.deltaPercent! !== 0
              ? (popoverData?.deltaPercent | percent: "1.2")
              : "—"
          }}
          <i
            [hidden]="popoverData?.deltaPercent! === 0"
            [ngClass]="
              popoverData?.deltaPercent! > 0 ? 'fa-arrow-up' : 'fa-arrow-down'
            "
            class="fa-regular"
          ></i>
        </span>
      </div>
    }
    <sat-popover #popInfo horizontalAlign="center" verticalAlign="above">
      <app-popover-content
        [popoverData]="popoverData"
        [pipeType]="pipeType"
      ></app-popover-content>
    </sat-popover>
  </div>
}
