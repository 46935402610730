import {Component, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-tab-label',
    templateUrl: './tab-label.component.html',
    standalone: true,
})
export class TabLabelComponent {
  // Children
  @ViewChild(TemplateRef) labelContent: TemplateRef<any>;
}
