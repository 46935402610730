<div
  [ngClass]="{
    'align-items-center': !isIndividual,
    'connection-card': !isMobile,
    'connection-card-mobile': isMobile
  }"
>
  <div class="logo-container p-2 p-lg-3 me-0 me-lg-3">
    <img
      [src]="connection?.logo"
      width="25px"
      height="25px"
      alt="Connection Logo"
    />
  </div>
  <div class="connection-card-content">
    <h6>{{ connection.name }}</h6>

    @if (
      connection?.provider !== "adwords" &&
      connection?.provider !== "google-analytics" &&
      connection?.provider !== "google-search-console" &&
      connection?.provider !== "facebook-ads" &&
      connection?.provider !== "sm-facebook-ads" &&
      connection?.provider !== "sm-facebook-insights" &&
      connection?.provider !== "google-analytics-v4" &&
      connection?.provider !== "youtube"
    ) {
      <button
        (click)="signIn(connection)"
        class="wsm-btn wsm-btn-primary"
        [ngClass]="'sign-in-' + connection?.provider"
      >
        @if (!connection?.isConnecting) {
          <span>
            {{ "shared.components.connection_login.connect_info" | translate }}
          </span>
        }
      </button>
    }
    @if (
      connection?.provider === "adwords" ||
      connection?.provider === "google-analytics" ||
      connection?.provider === "google-search-console" ||
      connection?.provider === "google-analytics-v4" ||
      connection?.provider === "youtube"
    ) {
      <div
        class="wsm-button sign-in-button google"
        [ngClass]="'sign-in-' + connection?.provider"
        (click)="signIn(connection)"
      >
        <div class="icon">
          <img src="assets/img/svg/google-icon.svg" width="35px" alt="Google" />
        </div>
        <span class="d-block my-2">
          {{ "shared.components.connection_login.login_google" | translate }}
        </span>
      </div>
    }
    @if (
      connection?.provider === "facebook-ads" ||
      connection?.provider === "sm-facebook-ads" ||
      connection?.provider === "sm-facebook-insights"
    ) {
      <div
        class="wsm-button sign-in-button facebook"
        [ngClass]="'sign-in-' + connection?.provider"
        (click)="signIn(connection)"
      >
        <img
          src="assets/img/svg/login-facebook.svg"
          width="35px"
          alt="Google"
        />
        <span class="d-block my-2">
          {{ "shared.components.connection_login.login_facebook" | translate }}
        </span>
      </div>
    }
  </div>
</div>
