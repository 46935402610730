import {trigger, transition, style, animate} from '@angular/animations';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Inject, OnInit} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipGrid, MatChipRow, MatChipInput } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocompleteOrigin, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import {map, Observable, startWith} from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import {
  NotificationService,
  ReportService,
  TrackingService,
  WidgetService,
} from 'src/app/core/services';
import {
  CustomError,
  SavePageTemplatePayload,
  SaveTemplatePayload,
  UpdateTemplatePayload,
} from '../../models';
import {CommonLanguage, HelperService} from '../../helpers';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

export interface Goal {
  name: string;
}

@Component({
    selector: 'app-save-as-template-dialog',
    templateUrl: './save-as-template-dialog.component.html',
    styleUrls: ['./save-as-template-dialog.component.scss'],
    animations: [
        trigger('slideInOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('0.3s ease-out', style({ height: '*', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                animate('0.3s ease-out', style({ height: 0, opacity: 0 })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatChipGrid,
        MatAutocompleteOrigin,
        NgClass,
        MatChipRow,
        MatChipInput,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOption,
        MatSelect,
        MatDialogActions,
        AsyncPipe,
        TranslateModule,
    ],
})
export class SaveAsTemplateDialogComponent implements OnInit {
  // State Variables
  public isLoading: boolean;
  public isEdit = false;
  public isLoadingGoalTypes = true;

  // Properties
  public goals: string[] = [];
  public readonly separatorKeysCodes = [COMMA, ENTER] as const;

  // Form
  public templateForm: UntypedFormGroup;
  public goalsTypes: string[] = [];
  public goalInputControl: UntypedFormControl = new UntypedFormControl('');
  public filteredGoals: Observable<string[]>;
  public languages: CommonLanguage[];

  constructor(
    public dialogRef: MatDialogRef<SaveAsTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private widgetService: WidgetService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private helperService: HelperService,
    private trackingService: TrackingService
  ) {
    this.filteredGoals = this.goalInputControl.valueChanges.pipe(
      startWith(null),
      map((goal: string | null): string[] => {
        return goal ? this._filter(goal) : this.goalsTypes.slice();
      })
    );
  }

  ngOnInit(): void {
    this.languages = this.helperService.getCommonLanguage();
    this.goalInputControl.disable();
    this.handleGetGoalTypes();
    this.templateForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      description: new UntypedFormControl(null, [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      goal: new UntypedFormControl([], Validators.required),
      language: new UntypedFormControl(null, Validators.required),
      orientation: new UntypedFormControl(this.data.orientation),
    });
    this.templateForm.get('orientation')?.disable();
    if (this.data?.isEdit) {
      this.isEdit = true;
      this.setEditData();
    }
  }

  setEditData(): void {
    if (this.data?.template) {
      this.templateForm.patchValue({
        name: this.data.template?.name,
        description: this.data.template?.description,
        goal: this.goals && this.goals.length !== 0 ? this.goals : null,
        language: this.data.template?.language,
        orientation: this.data.template?.orientation,
      });
      this.goals = [...this.data.template.goalType];
      this.updateGoals();
    }
  }

  handleGetGoalTypes(): void {
    switch (this.data.type) {
      case 'report':
        this.getReportTemplateGoalTypes();
        break;
      case 'page':
        this.getPageTemplateGoalTypes();
        break;
      case 'widget':
        this.getWidgetTemplateGoalTypes();
        break;
      default:
        break;
    }
  }

  getReportTemplateGoalTypes(): void {
    this.reportService.getReportGoalTypes().subscribe({
      next: (res: string[]): void => {
        this.goalsTypes = res;
        this.goalInputControl.enable();
        this.isLoadingGoalTypes = false;
      },
      error: (err: CustomError): void => {
        this.isLoadingGoalTypes = false;
        this.notificationService.error(err.message, 3000);
      },
    });
  }

  getPageTemplateGoalTypes(): void {
    this.reportService.getPageTemplateGlobalTypes().subscribe({
      next: (res: string[]): void => {
        this.goalsTypes = res;
        this.goalInputControl.enable();
        this.isLoadingGoalTypes = false;
      },
      error: (err: CustomError): void => {
        this.isLoadingGoalTypes = false;
        this.notificationService.error(err.message, 3000);
      },
    });
  }

  getWidgetTemplateGoalTypes(): void {
    this.reportService.getWidgetTemplateGlobalTypes().subscribe({
      next: (res: string[]): void => {
        this.goalsTypes = res;
        this.goalInputControl.enable();
        this.isLoadingGoalTypes = false;
      },
      error: (err: CustomError): void => {
        this.isLoadingGoalTypes = false;
        this.notificationService.error(err.message, 3000);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.goalsTypes.filter((goal: string) =>
      goal.toLowerCase().includes(filterValue)
    );
  }

  public noWhitespaceValidator(
    control: UntypedFormControl
  ): {[key: string]: boolean} | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
  }

  add(event: MatChipInputEvent): void {
    let value = (event.value || '').trim();
    if (value?.length > 0) {
      value = value?.toLowerCase();
    }

    // Add our goal
    if (value && /[a-z ]+/g.test(value)) {
      const upperGoal = this.capitalizeGoals(value);
      this.goals.push(upperGoal);
    }

    // Clear the input value
    event.chipInput.clear();
    this.goalInputControl.setValue(null);
    this.updateGoals();
  }

  remove(goal: string): void {
    const index = this.goals.indexOf(goal);

    if (index >= 0) {
      this.goals.splice(index, 1);
    }

    this.updateGoals();
  }

  selectedGoal(event: MatAutocompleteSelectedEvent): void {
    this.goalInputControl.setValue(null);
    this.goals.push(event.option.value);
    this.updateGoals();
  }

  updateGoals(): void {
    if (this.goals?.length > 0) {
      this.templateForm.get('goal')?.clearValidators();
      this.goals.length === 3
        ? this.goalInputControl.disable()
        : this.goalInputControl.enable();
    } else {
      this.templateForm.get('goal')?.setValidators([Validators.required]);
    }
    this.goalInputControl.updateValueAndValidity();
    this.templateForm.get('goal')?.updateValueAndValidity();
  }

  capitalizeGoals(goal: string): string {
    const cutGoal = goal.toLowerCase().split(' ');
    const toUpperGoal = cutGoal.map(
      (el) => el.charAt(0).toUpperCase() + el.slice(1)
    );
    return toUpperGoal.join(' ');
  }

  onSubmit(): void {
    if (this.isEdit) {
      this.onUpdate();
    } else {
      this.onSave();
    }
  }

  onSave(): void {
    this.templateForm
      .get('goal')
      ?.setValue(this.goals && this.goals.length !== 0 ? this.goals : null);
    if (this.templateForm.invalid) {
      Object.values(this.templateForm.controls).forEach((control) => {
        if (!control.value) {
          control.markAsDirty();
        }
      });
    } else {
      this.isLoading = true;
      const widget = this.data.widget;
      let payload: SaveTemplatePayload | SavePageTemplatePayload;
      switch (this.data.type) {
        case 'report':
          payload = {
            name: this.templateForm.get('name')?.value?.trim(),
            goalType: this.templateForm.get('goal')?.value,
            description: this.templateForm.get('description')?.value,
            language: this.templateForm.get('language')?.value,
            orientation: this.templateForm.get('orientation')?.value,
            favorite: false,
          } as SaveTemplatePayload;

          this.reportService
            .saveTemplate(payload as SaveTemplatePayload, this.data.reportId)
            .subscribe({
              error: (err: CustomError): void => {
                this.isLoading = false;
                this.notificationService.error(err?.message as string, 5000);
              },
              complete: () => {
                this.trackingService.trackEvent('Save Report Template');
                this.isLoading = false;
                this.translate
                  .get('notifications.template_saved')
                  .subscribe((res: string) => {
                    this.notificationService.success(res, 5000);
                  });
                this.dialogRef.close();
              },
            });
          break;
        case 'page':
          // Save page as template
          payload = {
            name: this.templateForm.get('name')?.value?.trim(),
            description: this.templateForm.get('description')?.value,
            goalType: this.templateForm.get('goal')?.value,
          } as SavePageTemplatePayload;

          this.reportService
            .savePageTemplate(this.data.reportId, this.data.pageId, payload)
            .subscribe({
              error: (err: CustomError): void => {
                this.isLoading = false;
                this.notificationService.error(err?.message as string, 5000);
              },
              complete: (): void => {
                this.trackingService.trackEvent('Save Page Template');
                this.isLoading = false;
                this.translate
                  .get('notifications.template_saved')
                  .subscribe((res: string) => {
                    this.notificationService.success(res, 5000);
                  });
                this.dialogRef.close();
              },
            });
          break;
        case 'widget':
          widget.name = this.templateForm.get('name')?.value?.trim();
          widget.goalType = this.templateForm.get('goal')?.value;
          widget.templateHeight = this.data.height;
          widget.templateWidth = this.data.width;
          widget.description = this.templateForm.get('description')?.value;

          this.widgetService.saveWidgetTemplate(widget).subscribe({
            error: (err: CustomError): void => {
              this.notificationService.error(err?.message as string, 5000);
            },
            complete: (): void => {
              this.trackingService.trackEvent('Save Widget Template');
              this.isLoading = false;
              this.translate
                .get('notifications.template_saved')
                .subscribe((res: string) => {
                  this.notificationService.success(res, 5000);
                });
              this.dialogRef.close();
            },
          });
          break;
      }
    }
  }

  onUpdate(): void {
    this.templateForm
      .get('goal')
      ?.setValue(this.goals && this.goals.length !== 0 ? this.goals : null);

    if (this.templateForm.invalid) {
      Object.values(this.templateForm.controls).forEach((control) => {
        if (!control.value) {
          control.markAsDirty();
        }
      });
      return;
    }

    const payload: UpdateTemplatePayload = {
      name: this.templateForm.get('name')?.value?.trim(),
      goalType: this.templateForm.get('goal')?.value,
      description: this.templateForm.get('description')?.value,
      language: this.templateForm.get('language')?.value,
      orientation: this.templateForm.get('orientation')?.value,
      id: this.data?.template?.id,
    };

    this.isLoading = true;

    this.reportService.updateTemplate(payload).subscribe({
      next: (): void => {},
      error: (err: CustomError): void => {
        this.isLoading = false;
        this.notificationService.error(err?.message, 5000);
      },
      complete: (): void => {
        this.isLoading = false;
        this.translate
          .get('notifications.template_updated')
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
        this.dialogRef.close({success: true});
      },
    });
  }
}
