import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import {IAlert, IAlertPayload, CustomError} from '../../models';
import {MonitoringService, NotificationService} from '../../../core/services';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { CompanySelectionComponent } from '../../components/company-selection/company-selection.component';

@Component({
    selector: 'app-create-alert',
    templateUrl: './create-alert.component.html',
    styleUrls: ['./create-alert.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        CompanySelectionComponent,
        MatDialogActions,
        TranslateModule,
    ],
})
export class CreateAlertComponent implements OnInit, OnDestroy {
  // Properties
  conditionsSelected: Array<any> = [];
  dataType = 'specific';
  alertNameCtrl: FormControl = new FormControl('', Validators.required);
  subs: Subscription = new Subscription();

  // State
  isLoading = false;
  clientSelected = false;
  clientIsTouched = false;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<CreateAlertComponent>,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private monitoringService: MonitoringService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.alertNameCtrl.valueChanges.subscribe((): void => {
        this.setSelection();
      })
    );
  }

  public getCustomConditions(event): void {
    this.conditionsSelected = event.conditions;
    this.dataType = event.dataType;
    this.setSelection();
  }

  private setSelection(): void {
    if (this.dataType !== 'all') {
      this.clientSelected =
        this.conditionsSelected?.length > 0 &&
        !!this.alertNameCtrl?.value &&
        this.alertNameCtrl?.value?.length > 0;
    }

    if (this.dataType === 'all') {
      this.clientSelected =
        !!this.alertNameCtrl?.value && this.alertNameCtrl?.value?.length > 0;
    }
  }

  /**
   * Create a monitoring alert.
   */
  public onCreateAlert(): void {
    this.clientIsTouched = true;
    if (this.alertNameCtrl.invalid && !this.clientSelected) {
      this.alertNameCtrl.markAsTouched();
    }

    if (this.alertNameCtrl.valid && this.clientSelected) {
      this.isLoading = true;
      const payload: IAlertPayload = {
        name: this.alertNameCtrl?.value?.trim(),
        companiesMatching: this.conditionsSelected,
        companyDataType: this.dataType,
      };

      this.subs.add(
        this.monitoringService.createAlert(payload).subscribe({
          next: (res: IAlert): void => {
            this.isLoading = false;
            this.notificationService.success(
              this.translate.instant('notifications.alert_created'),
              5000
            );
            this.router.navigate(['monitoring/' + res?.id]).then();
            this.dialogRef.close();
          },
          error: (err: CustomError): void => {
            this.isLoading = false;
            this.notificationService.error(err?.message || '', 5000);
          },
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
