import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import {IConnectionVideo} from '../../models';
import { TranslateModule } from '@ngx-translate/core';
import { SafeResourcePipe } from '../../pipes/safe-resource.pipe';

@Component({
    selector: 'app-demo-video-dialog',
    templateUrl: './demo-video-dialog.component.html',
    styleUrl: './demo-video-dialog.component.scss',
    standalone: true,
    imports: [
        MatDialogContent,
        MatDialogClose,
        TranslateModule,
        SafeResourcePipe,
    ],
})
export class DemoVideoDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DemoVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConnectionVideo
  ) {}

  public moveTopRight(): void {
    this.dialogRef.updatePosition({right: '10px', top: '10px'});
  }

  public moveBottomRight(): void {
    this.dialogRef.updatePosition({right: '10px', bottom: '10px'});
  }
}
