import {Component, Input} from '@angular/core';

import {ClientField, FilterOperator, IAlertField} from '../../models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-filter-operator',
    template: `
    @switch (filter.operator) {
      @case ('eq') {
        <span class="me-1">:</span>
      }
      @case ('in') {
        <span class="me-1">:</span>
      }
      @case ('ne') {
        <span class="mx-1">
          {{ 'operators.is_not' | translate }}
        </span>
      }
      @case ('nin') {
        <span class="mx-1">
          {{ 'operators.is_not' | translate }}
        </span>
      }
      @case ('notIn') {
        <span class="mx-1">
          {{ 'operators.is_not' | translate }}
        </span>
      }
      @case ('like') {
        <span class="mx-1">
          {{ 'operators.contains' | translate }}
        </span>
      }
      @case ('contains') {
        <span class="mx-1">
          {{ 'operators.contains' | translate }}
        </span>
      }
      @case ('notLike') {
        <span class="mx-1">
          {{ 'operators.not_contains' | translate }}
        </span>
      }
      @case ('notContains') {
        <span class="mx-1">
          {{ 'operators.not_contains' | translate }}
        </span>
      }
      @case ('gte') {
        <span class="mx-1">>=</span>
      }
      @case ('lte') {
        <span class="mx-1"><=</span>
      }
      @case ('gt') {
        <span class="mx-1">></span>
      }
      @case ('lt') {
        <span class="mx-1"><</span>
      }
    }
  `,
    styles: [],
    standalone: true,
    imports: [TranslateModule],
})
export class FilterOperatorComponent {
  // Inputs
  @Input() filter: IAlertField | ClientField | FilterOperator;
}
