<div
  id="mobile-report-grid"
  class="p-3 position-relative"
  [ngStyle]="{
    'background-color': theme.pageColor,
    'height': gridItems.length === 0 ? '100vh' : ''
  }"
>
  <div
    class="widget-grid"
    [ngClass]="{'empty-grid': page.widgets?.length === 0}"
    [ngStyle]="{
      'grid-template-columns': mobileStatus.largeMobile
        ? 'repeat(2, 1fr)'
        : '100%'
    }"
  >
    @for (widget of gridItems; track widget) {
      <div
        class="mobile-grid-item"
        [ngClass]="{
          'chart-item':
            widget.widgetType === 'chart' ||
            widget.widgetType === 'current-goal',
          'm-auto': widget.widgetType === 'image',
          'branding-item': widget.widgetType === 'branding',
          'timeline-item': widget.widgetType === 'timeline',
          'biggest-changes-item': widget.widgetType === 'biggestchanges'
        }"
        [ngStyle]="{
          'grid-column': 'span ' + widget.position.columnSpan,
          'height':
            widget.widgetType === 'image'
              ? widget.position.height * gridSquareSize + 'px'
              : '',
          'width':
            widget.widgetType === 'image'
              ? widget.position.width * gridSquareSize + 'px'
              : '',
          'max-height': widget.widgetType === 'image' ? '350px' : '',
          'max-width': '100%'
        }"
      >
        <app-widget
          [widget]="widget"
          [reportTheme]="theme"
          [page]="page"
          [isEditMode]="isEditMode"
          [pages]="pages"
          [reportId]="reportId"
          [isPrint]="isPrint"
          [mobileStatus]="mobileStatus"
        />
      </div>
    }
  </div>

  @if (isLoading) {
    <div
      id="loader-spinner"
      class="loader-container mobile-grid-loader"
      [ngStyle]="{
        'background-color': theme.pageColor
      }"
    >
      <div class="spinner-ripple">
        <div class="circle">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  }
</div>
