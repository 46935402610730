<nav class="wsm-tabs">
  @for (link of sidenavLinks; track link) {
    @if (authService.checkRole(link.roles) && link.showMobile) {
      <a
        href=""
        class="wsm-tab-link"
        routerLink="{{ link.path }}"
        [fragment]="link?.fragment"
        routerLinkActive="active-tab-link"
      >
        <i class="fa-regular {{ link.icon.class }} tab-link-icon"></i>
      </a>
    }
  }
</nav>
