import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {IAlert, Report} from '../../models';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { SpecificComponent } from './components/specific/specific.component';
import { CriteriaComponent } from './components/criteria/criteria.component';
import { TranslateModule } from '@ngx-translate/core';
// TODO Migrate to signals al components related to company-selection and its children
@Component({
    selector: 'app-company-selection',
    templateUrl: './company-selection.component.html',
    styleUrls: ['./company-selection.component.scss'],
    standalone: true,
    imports: [
        MatRadioGroup,
        FormsModule,
        NgxIntlTelInputModule,
        MatRadioButton,
        SpecificComponent,
        CriteriaComponent,
        TranslateModule,
    ],
})
export class CompanySelectionComponent implements OnInit, OnChanges {
  @Input() report?: Report;
  @Input() alert?: IAlert;
  @Input() conditionsFromWizard?: Array<any>;
  @Output() sendConditions = new EventEmitter<any>();
  @Output() specificLoadingState = new EventEmitter<any>();
  @Input() showTitle?: boolean = true;

  @Input() companyDataType?: string;

  constructor() {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyDataType) {
      this.companyDataType = changes.companyDataType.currentValue || 'specific';
    }
  }

  ngOnInit(): void {
    //
  }

  setConditions(event): void {
    this.sendConditions.emit({
      conditions: event,
      dataType: this.companyDataType,
    });
  }

  onChangeGroup(event): void {
    this.sendConditions.emit({conditions: [], dataType: event.value});
  }

  getLoadingState(event: boolean): void {
    this.specificLoadingState.emit(event);
  }
}
