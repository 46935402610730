<div class="w-100" [ngClass]="{'d-flex justify-content-center': isFromStarted}">
  <section
    class="selector-container open p-3"
    [ngClass]="{
      'custom-selector': isMobile,
      'wsm-w-50 ps-0 position-relative': isFromStarted
    }"
  >
    <div class="d-flex align-items-center justify-content-between mb-3">
      @if (!isFromStarted) {
        <h3 class="h4 mb-0 me-3">
          {{ "company.account_selector.selector_title" | translate }}
        </h3>
      }

      <div class="action-buttons">
        @if (!isMobile) {
          <button
            class="wsm-btn wsm-btn-primary wsm-btn-small me-2"
            (click)="saveChanges()"
            [disabled]="!unsavedChanges"
          >
            {{ "company.account_selector.save_button" | translate }}
          </button>
        }

        @if (!isMobile) {
          <button
            class="wsm-btn wsm-btn-outline wsm-btn-small"
            (click)="discardChanges()"
            [disabled]="!unsavedChanges"
          >
            {{ "company.account_selector.discard_button" | translate }}
          </button>
        }
      </div>
      @if (!isFromStarted) {
        <button
          (click)="warnUnsavedChanges()"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon ms-2"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      }
    </div>

    <div class="wsm-input-group-prepend w-100 mb-3">
      @if (!accountsLoading) {
        <i class="fa-regular fa-magnifying-glass ps-1"> </i>
      }
      <i
        [ngClass]="{'d-none': !accountsLoading}"
        class="fa-solid fa-circle-notch fa-spin ac-loading"
      >
      </i>
      <input
        [formControl]="searchAccountControl"
        appAlphanumeric
        class="wsm-input w-100"
        placeholder="{{
          'company.company_connections.search_account_placeholder' | translate
        }}"
        type="text"
      />
    </div>

    <div class="sections-container flex-grow-1">
      @for (category of categories; track category; let i = $index) {
        <mat-expansion-panel
          hideToggle
          [expanded]="categoryCounter[category.id].length > 0"
          (opened)="setExpansionStatus(true, i)"
          (closed)="setExpansionStatus(false, i)"
        >
          <mat-expansion-panel-header class="mb-3">
            <mat-panel-title class="custom-account-mat-title">
              <label class="wsm-text-grayed fw-bold">
                {{ category.label | translate }} ({{
                  categoryCounter[category.id].length
                }})
              </label>
            </mat-panel-title>
            <mat-panel-description>
              <i
                class="collapse-icon fa-solid fa-caret-down wsm-text-grayed"
                [ngClass]="{
                  'collapse-icon-rotated': expansionPanels[i].expanded
                }"
              >
              </i>
            </mat-panel-description>
          </mat-expansion-panel-header>
          @for (serviceAccount of serviceAccounts; track serviceAccount) {
            @if (serviceAccount.category === category.id) {
              <div class="account-info mb-3">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selectAccount(serviceAccount) : null"
                  [checked]="accountSelection.isSelected(serviceAccount)"
                >
                </mat-checkbox>
                <img
                  [class]="serviceAccount.type"
                  [src]="serviceAccount.logo"
                  width="20px"
                  alt="Service Logo"
                />
                <div class="d-flex flex-column">
                  <span
                    [innerHtml]="serviceAccount.name | highlight: toHighlight"
                  >
                  </span>
                  <span
                    class="id-label"
                    [innerHtml]="
                      serviceAccount.accountId | highlight: toHighlight
                    "
                  >
                  </span>
                </div>
              </div>
            }
          }
        </mat-expansion-panel>
      }
    </div>

    @if (isMobile && !isFromStarted) {
      <div class="d-flex justify-content-center mt-4">
        <button
          class="wsm-btn wsm-btn-primary wsm-btn-small w-50 w-sm-100"
          (click)="saveChanges()"
          [disabled]="!unsavedChanges"
        >
          {{ "company.account_selector.save_button" | translate }}
        </button>
      </div>
    }
  </section>
</div>
