import {Component, Input} from '@angular/core';
import {Connection, DatasetConnection} from '../../models';
import { NgClass, SlicePipe } from '@angular/common';
import { SatPopoverModule } from '@ncstate/sat-popover';

@Component({
    selector: 'app-connection-logos',
    templateUrl: './connection-logos.component.html',
    styleUrls: ['./connection-logos.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        SatPopoverModule,
        SlicePipe,
    ],
})
export class ConnectionLogosComponent {
  //Inputs
  @Input() connections: Connection[] | DatasetConnection[] | undefined;
  @Input() type: string;
  @Input() isCustom: boolean | undefined = false;
}
