import {Component, ContentChild, input, Input} from '@angular/core';
import {TabBodyComponent, TabLabelComponent} from '..';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  standalone: true,
})
export class TabItemComponent {
  @Input() label: string;
  @Input() isActive;
  @Input() index;
  @Input() id: string;
  keyId = input.required<string | number>();
  public menuOpen: boolean;

  @ContentChild(TabLabelComponent) labelComponent: TabLabelComponent;
  @ContentChild(TabBodyComponent) bodyComponent: TabBodyComponent;
}
