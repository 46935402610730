<div class="card-content mb-3" [ngClass]="{'is-active': page.isActive}">
  <div class="d-flex align-items-center pe-4">
    @if (!canEditInline || hasChanges || isReportLoading) {
      <span>
        {{ page.name | truncate: 131 }}
      </span>
    }
    @if (canEditInline && !hasChanges && !isReportLoading) {
      @if (updatingName) {
        <mat-progress-bar class="mt-2" mode="indeterminate"></mat-progress-bar>
      }
      @if (!updatingName) {
        @if (page.isActive && isEditMode) {
          <i class="fa-regular fa-grip-dots-vertical drag-icon me-2"></i>
        }
        <app-editable-title
          [titleContent]="page.name"
          [editMode]="isPageActive && isEditMode"
          [useAs]="'body'"
          (titleSave)="onTitleReportChange($event)"
          class="w-100"
        >
          <ng-template>
            <span [ngClass]="{'ms-3': !page.isActive || !isEditMode}">
              {{ page.name | truncate: 131 }}
            </span>
          </ng-template>
        </app-editable-title>
      }
    }
  </div>

  <div class="d-flex justify-content-between align-items-end">
    <app-connection-logos
      [connections]="page.connections"
      type="page"
    ></app-connection-logos>
    <span class="page-number mb-1 me-2">{{ index + 1 }}</span>
  </div>
</div>
