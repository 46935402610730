<header class="mb-2">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h3 m-0">
      {{
        (isExport && partnerInstance
          ? "shared.dialogs.export_to.export_title"
          : "shared.dialogs.export_to.switch_title"
        ) | translate
      }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content class="custom-content overlay-container">
  @if (!partnerInstance) {
    <p>{{ "shared.dialogs.export_to.switch_description" | translate }}</p>
    <div class="w-100 my-3">
      <div class="wsm-input-group-prepend w-100">
        <i class="fa-regular fa-magnifying-glass"></i>
        <input
          type="text"
          #instanceInput
          class="wsm-input w-100"
          placeholder="{{
            'shared.dialogs.export_to.instance_placeholder' | translate
          }}"
        />
      </div>
      <div class="mt-2">
        <mat-chip-listbox aria-label="Organization Filters">
          <mat-chip
            class="d-inline-flex align-items-center me-2"
            [matMenuTriggerFor]="statusMenu"
          >
            <i class="fa-regular fa-filter me-1"></i>
            {{ "shared.dialogs.export_to.status_label" | translate }} :
            {{
              statusCtrl.value === "ALL"
                ? "All"
                : statusCtrl.value === "ACTIVE"
                  ? "Active"
                  : "Inactive"
            }}
          </mat-chip>
          <mat-chip
            [matMenuTriggerFor]="planMenu"
            class="d-inline-flex align-items-center"
            [satPopoverAnchor]="popInfo"
            (mouseenter)="popInfo.open()"
            (mouseleave)="popInfo.close()"
          >
            <i class="fa-regular fa-filter me-1"></i>
            {{ "shared.dialogs.export_to.plan_label" | translate }}:&nbsp;
            @if (
              planCtrl?.value &&
              planCtrl?.value?.length! === planFilters?.length
            ) {
              <span> All </span>
            }
            @if (planCtrl?.value && planCtrl?.value?.length! <= 2) {
              <span>
                @for (
                  plan of planCtrl.value;
                  track plan;
                  let last = $last;
                  let i = $index
                ) {
                  {{ getPlanLabel(plan) }}
                  @if (!last && i < 1) {
                    ,
                  }
                }
              </span>
            }
            @if (
              planCtrl?.value &&
              planCtrl?.value?.length! > 2 &&
              planCtrl?.value?.length! < planFilters?.length!
            ) {
              <span>
                @for (
                  plan of planCtrl.value | slice: 0 : 2;
                  track plan;
                  let last = $last
                ) {
                  {{ getPlanLabel(plan) }},
                  @if (last) {
                    +{{ planCtrl?.value?.length! - 2 }}
                  }
                }
              </span>
            }
          </mat-chip>
          <sat-popover
            #popInfo
            horizontalAlign="center"
            verticalAlign="below"
            panelClass="custom-pop-icon"
          >
            @if (
              planCtrl?.value &&
              planCtrl?.value?.length! > 2 &&
              planCtrl?.value?.length! < planFilters?.length!
            ) {
              <div class="org-wrapper">
                <div class="popover-org-arrow"></div>
                <ul class="ps-3 mb-1">
                  @for (plan of planCtrl.value | slice: 2; track plan) {
                    <li>
                      {{ getPlanLabel(plan) }}
                    </li>
                  }
                </ul>
              </div>
            }
          </sat-popover>
        </mat-chip-listbox>
        <!-- Status Menu -->
        <mat-menu #statusMenu="matMenu" class="mat-menu-gray">
          <mat-radio-group
            aria-label="Select a status"
            class="d-flex flex-column ps-2 pe-3"
            [(ngModel)]="statusFilter"
            (click)="$event.stopPropagation()"
          >
            <mat-radio-button value="ACTIVE">Active</mat-radio-button>
            <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
            <mat-radio-button value="ALL">All</mat-radio-button>
          </mat-radio-group>
          <div class="w-100 text-end pe-2 ps-3 mt-1 mb-1">
            <button
              class="wsm-btn wsm-btn-primary wsm-btn-small"
              (click)="onSetStatusFilter()"
            >
              {{ "common.apply" | translate }}
            </button>
          </div>
        </mat-menu>
        <!-- Plan Menu -->
        <mat-menu #planMenu="matMenu" class="mat-menu-gray">
          <div (click)="$event.stopPropagation()" class="ps-2 pe-3">
            <mat-checkbox
              [checked]="allPlans"
              [indeterminate]="somePlansSelected()"
              (change)="setAllPlans($event.checked)"
            >
              All
            </mat-checkbox>
            @for (plan of planFilters; track plan) {
              <div>
                <mat-checkbox
                  [(ngModel)]="plan.selected"
                  (ngModelChange)="updateAllSelected()"
                >
                  {{ plan.name }}
                </mat-checkbox>
              </div>
            }
          </div>
          <div class="w-100 text-end mt-1 ps-2 pe-3 mb-1">
            <button
              class="wsm-btn wsm-btn-primary wsm-btn-small"
              (click)="onSetPlanFilter()"
              [disabled]="!savePlans"
            >
              {{ "common.apply" | translate }}
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
    <div class="list-group partner-list">
      @for (org of orgList; track org) {
        <div
          (click)="onInstanceSelect(org)"
          class="list-group-item list-group-item-action partner-item"
        >
          <div class="d-inline-flex align-items-center justify-content-center">
            @if (org.logo) {
              <div class="partner-logo">
                <img
                  [src]="org.logo"
                  alt="Organization Logo"
                  class="img-fluid"
                />
              </div>
            } @else {
              @if (!org.logo) {
                <div class="partner-logo">
                  <i class="fa-regular fa-shapes"></i>
                </div>
              }
            }
            <div class="d-inline-block ms-3 partner-info">
              <h6>{{ org.name }}</h6>
              <small
                class="wsm-tag w-50"
                [ngClass]="org.status === 'ACTIVE' ? 'success' : 'inactive'"
              >
                <i class="fa-solid fa-circle me-1"></i>
                {{ org.status | titlecase }}
              </small>
              <small class="wsm-tag w-50 ms-2 custom-badge">
                <i class="fa-kit fa-adclicks-icon me-1"></i>
                {{
                  org?.paymentSetting?.product
                    ? org.paymentSetting.product?.name
                    : "No Set"
                }}
              </small>
            </div>
          </div>
          @if (!partnerInstance && isExport) {
            <div class="d-inline-block">
              <i class="fa-regular fa-chevron-right"></i>
            </div>
          }
        </div>
      }
    </div>
  } @else {
    <div class="">
      <label for="name">
        {{ "shared.dialogs.export_to.name_label" | translate }}&nbsp;
        <span class="required-mark">*</span>
      </label>
      <input
        class="wsm-input w-100"
        id="name"
        [formControl]="reportName"
        type="text"
      />
    </div>
    <div class="my-3">
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon me-1"
        (click)="onBackInstance()"
      >
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <span class="back-title">
        {{ "shared.dialogs.export_to.instance_back_title" | translate }}
      </span>
    </div>
    <div class="reportContainer my-3">
      <label for="replace">
        {{ "shared.dialogs.export_to.replace_report_label" | translate }}
      </label>
      <input
        id="replace"
        [formControl]="replaceReportCtrl"
        placeholder="{{
          'shared.dialogs.export_to.replace_report_placeholder' | translate
        }}"
        [matAutocomplete]="reportAutocomplete"
        class="wsm-input w-100"
      />
      <mat-autocomplete
        #reportAutocomplete="matAutocomplete"
        [displayWith]="displayReport"
      >
        @for (report of filteredReports; track report) {
          <mat-option [value]="report">
            {{ report?.name }}
          </mat-option>
        }
      </mat-autocomplete>
    </div>
    <div class="my-3">
      <label class="mb-2">
        {{ "shared.dialogs.export_to.choose_instance" | translate }}&nbsp;
        <span class="required-mark">*</span>
      </label>
      <mat-radio-group [formControl]="clientFilter">
        <mat-radio-button
          class="me-3"
          (change)="clientFilterChange($event)"
          value="specific"
        >
          {{ "shared.dialogs.export_to.specific_company" | translate }}
        </mat-radio-button>
        <mat-radio-button
          class="me-3"
          value="allCompanies"
          (change)="clientFilterChange($event)"
        >
          {{ "shared.dialogs.export_to.all_companies" | translate }}
        </mat-radio-button>
        @if ((report?.companiesMatching)![0]?.value?.length! === 1) {
          <mat-radio-button
            class="me-3"
            value="mirrorCompany"
            (change)="clientFilterChange($event)"
          >
            {{ "shared.dialogs.export_to.mirror_company" | translate }}
          </mat-radio-button>
        }
      </mat-radio-group>
    </div>
    <div [hidden]="clientFilter.value !== 'specific'">
      <hr class="mt-0 mb-3" />
      <div class="wsm-input-group-prepend mb-3 w-100">
        <i class="fa-regular fa-magnifying-glass"></i>
        <input
          #companyInput
          type="text"
          class="wsm-input w-100"
          placeholder="{{
            'shared.dialogs.export_to.search_company_placeholder' | translate
          }}"
        />
      </div>
      <div class="client-container">
        <div class="table-container custom-table overlay-container pt-3">
          <table
            [dataSource]="dataSource"
            class="w-100 table-borderless table-hover h-100 wsm-table-header-border"
            mat-table
            matSort
          >
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-sort-header mat-header-cell>
                {{ "shared.dialogs.export_to.company_column" | translate }}
              </th>
              <td *matCellDef="let company" mat-cell>
                <mat-radio-button
                  (change)="onSelectCompany(company)"
                  (click)="$event.stopPropagation()"
                  [checked]="isCompanySelected(company.id)"
                  class="me-3"
                >
                  {{ company?.name }}
                </mat-radio-button>
              </td>
            </ng-container>
            <ng-container matColumnDef="owner">
              <th *matHeaderCellDef mat-sort-header mat-header-cell>
                {{ "shared.dialogs.export_to.owner_column" | translate }}
              </th>
              <td *matCellDef="let company" mat-cell>
                {{ company?.ownerData?.name }}
              </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumnsCompany" mat-header-row></tr>
            <tr
              (click)="onSelectCompany(row)"
              *matRowDef="let row; columns: displayedColumnsCompany"
              mat-row
            ></tr>
          </table>
          <mat-paginator
            #clientPaginator
            [pageSizeOptions]="pageSizeOptions"
            [showFirstLastButtons]="true"
            [length]="clientsCount"
          >
          </mat-paginator>
        </div>
        <app-loading [isTable]="true" [generalLoading]="false"></app-loading>
      </div>
    </div>
    @if (clientFilter.value === "mirrorCompany") {
      <div class="mirror-company">
        <div class="row mt-3">
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Company Name
            </h6>
            <p class="mirror-company-sub-title mt-1">
              {{ reportClient?.companyName }}
            </p>
          </div>
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Currency
            </h6>
            <p class="mirror-company-sub-title mt-1">
              {{ reportClient?.currency }}
            </p>
          </div>
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Website
            </h6>
            <p class="mirror-company-sub-title mt-1">
              {{ reportClient?.website }}
            </p>
          </div>
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Lifecycle Stage
            </h6>
            <p class="mirror-company-sub-title mt-1">
              {{ reportClient?.lifeCycle }}
            </p>
          </div>
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Marketing Objective
            </h6>
            @for (obj of reportClient?.marketingObjective; track obj) {
              <p class="d-block mirror-company-sub-title mt-1">
                {{ obj }}
              </p>
            }
          </div>
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Business Type
            </h6>
            <p class="mirror-company-sub-title mt-1">
              {{ reportClient?.businessType }}
            </p>
          </div>
          <div class="col-4 mb-3">
            <h6 class="wsm-text-grayed text-uppercase mirror-company-title">
              Industry
            </h6>
            <p class="mirror-company-sub-title mt-1">
              {{ reportClient?.industry }}
            </p>
          </div>
        </div>
      </div>
    }
  }

  <!-- Loading -->
  @if (isLoading) {
    <div class="overlay"></div>
  }
  @if (isLoading) {
    <div class="small-spinner" style="left: 45%">
      <div class="circle">
        <div></div>
        <div></div>
      </div>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  @if (!partnerInstance) {
    <div class="w-100 d-flex justify-content-center">
      <mat-paginator
        class="modal-paginator"
        #orgPaginator
        [pageSizeOptions]="[5, 10]"
        [pageSize]="5"
        [length]="orgCount"
        [showFirstLastButtons]="true"
        [disabled]="isLoading"
      >
      </mat-paginator>
    </div>
  }

  @if (partnerInstance) {
    <div class="w-100 d-flex justify-content-end">
      <button
        class="wsm-btn wsm-btn-primary"
        [disabled]="
          !this.reportName.value ||
          (clientFilter.value === 'specific' &&
            selectedCompany?.length! <= 0) ||
          generalError
        "
        (click)="onExportReport()"
      >
        @if (!isLoading) {
          {{ "shared.dialogs.export_to.export_to_btn" | translate }}
        }
        @if (isLoading) {
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    </div>
  }
</mat-dialog-actions>
