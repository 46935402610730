import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Connection, Report} from 'src/app/shared/models';
import { RouterLink } from '@angular/router';
import { NgClass, DatePipe } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { ConnectionLogosComponent } from '../connection-logos/connection-logos.component';
import { TranslateModule } from '@ngx-translate/core';
import { TruncatePipe } from '../../pipes/truncate-string.pipe';

@Component({
    selector: 'app-report-card',
    templateUrl: './report-card.component.html',
    styleUrls: ['./report-card.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        NgClass,
        MatMenuTrigger,
        SatPopoverModule,
        MatMenu,
        MatMenuItem,
        ProfilePictureComponent,
        ConnectionLogosComponent,
        DatePipe,
        TranslateModule,
        TruncatePipe,
    ],
})
export class ReportCardComponent implements OnInit {
  // Inputs / Outputs
  @Input() report: Report;
  @Input() isMobile: boolean;
  @Input() favoriteControl: boolean;
  @Output() reportHistoryOpened: EventEmitter<Report> =
    new EventEmitter<Report>();
  @Output() reportDeleted: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() reportCloned: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() addedToFavorites: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() showMoreClients: EventEmitter<Report> = new EventEmitter<Report>();

  reportConnections: Connection[] = [];

  ngOnInit(): void {
    this.reportConnections = this.report?.connections
      ? this.report.connections
      : [];
  }

  openReportHistory(report: Report): void {
    this.reportHistoryOpened.emit(report);
  }

  onDelete(report: Report): void {
    this.reportDeleted.emit(report);
  }

  onDuplicate(report: Report): void {
    this.reportCloned.emit(report);
  }

  onFavoriteReport(report: Report): void {
    this.addedToFavorites.emit(report);
  }

  onShowMoreClients(report: Report): void {
    this.showMoreClients.emit(report);
  }
}
