@if (widget.dataset?.length! <= 0) {
  <div
    class="wsm-alert-message"
    [innerHTML]="'shared.components.conditional_metric.description' | translate"
  ></div>
}

@if (metrics?.length! > 0) {
  <section class="mt-2">
    <form [formGroup]="conditionalForm">
      <div formArrayName="fields">
        @for (
          condition of conditionalForm.get("fields")!["controls"];
          track condition;
          let i = $index;
          let first = $first;
          let last = $last
        ) {
          <div class="conditional-container">
            <div [formGroupName]="i">
              <div class="d-flex align-items-center">
                <span class="conditional-text mb-2">if(</span>
                <mat-chip
                  #measureMenuTrigger="matMenuTrigger"
                  (menuOpened)="onOpenMeasureMenu(i, 1)"
                  [class.border-success]="
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'firstCondition'
                    ]?.value
                  "
                  [matMenuTriggerData]="{index: i, position: 1}"
                  [matMenuTriggerFor]="newMeasureMenu"
                  [ngClass]="{
                    'border border-danger':
                      conditionalForm.get('fields')!['controls'][i]!.controls?.[
                        'firstCondition'
                      ]?.errors?.required &&
                      conditionalForm.get('fields')!['controls'][i]!.controls?.[
                        'firstCondition'
                      ]?.touched
                  }"
                  class="mx-1 mb-2 custom-mat-chip"
                >
                  @if (
                    conditionalForm.get("fields")!["controls"][i]!.controls?.[
                      "firstCondition"
                    ]?.value
                  ) {
                    <i class="fa-regular fa-pen pe-1 edit-measure"> </i>
                  }
                  {{
                    conditionalForm.get("fields")!["controls"][i]!.controls?.[
                      "firstCondition"
                    ]?.value || "+ Value"
                  }}
                </mat-chip>
                <mat-select
                  class="wsm-input min-select mb-2"
                  [class.border-danger]="
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'operator'
                    ]?.errors?.required &&
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'operator'
                    ]?.touched
                  "
                  [ngClass]="{
                    'min-sm-select':
                      conditionalForm.get('fields')!['controls'][i]!.controls?.[
                        'operator'
                      ]?.value
                  }"
                  [panelClass]="'wsm-custom-select custom-options'"
                  [placeholder]="'operator'"
                  disableOptionCentering
                  formControlName="operator"
                >
                  <mat-select-trigger>
                    <div class="operator-value">
                      {{
                        conditionalForm.get("fields")!["controls"][i]!
                          .controls?.["operator"]?.value | showOperator
                      }}
                    </div>
                  </mat-select-trigger>
                  @for (operator of logicOperators; track operator) {
                    <mat-option [value]="operator.operatorName">
                      {{ operator.operatorLabel }}
                    </mat-option>
                  }
                </mat-select>
                <mat-chip
                  #measureMenuTrigger="matMenuTrigger"
                  (menuOpened)="onOpenMeasureMenu(i, 2)"
                  [class.border-success]="
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'secondCondition'
                    ]?.value
                  "
                  [matMenuTriggerData]="{index: i, position: 2}"
                  [matMenuTriggerFor]="newMeasureMenu"
                  [ngClass]="{
                    'border border-danger':
                      conditionalForm.get('fields')!['controls'][i]!.controls?.[
                        'secondCondition'
                      ]?.errors?.required &&
                      conditionalForm.get('fields')!['controls'][i]!.controls?.[
                        'secondCondition'
                      ]?.touched
                  }"
                  class="mx-1 mb-2 custom-mat-chip"
                >
                  @if (
                    conditionalForm.get("fields")!["controls"][i]!.controls?.[
                      "secondCondition"
                    ]?.value
                  ) {
                    <i class="fa-regular fa-pen pe-1 edit-measure"> </i>
                  }
                  {{
                    conditionalForm.get("fields")!["controls"][i]!.controls?.[
                      "secondCondition"
                    ]?.value || "+ Value"
                  }}
                </mat-chip>
                <span class="conditional-text me-1 my-2">) then</span>
                <input
                  (click)="openEditor(i, 1)"
                  [class.border-danger]="
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'trueValuePlainText'
                    ]?.errors?.required &&
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'trueValuePlainText'
                    ]?.touched
                  "
                  [readOnly]="true"
                  autocomplete="off"
                  class="wsm-input custom-input mx-1 mb-2"
                  formControlName="trueValuePlainText"
                  placeholder="insert result"
                  type="text"
                />
                @if (!first) {
                  <span
                    (click)="removeCondition(i)"
                    class="btn-remove-condition me-1 mb-2"
                    >- Condition</span
                  >
                }
              </div>
              @if (last) {
                <div class="d-block"></div>
              }
              <span
                class="conditional-text mb-2 me-1"
                [ngClass]="{
                  'd-block':
                    conditionalForm.get('fields')!['controls']?.length! >= 1 &&
                    !last
                }"
                >else</span
              >
              @if (
                (conditionalForm?.get("fields"))!["controls"][i]?.controls?.[
                  "falseValue"
                ]
              ) {
                <span class="conditional-text mb-2 ms-1">then</span>
                <input
                  (click)="openEditor(i, 2)"
                  [class.border-danger]="
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'falseValuePlainText'
                    ]?.errors?.required &&
                    conditionalForm.get('fields')!['controls'][i]!.controls?.[
                      'falseValuePlainText'
                    ]?.touched
                  "
                  [readOnly]="true"
                  autocomplete="off"
                  class="wsm-input custom-input mx-1 mb-2"
                  formControlName="falseValuePlainText"
                  placeholder="insert result"
                  type="text"
                />
              }
              @if (last) {
                <span
                  (click)="addCondition()"
                  class="btn-add-condition me-1 mb-2"
                >
                  {{
                    "shared.components.conditional_metric.add_condition"
                      | translate
                  }}
                </span>
              }
            </div>
          </div>
        }
      </div>
    </form>
    <!-- Measure Form -->
    <mat-menu
      #newMeasureMenu="matMenu"
      (closed)="onCloseMeasureMenu()"
      xPosition="after"
      yPosition="above"
    >
      <ng-template let-index="index" let-position="position" matMenuContent>
        <div (click)="$event.stopPropagation()" class="measure-container p-2">
          <mat-button-toggle-group
            [formControl]="valueTypeCtrl"
            appearance="legacy"
            aria-label="Value Type"
            class="custom-btn-group mb-2"
          >
            <mat-button-toggle [value]="'metric'" class="col-6 my-1">
              {{
                "shared.components.conditional_metric.measure_form.button_toggle_metric"
                  | translate
              }}
            </mat-button-toggle>
            <mat-button-toggle [value]="'fixedValue'" class="col-6 my-1">
              {{
                "shared.components.conditional_metric.measure_form.button_toggle_fixed"
                  | translate
              }}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <form [formGroup]="measureForm" class="mt-2">
            <div class="row mx-0">
              @if (valueTypeCtrl.value === "metric") {
                <!-- Metric -->
                <div class="col-12 mb-3">
                  <label class="d-inline-block">
                    {{
                      "shared.components.conditional_metric.measure_form.label_metrics"
                        | translate
                    }}&nbsp;
                    <span class="required-mark">*</span>
                  </label>
                  <input
                    #metricSelect
                    type="text"
                    class="wsm-input w-100"
                    [class.border-danger]="
                      measureForm.get('metric')?.errors?.required &&
                      measureForm.get('metric')?.touched
                    "
                    id="metric"
                    placeholder="{{
                      'shared.dialogs.dynamic_metric.dataset_placeholder'
                        | translate
                    }}"
                    [formControl]="fieldFilterCtrl"
                    [matAutocomplete]="filterMetrics"
                  />
                  <mat-autocomplete
                    #filterMetrics="matAutocomplete"
                    (optionSelected)="onMetricChange($event)"
                    [displayWith]="displayMetric"
                    autoActiveFirstOption
                    class="custom-widget-autocomplete"
                  >
                    @for (metric of filteredMetrics | async; track metric) {
                      <mat-option
                        [value]="metric"
                        [disabled]="metric.isDisabled"
                        [matTooltip]="scopeService.showTooltip(metric)"
                        matTooltipPosition="right"
                        [title]="metric.name"
                      >
                        <span class="d-block mb-1">{{ metric.name }}</span>
                        <app-connection-logos
                          [connections]="metric?.connections!"
                          [isCustom]="metric?.isCustom"
                          [type]="'widget'"
                        >
                        </app-connection-logos>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </div>
                <!-- Aggregation -->
                <div class="col-12 mb-3">
                  <label>
                    {{
                      "shared.components.conditional_metric.measure_form.label_aggregation"
                        | translate
                    }}&nbsp; <span class="required-mark">*</span></label
                  >
                  <mat-select
                    [class.border-danger]="
                      measureForm.get('aggregation')?.errors?.required &&
                      measureForm.get('aggregation')?.touched
                    "
                    [panelClass]="'wsm-custom-select'"
                    class="wsm-input w-100"
                    disableOptionCentering
                    formControlName="aggregation"
                    id="measureAggregation"
                    placeholder="{{
                      'shared.components.conditional_metric.measure_form.placeholder_aggregation'
                        | translate
                    }}"
                  >
                    <mat-option [value]="'sum'">Sum</mat-option>
                    <mat-option [value]="'avg'">Average</mat-option>
                    <mat-option [value]="'count'">Count</mat-option>
                    <mat-option [value]="'distinct'">Count Unique</mat-option>
                    <mat-option [value]="'max'">Max</mat-option>
                    <mat-option [value]="'min'">Min</mat-option>
                  </mat-select>
                </div>
                <!-- Value To Show -->
                @if (widget.dateRange?.previous || page?.dateRange?.previous) {
                  <div class="col-12 mb-3">
                    <label class="d-inline-block">
                      {{
                        "shared.components.conditional_metric.measure_form.label_value_show"
                          | translate
                      }}
                    </label>
                    <mat-select
                      [panelClass]="'wsm-custom-select'"
                      class="wsm-input w-100"
                      disableOptionCentering
                      formControlName="valueType"
                      id="valueType"
                      placeholder="{{
                        'shared.components.conditional_metric.measure_form.placeholder_value_show'
                          | translate
                      }}"
                    >
                      <mat-option [value]="'current'">Current</mat-option>
                      @if (
                        widget.dateRange?.previous || page?.dateRange?.previous
                      ) {
                        <mat-option [value]="'previous'">Previous</mat-option>
                        <mat-option [value]="'delta'">Delta</mat-option>
                        <mat-option [value]="'deltaPercent'"
                          >Delta Percent</mat-option
                        >
                      }
                    </mat-select>
                  </div>
                }
              }
              <!-- Fixed Value -->
              @if (valueTypeCtrl.value === "fixedValue") {
                <div class="col-12 mb-3">
                  <label class="d-inline-block">
                    {{
                      "shared.components.conditional_metric.measure_form.label_value"
                        | translate
                    }}&nbsp;
                    <span class="required-mark">*</span>
                  </label>
                  <input
                    [class.border-danger]="
                      measureForm.get('fixedValue')?.errors?.required &&
                      measureForm.get('fixedValue')?.touched
                    "
                    autocomplete="off"
                    class="wsm-input w-100"
                    formControlName="fixedValue"
                    id="fixedValue"
                    placeholder="{{
                      'shared.components.conditional_metric.measure_form.placeholder_value'
                        | translate
                    }}"
                    type="text"
                  />
                </div>
              }
              <!-- Save Button -->
              <div class="col-12 d-flex justify-content-end">
                <button
                  class="wsm-btn wsm-btn-primary wsm-btn-small"
                  [disabled]="measureForm.invalid"
                  (click)="onSaveMetric(index, position)"
                >
                  {{
                    "shared.components.conditional_metric.measure_form.button_save"
                      | translate
                  }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </mat-menu>
  </section>
}
