import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NotificationService, ReportService} from 'src/app/core/services';
import {CustomError, Report, ReportPage} from '../../models';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-reorder-pages-dialog',
  templateUrl: './reorder-pages-dialog.component.html',
  styleUrls: ['./reorder-pages-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    CdkScrollable,
    CdkDropList,
    CdkDrag,
    NgClass,
    MatDialogActions,
    TranslateModule,
  ],
})
export class ReorderPagesDialogComponent implements OnInit {
  //Children
  @ViewChild('pagesContainer') pagesContainer;

  //Properties
  pageOrder = JSON.parse(JSON.stringify(this.data.pages));

  //State Variables
  isLoading: boolean;
  isDragging: boolean;
  isExternalUser: boolean;

  constructor(
    public dialogRef: MatDialogRef<ReorderPagesDialogComponent>,
    private reportService: ReportService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.isExternalUser = JSON.parse(
      localStorage.getItem('currentUser')!
    ).roles.includes('ROLE_EXTERNAL');
  }

  onPageDrag(): void {
    this.isDragging = true;
    this.pagesContainer.nativeElement.classList.add('inherit-cursors');
    this.pagesContainer.nativeElement.style.cursor = 'grabbing';
  }

  drop(event: CdkDragDrop<string[]>): void {
    this.isDragging = false;
    this.pagesContainer.nativeElement.classList.remove('inherit-cursors');
    this.pagesContainer.nativeElement.style.cursor = 'unset';
    moveItemInArray(this.pageOrder, event.previousIndex, event.currentIndex);
  }

  onHidePage(page: ReportPage): void {
    const hiddenPages = this.pageOrder.filter((page) => !page.isVisible);

    if (hiddenPages.length !== this.pageOrder.length - 1 || !page.isVisible) {
      page.isVisible = !page.isVisible;
      if (this.isExternalUser) {
        return;
      }
      this.updateReportPage(page);
    } else {
      this.translate
        .get('notifications.report_page_not_visible')
        .subscribe((res: string) => {
          this.notificationService.warning(res, 5000);
        });
    }
  }

  updateReportPage(page: ReportPage): void {
    this.reportService.updatePage(this.data.reportId, page.id, page).subscribe({
      error: (err) => {
        this.notificationService.error(err.error.error_description, 5000);
      },
    });
  }

  saveOrder(): void {
    this.isLoading = true;
    let updatedReport: Report;
    const payload = {
      pageIndex: this.pageOrder.findIndex(
        (page) => page.id === this.data.currentPageId
      ),
      pages: this.pageOrder,
    };

    this.reportService.reorderPages(this.data.reportId, payload).subscribe({
      next: (res) => {
        updatedReport = res;
      },
      error: (err: CustomError) => {
        this.isLoading = false;
        this.translate
          .get('notifications.error_reorder_pages')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
      },
      complete: () => {
        this.dialogRef.close(updatedReport);
      },
    });
  }
}
