import {
  Component,
  ElementRef,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {interval, Subscription, switchMap} from 'rxjs';

import {BillingService, NotificationService} from 'src/app/core/services';
import {BillingPlan, SecondaryOrder, Response} from '../../models';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {NgClass} from '@angular/common';
import {MatRadioGroup, MatRadioButton} from '@angular/material/radio';
import {LottieComponent} from 'ngx-lottie';
import {TranslateModule} from '@ngx-translate/core';
import {BillingPlanStore} from '../../state';

@Component({
  selector: 'app-client-limit-dialog',
  templateUrl: './client-limit-dialog.component.html',
  styleUrls: ['./client-limit-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgClass,
    MatRadioGroup,
    MatRadioButton,
    LottieComponent,
    MatDialogActions,
    TranslateModule,
  ],
})
export class ClientLimitDialogComponent implements OnInit {
  // View Child
  @ViewChild('limitInput') limitInput: ElementRef;

  // Store
  private billingPlanStore = inject(BillingPlanStore);

  // Properties
  subs: Subscription = new Subscription();
  step = 1;
  title: string;
  description: string;
  billingInfo: BillingPlan;
  options = {
    path: '/assets/lotties/drinking-clicky.json',
  };
  paymentClientLimitSub: Subscription;
  secondaryOrder: SecondaryOrder;
  extraClients: UntypedFormControl = new UntypedFormControl(1, [
    Validators.min(1),
    Validators.max(999),
  ]);
  cardType: UntypedFormControl = new UntypedFormControl('sameCard');

  // State
  isLoading: boolean;

  constructor(
    private billingService: BillingService,
    @Inject(MAT_DIALOG_DATA) public data,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<ClientLimitDialogComponent>
  ) {}

  ngOnInit(): void {
    this.billingInfo = this.data.billingInfo;
    if (
      this.billingInfo.activeClients < this.billingInfo.clientLimit ||
      this.data.origin === 'billing'
    ) {
      this.title = 'shared.dialogs.client_limit.expand_limit';
      this.description = 'shared.dialogs.client_limit.expand_limit_desc';
    } else {
      this.title = 'shared.dialogs.client_limit.limit_reached';
      this.description = 'shared.dialogs.client_limit.limit_reched_desc';
    }

    this.subs.add(
      this.extraClients.valueChanges.subscribe((value) => {
        const digits = (num: number, count = 0): number => {
          if (num) {
            return digits(Math.floor(num / 10), ++count);
          }
          return count;
        };
        const numberOfDigits: number = digits(value);
        let widthValue = 48;

        for (let i = 1; i < numberOfDigits; i++) {
          widthValue = widthValue + 25;
        }

        this.limitInput.nativeElement.style.width = `${widthValue}px`;
        if (value < 1) {
          this.extraClients.setValue(1);
        }
        if (value > 999) {
          this.extraClients.setValue(999);
        }
      })
    );
  }

  addExtraClients(direction: string): void {
    const currentValue = this.extraClients.value;
    switch (direction) {
      case 'up':
        this.extraClients.setValue(currentValue + 1);
        break;
      case 'down':
        this.extraClients.setValue(currentValue - 1);
        break;
    }
  }

  onContinue(): void {
    this.isLoading = true;
    this.billingService
      .createSecondaryOrder(this.extraClients.value)
      .subscribe({
        next: (res) => {
          this.secondaryOrder = res.data;
        },
        error: (err) => {
          this.isLoading = false;
          this.notificationService.error(err.error.message);
        },
        complete: () => {
          this.title = 'shared.dialogs.client_limit.confirm_order';
          this.description = 'shared.dialogs.client_limit.confirm_order_desc';
          this.step = 2;
          this.isLoading = false;
        },
      });
  }

  public onPay(): void {
    this.step = 3;
    if (this.cardType.value === 'sameCard') {
      this.paidExtraClients();
    } else {
      this.checkPaymentStatus();
      window.open(this.secondaryOrder?.paymentLink, '_blank');
    }
  }

  private paidExtraClients(): void {
    this.billingService.chargeExtraClients().subscribe({
      next: (): void => {
        this.checkPaymentStatus();
      },
      error: (err): void => {
        this.isLoading = false;
        this.notificationService.error(err.error.message);
      },
    });
  }

  private checkPaymentStatus(): void {
    const currentClientLimit =
      this.billingPlanStore?.getPlan()?.clientLimit ||
      this.billingInfo.clientLimit;
    this.paymentClientLimitSub = interval(5000)
      .pipe(switchMap(() => this.billingService.getPaymentDetails()))
      .subscribe({
        next: (res: Response): void => {
          const clientLimit = res.data?.plan?.clientLimit;
          if (clientLimit !== currentClientLimit) {
            const plan: BillingPlan = res?.data?.plan || {};
            this.billingPlanStore.updateBillingPlanMethod(plan);
            this.paymentClientLimitSub?.unsubscribe();
            this.dialogRef.close(true);
          }
        },
        error: (err) => {
          this.notificationService.error(err.error.message);
          this.paymentClientLimitSub?.unsubscribe();
        },
      });
    this.subs.add(this.paymentClientLimitSub);
  }
}
