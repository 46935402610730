<section class="h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <h1 class="h2">{{ "monitoring.create_alert" | translate }}</h1>
        <p>{{ "monitoring.create_alert_description" | translate }}</p>
      </div>

      <button
        [mat-dialog-close]="false"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    <p class="mt-3">
      <small class="d-flex wsm-text-grayed">
        {{ "common.fields_required" | translate }}
      </small>
    </p>
  </header>

  <mat-dialog-content class="custom-alert-content">
    <div class="row mx-0">
      <!-- Alert Name -->
      <div class="col-12 mb-3">
        <label
          >{{ "monitoring.alert_name_label" | translate }}&nbsp;<span
            class="required-mark"
            >*</span
          ></label
        >
        <input
          type="text"
          class="wsm-input w-100"
          placeholder="{{ 'monitoring.alert_name_placeholder' | translate }}"
          [formControl]="alertNameCtrl"
          [class.border-danger]="
            alertNameCtrl?.errors?.required && alertNameCtrl?.touched
          "
        />
        @if (alertNameCtrl?.errors?.["required"] && alertNameCtrl?.touched) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>

      <!-- Clients -->
      <div class="col-12 mb-3">
        <div class="custom-form-group">
          <label
            >{{ "monitoring.select_client_label" | translate }}&nbsp;<span
              class="required-mark"
              >*</span
            ></label
          >
          @if (!clientSelected && clientIsTouched) {
            <div class="mt-1">
              <span class="invalid-info">{{
                "common.errors.field_required" | translate
              }}</span>
            </div>
          }
        </div>

        <app-company-selection
          [conditionsFromWizard]="conditionsSelected"
          [showTitle]="false"
          [companyDataType]="dataType"
          (sendConditions)="getCustomConditions($event)"
        >
        </app-company-selection>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-end">
    <button
      class="wsm-btn wsm-btn-primary submit-btn"
      (click)="onCreateAlert()"
      [disabled]="isLoading"
    >
      @if (!isLoading) {
        <span>{{ "monitoring.start_alert_btn" | translate }}</span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  </mat-dialog-actions>
</section>
