@if (!isIndividual) {
  <div
    class="service-accounts-container"
    [ngClass]="isEditMode ? 'd-flex' : 'd-block'"
  >
    <div class="account-cards-container">
      <div
        class="service-accounts-header mb-4 flex-column flex-sm-row align-items-start align-items-sm-center"
      >
        <button
          class="wsm-btn wsm-btn-primary me-3 mb-3 mb-sm-0"
          (click)="onEditAccounts()"
        >
          <i class="fa-regular fa-pen me-2"></i>
          {{ "company.account_selector.selector_title" | translate }}&nbsp;
        </button>
        <button
          class="wsm-btn wsm-btn-outline"
          (click)="openManageCredentialDialog()"
        >
          {{
            "shared.components.general.manage_services_button" | translate
          }}&nbsp;
        </button>
      </div>
      <!-- Account Cards Grid -->
      <div class="position-relative">
        @if (!accountCards || accountCards?.length === 0) {
          <div class="wsm-alert-message mt-3">
            <strong>{{
              "company.company_connections.no_accounts_label" | translate
            }}</strong>
          </div>
        }
        <div
          #accountGrid
          [hidden]="!accountCards"
          class="ps-1"
          [ngClass]="{
            'service-accounts-mobile-grid': isMobile,
            'service-accounts-grid': !isMobile
          }"
        >
          @for (account of accountCards; track account) {
            <app-service-account-card
              [account]="account"
              [isEditMode]="isEditMode"
              [isMobile]="isMobile"
              (accountRemoved)="removeServiceAccount($event)"
            >
            </app-service-account-card>
          }
        </div>
        <app-loading [generalLoading]="isLoading"></app-loading>
      </div>
    </div>
    <div
      #accountSelector
      id="account-selector"
      class="account-selector"
      [ngClass]="panelOpen ? 'open' : ''"
      (transitionend)="onTransitionEnd($event, accountSelector.id)"
    >
      @if (isEditMode && !isMobile) {
        <app-account-selector
          [selectedAccounts]="accountCards!"
          (panelClosed)="onPanelClosed($event)"
        >
        </app-account-selector>
      }
    </div>
  </div>
}

<!-- Individual Cards -->
@if (isIndividual) {
  <div
    class="service-accounts-container"
    [ngClass]="isEditMode ? 'd-flex' : 'd-block'"
  >
    <div class="account-cards-container">
      <div
        class="d-flex align-items-start mb-3"
        [ngClass]="{'d-none': isFromStarted}"
      >
        <div class="wsm-input-group-prepend">
          <i class="fa-regular fa-search ps-1"></i>
          <input
            #inputSearch
            appAlphanumeric
            type="text"
            class="wsm-input"
            placeholder="{{
              'company.company_list.search_company_placeholder' | translate
            }}"
          />
        </div>
      </div>
      <div class="position-relative">
        @if (accountList?.length === 0 && !inputSearch.value && !isLoading) {
          <div class="wsm-alert-message mt-3">
            <p
              [innerHTML]="
                'company.company_connections.no_service_accounts' | translate
              "
            ></p>
          </div>
        }
        @if (accountList?.length === 0 && inputSearch.value && !isLoading) {
          <div class="wsm-alert-message mt-3">
            <strong>{{
              "company.company_connections.no_accounts_label" | translate
            }}</strong>
          </div>
        }
        <div
          class="individual-cards ps-1"
          [ngClass]="{
            'service-accounts-mobile-grid': isMobile,
            'service-accounts-grid': !isMobile,
            'custom-card-started': isFromStarted
          }"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [scrollWindow]="!isFromStarted"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        >
          @for (
            account of accountList | slice: 0 : scrollLimit;
            track account
          ) {
            <app-service-account-card
              [account]="account"
              [userPlanType]="userPlanType"
              [isMobile]="isMobile"
              (accountSelected)="onAccountSelected($event)"
              (accountRemoved)="removeServiceAccount($event)"
            />
          }
        </div>
        <app-loading [generalLoading]="isLoading"></app-loading>
      </div>
    </div>
  </div>
}
