import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ChartType, Widget} from '../../../../models';
import {Subscription} from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-chart-type',
    templateUrl: './chart-type.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatSelect,
        NgxIntlTelInputModule,
        MatOption,
        TranslateModule,
    ],
})
export class ChartTypeComponent implements OnInit {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetDataForm: UntypedFormGroup = new UntypedFormGroup({});
  @Output() widgetType = new EventEmitter();

  // Properties
  subs: Subscription = new Subscription();

  // Static Data
  chartTypes: Array<ChartType> = [
    {
      id: 'pie2d',
      name: 'reporting.reportToolbar.widget_type_pie',
    },
    {
      id: 'doughnut2d',
      name: 'reporting.reportToolbar.widget_type_doughnut',
    },
    {
      id: 'funnel',
      name: 'reporting.reportToolbar.widget_type_funnel',
    },
    {
      id: 'mscolumn2d',
      name: 'reporting.reportToolbar.widget_type_msColumn',
    },
    {
      id: 'msbar2d',
      name: 'reporting.reportToolbar.widget_type_msBar',
    },
    {
      id: 'mscombidy2d',
      name: 'reporting.reportToolbar.widget_type_msCombination',
    },
    {
      id: 'multiaxisline',
      name: 'reporting.reportToolbar.widget_type_multiAxisLine',
    },
    {
      id: 'msarea',
      name: 'reporting.reportToolbar.widget_type_area',
    },
    {
      id: 'stackedbar2d',
      name: 'reporting.reportToolbar.widget_type_stackedBar',
    },
    {
      id: 'stackedcolumn2d',
      name: 'reporting.reportToolbar.widget_type_stackedColumn',
    },
  ];

  constructor() {
    // do nothing
  }

  ngOnInit(): void {
    // Add control for chart type
    if (this.widget.widgetType === 'chart') {
      this.widgetDataForm.addControl('chartType', new UntypedFormControl(''));

      this.subs.add(
        this.widgetDataForm
          .get('chartType')
          ?.valueChanges.subscribe((value: string) => {
            this.widgetType.emit(value);
          })
      );

      this.widgetDataForm.get('chartType')?.setValue(this.widget.chartType);
    }
  }
}
