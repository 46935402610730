import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import {DialogService} from 'src/app/core/services';
import {DataQueueDialogComponent} from '../data-queue-dialog/data-queue-dialog.component';
import {Subscription} from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@Component({
    selector: 'app-ad-preview-dialog',
    templateUrl: './ad-preview-dialog.component.html',
    styleUrls: ['./ad-preview-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogContent,
        TranslateModule,
        SafeHtmlPipe,
    ],
})
export class AdPreviewDialogComponent implements OnInit, OnDestroy {
  // Properties
  subs: Subscription = new Subscription();

  constructor(
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<DataQueueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.dialogRef.backdropClick().subscribe((res: MouseEvent): void => {
        this.onClose();
      })
    );
  }

  onClose(): void {
    this.dialogService.closeDialog(this.dialogRef);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
