import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {BreadcrumbService} from 'src/app/core/services';
import {Breadcrumb} from '../../models';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: true,
    imports: [RouterLink, TranslateModule],
})
export class BreadcrumbsComponent implements OnInit, OnChanges {
  // Services
  private breadcrumbService = inject(BreadcrumbService);

  // Inputs
  @Input() breadcrumbs: Breadcrumb[];

  // Properties
  allBreadcrumbs: Breadcrumb[];

  ngOnInit(): void {
    this.loadBreadcrumbs();
    this.breadcrumbService.breadcrumbs$.subscribe((res: Breadcrumb[]): void => {
      if (res.length === 0) {
        return;
      }
      this.allBreadcrumbs.push(res[0]);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.breadcrumbs.firstChange) {
      this.breadcrumbs = changes.breadcrumbs.currentValue;
      this.loadBreadcrumbs();
    }
  }

  loadBreadcrumbs(): void {
    this.allBreadcrumbs = [];

    if (this.breadcrumbs !== undefined && this.breadcrumbs.length !== 0) {
      this.allBreadcrumbs.push(...this.breadcrumbs);
    }
  }
}
