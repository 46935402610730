@switch (columnDataType) {
  <!-- Ad Preview -->
  @case ("adPreview") {
    @if (element.adPreviewError) {
      <div class="wsm__ad py-3 d-flex align-items-center">
        <i class="fa-regular fa-triangle-exclamation me-3"></i>
        <p class="preview-error">
          {{ "reporting.widgets.table.preview_error" | translate }}
          <br />
          @if (!isExternalUser) {
            <a [href]="helpUrl" target="_blank">
              {{ "reporting.widgets.table.contact_support" | translate }}
            </a>
          } @else {
            <span>{{ "common.contact_administrator" | translate }}</span>
          }
        </p>
      </div>
    }
    @if (
      !element?.adPreviewError &&
      element[columnKey]?.value !== "Total" &&
      element[columnKey]?.value !== "--"
    ) {
      <div [innerHTML]="element[columnKey] | safeHtml"></div>
    }
    @if (
      (!element?.adPreviewError && element[columnKey]?.value === "Total") ||
      element[columnKey]?.value === "--"
    ) {
      <span>{{ element[columnKey]?.value }}</span>
    }
  }
  <!-- Audio Player -->
  @case ("audio") {
    @if (
      element[columnKey] !== "" &&
      element[columnKey]?.value !== "Total" &&
      element[columnKey]?.value !== "--"
    ) {
      @if (isPrint && element[columnKey].value) {
        <a
          [href]="element[columnKey].value"
          class="text-decoration-none"
          target="_blank"
          [ngStyle]="{'color': theme.bodyColor}"
        >
          <i
            [ngStyle]="{'color': theme.bodyColor}"
            class="fa-regular fa-play me-1"
            style="cursor: pointer"
            title="Play Recording"
          >
          </i>
          {{ "reporting.widgets.table.play_label" | translate }}
        </a>
      }
      @if (
        !checkAudio(element[columnKey].value) &&
        element[columnKey].value &&
        !isPrint
      ) {
        <div (click)="playAudio(element[columnKey].value)" class="btn-play p-1">
          <span
            [ngStyle]="{'color': theme.bodyColor}"
            class="fa-regular fa-play me-1"
            title="Play Recording"
          >
          </span>
          {{ "reporting.widgets.table.play_label" | translate }}
        </div>
      }
      @if (!isPrint) {
        @if (checkAudio(element[columnKey].value) && element[columnKey].value) {
          <app-audio-player
            (hidePlayer)="hideAudioPlayer($event)"
            [audioUrl]="element[columnKey].value"
            [playerStyle]="widget?.playerStyle!"
            [reportTheme]="reportTheme || pageTheme"
          />
        }
      }
      @if (!element[columnKey].value) {
        <div>
          <span [ngStyle]="{'color': theme.bodyColor}" class="no-record p-2">
            {{ "reporting.widgets.table.no_audio_label" | translate }}
          </span>
        </div>
      }
    }
    @if (
      element[columnKey]?.value === "Total" ||
      element[columnKey]?.value === "--"
    ) {
      <span>{{ element[columnKey]?.value }}</span>
    }
  }
  <!-- Phone -->
  @case ("phone") {
    <p>{{ element[columnKey].value }}</p>
  }
  <!-- Date -->
  @case ("date") {
    <p>
      @if (
        column?.field?.dateConfiguration &&
        element[columnKey]?.value !== "Total" &&
        element[columnKey]?.value !== "--"
      ) {
        @switch (column?.field?.dateConfiguration?.dateFormat) {
          @case ("dateHourMinute") {
            {{ element[columnKey].value | date: "yyyy-MM-dd h:mm a z" }}
          }
          @case ("date") {
            {{ element[columnKey].value }}
          }
          @case ("dayHourStandard") {
            {{ element[columnKey].value | date: "h:mm a" : userTimeZone }}
          }
          @case ("dayHours") {
            {{ element[columnKey].value | date: "H:mm" : userTimeZone }}
          }
          @case ("shortDay") {
            {{ element[columnKey].value }}
          }
          @case ("longDay") {
            {{ element[columnKey].value }}
          }
          @case ("monthDay") {
            {{ element[columnKey].value | date: "MMM, dd" }}
          }
          @case ("monthDayYear") {
            {{ element[columnKey].value | date: "MMM dd, yyyy" }}
          }
          @case ("week") {
            {{ element[columnKey].value }}
          }
          @case ("month") {
            {{ element[columnKey].value }}
          }
          @case ("monthYear") {
            {{ element[columnKey].value }}
          }
          @case ("quarter") {
            {{ element[columnKey].value }}
          }
          @case ("year") {
            {{ element[columnKey].value }}
          }
        }
      } @else {
        {{ element[columnKey].value }}
      }
    </p>
  }
  <!-- Time -->
  @case ("time") {
    <p>{{ element[columnKey].value | transformDate }}</p>
    <app-widget-popover
      [pipeType]="columnDataType"
      [popoverData]="element[columnKey]"
      [theme]="theme"
    />
  }
  <!-- String -->
  @case ("string") {
    <p>{{ element[columnKey].value }}</p>
    <app-widget-popover
      [pipeType]="columnDataType"
      [popoverData]="element[columnKey]"
      [theme]="theme"
    />
  }
  <!-- Integer -->
  @case ("integer") {
    <p>{{ element[columnKey].valueLabel }}</p>
    <app-widget-popover
      [pipeType]="columnDataType"
      [popoverData]="element[columnKey]"
      [theme]="theme"
    />
  }
  <!-- Float -->
  @case ("float") {
    <p>{{ element[columnKey].valueLabel }}</p>
  }
  <!-- Decimal -->
  @case ("decimal") {
    <p>{{ element[columnKey].valueLabel }}</p>
    <app-widget-popover
      [pipeType]="columnDataType"
      [popoverData]="element[columnKey]"
      [theme]="theme"
    />
  }
  <!-- Currency -->
  @case ("currency") {
    <p>{{ element[columnKey].valueLabel }}</p>
    <app-widget-popover
      [pipeType]="columnDataType"
      [popoverData]="element[columnKey]"
      [theme]="theme"
    />
  }
  <!-- Percentage -->
  @case ("percentage") {
    <p>{{ element[columnKey].value | percent: "1.2-2" }}</p>
    <app-widget-popover
      [pipeType]="columnDataType"
      [popoverData]="element[columnKey]"
      [theme]="theme"
    />
  }
  <!--Object-->
  @case ("object") {
    <!--Custom Field - CTM-->
    @if (column.key === "customFields") {
      @if (isTotalRow) {
        <span>{{ element[columnKey]?.value }}</span>
      }
      @if (!isTotalRow) {
        <div class="custom-field-wrapper">
          @for (field of customFieldsArray; track field) {
            <span
              class="custom-field-tag"
              [ngStyle]="{'border-color': theme.bodyColor}"
              >{{ field.key }}: {{ field.value }}</span
            >
          }
          @if (false) {
            <button
              [ngStyle]="{
                'border-color': theme.bodyColor,
                'color': theme.bodyColor
              }"
              class="custom-field-more"
            >
              <i class="fa-regular fa-plus"></i>
              <span>2</span>
            </button>
          }
        </div>
      }
    }
  }
  <!-- Default -->
  @default {
    <p>{{ element[columnKey].value }}</p>
  }
}
