import {Component, Inject, OnInit} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {filter, map, Observable, startWith, Subscription} from 'rxjs';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';

import {ApiService} from 'src/app/core/services/api.service';
import {BrandingService} from 'src/app/core/services/branding.service';
import {NotificationService, ReportingStore} from 'src/app/core/services';
import {ReportService} from 'src/app/core/services/report.service';
import {ReportTheme} from 'src/app/shared/models/report/report-theme.model';
import {Router} from '@angular/router';
import {FontService, HelperService} from '../../../../shared/helpers';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {ThemeFromReportComponent} from '../theme-from-report/theme-from-report.component';
import {PlanType} from 'src/app/shared/enums';
import {NgClass, AsyncPipe} from '@angular/common';
import {MatRadioGroup, MatRadioButton} from '@angular/material/radio';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {CompanySelectionComponent} from '../../../../shared/components/company-selection/company-selection.component';
import {
  CreateReportPayload,
  Report,
  UpdateReportPayload,
} from 'src/app/shared/models/report/report.model';

@Component({
  selector: 'app-new-update-report',
  templateUrl: './new-update-report.component.html',
  styleUrls: ['./new-update-report.component.scss'],
  standalone: true,
  imports: [
    MatDialogContent,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatRadioGroup,
    MatRadioButton,
    MatSelect,
    MatOption,
    MatAutocompleteTrigger,
    MatAutocomplete,
    CompanySelectionComponent,
    MatDialogActions,
    AsyncPipe,
    TranslateModule,
  ],
})
export class NewUpdateReportComponent implements OnInit {
  public report: Report;
  public newReportSettings: Report;
  public isEdit: boolean;

  public userPlan: PlanType = this.helperService.getUserPlan();
  public isIndividual: boolean;

  public newReportNotification: string;
  public updateReportNotification: string;

  public fontSizes: any;
  public reportForm: UntypedFormGroup;
  public themeControl: UntypedFormControl = new UntypedFormControl();
  public subs = new Subscription();
  public conditions: [] = [];
  public conditionsInvalid = true;
  public customizedTheme: ReportTheme;

  public reportId: string;
  public themeSelect = new UntypedFormControl('', Validators.required);
  public generalLoading: boolean;
  public companyDataType: string;

  public themes: ReportTheme[] = [];
  public filteredThemes: Observable<ReportTheme[]>;

  public isLoading: boolean;
  public loadingThemes: boolean;
  public pickANewTheme: boolean;

  constructor(
    public dialogRef: MatDialogRef<NewUpdateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private fontService: FontService,
    private router: Router,
    private brandingService: BrandingService,
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private apiService: ApiService,
    private reportStorage: ReportingStore,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.report = this.data.report;
    this.isEdit = !!this.report;
    this.isIndividual = this.userPlan === PlanType.Individual;
    this.pickANewTheme = this.isEdit ? true : !this.isEdit && false;
    this.fontSizes = this.fontService.getFontSizes;

    this.buildReportForm();
    this.getThemes();

    if (this.isEdit) {
      this.populateForm();
    }

    this.filteredThemes = this.themeControl.valueChanges.pipe(
      startWith(''),
      filter((value) => typeof value === 'string'),
      map((value) => this._filterTheme(value || ''))
    );
  }

  buildReportForm(): void {
    this.reportForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      orientation: new UntypedFormControl('horizontal', Validators.required),
      fontSize: new UntypedFormControl(this.fontSizes[1], Validators.required),
    });
    this.reportForm.controls.orientation.disable();
    if (this.report?.companyDataType) {
      this.companyDataType = this.report.companyDataType;
    } else {
      if (this.report?.companiesMatching) {
        if (this.report.companiesMatching.length === 0) {
          this.companyDataType = 'all';
          this.conditionsInvalid = false;
        }
        if (this.report.companiesMatching.length !== 0) {
          if (this.report.companiesMatching[0].operator === 'in') {
            this.companyDataType = 'specific';
          } else {
            this.companyDataType = 'criteria';
          }
        }
      } else {
        this.companyDataType = 'specific';
      }
    }
  }

  populateForm(): void {
    this.reportForm.setValue({
      name: this.report.name,
      orientation: this.report.orientation,
      fontSize: this.fontSizes.filter(
        (f) => f.size === this.report.theme.fontSize
      )[0],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getThemes(): void {
    this.loadingThemes = true;
    this.brandingService.getReportThemes().subscribe({
      next: (res) => {
        if (res) {
          res.forEach((t) => {
            this.themes.push(t);
          });
          this.themes.forEach((theme) => {
            theme.colors = [
              theme.backgroundColor!,
              theme.bodyColor!,
              theme.borderColor!,
              theme.pageColor!,
              theme.titleColor!,
            ];
          });
          this.handleSetTheme();
          this.loadingThemes = false;
          this.hideShowCaret();
        }
      },
      error: () => {
        this.loadingThemes = false;
        this.hideShowCaret();
      },
    });
  }

  private _filterTheme(name: string): ReportTheme[] {
    const filterValue = name.toLowerCase();

    return [
      ...this.themes.filter((t) => t.name?.toLowerCase().includes(filterValue)),
    ];
  }

  handleSetTheme(): void {
    if (this.isEdit) {
      const currentTheme = this.themes
        .filter((el) => el._id)
        .find((t) => t._id === this.report.theme._id);
      this.themeSelect.setValue(currentTheme);
      this.themeControl.setValue('');
    }
  }

  onShowThemeDialog(): void {
    this.brandingService.shareReportTheme(this.themeSelect.value);
    const dialogRef = this.dialog.open(ThemeFromReportComponent, {
      data: {
        isFromReport: true,
      },
      autoFocus: false,
      maxWidth: '100vw',
      width: '85vw',
      height: '95vh',
      disableClose: true,
      position: {
        top: '1rem',
      },
    });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    this.subs.add(
      dialogRef.afterClosed().subscribe((res) => {
        if (res?.theme) {
          this.themes.push(res.theme);
          this.themeSelect.setValue(res.theme);
        }
        dialogSub.unsubscribe();
      })
    );
  }

  removeCurrentCustomTheme(): void {
    if (this.themes.some((t) => t.name === 'Custom Theme')) {
      const removeTheme = this.themes.find(
        (theme) => theme.name === 'Custom Theme'
      );
      const removeIndex = this.themes.indexOf(removeTheme!);
      this.themes.splice(removeIndex, 1);
    }
  }

  getConditions(event): void {
    if (event.dataType === 'criteria' || event.dataType === 'specific') {
      this.conditions = event.conditions;
      this.conditionsInvalid = this.conditions.length === 0;
    }
    if (event.dataType === 'all') {
      this.conditionsInvalid = false;
      this.conditions = event.conditions;
    }
    this.companyDataType = event.dataType;
  }

  onChangeThemeFromList(event: MatAutocompleteSelectedEvent): void {
    this.themeSelect.setValue(event.option.value);
    this.themeControl.setValue('');
    this.pickANewTheme = true;
  }

  handleSubmit(): void {
    this.isLoading = true;
    const rpt = {
      name: this.reportForm.controls.name.value?.trim(),
      orientation: this.reportForm.controls.orientation?.value,
      reportType: 'dashboard',
      companyDataType: this.companyDataType,
      companiesMatching: this.isIndividual ? [] : this.conditions,
      theme: this.themeSelect.value,
      favorite: this.isEdit ? this.report.favorite : false,
    } as CreateReportPayload;

    if (rpt.theme) {
      rpt.theme.fontSize = this.reportForm.controls.fontSize.value.size;
    }
    if (!this.isEdit) {
      this.saveReport(rpt);
    }
    if (this.isEdit) {
      this.updateReport(rpt);
    }
  }

  saveReport(report: CreateReportPayload): void {
    this.reportService.createReport(report).subscribe({
      next: (res) => {
        this.reportId = res.id;
        this.newReportSettings = res;
      },
      error: (err) => {
        this.isLoading = false;
        this.notificationService.error(err?.message, 5000);
      },
      complete: () => {
        if (report.theme && report.theme.backgroundImageFile) {
          this.uploadImage(report.theme.backgroundImageFile, this.reportId);
        } else {
          this.isLoading = false;
          this.dialogRef.close();
          this.notificationService.success(
            this.translate.instant(
              'reporting.create_report.notification.report_created'
            ),
            3000
          );
          this.router.navigate([`/reports/${this.reportId}/edit`]).then();
        }
      },
    });
    //this.dialoRef.close();
  }

  uploadImage(imageFile?: any, themeId?: string): void {
    this.apiService
      .uploadPicture(imageFile, 'customThemeBackgroundImage', themeId)
      .subscribe({
        next: (res) => {
          // tslint:disable-next-line:triple-equals
          if (res.body != undefined) {
            this.handleBackgroundStore(imageFile, themeId);
            this.newReportSettings.theme.customThemeBackgroundImage =
              res.body.file;
          }
        },
        error: (err) => {
          this.notificationService.error(err.error.error_description, 5000);
        },
        complete: () => {
          this.notificationService.success(this.newReportNotification, 3000);
          if (this.isEdit) {
            this.handleBackgroundStore(imageFile, themeId);
            this.dialogRef.close({report: this.newReportSettings});
            this.notificationService.success(
              this.translate.instant(
                'reporting.create_report.notification.theme_updated'
              ),
              3000
            );
          } else {
            this.dialogRef.close();
            this.router.navigate([`/reports/${themeId}/edit`]);
          }
          this.isLoading = false;
        },
      });
  }

  updateReport(report: UpdateReportPayload): void {
    this.reportService.updateReport(report, this.report.id).subscribe({
      next: (res) => {
        this.newReportSettings = res;
      },
      error: (err) => {
        const errorMessage = this.translate.instant('common.something_wrong');
        this.notificationService.error(
          err?.error?.message || errorMessage,
          5000
        );
        this.isLoading = false;
      },
      complete: () => {
        this.closeReportDialog();
      },
    });
  }

  closeReportDialog(): void {
    this.isLoading = false;
    this.reportStorage.deleteTypeNoPage(this.newReportSettings.id);
    this.dialogRef.close({report: this.newReportSettings});
    this.notificationService.success(
      this.translate.instant(
        'reporting.create_report.notification.report_update'
      ),
      3000
    );
  }

  handleBackgroundStore(imgFile, themeId): void {
    const reader = new FileReader();
    reader.readAsDataURL(imgFile);
    reader.onload = (): void => {
      const bck = reader.result!.toString();
      this.reportStorage.handleCustomBackgroundPage(themeId, bck);
    };
  }

  hideShowCaret(): void {
    const caret = document.querySelector(
      '#cThemeSlt>.mat-select-trigger>.mat-select-arrow-wrapper'
    );
    this.loadingThemes
      ? caret!.classList.add('d-none')
      : caret?.classList.remove('d-none');
  }

  setLoading(event: boolean): void {
    setTimeout(() => {
      this.generalLoading = event && this.loadingThemes;
    }, 0);
  }
}
