import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  model,
  signal,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

export type StartAs = 'template' | 'custom';
// [x]: Signals implemented
@Component({
    selector: 'app-start-as',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './start-as.component.html',
    styleUrls: ['./start-as.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class StartAsComponent implements OnInit {
  public startAs = model<StartAs>('template');
  public prosTitle = signal<string>('');
  public prosFirstItem = signal<string>('');
  public prosSecondItem = signal<string>('');

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.onChangeStart('template');
  }

  onChangeStart(startAs: StartAs): void {
    this.startAs.set(startAs);
    this.prosTitle.set(
      `reporting.create_report.wizard_modal.start_as.${startAs}.pros_title`
    );
    this.prosFirstItem.set(
      `reporting.create_report.wizard_modal.start_as.${startAs}.pros_first_item`
    );
    this.prosSecondItem.set(
      `reporting.create_report.wizard_modal.start_as.${startAs}.pros_second_item`
    );
  }
}
