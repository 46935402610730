import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Connection} from '../../../models';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-connection-login-card',
    templateUrl: './connection-login-card.component.html',
    styleUrls: ['./connection-login-card.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule]
})
export class ConnectionLoginCardComponent implements OnInit {
  // Inputs / Outputs
  @Input() connection: Connection;
  @Input() isMobile: boolean;
  @Input() isIndividual: boolean;
  @Output() login: EventEmitter<Connection> = new EventEmitter<Connection>();

  constructor() {
  }

  ngOnInit(): void {
  }
  public signIn(connection: Connection): void {
    this.login.emit(connection);
  }
}
