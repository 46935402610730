import {Component} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-loading',
    template: `
    <div class="w-100 mt-2 text-center">
      <div class="pe-4">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ 'reporting.reportToolbar.loading_label' | translate }}
      </div>
    </div>
  `,
    styles: [],
    standalone: true,
    imports: [TranslateModule],
})
export class MobileLoadingComponent {}
