<div class="d-flex flex-column">
  @if (!isIndividual && !isMobile) {
    <mat-select
      class="wsm-input mb-4 w-100 filter-input"
      placeholder=" {{
        'shared.components.connection_login.filter_by' | translate
      }}"
      (selectionChange)="selectFilter($event.value)"
    >
      @for (filter of filters; track filter) {
        <mat-option [value]="filter">
          {{ filter.label }}
        </mat-option>
      }
    </mat-select>
  }

  <!--  Desktop Selection -->
  @if (!isMobile) {
    <article
      class="col"
      [ngClass]="{
        'ms-4': isIndividual && !isMobile,
        'connection-cards': !isMobile,
        'connection-cards-mobile': isMobile
      }"
    >
      @for (connection of filteredConnections; track connection) {
        <app-connection-login-card
          [connection]="connection"
          [isMobile]="isMobile"
          [isIndividual]="isIndividual"
          (login)="signIn($event)"
        >
        </app-connection-login-card>
      }
    </article>
  } @else {
    @for (filter of filters; track filter) {
      <article class="mb-3">
        @if (filter.id !== "all") {
          <h2 class="wsm-text-grayed category-title">{{ filter.label }}</h2>
        }
        <div
          class="col connection-cards-mobile"
          [ngClass]="{'ms-4': isIndividual && !isMobile}"
        >
          @for (connection of filteredConnections; track connection) {
            @if (filter?.id === connection.category) {
              <app-connection-login-card
                [connection]="connection"
                [isMobile]="isMobile"
                [isIndividual]="isIndividual"
                (login)="signIn($event)"
              >
              </app-connection-login-card>
            }
          }
        </div>
      </article>
    }
  }

  <!-- Mobile Cards -->
</div>
