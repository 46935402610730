import {
  Component,
  input,
  Input, model,
  OnChanges,
  output,
  SimpleChanges
} from "@angular/core";

import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgStyle, NgClass, AsyncPipe} from '@angular/common';
import {WidgetComponent} from '../widget/widget.component';
import {TranslateModule} from '@ngx-translate/core';
import {Widget} from 'src/app/shared/models/widget/widget.model';
import {ReportPage} from 'src/app/shared/models/report/report.model';
import {ReportTheme} from 'src/app/shared/models/report/report-theme.model';

@Component({
  selector: 'app-widget-preview',
  templateUrl: './widget-preview.component.html',
  styleUrls: ['./widget-preview.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, WidgetComponent, AsyncPipe, TranslateModule],
})
export class WidgetPreviewComponent implements OnChanges {
  // Inputs / Outputs
  @Input() showPreviewOverlay: boolean;
  @Input() widget: Widget;
  @Input() page: ReportPage;
  @Input() reportTheme: ReportTheme;
  @Input() theme: ReportTheme;
  @Input() pageTheme: ReportTheme;
  @Input() widgetStyleForm: UntypedFormGroup;
  @Input() reportId: string;
  @Input() height: string;
  @Input() width: string;
  @Input() sortChange: boolean;
  @Input() datasetFilterCtrl: UntypedFormControl;
  @Input() filterApplied: boolean;
  public showTableMessage = model<boolean>(false);
  public clickPreview = output<boolean>();
  public widgetSortChanged = output<Widget>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
    }
  }

  previewData(): void {
    this.clickPreview.emit(true);
  }

  onWidgetSortChanged(event: Widget): void {
    this.widgetSortChanged.emit(event);
  }

  onCloseMessage(): void {
    this.setHideAnimation();
    setTimeout((): void => {
      this.showTableMessage.set(false);
    }, 200);
  }

  private setHideAnimation(): void {
    const element = document.getElementById('table-preview-message');
    if (element) {
      element.classList.add('animate__fadeOutUp');
    }
  }
}
