import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import {ReportTheme, Widget} from '../../../../models';
import {WidgetService} from 'src/app/core/services';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgStyle, NgClass } from '@angular/common';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';

@Component({
    selector: 'app-heading',
    templateUrl: './heading.component.html',
    styleUrls: ['./heading.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        FormsModule,
        CdkTrapFocus,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
    ],
})
export class HeadingComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() theme: ReportTheme;
  @Input() isEditMode: boolean;
  @Input() mobileStatus: {isMobile: boolean; largeMobile: boolean};

  private widgetService = inject(WidgetService);

  isEditTitle = false;
  titleControl: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.isEditMode) {
        this.isEditTitle = changes.isEditMode.currentValue === false;
        this.titleControl && this.titleControl.setValue(this.widget.title);
        this.widgetService.setEditTitleWidgetTitle(false, this.widget.id);
      }
    }
  }

  ngOnInit(): void {
    this.titleControl = new FormControl<string>(
      this.widget.title ? this.widget.title : ''
    );

    this.widgetService.editTitleWidgetTitle$.subscribe({
      next: (res) => {
        this.isEditTitle = res.isEdit ? this.widget.id === res.widgetId : false;
        this.widget.isEditingInline = this.isEditTitle;
      },
    });
  }

  onSaveTitle(): void {
    this.widget = {
      ...this.widget,
      title: this.titleControl.value,
    };
    this.isEditTitle = false;
    this.widgetService.setEditTitleWidgetTitle(false, this.widget.id);
    this.widgetService.cascadeSaveWidget(this.widget);
  }

  onCloseEdit(): void {
    this.isEditTitle = false;
    this.widgetService.setEditTitleWidgetTitle(false, this.widget.id);
  }
}
