import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList, CdkDropListGroup, CdkDrag } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import {NotificationService} from '../../../core/services';
import {
  Filter,
  FilterOperator,
  IFilterLogicDialog,
  IScopeFilter,
  PossibleValue,
  ReportFilter,
  ScopeField,
  Widget,
} from '../../models';
import {checkIfArray, ScopeService} from '../../helpers';
import { MatMenuTrigger, MatMenu, MatMenuContent } from '@angular/material/menu';
import { MatChip, MatChipRemove } from '@angular/material/chips';
import { FilterOperatorComponent } from '../../components/filter-operator/filter-operator.component';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { FilterSelectionComponent } from '../../components/filter-selection/filter-selection.component';
import { JoinStringArrayPipe } from '../../pipes/join-string-array.pipe';

@Component({
    selector: 'app-filter-logic',
    templateUrl: './filter-logic.component.html',
    styleUrls: ['./filter-logic.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        MatMenuTrigger,
        CdkDropListGroup,
        CdkDropList,
        MatChip,
        CdkDrag,
        FilterOperatorComponent,
        MatIcon,
        MatChipRemove,
        NgClass,
        FilterSelectionComponent,
        MatMenu,
        MatMenuContent,
        MatDialogActions,
        TranslateModule,
        JoinStringArrayPipe,
    ],
})
export class FilterLogicComponent implements OnInit {
  // Children
  @ViewChild(CdkDropList) filterList: CdkDropList;

  // Properties
  filters: Array<FilterOperator> = [];
  availableFilters: ScopeField[] = [];
  isArray = checkIfArray;
  addingFilter: boolean;
  filterData;
  addFilter: boolean;
  filterFormStatus: string;

  constructor(
    public dialogRef: MatDialogRef<FilterLogicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterLogicDialog,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private changeDetectRef: ChangeDetectorRef,
    public scopeService: ScopeService
  ) {}

  ngOnInit(): void {
    if (this.data?.filters?.length > 0) {
      this.data = structuredClone(this.data);
      const notOrFilters: any[] = [];

      this.data.filters.forEach((filter: any): void => {
        if ('expressions' in filter) {
          if (filter?.expressions?.length > 0) {
            this.filters.push(filter);
          }
        } else {
          notOrFilters.push(filter);
        }
      });
      this.filters.push({operator: 'and', expressions: notOrFilters});
    } else {
      this.filters.push({operator: 'and', expressions: []});
    }
    this.availableFilters = (this.data.availableFilters as Filter[]) || [];
    this.reorderFilters();
  }

  public drop(event: CdkDragDrop<FilterOperator>): void {
    if (event.previousContainer == event.container) {
      moveItemInArray(
        event.container.data.expressions || [],
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const prevContainer = this.filters.find(
        (el: FilterOperator): boolean => el.order === event.item.data.listIndex
      )?.expressions;
      const newContainer = this.filters.find(
        (el: FilterOperator): boolean => el.order === event.container.data.order
      )?.expressions;
      transferArrayItem(
        prevContainer!,
        newContainer!,
        event.item.data.itemIndex,
        newContainer!.length
      );
    }
    this.reorderFilters();
  }

  addGroup(): void {
    this.filters.push({
      operator: 'or',
      expressions: [],
    });
    this.filters.forEach((el: FilterOperator, idx: number): void => {
      el.order = idx;
    });
  }

  editFilter(filterData, action: string): void {
    this.addingFilter = true;
    if (action === 'new') {
      this.filterFormStatus = 'INVALID';
    }
    this.filterData = filterData;
  }

  addSelectedFilter(): void {
    this.addFilter = true;
  }

  deleteGroup(filter: FilterOperator): void {
    if (filter) {
      if (filter.expressions && filter.expressions?.length >= 1) {
        filter.expressions?.forEach((f: ReportFilter) => {
          this.filters[0]?.expressions?.push(f);
        });
      }
      this.filters.splice(this.filters.indexOf(filter), 1);
      this.filters.forEach((el, index) => {
        el.order = index;
      });
    }

    if (this.data?.fromWidget) {
      this.updateFieldsOnChange();
    }
  }

  onSave(): void {
    const notEmptyFilters: Array<FilterOperator> = this.filters.filter(
      (filter: FilterOperator) =>
        filter?.expressions && filter?.expressions?.length >= 1
    );
    this.filters = [...notEmptyFilters];
    let newFilters: FilterOperator[] = this.filters.filter(
      (filter: FilterOperator): boolean => filter.operator !== 'and'
    );
    this.filters.forEach((filter: FilterOperator): void => {
      if (filter.operator === 'and' && filter.expressions) {
        newFilters.push(...filter.expressions);
      }
    });
    if (newFilters?.length > 0) {
      const orGroup: FilterOperator[] = newFilters.filter(
        (filter: FilterOperator) =>
          Object.prototype.hasOwnProperty.call(filter, 'expressions')
      );
      const andGroup: FilterOperator[] = newFilters.filter(
        (filter: FilterOperator) =>
          !Object.prototype.hasOwnProperty.call(filter, 'expressions')
      );
      newFilters = [...andGroup, ...orGroup];
    }
    this.dialogRef.close(newFilters);
  }

  reorderFilters(): void {
    if (this.filters?.length > 0) {
      const orGroup: FilterOperator[] = this.filters.filter(
        (filter: FilterOperator): boolean => filter.operator === 'or'
      );
      const andGroup: FilterOperator[] = this.filters.filter(
        (filter: FilterOperator): boolean => filter.operator === 'and'
      );
      this.filters = [...andGroup, ...orGroup];
      this.filters = this.filters.map(
        (el: FilterOperator, index: number): FilterOperator => {
          return {
            ...el,
            order: index,
          };
        }
      );
    }
  }

  onFilterApplied(data: IScopeFilter, index: number, subIndex: number): void {
    if (data !== null) {
      const newFilter: ReportFilter = {} as ReportFilter;

      let matchingField: ScopeField | undefined;

      if (this.data?.isPage) {
        matchingField = this.availableFilters?.find(
          (f: ScopeField) =>
            (typeof data?.filterField !== 'string' &&
              f.name === data?.filterField?.name) ||
            f.name === data?.filterField
        );
      } else {
        matchingField = this.availableFilters?.find(
          (f: ScopeField) =>
            (typeof data?.filterField !== 'string' &&
              f.id === data?.filterField?.id) ||
            f.id === data?.filterField
        );
      }

      if (matchingField) {
        newFilter.fieldId = null;
        if (this.data?.isFromWidgetEditor) {
          newFilter.fieldId = matchingField?.id ? matchingField?.id : null;
        }

        newFilter.fieldName = matchingField.name;
        newFilter.operator = data?.filterOperator;

        if (
          matchingField.hasPossibleValues ||
          (matchingField.possibleValues &&
            matchingField.possibleValues.length > 0)
        ) {
          if (typeof data.filterValue === 'string') {
            newFilter.value = data.filterValue;
          }
          if (typeof data.filterValue !== 'string') {
            newFilter.labels = data?.filterValue;
          }
        }

        if (!matchingField.hasPossibleValues) {
          newFilter.value = data.filterValue as string;
        }

        if (
          !matchingField.hasPossibleValues &&
          matchingField.possibleValues &&
          matchingField.possibleValues.length === 0
        ) {
          newFilter.value = data?.filterValue as string;
        }

        if ('filterLevel' in data) {
          newFilter.isPropagable = data?.filterLevel;
        }
      }

      if (newFilter.fieldId !== undefined) {
        if (data.filterIsNew) {
          if (
            this.filters[0].operator === 'and' &&
            this.filters[0].expressions &&
            this.filters[0].expressions?.length >= 0
          ) {
            this.filters[0].expressions.push(newFilter);
          } else {
            this.filters.push({
              operator: 'and',
              expressions: [newFilter],
            });
          }
          this.reorderFilters();
          if (!this.data.isMobile) {
            this.translate
              .get('notifications.filter_added')
              .subscribe((res: string) => {
                this.notificationService.success(res, 5000);
              });
          }
        } else {
          if (index >= 0) {
            this.filters[index].expressions![subIndex] = newFilter;
          }
          this.translate
            .get('notifications.filter_updated')
            .subscribe((res: string) => {
              this.notificationService.success(res, 5000);
            });
        }
      }
    }
    this.addingFilter = false;
    this.addFilter = false;
    if (this.data?.fromWidget) {
      this.updateFieldsOnChange();
    }
    this.changeDetectRef.detectChanges();
  }

  removeFilter(filter: ReportFilter, index: number): void {
    const targetFilter: FilterOperator = this.filters[index];

    if (targetFilter?.expressions && filter !== null) {
      const filterIndex: number = targetFilter.expressions.indexOf(filter);
      if (filterIndex !== -1) {
        targetFilter.expressions.splice(filterIndex, 1);
      }
    }
    if (this.data?.fromWidget) {
      this.updateFieldsOnChange();
    }
  }

  public possibleValuesChanged($event: ScopeField[]): void {
    this.availableFilters = $event || [];
  }

  updateFieldsOnChange(): void {
    const widget = {
      filters: this.filters,
    } as Widget;

    this.availableFilters = this.scopeService.onFieldSelectionChanged(
      widget,
      this.availableFilters
    )[0];
  }
}
