import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  model,
  output,
  signal,
} from '@angular/core';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mergeMap,
  of,
  Subject,
  Subscription,
  tap,
} from 'rxjs';
import {
  AuthenticationService,
  LoadingService,
  NotificationService,
  ReportService,
} from 'src/app/core/services';
import {
  AllTemplatePayload,
  CustomError,
  PageTemplate,
  ReportTemplate,
  WidgetTemplate,
} from 'src/app/shared/models';
import { AlphanumericDirective } from '../../../../../shared/directives/alphanumeric.directive';
import { NgClass } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { TemplateGalleryCardComponent } from '../../../../../shared/components/template-gallery-card/template-gallery-card.component';
import { LoadingComponent } from '../../../../../shared/components/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';

// [x]: Signals implemented
@Component({
    selector: 'app-to-select-template',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './to-select-template.component.html',
    styleUrls: ['./to-select-template.component.scss'],
    standalone: true,
    imports: [
        AlphanumericDirective,
        NgClass,
        InfiniteScrollDirective,
        TemplateGalleryCardComponent,
        LoadingComponent,
        TranslateModule,
    ],
})
export class ToSelectTemplateComponent implements OnInit {
  templateSelected = model<ReportTemplate | null>();
  changeTemplateSelected = output<boolean>();

  public keyUp = new Subject<any>();

  public templates = signal<ReportTemplate[]>([]);
  TemplateModel!: ReportTemplate;

  //pagination
  public pageSize = signal<number>(16);
  public page = signal<number>(1);
  public totalRecords = signal<number>(0);
  public keyword = signal<string>('');

  public isLoadingTemplates = signal<boolean>(true);
  public isLoadingMoreTemplates = signal<boolean>(false);

  public getTemplateSubscription: Subscription;

  constructor(
    private reportService: ReportService,
    private loader: LoadingService,
    private notificationService: NotificationService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.getTemplates({limit: this.pageSize(), page: this.page()});
    this.keyUp
      .pipe(
        map((event) => event.target.value),
        debounceTime(500),
        tap(() => {
          if (this.getTemplateSubscription) {
            this.getTemplateSubscription.unsubscribe();
          }
        }),
        distinctUntilChanged(),
        mergeMap((search) => of(search).pipe(delay(300)))
      )
      .subscribe({
        next: (value) => {
          this.keyword.set(value);
          this.templates.set([]);
          this.page.set(1);
          const payload = this.buildParamPayload();
          this.getTemplates(payload);
        },
      });
  }

  getTemplates(payload: AllTemplatePayload): void {
    this.isLoadingTemplates.set(true);
    this.getTemplateSubscription = this.reportService
      .getAllTemplates(payload)
      .subscribe({
        next: (res) => {
          this.templates.update((value) => {
            value = [...value, ...res.data];
            return value;
          });
          this.totalRecords.set(res.total);
          this.isLoadingTemplates.set(false);
          this.isLoadingMoreTemplates.set(false);
        },
        error: (err: CustomError) => {
          this.notificationService.error(err.message, 3000);
          this.isLoadingTemplates.set(false);
          this.isLoadingMoreTemplates.set(false);
        },
      });
  }

  buildParamPayload(): AllTemplatePayload {
    const payload = {
      limit: this.pageSize(),
      page: this.page(),
      search: [
        {
          fieldName: 'name',
          operator: 'contains',
          value: this.keyword() ? this.keyword() : '',
        },
        {
          fieldName: 'connections',
          operator: 'in',
          value: [],
        },
        {
          fieldName: 'labels',
          operator: 'in',
          value: [],
        },
        {
          fieldName: 'language',
          operator: 'in',
          value: [],
        },
        {
          fieldName: 'orientation',
          operator: 'in',
          value: [],
        },
      ],
    };

    return payload;
  }

  onScroll(): void {
    if (this.templates().length < this.totalRecords()) {
      this.isLoadingMoreTemplates.set(true);
      this.page.update((page) => page++);
      const payload = this.buildParamPayload();
      this.getTemplates(payload);
    }
  }

  // [x]: Signals implemented
  onTemplateSelected(
    event: ReportTemplate | PageTemplate | WidgetTemplate
  ): void {
    this.templateSelected.set(event as ReportTemplate);
    this.changeTemplateSelected.emit(!!event);
  }
}
