<div class="h-100">
  <header class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      @if (!isEdit) {
        <h1 class="h2 m-0">
          {{ "shared.dialogs.new_user.add_new_user_title" | translate }}
        </h1>
      }
      @if (isEdit) {
        <h1 class="h2 m-0">
          {{ "shared.dialogs.new_user.edit_user" | translate }}
        </h1>
      }

      <button
        (click)="closeDialog()"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    @if (isExternal && !isEdit) {
      <p>
        {{ "shared.dialogs.new_user.external_user_label" | translate }}
      </p>
    }
    @if (!isExternal && !isEdit) {
      <p>
        {{ "shared.dialogs.new_user.new_user_label" | translate }}
      </p>
    }
    @if (isEdit) {
      <p>
        {{ "shared.dialogs.new_user.edit_user_info" | translate }}
      </p>
    }
    <p class="mt-3">
      <small class="d-flex wsm-text-grayed">
        {{ "common.fields_required" | translate }}
      </small>
    </p>
  </header>

  <mat-dialog-content class="new-user-container blue-scroll-light">
    <section id="basic-info" class="wsm-card shadow-none mb-3">
      <form id="user-form" class="row mx-0" [formGroup]="userForm">
        <div
          class="new-user-content"
          [ngClass]="{'flex-column': data.isMobile}"
        >
          <div class="col">
            <label for="first_name">
              {{
                "shared.dialogs.new_user.firstname_label" | translate
              }}&nbsp;<span class="required-mark">*</span>
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              class="wsm-input validate d-block w-100"
              formControlName="firstName"
              placeholder="{{
                'shared.dialogs.new_user.firstname_label' | translate
              }}"
              autocomplete="off"
            />
            @if (
              userForm.get("firstName")?.errors?.["required"] &&
              userForm.get("firstName")?.dirty
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
          <div class="col">
            <label for="last_name">
              {{
                "shared.dialogs.new_user.lastname_label" | translate
              }}&nbsp;<span class="required-mark">*</span>
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              class="wsm-input validate d-block w-100"
              formControlName="lastName"
              placeholder="{{
                'shared.dialogs.new_user.lastname_label' | translate
              }}"
              autocomplete="off"
            />
            @if (
              userForm.get("lastName")?.errors?.["required"] &&
              userForm.get("lastName")?.dirty
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.field_required" | translate
                }}</span>
              </div>
            }
          </div>
          <div class="col">
            <label for="email">
              {{ "shared.dialogs.new_user.email_label" | translate }}&nbsp;<span
                class="required-mark"
                >*</span
              >
            </label>
            <input
              type="text"
              name="email"
              id="email"
              class="wsm-input validate d-block w-100"
              appLowercase
              formControlName="email"
              [defaultValue]="user?.email"
              placeholder="{{
                'shared.dialogs.new_user.email_label' | translate
              }}"
              autocomplete="off"
            />
            @if (
              userForm.get("email")?.errors?.["email"] ||
              (userForm.get("email")?.errors?.["required"] &&
                userForm.get("email")?.dirty)
            ) {
              <div class="mt-1">
                <span class="invalid-info">{{
                  "common.errors.valid_email_required" | translate
                }}</span>
              </div>
            }
          </div>
        </div>

        @if (!data?.externalUser) {
          <div class="row mx-0 mt-3">
            <div class="col-lg-4 col-md-4 col-sm-12 mb-2 align-self-end">
              <mat-checkbox formControlName="isAdmin">
                {{ "shared.dialogs.new_user.is_admin_label" | translate }}
              </mat-checkbox>
            </div>
          </div>
        }
      </form>
    </section>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-end">
    <button
      class="wsm-btn wsm-btn-outline me-3"
      (click)="closeDialog({type: 'back'})"
    >
      {{ "shared.dialogs.new_user.cancel_button" | translate }}
    </button>

    <button
      class="wsm-btn wsm-btn-primary submit-btn"
      (click)="onSubmit()"
      form="user-form"
    >
      @if (!isLoading && !isEdit) {
        <span>{{
          "shared.dialogs.new_user.create_user_button" | translate
        }}</span>
      }
      @if (!isLoading && isEdit) {
        <span>{{
          "shared.dialogs.new_user.update_user_button" | translate
        }}</span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  </mat-dialog-actions>
</div>
