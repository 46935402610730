@if (isLoaded()) {
  <section
    id="widget-preview"
    class="m-0 d-inline-block"
    [ngStyle]="{'height.px': widgetHeight(), 'width.px': widgetWidth()}"
  >
    @if (widget()) {
      <app-widget
        [reportId]="reportId()"
        [reportTheme]="reportTheme()"
        [pageTheme]="pageTheme()"
        [widget]="widget() | as: WidgetModel"
      ></app-widget>
    }
  </section>
}
