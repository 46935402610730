import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, from, map} from 'rxjs';

export interface FontawesomeToken {
  access_token: string;
  expires_in: number;
  scopes: Array<string>;
  token_type: string;
}

export interface FontawesomeIcon {
  id: string;
  label: string;
  style: 'regular' | 'solid' | 'brands' | 'kit';
  faClass: string;
  position: 'left' | 'right' | 'center_up' | 'center_down';
}

@Injectable({providedIn: 'root'})
export class FontawesomeService {
  private fontAwesomeUrl = 'https://api.fontawesome.com';
  private tokenKit = '2ef36c5464';
  private fontAwesomeTokenBehavior$ =
    new BehaviorSubject<FontawesomeToken | null>(null);
  private fontAwesomeAdclicksKit$ =
    new BehaviorSubject<Array<FontawesomeIcon> | null>(null);

  constructor(private httpClient: HttpClient) {}

  public setFontawesomeToken(token: FontawesomeToken): void {
    const faToken = JSON.stringify(token);
    localStorage.setItem('faToken', faToken);
    this.fontAwesomeTokenBehavior$.next(token);
  }

  public setAdlicksKit(kit: Array<FontawesomeIcon>): void {
    this.fontAwesomeAdclicksKit$.next(kit);
  }

  public async getFontawesomeToken(isFromWidgetEditor?: boolean): Promise<any> {
    const response = await fetch(`${this.fontAwesomeUrl}/token`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 32252566-08B1-49B0-85AC-405D67240C80',
      },
    });

    const token = response.ok ? response.json() : null;

    if (token) {
      token.then((res) => {
        this.setFontawesomeToken(res);
        if (!isFromWidgetEditor) {
          this.searchFontAwesomeIcons('');
        }
      });
      return token;
    }
  }

  private async promiseSearchFontAwesomeIcons(iconName: string): Promise<any> {
    const fontAwesomeVersion = '6.4.0';
    const query = {
      query: `query getIcon($version: String!, $iconName: String!) {
        search(version: $version, query:$iconName, first: 15) {
          id,
          label,
          familyStylesByLicense {
              pro{
                  family,
                  style
              }
          },
        }
      }`,
      variables: {
        version: fontAwesomeVersion,
        iconName: iconName,
      },
    };

    try {
      const response = await fetch(this.fontAwesomeUrl, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.fontAwesomeTokenBehavior$.getValue()
            ?.access_token}`,
        },
        body: JSON.stringify(query),
      });
      const json = response.ok ? response.json() : null;
      return json;
    } catch (e) {
      return console.error(e);
    }
  }

  private async promiseGetAdclicksKit(): Promise<any> {
    const query = {
      query: `query getMineIcons($token: String!){
        me{
            kit(token:$token){
                iconUploads{
                    name
                }
            }
        }
      }`,
      variables: {
        token: this.tokenKit,
      },
    };

    try {
      const response = await fetch(this.fontAwesomeUrl, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.fontAwesomeTokenBehavior$.getValue()
            ?.access_token}`,
        },
        body: JSON.stringify(query),
      });
      const json = response.ok ? response.json() : null;
      return json;
    } catch (e) {
      return console.error(e);
    }
  }

  public searchFontAwesomeIcons(
    iconName: string
  ): Observable<Array<FontawesomeIcon>> {
    const kitIcons =
      this.fontAwesomeAdclicksKit$ && this.fontAwesomeAdclicksKit$.getValue();
    const filteredKit = kitIcons?.filter((el) => {
      return el.label.toLowerCase().includes(iconName.toLowerCase());
    });

    return from(this.promiseSearchFontAwesomeIcons(iconName)).pipe(
      map((res) => {
        if (res.errors && res.errors[0].message === 'unauthorized') {
          this.getFontawesomeToken();
        }
        return res;
      }),
      map((res) => res?.data?.search),
      map((icons) => {
        return this.filterSearchIcons(icons);
      }),
      map((icons) => {
        if (filteredKit && filteredKit.length > 0) {
          return [...icons, ...filteredKit];
        }
        return icons;
      })
    ) as Observable<Array<FontawesomeIcon>>;
  }

  public getFontawesomeAdclicksKit(): Observable<Array<FontawesomeIcon>> {
    return from(this.promiseGetAdclicksKit()).pipe(
      map((res) => res?.data?.me?.kit?.iconUploads),
      map((iconsUpload) => {
        return this.buildKitArray(iconsUpload);
      })
    );
  }

  private buildKitArray(icons: Array<{name: string}>): Array<FontawesomeIcon> {
    return icons.reduce((acc: Array<FontawesomeIcon>, value) => {
      const newElement: FontawesomeIcon = {
        id: value.name,
        label: value.name,
        style: 'kit',
        faClass: `fak fa-${value.name}`,
        position: 'left',
      };
      acc = [...acc, newElement];
      return acc;
    }, []);
  }

  private filterSearchIcons(icons: Array<any>): Array<FontawesomeIcon> {
    return icons.reduce((icons: Array<FontawesomeIcon>, icon) => {
      icon.familyStylesByLicense.pro.forEach((el) => {
        if (el.family === 'classic') {
          if (
            el.style === 'solid' ||
            el.style === 'regular' ||
            el.style === 'brands'
          ) {
            const newIcon: FontawesomeIcon = {
              id: icon.id,
              label: icon.label,
              style: el.style,
              faClass: `fa-${el.style} fa-${icon.id}`,
              position: 'left',
            };
            icons = [...icons, newIcon];
          }
        }
      });
      return icons;
    }, []);
  }
}
