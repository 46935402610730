<section [ngClass]="{'company-container': isFromDialog}">
  <form id="company-form" [formGroup]="newCompanyForm" (ngSubmit)="onSubmit()">
    <article class="row mx-0">
      @if (isFromStarted) {
        <div class="col-12 mb-2">
          <p class="mb-0">
            <small class="d-flex wsm-text-grayed">
              {{ "common.fields_required" | translate }}
            </small>
          </p>
        </div>
      }

      <!-- Name -->
      <div class="col-12 col-md-6 mb-3">
        <label for="company-name">
          {{
            "shared.dialogs.new_company.company_name_label" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <input
          id="company-name"
          formControlName="name"
          type="text"
          autocomplete="off"
          class="wsm-input w-100"
          [class.border-danger]="
            newCompanyForm.get('name')?.errors?.['required'] &&
            newCompanyForm.get('name')?.touched
          "
          placeholder="{{
            'shared.dialogs.new_company.company_name_placeholder' | translate
          }}"
        />
        @if (
          newCompanyForm.get("name")?.errors?.["required"] &&
          newCompanyForm.get("name")?.touched
        ) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>

      <!-- Website -->
      <div class="col-12 col-md-6 mb-3">
        <label for="website">
          {{
            "shared.dialogs.new_company.website_label" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <input
          id="website"
          formControlName="website"
          type="text"
          autocomplete="off"
          class="wsm-input w-100"
          [class.border-danger]="
            newCompanyForm.get('website')?.errors?.['required'] &&
            newCompanyForm.get('website')?.touched
          "
          placeholder="{{
            'shared.dialogs.new_company.website_placeholder' | translate
          }}"
        />
        @if (
          newCompanyForm.get("website")?.errors?.["required"] &&
          newCompanyForm.get("website")?.touched
        ) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>

      <!-- Currency -->
      <div class="col-12 col-md-6 mb-3">
        <label for="currency">
          {{
            "shared.dialogs.new_company.currency_label" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <mat-select
          id="currency"
          formControlName="currency"
          class="wsm-input"
          [class.border-danger]="
            newCompanyForm.get('currency')?.errors?.['required'] &&
            newCompanyForm.get('currency')?.touched
          "
          placeholder="{{
            'shared.dialogs.plan_cancelation.feedback_options.select_option'
              | translate
          }}"
          [panelClass]="'wsm-custom-select'"
        >
          @for (item of currencyList; track item) {
            <mat-option [value]="item?.code">
              {{ item?.code }} - {{ item?.name! | translate }}
            </mat-option>
          }
        </mat-select>
        @if (
          newCompanyForm.get("currency")?.errors?.["required"] &&
          newCompanyForm.get("currency")?.touched
        ) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
      </div>

      <!-- Industry -->
      <div class="col-12 col-md-6 mb-3">
        <label for="industry">
          {{
            "shared.dialogs.new_company.industry_label" | translate
          }}&nbsp;<span class="required-mark">*</span>
        </label>
        <div class="wsm-input-group-prepend w-100">
          <i
            [ngClass]="{'d-none': !industriesLoading}"
            class="fa-solid fa-circle-notch fa-spin wsm-text-white ac-loading"
          >
          </i>
          <input
            id="industry"
            type="text"
            class="wsm-input w-100"
            [class.border-danger]="
              (newCompanyForm.get('industry')?.errors?.['required'] &&
                newCompanyForm.get('industry')?.touched) ||
              (newCompanyForm.get('industry')?.touched &&
                selectedIndustryFullName !==
                  newCompanyForm.get('industry')?.value)
            "
            placeholder="{{
              'shared.dialogs.new_company.industry_placeholder' | translate
            }}"
            formControlName="industry"
            [matAutocomplete]="industryAutocomplete"
          />
          <mat-autocomplete
            #industryAutocomplete="matAutocomplete"
            [panelWidth]="'unset'"
            [class]="'industry-select'"
            (optionSelected)="onIndustrySelect($event)"
          >
            @for (
              option of filteredOptions[fieldTypes.indexOf("industry")] | async;
              track option
            ) {
              @if ((option | as: IndustryGroupModel).category) {
                <mat-optgroup
                  [label]="(option | as: IndustryGroupModel).category"
                >
                  @for (
                    subcategory of (option | as: IndustryGroupModel)
                      .subcategories;
                    track subcategory
                  ) {
                    <mat-option
                      [value]="{
                        category: (option | as: IndustryGroupModel).category,
                        subcategory,
                      }"
                      [innerHTML]="subcategory | highlight: toHighlight"
                    >
                    </mat-option>
                  }
                </mat-optgroup>
              }
            }
          </mat-autocomplete>
        </div>
        @if (
          newCompanyForm.get("industry")?.errors?.["required"] &&
          newCompanyForm.get("industry")?.touched
        ) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.field_required" | translate
            }}</span>
          </div>
        }
        @if (
          newCompanyForm.get("industry")?.touched &&
          selectedIndustryFullName !== newCompanyForm.get("industry")?.value
        ) {
          <div class="mt-1">
            <span class="invalid-info">{{
              "common.errors.invalid_option_selected" | translate
            }}</span>
          </div>
        }
      </div>
    </article>

    <!-- Advanced Settings -->
    <mat-expansion-panel [expanded]="true" class="custom-client-panel">
      <mat-expansion-panel-header style="background: transparent !important">
        <mat-panel-title>
          {{ "shared.dialogs.new_company.advanced_settings" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <article class="row mx-0 mt-2">
        <!-- External ID -->
        <div class="col-12 col-md-6 mb-3">
          <label for="external-id">
            {{ "shared.dialogs.new_company.external_id_label" | translate }}
          </label>
          <input
            id="external-id"
            formControlName="externalId"
            type="text"
            autocomplete="off"
            class="wsm-input w-100"
            placeholder="{{
              'shared.dialogs.new_company.external_id_placeholder' | translate
            }}"
          />
        </div>

        <!-- Lifecycle Stage -->
        <div class="col-12 col-md-6 mb-3">
          <label for="lifecycle">
            {{ "shared.dialogs.new_company.lifecycle_label" | translate }}
          </label>
          <mat-select
            id="lifecycle"
            formControlName="lifecycle"
            class="wsm-input"
            [panelClass]="'wsm-custom-select'"
            placeholder="{{
              'shared.dialogs.new_company.lifecycle_placeholder' | translate
            }}"
          >
            @for (item of clientService.getLifeCycle(); track item) {
              <mat-option [value]="item?.value">
                {{ item.ngxId | translate }}
              </mat-option>
            }
          </mat-select>
        </div>

        <!-- Parent Company Autocomplete -->
        <div class="col-12 col-md-6 mb-3">
          <div class="company-form-input mb-3">
            <label for="parentCompany">
              {{
                "shared.dialogs.new_company.parent_company_label" | translate
              }}
            </label>
            <div class="wsm-input-group-prepend w-100">
              <input
                type="text"
                id="parentCompany"
                class="wsm-input w-100"
                appBInput
                [inputType]="'input'"
                [inputStatus]="parentLoading ? 'loading' : 'default'"
                [selectValue]="newCompanyForm.get('parentCompany')?.value"
                placeholder="{{
                  'shared.dialogs.new_company.parent_company_placeholder'
                    | translate
                }}"
                formControlName="parentCompany"
                [matAutocomplete]="parentCompanyAutocomplete"
              />
              <mat-autocomplete
                #parentCompanyAutocomplete="matAutocomplete"
                class="mat-menu-gray"
                (optionSelected)="onParentCompanySelect($event)"
              >
                @for (
                  parentCompany of filteredOptions[
                    fieldTypes.indexOf("parentCompany")
                  ] | async;
                  track parentCompany
                ) {
                  @if ((parentCompany | as: ClientDataModel).name) {
                    <mat-option
                      [value]="parentCompany | as: ClientDataModel"
                      [innerHtml]="
                        (parentCompany | as: ClientDataModel).name
                          | highlight: toHighlight
                      "
                    >
                    </mat-option>
                  }
                }
              </mat-autocomplete>
              @if (parentNoResults) {
                <span class="invalid-info">
                  {{
                    "shared.dialogs.new_company.not_company_found" | translate
                  }}
                </span>
              }
            </div>
          </div>
        </div>

        <!-- Marketing Objective -->
        <div class="col-12 col-md-6 mb-3">
          <label for="marketing-objective">
            {{ "shared.dialogs.new_company.objective_label" | translate }}
          </label>
          <mat-select
            id="marketing-objective"
            formControlName="marketingObjective"
            [multiple]="true"
            class="wsm-input"
            placeholder="{{
              'shared.dialogs.new_company.objective_placeholder' | translate
            }}"
            [panelClass]="'wsm-custom-select'"
          >
            <mat-select-trigger>
              {{
                newCompanyForm?.controls?.marketingObjective?.value
                  ? clientService
                      .getValueMarketingObjectives(
                        newCompanyForm?.controls?.marketingObjective?.value
                      )
                      .slice(0, 2)
                  : ""
              }}
              @if (
                newCompanyForm?.controls?.marketingObjective?.value.length > 2
              ) {
                <span class="additional-selection">
                  (+{{
                    newCompanyForm?.controls?.marketingObjective?.value.length -
                      2
                  }}
                  )
                </span>
              }
            </mat-select-trigger>
            @for (item of marketingObjectives; track item) {
              <mat-option [value]="item.value">
                {{ item.ngxId | translate }}
              </mat-option>
            }
          </mat-select>
        </div>
      </article>
    </mat-expansion-panel>
  </form>
</section>

@if (isFromDialog) {
  <section class="d-flex align-items-center justify-content-end pt-2">
    @if (billingInfo?.recurrence === "YEARLY") {
      <div class="check-circle rounded-circle me-2">
        <i class="fa-regular fa-check"></i>
      </div>
      <span class="me-3">
        {{ "shared.dialogs.new_company.available_clients" | translate }}
        {{ availableClients }}
      </span>
    }
    <button
      id="createCompanyBtn"
      class="wsm-btn wsm-btn-primary submit-btn"
      type="submit"
      form="company-form"
    >
      @if (!isLoading) {
        <span>
          {{ "shared.dialogs.new_company.create_company_button" | translate }}
        </span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  </section>
}
