@if (playerStyle === "classic") {
  <app-classic-player
    [audioUrl]="audioUrl"
    [reportTheme]="reportTheme"
    [getTimeCodeFromNum]="convertSeconds"
    (hidePlayer)="closePlayer($event)"
  />
}

@if (playerStyle === "modern") {
  <app-modern-player
    [audioUrl]="audioUrl"
    [reportTheme]="reportTheme"
    [getTimeCodeFromNum]="convertSeconds"
    (hidePlayer)="closePlayer($event)"
  />
}
