import {Component, Input, OnInit} from '@angular/core';
import {ReportTheme, WidgetPopoverData} from '../../../models';
import { NgClass, PercentPipe } from '@angular/common';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { PopoverContentComponent } from '../popover-content/popover-content.component';

@Component({
    selector: 'app-widget-popover',
    templateUrl: './widget-popover.component.html',
    styleUrls: ['./widget-popover.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        SatPopoverModule,
        PopoverContentComponent,
        PercentPipe,
    ],
})
export class WidgetPopoverComponent implements OnInit {
  // Inputs / Output
  @Input() popoverData: WidgetPopoverData;
  @Input() pipeType: string;
  @Input() theme: ReportTheme = {};
  @Input() isFromGallery = false;

  ngOnInit(): void {
    this.popoverData.isNeutral =
      typeof this.popoverData?.previousValue === 'number' &&
      this.popoverData?.deltaPercent === 0;
  }

  formatDiff(diffPercent: string | null): number {
    return parseFloat(diffPercent?.replace('%', '') as string);
  }
}
