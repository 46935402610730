<div
  class="profile-picture"
  [style.background-color]="
    createdBy?.avatarBackground ? createdBy?.avatarBackground : '#fc7282'
  "
>
  <img
    class="profile-picture"
    [ngClass]="{'avatar': !createdBy?.picture, 'image': createdBy?.picture}"
    [src]="
      createdBy?.picture
        ? fullApiUrl + createdBy?.picture
        : createdBy?.avatarImage || defaultImage
    "
    alt="User Picture"
  />
</div>
