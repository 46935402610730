import {
  Component, ElementRef, EventEmitter, Input,
  OnChanges, OnDestroy, OnInit,
  Output, QueryList, SimpleChanges,
  ViewChild, ViewChildren
} from '@angular/core';
import {Observable, startWith, Subscription} from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocompleteOrigin, MatAutocomplete } from "@angular/material/autocomplete";
import {FormulaElement, FormulaSubmitResult, Operator, ScopeField} from "../../models";
import { AbstractControl, UntypedFormArray, UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialog, MatDialogClose, MatDialogTitle } from "@angular/material/dialog";
import {ReportingDatasetsService} from "../../../core/services";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import { NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-formula-builder',
    templateUrl: './formula-builder.component.html',
    styleUrls: ['./formula-builder.component.scss'],
    standalone: true,
    imports: [NgClass, MatDialogClose, MatDialogTitle, MatTooltip, MatChipListbox, MatChipOption, MatIcon, MatChipRemove, NgTemplateOutlet, FormsModule, MatAutocompleteTrigger, MatAutocompleteOrigin, ReactiveFormsModule, NgxIntlTelInputModule, MatAutocomplete, MatOption, AsyncPipe, TranslateModule]
})
export class FormulaBuilderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isInline: boolean;
  @Input() fields: ScopeField[];
  @Input() isMobile: boolean;
  @Output() resultFormula = new EventEmitter<FormulaSubmitResult>();
  @Input() submitEvent: EventEmitter<any>;
  @Output() formulaIsEmpty = new EventEmitter<boolean>();
  @Input() allFormulaElements: FormulaElement[];

  @ViewChildren('formulaInput') inputs: QueryList<any>;
  @ViewChildren(MatAutocompleteTrigger) autos: QueryList<MatAutocompleteTrigger>;
  @ViewChild('mainInput') mainInput: any;

  visible = true;
  subscription = new Subscription();
  selectable = true;
  removable = true;
  left = 'LEFT';
  right = 'RIGHT';
  filteredFields: Observable<ScopeField[]>[] = [];
  availableOperators: string[] = ['(', ')', '+', '-', '*', '/'];
  lastActiveInputIndex = 0;
  lastActiveInput: any = {};
  formulaControls = new UntypedFormArray([]);
  formulaHasError: boolean;

  operators: Operator[] = [
    {
      operatorLabel: '+',
      operatorName: 'sum'
    },
    {
      operatorLabel: '-',
      operatorName: 'subtract'
    },
    {
      operatorLabel: '*',
      operatorName: 'multiply'
    },
    {
      operatorLabel: '/',
      operatorName: 'divide'
    }
  ];

  private filterFields(value: string): ScopeField[] {
    const filterValue = value.toLowerCase();

    return this.fields.filter(field => field.name.toLowerCase().indexOf(filterValue) === 0);
  }

  constructor(public dialog: MatDialog,
              private reportingService: ReportingDatasetsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.subscription.add(this.submitEvent.subscribe(this.onSubmitFormula.bind(this)));
    if(this.isMobile) {
      const mobileBody = document.querySelector('.wsm-mobile-body') as HTMLElement;
      mobileBody.style.overflowX = 'hidden';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allFormulaElements && changes.allFormulaElements.currentValue) {
      this.formulaControls.clear();
      this.initializeFormulaElements();
    }

    if (changes.fields && changes.fields.currentValue && changes.fields.previousValue === null) {
      this.formulaControls.push(this.addEmptyControl(0));
      this.fields = changes.fields?.currentValue;
    }
  }

  ngOnDestroy(): void {
    if(this.isMobile) {
      const mobileBody = document.querySelector('.wsm-mobile-body') as HTMLElement;
      mobileBody.style.overflowX = 'auto';
    }
    this.subscription.unsubscribe();
  }

  initializeFormulaElements(): void {
    if (this.fields && (!this.allFormulaElements ||
      (this.allFormulaElements.length > 0 && this.allFormulaElements[0].type !== 'if'))) {
      this.formulaControls.push(this.addEmptyControl(0));
    }

    if (this.allFormulaElements) {
      this.allFormulaElements.forEach((element, index) => {
        this.formulaControls.push(new UntypedFormControl(element));
        if (element.type !== 'if') {
          this.formulaControls.push(this.addEmptyControl(index * 2 + 2));
        }
      });
    }

    if (this.allFormulaElements.length === 0 && this.fields) {
      this.clearFormula();
    }
  }

  add(event, index: number, control): void {
    const value = (event.target.value || '').trim();
    this.addNewControl(value, index, control);
  }

  addOperator(value: string): void {
    if (typeof this.formulaControls.value[0] === 'object') {
      this.clearFormula();
    }
    this.addNewControl(value, this.lastActiveInputIndex, this.formulaControls.controls[this.lastActiveInputIndex]);
  }

  selected(event: MatAutocompleteSelectedEvent, index: number, control: AbstractControl): void {
    const value = (event.option.viewValue || '').trim();
    this.addNewControl(value, index, control);
  }

  addNewControl(value: string, index: number, control: AbstractControl): void {
    if (value !== undefined && value !== '' && value !== null) {
      for (const field of this.fields) {
        if (field.name.trim().toLowerCase() === value.toLowerCase()) {
          this.formulaControls.insert(index + 1, new UntypedFormControl(
            {
              type: 'field',
              name: field.name,
              id: field.id
            }));

          this.createNewInputOnAdd(index + 2, control);
          return;
        }
      }

      for (const operator of this.availableOperators) {
        if (operator === value) {
          const opId = 'op' + this.formulaControls.controls.length + value;
          this.formulaControls.insert(index + 1, new UntypedFormControl(
            {
              type: 'operator',
              name: value,
              id: opId
            }));

          this.createNewInputOnAdd(index + 2, control);
          return;
        }
      }

      if (value.match(/^\d*\.?\d*%?$/)) {
        const opId = 'op' + this.formulaControls.controls.length + value;
        this.formulaControls.insert(index + 1, new UntypedFormControl(
          {
            type: 'operand',
            name: value,
            id: opId
          }));

        this.createNewInputOnAdd(index + 2, control);
      }
    }
  }

  addEmptyControl(index): UntypedFormControl {
    const control = new UntypedFormControl('');
    this.addAutocomplete(control, index);
    return control;
  }

  addAutocomplete(control, index): void {
    this.filteredFields.splice(index, 0, control.valueChanges.pipe(
      startWith(''),
      map((filteredFields: string) =>
        filteredFields ? this.filterFields(filteredFields) : this.fields?.slice()
      )
    ));
    this.emitIsEmpty();
  }

  createNewInputOnAdd(index, control): void {
    this.filteredFields.splice(index, 0, new Observable<ScopeField[]>());
    this.formulaControls.insert(index, this.addEmptyControl(index));
    setTimeout(() => this.focusOnInput(index), 0);
    control.setValue('');
  }

  onArrowNavigation(event: Event, control: AbstractControl, direction: string): void {
    let selectionStart: any;
    let length = 0;
    if (event.target !== null) {
      selectionStart = (event.target as HTMLInputElement).selectionStart;
      if (selectionStart === undefined) {
        selectionStart = 0;
      }
      if ((event.target as HTMLInputElement).value !== undefined) {
        length = (event.target as HTMLInputElement).value.length;
      }
    }
    if (selectionStart == null) {
      return;
    }
    if ((direction === this.left && selectionStart === 0) || (direction === this.right && selectionStart === length)) {
      const currentIndex = this.getControlIndex(control);
      const newIndex = direction === 'LEFT' ? currentIndex - 2 : currentIndex + 2;
      this.focusOnInput(newIndex);
    }
  }

  focusOnInput(newIndex: number): void {
    if (newIndex >= 0 && newIndex < this.formulaControls.controls.length) {
      if ((this.inputs.toArray()[newIndex] as ElementRef).nativeElement !== undefined) {
        (this.inputs.toArray()[newIndex] as ElementRef).nativeElement.focus();
      }
      this.autos.forEach(auto => {
        auto.closePanel();
      });
    }
  }

  onBackSpaceNavigation(event: Event, controls: AbstractControl): void {
    const controlIndex = this.getControlIndex(controls);

    let currentVal = '';
    if (event.target !== null) {
      currentVal = (event.target as HTMLInputElement).value;
    }

    if (currentVal.length === 0) {
      event.stopPropagation();
      if (controlIndex >= 2) {
        this.formulaControls.removeAt(controlIndex);
        this.formulaControls.removeAt(controlIndex - 1);
        this.filteredFields.splice(controlIndex, 1);
        this.filteredFields.splice(controlIndex - 1, 1);
        this.focusOnInput(controlIndex - 2);
      }
    }

    this.emitIsEmpty();
  }

  getControlIndex(control): number {
    return this.formulaControls.controls.indexOf(control);
  }

  setControl(control): UntypedFormControl {
    return control as UntypedFormControl;
  }

  onBlurEverythingElse(index: number): void {
    this.lastActiveInputIndex = index;
  }

  clearFormula(): void {
    this.formulaControls.clear();
    this.filteredFields = [];
    this.formulaControls.push(this.addEmptyControl(0));
    this.lastActiveInputIndex = 0;
    this.emitIsEmpty();
  }

  onLostClick(e: Event): void {
    if (this.fields) {
      if (this.mainInput.nativeElement === e.target && this.inputs.last.nativeElement) {
        this.inputs.last.nativeElement.focus();
      }
    }
  }

  remove(index): void {
    this.formulaControls.removeAt(index + 1);
    this.formulaControls.removeAt(index);
    this.filteredFields.splice(index + 1, 1);
    this.filteredFields.splice(index, 1);
    this.focusOnInput(index - 1);

    if (this.formulaControls.length === 0) {
      this.formulaControls.push(this.addEmptyControl(0));
    }

    this.emitIsEmpty();
  }

  openFormulaDialog(isEdit): void {
    /* const allData = isEdit ? {
      fields: this.fields,
      operators: this.operators,
      customExpression: this.formulaControls.controls[0].value.data
    } : {fields: this.fields, operators: this.operators};

    const dialogRef = this.dialog.open(FormulaDialogComponent,
      {
        data: allData,
        width: '60vw',
        autoFocus: false
      });

    const dialogSub = this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.formulaControls.clear();
        this.formulaControls.insert(0, new FormControl(
          {
            type: 'if',
            name: 'Custom IF Statement',
            id: 'customIfLogicFx',
            data: res.data
          }));
        this.emitIsEmpty();
      }
      dialogSub.unsubscribe();
    }); */
  }

  emitIsEmpty(): void {
    const formulaElements = this.formulaControls.value.filter(value => typeof value === 'object');
    this.formulaIsEmpty.emit(formulaElements.length === 0);
  }

  onSubmitFormula(): void {
    const formulaElements = this.formulaControls.value.filter(value => typeof value === 'object');
    const expressionString = this.buildExpressionString(formulaElements);
    this.reportingService.convertExpressions(formulaElements).subscribe({
      next: customExpression => {
        this.resultFormula.emit({customExpression, formulaElements, expressionString});
      },
      error: () => {
        this.formulaHasError = true;
      },
      complete: () => {
        this.formulaHasError = false;
      }
    });
  }

  buildExpressionString(formulaElements: FormulaElement[]): string {
    return formulaElements.reduce((acc, formulaElement) => {
      return acc + ' ' + formulaElement.name;
    }, '').trim();
  }
}
