import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';

import {IServiceProviderConfiguration} from '../../models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-service-provider-config',
    templateUrl: './service-provider-config.component.html',
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        MatDialogActions,
        TranslateModule,
    ],
})
export class ServiceProviderConfigComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IServiceProviderConfiguration,
    public dialogRef: MatDialogRef<ServiceProviderConfigComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  public onClose(): void {
    this.dialogRef.close();
  }
}
