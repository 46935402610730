<section class="d-flex align-items-end h-100 position-relative">
  <article class="add-filter-chip" [ngClass]="{'pt-1': isMobile}">
    <div class="me-2">
      @if (!isMobile) {
        <mat-chip
          [matMenuTriggerFor]="btnFilterMenu"
          [disabled]="!page.availableFilters || isLoading"
          class="d-inline-flex align-items-center"
        >
          <i class="fa-regular fa-filter me-1"></i>
          {{ "reporting.report_page.add_filter" | translate }}
        </mat-chip>
      }

      @if (isMobile) {
        <div class="filter-chip-container position-relative">
          @if (page?.filters?.length! > 0 && !isLoading) {
            <div class="notification-bubble counter">
              <span>{{ page.filters?.length! }}</span>
            </div>
          }
          <mat-chip
            (click)="addFilterLogic()"
            [disabled]="!page.availableFilters || isLoading"
            class="d-inline-flex align-items-center"
            [ngClass]="{'wsm-mat-chip': isMobile && page?.filters?.length! > 0}"
          >
            <i class="fa-regular fa-filter me-1"></i>
            {{ "reporting.report_page.add_filter" | translate }}
          </mat-chip>
        </div>
      }

      <mat-menu #btnFilterMenu="matMenu">
        <ng-template matMenuContent>
          <button
            [matMenuTriggerData]="{index: -1}"
            [matMenuTriggerFor]="filterMenu"
            mat-menu-item
          >
            {{ "reporting.report_page.basic_filter_button" | translate }}
          </button>
          <button (click)="addFilterLogic()" mat-menu-item>
            {{ "reporting.report_page.edit_filter_logic_button" | translate }}
          </button>
        </ng-template>
      </mat-menu>
    </div>
  </article>

  @if (!isMobile) {
    <article class="filter-list">
      @for (
        filter of page.filters;
        track i;
        let i = $index;
        let first = $first;
        let last = $last
      ) {
        <!-- "or" filters -->
        @if (filter?.expressions) {
          <div
            class="ms-1 p-1 filter-item d-flex align-items-center"
            [ngClass]="{
              'me-2': last,
              'ms-2': first,
              'or-container': filter?.expressions?.length! > 0,
            }"
          >
            @for (fl of filter.expressions; track j; let j = $index) {
              <mat-chip
                [disabled]="isLoading || !page.availableFilters"
                (removed)="removeFilter(filter, i, fl)"
                [matMenuTriggerData]="{
                  index: i,
                  subIndex: j,
                  currentFilter: fl,
                }"
                [matMenuTriggerFor]="filterMenu"
                [removable]="true"
                class="wsm-mat-chip d-inline-flex"
              >
                {{ fl.fieldName }}
                <app-filter-operator [filter]="fl" />
                @if (fl.value) {
                  <span>{{ fl.value | joinStringArray }} </span>
                }
                @if (fl.labels) {
                  <span>{{ fl.labels | joinStringArray }} </span>
                }
                <mat-icon matChipRemove>close</mat-icon>
              </mat-chip>
              @if (j < filter.expressions?.length! - 1) {
                <span class="badge mx-1"> OR </span>
              }
            }
          </div>
          @if (
            i < page.filters?.length! - 1 && filter?.expressions?.length! > 0
          ) {
            <div class="ms-1 d-flex justify-content-center">
              <span class="badge mx-1">AND</span>
            </div>
          }
        } @else {
          <div
            class="filter-item"
            [ngClass]="{'ms-1 p-1': !first, 'me-2': last, 'ms-2': first}"
          >
            <div class="d-flex align-items-center">
              <mat-chip
                [disabled]="isLoading || !page.availableFilters"
                (removed)="removeFilter(filter, i)"
                [matMenuTriggerData]="{index: i, currentFilter: filter}"
                [matMenuTriggerFor]="filterMenu"
                [removable]="true"
                class="wsm-mat-chip d-inline-flex"
              >
                {{ filter.fieldName }}
                <app-filter-operator [filter]="filter" />
                @if (filter.value) {
                  <span>{{ filter.value | joinStringArray }}</span>
                }
                @if (filter.labels) {
                  <span>{{ filter.labels | joinStringArray }}</span>
                }
                <mat-icon matChipRemove>close</mat-icon>
              </mat-chip>
              @if (i < page.filters?.length! - 1) {
                <span class="badge mx-1"> AND </span>
              }
            </div>
          </div>
        }
        <!-- "and" filters -->
      }
    </article>
  }

  @if (!isMobile) {
    <div class="start-shadow d-inline"></div>
  }
  @if (!isMobile) {
    <div class="end-shadow d-inline"></div>
  }

  <mat-menu #filterMenu="matMenu" class="menu-container">
    <ng-template
      let-currentFilter="currentFilter"
      let-index="index"
      let-subIndex="subIndex"
      matMenuContent
    >
      <app-filter-selection
        (filterCompleted)="onFilterApplied($event, index, subIndex)"
        [availableFilters]="page.availableFilters!"
        [currentFilter]="currentFilter"
        [isFromPage]="true"
        [connections]="pageConnections"
      />
    </ng-template>
  </mat-menu>
</section>
