import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {
  LangChangeEvent,
  TranslateService,
  TranslateModule,
} from '@ngx-translate/core';

import {CardData, ReportTheme, Widget} from '../../../../models';
import {HelperService} from '../../../../helpers';
import {AuthenticationService, MobileService} from 'src/app/core/services';
import {ResizedDirective} from '../../../../directives/resized.directive';
import {NgClass} from '@angular/common';
import {AdPreviewComponent} from '../../ad-preview/ad-preview.component';
import {CardCarouselComponent} from '../../../../mobile/components/card-carousel/card-carousel.component';

@Component({
  selector: 'app-ad-gallery',
  templateUrl: './ad-gallery.component.html',
  styleUrls: ['./ad-gallery.component.scss'],
  standalone: true,
  imports: [
    ResizedDirective,
    NgClass,
    AdPreviewComponent,
    CardCarouselComponent,
    TranslateModule,
  ],
})
export class AdGalleryComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetForm: any;
  @Input() theme: ReportTheme = {};

  // Properties
  subs: Subscription = new Subscription();
  helpUrl: string;
  isExternalUser: boolean;
  adCardData: CardData = {labels: [], elements: []};

  // State
  isMobile: boolean;

  constructor(
    private helperService: HelperService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
    private authService: AuthenticationService,
    private mobileService: MobileService
  ) {
    this.isExternalUser = this.authService.checkRole(['ROLE_EXTERNAL']);
    translate.onLangChange.subscribe((event: LangChangeEvent): void => {
      this.helpUrl = this.helperService.getSupportUrl(event.lang);
    });
  }

  ngOnInit(): void {
    this.subs.add(
      this.mobileService.getMobileBehavior().subscribe((res: boolean): void => {
        this.isMobile = res;
      })
    );

    this.helpUrl = this.helperService.getSupportUrl(this.translate.currentLang);
    this.transformData();
  }

  ngAfterViewInit(): void {
    setTimeout((): void => {
      this.resizeAllGridItems();
      this.waitForImages();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.transformData();
      this.resizeAllGridItems();
      this.waitForImages();
    }
  }

  transformData(): void {
    if (
      this.widget?.ads?.length > 0 &&
      this.widget?.metrics &&
      this.widget?.metrics?.length > 0
    ) {
      this.widget?.ads?.forEach((ad): void => {
        if (typeof ad === 'object') {
          ad.values.newValue =
            this.widget.metrics &&
            this.helperService.updateMetricData(
              this.widget.metrics[0].dataType as string,
              ad?.values?.value,
              ad?.values?.currency || 'USD',
              this.widget?.metrics
                ? this.widget.metrics[0]?.shortenNumbers
                : false
            );
        }
      });
    }

    if (this.widget?.ads?.length > 0) {
      this.adCardData.elements = [];
      this.adCardData.labels = [];

      this.widget?.ads?.forEach((ad: any, index: number): void => {
        const element: any = {
          widget: this.widget,
          isExternalUser: this.isExternalUser,
          helpUrl: this.helpUrl,
          theme: this.theme,
          type: 'adPreview',
          isDummy: !(this.widget?.metrics && this.widget?.metrics?.length > 0),
        };
        element['ad-' + index] = ad;
        this.adCardData.elements.push(element);
      });
    }
  }

  public resizeGridItem(item: Element): void {
    const grid: Element = document.getElementsByClassName(
      'horizontal-content-masonry'
    )[0];
    const rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'),
      10
    );
    const rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue('grid-row-gap'),
      10
    );
    const rowSpan = Math.ceil(
      (item?.querySelector('.ad-container')!.getBoundingClientRect().height +
        rowGap) /
        (rowHeight + rowGap)
    );
    item['style'].gridRowEnd = 'span ' + (rowSpan + 1);
  }

  resizeAllGridItems(): void {
    this.changeDetector.detectChanges();
    const itemList: Element[] = Array.from(
      document.getElementsByClassName('horizontal-content-item')
    );
    for (const item of itemList) {
      this.resizeGridItem(item);
    }
  }

  onGridResize(): void {
    this.resizeAllGridItems();
  }

  waitForImages(): void {
    this.changeDetector.detectChanges();
    const adGallery: HTMLElement | null = document.getElementById('ad-gallery');

    if (adGallery) {
      const imagesArray: HTMLImageElement[] = Array.from(
        adGallery.querySelectorAll('img')
      );

      const loadImage = (img: HTMLImageElement): Promise<unknown> => {
        return new Promise((resolve, reject): void => {
          img.addEventListener('load', resolve);
          img.addEventListener('error', () => reject(img));
        });
      };

      const imageLoadPromises = imagesArray.map(loadImage);

      Promise.allSettled(imageLoadPromises).then((): void => {
        /* All images loaded (either successfully or with errors) */
        this.resizeAllGridItems();
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
