import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
  inject,
  output,
} from '@angular/core';
import {retry, Subscription, take} from 'rxjs';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CdkMenuModule} from '@angular/cdk/menu';

import {
  CustomError,
  IContextMenu,
  ReportDateRange,
  ReportFilter,
  ReportPage,
  ReportTheme,
  Widget,
  WidgetDelete,
  WidgetInfo,
} from 'src/app/shared/models';
import {
  ApiService,
  AuthenticationService,
  LoadingService,
  NotificationService,
  ReportService,
  WidgetService,
  backgroundImageType,
  ReportingStore,
  MobileService,
} from '../../../../core/services';
import {NavigationStart, Router} from '@angular/router';
import {NgClass, NgStyle} from '@angular/common';
import {ReportPageFiltersComponent} from '../../../../shared/components';
import {DatePickerComponent} from '../../../../shared/components';
import {ReportGridComponent} from '../../../../shared/components';
import {MobileGridComponent} from '../../../../shared/mobile';
import {ReportPageStoreSignal} from '../../state/report-page.store';
import {ScopeService} from 'src/app/shared/helpers/scope.service';
import {DatasetStoreSignal} from '../../state/dataset.store';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ReportPageFiltersComponent,
    DatePickerComponent,
    ReportGridComponent,
    MobileGridComponent,
    NgStyle,
    CdkMenuModule,
    TranslateModule,
  ],
})
export class ReportPageComponent implements OnInit, OnChanges, OnDestroy {
  // Services
  private reportPageStore = inject(ReportPageStoreSignal);

  // Inputs / Outputs
  @Input() reportId: string;
  @Input() page: ReportPage;
  @Input() pages: Array<ReportPage>;
  @Input() orientation: string;
  @Input() parentTheme: ReportTheme;
  @Input() isEditMode: boolean;
  @Input() isPrint: boolean;
  @Input() thereIsMap: boolean;
  @Input() originalPageState: ReportPage;
  @Input() isMobile: boolean;
  public pageUpdated = output<ReportPage>();
  public contextMenuAction = output<IContextMenu>();

  // State Variables
  isExternalUser = false;
  hasChanges: boolean;
  isLoading: boolean;
  largeMobile: boolean;

  // Properties
  subs: Subscription = new Subscription();
  bckImageUrl: string | null;
  themeName: string;
  theme: ReportTheme = {};
  pivotImageName: string;
  deleteOperations: Array<WidgetDelete> = [];
  datePayload: ReportDateRange | null = null;
  getPageSub: Subscription;
  previewSub: Subscription;
  windowWidth: number = window.screen.width;

  private datasetStoreSignal = inject(DatasetStoreSignal);
  constructor(
    private reportService: ReportService,
    private reportStorage: ReportingStore,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private widgetService: WidgetService,
    private translate: TranslateService,
    private loader: LoadingService,
    private router: Router,
    private mobileService: MobileService,
    private scopeService: ScopeService
  ) {
    this.subs.add(
      router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.getPageSub?.unsubscribe();
        }
      })
    );
  }

  ngOnInit(): void {
    this.subs.add(
      this.mobileService.mobileWindow$.subscribe((res: string) => {
        this.largeMobile = res === 'large';
      })
    );
    this.listenWidgetSubs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.datePayload = null;

    if (changes.page) {
      this.isExternalUser = this.authService.checkRole(['ROLE_EXTERNAL']);
      this.getPageSub?.unsubscribe();
      this.previewSub?.unsubscribe();
      this.isLoading = false;
      this.bckImageUrl = null;
      if (this.page.theme === null) {
        this.page.theme = {};
      }
      this.themeName = this.parentTheme?.name ?? '';
      this.theme = {};
      this.theme = Object.assign(this.theme, this.parentTheme, this.page.theme);
      if (this.theme.widgetBackgroundColor) {
        delete this.theme.backgroundColor;
      }
      if (
        !this.parentTheme?.backgroundImage &&
        !this.page.theme?.backgroundImage
      ) {
        delete this.theme.backgroundImage;
      }
      if (this.isPrint) {
        this.handlePageStore();
      } else {
        if (changes.page.currentValue.isCached) {
          this.page = changes.page.currentValue;
        }
        if (this.isExternalUser && !changes.page.currentValue.isCached) {
          this.page.widgets = [];
          this.previewData(this.reportId, this.page.id, this.page);
        } else {
          this.getPage(this.page.id);
        }
      }
    }
  }

  getPage(pageId: string): void {
    if (!this.pages[this.page.order].isCached) {
      this.isLoading = true;
      this.getPageSub = this.reportService
        .getReportPage(this.reportId, pageId)
        .subscribe({
          next: (res: ReportPage): void => {
            this.page = res;
            this.pages[this.page.order] = this.page;
          },
          error: (err: CustomError): void => {
            this.translate
              .get('notifications.error_loading_page')
              .subscribe((res: string) => {
                this.notificationService.error(err?.message ?? res, 5000);
              });
            this.loader.hide('body');
            this.isLoading = false;
          },
          complete: (): void => {
            this.isLoading = false;
            // This.reportService.setIsLoadingValue = false;
            this.page.isActive = true;
            this.pages[this.page.order].isCached = true;
            this.reportPageStore.addPage(this.page);
            this.reportPageStore.updateActivePage(this.page);
            this.pageUpdated.emit(this.page);
            // This function handles the page's background image.
            this.handlePageStore();
          },
        });
    } else {
      this.isLoading = false;
      this.reportService.setIsLoadingValue = false;
      if (this.page.theme !== undefined || this.parentTheme !== undefined) {
        this.handlePageStore();
      }
    }
  }

  getBackgroundImage(pageId: string): void {
    const {imageName, type} = this.getImageAndType();

    if (
      this.page.theme !== undefined &&
      this.page.theme.backgroundImage !== undefined
    ) {
      this.subs.add(
        this.reportService
          .getPictureByPage(this.reportId, this.page.id)
          .pipe(retry(5))
          .subscribe({
            next: (res) => {
              const reader: FileReader = new FileReader();
              reader.readAsDataURL(res);

              reader.onloadend = (): void => {
                this.bckImageUrl = reader.result?.toString() ?? '';

                this.reportStorage.updatePages({
                  reportId: this.reportId,
                  pageId: pageId,
                  backgroundImage: this.bckImageUrl,
                  backgroundImageType: 'page',
                });
                this.loader.hide('body');
              };
            },
            error: (err: CustomError) => {
              this.loader.hide('body');
              this.translate
                .get('notifications.error_getting_image')
                .subscribe((res: string) => {
                  this.notificationService.error(err?.message ?? res, 5000);
                });
            },
          })
      );
      return;
    } else {
      if (imageName) {
        this.handleThemeBackgroundImage(imageName, type);
      } else {
        this.loader.hide('body');
      }
    }
  }

  getImageAndType(): {imageName: string; type: backgroundImageType} {
    return this.page?.theme?.backgroundImage !== undefined
      ? {
          imageName: this.page.theme.backgroundImage,
          type: 'reportThemeBackground',
        }
      : {
          imageName:
            this.themeName === 'Custom Theme'
              ? this.theme.customThemeBackgroundImage
              : this.theme?.backgroundImage,
          type:
            this.themeName === 'Custom Theme'
              ? 'customThemeBackgroundImage'
              : 'reportThemeBackground',
        };
  }

  handleThemeBackgroundImage(
    imageName: string,
    type: backgroundImageType
  ): void {
    const flagName = this.pivotImageName ? this.pivotImageName : imageName;
    this.apiService
      .getImage(flagName, type, this.reportId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(res as Blob);
          reader.onload = (): void => {
            this.bckImageUrl = reader.result?.toString() ?? '';
            this.reportStorage.updatePages({
              reportId: this.reportId,
              pageId: this.page.id,
              backgroundImage: this.bckImageUrl,
              backgroundImageType: type,
            });
            this.reportStorage.updateCurrentBackgroundImageName(null);
            this.loader.hide('body');
          };
        },
        error: (err) => {
          this.notificationService.error(err.error.error_description, 5000);
          this.loader.hide('body');
        },
      });
  }

  handlePageStore(): void {
    this.reportStorage
      .getState()
      .pipe(take(1))
      .subscribe((store) => {
        if (store != null) {
          const currentPage = store.pages.find(
            (p) => p.reportId === this.reportId && p.pageId === this.page.id
          );
          if (currentPage && currentPage.backgroundImage) {
            this.bckImageUrl = currentPage.backgroundImage;
          } else {
            this.pivotImageName = store.currentBackgroundImageName;
            this.getBackgroundImage(this.page.id);
          }
        } else {
          this.getBackgroundImage(this.page.id);
        }
      });
  }

  previewData(reportId: string, pageId: string, pagePayload: ReportPage): void {
    this.isLoading = true;
    this.previewSub = this.widgetService
      .previewData(reportId, pageId, pagePayload)
      .subscribe({
        next: (res) => {
          res.order = this.page.order;
          this.page = res;
          this.page.isActive = true;
          this.page.isCached = true;
          this.pages[this.page.order] = this.page;
          if (this.isExternalUser) {
            this.handlePageStore();
          }
        },
        error: (err: CustomError) => {
          this.isLoading = false;
          this.reportService.setIsLoadingValue = false;
          this.notificationService.error(err.message as string, 5000);
        },
        complete: () => {
          this.isLoading = false;
          this.reportService.setIsLoadingValue = false;
        },
      });
  }

  listenWidgetSubs(): void {
    // Add Widget Subscription
    this.subs.add(
      this.widgetService.addWidgetStream$.subscribe(
        (widgetInfo: WidgetInfo): void => {
          if (this.page.id === widgetInfo.pageId) {
            const pageWidgets = this.page.widgets?.filter(
              (widget) => widget.id !== 'dummy'
            );

            this.reportService.setIsLoadingValue = true;
            this.widgetService
              .getSampleWidget(
                this.reportId,
                this.page.id,
                widgetInfo.widgetType,
                this.theme,
                pageWidgets
              )
              .subscribe({
                next: (sampleWidget: Widget) => {
                  if (
                    sampleWidget.widgetType === 'branding' &&
                    this.checkBrandingWidget(this.page.widgets)
                  ) {
                    this.translate
                      .get('notifications.widget_exists')
                      .subscribe((res: string) => {
                        this.notificationService.error(res, 5000);
                      });
                  } else {
                    if (
                      widgetInfo?.origin === 'paste' &&
                      widgetInfo?.widgetType === 'image' &&
                      widgetInfo?.data
                    ) {
                      sampleWidget.imageData = widgetInfo.data;
                    }
                    this.addNewWidget(sampleWidget);
                  }
                },
                error: (err: CustomError) => {
                  this.reportService.setIsLoadingValue = false;
                  this.notificationService.error(err.message as string, 5000);
                },
                complete: () => {
                  this.reportService.setIsLoadingValue = false;
                },
              });
          }
        }
      )
    );

    // Add widget from template subscription
    this.subs.add(
      this.widgetService.templateWidgetStream$.subscribe(
        (widgetInfo: Widget) => {
          const copyWidget: Widget = JSON.parse(JSON.stringify(widgetInfo));
          copyWidget.id = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
          copyWidget.isDuplicate = true;
          copyWidget.pageId = this.page.id;
          this.addNewWidget(copyWidget);
        }
      )
    );

    // Duplicate widget Subscription
    this.subs.add(
      this.widgetService.duplicateWidgetStream$.subscribe(
        (widgetInfo: Widget) => {
          if (this.page.id === widgetInfo.pageId) {
            const copyWidget: Widget = JSON.parse(JSON.stringify(widgetInfo));
            if (copyWidget.isDuplicate || copyWidget.isSample) {
              copyWidget.id = Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
              this.addNewWidget(copyWidget);
            } else {
              this.widgetService
                .duplicateWidget(
                  this.reportId,
                  widgetInfo.pageId,
                  widgetInfo.id
                )
                .subscribe({
                  next: (widget) => {
                    copyWidget.id = widget.id;
                    copyWidget.isDuplicate = widget.isDuplicate;
                    this.addNewWidget(copyWidget);
                  },
                  error: (err: CustomError) => {
                    this.notificationService.error(
                      err?.message as string,
                      5000
                    );
                  },
                  complete: () => {
                    //
                  },
                });
            }
          }
        }
      )
    );

    // Save Widgets Subscription
    this.subs.add(
      this.widgetService.save$.subscribe((res: boolean) => {
        if (res) {
          this.saveDeleteWidgets();
          this.widgetService.setSaveValue = false;
        }
      })
    );

    // Save Widget Subscription from Editor
    this.subs.add(
      this.widgetService.saveWidgetStream$.subscribe((widgetInfo: Widget) => {
        if (this.page.id === widgetInfo.pageId) {
          this.updateWidget(widgetInfo);
        }
      })
    );

    // Remove Widget Subscription
    this.subs.add(
      this.widgetService.deleteWidgetStream$.subscribe(
        (widgetInfo: WidgetDelete) => {
          if (this.page.id === widgetInfo.pageId) {
            this.removeWidget(widgetInfo);
          }
        }
      )
    );

    // Has Changes Subscription
    this.reportService.hasChanges$.subscribe(
      (res: {hasChanges: boolean; skipPageState?: boolean}) => {
        this.hasChanges = res.hasChanges;
      }
    );
  }

  addNewWidget(newWidget: Widget): void {
    // Send info to the grid to add the widget.
    //this.reportService.setChangeValue = true;
    this.widgetService.cascadeAddWidgetGrid(newWidget);
  }

  /**
   * Check if the brand widget already exists on the report page.
   * @param widgetList List of widgets in the page.
   */
  private checkBrandingWidget(widgetList: Widget[]): boolean {
    if (widgetList?.length > 0) {
      return widgetList.filter((w) => w.widgetType === 'branding').length > 0;
    } else {
      return false;
    }
  }

  removeWidget(widgetInfo: WidgetDelete): void {
    if (this.page.widgets) {
      this.page.widgets.forEach((widget: Widget, index: number) => {
        if (widget.id === widgetInfo.widgetId) {
          if (!widget.isSample && !widget.isDuplicate) {
            // Only add operation to queue if it's not a sample widget.
            this.deleteOperations.push({
              widgetId: widgetInfo.widgetId,
              widgetType: widget.widgetType,
            });
          }
          this.page.widgets.splice(index, 1);
        }
      });
    }
    this.reportService.setChangeValue = {
      hasChanges: true,
      from: 'remove-widget',
    };
  }

  saveDeleteWidgets(isPreview?: boolean): void {
    this.reportService.setIsLoadingValue = true;
    this.isLoading = true;
    this.reportPageStore.updateActivePage(this.page);
    this.originalPageState.widgets.forEach((widget: Widget): void => {
      if (this.page.widgets.every((w: Widget): boolean => w.id !== widget.id)) {
        this.deleteOperations.push({
          widgetId: widget.id,
          widgetType: widget.widgetType,
        });
      }
    });

    if (this.deleteOperations.length > 0 && !isPreview) {
      this.subs.add(
        this.widgetService
          .deleteWidgets(this.reportId, this.page.id, this.deleteOperations)
          .subscribe({
            next: (): void => {},
            error: (err: CustomError): void => {
              this.notificationService.error(err?.message as string, 5000);
              this.isLoading = false;
            },
            complete: (): void => {
              this.deleteOperations.forEach((w: WidgetDelete): void => {
                if (w?.widgetType === 'branding') {
                  this.pages.forEach((page: ReportPage): void => {
                    if (this.page.id !== page?.id) {
                      page.isCached = null;
                    }
                  });
                }
              });

              this.deleteOperations = [];
              if (this.hasChanges) {
                this.updateReportPage();
              } else {
                this.notificationService.success(
                  this.translate.instant('notifications.report_page_updated'),
                  5000
                );
              }
            },
          })
      );
    } else {
      // Recalculate the widgets scopes
      this.page.widgets.forEach((widget: Widget): void => {
        if (widget.id !== 'dummy') {
          this.scopeService.recalculateWidgetScope(
            this.datasetStoreSignal.getAllDataSets(),
            widget,
            this.page.filters as Array<ReportFilter>
          );
        }
      });
      this.updateReportPage(isPreview);
    }
  }

  updateWidget(widgetInfo): void {
    this.page.widgets.forEach((widget, index) => {
      if (widget.id === widgetInfo.id) {
        this.page.widgets[index] = widgetInfo;
      }
    });

    // Clear the other pages cache on exclude branding
    if (widgetInfo.widgetType === 'branding') {
      this.page.widgets.forEach((w: Widget) => {
        if (
          w.widgetType === 'branding' &&
          w?.excludedPages &&
          w?.excludedPages?.length >= 0
        ) {
          this.pages.forEach((page: ReportPage): void => {
            if (this.page.id !== page?.id) {
              page.isCached = null;
            }
          });
        }
      });
    }
    this.widgetService.onWidgetHasBeenEdited(null);
    this.reportService.setChangeValue = {hasChanges: true, from: 'update'};
  }

  updateReportPage(isPreview?: boolean): void {
    this.reportService.setIsLoadingValue = true;
    this.isLoading = true;

    if (!this.page.dateRange) {
      this.page.dateRange = {} as ReportDateRange;
    }

    if (this.datePayload?.current) {
      this.page.dateRange = {
        ...this.page.dateRange,
        current: this.datePayload.current,
      };
    }

    if (this.datePayload?.previous) {
      this.page.dateRange = {
        ...this.page.dateRange,
        previous: this.datePayload.previous,
      };
    } else if (this.datePayload) {
      delete this.page?.dateRange?.previous;
    }

    if (this.datePayload?.applyToAll) {
      this.page.dateRange.applyToAll = this.datePayload?.applyToAll;
      if (this.page.dateRange.applyToAll) {
        this.pages.forEach((page: ReportPage): void => {
          page.isCached = false;
          if (page.id === this.page.id) {
            return;
          }
          page.dateRange = this.page.dateRange;
        });
      }
    }

    if (this.datePayload?.isCompare) {
      this.page.dateRange = {
        ...this.page.dateRange,
        isCompare: this.datePayload.isCompare,
      };
    }

    const payload: ReportPage = this.page;

    if (payload?.widgets?.length > 0) {
      payload.widgets = payload.widgets?.filter(
        (widget: Widget): boolean => widget.id !== 'dummy'
      );
    }

    if (this.isExternalUser || isPreview) {
      if (!this.isExternalUser && !this.isMobile) {
        this.reportService.setChangeValue = {
          hasChanges: true,
          skipPageState: true,
          from: 'update-report-page',
        };
      }
      this.previewData(this.reportId, this.page.id, payload);
    } else {
      this.widgetService
        .updatePageWidgets(this.reportId, this.page.id, payload)
        .subscribe({
          next: (res: ReportPage): void => {
            if (res) {
              this.page = res;
              this.page.isActive = true;
              this.page.isCached = true;
              this.pageUpdated.emit(this.page);
              // Update page in pages array so changes are reflected in navigator cards.
              this.pages[this.page.order] = this.page;
            }
          },
          error: (err: CustomError): void => {
            this.isLoading = false;
            this.reportService.setIsLoadingValue = false;
            this.notificationService.error(err.message as string, 5000);
          },
          complete: (): void => {
            this.isLoading = false;
            this.reportService.setIsLoadingValue = false;
            this.translate
              .get('notifications.report_page_updated')
              .subscribe((res: string) => {
                this.notificationService.success(res, 5000);
              });
            this.reportService.setChangeValue = {hasChanges: false};
            this.widgetService.finishSavingPage();
          },
        });
    }
  }

  onDateSelection(event: ReportDateRange): void {
    this.datePayload = event;
    this.saveDeleteWidgets(true);
  }

  handleContextMenuAction(type: string, action: string): void {
    this.contextMenuAction.emit({type, action});
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
