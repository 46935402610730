import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-alert',
    templateUrl: './mobile-alert.component.html',
    standalone: true,
    imports: [LottieComponent, TranslateModule],
})
export class MobileAlertComponent implements OnInit {
  public options: AnimationOptions;

  constructor(public dialogRef: MatDialogRef<MobileAlertComponent>) {}

  ngOnInit(): void {
    this.options = {
      path: '../../../../assets/lotties/coffee-clicky.json',
    };
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
