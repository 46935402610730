@if (rendered) {
  <section class="w-100 h-100">
    <fusioncharts
      (initialized)="onChartReady($event)"
      width="100%"
      height="100%"
      type="bar2d"
      dataFormat="JSON"
      [dataSource]="widget.chartData"
    />
  </section>
}
