import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {NgxGauge, NgxGaugeModule} from 'ngx-gauge';
import {
  ColorService,
  HelperService,
  isValidDate,
  setWidgetFontSize,
} from 'src/app/shared/helpers';
import {FormGroup} from '@angular/forms';
import {ReportTheme, ScopeField, Widget} from 'src/app/shared/models';
import {CommonModule, formatDate} from '@angular/common';
import {ReportService} from '../../../../../core/services';
import {Subscription} from 'rxjs';
import {FusionChartsModule} from 'angular-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import Widgets from 'fusioncharts/fusioncharts.widgets';
import '../../../../../../assets/libs/fusioncharts/custom-themes/wsm_theme.js';

export interface GaugeColor {
  backgroundColor: string;
  foregroundColor: string;
}

@Component({
  standalone: true,
  selector: 'app-goal-tracker',
  templateUrl: './goal-tracker.component.html',
  styleUrls: ['./goal-tracker.component.scss'],
  imports: [CommonModule, FusionChartsModule, NgxGaugeModule],
})
export class GoalTrackerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('gauge', {static: false}) gauge: NgxGauge;

  @Input() widget: Widget;
  @Input() widgetStyleForm: FormGroup;
  @Input() theme: ReportTheme;

  public markers: any = {};
  public gaugeColor: GaugeColor;
  public currentVsGoal: {currentValue: string; goalValue: string};
  public size = 270; // Min = 190 Max=270
  public renderedMarkers = false;
  public goalAmount: number;
  public currentValue: string;
  subs: Subscription = new Subscription();

  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  constructor(
    private colorService: ColorService,
    private helperService: HelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private reportService: ReportService
  ) {
    // Use fcRoot function to inject FusionCharts library, and the modules you want to use
    FusionChartsModule.fcRoot(FusionCharts, Charts, Widgets);
  }

  ngOnInit(): void {
    this.handleChartInfo();

    this.subs.add(
      this.reportService.presentationMode.subscribe((value: boolean): void => {
        setWidgetFontSize(value, this.widget);
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  handleMarkerFontColor(): void {
    this.changeDetectorRef.detectChanges();
    if (this.gauge) {
      const context = (
        this.gauge._canvas.nativeElement as HTMLCanvasElement
      ).getContext('2d');
      context!.fillStyle = this.theme.bodyColor!;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.handleChartInfo();
    }

    if (
      changes.widgetStyleForm !== undefined &&
      !changes.widgetStyleForm.isFirstChange() &&
      changes.widgetStyleForm.currentValue !== undefined
    ) {
      changes.widgetStyleForm.currentValue.type === 'style' &&
        this.handleChartInfo();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public setMarkers(): void {
    this.renderedMarkers = false;
    this.markers = {};
    this.currentValue = this.setMetricFormat(
      this.widget.chartData?.data.currentValue
    );
    const gAmount = this.setMetricFormat(this.widget.chartData?.data.goalValue);
    setTimeout(() => {
      this.markers = {
        '0': {
          color: '#555',
          type: 'line',
          size: 1,
          label: '0',
          font: '1rem arial',
        },
        '100': {
          type: 'line',
          size: 1,
          label: gAmount,
          font: '1rem arial',
        },
      };
      this.changeDetectorRef.detectChanges();
      this.renderedMarkers = true;
      this.handleMarkerFontColor();
    }, 500);
  }

  public setCurrentVsGoal(): void {
    this.currentVsGoal = {
      currentValue: this.setMetricFormat(
        this.widget.chartData?.data.currentValue
      ),
      goalValue: this.setMetricFormat(this.widget.chartData?.data.goalValue),
    };
  }

  setMetricFormat(value: number): string {
    if (this.widget.metrics && this.widget.metrics.length > 0) {
      return this.helperService.updateMetricData(
        this.widget.metrics![0].dataType!,
        value,
        '',
        this.widget.metrics![0].shortenNumbers
      );
    } else {
      return value.toString();
    }
  }

  handleChartInfo(): void {
    if (this.widget.widgetType === 'gauge') {
      this.setMarkers();
    }

    if (
      this.widget.widgetType === 'gauge' ||
      this.widget.widgetType === 'current-goal'
    ) {
      this.handleColor();
      this.setCurrentVsGoal();
    }

    if (this.widget.widgetType === 'timeline') {
      this.updateChartDateValues();
      this.handlePlotToolText();
    }
  }

  handlePlotToolText(): void {
    this.widget.chartData!.chartData.chart.plottooltext = `<div class='plottooltext-label'>
      <span class='goal-label'>Goal: ${this.widget.goalAmount}</span>
      </br>
      $seriesName, $label, $dataValue
    </div>`;
  }

  handleColor(): void {
    const isDark = this.colorService.isDark(
      this.widget.chartData?.data.colorPalette
    );
    if (isDark) {
      this.gaugeColor = {
        backgroundColor: this.widget.chartData?.data.colorPalette,
        foregroundColor: this.colorService.pSBC(
          0.05,
          this.widget.chartData?.data.colorPalette
        ),
      };
    }
    if (!isDark) {
      this.gaugeColor = {
        backgroundColor: this.widget.chartData?.data.colorPalette,
        foregroundColor: this.colorService.pSBC(
          -0.7,
          this.widget.chartData?.data.colorPalette
        ),
      };
    }
  }

  resizeGauge(event: any): void {
    const pivotSize = Math.round(event.newRect.height);
    this.size = pivotSize;
  }

  private updateChartDateValues(): void {
    const dimensions: ScopeField[] | undefined = this.widget?.dimensions;
    const chartData = this.widget?.chartData?.chartData;

    if (
      dimensions &&
      dimensions?.length > 0 &&
      chartData?.categories?.length > 0
    ) {
      const dateData: ScopeField | undefined = dimensions.find(
        (item: ScopeField): boolean => item.name === 'Date'
      );

      if (
        dateData &&
        dateData.dateConfiguration &&
        dateData.dateConfiguration.dateGroup === 'dayHours' &&
        chartData?.categories
      ) {
        const userTimeZone: string =
          Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (chartData?.categories && chartData?.categories?.length > 0) {
          for (const category of chartData.categories) {
            const categoryInfo = category?.category;

            if (categoryInfo && categoryInfo.length > 0) {
              categoryInfo.forEach((item): void => {
                if (isValidDate(item?.label)) {
                  item.label = formatDate(
                    item?.label,
                    dateData?.dateConfiguration?.dateFormat ===
                      'dayHourStandard'
                      ? 'h:mm a'
                      : 'H:mm',
                    this.userLocale,
                    userTimeZone
                  );
                }
              });
            }
          }
        }
      }
    }
  }
}
