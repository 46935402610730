import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoadingService, NotificationService, ReportService, WidgetService} from 'src/app/core/services';
import {FontService} from 'src/app/shared/helpers';
import {CustomError, PageTemplate, Report, WidgetTemplate} from 'src/app/shared/models';
import {TranslateService} from "@ngx-translate/core";
import {retry} from 'rxjs';
import { NgClass, NgStyle } from '@angular/common';
import { ReportPageComponent } from '../report-page/report-page.component';
import { WidgetComponent } from '../../../../shared/components/widgets/widget/widget.component';

@Component({
    selector: 'app-report-print',
    templateUrl: './report-print.component.html',
    styleUrls: ['./report-print.component.scss'],
    standalone: true,
    imports: [NgClass, ReportPageComponent, NgStyle, WidgetComponent]
})
export class ReportPrintComponent implements OnInit {

  //Properties
  report: Report;
  reportId: string;
  pageTemplate: PageTemplate;
  widgetTemplate: WidgetTemplate;

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private loader: LoadingService,
    private fontService: FontService,
    private widgetService: WidgetService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.reportId = this.route.snapshot.paramMap.get('id') ?? '';
    const pageTemplateId = this.route.snapshot.paramMap.get('pid') ?? '';
    const widgetTemplateId = this.route.snapshot.paramMap.get('wid');
    const isTemplateThumbnail = this.route.snapshot.url[2].path === 'thumbnailPrint';
    if (pageTemplateId) {
      this.getPrintPage(pageTemplateId);
    } else if (widgetTemplateId) {
      this.getPrintWidget(widgetTemplateId);
    } else if(isTemplateThumbnail) {
      this.getCoverPage();
    } else {
      this.getReport();
    }
  }

  getReport(): void {
    this.loader.show('body');
    this.reportService.getFullReport(this.reportId).pipe(retry(3)).subscribe({
      next: res => {
        this.report = res;
      },
      error: (err: CustomError) => {
        this.translate
          .get('notifications.error_loading_report')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
        this.loader.hide('body');
      },
      complete: () => {
        this.fontService.setReportFontSize(this.report.theme.fontSize);
      }
    });
  }

  getPrintPage(pageTemplateId: string): void {
    this.loader.show('body');
    this.reportService.getPageTemplate(pageTemplateId).subscribe({
      next: res => {
        this.pageTemplate = res;
      },
      error: (err: CustomError) => {
        this.loader.hide('body');
        this.translate
          .get('notifications.error_loading_page')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
        },
      complete: () => {
        this.loader.hide('body');
      }
    });
  }

  getPrintWidget(widgetTemplateId: string): void {
    this.widgetService.getWidgetTemplate(widgetTemplateId).subscribe({
      next: res => {
        this.widgetTemplate = res;
      },
      error: (err: CustomError)  => {
        this.loader.hide('body');
        this.translate
          .get('notifications.error_loading_widget')
          .subscribe((res: string) => {
            this.notificationService.error(err?.message ?? res, 5000);
          });
      },
      complete: () => {
        this.loader.hide('body');
      }
    });
  }

  getCoverPage(): void {
    this.reportService.getCoverPage(this.reportId).subscribe({
      next: res => {
        this.report = res;
      },
      error: () => {
        //
      },
      complete: () => {
        this.loader.hide('body');
      }
    });
  }
}
