<section
  [ngStyle]="{background: reportTheme?.backgroundColor}"
  class="classic-player"
>
  <div #timeline (click)="skipAround($event)" class="timeline">
    <div
      #progress
      [ngStyle]="{background: reportTheme?.bodyColor}"
      class="progress"
    ></div>
  </div>

  <div class="controls">
    <div>
      <div
        (click)="togglePlay()"
        [ngClass]="isPlaying ? 'fa-pause' : 'fa-play'"
        [ngStyle]="{color: reportTheme?.bodyColor}"
        class="toggle-play fa-regular"
      ></div>
    </div>

    <div [ngStyle]="{color: reportTheme?.bodyColor}" class="time">
      <div>{{ currentDuration }}</div>
      <div>{{ audioDuration ? " / " + audioDuration : " / 00:00" }}</div>
    </div>

    <div class="volume-container">
      <div class="volume-button">
        <div
          #volume
          (click)="muteAudio()"
          [ngClass]="audio?.muted ? 'fa-volume-slash' : 'fa-volume-high'"
          [ngStyle]="{color: reportTheme?.bodyColor}"
          class="volume fa-regular fa-sm"
        ></div>
      </div>

      <div #volumeSlider (click)="changeVolume($event)" class="volume-slider">
        <div
          #volumePercentage
          [ngStyle]="{background: reportTheme?.backgroundColor}"
          class="volume-percentage"
        ></div>
      </div>
    </div>

    <div class="show-player">
      <div
        (click)="closePlayer()"
        class="fa-regular fa-eye-slash btn-hide btn-hide"
        title="Hide Player"
        [ngStyle]="{color: reportTheme?.bodyColor}"
      ></div>
    </div>
  </div>
</section>
