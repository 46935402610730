import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {Subscription, distinctUntilChanged} from 'rxjs';
import {MatMenuTrigger, MatMenu, MatMenuContent} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {CKEditorComponent} from '@ckeditor/ckeditor5-angular';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {WidgetTitleComponent} from '../widget-settings/widget-title/widget-title.component';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {DynamicDateRangeComponent} from '../../dynamic-date-range/dynamic-date-range.component';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {FontawesomeIconSearchComponent} from '../../fontawesome-icon-search/fontawesome-icon-search.component';
import {NgClass} from '@angular/common';
import {
  MatButtonToggleGroup,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import {WidgetShapesComponent} from '../widget-settings/widget-shapes/widget-shapes.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormControlPipe} from '../../../pipes/form-control.pipe';
import {ImageSize, Widget} from 'src/app/shared/models/widget/widget.model';
import {ReportTheme} from 'src/app/shared/models/report/report-theme.model';
import {ReportPage} from 'src/app/shared/models/report/report.model';
import {TextAlignment} from 'src/app/shared/models/widget/font.model';
import {ScopeField} from 'src/app/shared/models/dataset.model';
import {FontService} from 'src/app/shared/helpers/font.service';
import {ImageService} from 'src/app/shared/helpers/image.service';
import {ReportPresetColorsStore} from 'src/app/modules/reporting/state/report-preset-colors.store';

@Component({
  selector: 'app-widget-style',
  templateUrl: './widget-style.component.html',
  styleUrls: ['./widget-style.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    WidgetTitleComponent,
    MatMenuTrigger,
    NgxIntlTelInputModule,
    MatMenu,
    MatMenuContent,
    DynamicDateRangeComponent,
    MatSelect,
    MatOption,
    ColorPickerModule,
    MatSlideToggle,
    FontawesomeIconSearchComponent,
    NgClass,
    MatButtonToggleGroup,
    MatButtonToggle,
    WidgetShapesComponent,
    TranslateModule,
    FormControlPipe,
  ],
})
export class WidgetStyleComponent implements OnInit, OnDestroy {
  // services
  private reportPresetColorsStore = inject(ReportPresetColorsStore);
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: UntypedFormGroup = new UntypedFormGroup({});
  @Input() reportTheme: ReportTheme;
  @Input() theme: ReportTheme;
  @Input() reportPage: ReportPage;
  @Input() formType: string;
  @Input() page: ReportPage;
  @Input() pages: Array<ReportPage>;
  @Input() ckEditorCtrl: UntypedFormControl = new UntypedFormControl();
  @Input() loading: boolean;
  @Output() saveWidget = new EventEmitter();
  @Output() styleFormPrevious = new EventEmitter();

  @ViewChild('drMenuTrigger') drMenuTrigger: MatMenuTrigger;
  @ViewChild('ckeditor', {static: false}) ckeditor: CKEditorComponent;

  // State
  disablePicker: boolean;
  stripedRowsValue: boolean;
  invalidUrl = false;
  errorLoading = false;
  isFile: boolean;
  recordExists = false;

  // Properties
  subs: Subscription = new Subscription();
  fontSizes = this.fontService.getFontSizes;
  alignmentTypes: Array<TextAlignment> = this.fontService.getAlignmentTypes;
  imageSizeFilters: Array<ImageSize> = this.imageService.getFontSizes;
  stockImage =
    'https://triton-cdn-files.s3-us-west-2.amazonaws.com/sample-image.png';

  // Icons
  widgetIconPositions = ['left', 'right'];

  // preset colors
  public presetColors = this.reportPresetColorsStore.getActivePresetColors()
    ?.colors as string[];
  private reportId =
    this.reportPresetColorsStore.getActivePresetColors()?.reportId;
  public maxPresetColors = 12;

  constructor(
    private formBuilder: FormBuilder,
    private fontService: FontService,
    private imageService: ImageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.addStyleControls();
    this.setStyleFormData();
    this.listenControlSubs();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Add all required controls for the widget style form.
   */
  addStyleControls(): void {
    this.widgetStyleForm.addControl('type', new UntypedFormControl(''));

    if (
      this.widget.widgetType !== 'image' &&
      this.widget.widgetType !== 'scorecard' &&
      this.widget.widgetType !== 'branding'
    ) {
      this.widgetStyleForm.addControl('title', new UntypedFormControl(''));
    }

    this.widgetStyleForm.addControl(
      'backgroundColor',
      new UntypedFormControl('', [this.disableTransparency.bind(this)])
    );
    this.widgetStyleForm.addControl('transparency', new UntypedFormControl(''));
    this.widgetStyleForm.addControl('borderColor', new UntypedFormControl(''));
    this.widgetStyleForm.addControl('bodyColor', new UntypedFormControl(''));
    this.widgetStyleForm.addControl('titleColor', new UntypedFormControl(''));
    this.widgetStyleForm.addControl('fontSize', new UntypedFormControl(''));
    this.widgetStyleForm.addControl('widgetIcon', new UntypedFormControl(''));
    this.widgetStyleForm.addControl(
      'widgetIconPosition',
      new UntypedFormControl({value: '', disabled: true})
    );

    if (this.widget.widgetType === 'scorecard') {
      this.widgetIconPositions = [
        ...this.widgetIconPositions,
        'center_up',
        'center_down',
      ];
    }

    if (
      this.widget.widgetType === 'scorecard' ||
      this.widget.widgetType === 'table' ||
      this.widget.widgetType === 'gallery'
    ) {
      this.widgetStyleForm.addControl(
        'schemaIndicator',
        new UntypedFormControl('')
      );
      this.widgetStyleForm.addControl(
        'playerStyle',
        new UntypedFormControl('')
      );
    }

    if (this.widget.widgetType === 'table') {
      this.widgetStyleForm.addControl(
        'tableHeaderBgColor',
        new UntypedFormControl('')
      );
      this.widgetStyleForm.addControl(
        'tableHeaderBorderColor',
        new UntypedFormControl('')
      );
      this.widgetStyleForm.addControl(
        'tableRowBorderColor',
        new UntypedFormControl('')
      );
      this.widgetStyleForm.addControl(
        'tableRowBgColor',
        new UntypedFormControl('')
      );
      this.widgetStyleForm.addControl(
        'stripedRows',
        new UntypedFormControl(false)
      );

      this.stripedRowsValue =
        this.widget?.theme?.stripedRows !== null &&
        this.widget?.theme?.stripedRows !== undefined
          ? this.widget?.theme?.stripedRows
          : this.reportPage?.theme?.stripedRows !== null &&
              this.reportPage?.theme?.stripedRows !== undefined
            ? this.reportPage?.theme?.stripedRows
            : !!this.reportTheme?.stripedRows;
    }

    if (this.widget.widgetType === 'biggestchanges') {
      this.widgetStyleForm.addControl(
        'valueToShow',
        new UntypedFormControl(this.widget?.valueToShow)
      );
      this.widgetStyleForm.addControl(
        'hideXAxisName',
        new UntypedFormControl('')
      );
      this.widgetStyleForm.addControl(
        'placeValuesInside',
        new UntypedFormControl(this.widget.placeValuesInside || 0)
      );
    }

    if (this.widget.widgetType === 'gauge') {
      this.widgetStyleForm.addControl(
        'showPercentage',
        new UntypedFormControl(this.widget?.showPercentage)
      );
    }
    if (this.widget.widgetType === 'current-goal') {
      this.widgetStyleForm.addControl(
        'resultValueLabel',
        new UntypedFormControl(this.widget.resultValueLabel)
      );
      this.widgetStyleForm.addControl(
        'goalAmountLabel',
        new UntypedFormControl(this.widget.goalAmountLabel)
      );
    }

    if (this.widget.widgetType === 'timeline') {
      this.widgetStyleForm.addControl(
        'showCumulative',
        new UntypedFormControl('')
      );
    }

    if (this.widget.widgetType === 'chart') {
      this.widgetStyleForm.addControl(
        'legendPosition',
        new UntypedFormControl('')
      );
      if (
        this.widget?.chartData?.chartType === 'pie2d' ||
        this.widget?.chartData?.chartType === 'doughnut2d'
      ) {
        this.widgetStyleForm.addControl(
          'dataLabel',
          new UntypedFormControl('')
        );
        this.widgetStyleForm.addControl(
          'dataValue',
          new UntypedFormControl('')
        );
      }

      if (this.widget?.chartData?.chartType === 'funnel') {
        this.widgetStyleForm.addControl(
          'dataValue',
          new UntypedFormControl('')
        );
      }

      if (this.widget?.chartData?.chartType === 'doughnut2d') {
        this.widgetStyleForm.addControl('dataPlot', new UntypedFormControl(''));
      }

      if (
        this.widget?.chartData?.chartType !== 'pie2d' &&
        this.widget?.chartData?.chartType !== 'doughnut2d' &&
        this.widget?.chartData?.chartType !== 'funnel'
      ) {
        this.widgetStyleForm.addControl('showAxis', new UntypedFormControl(''));
        this.widgetStyleForm.addControl(
          'hideXAxisName',
          new UntypedFormControl('')
        );
        this.widgetStyleForm.addControl(
          'showCumulative',
          new UntypedFormControl('')
        );

        if (this.widget?.chartData?.chartType === 'mscombidy2d') {
          this.widgetStyleForm.addControl(
            'hideYAxisName',
            new UntypedFormControl('')
          );
        }
      }
    }

    if (this.widget.widgetType === 'image') {
      this.widgetStyleForm.addControl(
        'imageData',
        this.formBuilder.group({
          imageFit: new UntypedFormControl(''),

          imageSrc: new UntypedFormControl('', [this.isAValidUrl.bind(this)]),
          fileName: new UntypedFormControl(''),
        })
      );
    }

    if (this.widget.widgetType === 'branding') {
      this.widgetStyleForm.addControl(
        'websiteColor',
        new UntypedFormControl(this.theme?.websiteColor)
      );
      this.widgetStyleForm.addControl(
        'excludedPages',
        new UntypedFormControl(this.widget?.excludedPages || [])
      );
      this.widgetStyleForm.addControl(
        'brandingStyle',
        new UntypedFormControl(this.widget?.brandingStyle || 'left')
      );
    }

    if (this.widget.widgetType === 'title') {
      this.widgetStyleForm.addControl(
        'alignment',
        new UntypedFormControl(
          this.widget.alignment ? this.widget.alignment : 'flex-start'
        )
      );
    }

    if (this.widget.widgetType === 'gallery') {
      this.widgetStyleForm.addControl('darkMode', new UntypedFormControl(''));
    }

    if (this.widget.widgetType === 'scorecard') {
      this.widgetStyleForm.addControl(
        'kpiLabel',
        new UntypedFormControl(this.widget.kpiLabel ? this.widget.kpiLabel : '')
      );
    }

    this.widgetStyleForm.get('widgetIcon')?.valueChanges.subscribe((val) => {
      if (val) {
        this.widgetStyleForm.get('widgetIconPosition')?.enable();
        this.widgetStyleForm.get('widgetIconPosition')?.setValue('left');
      } else {
        this.widgetStyleForm.get('widgetIconPosition')?.disable();
        this.widgetStyleForm.get('widgetIconPosition')?.setValue('');
      }
    });
  }

  /**
   * Initialize all the controls of the widget style form.
   */
  setStyleFormData(): void {
    this.widgetStyleForm.patchValue({
      type: 'style',
      title: this.widget.title ?? '',
      backgroundColor: this.widget.theme.backgroundColor ?? null,
      transparency: this.widget.theme.transparency ?? null,
      borderColor: this.widget.theme.borderColor ?? null,
      bodyColor: this.widget.theme.bodyColor ?? null,
      titleColor: this.widget.theme.titleColor ?? null,
      fontSize: this.widget.theme.fontSize
        ? this.fontSizes.filter(
            (fontSize) => fontSize.size === this.widget.theme.fontSize
          )[0]
        : '',
      schemaIndicator:
        this.widget?.theme?.schemaIndicator ||
        this.reportTheme?.schemaIndicator ||
        'opt-1',
      tableHeaderBgColor: this.widget.theme.tableHeaderBgColor ?? null,
      tableHeaderBorderColor: this.widget.theme.tableHeaderBorderColor ?? null,
      tableRowBorderColor: this.widget.theme.tableRowBorderColor ?? null,
      tableRowBgColor: this.widget.theme.tableRowBgColor ?? null,
      playerStyle: this.widget?.playerStyle || 'classic',
      stripedRows:
        this.stripedRowsValue === null || this.stripedRowsValue === undefined
          ? false
          : this.stripedRowsValue,
      legendPosition: this.widget?.legendPosition || 'bottom',
      dataLabel: this.widget?.dataLabel || 'metric',
      dataValue: this.widget?.dataValue || 'metricValue',
      dataPlot: this.widget?.dataPlot || false,
      showAxis: this.widget?.showAxis || false,
      hideXAxisName: this.widget?.hideXAxisName || false,
      hideYAxisName: this.widget?.hideYAxisName || false,
      showCumulative: this.widget?.showCumulative || false,
      imageData: {
        imageFit: this.widget.imageData?.imageFit
          ? this.widget.imageData.imageFit
          : this.imageSizeFilters[0].property,
        imageSrc:
          this.widget.imageData &&
          !this.widget.isSample &&
          this.widget.imageData.imageSrc !== this.stockImage
            ? this.widget.imageData.imageSrc
            : null,
        fileName: this.widget.imageData?.fileName
          ? this.widget.imageData.fileName
          : this.widget.isSample &&
              this.widget?.imageData?.imageSrc !== this.stockImage
            ? this.widget?.imageData?.imageSrc
            : null,
      },
      darkMode: this.widget?.darkMode || 'off',
      widgetIcon: this.widget.widgetIcon ? this.widget.widgetIcon : null,
      widgetIconPosition: this.widget.widgetIcon
        ? this.widget.widgetIcon.position
        : '',
    });

    this.setDefaultConfigs();
  }

  setDefaultConfigs(): void {
    this.styleFormPrevious.emit(this.widgetStyleForm.value);

    if (this.widgetStyleForm?.get('imageData')?.get('fileName')?.value) {
      this.isFile = true;
    }

    if (
      this.widget.widgetType !== 'image' &&
      this.widget.widgetType !== 'branding' &&
      !this.widget?.isEditStyle
    ) {
      this.widget.isEditStyle = true;
    }

    if (this.widget.widgetType !== 'image') {
      this.widgetStyleForm?.get('imageData')?.disable();
    }

    if (this.widgetStyleForm.get('transparency')?.value) {
      this.disablePicker = true;
      this.widgetStyleForm.get('borderColor')?.disable();
      this.widgetStyleForm.get('backgroundColor')?.disable();
    }

    this.checkRecordingField();
  }

  /**
   * Detect changes in the controls to update the widget properties.
   */
  listenControlSubs(): void {
    // Transparency
    this.subs.add(
      this.widgetStyleForm
        .get('transparency')
        ?.valueChanges.subscribe((value) => {
          this.widget.theme.transparency = value;

          if (value) {
            this.widgetStyleForm.get('backgroundColor')?.disable();
            this.widgetStyleForm.get('borderColor')?.disable();
            this.disablePicker = true;
          } else {
            this.widgetStyleForm.get('backgroundColor')?.enable();
            this.widgetStyleForm.get('borderColor')?.enable();
            this.disablePicker = false;
          }
        })
    );

    // Legend Position
    this.subs.add(
      this.widgetStyleForm
        .get('legendPosition')
        ?.valueChanges.subscribe((selectedValue: string) => {
          this.widget.legendPosition = selectedValue;
        })
    );

    // legend position for biggestchanges
    this.subs.add(
      this.widgetStyleForm
        .get('placeValuesInside')
        ?.valueChanges.subscribe((selectedValue: number) => {
          this.widget.placeValuesInside = selectedValue;
        })
    );

    // value to show for biggestchanges
    this.subs.add(
      this.widgetStyleForm
        .get('valueToShow')
        ?.valueChanges.subscribe((selectedValue: string): void => {
          this.widget.valueToShow = selectedValue;
        })
    );

    // Data Label
    this.subs.add(
      this.widgetStyleForm
        .get('dataLabel')
        ?.valueChanges.subscribe((selectedValue: string): void => {
          this.widget.dataLabel = selectedValue;
        })
    );

    // Data Value
    this.subs.add(
      this.widgetStyleForm
        .get('dataValue')
        ?.valueChanges.subscribe((selectedValue: string) => {
          this.widget.dataValue = selectedValue;
        })
    );

    // Font Size
    this.subs.add(
      this.widgetStyleForm
        .get('fontSize')
        ?.valueChanges.subscribe((selectedValue: any) => {
          this.widget.theme.fontSize = selectedValue?.size;
        })
    );

    // Show Values In Data Plot
    this.subs.add(
      this.widgetStyleForm
        .get('dataPlot')
        ?.valueChanges.subscribe((selectedValue: boolean): void => {
          this.widget.dataPlot = selectedValue;

          if (selectedValue) {
            this.widgetStyleForm.get('dataLabel')?.disable();
          } else {
            this.widgetStyleForm.get('dataLabel')?.enable();
          }
        })
    );

    // Show Axis Values
    this.subs.add(
      this.widgetStyleForm
        .get('showAxis')
        ?.valueChanges.subscribe((selectedValue: boolean): void => {
          this.widget.showAxis = selectedValue;
        })
    );

    // Hide X Axis Name
    this.subs.add(
      this.widgetStyleForm
        .get('hideXAxisName')
        ?.valueChanges.subscribe((selectedValue: boolean) => {
          this.widget.hideXAxisName = selectedValue;
        })
    );

    // Hide Y Axis Name
    this.subs.add(
      this.widgetStyleForm
        .get('hideYAxisName')
        ?.valueChanges.subscribe((selectedValue: boolean) => {
          this.widget.hideYAxisName = selectedValue;
        })
    );

    // Show Cumulative
    this.subs.add(
      this.widgetStyleForm
        .get('showCumulative')
        ?.valueChanges.subscribe((selectedValue: boolean) => {
          this.widget.showCumulative = selectedValue;
        })
    );

    // Color Schema
    this.subs.add(
      this.widgetStyleForm
        .get('schemaIndicator')
        ?.valueChanges.subscribe((selectedValue) => {
          this.widget.theme.schemaIndicator = selectedValue;
        })
    );

    // Striped Rows
    this.subs.add(
      this.widgetStyleForm
        .get('stripedRows')
        ?.valueChanges.subscribe((selectedValue) => {
          this.widget.theme.stripedRows = selectedValue;
        })
    );

    // Dark Mode
    this.subs.add(
      this.widgetStyleForm
        .get('darkMode')
        ?.valueChanges.subscribe((selectedValue: string) => {
          this.widget.darkMode = selectedValue;
        })
    );

    // Rich Text
    this.subs.add(
      this.widgetStyleForm.get('richText')?.valueChanges.subscribe((val) => {
        this.widget.textData!.innerHtml = val;
        if (!this.widget.isEditStyle) {
          this.widget.isEditStyle = true;
        }
      })
    );

    // CKEditor Control
    this.subs.add(
      this.ckEditorCtrl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((res: string): void => {
          this.widget.dynamicTextEdit = res;
          if (
            res === 'advanced' &&
            !this.widgetStyleForm?.contains('advancedRichText')
          ) {
            this.setDynamicCtrl();
          }
        })
    );

    // title control
    this.subs.add(
      this.widgetStyleForm.get('title')?.valueChanges.subscribe((val) => {
        if (this.widget.widgetType !== 'scorecard') {
          const position = this.widgetStyleForm.get('widgetIconPosition');
          if (!val) {
            position?.disable();
            position?.setValue('left');
          }
          if (val) {
            position?.enable();
          }
        }
      })
    );
  }

  private setDynamicCtrl(): void {
    this.widgetStyleForm.addControl(
      'advancedRichText',
      new UntypedFormControl(this.widget?.dynamicConditionalData || '')
    );

    this.subs.add(
      this.widgetStyleForm
        .get('advancedRichText')
        ?.valueChanges.subscribe((): void => {
          this.widgetStyleForm.markAsDirty();
        })
    );
  }

  onStyleControlChange(newValue: any, controlName: string): void {
    this.widget.theme[controlName] = newValue;
  }

  onSaveWidget(): void {
    this.saveWidget.emit(true);
  }

  onColorReset(control: string): void {
    this.widgetStyleForm.get(control)?.setValue(null);
    this.widgetStyleForm.get(control)?.markAsDirty();
  }

  onInputChange(event, control: string): void {
    this.widgetStyleForm.get(control)?.setValue(event);
    this.widgetStyleForm.get(control)?.markAsDirty();
  }

  disableTransparency(): any {
    if (!this.widgetStyleForm) {
      return null;
    }

    const backgroundColor = this.widgetStyleForm.get('backgroundColor');

    if (backgroundColor && backgroundColor.value) {
      if (this.widgetStyleForm.get('transparency')?.enabled) {
        this.widgetStyleForm.get('transparency')?.disable();
      }
    } else {
      if (this.widgetStyleForm.get('transparency')?.disabled) {
        this.widgetStyleForm.get('transparency')?.enable();
      }
    }
  }

  addDynamicDate(event, type: string): void {
    if (
      type === 'title' &&
      this.formType === 'style' &&
      this.widgetStyleForm.contains('title')
    ) {
      this.widgetStyleForm.markAsDirty();
      this.widgetStyleForm
        .get('title')
        ?.setValue(`${this.widgetStyleForm.get('title')?.value} {{${event}}}`);
      this.drMenuTrigger.closeMenu();
    }

    if (
      type === 'kpiLabel' &&
      this.formType === 'style' &&
      this.widgetStyleForm.contains('kpiLabel')
    ) {
      this.widgetStyleForm.markAsDirty();
      this.widgetStyleForm
        .get('kpiLabel')
        ?.setValue(
          `${this.widgetStyleForm.get('kpiLabel')?.value} {{${event}}}`
        );
      this.drMenuTrigger.closeMenu();
    }
  }

  private isAValidUrl(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const urlRegex =
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
      const isValid = urlRegex.test(control.value);

      if (!isValid && control.value) {
        this.invalidUrl = true;
        return {invalidUrl: true};
      } else {
        this.invalidUrl = false;
      }

      if (!control.value) {
        this.errorLoading = false;
      }

      this.checkIfImageExists(control.value, (exists) => {
        this.errorLoading = !exists;
      });
      if (this.errorLoading) {
        return {errorLoading: true};
      }
      return null;
    };
  }

  private checkIfImageExists = (
    url: string,
    callback: (exists: boolean) => void
  ): void => {
    const img: HTMLImageElement = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = (): void => {
        callback(true);
      };
      img.onerror = (): void => {
        callback(false);
      };
    }
  };

  get imageDataForm(): UntypedFormGroup {
    return this.widgetStyleForm.get('imageData') as UntypedFormGroup;
  }

  handleFileInput(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const files: FileList = target.files as FileList;
    const mimeType = files?.item(0)?.type;
    if (mimeType?.match(/image\/*/) == null) {
      return;
    }

    this.isFile = true;
    this.widgetStyleForm
      ?.get('imageData')
      ?.get('fileName')
      ?.setValue(files?.item(0)?.name);

    const reader: FileReader = new FileReader();
    if (files.item(0)) {
      reader.readAsDataURL(files.item(0) as Blob);
      reader.onload = (): void => {
        this.widgetStyleForm
          ?.get('imageData')
          ?.get('imageSrc')
          ?.setValue(reader.result);
      };
    }
  }

  removeImage(): void {
    this.isFile = false;
    this.widgetStyleForm?.get('imageData')?.get('fileName')?.setValue('');
    this.widget.imageData!.fileName = null;
    this.widgetStyleForm?.get('imageData')?.get('imageSrc')?.setValue('');
    this.widget.imageData!.imageSrc = null;
  }

  checkRecordingField(): void {
    if (this.widget.widgetType === 'table') {
      if (
        this.widget?.dimensions &&
        this.widget?.dimensions?.filter(
          (d: ScopeField) => d.id === 'recordingURL'
        ).length > 0
      ) {
        this.recordExists = true;
      }

      if (
        this.widget?.metrics &&
        this.widget.metrics?.filter((m) => m.id === 'recordingURL').length > 0
      ) {
        this.recordExists = true;
      }
    }
  }

  changePresetColor(event: string[]): void {
    this.reportPresetColorsStore.updateActiveReportPresetColors(
      this.reportId as string,
      event
    );
  }
}
