<section class="h-100">
  <div [ngClass]="!isInline ? 'd-flex justify-content-end' : ''">
    @if (!isInline) {
      <button mat-dialog-close>
        <i class="fa-regular fa-xmark"></i>
      </button>
    }
  </div>

  <h2 mat-dialog-title class="h3" [hidden]="isInline">
    {{ "shared.components.formulaBuilder.header" | translate }}
  </h2>

  <div
    [ngClass]="[!isInline ? 'pb-0 pt-3 f-height' : 'py-3']"
    [class.wsm-card]="!isInline"
  >
    <div class="mb-2">
      @for (op of availableOperators; track op) {
        <span
          matTooltip="{{
            'shared.components.formulaBuilder.not_available' | translate
          }}"
          [matTooltipDisabled]="op !== '/' && op !== '*'"
        >
          <button
            class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon me-2"
            [disabled]="!fields || op === '/' || op === '*'"
            (click)="addOperator(op)"
          >
            {{ op }}
          </button>
        </span>
      }
      <!-- <button class="wsm-btn wsm-btn-primary me-2"
      [disabled]="!fields"
      (click)="openFormulaDialog(false)">
      {{ 'shared.components.formulaBuilder.advanced' | translate }}
    </button> -->
      <button
        [ngClass]="{'mt-2 w-100': isMobile}"
        class="wsm-btn wsm-btn-primary"
        [disabled]="!fields"
        (click)="clearFormula()"
      >
        {{ "shared.components.formulaBuilder.clear" | translate }}
      </button>
    </div>
    <div
      [ngClass]="{
        'flex-column align-items-start': isMobile,
        'active': fields,
        'disable': !fields
      }"
      #mainInput
      class="formula-input mb-2"
      (click)="onLostClick($event)"
    >
      @for (
        control of formulaControls.controls;
        track control;
        let i = $index
      ) {
        <span>
          @if (
            control.value.type &&
            ["field", "operand", "if"].includes(control.value.type)
          ) {
            <mat-chip-listbox #chipList class="chips">
              @if (["field", "operand"].includes(control.value.type)) {
                <mat-chip-option
                  [ngClass]="{'mobile-mat-chip': isMobile}"
                  #formulaInput
                  (keydown.arrowleft)="onArrowNavigation($event, control, left)"
                  (keydown.arrowright)="
                    onArrowNavigation($event, control, right)
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(i)"
                >
                  {{ control.value.name }}
                  @if (removable) {
                    <mat-icon matChipRemove>cancel</mat-icon>
                  }
                </mat-chip-option>
              } @else {
                @if (["if"].includes(control.value.type)) {
                  <mat-chip-option
                    #formulaInput
                    class="mat-chip-edit"
                    (keydown.arrowleft)="
                      onArrowNavigation($event, control, left)
                    "
                    (keydown.arrowright)="
                      onArrowNavigation($event, control, right)
                    "
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(i)"
                    (click)="openFormulaDialog(true)"
                  >
                    {{ control.value.name }}
                    @if (removable) {
                      <mat-icon matChipRemove>cancel</mat-icon>
                    }
                  </mat-chip-option>
                } @else {
                  <ng-template
                    [ngTemplateOutlet]="advanceOperator"
                  ></ng-template>
                }
              }
              <ng-template #advanceOperator>
                @if (["if"].includes(control.value.type)) {
                  <mat-chip-option
                    #formulaInput
                    class="mat-chip-edit"
                    (keydown.arrowleft)="
                      onArrowNavigation($event, control, left)
                    "
                    (keydown.arrowright)="
                      onArrowNavigation($event, control, right)
                    "
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(i)"
                    (click)="openFormulaDialog(true)"
                  >
                    {{ control.value.name }}
                    @if (removable) {
                      <mat-icon matChipRemove>cancel</mat-icon>
                    }
                  </mat-chip-option>
                } @else {
                  @if (["if"].includes(control.value.type)) {
                    <mat-chip-option
                      #formulaInput
                      class="mat-chip-edit"
                      (keydown.arrowleft)="
                        onArrowNavigation($event, control, left)
                      "
                      (keydown.arrowright)="
                        onArrowNavigation($event, control, right)
                      "
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="remove(i)"
                      (click)="openFormulaDialog(true)"
                    >
                      {{ control.value.name }}
                      @if (removable) {
                        <mat-icon matChipRemove>cancel</mat-icon>
                      }
                    </mat-chip-option>
                  } @else {
                    <ng-template
                      [ngTemplateOutlet]="advanceOperator"
                    ></ng-template>
                  }
                }
              </ng-template>
            </mat-chip-listbox>
          } @else {
            @if (control.value.type && control.value.type === "operator") {
              <span #formulaInput class="chip-input">{{
                control.value.name
              }}</span>
            } @else {
              <mat-chip-listbox>
                <input
                  #origin="matAutocompleteOrigin"
                  #formulaInput
                  matAutocompleteOrigin
                  class="chip-input chip-size"
                  (keydown.backspace)="onBackSpaceNavigation($event, control)"
                  (keydown.enter)="
                    $event.preventDefault(); add($event, i, control)
                  "
                  (keydown.space)="
                    $event.preventDefault(); add($event, i, control)
                  "
                  (keydown.arrowleft)="onArrowNavigation($event, control, left)"
                  (keydown.arrowright)="
                    onArrowNavigation($event, control, right)
                  "
                  (blur)="onBlurEverythingElse(i)"
                  [matAutocompleteConnectedTo]="origin"
                  [formControl]="setControl(control)"
                  [matAutocomplete]="autocomplete"
                />
              </mat-chip-listbox>
              <mat-autocomplete
                #autocomplete="matAutocomplete"
                class="wsm-custom-select"
                (optionSelected)="selected($event, i, control)"
              >
                @for (field of filteredFields[i] | async; track field) {
                  <mat-option [value]="field.id">
                    {{ field.name }}
                  </mat-option>
                }
              </mat-autocomplete>
            }
          }
        </span>
      }
    </div>
    @if (formulaHasError) {
      <i class="required-mark">{{
        "shared.components.formulaBuilder.invalidFormula" | translate
      }}</i>
    }
    @if (!isInline) {
      <div class="d-flex justify-content-end align-items-center pe-4">
        <button type="submit" (click)="onSubmitFormula()" class="wsm-button">
          {{ "shared.components.formulaBuilder.submit" | translate }}
        </button>
      </div>
    }
  </div>
</section>
