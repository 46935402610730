import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {distinctUntilChanged, Subscription} from 'rxjs';
import {GeometricShape, Widget} from 'src/app/shared/models';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { NumbersOnlyDirective } from '../../../../directives/numbers-only.directive';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-widget-shapes',
    templateUrl: './widget-shapes.component.html',
    styleUrls: ['./widget-shapes.component.scss'],
    standalone: true,
    imports: [
        MatButtonToggleGroup,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        MatButtonToggle,
        MatRadioGroup,
        MatRadioButton,
        NumbersOnlyDirective,
        NgClass,
        TranslateModule,
    ],
})
export class WidgetShapesComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: UntypedFormGroup = new UntypedFormGroup({});

  // State
  isFirstChange = false;

  // Properties
  subs: Subscription = new Subscription();
  cornerForm: UntypedFormGroup;
  shapeTypeCtrl: UntypedFormControl = new UntypedFormControl();
  cornerTypeCtrl: UntypedFormControl = new UntypedFormControl();

  // Static Data
  // Set these classes on Widget Component too.
  geometricShapes: Array<GeometricShape> = [
    {
      class: 'triangle-up',
      name: 'Triangle',
      translation: 'reporting.widget_editor.style_form.shapes.triangle_up',
    },
    {
      class: 'rhombus',
      name: 'Rhombus',
      translation: 'reporting.widget_editor.style_form.shapes.rhombus',
    },
    {
      class: 'pentagon-up',
      name: 'Pentagon',
      translation: 'reporting.widget_editor.style_form.shapes.pentagon_up',
    },
    {
      class: 'trapezoid-up',
      name: 'Trapezoid',
      translation: 'reporting.widget_editor.style_form.shapes.trapezoid_up',
    },
    {
      class: 'left-arrow',
      name: 'Left Arrow',
      translation: 'reporting.widget_editor.style_form.shapes.left_arrow',
    },
    {
      class: 'right-arrow',
      name: 'Right Arrow',
      translation: 'reporting.widget_editor.style_form.shapes.right_arrow',
    },
    {
      class: 'left-point',
      name: 'Left Point',
      translation: 'reporting.widget_editor.style_form.shapes.left_point',
    },
    {
      class: 'right-point',
      name: 'Right Point',
      translation: 'reporting.widget_editor.style_form.shapes.right_point',
    },
    {
      class: 'left-chevron',
      name: 'Left Chevron',
      translation: 'reporting.widget_editor.style_form.shapes.left_chevron',
    },
    {
      class: 'right-chevron',
      name: 'Right Chevron',
      translation: 'reporting.widget_editor.style_form.shapes.right_chevron',
    },
    {
      class: 'star',
      name: 'Star',
      translation: 'reporting.widget_editor.style_form.shapes.star',
    },
    {
      class: 'message',
      name: 'Message',
      translation: 'reporting.widget_editor.style_form.shapes.message',
    },
    {
      class: 'circle-shape',
      name: 'Circle',
      translation: 'reporting.widget_editor.style_form.shapes.circle',
    },
  ];

  constructor() {
    // do nothing
  }

  ngOnInit(): void {
    this.cornerForm = new UntypedFormGroup({
      allCorners: new UntypedFormControl(4, [Validators.minLength(1)]),
      topLeftCorner: new UntypedFormControl(4, [Validators.minLength(1)]),
      topRightCorner: new UntypedFormControl(4, [Validators.minLength(1)]),
      bottomLeftCorner: new UntypedFormControl(4, [Validators.minLength(1)]),
      bottomRightCorner: new UntypedFormControl(4, [Validators.minLength(1)]),
      shapeClass: new UntypedFormControl(''),
    });

    this.widgetStyleForm.addControl('cornerForm', this.cornerForm);
    this.listenControlSubs();
    this.initData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private listenControlSubs(): void {
    this.subs.add(
      this.shapeTypeCtrl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((res: string) => {
          this.widget.shapeType = res;

          if (this.isFirstChange) {
            this.isFirstChange = false;
          }

          if (res === 'round') {
            this.cornerForm.get('shapeClass')?.setValue('');
          }
          if (res === 'shapes') {
            this.cornerForm
              .get('shapeClass')
              ?.setValue(this.widget.theme?.shapeClass || '');
          }
        })
    );

    this.subs.add(
      this.cornerTypeCtrl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((res: string) => {
          this.widget.cornerType = res;
          this.setCorners();
        })
    );

    this.subs.add(
      this.cornerForm.get('allCorners')?.valueChanges.subscribe((value) => {
        if (value >= 0) {
          this.widget.theme.allCorners = +value;
        }
      })
    );

    this.subs.add(
      this.cornerForm.get('topLeftCorner')?.valueChanges.subscribe((value) => {
        if (value >= 0) {
          this.widget.theme.topLeftCorner = +value;
        }
      })
    );

    this.subs.add(
      this.cornerForm.get('topRightCorner')?.valueChanges.subscribe((value) => {
        if (value >= 0) {
          this.widget.theme.topRightCorner = +value;
        }
      })
    );

    this.subs.add(
      this.cornerForm
        .get('bottomLeftCorner')
        ?.valueChanges.subscribe((value) => {
          if (value >= 0) {
            this.widget.theme.bottomLeftCorner = +value;
          }
        })
    );

    this.subs.add(
      this.cornerForm
        .get('bottomRightCorner')
        ?.valueChanges.subscribe((value) => {
          if (value >= 0) {
            this.widget.theme.bottomRightCorner = +value;
          }
        })
    );
  }

  initData(): void {
    this.shapeTypeCtrl.setValue(this.widget?.shapeType || 'round');
    this.cornerTypeCtrl.setValue(this.widget?.cornerType || 'all');
    this.setCorners();
  }

  private setCorners(): void {
    switch (this.widget?.cornerType) {
      case 'all':
        this.cornerForm
          .get('allCorners')
          ?.setValue(this.widget?.theme?.allCorners || 4);
        break;
      case 'custom':
        this.cornerForm
          .get('allCorners')
          ?.setValue(
            this.widget?.theme?.allCorners &&
              this.widget?.theme?.allCorners >= 0
              ? this.widget?.theme?.allCorners
              : 4
          );
        this.cornerForm
          .get('topLeftCorner')
          ?.setValue(
            this.widget?.theme?.topLeftCorner &&
              this.widget?.theme?.topLeftCorner >= 0
              ? this.widget?.theme?.topLeftCorner
              : 4
          );
        this.cornerForm
          .get('topRightCorner')
          ?.setValue(
            this.widget?.theme?.topRightCorner &&
              this.widget?.theme?.topRightCorner >= 0
              ? this.widget?.theme?.topRightCorner
              : 4
          );
        this.cornerForm
          .get('bottomLeftCorner')
          ?.setValue(
            this.widget?.theme?.bottomLeftCorner &&
              this.widget?.theme?.bottomLeftCorner >= 0
              ? this.widget?.theme?.bottomLeftCorner
              : 4
          );
        this.cornerForm
          .get('bottomRightCorner')
          ?.setValue(
            this.widget?.theme?.bottomRightCorner &&
              this.widget?.theme?.bottomRightCorner >= 0
              ? this.widget?.theme?.bottomRightCorner
              : 4
          );
        break;
    }

    this.subs.add(
      this.cornerForm.valueChanges.subscribe(() => {
        if (!this.isFirstChange) {
          this.isFirstChange = true;
        } else {
          this.cornerForm.markAsDirty();
        }
      })
    );
  }

  onShapeSelect(className: string): void {
    this.widget.theme.shapeClass = className || '';
    this.cornerForm.get('shapeClass')?.setValue(this.widget.theme.shapeClass);
  }
}
