<!-- Report Print -->
@if (!pageTemplate && !widgetTemplate) {
  @for (page of report?.pages; track page) {
    @if (page.isVisible) {
      <div
        class="report-page-container"
        [ngClass]="{
          'report-page-container-vertical': report?.orientation === 'vertical'
        }"
      >
        <app-report-page
          [id]="page.id"
          [reportId]="report.id"
          [page]="page"
          [pages]="report.pages"
          [orientation]="report.orientation"
          [parentTheme]="report.theme"
          [isPrint]="true"
        >
        </app-report-page>
      </div>
    }
  }
}

<!-- Page Print -->
@if (pageTemplate) {
  <div class="report-page-container">
    <app-report-page
      [page]="pageTemplate"
      [orientation]="pageTemplate.orientation"
      [isPrint]="true"
    >
    </app-report-page>
  </div>
}

<!-- Widget Print -->
@if (widgetTemplate) {
  <div
    [ngStyle]="{
      'width': widgetTemplate.templateWidth,
      'height': widgetTemplate.templateHeight
    }"
  >
    <app-widget
      [reportId]="reportId"
      [reportTheme]="widgetTemplate.theme"
      [widget]="widgetTemplate"
    ></app-widget>
  </div>
}

<span id="report-id" class="d-none m-0 p-0">{{ reportId }}</span>
