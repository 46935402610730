import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  distinctUntilChanged,
  ReplaySubject,
  Subject,
  Subscription,
  take,
  takeUntil,
} from 'rxjs';
import {
  FormGroup,
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatMenuTrigger, MatMenu, MatMenuContent} from '@angular/material/menu';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {
  Dataset,
  DynamicConditional,
  LogicOperator,
  ReportPage,
  ScopeField,
  Widget,
  WidgetConfiguration,
} from '../../models';
import {DynamicTextComponent} from '../../dialogs';
import {allPropertiesAreNotNullNorUndefined, ScopeService} from '../../helpers';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {MatChip} from '@angular/material/chips';
import {NgClass, AsyncPipe} from '@angular/common';
import {MatSelect, MatSelectTrigger} from '@angular/material/select';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatOption} from '@angular/material/core';
import {
  MatButtonToggleGroup,
  MatButtonToggle,
} from '@angular/material/button-toggle';
import {MatTooltip} from '@angular/material/tooltip';
import {ConnectionLogosComponent} from '../connection-logos/connection-logos.component';
import {ShowOperatorPipe} from '../../pipes/show-operator.pipe';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-conditional-metric',
  templateUrl: './conditional-metric.component.html',
  styleUrls: ['./conditional-metric.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatChip,
    MatMenuTrigger,
    NgClass,
    MatSelect,
    NgxIntlTelInputModule,
    MatSelectTrigger,
    MatOption,
    MatMenu,
    MatMenuContent,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatTooltip,
    ConnectionLogosComponent,
    AsyncPipe,
    TranslateModule,
    ShowOperatorPipe,
  ],
})
export class ConditionalMetricComponent
  implements OnInit, OnDestroy, OnChanges
{
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() dataset: Dataset;
  @Input() fields: ScopeField[] = [];
  @Input() page: ReportPage;
  @Input() metricTypes: string[] = [];
  @Input() widgetDataForm: FormGroup;

  // Children
  @ViewChild('measureMenuTrigger') measureMenuTrigger: MatMenuTrigger;

  // Properties
  selectedMetric: ScopeField;
  subs: Subscription = new Subscription();
  loading = false;
  metrics: ScopeField[] = [];
  valueTypeCtrl: UntypedFormControl = new UntypedFormControl('metric');

  public filteredMetrics: ReplaySubject<ScopeField[]> = new ReplaySubject<
    ScopeField[]
  >(1);
  public fieldFilterCtrl: UntypedFormControl = new UntypedFormControl({
    value: '',
    disabled: true,
  });
  protected onDestroySubject = new Subject<void>();

  // Forms
  measureForm: UntypedFormGroup;
  conditionalForm: UntypedFormGroup;

  logicOperators: LogicOperator[] = [
    {
      operatorLabel: 'Equals (=)',
      operatorName: 'eq',
    },
    {
      operatorLabel: 'Less Than (<)',
      operatorName: 'lt',
    },
    {
      operatorLabel: 'Greater Than (>)',
      operatorName: 'gt',
    },
    {
      operatorLabel: 'Less Than or Equals (<=)',
      operatorName: 'lte',
    },
    {
      operatorLabel: 'Greater Than or Equals (>=)',
      operatorName: 'gte',
    },
    {
      operatorLabel: 'Not Equal (<>)',
      operatorName: 'ne',
    },
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    public scopeService: ScopeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields && changes.fields.currentValue) {
      this.fields = changes.fields.currentValue;
      this.metrics = this.fields.filter(
        (field) => field.dataType && this.metricTypes.includes(field.dataType)
      );
      this.getWidgetFiltered();
    }

    if (
      changes.dataset?.previousValue &&
      changes.dataset?.previousValue._id &&
      changes.dataset?.previousValue._id !== changes.dataset?.currentValue._id
    ) {
      if (this.widget && this.widget?.dynamicConditionalData?.fields) {
        setTimeout(() => {
          (this.conditionalForm.controls['fields'] as UntypedFormArray).clear();
          this.addCondition();
        }, 0);
      }
    }
  }

  ngOnInit(): void {
    // Initialize Measure Form
    this.measureForm = new UntypedFormGroup({
      metric: new UntypedFormControl(
        {value: '', disabled: false},
        Validators.required
      ),
      aggregation: new UntypedFormControl(
        {value: '', disabled: true},
        Validators.required
      ),
      valueType: new UntypedFormControl('current'),
    });

    this.subs.add(
      this.valueTypeCtrl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((res: string): void => {
          this.updateMeasureForm(res);
        })
    );

    this.conditionalForm = this.formBuilder.group({
      fields: this.formBuilder.array([]),
    });

    // Initialize Conditional Form
    if (
      this.widget.dynamicConditionalData?.fields &&
      this.widget.dynamicConditionalData?.fields?.length > 0
    ) {
      this.setConditionalFormData();
    } else {
      this.addCondition();
    }

    this.conditionalForm.valueChanges.subscribe((): void => {
      this.widget.dynamicConditionalData = this.conditionalForm?.getRawValue();
      if (!this.widget.isEditStyle) {
        this.widget.isEditStyle = true;
      }

      if (
        allPropertiesAreNotNullNorUndefined(
          this.widget?.dynamicConditionalData!.fields
        )
      ) {
        this.widgetDataForm
          .get('advancedRichText')
          ?.setValue(this.widget?.dynamicConditionalData);
        this.widgetDataForm?.markAsDirty();
      }

      this.validateMetricsInsideArray();
    });

    if (this.metrics) {
      this.listenControlSub();
      this.fieldFilterCtrl.enable();
    } else {
      this.fieldFilterCtrl.disable();
    }
  }

  public addCondition(): void {
    const controls = this.conditionalForm.controls.fields as UntypedFormArray;
    let lastFalseValue = '';
    let lastFalseValueText = '';

    if (controls?.length - 1 >= 0) {
      lastFalseValue = this.conditionalForm
        .get('fields')
        ?.['controls'][controls.length - 1]?.get('falseValue')?.value;
      lastFalseValueText = this.conditionalForm
        .get('fields')
        ?.['controls'][controls.length - 1]?.get('falseValuePlainText')?.value;
    }

    controls.push(
      this.formBuilder.group({
        firstCondition: new UntypedFormControl('', [Validators.required]),
        secondCondition: new UntypedFormControl('', [Validators.required]),
        firstConditionType: new UntypedFormControl(''),
        secondConditionType: new UntypedFormControl(''),
        operator: new UntypedFormControl('', [Validators.required]),
        trueValue: new UntypedFormControl('', [Validators.required]),
        falseValue: new UntypedFormControl(lastFalseValue || '', [
          Validators.required,
        ]),
        trueValuePlainText: new UntypedFormControl('', [Validators.required]),
        falseValuePlainText: new UntypedFormControl(lastFalseValueText || '', [
          Validators.required,
        ]),
      })
    );

    if (controls?.length - 1 >= 1) {
      this.conditionalForm
        .get('fields')
        ?.['controls'][controls.length - 2]?.removeControl('falseValue');
      this.conditionalForm
        .get('fields')
        ?.[
          'controls'
        ][controls.length - 2]?.removeControl('falseValuePlainText');
    }
  }

  public removeCondition(index: number): void {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant(
            'shared.components.conditional_metric.remove_condition'
          ),
          button: 'No',
          innerHtml: this.translate.instant(
            'shared.components.conditional_metric.confirm_delete_condition'
          ),
        },
      });

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    this.subs.add(
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          const controls = this.conditionalForm.controls
            .fields as UntypedFormArray;
          let lastFalseValue = '';
          let lastFalseValueText = '';
          let isChecked = false;

          for (let i = controls.length; i-- > 0; ) {
            if (i >= index) {
              // Get the last false value if exists
              if (i === controls?.length - 1 && !isChecked) {
                lastFalseValue =
                  controls?.controls[controls?.length - 1].get(
                    'falseValue'
                  )?.value;
                lastFalseValueText = controls?.controls[
                  controls?.length - 1
                ].get('falseValuePlainText')?.value;
                isChecked = true;
              }
              controls.removeAt(i);
            }
          }
          if (index - 1 >= 0) {
            const control = controls?.controls[index - 1] as FormGroup;
            control.addControl(
              'falseValue',
              this.formBuilder.control(lastFalseValue, [Validators.required])
            );
            control.addControl(
              'falseValuePlainText',
              this.formBuilder.control(lastFalseValueText, [
                Validators.required,
              ])
            );
            this.changeDetectorRef.detectChanges();
          }
        }
        dialogSub.unsubscribe();
      })
    );
  }

  private setConditionalFormData(): void {
    const controls: UntypedFormArray = this.conditionalForm.controls
      .fields as UntypedFormArray;
    this.widget.dynamicConditionalData?.fields?.forEach(
      (field: DynamicConditional): void => {
        const controlData = this.formBuilder.group({
          firstCondition: new UntypedFormControl(field?.firstCondition || '', [
            Validators.required,
          ]),
          secondCondition: new UntypedFormControl(
            field?.secondCondition || '',
            [Validators.required]
          ),
          firstConditionType: new UntypedFormControl(
            field?.firstConditionType || ''
          ),
          secondConditionType: new UntypedFormControl(
            field?.secondConditionType || ''
          ),
          operator: new UntypedFormControl(field?.operator || '', [
            Validators.required,
          ]),
          trueValue: new UntypedFormControl(field?.trueValue || '', [
            Validators.required,
          ]),
          trueValuePlainText: new UntypedFormControl(
            field?.trueValuePlainText || '',
            [Validators.required]
          ),
        });

        if (field?.falseValue || field?.falseValuePlainText?.length === 0) {
          controlData.addControl(
            'falseValue',
            this.formBuilder.control(field?.falseValue || '', [
              Validators.required,
            ])
          );
          controlData.addControl(
            'falseValuePlainText',
            this.formBuilder.control(field?.falseValuePlainText || '', [
              Validators.required,
            ])
          );
        }

        controls.push(controlData);
      }
    );
  }

  /* Mat Menu Operations */
  onCloseMeasureMenu(): void {
    this.measureForm.reset();
    this.fieldFilterCtrl.reset();
    if (this.measureForm?.controls?.valueType) {
      this.measureForm.get('valueType')?.setValue('current');
    }
    this.valueTypeCtrl.setValue('metric');
  }

  onOpenMeasureMenu(index: number, position: number): void {
    if (index >= 0 && position) {
      const controls = (this.conditionalForm?.get('fields') as UntypedFormArray)
        .controls;
      if (position === 1) {
        controls[index].get('firstCondition')?.markAsTouched();
        if (
          controls[index].get('firstCondition')?.value &&
          controls[index].get('firstConditionType')?.value
        ) {
          this.valueTypeCtrl.setValue(
            controls[index].get('firstConditionType')?.value
          );
          this.showAsEdit(
            controls[index].get('firstCondition')?.value,
            controls[index].get('firstConditionType')?.value
          );
        }
      }
      if (position === 2) {
        controls[index].get('secondCondition')?.markAsTouched();
        if (
          controls[index].get('secondCondition')?.value &&
          controls[index].get('secondConditionType')?.value
        ) {
          this.valueTypeCtrl.setValue(
            controls[index].get('secondConditionType')?.value
          );
          this.showAsEdit(
            controls[index].get('secondCondition')?.value,
            controls[index].get('secondConditionType')?.value
          );
        }
      }
    }
  }

  showAsEdit(value: string, valueType: string): void {
    if (valueType === 'metric') {
      const values: Array<string> = value.split('_');
      const metricValue =
        this.widget.metrics?.find((m) => m.id === values[0]) || '';
      this.fieldFilterCtrl.setValue(metricValue);
      this.measureForm?.get('metric')?.setValue(metricValue);
      this.measureForm
        ?.get('aggregation')
        ?.setValue(values[1] ? values[1] : '');
      this.measureForm.get('aggregation')?.enable();
      this.measureForm
        ?.get('valueType')
        ?.setValue(values[2] ? values[2] : 'current');
    }

    if (valueType === 'fixedValue') {
      this.measureForm?.get('fixedValue')?.setValue(value);
    }
  }

  /* Measure Form Operations */
  updateMeasureForm(type: string): void {
    if (type === 'fixedValue') {
      this.measureForm.removeControl('metric');
      this.measureForm.removeControl('aggregation');
      this.measureForm.removeControl('valueType');
      this.measureForm.addControl(
        'fixedValue',
        this.formBuilder.control('', [Validators.required])
      );
    } else {
      this.measureForm.removeControl('fixedValue');
      this.measureForm.addControl(
        'metric',
        this.formBuilder.control('', [Validators.required])
      );
      this.measureForm.addControl(
        'aggregation',
        this.formBuilder.control('', [Validators.required])
      );
      this.measureForm.addControl(
        'valueType',
        this.formBuilder.control('current', [Validators.required])
      );
    }
  }

  private listenControlSub(): void {
    this.fieldFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe((value) => {
        if (typeof value !== 'string') {
          this.measureForm.get('metric')?.setValue(value);
        }

        if (!value) {
          if (this.metrics) {
            this.filteredMetrics.next(this.metrics.slice());
          }
          return;
        } else {
          value = typeof value === 'string' ? value.toLowerCase() : value;
        }

        this.filteredMetrics.next(
          this.metrics.filter(
            (field: {name: string}) =>
              field.name.toLowerCase().indexOf(value) > -1
          )
        );
      });
  }

  onMetricChange(event: MatAutocompleteSelectedEvent): void {
    if (event?.option?.value) {
      this.measureForm.get('aggregation')?.enable();
      this.measureForm.get('aggregation')?.setValue('');
      this.selectedMetric = this.metrics.filter(
        (field: ScopeField) => field.id === event?.option?.value?.id
      )[0];
      if (this.selectedMetric && this.selectedMetric.defaultAggregation) {
        this.measureForm
          .get('aggregation')
          ?.setValue(this.selectedMetric.defaultAggregation);
      }
    } else {
      this.measureForm.get('aggregation')?.disable();
    }
  }

  displayMetric(filter: ScopeField): string {
    return filter && filter.name ? filter.name : '';
  }

  // CKEditor Dialog
  openEditor(index: number, position: number): void {
    const controls: AbstractControl[] = (
      this.conditionalForm?.get('fields') as UntypedFormArray
    ).controls;
    const text =
      position === 1
        ? controls[index].get('trueValue')?.value
        : controls[index].get('falseValue')?.value;

    const dialogRef: MatDialogRef<DynamicTextComponent> = this.dialog.open(
      DynamicTextComponent,
      {
        data: {index, position, text},
        width: '580px',
        minHeight: '350px',
      }
    );

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: any): void => {
        if (res?.index >= 0 && res?.position && res?.text) {
          if (position === 1) {
            controls[index].get('trueValue')?.setValue(res.text);
            controls[index].get('trueValuePlainText')?.setValue(res?.plainText);
          }
          if (position === 2) {
            controls[index].get('falseValue')?.setValue(res.text);
            controls[index]
              .get('falseValuePlainText')
              ?.setValue(res?.plainText);
          }
          this.conditionalForm.markAsDirty();
          this.changeDetectorRef.detectChanges();
        }
        dialogSub.unsubscribe();
      });
  }

  onSaveMetric(index: number, position: number): void {
    if (this.measureForm.invalid) {
      return;
    }

    if (
      index >= 0 &&
      position &&
      this.conditionalForm?.get('fields')?.value?.length > 0
    ) {
      const controls = (this.conditionalForm?.get('fields') as UntypedFormArray)
        .controls;
      let value: string;
      if (this.valueTypeCtrl.value === 'metric') {
        value = `${
          this.measureForm.get('metric')?.value.id
        }_${this.measureForm.get('aggregation')?.value}`;
        if (this.measureForm.get('valueType')?.value !== 'current') {
          value += `_${this.measureForm.get('valueType')?.value}`;
        }
      } else {
        value = this.measureForm.get('fixedValue')?.value;
      }

      if (value && position === 1) {
        controls[index].get('firstCondition')?.setValue(value);
        controls[index]
          .get('firstConditionType')
          ?.setValue(this.valueTypeCtrl.value);
      }
      if (value && position === 2) {
        controls[index].get('secondCondition')?.setValue(value);
        controls[index]
          .get('secondConditionType')
          ?.setValue(this.valueTypeCtrl.value);
      }
      this.conditionalForm.markAsDirty();
    }

    this.measureMenuTrigger?.closeMenu();
  }

  getWidgetFiltered(): void {
    const [fields, scope] = this.scopeService.onFieldSelectionChanged(
      this.widget,
      this.fields
    );
    this.fields = fields;
    this.widget.scope = scope;

    this.setWidgetFilteredFields();
  }

  setWidgetFilteredFields(search = ''): void {
    const widgetConfiguration: WidgetConfiguration = {
      widget: this.widget,
      fields: this.fields,
      locationDimension: [],
      allowDuplicate: true,
      search,
      from: 'widget',
    };

    this.metrics =
      this.scopeService.filterMetricsAndDimensionsByFields(
        widgetConfiguration
      )[1];
    this.filteredMetrics.next(this.metrics);
  }

  validateMetricsInsideArray(): void {
    const fields =
      (this.conditionalForm?.controls?.fields as UntypedFormArray) ?? [];

    if (fields.length === 0) {
      return;
    }

    this.widget.metrics = this.scopeService.updateWidgetMetricsFromMatches(
      this.metrics,
      fields.controls
    );

    this.getWidgetFiltered();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }
}
