import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import {Connection} from '../../../models';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-sign-in-button',
    templateUrl: './sign-in-button.component.html',
    styleUrls: ['./sign-in-button.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class SignInButtonComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() service: Connection;
  @Input() isConnecting: boolean;
  @Input() isTokenAuth: boolean;
  @Input() mainSignIn: boolean;
  @Output() addNewAccount: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Properties
  header: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.service) {
      this.service = changes.service.currentValue;
      this.setTitle(this.service?.provider ? this.service.provider : '');
    }
  }

  public onAddNewAccount(): void {
    this.addNewAccount.emit(true);
  }

  private setTitle(type: string): void {
    const serviceTitles = {
      'adwords': 'Google',
      'google-analytics': 'Google',
      'bing': 'Microsoft',
      'call-tracking-metrics': 'Call Tracking Metrics',
      'sm-facebook-ads': 'Facebook',
      'facebook-ads': 'Facebook',
      'sm-facebook-insights': 'Facebook',
      'facebook-insights': 'Facebook',
      'call-rail': 'Call Rail',
      'google-search-console': 'Google',
      'google-analytics-v4': 'Google',
      'youtube': 'Google',
      'sm-instagram-insights': 'Facebook',
      'instagram-insights': 'Facebook',
      'semrush': 'Semrush',
      'linkedin-ads': 'LinkedIn',
      'linkedin-insights': 'LinkedIn',
    };

    const provider = serviceTitles.hasOwnProperty(type) ? serviceTitles[type] : this.service?.name!;
    this.header = this.translate.instant(
      'shared.dialogs.manage_credentials.sign_in_with',
      {provider}
    );
  }
}
