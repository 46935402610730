<section
  [ngClass]="{'isSelected': isSelected}"
  (click)="onSelectCardTemplate()"
  class="template-card pb-3"
>
  <article class="template-header">
    <div class="d-flex align-items-center gap-1">
      @if (type === "report" && lockGlobal) {
        <span class="is-global-template header-icon">
          <i class="fa-regular fa-lock-keyhole"></i>
        </span>
      }
      @if (type === "report") {
        <button
          (click)="onFavoriteTemplate()"
          [ngClass]="{'pe-none': from === 'wizard'}"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon star-icon"
        >
          <i
            [ngClass]="{
              'fa-light': !templateCard.favorite,
              'fa-solid star-fill-color': templateCard.favorite,
              'fa-spin': templateCard.updatingFavorite,
            }"
            class="fa-star"
          ></i>
        </button>
      }
      <span class="wsm-text-grayed">
        {{ templateCard.language! || "EN" }}
      </span>
      <span class="layout-icon">
        @if (templateCard.orientation === "horizontal") {
          <i class="wsm-text-grayed fak fa-landscape-report"></i>
        }
        @if (templateCard.orientation !== "horizontal") {
          <i class="wsm-text-grayed fak fa-portrait-report"></i>
        }
      </span>
    </div>

    @if (from !== "wizard" && !isFromStarted) {
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon btn-options align-self-end"
        #pageMenu="matMenuTrigger"
        [matMenuTriggerFor]="templateCardMenu"
      >
        <i class="fa-regular fa-ellipsis-vertical"></i>
      </button>
    }
    <mat-menu #templateCardMenu="matMenu" class="mat-menu-gray">
      @if (type === "report") {
        <button
          id="preview-template"
          mat-menu-item
          (click)="onPreviewTemplate()"
        >
          {{
            "shared.components.template_gallery_card.preview_button" | translate
          }}
        </button>
      }
      <button id="use-template" mat-menu-item (click)="onSelectTemplate()">
        {{ "shared.components.template_gallery_card.use_button" | translate }}
      </button>
      @if (type === "report") {
        @if (canEditIsGlobal && from !== "main") {
          <button mat-menu-item (click)="onTemplateEdit()">
            {{
              "shared.components.template_gallery_card.edit_button" | translate
            }}
          </button>
        }
        @if (!lockGlobal && from !== "main") {
          <button mat-menu-item (click)="onTemplateEdit()">
            {{
              "shared.components.template_gallery_card.edit_button" | translate
            }}
          </button>
        }
      }
      @if (type === "report" && from !== "main") {
        @if (canEditIsGlobal) {
          @if (canDelete) {
            <button mat-menu-item (click)="onDeleteTemplate()">
              {{
                "shared.components.template_gallery_card.delete_button"
                  | translate
              }}
            </button>
          }
        }
        @if (!lockGlobal) {
          @if (canDelete && from !== "main") {
            <button mat-menu-item (click)="onDeleteTemplate()">
              {{
                "shared.components.template_gallery_card.delete_button"
                  | translate
              }}
            </button>
          }
        }
      } @else {
        @if (canDelete && from !== "main") {
          <button mat-menu-item (click)="onDeleteTemplate()">
            {{
              "shared.components.template_gallery_card.delete_button"
                | translate
            }}
          </button>
        }
      }
    </mat-menu>
  </article>

  <article class="template-content">
    <div class="actions-and-title">
      <p class="mb-2">
        <span
          matTooltip="{{ templateCard.name }}"
          matTooltipPosition="above"
          matTooltipClass="wsm-tooltip tooltip-above"
        >
          {{ templateCard.name | truncate: 20 }}
        </span>
      </p>
    </div>

    <div class="connections-and-labels">
      @if (
        templateCard?.connections && templateCard?.connections?.length! > 0
      ) {
        <div class="d-flex flex-row flex-wrap gap-2 mb-2">
          <app-connection-logos
            [connections]="templateCard.connections"
            type="template"
          ></app-connection-logos>
        </div>
      }

      <div class="d-flex flex-row flex-wrap gap-2 custom-labels">
        @if (templateCard?.goalType && templateCard?.goalType?.length! > 0) {
          @if (!areShortLabels) {
            @for (goal of templateCard?.goalType; track goal) {
              <small
                [matTooltip]="goal"
                matTooltipPosition="below"
                matTooltipClass="wsm-tooltip tooltip-below"
                class="wsm-tag info template-tag me-2 truncate"
                >{{ goal }}</small
              >
            }
          } @else {
            @for (
              goal of templateCard?.goalType | slice: 0 : goalTypeSlice;
              track goal
            ) {
              <small
                [matTooltip]="goal"
                matTooltipPosition="below"
                matTooltipClass="wsm-tooltip tooltip-below"
                class="wsm-tag info template-tag me-2 truncate"
                >{{ goal }}</small
              >
            }
            @if (templateCard?.goalType?.length! > goalTypeSlice) {
              <small
                class="wsm-tag info template-tag me-2 show-more"
                [satPopoverAnchor]="popLabels"
                (mouseenter)="popLabels.open()"
                (mouseleave)="popLabels.close()"
              >
                +{{ templateCard?.goalType?.length! - goalTypeSlice }}
              </small>
            }
          }
        }
      </div>

      <sat-popover
        #popLabels
        horizontalAlign="center"
        verticalAlign="below"
        panelClass="custom-pop-icon"
      >
        <div class="label-wrapper">
          <div class="popover-name-arrow"></div>
          <div
            class="my-1 d-flex flex-column align-items-start justify-content-start"
          >
            @for (
              goal of templateCard?.goalType | slice: goalTypeSlice;
              track goal;
              let last = $last
            ) {
              <small
                class="wsm-tag info template-tag"
                [ngClass]="{'mb-2': !last}"
                >{{ goal }}</small
              >
            }
          </div>
        </div>
      </sat-popover>
    </div>
  </article>
</section>
