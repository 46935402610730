import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {ReportTheme} from 'src/app/shared/models';
import { NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'app-classic-player',
    templateUrl: './classic-player.component.html',
    styleUrls: ['./classic-player.component.scss'],
    standalone: true,
    imports: [NgStyle, NgClass],
})
export class ClassicPlayerComponent implements OnInit, OnDestroy {
  // Inputs / Output
  @Input() audioUrl: string;
  @Input() reportTheme: ReportTheme;
  @Input() getTimeCodeFromNum: any;
  @Output() hidePlayer = new EventEmitter();

  @ViewChild('progress', {static: true}) progress: ElementRef<HTMLElement>;
  @ViewChild('timeline', {static: true}) timeline: ElementRef<HTMLElement>;
  @ViewChild('volumePercentage', {static: true})
  volumePercentage: ElementRef<HTMLElement>;
  @ViewChild('volumeSlider', {static: true})
  volumeSlider: ElementRef<HTMLElement>;

  // State
  isPlaying = false;

  // Properties
  audio = new Audio();
  audioDuration: string;
  currentDuration = '00:00';

  constructor() {
    //
  }

  ngOnInit(): void {
    if (this.audioUrl) {
      this.audio.src = this.audioUrl;

      this.audio.addEventListener(
        'loadeddata',
        () => {
          this.audioDuration = this.getTimeCodeFromNum(this.audio.duration);
        },
        false
      );

      this.checkAudioEnd();
      this.updateProgress();
    }
  }

  ngOnDestroy(): void {
    if (this.audioUrl) {
      this.audio.pause();
    }
  }

  togglePlay(): void {
    if (this.audioUrl) {
      if (this.audio.paused) {
        this.audio.play().then();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    }
  }

  updateProgress(): void {
    setInterval(() => {
      this.progress.nativeElement.style.width =
        (this.audio.currentTime / this.audio.duration) * 100 + '%';

      this.currentDuration = this.getTimeCodeFromNum(this.audio.currentTime);
    }, 500);
  }

  checkAudioEnd(): void {
    this.audio.addEventListener(
      'ended',
      () => {
        this.audio.currentTime = 0;
        this.isPlaying = false;
        this.progress.nativeElement.style.width = '0%';
      },
      false
    );
  }

  skipAround(event: any): void {
    if (this.audioUrl) {
      const timelineWidth = this.timeline.nativeElement.clientWidth;
      this.audio.currentTime =
        (event.offsetX / timelineWidth) * this.audio.duration;
    }
  }

  changeVolume(event: any): void {
    if (this.audioUrl) {
      const sliderWidth = this.volumeSlider.nativeElement.clientWidth;
      const newVolume = event.offsetX / sliderWidth;
      this.audio.volume = newVolume;
      this.volumePercentage.nativeElement.style.width = newVolume * 100 + '%';
    }
  }

  muteAudio(): void {
    if (this.audioUrl) {
      this.audio.muted = !this.audio.muted;
    }
  }

  closePlayer(): void {
    this.hidePlayer.emit(this.audioUrl);
  }
}
