import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ResFile} from '../../models';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-upload-button',
    templateUrl: './upload-button.component.html',
    styleUrls: ['./upload-button.component.scss'],
    standalone: true,
})
export class UploadButtonComponent implements OnChanges {
  @Input() parentFile: File | null;
  @Input() parentFileName: string;
  @Input() disabled: boolean;
  @Input() noDispatch?: boolean = false; // if true -> do not emit the resFile output

  @ViewChild('imageInput') imageInput: ElementRef;

  @Output() resFile: EventEmitter<ResFile | null> =
    new EventEmitter<ResFile | null>();

  public imageName: string | null;

  constructor(private domSanitizationService: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.parentFile) {
      if (this.parentFile) {
        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsDataURL(this.parentFile as File);
          reader.onload = (): void => {
            const imgSrc = this.domSanitizationService.bypassSecurityTrustUrl(
              reader.result?.toString() as string
            );
            if (!this.noDispatch && this.parentFile) {
              this.imageName = this.parentFile.name;
              this.resFile.emit({
                fileName: this.parentFile.name,
                fileSource: imgSrc!,
                fileFile: this.parentFile,
              });
            }
          };
        }, 0);
      }
    } else {
      this.imageName = changes.parentFileName?.currentValue;
    }
  }

  handleFileInput(event): void {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length !== 0) {
      const mimeType = files!.item(0)!.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }

      const imgFile = files!.item(0);
      const imgName = imgFile!.name;

      const reader = new FileReader();
      reader.readAsDataURL(imgFile!);
      reader.onload = (): void => {
        const imgSrc = reader.result;
        this.noDispatch = false;
        this.imageName = imgName;
        !this.noDispatch &&
          this.resFile.emit({
            fileName: imgName,
            fileSource: imgSrc,
            fileFile: imgFile,
          });
      };
    }
  }

  removeImage(): void {
    this.imageName = null;
    this.resFile.emit(null);
    this.imageInput.nativeElement.value = '';
  }
}
