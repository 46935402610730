import {
  PageTemplate,
  ReportTemplate,
  WidgetTemplate,
} from '../../../shared/models';
import {
  signalStore,
  withMethods,
  withState,
  WritableStateSource,
} from '@ngrx/signals';
import {updateState, withDevtools} from '@angular-architects/ngrx-toolkit';

export interface GetStartedState {
  currentStep: number;
  validSteps: Array<number>;
  companyId: string | null;
  template: ReportTemplate | PageTemplate | WidgetTemplate | null;
}

type GetStartedStateSignal = GetStartedState;

const getStartedInitialState: GetStartedStateSignal = {
  currentStep: 1,
  validSteps: [],
  companyId: null,
  template: null,
};

export const GetStartedStoreSignal = signalStore(
  {providedIn: 'root'},
  withDevtools('GetStartedStore'),
  // getstarted state
  withState(getStartedInitialState),

  // getstarted store methods/computed/hooks
  withMethods((store) => ({
    updateStartedMethod: (payload: Partial<GetStartedStateSignal>): void =>
      _updateStarted(store, payload),
  }))
);

function _updateStarted(
  store: WritableStateSource<GetStartedStateSignal>,
  payload: Partial<GetStartedStateSignal>
): void {
  updateState(store, '[GetStarted] Update Started', (state) => ({
    ...state,
    ...payload,
  }));
}
