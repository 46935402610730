import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {CardData, ReportTheme, Widget} from '../../../models';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import {DialogService} from 'src/app/core/services';
import {AdPreviewDialogComponent} from 'src/app/shared/dialogs';
import { NgClass, NgStyle, PercentPipe, DatePipe } from '@angular/common';
import { AdPreviewComponent } from '../../../components/widgets/ad-preview/ad-preview.component';
import { AudioPlayerComponent } from '../../../components/audio-player/audio-player.component';
import { WidgetPopoverComponent } from '../../../components/widgets/widget-popover/widget-popover.component';
import { TranslateModule } from '@ngx-translate/core';
import { TransformDatePipe } from '../../../pipes/transform-date.pipe';
import { DateAsAgoPipe } from '../../../pipes/date-as-ago.pipe';

@Component({
    selector: 'app-card-carousel',
    templateUrl: './card-carousel.component.html',
    styleUrls: ['./card-carousel.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        AdPreviewComponent,
        AudioPlayerComponent,
        WidgetPopoverComponent,
        MatCheckbox,
        PercentPipe,
        DatePipe,
        TranslateModule,
        TransformDatePipe,
        DateAsAgoPipe,
    ],
})
export class CardCarouselComponent implements OnInit, OnChanges {
  // Inputs / Outputs
  @Input() cardData: CardData;
  @Input() indicatorsOn: boolean;
  @Input() indicatorNumber = 3;
  @Input() isPrint: boolean;
  @Input() usePagination: boolean;
  @Input() totalRecords: number;
  @Input() themes: {
    theme: ReportTheme;
    reportTheme: ReportTheme;
    pageTheme: ReportTheme;
  } = {
    theme: {},
    reportTheme: {},
    pageTheme: {},
  };
  @Input() widget: Widget;
  @Output() handledButton = new EventEmitter();
  @Output() recordLimitReached = new EventEmitter();

  // Properties
  audioList = {};
  selectedIndex = 0;
  oldIndex = 0;
  min = 0;
  max: number;

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    this.max = this.indicatorNumber - 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedIndex !== this.oldIndex) {
      if (changes.cardData) {
        this.selectedIndex = 0;
        if (this.cardData) {
          this.setCurrentPosition(0);
        }
      }
    } else if (this.selectedIndex !== 0) {
      this.setNextPosition();
    }
  }

  setCurrentPosition(index: number): void {
    this.selectedIndex = index;
    this.cardData.elements[0].marginLeft = -100 * this.selectedIndex;
    this.calculateMinMaxIndicators();
  }

  setNextPosition(): void {
    let finalPercentage = 0;
    let nextPosition = this.selectedIndex + 1;

    if (nextPosition <= this.cardData.elements.length - 1) {
      finalPercentage = -100 * nextPosition;
    } else {
      if (this.usePagination && this.selectedIndex + 1 !== this.totalRecords) {
        this.recordLimitReached.emit(true);
        nextPosition = this.selectedIndex;
        this.oldIndex = this.selectedIndex;
        finalPercentage = -100 * this.selectedIndex;
      } else {
        nextPosition = 0;
      }
    }

    this.cardData.elements[0].marginLeft = finalPercentage;
    this.selectedIndex = nextPosition;
    this.calculateMinMaxIndicators();
  }

  setBackPosition(): void {
    let finalPercentage = 0;
    let backPosition = this.selectedIndex - 1;

    if (backPosition >= 0) {
      finalPercentage = -100 * backPosition;
    } else {
      if (!this.usePagination) {
        backPosition = this.cardData.elements.length - 1;
        finalPercentage = -100 * backPosition;
      } else {
        backPosition = 0;
      }
    }

    this.cardData.elements[0].marginLeft = finalPercentage;
    this.selectedIndex = backPosition;
    this.calculateMinMaxIndicators();
  }

  onSwipe(evt): void {
    const x =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'left') {
      this.setNextPosition();
    }
    if (x === 'right') {
      this.setBackPosition();
    }
  }

  getIndicatorClass(indicatorIndex: number): string {
    if (indicatorIndex === this.selectedIndex) {
      return 'active';
    }
    if (indicatorIndex >= this.min && indicatorIndex <= this.max) {
      return 'normal';
    }
    if (indicatorIndex === this.min - 1 || indicatorIndex === this.max + 1) {
      return 'small';
    }
    return 'hidden';
  }

  calculateMinMaxIndicators(): void {
    if (this.selectedIndex < this.min) {
      this.min = this.selectedIndex;
      this.max = this.min + (this.indicatorNumber - 1);
      if (this.max > this.cardData.elements.length) {
        this.max = this.cardData.elements.length;
      }
    }
    if (this.selectedIndex > this.max) {
      this.max = this.selectedIndex;
      this.min = this.max - (this.indicatorNumber - 1);
      if (this.min < 0) {
        this.min = 0;
      }
    }
  }

  public onHandleButton(action: string, element: any): void {
    this.handledButton.emit({action, element});
  }

  public onHandleCheckbox(
    event: MatCheckboxChange,
    action: string,
    element: any
  ): void {
    this.handledButton.emit({action, element, checked: event.checked});
  }

  //Audio player handling
  playAudio(audioData: any): void {
    if (audioData) {
      if (!this.audioList[`${audioData}`]) {
        this.audioList[`${audioData}`] = true;
      }
    }
  }

  checkAudio(audioData: any): boolean {
    return !!this.audioList[`${audioData}`];
  }

  hideAudioPlayer(event: any): void {
    this.audioList[`${event?.audioUrl}`] = false;
  }

  openAdPreview(adPreview: HTMLElement): void {
    this.dialogService.openDialog(AdPreviewDialogComponent, {
      data: {
        adPreview,
      },
    });
  }
}
