<button (click)="logout()" class="wsm-btn logout-button">
  {{ "profileNavbar.logout" | translate }}
</button>
<div class="d-flex h-100">
  <div
    class="side-banner d-flex justify-content-lg-start align-items-center position-relative"
  >
    @if (step !== 1) {
      <img
        src="/assets/img/svg/clicky-signup.svg"
        class="clicky-position"
        alt=""
        width="75"
        height="160"
      />
    }
  </div>
  <div
    class="w-100 position-relative"
    [ngClass]="{'d-flex justify-content-center': !isLoading}"
  >
    <app-loading [generalLoading]="isLoading"></app-loading>

    @if (step === 1) {
      <app-unauthorized-page
        [title]="title"
        [message]="message"
        [buttonText]="buttonText"
        [lottiePath]="lottiePath"
        (actionToggled)="goToPlanSelection()"
      ></app-unauthorized-page>
    }

    @if (step === 2) {
      <div class="plan-selection-container">
        <div class="justify-content-center align-items-center text-center mb-3">
          <img src="/assets/img/svg/logo.svg" class="mb-2" alt="logo" />
          <h1 class="mb-2 text-nowrap">
            {{
              "common.unauthorized_page.free_trial.choose_your_plan" | translate
            }}
          </h1>
          <span>{{
            "common.unauthorized_page.free_trial.choose_your_plan_desc"
              | translate
          }}</span>
        </div>
        <app-plan-selection
          (productsLoaded)="finishLoading()"
          (paymentCompleted)="router.navigate(['/home'])"
        ></app-plan-selection>
      </div>
    }
  </div>
</div>
