import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {debounceTime, merge, Subscription} from 'rxjs';
import {ColorService, getValidThemeKeys, ScopeService} from '../../../helpers';
import {
  FontawesomeIcon,
  FontawesomeService,
} from 'src/app/core/services/fontawesome.service';
import {WidgetDataComponent} from '../widget-data/widget-data.component';
import {MatTabGroup, MatTab, MatTabContent} from '@angular/material/tabs';
import {WidgetStyleComponent} from '../widget-style/widget-style.component';
import {NgStyle, NgClass} from '@angular/common';
import {WidgetPreviewComponent} from '../widget-preview/widget-preview.component';
import {LoadingComponent} from '../../loading/loading.component';
import {TranslateModule} from '@ngx-translate/core';
import {Widget} from 'src/app/shared/models/widget/widget.model';
import {ReportTheme} from 'src/app/shared/models/report/report-theme.model';
import {Dataset, ScopeField} from 'src/app/shared/models/dataset.model';
import {
  ReportFilter,
  ReportPage,
} from 'src/app/shared/models/report/report.model';
import {ReportingDatasetsService} from 'src/app/core/services/reporting-datasets.service';
import {NotificationService} from 'src/app/core/services/notification.service';
import {WidgetService} from 'src/app/core/services/widget.service';
import {CustomError} from 'src/app/shared/models/custom-error.model';
import {toObservable} from '@angular/core/rxjs-interop';
import {DatasetStoreSignal} from 'src/app/modules/reporting/state/dataset.store';
import {ReportPageStoreSignal} from 'src/app/modules/reporting/state/report-page.store';
import {hasWidgetSortBy} from 'src/app/shared/pipes';

@Component({
  selector: 'app-widget-editor',
  templateUrl: './widget-editor.component.html',
  styleUrls: ['./widget-editor.component.scss'],
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabContent,
    WidgetDataComponent,
    WidgetStyleComponent,
    NgStyle,
    NgClass,
    WidgetPreviewComponent,
    LoadingComponent,
    TranslateModule,
  ],
})
export class WidgetEditorComponent implements OnInit, OnDestroy {
  // Getting child pages
  @ViewChild('widgetDataComponent') widgetDataComponent: WidgetDataComponent;

  // Properties
  subs: Subscription = new Subscription();
  widget: Widget;
  theme: ReportTheme;
  reportTheme: ReportTheme;
  fields: ScopeField[] = [];
  pageFilters: ReportFilter[] = [];
  reportPage: ReportPage;
  formType: string;
  widthPreview: string;
  heightPreview: string;
  widgetOriginal: string;
  public showTableMessage = signal<boolean>(false);

  // State
  previewed = false;
  loading = false;
  loadingPreview;
  filterApplied = false;
  sortChange: boolean;
  disableSave = false;

  // Forms
  widgetDataForm: UntypedFormGroup = new UntypedFormGroup({});
  widgetStyleForm: UntypedFormGroup = new UntypedFormGroup({});
  styleFormPreviousValue: object;
  dataFormPreviousValue: object;
  newMeasureForm: UntypedFormGroup;
  newDimensionForm: UntypedFormGroup;

  // List of items to filter
  datasets: Array<Dataset>;

  // Controls
  datasetFilterCtrl: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.required
  );
  ckEditorCtrl: UntypedFormControl = new UntypedFormControl();

  // State Variables
  showPreviewOverlay = false;

  // current scope name
  currentScope = signal<string | null>(null);

  public datasetStoreSignal = inject(DatasetStoreSignal);
  private reportPageStore = inject(ReportPageStoreSignal);
  private getPageActive$ = toObservable(this.reportPageStore.activePage);
  private allDataSet$ = toObservable(this.datasetStoreSignal.getAllDataSets);

  constructor(
    private bottomSheetRef: MatBottomSheetRef<WidgetEditorComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      widget: Widget;
      theme: ReportTheme;
      style: any;
      type: string;
      page: ReportPage;
      reportId: string;
      pages: Array<ReportPage>;
      reportTheme: ReportTheme;
      orientation: string;
    },
    private datasetService: ReportingDatasetsService,
    private notificationService: NotificationService,
    private widgetService: WidgetService,
    private faService: FontawesomeService,
    private scopeService: ScopeService,
    private colorService: ColorService
  ) {}

  async ngOnInit(): Promise<void> {
    // Store Sheet Data
    this.theme = this.data.theme;
    this.widget = JSON.parse(JSON.stringify(this.data.widget));
    this.widgetOriginal = JSON.stringify(this.data.widget);

    if (this.widget.widgetType === 'text') {
      this.widget.widgetType = 'dynamictext';
      this.widget.dynamicTextEdit = 'basic';

      if (!this.widget.textData?.originalHtml) {
        this.widget.textData!.originalHtml = this.widget.textData?.innerHtml;
      }
    }
    this.reportPage = this.data.page;
    this.reportTheme = this.data.reportTheme;
    this.widthPreview =
      this.data.widget.widgetType === 'geoHeatMap'
        ? '100%'
        : this.data.style.width;
    this.heightPreview =
      this.data.widget.widgetType === 'geoHeatMap'
        ? '100%'
        : this.data.style.height;
    this.setFormType();
    // Listen changes on widget forms.
    this.listenWidgetForms();
    await this.getAdclicksKit();
  }

  public listenWidgetForms(): void {
    this.subs.add(
      merge(this.widgetDataForm.valueChanges, this.widgetStyleForm.valueChanges)
        .pipe(debounceTime(0))
        .subscribe({
          next: (): void => {
            const datasetValue = this.widgetDataForm?.get('dataset')?.value;
            const isMetricsNotEmpty: boolean =
              this.widget.metrics?.length !== 0;
            const isDimensionsNotEmpty: boolean =
              this.widget.dimensions?.length !== 0;

            if (
              this.widgetDataForm?.dirty &&
              datasetValue &&
              this.widget.widgetType !== 'dynamictext'
            ) {
              if (
                this.widget.chartType === 'funnel' ||
                this.widget.widgetType === 'gallery' ||
                this.widget.widgetType === 'gauge' ||
                this.widget.widgetType === 'current-goal' ||
                this.widget.widgetType === 'timeline' ||
                this.widget.widgetType === 'geoHeatMap'
              ) {
                if (isMetricsNotEmpty) {
                  this.showPreviewOverlay = true;
                  this.previewed = false;
                } else {
                  this.showPreviewOverlay = false;
                }
              } else {
                if (isMetricsNotEmpty && isDimensionsNotEmpty) {
                  this.showPreviewOverlay = true;
                  this.previewed = false;
                } else {
                  this.showPreviewOverlay = false;
                }
              }
              // ADR-2948: Row Count Feature
              if (this.widget.widgetType === 'scorecard') {
                if (isMetricsNotEmpty || isDimensionsNotEmpty) {
                  this.showPreviewOverlay = true;
                  this.previewed = false;
                } else {
                  this.showPreviewOverlay = false;
                }
              }
            }
            if (this.widget.widgetType === 'dynamictext') {
              if (this.widgetStyleForm?.dirty || this.widgetDataForm?.dirty) {
                this.showPreviewOverlay = true;
                this.previewed = false;
              } else {
                this.showPreviewOverlay = false;
              }
            }

            if (this.widgetStyleForm.dirty) {
              this.showPreviewOverlay = true;
              this.previewed = false;
            }

            this.setShowMessage();
          },
        })
    );

    this.subs.add(
      this.getPageActive$.subscribe((val) => {
        if (val && val.filters) {
          this.pageFilters = val?.filters;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.widget.isEditStyle = false;
  }

  private setShowMessage(): void {
    if (this.widget.widgetType === 'table') {
      const columnCount =
        (this.widget?.dimensions?.length || 0) +
        (this.widget?.metrics?.length || 0);
      const showMessage =
        this.data.orientation === 'horizontal'
          ? columnCount >= 8
          : columnCount >= 6;
      this.showTableMessage.set(showMessage);
    }
  }

  /**
   * Set initial form type according to the widget.
   */
  private setFormType(): void {
    if (
      this.widget.widgetType !== 'text' &&
      this.widget.widgetType !== 'title' &&
      this.widget.widgetType !== 'image' &&
      this.widget.widgetType !== 'container' &&
      this.widget.widgetType !== 'branding'
    ) {
      this.formType = 'data';
      this.getAllDatasets();
    } else {
      this.formType = 'style';
    }
  }

  public async getAdclicksKit(): Promise<void> {
    await this.faService.getFontawesomeToken(true).then((res) => {
      if (res) {
        this.faService.getFontawesomeAdclicksKit().subscribe({
          next: (res: FontawesomeIcon[]): void => {
            this.faService.setAdlicksKit(res);
          },
        });
      }
    });
  }

  private getAllDatasets(): void {
    this.loading = true;
    this.subs.add(
      this.allDataSet$.subscribe((value) => {
        if (value) {
          this.datasets = this.handleDataSet(value);
          this.setCurrentScopeName();
          this.loading = false;
        }
      })
    );
  }

  handleDataSet(dataSets: Array<Dataset>): Array<Dataset> {
    switch (this.widget.widgetType) {
      case 'geoHeatMap':
        return dataSets.reduce((acc: Array<Dataset>, dataset: Dataset) => {
          const scopes = dataset.scopes!.filter((scope) =>
            scope.fields.some((field) => field.dataType === 'location')
          );
          if (scopes.length > 0) {
            acc = [...acc, dataset];
          }
          return acc;
        }, []);
      default:
        return dataSets;
    }
  }

  onTabChanged(event): void {
    this.formType = event.index === 0 ? 'data' : 'style';

    if (
      this.formType === 'style' &&
      this.widget.widgetType !== 'image' &&
      this.widget.widgetType !== 'branding'
    ) {
      this.widget.isEditStyle = true;
    }
  }

  dismiss(event: MouseEvent): void {
    if (this.widgetStyleForm) {
      this.widgetStyleForm.reset();
    } else {
      this.widgetDataForm.reset();
    }

    const widget: Widget = JSON.parse(this.widgetOriginal);
    widget.isOriginal = true;
    this.widgetOriginal = JSON.stringify(widget);

    this.bottomSheetRef.dismiss(JSON.parse(this.widgetOriginal));
    event.preventDefault();
  }

  markControlsAsTouched(formGroup: UntypedFormGroup): void {
    const controls = formGroup?.controls
      ? Object.values(formGroup?.controls)
      : [];

    controls.forEach((control: AbstractControl): void => {
      control.markAsTouched();
    });
  }

  setPreviousStyleForm(event: object): void {
    this.styleFormPreviousValue = event;
  }

  setPreviousDataForm(event: object): void {
    this.dataFormPreviousValue = event;
  }

  setFilterData(event: ScopeField[]): void {
    this.fields = event;
  }

  /**
   * show current scope name (ADR-2702)
   */
  private setCurrentScopeName(): void {
    if (this.widget && this.widget.scope) {
      const currentDataset = this.datasets.find(
        (dataset) => dataset._id === this.widget.dataset
      );
      const scopeName = currentDataset?.scopes?.find(
        (scope) => scope.id === this.widget.scope
      )?.name;
      this.currentScope.set(scopeName ?? null);
    }
  }

  saveWidget(): void {
    let controls: Array<AbstractControl> = [];
    const excludedWidgetTypes: string[] = [
      'text',
      'title',
      'image',
      'container',
      'branding',
    ];
    if (!excludedWidgetTypes.includes(this.widget.widgetType)) {
      controls = Object.values(this.widgetDataForm.controls);
    }

    this.assignTheme();

    if (this.widgetDataForm.touched) {
      if (
        controls.some((control: AbstractControl) =>
          control.hasError('required')
        )
      ) {
        this.formType = 'data';
        if (this.widgetDataForm.value !== this.dataFormPreviousValue) {
          this.markControlsAsTouched(this.widgetDataForm);
        } else if (this.widgetStyleForm.value !== this.styleFormPreviousValue) {
          this.markControlsAsTouched(this.widgetStyleForm);
        }
        return;
      }
    }

    this.widget.widgetIcon = null;
    if (
      this.widgetStyleForm.get('widgetIcon')?.value &&
      typeof this.widgetStyleForm.get('widgetIcon')?.value !== 'string'
    ) {
      this.widget.widgetIcon = {
        ...this.widgetStyleForm.get('widgetIcon')?.value,
        position: this.widgetStyleForm.get('widgetIconPosition')?.value,
      };
    }

    if (
      this.widget.widgetType !== 'scorecard' &&
      this.widget.chartType !== 'funnel' &&
      this.widget.widgetType !== 'dynamictext' &&
      this.widget.widgetType !== 'gallery' &&
      this.widget.widgetType !== 'gauge' &&
      this.widget.widgetType !== 'current-goal'
    ) {
      if (
        (this.widget.dimensions?.length === 0 ||
          this.widget.metrics?.length === 0) &&
        this.widgetDataForm.value !== this.dataFormPreviousValue &&
        this.widgetDataForm.touched
      ) {
        this.markControlsAsTouched(this.newDimensionForm);
        this.markControlsAsTouched(this.newMeasureForm);
        return;
      }
    } else {
      if (
        this.widget.metrics?.length === 0 &&
        this.widgetDataForm.value !== this.dataFormPreviousValue &&
        this.widgetDataForm.touched &&
        this.widget.widgetType !== 'dynamictext' &&
        this.widget.widgetType !== 'gallery' &&
        this.widget.widgetType !== 'scorecard'
      ) {
        this.markControlsAsTouched(this.newMeasureForm);
        return;
      }
      if (
        this.widget.widgetType === 'scorecard' &&
        this.widget.dimensions?.length === 0 &&
        this.widget.metrics?.length === 0 &&
        this.widgetDataForm.value !== this.dataFormPreviousValue &&
        this.widgetDataForm.touched
      ) {
        this.markControlsAsTouched(this.newDimensionForm);
        this.markControlsAsTouched(this.newMeasureForm);
        return;
      }
    }

    if (this.widget.imageData && !this.widget.imageData.imageSrc) {
      this.widget.imageData.imageSrc =
        'https://triton-cdn-files.s3-us-west-2.amazonaws.com/sample-image.png';
      this.widget.isSample = true;
    }

    if (this.widget.widgetType === 'biggestchanges') {
      this.widget.placeValuesInside =
        this.widgetStyleForm.controls['placeValuesInside']?.value ||
        this.widget.placeValuesInside;
      this.widget.valueToShow =
        this.widgetStyleForm.controls['valueToShow']?.value ||
        this.widget.valueToShow;
      this.widget.sortCustom = this.widgetDataForm.controls['sortField']?.value;
      delete this.widget.sortField;
      delete this.widget.chartData?.chart.placeValuesInside;
    }

    if (this.widget.widgetType === 'current-goal') {
      this.widget.resultValueLabel =
        this.widgetStyleForm.controls['resultValueLabel']?.value || 'Results';
      this.widget.goalAmountLabel =
        this.widgetStyleForm.controls['goalAmountLabel']?.value || 'Goal';
    }

    if (this.widget.widgetType === 'gauge') {
      this.widget.showPercentage =
        this.widgetStyleForm.controls['showPercentage']?.value;
    }

    if (
      this.widget.widgetType === 'gauge' ||
      this.widget.widgetType === 'current-goal' ||
      this.widget.widgetType === 'timeline'
    ) {
      this.widget.goalAmount =
        this.widgetDataForm.controls['goalAmount']?.value;
    }

    if (this.widget.widgetType === 'table') {
      this.widget = {
        ...this.widget,
        totalPosition: this.widgetDataForm.controls['totalPosition'].value,
      };
    }

    if (hasWidgetSortBy(this.widget)) {
      this.widget.sortDirection =
        this.widgetDataForm.controls['sortDirection']?.value || 'asc';
    }

    if (this.previewed) {
      this.loading = false;
      this.bottomSheetRef.dismiss(this.widget);
    } else {
      this.previewData(true);
    }
  }

  assignTheme(): void {
    const styleControls = Object.entries(this.widgetStyleForm.controls);
    const validThemeKeys: string[] = getValidThemeKeys();

    if (!this.widget.theme || Array.isArray(this.widget.theme)) {
      this.widget.theme = {};
    }

    styleControls.forEach(([key, value]): void => {
      if (value.value && validThemeKeys.includes(key)) {
        if (key === 'fontSize') {
          this.widget.theme.fontSize = value.value.size;
        } else {
          this.widget.theme[key] = value.value;
        }
      } else if (key === 'stripedRows') {
        this.widget.theme.stripedRows = value.value;
      } else if (!value.value && validThemeKeys.includes(key)) {
        if (this.widget.theme[key]) {
          delete this.widget.theme[key];
        }
      }
    });

    if (typeof this.widget?.theme === 'object' && this.widget?.theme !== null) {
      Object.keys(this.widget?.theme)?.forEach((key: string): void => {
        const themeKeyValue = this.widget?.theme[key];
        const colorKeys = [
          'titleColor',
          'borderColor',
          'bodyColor',
          'tableHeaderBgColor',
          'tableHeaderBorderColor',
          'tableRowBorderColor',
          'tableRowBgColor',
        ];

        if (themeKeyValue == null) {
          delete this.widget.theme[key];
        }

        // remove empty values
        if (themeKeyValue !== null && typeof themeKeyValue === 'string') {
          if ((themeKeyValue as string).trim().length === 0) {
            delete this.widget.theme[key];
          }

          // remove invalid color values
          if (
            (themeKeyValue as string).trim().length !== 0 &&
            colorKeys.includes(key) &&
            this.colorService.getColorType(themeKeyValue) === null
          ) {
            delete this.widget.theme[key];
          }
        }
      });
    }
  }

  previewData(applyChanges?: boolean): void {
    if (this.widget.widgetType === 'dynamictext') {
      if (this.ckEditorCtrl?.value === 'advanced') {
        if (
          this.widget.dataset &&
          this.widget.dataset?.length > 0 &&
          this.widgetDataComponent?.conditionalMetric?.conditionalForm?.invalid
        ) {
          this.widgetDataComponent?.conditionalMetric?.conditionalForm.markAsPristine();
          return;
        }
      } else {
        delete this.widget?.dynamicConditionalData;
      }
    }

    if (
      this.widget.widgetType === 'gallery' &&
      this.datasetFilterCtrl.dirty &&
      (this.datasetFilterCtrl.errors?.required ||
        this.datasetFilterCtrl.errors?.invalidDataset)
    ) {
      return;
    }

    if (
      (this.widget?.chartType === 'stackedcolumn2d' ||
        this.widget?.chartType === 'stackedbar2d') &&
      this.widget?.dimensions &&
      this.widget?.dimensions?.length < 2
    ) {
      return;
    }

    if (this.widget.widgetType === 'biggestchanges') {
      this.widget.placeValuesInside =
        this.widgetStyleForm.controls['placeValuesInside']?.value ||
        this.widget.placeValuesInside;
      this.widget.valueToShow =
        this.widgetStyleForm.controls['valueToShow']?.value ||
        this.widget.valueToShow;
      this.widget.sortCustom = this.widgetDataForm.controls['sortField']?.value;
      delete this.widget.sortField;
      delete this.widget.chartData?.chart.placeValuesInside;
    }

    if (this.widget.widgetType === 'current-goal') {
      this.widget.resultValueLabel =
        this.widgetStyleForm.controls['resultValueLabel']?.value || 'Results';
      this.widget.goalAmountLabel =
        this.widgetStyleForm.controls['goalAmountLabel']?.value || 'Goal';
    }

    if (this.widget.widgetType === 'gauge') {
      this.widget.showPercentage =
        this.widgetStyleForm.controls['showPercentage']?.value;
    }

    if (
      this.widget.widgetType === 'gauge' ||
      this.widget.widgetType === 'current-goal' ||
      this.widget.widgetType === 'timeline'
    ) {
      this.widget.goalAmount =
        this.widgetDataForm.controls['goalAmount']?.value;
    }

    if (
      this.widgetStyleForm.get('widgetIcon')?.value &&
      typeof this.widgetStyleForm.get('widgetIcon')?.value !== 'string'
    ) {
      this.widget.widgetIcon = {
        ...this.widgetStyleForm.get('widgetIcon')?.value,
        position: this.widgetStyleForm.get('widgetIconPosition')?.value,
      };
    } else {
      const {widgetIcon, ...newWidget} = this.widget;
      this.widget = newWidget;
    }

    if (hasWidgetSortBy(this.widget)) {
      this.widget.sortDirection =
        this.widgetDataForm.controls['sortDirection']?.value || 'asc';
    }

    this.previewed = true;
    this.loadingPreview = true;
    this.disableSave = true;
    const payload: any = JSON.parse(JSON.stringify(this.data.page));
    this.assignTheme();
    if (
      this.widget.resultLimit !== this.widgetDataForm.get('resultLimit')?.value
    ) {
      this.widget.pageNumber = 1;
    }
    this.widget.resultLimit = this.widgetDataForm.get('resultLimit')?.value;

    payload.widgets = [this.widget];

    if (this.widget.widgetType === 'branding') {
      payload.widgets[0].brandingStyle =
        this.widgetStyleForm.get('brandingStyle')?.value;
      payload.widgets[0].excludedPages =
        this.widgetStyleForm.get('excludedPages')?.value;
    }

    this.updateFieldsOnChange();

    this.subs.add(
      this.widgetService
        .previewData(this.data.reportId, this.data.page.id, payload)
        .subscribe({
          next: (res): void => {
            if (this.widget.isDuplicate) {
              this.widget = res.widgets[0];
              this.widget.isDuplicate = true;
            } else {
              this.widget = res.widgets[0];
            }

            if (res) {
              if (this.widget.widgetType === 'dynamictext') {
                if (this.widget.textData && this.widget.newPreviewText) {
                  this.onChangesDynamicTextValues(
                    this.widget.dynamicTextEdit || 'basic',
                    this.widget.id
                  );
                }
                this.widgetDataComponent?.conditionalMetric?.conditionalForm.markAsPristine();
              }
            }
          },
          error: (err: CustomError) => {
            this.loadingPreview = false;
            this.disableSave = false;
            this.notificationService.error(err?.message, 5000);
          },
          complete: () => {
            this.sortChange = false;
            this.showPreviewOverlay = false;
            this.widgetDataForm.markAsPristine();
            this.widgetStyleForm.markAsPristine();
            this.filterApplied = false;
            if (this.widgetDataForm.get('resultLimit')) {
              this.widget.resultLimit =
                this.widgetDataForm.get('resultLimit')?.value;
            }

            if (this.widget.widgetType === 'gallery') {
              this.datasetFilterCtrl.markAsPristine();
            }

            this.loadingPreview = false;
            this.disableSave = false;

            if (applyChanges) {
              this.bottomSheetRef.dismiss(this.widget);
            }
          },
        })
    );
  }

  onChangesDynamicTextValues(type: string, widgetId: string): void {
    if (type === 'basic' && this.widget.textData) {
      this.widgetService.setChangeDynamicTextValue({
        isInitialValue: false,
        from: 'editor',
        widgetId,
        newText: {
          innerHtml:
            this.widget.newPreviewText?.innerHtml ||
            this.widget.textData?.innerHtml,
          originalHtml: this.widget.textData?.originalHtml,
        },
        widget: this.widget,
      });
    }
    if (type === 'advanced' && this.widget.newPreviewText) {
      this.widgetService.setChangeDynamicTextValue({
        isInitialValue: false,
        from: 'editor',
        widgetId,
        newText: {
          innerHtml: this.widget.newPreviewText.innerHtml,
          originalHtml: this.widget.newPreviewText?.originalHtml,
        },
        widget: this.widget,
      });
    }
  }

  onWidgetSort(widget: Widget): void {
    if (widget) {
      this.widget.sortDirection = widget.sortDirection;
      this.widget.sortField = widget.sortField;
    }
  }

  updateFieldsOnChange(): void {
    const scopeChanged = this.scopeService.onFieldSelectionChanged(
      this.widget,
      this.fields,
      this.pageFilters
    )[1];
    this.widget.scope = scopeChanged;
    this.setCurrentScopeName();
  }
}
