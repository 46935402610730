import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { DatePickerMenuComponent } from '../../../components/date-picker/date-picker-menu/date-picker-menu.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-date-picker-dialog',
    templateUrl: './date-picker-dialog.component.html',
    styleUrls: ['./date-picker-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        DatePickerMenuComponent,
        TranslateModule,
    ],
})
export class DatePickerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onDateSelection(event): void {
    this.dialogRef.close(event);
  }
}
