<div class="overlay-container position-relative">
  <mat-dialog-content class="">
    <header>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <h5 class="fw-bold mb-0">
            {{
              !isEdit
                ? ("reporting.create_report.title_create" | translate)
                : ("reporting.create_report.title_general" | translate)
            }}
          </h5>
          <p class="mb-1">
            {{
              !isEdit
                ? ("reporting.create_report.create_help_description"
                  | translate)
                : ("reporting.create_report.general_help_description"
                  | translate)
            }}
          </p>
        </div>
        <button
          (click)="closeDialog()"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      </div>
      <p class="mt-3 mb-1">
        <small class="d-flex wsm-text-grayed">
          {{ "common.fields_required" | translate }}
        </small>
      </p>
    </header>
    <div class="row mx-0">
      <div
        class="ps-0"
        [ngClass]="{
          'col-5 col-md-5 col-sm-12': !isIndividual,
          'col-12': isIndividual,
        }"
      >
        <form [formGroup]="reportForm" action="">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label for="report-name"
                >{{ "reporting.create_report.form.report_name" | translate
                }}<span class="required-mark">*</span></label
              >
              <input
                formControlName="name"
                type="text"
                class="wsm-input w-100"
                id="report-name"
                placeholder="{{
                  'reporting.create_report.form.report_name_placeholder'
                    | translate
                }}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 mb-3">
              <label for="orientation"
                >{{
                  "reporting.create_report.form.layout" | translate
                }}&nbsp;<span class="required-mark">*</span></label
              >
              <mat-radio-group formControlName="orientation" id="orientation">
                <mat-radio-button value="horizontal" class="me-3">
                  <i class="fak fa-landscape-report"></i>&nbsp;
                  {{
                    "reporting.create_report.form.layout_landscape" | translate
                  }}
                </mat-radio-button>
                <mat-radio-button value="vertical">
                  <i class="fak fa-portrait-report portrait-icon"></i
                  >&nbsp;&nbsp;
                  {{
                    "reporting.create_report.form.layout_portrait" | translate
                  }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label for="fontSize"
                >{{
                  "reporting.create_report.form.font_size" | translate
                }}&nbsp;<span class="required-mark">*</span></label
              >
              <mat-select
                disableOptionCentering
                formControlName="fontSize"
                class="wsm-input"
                id="fontSize"
                [panelClass]="'wsm-custom-select'"
              >
                @for (fontSize of fontSizes; track i; let i = $index) {
                  <mat-option [value]="fontSize">
                    {{ fontSize?.label }}
                  </mat-option>
                }
              </mat-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 drd-theme">
              <div class="d-flex align-items-baseline justify-content-between">
                <label class="me-2" for="">{{
                  "reporting.create_report.form.select_theme" | translate
                }}</label>
              </div>
              @if (themeSelect?.value) {
                <a
                  (click)="onShowThemeDialog()"
                  style="border-radius: 10px"
                  class="colors-container current-theme-card wsm-bg-secondary d-flex flex-column rounded-md mb-2 p-3 text-decoration-none gap-2"
                >
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="wsm-text-white">{{
                      themeSelect.value.name
                    }}</span>
                    <i class="fa-regular fa-fill-drip wsm-text-white"></i>
                  </span>
                  <span class="d-flex gap-2">
                    @for (
                      color of themeSelect?.value?.colors;
                      track i;
                      let i = $index
                    ) {
                      <span
                        class="nr-circle-input rounded-circle cursor-default"
                        [style]="'background-color: ' + color"
                      ></span>
                    }
                  </span>
                </a>
              }
              @if (themes && themes.length > 0) {
                <div class="wsm-input-group-prepend w-100">
                  @if (pickANewTheme) {
                    <label for="">{{
                      "reporting.create_report.form.pick_a_new_theme"
                        | translate
                    }}</label>
                  }
                  <input
                    type="text"
                    [formControl]="themeControl"
                    class="wsm-input h-100 w-100"
                    [matAutocomplete]="themeAutoComplete"
                    placeholder="{{
                      'reporting.create_report.form.select_theme_placeholder'
                        | translate
                    }}"
                  />
                  <mat-autocomplete
                    class="mat-menu-gray"
                    autoActiveFirstOption
                    #themeAutoComplete="matAutocomplete"
                    (optionSelected)="onChangeThemeFromList($event)"
                  >
                    @for (item of filteredThemes | async; track item._id) {
                      <mat-option style="height: 4rem" [value]="item">
                        {{ item.name }}
                        <div class="colors-container d-flex gap-2 mt-1 mb-1">
                          @for (color of item.colors; track i; let i = $index) {
                            <div
                              class="nr-circle-input rounded-circle"
                              [style]="'background-color: ' + color"
                            ></div>
                          }
                        </div>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
      @if (!isIndividual) {
        <div class="col-lg-7 col-md-7 col-sm-12">
          <app-company-selection
            [companyDataType]="companyDataType"
            [report]="report"
            (specificLoadingState)="setLoading($event)"
            (sendConditions)="getConditions($event)"
          ></app-company-selection>
        </div>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="d-flex justify-content-end">
    @if (!isEdit) {
      <button
        id="startReportBtn"
        [disabled]="
          reportForm.invalid ||
          themeSelect.invalid ||
          conditionsInvalid ||
          isLoading
        "
        (click)="handleSubmit()"
        class="wsm-btn wsm-btn-primary"
      >
        @if (!isLoading) {
          <span (click)="$event.preventDefault()">{{
            "reporting.create_report.form.accept_button" | translate
          }}</span>
        }
        @if (isLoading) {
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    }
    @if (isEdit) {
      <button
        [disabled]="
          reportForm.invalid ||
          themeSelect.invalid ||
          conditionsInvalid ||
          isLoading
        "
        (click)="handleSubmit()"
        class="wsm-btn wsm-btn-primary"
      >
        @if (!isLoading) {
          <span>
            <span>{{
              "reporting.create_report.form.save_settings" | translate
            }}</span>
          </span>
        }
        @if (isLoading) {
          <div
            class="spinner-border spinner-border-sm text-light"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    }
  </mat-dialog-actions>
  @if (generalLoading) {
    <div class="loader-container">
      <div class="spinner-ripple">
        <div class="circle">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  }
</div>
