@if (step !== 3) {
  <header class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="h2 m-0">{{ title | translate }}</h1>
      <button
        [mat-dialog-close]="false"
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
      >
        <i class="fa-regular fa-xmark"></i>
      </button>
    </div>
    <p>{{ description | translate }}</p>
  </header>
}

<mat-dialog-content class="client-select-container">
  @if (step === 1) {
    <div class="d-flex flex-column align-items-center">
      <label>
        {{ "shared.dialogs.client_limit.number_of_clients" | translate }}
      </label>
      <div class="d-flex align-items-center">
        <input
          #limitInput
          type="number"
          class="wsm-input wsm-input-sm"
          autocomplete="off"
          min="1"
          [formControl]="extraClients"
          (keydown)="
            $event.code === 'Period' || $event.code === 'NumpadDecimal'
              ? $event.preventDefault()
              : null
          "
        />
        <div class="d-flex flex-column align-items-center ms-3">
          <i
            class="fa-regular fa-chevron-up mb-3"
            [ngClass]="extraClients.value === 999 ? 'disabled' : ''"
            (click)="addExtraClients('up')"
            role="button"
          ></i>
          <i
            class="fa-regular fa-chevron-down"
            [ngClass]="extraClients.value === 1 ? 'disabled' : ''"
            (click)="addExtraClients('down')"
            role="button"
          ></i>
        </div>
      </div>
    </div>
  }

  @if (step === 2) {
    <div class="d-flex flex-column">
      <label class="wsm-text-grayed mb-4">
        {{ "shared.dialogs.client_limit.select_card" | translate }}
      </label>
      <mat-radio-group class="d-flex flex-column ms-5" [formControl]="cardType">
        <mat-radio-button value="sameCard" class="ms-3">
          <span class="wsm-text-grayed">
            <i class="fa-regular fa-credit-card ms-3 me-1"></i>
            {{ "shared.dialogs.client_limit.card_ending" | translate }}
            {{ secondaryOrder?.paymentMethod?.lastFourNumber }}
          </span>
        </mat-radio-button>
        <hr class="m-0 mb-3 mt-2 divider" />
        <mat-radio-button value="otherCard" class="ms-3">
          <span class="wsm-text-grayed">
            <i class="fa-regular fa-plus ms-3 me-1"></i>
            {{ "shared.dialogs.client_limit.different_card" | translate }}
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  }

  @if (step === 3) {
    <ng-lottie height="300px" [options]="options"> </ng-lottie>
    <div class="text-center">
      <h1>
        {{ "shared.dialogs.client_limit.expanding_limit" | translate }}
      </h1>
      <p class="wsm-text-grayed">
        {{ "shared.dialogs.client_limit.expanding_limit_desc" | translate }}
      </p>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions
  align="end"
  [ngClass]="step === 2 ? 'justify-content-between' : ''"
>
  @if (step === 2) {
    <span>
      {{ "shared.dialogs.client_limit.total_amount" | translate }}: ${{
      secondaryOrder?.totalAmount | number: "1.2-2"
    </span>
  }
  @if (step === 1) {
    <button
      class="wsm-btn wsm-btn-primary submit-btn ms-3"
      type="submit"
      (click)="onContinue()"
    >
      @if (!isLoading) {
        <span>
          {{ "shared.dialogs.client_limit.continue_btn" | translate }}
        </span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  }
  @if (step === 2) {
    <button
      class="wsm-btn wsm-btn-primary submit-btn ms-3"
      type="submit"
      (click)="onPay()"
    >
      @if (!isLoading) {
        <span>
          {{ "shared.dialogs.client_limit.proceed_to_payment" | translate }}
        </span>
      }
      @if (isLoading) {
        <div class="spinner-border spinner-border-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      }
    </button>
  }
</mat-dialog-actions>
