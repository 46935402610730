<section
  class="container-fluid d-flex justify-content-center align-items-center vh-100"
>
  <div class="row">
    <ng-lottie height="300px" [options]="options"> </ng-lottie>
    <h1 class="mb-2 text-nowrap text-center">Report Not Found</h1>
    <div class="loading-message text-center">
      <p class="mb-2">
        It looks like Clicky couldn't find the report you have requested.
      </p>
    </div>
  </div>
</section>
