@if (reportTemplate) {
  <section class="d-flex template-preview-wrapper">
    <div class="d-flex flex-column w-100 gap-4">
      <div class="content ps-2">
        <div class="d-flex gap-5">
          <div class="d-flex flex-column gap-2">
            <span class="fs-6">{{
              "shared.components.template_preview.language" | translate
            }}</span>
            <span class="wsm-text-grayed">{{
              reportTemplate.language! | formatLanguage
            }}</span>
          </div>
          <div class="d-flex flex-column gap-2">
            <span class="fs-6">{{
              "shared.components.template_preview.layout" | translate
            }}</span>
            <span class="wsm-text-grayed">{{
              "common.orientation." + reportTemplate.orientation.toLowerCase()
                | translate
            }}</span>
          </div>
          <div class="d-flex flex-column">
            <p class="fs-6">
              {{ "shared.components.template_preview.connections" | translate }}
            </p>
            <div class="d-flex flex-row flex-wrap gap-2 mt-2">
              @if (
                reportTemplate?.connections &&
                reportTemplate?.connections?.length! > 0
              ) {
                <div class="d-flex flex-row flex-wrap gap-2">
                  @for (
                    connection of reportTemplate?.connections!;
                    track connection
                  ) {
                    <img [src]="connection.logos?.cardLogo" width="20px" />
                  }
                </div>
              } @else {
                <span class="wsm-text-grayed">{{
                  "shared.components.template_preview.no_connections"
                    | translate
                }}</span>
              }
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex w-100">
          <div class="d-flex flex-column">
            <p class="fs-6">
              {{ "shared.components.template_preview.labels" | translate }}
            </p>
            <div class="d-flex flex-row flex-wrap gap-2 mt-2">
              @if (
                reportTemplate?.goalType &&
                reportTemplate?.goalType?.length! > 0
              ) {
                @for (goal of reportTemplate?.goalType; track goal) {
                  <small class="wsm-tag info template-tag me-2">{{
                    goal
                  }}</small>
                }
              }
            </div>
          </div>
        </div>
      </div>
      <div
        [style.--pdf-viewer-container-height]="pdfViewerContainer"
        class="preview-carousel"
      >
        @if (!loadingPDFStatus && !!pdfContent && !pdfLoadingHasError) {
          <div class="page-preview-container mt-2">
            <div class="pdf-wrapper">
              <div class="controls-wrapper me-1 align-items-center pe-2">
                <div class="d-flex gap-3">
                  <button
                    [disabled]="currentPage === 1"
                    (click)="changePage(-1)"
                    class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon preview-control"
                  >
                    <i class="fa-regular fa-arrow-left"></i>
                  </button>
                  <button
                    [disabled]="currentPage === totalPages"
                    (click)="changePage(+1)"
                    class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon preview-control"
                  >
                    <i class="fa-regular fa-arrow-right"></i>
                  </button>
                </div>
                <div>
                  <span class="text-white"
                    >{{ pagePreviewText.page }}&nbsp;{{ currentPage }}&nbsp;{{
                      pagePreviewText.of
                    }}&nbsp;{{ totalPages }}</span
                  >
                </div>
              </div>
              <pdf-viewer
                [src]="pdfContent"
                [render-text]="true"
                [zoom-scale]="'page-width'"
                [zoom]="1"
                [fit-to-page]="true"
                [original-size]="false"
                [autoresize]="true"
                [show-all]="false"
                [page]="currentPage"
                (after-load-complete)="getPDFInfo($event)"
                (text-layer-rendered)="textLayerRendered($event)"
                style="display: block; width: 100%; height: 100%"
              ></pdf-viewer>
            </div>
          </div>
        }
        @if (loadingPDFStatus && !pdfContent && !pdfLoadingHasError) {
          <div class="overlay-container mt-2">
            @if (true) {
              <div class="loader-container flex-column wsm-bg-secondary">
                <div class="spinner-ripple">
                  <div class="circle">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <p class="wsm-text-white">
                  {{
                    "shared.components.template_preview.loading_preview"
                      | translate
                  }}
                </p>
              </div>
            }
          </div>
        }
        @if (!loadingPDFStatus && pdfLoadingHasError) {
          <div class="error-preview-container">
            <i
              class="fa-kit fa-regular-file-chart-pie-slash wsm-text-grayed fa-3x"
            ></i>
            <p class="wsm-text-grayed text-center">
              {{
                "shared.components.template_preview.preview_could_not_be_generated"
                  | translate
              }}
            </p>
          </div>
        }
      </div>
    </div>
  </section>
}
