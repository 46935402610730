<header>
  <div class="d-flex justify-content-end">
    <button
      (click)="onCloseDialog()"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<div class="d-flex flex-column align-items-center justify-content-center h-100">
  <div class="d-flex align-items-center justify-content-center w-100">
    <section class="container-fluid">
      <ng-lottie height="300px" [options]="options"> </ng-lottie>

      <div class="text-center">
        <h6>{{ "common.mobile_alert.title" | translate }}</h6>
        <p>{{ "common.mobile_alert.description" | translate }}</p>
      </div>
    </section>
  </div>
</div>
