import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {ReplaySubject, Subject, takeUntil} from 'rxjs';
import {DatePipe, AsyncPipe, formatDate} from '@angular/common';

import {DynamicDate, ReportDateRange, ReportPage, Widget} from '../../models';
import {HelperService} from '../../helpers';
import {MatDialogClose} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatOption} from '@angular/material/core';
import {
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-dynamic-date-range',
  templateUrl: './dynamic-date-range.component.html',
  styleUrls: ['./dynamic-date-range.component.scss'],
  standalone: true,
  imports: [
    MatDialogClose,
    FormsModule,
    ReactiveFormsModule,
    MatSelect,
    NgxIntlTelInputModule,
    MatOption,
    MatAutocompleteTrigger,
    MatAutocomplete,
    AsyncPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class DynamicDateRangeComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() page: ReportPage;
  @Input() origin: string;
  @Output() dateChange = new EventEmitter<string>();

  // Properties
  currentDateRange: ReportDateRange;
  dateRangeForm: UntypedFormGroup;
  today: number = Date.now();
  dynamicDates: Array<DynamicDate> = [];
  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  // Filters
  protected onDestroySubject = new Subject<void>();
  dynamicDateFilterCtrl: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.required
  );
  filteredDynamicDates: ReplaySubject<DynamicDate[]> = new ReplaySubject<
    DynamicDate[]
  >(1);

  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.dynamicDates = this.helperService.getDynamicDates();
    this.currentDateRange = this.widget.dateRange
      ? (this.widget?.dateRange as ReportDateRange)
      : (this.page?.dateRange as ReportDateRange);
    this.dateRangeForm = new UntypedFormGroup({
      dateType: new UntypedFormControl('', [Validators.required]),
      dateShow: new UntypedFormControl('', Validators.required),
      dateFormat: new UntypedFormControl('', [Validators.required]),
    });

    this.filteredDynamicDates.next(this.dynamicDates.slice());
    this.dynamicDateFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe((): void => {
        this.filterData();
      });
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  private filterData(): void {
    const search = this.dynamicDateFilterCtrl.value;

    if (!search) {
      this.dateRangeForm.get('dateFormat')?.setValue('');
      this.filteredDynamicDates.next(this.dynamicDates.slice());
    } else {
      if (typeof search === 'string') {
        const filterValue = search?.toLowerCase();
        this.filteredDynamicDates.next(
          this.dynamicDates.filter((dynamicDate: DynamicDate) =>
            dynamicDate.name.toLowerCase().includes(filterValue)
          )
        );
      } else {
        this.dateRangeForm
          .get('dateFormat')
          ?.setValue(this.dynamicDateFilterCtrl.value?.value);
      }
    }
  }

  displayWith(value: DynamicDate): string {
    let formatName = '';
    this.dynamicDates.forEach((dynamicDate: DynamicDate) => {
      if (dynamicDate.value === value?.value) {
        formatName = `${dynamicDate.name} (${formatDate(
          this.today,
          dynamicDate?.pipeFormat,
          this.userLocale
        )})`;
      }
    });
    return formatName;
  }

  onSave(): void {
    if (this.dateRangeForm.invalid) {
      return;
    }

    // Emmit Date Result
    this.dateChange.emit(
      `${this.dateRangeForm.get('dateType')?.value}_${
        this.dateRangeForm.get('dateFormat')?.value
      }_${this.dateRangeForm.get('dateShow')?.value}`
    );
  }
}
