import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ReportTemplate} from '../../models';
import {NotificationService, ReportService} from 'src/app/core/services';
import { PDFDocumentProxy, PdfViewerModule } from 'ng2-pdf-viewer';
import {Subscription, interval, switchMap} from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormatLanguagePipe } from '../../pipes/language-format.pipe';

@Component({
    selector: 'app-template-preview',
    templateUrl: './template-preview.component.html',
    styleUrls: ['./template-preview.component.scss'],
    standalone: true,
    imports: [
        PdfViewerModule,
        TranslateModule,
        FormatLanguagePipe,
    ],
})
export class TemplatePreviewComponent implements OnInit, OnDestroy {
  @Input() reportTemplateId: string;
  @Output() loadingTemplateStatus = new EventEmitter<boolean>();

  public checkPDFStatus$: Subscription | null;
  public loadingPDFStatus: boolean;
  public pdfLoadingHasError: boolean;

  public reportTemplate: ReportTemplate;
  public templateId: string;
  public loadingTemplate: boolean;
  public pdfContent: Uint8Array;
  public currentPage = 1;
  public totalPages: number;
  public pdfViewerContainer: string;

  public pagePreviewText: {page: string; of: string};

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.reportTemplateId) {
      this.loadingPDFStatus = true;
      this.getTemplate();
    }
    this.pagePreviewText = {
      page: this.translate.instant('shared.components.template_preview.page'),
      of: this.translate.instant('shared.components.template_preview.of'),
    };
  }

  getTemplate(): void {
    this.loadingTemplate = true;
    this.loadingTemplateStatus.emit(true);
    this.reportService.getTemplate(this.reportTemplateId).subscribe({
      next: (res) => {
        if (res) {
          this.reportTemplate = res;
          this.templateId = this.reportTemplate.id;
          this.loadingTemplate = false;
          this.loadingTemplateStatus.emit(false);
          if (this.reportTemplate.preview?.status === 'DONE') {
            this.getPDF(this.reportTemplate.preview?.pdf);
            this.loadingPDFStatus = false;
            this.pdfLoadingHasError = false;
          }
          if (this.reportTemplate.preview?.status !== 'DONE') {
            this.onCheckPDFStatus();
          }
        }
      },
      error: (err) => {
        this.loadingTemplate = false;
        this.notificationService.error(err?.message ?? '', 5000);
        this.loadingTemplateStatus.emit(false);
      },
    });
  }

  onCheckPDFStatus(): void {
    if (!this.checkPDFStatus$) {
      this.checkPDFStatus$ = interval(5000)
        .pipe(
          switchMap(() =>
            this.reportService.getTemplatePDFStatus(this.templateId)
          )
        )
        .subscribe({
          next: (res) => {
            if (res && res.status === 'DONE') {
              this.getPDF(res.pdf);
              this.checkPDFStatus$?.unsubscribe();
              this.checkPDFStatus$ = null;
              this.loadingPDFStatus = false;
            }
            if (res && res.status === 'ERROR') {
              this.loadingPDFStatus = false;
              this.pdfLoadingHasError = true;
              this.checkPDFStatus$?.unsubscribe();
            }
          },
          error: (err) => {
            this.notificationService.error(err?.message ?? '', 5000);
          },
        });
    }
  }

  getPDF(base64Data: string): void {
    fetch(`data:application/pdf,${base64Data}`)
      .then(() => {
        const byteArray = new Uint8Array(
          atob(base64Data)
            .split('')
            .map((char) => char.charCodeAt(0))
        );
        return new Blob([byteArray], {type: 'application/pdf'});
      })
      .then((res) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(res);
        reader.onloadend = (): void => {
          this.pdfContent = new Uint8Array(reader.result as ArrayBuffer);
        };
      });
  }

  getPDFInfo(pdf: PDFDocumentProxy): void {
    this.totalPages = pdf.numPages;
  }

  changePage(amount: number): void {
    this.currentPage += amount;
  }

  textLayerRendered(event: any): void {
    this.pdfViewerContainer = `${event.source.viewport.height}px`;
  }
  ngOnDestroy(): void {
    this.checkPDFStatus$?.unsubscribe();
  }
}
