import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import {IClientForm} from '../../models';
import { CreateClientComponent } from '../../components/create-client/create-client.component';
import { TranslateModule } from '@ngx-translate/core';

declare global {
  interface Window {
    amplitude: any;
  }
}

const amplitude = window.amplitude;

@Component({
    selector: 'app-create-company-dialog',
    templateUrl: './create-company-dialog.component.html',
    styleUrls: ['./create-company-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogClose,
        MatDialogContent,
        CreateClientComponent,
        TranslateModule,
    ],
})
export class CreateCompanyDialogComponent implements OnInit {
  // Properties
  private clientFormCache: IClientForm;

  constructor(
    public dialogRef: MatDialogRef<CreateCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe((): void => this.safeClose());
  }

  public safeClose(): void {
    if (this.clientFormCache) {
      this.dialogRef.close(this.clientFormCache);
      return;
    }
  }

  public onCreateClient(event: string): void {
    this.dialogRef.close(event);
  }

  public clientFormChanged(event: IClientForm): void {
    this.clientFormCache = event;
  }
}
