import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {ReportPage} from 'src/app/shared/models';
import {CdkDragDrop, CdkDropList, CdkDrag} from '@angular/cdk/drag-drop';
import {NotificationService, ReportService} from 'src/app/core/services';
import {Subscription} from 'rxjs';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {HelperService} from '../../helpers';
import {NgClass} from '@angular/common';
import {NavigatorCardComponent} from '../navigator-card/navigator-card.component';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';

@Component({
  selector: 'app-report-template-navigator',
  templateUrl: './report-template-navigator.component.html',
  styleUrls: ['./report-template-navigator.component.scss'],
  standalone: true,
  imports: [
    CdkDropList,
    CdkDrag,
    NgClass,
    NavigatorCardComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class ReportTemplateNavigatorComponent implements OnInit {
  // Inputs / Outputs
  @Input() reportPages: ReportPage[];
  @Input() isEditMode: boolean;
  @Input() from: 'template' | 'report' | 'sharing' | 'other';
  @Input() reportId: string;
  @Input() selectedPage: ReportPage;
  @Input() originalPageState: ReportPage;
  @Output() pagesReordered: EventEmitter<CdkDragDrop<string[]>> =
    new EventEmitter<CdkDragDrop<string[]>>();
  @Output() pageSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() openPageSettings: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() pageDuplicated: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveAsTemplate: EventEmitter<string> = new EventEmitter<string>();
  @Output() pageDeleted: EventEmitter<string> = new EventEmitter<string>();

  //Children
  @ViewChild('pagesContainer') pagesContainer;

  // State Variables
  isDragging: boolean;
  menuOpen: boolean;
  hasChanges: boolean;
  isExternalUser: boolean;
  isReportLoading: boolean;

  //Properties
  subs: Subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    if (this.from === 'report') {
      this.subs.add(
        this.reportService.hasChanges$.subscribe(
          (res: {hasChanges: boolean; skipPageState?: boolean}) => {
            this.hasChanges = res.hasChanges;
          }
        )
      );

      this.subs.add(
        this.reportService.isReportLoading$.subscribe((res: boolean) => {
          this.isReportLoading = res;
        })
      );
    }
    this.selectPage(this.selectedPage);
    this.isExternalUser = JSON.parse(
      localStorage.getItem('currentUser')!
    )?.roles?.includes('ROLE_EXTERNAL');
  }

  drop(event: CdkDragDrop<string[]>): void {
    this.isDragging = false;
    this.pagesContainer.nativeElement.classList.remove('inherit-cursors');
    this.pagesContainer.nativeElement.style.cursor = 'unset';
    this.pagesReordered.emit(event);
  }

  selectPage(page: ReportPage, index?: number): void {
    if (this.isReportLoading) {
      return;
    }
    if (this.hasChanges) {
      this.reportService.warnOfUnsavedChanges(
        this.selectPage.bind(this, page),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.reportPages,
          this.selectedPage,
          this.originalPageState
        )
      );
    } else {
      if (page) {
        page.isActive = true;
        this.reportPages.forEach((p: ReportPage) => {
          if (p.id === page.id) {
            return;
          }
          p.isActive = false;
        });
        let tabElement;
        setTimeout(() => {
          tabElement = document.getElementById(
            'card' + (index ? index : page.order)
          );
          tabElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
          });
        });
        this.pageSelected.emit(index ? index : page.order);
      }
    }
  }

  onPageDrag(event): void {
    this.isDragging = true;
    this.pagesContainer.nativeElement.classList.add('inherit-cursors');
    this.pagesContainer.nativeElement.style.cursor = 'grabbing';
  }

  onEditPageSettings(): void {
    this.openPageSettings.emit(true);
  }

  onDuplicatePage(page: ReportPage): void {
    if (this.hasChanges) {
      this.reportService.warnOfUnsavedChanges(
        this.onDuplicatePage.bind(this, page),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.reportPages,
          this.selectedPage,
          this.originalPageState
        )
      );
    } else {
      this.pageDuplicated.emit(page.id);
    }
  }

  onSaveTemplate(): void {
    if (this.hasChanges) {
      this.reportService.warnOfUnsavedChanges(
        this.onSaveTemplate.bind(this),
        undefined,
        this.reportService.returnToOriginalPageState.bind(
          this,
          this.reportPages,
          this.selectedPage,
          this.originalPageState
        )
      );
    } else {
      this.saveAsTemplate.emit('page');
    }
  }

  onDeletePage(page: ReportPage): void {
    this.pageDeleted.emit(page.id);
  }

  onHidePage(page: ReportPage): void {
    const hiddenPages = this.reportPages.filter((page) => !page.isVisible);

    if (
      hiddenPages.length !== this.reportPages.length - 1 ||
      (hiddenPages.length === this.reportPages.length - 1 && !page.isVisible)
    ) {
      page.isVisible = !page.isVisible;
      if (this.isExternalUser) {
        return;
      }
      this.updateReportPageVisibility(page);
    } else {
      this.translate
        .get('notifications.report_page_not_visible')
        .subscribe((res: string) => {
          this.notificationService.warning(res, 5000);
        });
    }
  }

  updateReportPage(page: ReportPage): void {
    this.reportService.setIsLoadingValue = true;
    this.reportService.updatePage(this.reportId, page.id, page).subscribe({
      error: (err) => {
        this.reportService.setIsLoadingValue = false;
        this.notificationService.error(err?.error?.error_description, 5000);
      },
      complete: () => {
        this.reportService.setIsLoadingValue = false;
      },
    });
  }

  updateReportPageVisibility(page: ReportPage): void {
    this.reportService.setIsLoadingValue = true;
    this.reportService
      .updatePageVisibility(this.reportId, page.id, Boolean(page.isVisible))
      .subscribe({
        error: (err) => {
          this.reportService.setIsLoadingValue = false;
          this.notificationService.error(err?.error?.error_description, 5000);
        },
        complete: () => {
          this.reportService.setIsLoadingValue = false;
        },
      });
  }

  returnToOriginalPageState(): void {
    this.reportPages[this.selectedPage.order] = this.helperService.copyObject(
      this.originalPageState
    ) as ReportPage;
  }
}
