<div
  class="report-card-container h-100 p-3"
  [routerLink]="['/reports/' + report.id + '/edit']"
  fragment="page-1"
  (click)="$event.stopPropagation(); $event.preventDefault()"
>
  @if (favoriteControl) {
    <div
      class="d-flex justify-content-between align-items-center position-relative w-100 mb-3"
    >
      <div
        [ngClass]="{
          'align-items-end': report.orientation === 'horizontal',
          'align-items-center': report.orientation !== 'horizontal',
        }"
        class="d-flex gap-2"
      >
        <button
          (click)="$event.stopPropagation(); onFavoriteReport(report)"
          class="wsm-btn wsm-btn-outline-icon wsm-btn-tiny-icon star-icon p-0"
        >
          <i
            [ngClass]="{
              'fa-light': !report.favorite,
              'fa-solid star-fill-color': report.favorite,
            }"
            class="fa-star"
          ></i>
        </button>
        <span
          [ngClass]="{
            'landscape': report.orientation === 'horizontal',
            'portrait': report.orientation !== 'horizontal',
          }"
          class="layout-icon"
        >
          @if (report.orientation === "horizontal") {
            <i class="wsm-text-grayed fak fa-landscape-report"></i>
          }
          @if (report.orientation !== "horizontal") {
            <i class="wsm-text-grayed fak fa-portrait-report"></i>
          }
        </span>
      </div>
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon options-btn normal-height ms-1"
        #pageMenu="matMenuTrigger"
        [matMenuTriggerFor]="reportCardMenu"
        (click)="$event.stopPropagation()"
      >
        <i class="fa-regular fa-ellipsis-vertical"></i>
      </button>
    </div>
  }

  <div
    class="d-flex justify-content-between align-items-center mb-2 position-relative"
  >
    @if (report.name?.length! > 21) {
      <h6
        class="h5 mb-0 pe-3 report-card-name"
        [satPopoverAnchor]="popName"
        (mouseenter)="popName.open()"
        (mouseleave)="popName.close()"
      >
        {{ report.name | truncate: 21 }}
      </h6>
      <sat-popover
        #popName
        horizontalAlign="center"
        verticalAlign="below"
        panelClass="custom-pop-name"
      >
        <div class="name-wrapper">
          <div class="popover-name-arrow"></div>
          <span>{{ report?.name }}</span>
        </div>
      </sat-popover>
    } @else {
      <h6 class="h5 mb-0 pe-3 report-card-name">{{ report.name }}</h6>
    }

    @if (!favoriteControl) {
      <button
        class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon options-btn position-absolute ms-1"
        #pageMenu="matMenuTrigger"
        [matMenuTriggerFor]="reportCardMenu"
        (click)="$event.stopPropagation()"
      >
        <i class="fa-regular fa-ellipsis-vertical"></i>
      </button>
    }
    <mat-menu #reportCardMenu="matMenu" class="mat-menu-gray">
      @if (!isMobile) {
        <button
          [routerLink]="['/reports/' + report.id + '/edit']"
          mat-menu-item
        >
          {{ "company.report_cards.edit_label" | translate }}
        </button>
      }
      <button (click)="openReportHistory(report)" mat-menu-item>
        {{ "company.report_cards.report_history_label" | translate }}
      </button>
      @if (!isMobile) {
        <button (click)="onDuplicate(report)" mat-menu-item>
          {{ "company.report_cards.duplicate_label" | translate }}
        </button>
      }
      <button (click)="onDelete(report)" mat-menu-item>
        {{ "company.report_cards.delete_label" | translate }}
      </button>
    </mat-menu>
  </div>

  <!-- Clients Row -->
  @if (favoriteControl) {
    <div class="d-flex flex-column mb-3">
      <span class="wsm-text-grayed mb-2">
        {{ "reporting.reporting_list.companies_column" | translate }}
      </span>
      @if (report.companiesLabel?.length! > 23) {
        <span>{{ report.companiesLabel! | truncate: 23 }}</span>
        <button
          class="show-more-btn wsm-btn wsm-btn-outline w-100 mt-3"
          (click)="$event.stopPropagation(); onShowMoreClients(report)"
        >
          {{ "reporting.reporting_list.show_more_clients" | translate }}
        </button>
      } @else {
        <span>{{ report.companiesLabel }}</span>
      }
    </div>
  }

  <!-- Last Modified by row -->
  <div class="d-flex flex-column mb-3">
    <span class="wsm-text-grayed mb-2">
      {{ "reporting.reporting_list.last_modified_by" | translate }}
    </span>
    <div class="d-flex align-items-center mt-1">
      <app-profile-picture [createdBy]="report.lastModifiedBy" />
      <span class="ms-2">{{ report.lastModifiedBy?.name }}</span>
    </div>
  </div>

  <!-- Last Modified Date row -->
  <div class="d-flex flex-column mb-3">
    <span class="wsm-text-grayed mb-2">
      {{ "company.report_cards.last_modified_label" | translate }}
    </span>
    <span>{{ report.lastModifiedDate | date }}</span>
  </div>

  <!-- Connections Row -->
  @if (reportConnections.length !== 0) {
    <div class="d-flex flex-column">
      <span class="wsm-text-grayed mb-2">
        {{ "company.report_cards.connections_label" | translate }}
      </span>
      <app-connection-logos [connections]="reportConnections" type="report" />
    </div>
  }
</div>
