<section class="h-100 w-100 d-grid">
  <div
    class="w-100 d-flex flex-column align-items-center justify-content-center rounded my-2"
    [ngStyle]="{
      'color': theme.bodyColor,
      'border': '3px solid ' + theme.bodyColor,
      'background-color': theme?.bgErrorColor
    }"
  >
    <div>
      <i
        class="fa-regular fa-face-confused"
        [ngClass]="{'fa-2xl': !isMobile}"
      ></i>
    </div>
    <span class="text-center" [ngClass]="isMobile ? 'mt-1' : 'mt-2'">
      {{ "common.something_wrong" | translate }}
    </span>
  </div>
</section>
