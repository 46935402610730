<header class="mb-4">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h2 m-0">
      {{ "shared.dialogs.reorder_pages.reorder_pages_title" | translate }}
    </h1>

    <button
      [mat-dialog-close]="false"
      class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</header>

<mat-dialog-content class="reorder-list-content">
  <div
    class="page-list"
    #pagesContainer
    cdkDropList
    cdkDropListOrientation="vertical"
    [cdkDropListAutoScrollStep]="10"
    (cdkDropListDropped)="drop($event)"
  >
    @for (page of pageOrder; track page) {
      <div
        class="page mb-2"
        [ngClass]="{'dragging': isDragging}"
        cdkDrag
        cdkDragLockAxis="y"
        cdkDragBoundary=".page-list"
        (cdkDragMoved)="onPageDrag()"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <i class="fa-regular fa-grip-dots-vertical drag-icon me-2"></i>
            <span>{{ page.name }}</span>
          </div>
          <button
            class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon me-3 p-0"
            [style.height]="'unset'"
            [style.font-size]="'16px'"
            (click)="onHidePage(page)"
          >
            <i
              class="fa-regular"
              [ngClass]="!page.isVisible ? 'fa-eye-slash' : 'fa-eye'"
            ></i>
          </button>
        </div>
      </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="wsm-btn wsm-btn-primary" (click)="saveOrder()">
    @if (!isLoading) {
      <span>{{
        "shared.dialogs.reorder_pages.save_order_button" | translate
      }}</span>
    }
    @if (isLoading) {
      <div class="spinner-border spinner-border-sm text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }
  </button>
</mat-dialog-actions>
