@if (rendered) {
  <section class="w-100 h-100">
    <fusioncharts
      (initialized)="onChartReady($event)"
      [dataSource]="widget.chartData?.chartData"
      [type]="widget.chartData?.chartType!"
      height="100%"
      width="100%"
    />
  </section>
}
