import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';

import {
  LangChangeEvent,
  TranslateService,
  TranslateModule,
} from '@ngx-translate/core';
import {
  AuthenticationService,
  MobileService,
  OrganizationService,
  SidenavService,
} from 'src/app/core/services';
import {
  BillingPlan,
  Organization,
  SidenavLink,
  SideNavToggle,
  User,
} from '../../models';
import {canUser, getSidenavLinks, HelperService} from '../../helpers';
import {PlanType} from '../../enums';
import {environment} from 'src/environments/environment';
import {NgClass} from '@angular/common';
import {RouterLinkActive, RouterLink} from '@angular/router';
import {BillingPlanStore} from '../../state';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate('10ms', style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 0}),
        animate('10ms', style({opacity: 1})),
      ]),
    ]),
  ],
  standalone: true,
  imports: [NgClass, RouterLinkActive, RouterLink, TranslateModule],
})
export class SidenavComponent implements OnInit, OnDestroy {
  // Inputs / Outputs
  @Output() toggleSideNav: EventEmitter<SideNavToggle> =
    new EventEmitter<SideNavToggle>();

  // Store
  private billingPlanStore = inject(BillingPlanStore);

  // Properties
  screenWidth = 0;
  currentUser: User;
  mobileSidenav = 576;
  orgLogo: string;
  squareLogo: string;
  subs: Subscription = new Subscription();
  userPlan: PlanType = this.helperService.getUserPlan();
  trialDays: number | undefined;
  billingInfo: BillingPlan | undefined;
  helpUrl: string;
  sidenavLinks: Array<SidenavLink> = getSidenavLinks();

  // State Variables
  collapsed = false;
  isExternalUser: boolean;
  showBurgerMenu = false;
  isSidenavMobile = false;
  isLoading: boolean;
  isTrial: boolean;
  isMobile: boolean;

  isDev: boolean;
  isLocal: boolean;

  constructor(
    public authService: AuthenticationService,
    private sidenavService: SidenavService,
    private elementRef: ElementRef,
    private organizationService: OrganizationService,
    private translate: TranslateService,
    private helperService: HelperService,
    private mobileService: MobileService
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent): void => {
      this.helpUrl = this.helperService.getSupportUrl(event.lang);
    });

    this.mobileService.isMobile$.subscribe((res) => {
      this.isMobile = res;
    });

    const watchBilllingPlan = toObservable(this.billingPlanStore.getPlan);
    this.subs.add(
      watchBilllingPlan.subscribe((res: BillingPlan | null): void => {
        if (res) {
          this.sidenavLinks = getSidenavLinks();
          this.billingInfo = res;
          this.userPlan = this.helperService.getUserPlan();
          this.isTrial = this.billingInfo?.status === 'TRIAL';
          this.trialDays = this.billingInfo?.remainingDays;
          this.updateLinksPlan();
        }
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.showBurgerMenu =
      window.innerWidth <= 1360 || window.innerHeight <= 675 || this.isMobile;
    this.isSidenavMobile = this.screenWidth <= 1360 || this.isMobile;

    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.toggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
      });
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Click away from component?
      if (this.collapsed && this.screenWidth <= this.mobileSidenav) {
        this.toggleCollapse('leave');
      }
    }
  }

  ngOnInit(): void {
    this.isDev = environment.develop;
    this.isLocal = environment.local;
    this.helpUrl = this.helperService.getSupportUrl(this.translate.currentLang);
    this.updateLinksPlan();

    this.isLoading = true;
    this.showBurgerMenu =
      window.innerWidth <= 1360 || window.innerHeight <= 675;
    this.screenWidth = window.innerWidth;

    this.subs.add(
      this.authService.getCurrentUserState().subscribe(() => {
        this.currentUser = this.authService.getCurrentUser();
        this.isExternalUser = this.authService.checkRole(['ROLE_EXTERNAL']);
      })
    );

    this.subs.add(
      this.organizationService.getOrganizationState().subscribe(() => {
        const organization: Organization = JSON.parse(
          localStorage.getItem('organization') as string
        );
        this.orgLogo = organization?.logo as string;
        this.squareLogo = organization?.squareLogo as string;
      })
    );

    this.sidenavService.showSidenavStream$.subscribe((res: string): void => {
      if (res) {
        this.showBurgerMenu = false;
        setTimeout((): void => {
          this.toggleCollapse(res);
        }, 160);
        this.isSidenavMobile = true;
      }
    });
  }

  toggleCollapse(eventType: string): void {
    this.collapsed = eventType === 'enter';

    this.toggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });

    if (this.isSidenavMobile && eventType === 'leave') {
      setTimeout(() => {
        this.showBurgerMenu = true;
      }, 160);
      this.isSidenavMobile = false;
    }
  }

  // Only for mobile versions
  hideSidenav(): void {
    if (this.screenWidth <= this.mobileSidenav) {
      this.toggleCollapse('leave');
    }
  }

  private updateLinksPlan(): void {
    if (canUser('isIndividual', this.userPlan)) {
      this.sidenavLinks = this.sidenavLinks.filter(
        (link: SidenavLink): boolean =>
          link.path != 'clients' && link.path != 'monitoring'
      );
    }

    if (canUser('isBasic', this.userPlan)) {
      this.sidenavLinks = this.sidenavLinks.filter(
        (link: SidenavLink): boolean => link.path != 'monitoring'
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
