import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {ThemeService} from 'src/app/core/services';
import {ServiceAccount} from 'src/app/shared/models';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {PlanType} from '../../../../enums';
import { MatSlideToggleChange, MatSlideToggle } from '@angular/material/slide-toggle';
import {canUser} from '../../../../helpers';
import { FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'app-service-account-card',
    templateUrl: './service-account-card.component.html',
    styleUrls: ['./service-account-card.component.scss'],
    standalone: true,
    imports: [
        MatSlideToggle,
        FormsModule,
        NgxIntlTelInputModule,
        NgClass,
        NgStyle,
        TranslateModule,
    ],
})
export class ServiceAccountCardComponent implements OnInit, AfterViewInit {
  // Inputs / Outputs
  @Input() account: ServiceAccount;
  @Input() userPlanType: PlanType;
  @Input() isEditMode: boolean;
  @Input() isMobile: boolean;
  @Output() accountRemoved = new EventEmitter<any>();
  @Output() accountSelected: EventEmitter<ServiceAccount> =
    new EventEmitter<ServiceAccount>();

  // Children
  @ViewChild('accountCard', {static: false}) accountCard: ElementRef;

  // Properties
  accountLabel: string;
  idLabel: string;
  borderColor: string;
  logoSrc: string;

  // State Variables
  isDarkTheme: boolean;
  isIndividual = false;

  constructor(
    private themeService: ThemeService,
    private translate: TranslateService
  ) {
    this.listenSubs();
  }

  ngOnInit(): void {
    this.isIndividual = canUser('isIndividual', this.userPlanType);
    this.accountLabel = this.translate.instant(
      'company.company_connections.account_name_label'
    );
    this.idLabel = this.translate.instant(
      'company.company_connections.account_id_label'
    );

    switch (this.account.type) {
      case 'adwords':
        this.borderColor = '#3C8BD9';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'gads.svg' : 'GADS.svg'
        );
        break;
      case 'bing':
        this.borderColor = '#FFB902';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'mads.svg' : 'MADS.svg'
        );
        break;
      case 'call-rail':
        this.borderColor = '#3D72B4';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'call-rail.svg' : 'call-rail.svg'
        );
        break;
      case 'call-tracking-metrics':
        this.borderColor = '#43C1ED';
        this.logoSrc = this.isDarkTheme
          ? this.getIconPath(
              this.isMobile ? 'ctm.svg' : 'call-tracking-metrics-white.svg'
            )
          : this.getIconPath(
              this.isMobile ? 'ctm.svg' : 'call-tracking-metrics.svg'
            );
        break;
      case 'facebook-ads':
        this.borderColor = '#3b5999';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'facebook.svg' : 'FADS.svg'
        );
        break;
      case 'facebook-insights':
        this.accountLabel = this.translate.instant(
          'company.company_connections.page_name_label'
        );
        this.idLabel = this.translate.instant(
          'company.company_connections.page_id_label'
        );
        this.borderColor = '#3b5999';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'facebook.svg' : 'FADS.svg'
        );
        break;
      case 'instagram-insights':
        this.accountLabel = this.translate.instant(
          'company.company_connections.page_name_label'
        );
        this.idLabel = this.translate.instant(
          'company.company_connections.page_id_label'
        );
        this.borderColor = '#C6008B';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'instagram.svg' : 'instagram.svg'
        );
        break;
      case 'google-search-console':
        this.accountLabel = this.translate.instant(
          'company.company_connections.site_url_label'
        );
        this.borderColor = '#3C8BD9';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'google-search-console.svg' : 'search-console.svg'
        );
        break;
      case 'google-analytics':
        this.accountLabel = this.translate.instant(
          'company.company_connections.view_name_label'
        );
        this.idLabel = this.translate.instant(
          'company.company_connections.view_id_label'
        );
        this.borderColor = '#E37625';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'analytics.svg' : 'analytics.svg'
        );
        break;
      case 'google-analytics-v4':
        this.accountLabel = this.translate.instant(
          'company.company_connections.property_name_label'
        );
        this.idLabel = this.translate.instant(
          'company.company_connections.property_id_label'
        );
        this.borderColor = '#E37625';
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'analytics.svg' : 'analytics.svg'
        );
        break;
      case 'youtube':
        this.accountLabel = this.translate.instant(
          'company.company_connections.channel_name_label'
        );
        this.idLabel = this.translate.instant(
          'company.company_connections.channel_id_label'
        );
        this.borderColor = '#f70000';
        this.logoSrc = this.isDarkTheme
          ? this.getIconPath(this.isMobile ? 'youtube.svg' : 'youtube.svg')
          : this.getIconPath(
              this.isMobile ? 'youtube.svg' : 'youtube-dark.svg'
            );
        break;
      case 'linkedin-ads':
      case 'linkedin-insights':
        this.borderColor = '#2867B2';
        this.logoSrc = this.isDarkTheme
          ? this.getIconPath(
              this.isMobile ? 'linkedin.svg' : 'linkedin-logo-white.svg'
            )
          : this.getIconPath(
              this.isMobile ? 'linkedin.svg' : 'linkedin-logo.svg'
            );
        break;
      case 'semrush':
        this.accountLabel = this.translate.instant(
          'company.company_connections.project_name_label'
        );
        this.idLabel = this.translate.instant(
          'company.company_connections.project_id_label'
        );
        this.borderColor = '#f25f2a';
        this.logoSrc = this.isDarkTheme
          ? this.getIconPath(
              this.isMobile ? 'semrush.svg' : 'semrush-white.svg'
            )
          : this.getIconPath(
              this.isMobile ? 'semrush.svg' : 'semrush-black.svg'
            );
        break;
      case 'ahrefs':
        this.logoSrc = this.getIconPath(
          this.isMobile ? 'ahrefs-icon.svg' : 'ahrefs-big.svg'
        );
        break;
    }
  }

  ngAfterViewInit(): void {
    if (!this.isEditMode) {
      return;
    }
    this.accountCard.nativeElement.scrollIntoView();
  }

  private listenSubs(): void {
    this.themeService.theme$.subscribe((res: string): void => {
      this.isDarkTheme = res === 'dark';
      switch (this.account?.type) {
        case 'call-tracking-metrics':
          this.logoSrc = this.isDarkTheme
            ? this.getIconPath(
                this.isMobile ? 'ctm.svg' : 'call-tracking-metrics-white.svg'
              )
            : this.getIconPath(
                this.isMobile ? 'ctm.svg' : 'call-tracking-metrics.svg'
              );
          break;
        case 'youtube':
          this.logoSrc = this.isDarkTheme
            ? this.getIconPath(this.isMobile ? 'youtube.svg' : 'youtube.svg')
            : this.getIconPath(
                this.isMobile ? 'youtube.svg' : 'youtube-dark.svg'
              );
          break;
        case 'linkedin-ads':
        case 'linkedin-insights':
          this.logoSrc = this.isDarkTheme
            ? this.getIconPath(
                this.isMobile ? 'linkedin.svg' : 'linkedin-logo-white.svg'
              )
            : this.getIconPath(
                this.isMobile ? 'linkedin.svg' : 'linkedin-logo.svg'
              );
          break;
        case 'semrush':
          this.logoSrc = this.isDarkTheme
            ? this.getIconPath(
                this.isMobile ? 'semrush.svg' : 'semrush-white.svg'
              )
            : this.getIconPath(
                this.isMobile ? 'semrush.svg' : 'semrush-black.svg'
              );
          break;
      }
    });
  }

  removeServiceAccount(): void {
    this.accountRemoved.emit({id: this.account.accountId});
  }

  private getIconPath(iconName: string): string {
    return this.isMobile
      ? `/assets/img/svg/connections/normal/${iconName}`
      : `/assets/img/svg/connections/big/${iconName}`;
  }

  onChangeStatus(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.accountSelected.emit(this.account);
    } else {
      this.removeServiceAccount();
    }
  }
}
