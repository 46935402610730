import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {
  LangChangeEvent,
  TranslateService,
  TranslateModule,
} from '@ngx-translate/core';
import {
  MatSelectChange,
  MatSelect,
  MatSelectTrigger,
} from '@angular/material/select';
import {
  MatSlideToggleChange,
  MatSlideToggle,
} from '@angular/material/slide-toggle';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscription, take} from 'rxjs';
import {environment} from 'src/environments/environment';

import {
  AuthenticationService,
  MobileService,
  OrganizationService,
  SidenavService,
  ThemeService,
} from 'src/app/core/services';
import {ExportToDialogComponent} from '../../dialogs';
import {PlanType} from '../../enums';
import {BillingPlan, User} from '../../models';
import {canUser, HelperService} from '../../helpers';
import {NgClass, UpperCasePipe} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {FormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';
import {MatOption} from '@angular/material/core';
import {BillingPlanStore} from '../../state';
import {toObservable} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-profile-navbar',
  templateUrl: './profile-navbar.component.html',
  styleUrls: ['./profile-navbar.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    MatTooltip,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatSelect,
    FormsModule,
    NgxIntlTelInputModule,
    MatSelectTrigger,
    MatOption,
    MatSlideToggle,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class ProfileNavbarComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  // Inputs / Outputs
  @Input() burgerMenu = false;
  @ViewChild('profileImg', {static: false})
  profileImg: ElementRef<HTMLImageElement>;

  // Store
  private billingPlanStore = inject(BillingPlanStore);

  // Properties
  languages: Array<string> = ['en', 'es'];
  currentLang = 'en';
  public user: User = this.authService.getCurrentUser();
  public fullApiUrl: string = environment.fullApiUrl + environment.apiUrl;
  orgLogo: string;
  squareLogo: string;
  subs: Subscription = new Subscription();
  billingInfo: string;
  hideProfileNavbar: boolean;
  profilePicture: string | ArrayBuffer | null | undefined;

  // Permissions
  userPlan: PlanType = this.helper.getUserPlan();
  canSwitchTo = canUser('switchTo', this.userPlan);

  // State Variables
  callImpersonate = false;
  loading: boolean;
  isDarkTheme: boolean;
  isExternalUser: boolean;
  isMobile: boolean;

  constructor(
    private translate: TranslateService,
    private authService: AuthenticationService,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private router: Router,
    private sidenavService: SidenavService,
    private changeDetectorRef: ChangeDetectorRef,
    private organizationService: OrganizationService,
    private mobileService: MobileService,
    private helper: HelperService
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent): void => {
      this.currentLang = event.lang;
    });

    this.themeService.theme$.subscribe((res: string): void => {
      this.isDarkTheme = res === 'dark';
    });

    this.authService.getCurrentUserState().subscribe((): void => {
      this.user = this.authService.getCurrentUser();
      const imageSource =
        this.user?.image && this.user?.picture
          ? this.fullApiUrl + this.user?.picture
          : this.user?.picture;
      const rand: number = Math.floor(Math.random() * 10000);
      this.profilePicture = `${imageSource}?${rand}`;
    });

    this.subs.add(
      this.organizationService.getOrganizationState().subscribe(() => {
        this.orgLogo = JSON.parse(
          localStorage.getItem('organization') as string
        )?.logo;
        this.squareLogo = JSON.parse(
          localStorage.getItem('organization') as string
        )?.squareLogo;
      })
    );

    const watchBilllingPlan = toObservable(this.billingPlanStore.getPlan);
    this.subs.add(
      watchBilllingPlan.subscribe((res: BillingPlan | null) => {
        if (this.isExternalUser) {
          this.billingInfo = JSON.parse(
            localStorage.getItem('organization') as string
          )?.paymentStatus;
        } else {
          this.billingInfo = res?.status as string;
        }
      })
    );
  }

  ngOnInit(): void {
    this.hideProfileNavbar = ['/widget'].some((route: string) =>
      this.router.url.includes(route)
    );

    this.checkInitialLanguage();

    // Listening Subs
    this.subs.add(
      this.mobileService.getMobileBehavior().subscribe((res: boolean) => {
        this.isMobile = res;
      })
    );
  }

  ngAfterViewInit(): void {
    this.callImpersonate = this.authService.checkRole(['ROLE_IMPERSONATE']);
    this.isExternalUser = this.authService.checkRole(['ROLE_EXTERNAL']);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.burgerMenu = changes.burgerMenu?.currentValue;
  }

  logout(): void {
    this.authService.clearSession();
    window.location.replace(environment.fullApiUrl + '/api/v2/logout');
  }

  logoutFromImpersonate(): void {
    const loginFromDomain = encodeURIComponent(
      localStorage.getItem('loginFromDomain') ?? '{}'
    );
    this.authService.clearSession();
    window.location.replace(`https://${loginFromDomain}`);
  }

  changeSelectedLanguage(e: MatSelectChange): void {
    this.translate.use(e.value);
    localStorage.setItem('language', e.value);
  }

  toggleTheme(event: MatSlideToggleChange): void {
    this.themeService.setThemeScheme(event.checked);
  }

  onSwitch(): void {
    const dialogRef: MatDialogRef<ExportToDialogComponent> = this.dialog.open(
      ExportToDialogComponent,
      {
        data: {isExport: false},
        height: '710px',
        width: '50vw',
      }
    );

    const dialogSub: Subscription = this.router.events.subscribe((): void => {
      dialogRef.close();
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((): void => {
        dialogSub.unsubscribe();
      });
  }

  showSidenav(): void {
    this.sidenavService.showSidenav = 'enter';
  }

  checkInitialLanguage(): void {
    if (localStorage.getItem('language') !== null) {
      this.currentLang = localStorage.getItem('language') as string;
    } else if (typeof this.user?.language !== undefined) {
      this.currentLang = this.user?.language as string;
    }

    localStorage.setItem('language', this.currentLang);
    this.translate.setDefaultLang(this.currentLang);
  }

  goToGetStarted(): void {
    this.router.navigate(['./get-started']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
