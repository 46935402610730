import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  input,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {HelperService} from 'src/app/shared/helpers';
import {Widget} from 'src/app/shared/models';
import {
  GeoHeatMapData,
  GeoPoint,
} from 'src/app/shared/models/widget/location.model';
import {ReportService} from '../../../../../core/services';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-geo-heat-map',
    templateUrl: './geo-heat-map.component.html',
    styleUrls: ['./geo-heat-map.component.scss'],
    standalone: true,
    imports: [NgClass, TranslateModule],
})
export class GeoHeatMapComponent implements OnInit, AfterViewInit, OnChanges {
  // Services
  private reportService: ReportService = inject(ReportService);
  private helperService: HelperService = inject(HelperService);

  // Signals
  public isExternalUser = input.required<boolean>();
  public isMobile = input.required<boolean>();
  public isPrint = input<boolean>();

  // Inputs
  @Input() widget: Widget;
  @Input() widgetStyleForm: FormGroup;

  // Children
  @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;

  // Properties
  public geoData: GeoHeatMapData[] = [];
  public gMap: google.maps.Map;
  public gMapOptions: google.maps.MapOptions;
  public markers: google.maps.Polygon[] = [];
  public infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
  public circles: google.maps.Circle[] = [];

  // State
  private initialized = false;

  ngOnInit(): void {
    this.initialized = true;
  }

  ngAfterViewInit(): void {
    this.mapInitializer();
    setTimeout(() => {
      this.loadGeoCoordinates();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.loadGeoCoordinates();
    }
  }

  mapInitializer(): void {
    this.gMapOptions = {
      center: this.widget?.mapCenter || {lat: 0, lng: 0},
      zoom: this.widget?.mapZoomLevel || 2,
      mapTypeId: 'roadmap',
      mapId: 'a62a234534c3a603',
      streetViewControl: false,
      mapTypeControl: !this.isPrint(),
      zoomControl: !this.isPrint(),
      fullscreenControl: !this.isPrint(),
    };

    this.gMap = new google.maps.Map(
      this.mapContainer.nativeElement,
      this.gMapOptions
    );

    this.addCustomControlMap();
    this.listeningMapEvents();
  }

  loadGeoCoordinates(): void {
    this.circles.length > 0 && this.removeCircles();
    if (this.widget.geoData) {
      this.geoData = [
        ...this.widget.geoData.filter((el) => el.centroid !== null),
      ];
      this.geoData.forEach((gData) => {
        const shape = this.drawCircle(gData);
        shape.addListener('mouseover', () =>
          this.showInfo(shape, gData, gData.centroid as GeoPoint)
        );
        this.circles.push(shape);
      });
    }
    if (this.gMap && this.geoData && this.geoData.length > 0) {
      // If a map center is not specified use default from last item in the geoData array.
      if (!this.widget?.mapCenter) {
        this.gMap.panTo(
          this.geoData[this.geoData.length - 1]?.centroid as GeoPoint
        );
      }

      // If a map zoom level is not specified use default.
      if (!this.widget?.mapZoomLevel && this.widget?.mapZoomLevel !== 0) {
        const zoom =
          this.widget?.dimensions &&
          this.widget?.dimensions[0]?.administrativeArea === 'city'
            ? 6
            : 4.5;
        this.gMap.setZoom(zoom);
      }
    }
  }

  removeCircles(): void {
    this.circles.forEach((c) => c.setMap(null));
    this.circles = [];
  }

  showInfo(
    shape: google.maps.Circle,
    gHeatMapInfo: GeoHeatMapData,
    center: GeoPoint
  ): void {
    this.infoWindow.close();
    const formattedValue = this.widget.metrics
      ? this.helperService.updateMetricData(
          this.widget.metrics && this.widget.metrics.length > 0
            ? (this.widget.metrics[0].dataType as string)
            : 'no-type',
          gHeatMapInfo.metricValue,
          gHeatMapInfo.metricCurrency || '',
          this.widget.metrics && this.widget.metrics.length > 0
            ? this.widget.metrics[0].shortenNumbers
            : false
        )
      : gHeatMapInfo.metricValue;
    const content = `
      </span>
        <span style="font-weight: bold; color:#565656"><strong>${
          gHeatMapInfo.name ? gHeatMapInfo.name : 'not set'
        }</strong></span>
        <br/>
        <span style="color: #565656">${
          gHeatMapInfo.metricName
        }: ${formattedValue}</span>
      </span>
    `;
    this.infoWindow.setContent(content);
    this.infoWindow.setPosition(center);
    this.infoWindow.open({anchor: shape, map: this.gMap});
  }

  drawCircle(location: GeoHeatMapData): google.maps.Circle {
    const circle = new google.maps.Circle({
      strokeColor: location.opacity !== 0 ? location.colorPalette : '#cecece',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: location.opacity !== 0 ? location.colorPalette : '#cecece',
      fillOpacity: location?.opacity || 0.35,
      map: this.gMap,
      center: location.centroid,
      radius: location.radius,
    });
    circle.setMap(this.gMap);
    return circle;
  }

  private listeningMapEvents(): void {
    this.gMap.addListener('zoom_changed', (): void => {
      const existZoom = 'mapZoomLevel' in this.widget;

      if (!this.widget.mapZoomLevel && this.widget?.mapZoomLevel !== 0) {
        this.widget.mapZoomLevel = 2;
      }
      this.widget.mapZoomLevel = this.gMap.getZoom() ?? 2;

      if (existZoom && this.initialized) {
        this.setReportChanges();
      }
    });

    this.gMap.addListener('center_changed', (): void => {
      const existCenter = 'mapCenter' in this.widget;

      if (!this.widget.mapCenter) {
        this.widget.mapCenter = {lat: 0, lng: 0};
      }
      this.widget.mapCenter = {
        lat: this.gMap.getCenter()?.lat() || 0,
        lng: this.gMap.getCenter()?.lng() || 0,
      };

      if (existCenter && this.initialized) {
        this.setReportChanges();
      }
    });
  }

  private setReportChanges(): void {
    if (!this.isPrint()) {
      this.reportService.setChangeValue = {
        hasChanges: !(this.isMobile() || this.isExternalUser()),
      };
    }
  }

  private addCustomControlMap(): void {
    if (
      this.gMap &&
      this.geoData &&
      this.geoData.length > 0 &&
      !this.isPrint()
    ) {
      const centerControlDiv = document.createElement('div');
      centerControlDiv.classList.add('right-control-map');
      const centerControl = this.createResetMapButton();
      centerControlDiv.appendChild(centerControl);

      this.gMap.controls[google.maps.ControlPosition.INLINE_END_BLOCK_END].push(
        centerControlDiv
      );
    }
  }

  private createResetMapButton(): HTMLButtonElement {
    const controlButton: HTMLButtonElement = document.createElement('button');

    controlButton.classList.add('custom-reset-map-btn');

    controlButton.innerHTML = '<i class="fa-solid fa-rotate-left"></i>';
    controlButton.title = 'Reset map to initial position';
    controlButton.type = 'button';

    controlButton.addEventListener('click', (): void => {
      this.gMap.panTo(
        this.geoData[this.geoData.length - 1]?.centroid as GeoPoint
      );

      const zoom =
        this.widget?.dimensions &&
        this.widget?.dimensions[0]?.administrativeArea === 'city'
          ? 6
          : 4.5;
      this.gMap.setZoom(zoom);
    });

    return controlButton;
  }
}
