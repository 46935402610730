import {Component, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-tab-body',
    templateUrl: './tab-body.component.html',
    standalone: true,
})
export class TabBodyComponent {
  @ViewChild(TemplateRef) bodyContent: TemplateRef<any>;
}
