import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {NgClass, NgStyle, formatDate, formatPercent} from '@angular/common';
import {FormGroup} from '@angular/forms';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {
  DateRange,
  DynamicDate,
  KpiData,
  ReportDateRange,
  ReportPage,
  ReportTheme,
  TextData,
  Widget,
} from 'src/app/shared/models';
import {HelperService} from '../../../../helpers';
import {WidgetService} from 'src/app/core/services';
import {distinctUntilChanged, Subscription} from 'rxjs';

interface OriginalTexts {
  textData: TextData;
  newPreviewText: TextData;
}

@Component({
  selector: 'app-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass],
})
export class RichTextComponent implements OnInit, OnChanges, OnDestroy {
  subs: Subscription = new Subscription();
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() theme: ReportTheme = {};
  @Input() page: ReportPage;
  @Input() widgetForm: any;
  @Input() widgetStyleForm: FormGroup;
  @Input() isFromEditor: boolean;
  @Input() isMobile: boolean;

  public htmlTextToDisplay: SafeHtml;
  public originalTexts: OriginalTexts;
  public alive = true;
  public userLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  constructor(
    private helperService: HelperService,
    private sanitized: DomSanitizer,
    private widgetService: WidgetService
  ) {}

  ngOnInit(): void {
    this.setInitBasicText();
    this.setHtmlText();
    this.subs.add(
      this.widgetService.changeDynamicText$
        .pipe(distinctUntilChanged())
        .subscribe({
          next: (value) => {
            if (value.widgetId === this.widget.id && this.isFromEditor) {
              if (value.widget) {
                this.widget = value.widget;
              }
              if (!value.isInitialValue && value.newText) {
                if (
                  this.widget.newPreviewText &&
                  this.widget?.dynamicTextEdit === 'advanced'
                ) {
                  this.widget.newPreviewText.innerHtml =
                    value.newText?.innerHtml;
                  this.widget.newPreviewText.originalHtml =
                    value.newText?.originalHtml;
                }
                if (
                  this.widget.textData &&
                  this.widget?.dynamicTextEdit === 'basic'
                ) {
                  this.widget.textData.innerHtml = value.newText?.innerHtml;
                  this.widget.textData.originalHtml =
                    value.newText?.originalHtml;
                }
                this.setHtmlText();
              }
              // in order to set the originalHtml
              if (value.isInitialValue && this.isFromEditor) {
                this.setHtmlText();
              }
            }
          },
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
      this.setInitBasicText();
      this.setHtmlText();
    }
  }

  setInitBasicText(): void {
    if (this.widget.widgetType === 'dynamictext') {
      if (this.widget.dynamicTextEdit === 'basic') {
        const inner = this.widget.textData?.innerHtml
          ? this.widget.textData.innerHtml
          : this.widget.textData?.originalHtml
            ? this.widget.textData.originalHtml
            : '';

        const originalHtml = this.widget.textData?.originalHtml
          ? this.widget.textData?.originalHtml
          : this.widget.newPreviewText?.originalHtml
            ? this.widget.newPreviewText?.originalHtml
            : inner;

        this.widget.textData = {
          innerHtml: this.widget.textData?.innerHtml || '',
          originalHtml: originalHtml,
        };
      }
    }
  }

  private setHtmlText(): void {
    if (this.widget?.dynamicTextEdit === 'advanced') {
      this.htmlTextToDisplay = this.updateDynamicText(
        this.widget.newPreviewText?.originalHtml || ''
      );
    }

    if (this.widget?.dynamicTextEdit === 'basic') {
      this.htmlTextToDisplay = this.updateDynamicText(
        this.widget.textData?.originalHtml || ''
      );
    }

    if (this.widget.id === 'dummy' || this.widget.widgetType === 'text') {
      this.htmlTextToDisplay = this.sanitizeRichTextContent(
        this.widget.textData?.innerHtml || ''
      );
    }
  }

  private updateDynamicText(widgetText: string): SafeHtml {
    let replaceText: string;

    replaceText = this.updateDynamicDates(widgetText);

    if (
      widgetText &&
      this.widget.kpiDataArray &&
      this.widget.kpiDataArray.length > 0 &&
      this.widget.dataset &&
      this.widget.dataset.length > 0
    ) {
      const regexBraces = /{{(.*?)}}/g;
      const metricList: Array<string> = [];

      if (this.widget?.dynamicTextEdit === 'advanced') {
        while (this.alive) {
          const found = regexBraces.exec(widgetText);
          if (!found) {
            break;
          }
          metricList.push(`${found[1]}`);
        }
      }

      if (this.widget?.dynamicTextEdit === 'basic') {
        while (this.alive) {
          const found = regexBraces.exec(widgetText);
          if (!found) {
            break;
          }
          metricList.push(`${found[1]}`);
        }
      }

      this.widget?.kpiDataArray.forEach((kpi: KpiData) => {
        if (replaceText?.includes(`{{${kpi.field}_previous}}`)) {
          if (kpi.dataType && kpi.previousValue !== undefined) {
            const value = this.helperService.updateMetricData(
              kpi?.dataType,
              kpi?.previousValue,
              kpi?.currency || 'USD'
            );
            replaceText = replaceText.replace(
              new RegExp(`{{${kpi.field}_previous}}`, 'ig'),
              value
            );
          }
        }

        if (replaceText?.includes(`{{${kpi.field}_delta}}`)) {
          if (kpi.dataType && kpi.delta !== undefined) {
            const value = this.helperService.updateMetricData(
              kpi.dataType,
              kpi.delta,
              kpi?.currency || 'USD'
            );
            replaceText = replaceText.replace(
              new RegExp(`{{${kpi.field}_delta}}`, 'ig'),
              value
            );
          }
        }

        if (replaceText?.includes(`{{${kpi.field}_deltaPercent}}`)) {
          const value = formatPercent(kpi?.deltaPercent as number, '1.2');
          if (value) {
            replaceText = replaceText.replace(
              new RegExp(`{{${kpi.field}_deltaPercent}}`, 'ig'),
              value
            );
          }
        }

        if (replaceText?.includes(`{{${kpi.field}}`)) {
          if (kpi.dataType && kpi.currentValue !== undefined) {
            const value = this.helperService.updateMetricData(
              kpi?.dataType,
              kpi?.currentValue,
              kpi?.currency || 'USD'
            );
            replaceText = replaceText.replace(
              new RegExp(`{{${kpi.field}}}`, 'ig'),
              value
            );
          }
        }
      });

      if (metricList?.length > 0) {
        metricList.forEach((metric: string) => {
          if (metric?.length > 0 && replaceText.includes(`{{${metric}}}`)) {
            const value = `<span style="color:#ffc107" title="Warning: The metric doesn't exist." class="mat-tooltip-trigger fa-regular fa-triangle-exclamation fa-sm">&nbsp;{{${metric}}}</span>`;
            replaceText = replaceText.split(`{{${metric}}}`).join(value);
          }
        });
      }
    }

    return this.sanitizeRichTextContent(replaceText);
  }

  private updateDynamicDates(replaceText: string): string {
    if (this.widget.dateRange || this.page.dateRange) {
      const regexDateBraces = /{{date_(.*?)}}/g;
      const dynamicDates: Array<DynamicDate> =
        this.helperService.getDynamicDates();
      const datesList: Array<string> = [];

      while (this.alive) {
        const found = this.widget.textData?.innerHtml
          ? regexDateBraces.exec(this.widget.textData?.innerHtml)
          : null;
        if (!found) {
          break;
        }
        datesList.push(`${found[1]}`);
      }
      const currentDateRange: ReportDateRange = this.widget.dateRange
        ? this.widget.dateRange
        : (this.page.dateRange as ReportDateRange);

      datesList.forEach((value: string): void => {
        if (value?.length > 0) {
          for (const dynamicDate of dynamicDates) {
            if (dynamicDate.combinations.indexOf(value.toLowerCase()) >= 0) {
              let dateRange: DateRange | null = null;

              if (value.toLowerCase().startsWith('current_')) {
                dateRange = currentDateRange?.current;
              } else {
                if (
                  value.toLowerCase().startsWith('previous_') &&
                  currentDateRange?.previous
                ) {
                  dateRange = currentDateRange?.previous;
                }
              }

              if (
                replaceText
                  ?.toLowerCase()
                  ?.includes(`{{date_${value.toLowerCase()}}}`) &&
                dateRange
              ) {
                let formattedValue = '';
                if (value.toLowerCase().endsWith('_start')) {
                  formattedValue = `${formatDate(
                    dateRange?.start as string,
                    dynamicDate.pipeFormat,
                    this.userLocale
                  )}`;
                }
                if (value.toLowerCase().endsWith('_end')) {
                  formattedValue = `${formatDate(
                    dateRange?.end as string,
                    dynamicDate.pipeFormat,
                    this.userLocale
                  )}`;
                }
                if (value.toLowerCase().endsWith('_both')) {
                  formattedValue = `${formatDate(
                    dateRange?.start as string,
                    dynamicDate.pipeFormat,
                    this.userLocale
                  )} - ${formatDate(
                    dateRange?.end as string,
                    dynamicDate.pipeFormat,
                    this.userLocale
                  )}`;
                }
                replaceText = replaceText.replace(
                  new RegExp(`{{date_${value}}}`, 'ig'),
                  formattedValue
                );
              }
            }
          }
        }
      });
    }
    return replaceText;
  }

  private sanitizeRichTextContent(originalContent: string): SafeHtml {
    const color = this.widget.theme.titleColor || this.theme.titleColor;
    const styleValue = `"color:${color}"`;
    const newContent: string = originalContent
      ? this.helperService.insertStyleAttributeToAnchorTags(
          originalContent,
          styleValue
        )
      : originalContent;
    return this.sanitized.bypassSecurityTrustHtml(newContent);
  }

  ngOnDestroy(): void {
    this.widgetService.setChangeDynamicTextValue({
      isInitialValue: true,
      widgetId: '',
      from: 'reporting',
    });
    this.subs.unsubscribe();
  }
}
