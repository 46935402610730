import {Component, Inject, OnInit} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import {TranslateService, TranslateModule} from '@ngx-translate/core';

import {NotificationService, ServiceAuthService} from 'src/app/core/services';
import {IConnectionDataDialog} from '../../models';
import {DemoVideoComponent} from '../../components';
import {NgClass} from '@angular/common';
import {NgxIntlTelInputModule} from '@whiteshark-media/ngx-intl-tel-input-app';

@Component({
  selector: 'app-connection-login-dialog',
  templateUrl: './connection-login-dialog.component.html',
  styleUrls: ['./connection-login-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogClose,
    DemoVideoComponent,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgxIntlTelInputModule,
    MatDialogActions,
    TranslateModule,
  ],
})
export class ConnectionLoginDialogComponent implements OnInit {
  // Properties
  connectionForm: UntypedFormGroup;

  // State
  isConnecting: boolean;
  isUpdating: boolean;
  isMobile: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConnectionLoginDialogComponent>,
    private serviceAuthService: ServiceAuthService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: IConnectionDataDialog
  ) {}

  ngOnInit(): void {
    this.connectionForm = new UntypedFormGroup({
      userEmail: new UntypedFormControl('', [Validators.required]),
      authToken: new UntypedFormControl('', Validators.required),
    });

    this.isUpdating = this.data.update;
    this.isMobile = this.data?.isMobile;
    this.connectionForm
      .get('userEmail')
      ?.setValue(this.isUpdating ? this.data.service.userEmail : '');
  }

  onSubmit(): void {
    if (this.connectionForm.invalid) {
      Object.values(this.connectionForm.controls).forEach(
        (control: AbstractControl): void => {
          if (!control.value) {
            control.markAsDirty();
          }
        }
      );
    } else {
      this.serviceLogin();
    }
  }

  serviceLogin(): void {
    this.isConnecting = true;
    const payload = {
      email: this.connectionForm.get('userEmail')?.value,
      basicAuthToken: this.connectionForm.get('authToken')?.value,
    };

    this.serviceAuthService
      .serviceLogin(
        this.data?.service?.provider || this.data?.service?.type,
        payload
      )
      .subscribe({
        error: (err): void => {
          this.isConnecting = false;
          this.notificationService.error(err.error.message, 5000);
        },
        complete: (): void => {
          this.isConnecting = false;
          this.notificationService.success(
            this.translate.instant('notifications.service_linked'),
            5000
          );
          this.dialogRef.close(this.data.service._id);
        },
      });
  }

  public onVideoExpanded(event: boolean): void {
    if (event) {
      this.dialogRef.updateSize(
        this.isMobile ? '100vw' : '35vw',
        this.isMobile ? '100vh' : '68vh'
      );
    } else {
      this.dialogRef.updateSize(
        this.isMobile ? '100vw' : '35vw',
        this.isMobile ? '100vh' : '45vh'
      );
    }
  }
}
