import {Directive, ElementRef, Input, OnChanges} from '@angular/core';

@Directive({
    selector: '[appSliderColor]',
    standalone: true,
})
export class SliderColorDirective implements OnChanges {
  static sliderNumberCounter = 0;

  @Input() appSliderColor: string;
  styleElement: HTMLStyleElement = document.createElement('style');

  attributeName: string;

  constructor(private el: ElementRef) {
    this.attributeName = `slider-color-${SliderColorDirective.sliderNumberCounter}`;
    SliderColorDirective.sliderNumberCounter++;
    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.setAttribute(this.attributeName, '');
    nativeEl.appendChild(this.styleElement);
  }

  ngOnChanges(): void {
    this.setColors();
  }

  setColors(): void {
    this.styleElement.innerText = `
      [${this.attributeName}] .mat-slider-ticks-container {
        background-color: ${this.appSliderColor} !important;
      }

      [${this.attributeName}] .mat-slider-thumb {
        background-color: ${this.appSliderColor} !important;
      }
    `;
  }
}
