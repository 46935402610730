import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';

import {createdBy} from '../../models';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class ProfilePictureComponent {
  // Inputs
  @Input() createdBy: createdBy;

  // Properties
  public fullApiUrl = environment.fullApiUrl + environment.apiUrl;
  defaultImage = '/assets/img/png/avatar_1.png';
}
