<button
  [ngClass]="{
    'menu-open': dpMenuTrigger.menuOpen,
    'w-100': isMobile && !largeMobile,
  }"
  [disabled]="origin === 'template' || isDisabled"
  class="wsm-input text-left wsm-datepicker-trigger mb-2"
  type="button"
  (click)="
    $event.stopPropagation();
    isMobile ? openMobileDatePicker() : dpMenuTrigger.openMenu()
  "
>
  @if (!dateRangeValue?.range) {
    <span>
      {{ "shared.components.date_picker.date_range_description" | translate }}
    </span>
  }

  <small class="d-flex align-items-center inner-label">
    <span class="mdi mdi-calendar mr-1 lh-normal"></span>
    <span>
      @switch (dateRangeValue?.range?.relative) {
        @case ("LAST_7_DAYS") {
          {{ "shared.components.date_picker.last_days" | translate: {days: 7} }}
        }
        @case ("LAST_14_DAYS") {
          {{
            "shared.components.date_picker.last_days" | translate: {days: 14}
          }}
        }
        @case ("LAST_30_DAYS") {
          {{
            "shared.components.date_picker.last_days" | translate: {days: 30}
          }}
        }
        @case ("LAST_MONTH") {
          {{ "shared.components.date_picker.last_month" | translate }}
        }
        @case ("THIS_MONTH") {
          {{ "shared.components.date_picker.this_month" | translate }}
        }
        @case ("THIS_YEAR") {
          {{ "shared.components.date_picker.this_year" | translate }}
        }
        @case ("RELATIVE_RANGE") {
          {{ "shared.components.date_picker.relative_range" | translate }}
        }
        @default {
          {{ "shared.components.date_picker.custom_range" | translate }}
        }
      }
    </span>
  </small>
  @if (dateRangeValue?.range && dateRangeValue?.range?.dates?.start) {
    <span class="d-block current-date">
      {{ dateRangeValue.range.dates.start | date: "mediumDate" }} -
      {{ dateRangeValue.range.dates.end | date: "mediumDate" }}
    </span>
  }

  @if (dateRangeValue?.compare && dateRangeValue?.compare?.dates?.start) {
    <small class="d-block">
      <span class="inner-label">
        {{ "shared.components.date_picker.compare_description" | translate }}
      </span>
      {{ dateRangeValue.compare.dates.start | date: "mediumDate" }} -
      {{ dateRangeValue.compare.dates.end | date: "mediumDate" }}
    </small>
  }
</button>

<mat-menu
  #appMenu="matMenu"
  class="{{ 'wsm-dp-menu mt-2' + ' ' + (useGrayBg ? 'wsm-bg-body' : '') }}"
>
  <ng-template matMenuContent>
    <app-date-picker-menu
      [showClear]="origin === 'widget'"
      [currentOption]="currentOption"
      [compareOption]="compareOption"
      [selectedComparisonRange]="selectedComparisonRange"
      [selectedRange]="selectedRange"
      [relativeRange]="relativeRange"
      [origin]="origin"
      [disableCompare]="disableCompare"
      (rangeSelection)="onDateSelection($event)"
    >
    </app-date-picker-menu>
  </ng-template>
</mat-menu>
<div
  #dpMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="appMenu"
  [class.custom-class]="useGrayBg"
></div>
