<div class="company-container">
  @if (showTitle) {
    <header>
      <h6 class="fw-normal">
        {{
          "shared.components.general.data_source_title" | translate
        }}&nbsp;<small class="required-mark">*</small>
      </h6>
    </header>
  }

  <mat-radio-group
    (change)="onChangeGroup($event)"
    [(ngModel)]="companyDataType"
  >
    <mat-radio-button [value]="'specific'" class="me-3">
      <small>{{
        "company_selection.option.specific_company" | translate
      }}</small></mat-radio-button
    >
    <mat-radio-button [value]="'criteria'" class="me-3">
      <small>{{
        "company_selection.option.matching_criteria" | translate
      }}</small></mat-radio-button
    >
    <mat-radio-button [value]="'all'" class="me-3"
      ><small>{{ "company_selection.option.all" | translate }}</small>
    </mat-radio-button>
  </mat-radio-group>

  @if (companyDataType === "specific") {
    <app-specific
      [conditionsFromWizard]="conditionsFromWizard"
      (loadingState)="getLoadingState($event)"
      [report]="report"
      [alert]="alert"
      (sendCondition)="setConditions($event)"
    >
    </app-specific>
  }
  @if (companyDataType === "criteria") {
    <app-criteria
      [conditionsFromWizard]="conditionsFromWizard"
      [report]="report"
      (sendCondition)="setConditions($event)"
    >
    </app-criteria>
  }
</div>
