import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogContent,
} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import {Router} from '@angular/router';
import {TranslateService, TranslateModule} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

import {
  ServiceAuthService,
  ConnectionsService,
  NotificationService,
  MobileService,
  DialogService,
} from 'src/app/core/services';
import {
  CardData,
  CardLabel,
  Connection,
  DataDialogCredentials,
  Service,
  User,
} from '../../models';
import {ConnectionLoginDialogComponent} from '..';
import {PlanType} from '../../enums';
import {canUser, HelperService} from '../../helpers';
import {NgClass} from '@angular/common';
import {SignInButtonComponent} from '../../components/connections/sign-in-button/sign-in-button.component';
import {MatCheckbox} from '@angular/material/checkbox';
import {CardCarouselComponent} from '../../mobile/components/card-carousel/card-carousel.component';
import {ConnectionLoginComponent} from '../../components/connections/connection-login/connection-login.component';
import {LoadingComponent} from '../../components/loading/loading.component';
import {MobileLoadingComponent} from '../../mobile/components/mobile-loading/mobile-loading.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-manage-credentials-dialog',
  templateUrl: './manage-credentials-dialog.component.html',
  styleUrls: ['./manage-credentials-dialog.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    SignInButtonComponent,
    MatDialogContent,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatCheckbox,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    CardCarouselComponent,
    ConnectionLoginComponent,
    LoadingComponent,
    MobileLoadingComponent,
    TranslateModule,
  ],
})
export class ManageCredentialsDialogComponent implements OnInit, OnDestroy {
  // Children
  sort: MatSort;
  paginator: MatPaginator;

  @ViewChild(MatSort, {static: false}) set sortContent(content: MatSort) {
    if (content) {
      this.sort = content;
    }
  }

  @ViewChild(MatPaginator, {static: false}) set paginatorContent(
    content: MatPaginator
  ) {
    if (content) {
      this.paginator = content;
    }
  }

  @ViewChild('filterInput', {static: true}) filterInput: ElementRef;

  // Properties
  userPlan: PlanType = this.helperService.getUserPlan();
  meta: string[] = ['facebook', 'instagram'];
  search: UntypedFormControl;
  header: string;
  services: Service[] = [];
  currentUser: User = JSON.parse(localStorage.getItem('currentUser')!);
  subs: Subscription = new Subscription();
  cardData: CardData;

  // Table Properties
  servicesDataSource: MatTableDataSource<Service> =
    new MatTableDataSource<Service>();
  pageSizeOptions: number[] = [5, 10, 15, 20];
  columnsToDisplay: string[] = [
    'userEmail',
    'lastUpdated',
    'status',
    'orgWide',
    'action',
    'delete',
  ];

  // State Variables
  isConnecting: boolean;
  isTokenAuth: boolean;
  isLoading = false;
  isSignup: boolean;
  isMobile: boolean;
  largeMobile: boolean;
  isIndividual = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataDialogCredentials,
    public dialogRef: MatDialogRef<ManageCredentialsDialogComponent>,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private serviceAuthService: ServiceAuthService,
    private connectionsService: ConnectionsService,
    private dialogService: DialogService,
    private mobileService: MobileService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.isIndividual = canUser(
      'isIndividual',
      this.data?.userPlanType ?? PlanType.Basic
    );
    if (this.data.isMobile) {
      this.subs.add(
        this.dialogRef.backdropClick().subscribe((): void => {
          this.onClose();
        })
      );
    }

    this.isLoading = true;
    if (this.data?.service) {
      this.isTokenAuth =
        this.data.service.authType?.toUpperCase()?.trim() ===
        ConnectionsService.AUTH_TYPE_API_TOKEN;

      if (
        this.meta.some((m: string) => this.data.service?.provider?.includes(m))
      ) {
        this.columnsToDisplay = [
          'serviceName',
          'lastUpdated',
          'status',
          'orgWide',
          'action',
          'delete',
        ];
      }
    } else if (!this.data?.service) {
      this.columnsToDisplay = [
        'type',
        'userEmail',
        'lastUpdated',
        'status',
        'orgWide',
        'action',
        'delete',
      ];
    }

    this.setTitle(
      this.data?.service?.provider ? this.data.service.provider : ''
    );
    this.getServiceList();

    // Subscribe to the mobile behavior subject and update the isMobile flag accordingly.
    this.subs.add(
      this.mobileService.getMobileBehavior().subscribe((res: boolean): void => {
        this.isMobile = res;
      })
    );

    this.subs.add(
      this.mobileService.mobileWindow$.subscribe((res: string): void => {
        this.largeMobile = res === 'large';
      })
    );
  }

  private setTitle(type: string): void {
    const serviceTitles = {
      'adwords': 'gads_title',
      'google-analytics': 'analytics_title',
      'bing': 'mads_title',
      'call-tracking-metrics': 'ctm_title',
      'sm-facebook-ads': 'fads_title',
      'facebook-ads': 'fads_title',
      'sm-facebook-insights': 'fins_title',
      'facebook-insights': 'fins_title',
      'call-rail': 'call_rail_title',
      'google-search-console': 'gsearch_title',
      'google-analytics-v4': 'ga4_title',
      'youtube': 'youtube_title',
      'sm-instagram-insights': 'instagram_insights',
      'instagram-insights': 'instagram_insights',
      'semrush': 'semrush_title',
      'linkedin-ads': 'linkedin_ads_title',
      'linkedin-insights': 'linkedin_insights',
    };

    this.header = Object.prototype.hasOwnProperty.call(serviceTitles, type)
      ? `shared.dialogs.manage_credentials.services_titles.${serviceTitles[type]}`
      : '';
  }

  setLogo(type: string): string {
    const logoPaths: {[key: string]: string} = {
      'adwords': '/assets/img/svg/connections/normal/gads.svg',
      'google-analytics': '/assets/img/svg/connections/normal/analytics.svg',
      'bing': '/assets/img/svg/connections/normal/mads.svg',
      'call-tracking-metrics': '/assets/img/svg/connections/normal/ctm.svg',
      'facebook-ads': '/assets/img/svg/connections/normal/facebook.svg',
      'sm-facebook-ads': '/assets/img/svg/connections/normal/facebook.svg',
      'sm-facebook-insights': '/assets/img/svg/connections/normal/facebook.svg',
      'call-rail': '/assets/img/svg/connections/normal/call-rail.svg',
      'google-search-console':
        '/assets/img/svg/connections/normal/google-search-console.svg',
      'google-analytics-v4': '/assets/img/svg/connections/normal/analytics.svg',
      'youtube': '/assets/img/svg/connections/normal/youtube.svg',
      'sm-instagram-insights':
        '/assets/img/svg/connections/normal/instagram.svg',
      'linkedin': '/assets/img/svg/connections/normal/linkedin-icon.svg',
      'linkedin-ads': '/assets/img/svg/connections/normal/linkedin-icon.svg',
      'linkedin-insights':
        '/assets/img/svg/connections/normal/linkedin-icon.svg',
      'instagram-insights': '/assets/img/svg/connections/normal/instagram.svg',
      'facebook-insights': '/assets/img/svg/connections/normal/facebook.svg',
      'semrush': '/assets/img/svg/connections/normal/semrush.svg',
    };

    return logoPaths[type] || '';
  }

  getServiceList(): void {
    this.connectionsService
      .getServiceList(
        this.currentUser._id!,
        this.data?.service?.provider ? this.data.service.provider : null
      )
      .subscribe({
        next: (services: Service[]): void => {
          this.servicesDataSource.data = this.addLogoToServices(services);
          this.setDataForCardCarousel(this.servicesDataSource.data);
        },
        error: (response) => {
          const errorMessage =
            response.error &&
            response.error.error &&
            response.error.error.message
              ? response.error.error.message
              : response.message;
          this.notificationService.error(errorMessage, 5000);
          this.isLoading = false;
          this.onClose();
        },
        complete: (): void => {
          this.servicesDataSource.sort = this.sort;
          this.servicesDataSource.paginator = this.paginator;
          this.isLoading = false;
        },
      });
  }

  addLogoToServices(services: Array<Service>): Array<Service> {
    return services.reduce((acc: Array<Service>, value: Service) => {
      const newValue = {...value, logoSrc: this.setLogo(value.type as string)};
      acc = [...acc, newValue];
      return acc;
    }, []);
  }

  addNewAccount(): void {
    if (this.data && this.data?.service && !this.isIndividual) {
      this.isConnecting = !this.isTokenAuth;
      const service: Connection = this.data.service;
      if (this.isTokenAuth) {
        this.dialog.open(ConnectionLoginDialogComponent, {
          width: '35vw',
          height: '44vh',
          data: {service},
        });
        this.onClose();
      } else {
        this.serviceAuthService
          .openSignInDialog(service.provider as string)
          .subscribe({
            next: (): void => {
              this.isConnecting = false;
            },
            error: (): void => {
              this.isConnecting = false;
            },
          });
      }
    } else if (this.isIndividual) {
      this.router.navigate(['/connections/sign-in']).then();
    } else {
      this.isSignup = true;
      this.changeDetector.detectChanges();
    }
  }

  toggleShared(service: Service): void {
    this.isLoading = true;
    service.orgWide = !service.orgWide;
    this.connectionsService.updateService(service).subscribe({
      next: (): void => {
        this.translate
          .get('notifications.service_updated')
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
      },
      error: (response) => {
        const errorMessage =
          response.error && response.error.error && response.error.error.message
            ? response.error.error.message
            : response.message;
        this.notificationService.error(errorMessage, 5000);
      },
      complete: (): void => {
        this.isLoading = false;
      },
    });
  }

  updateToken(service: Service): void {
    this.dialog.open(ConnectionLoginDialogComponent, {
      width: this.isMobile ? '100vw' : '500px',
      height: this.isMobile ? '100vh' : '390px',
      maxWidth: this.isMobile ? '100vw' : '',
      data: {
        service,
        update: true,
        isMobile: this.isMobile,
      },
    });
    this.onClose();
  }

  refreshAccounts(service: Service): void {
    this.isLoading = true;
    this.connectionsService.refreshAccounts(service._id).subscribe({
      error: (response) => {
        const errorMessage =
          response.error && response.error.error && response.error.error.message
            ? response.error.error.message
            : response.message;
        this.notificationService.error(errorMessage, 5000);
        this.onClose();
      },
      complete: (): void => {
        this.isLoading = false;

        this.translate
          .get('notifications.service_start_process', {
            serviceName: service.name,
          })
          .subscribe((res: string) => {
            this.notificationService.success(res, 5000);
          });
      },
    });
  }

  openConfirmDialog(serviceId: string): void {
    const onCloseActions = (res?): void => {
      if (res) {
        this.deleteService(serviceId);
      }
    };

    const config: MatDialogConfig = {
      data: {
        title: this.translate.instant(
          'shared.dialogs.manage_credentials.delete_service'
        ),
        content: this.translate.instant(
          'shared.dialogs.manage_credentials.confirm_delete'
        ),
        button: 'No',
        isDelete: true,
        showIcon: false,
      },
    };

    this.dialogService.openDialog(
      ConfirmationDialogComponent,
      config,
      onCloseActions.bind(this)
    );
  }

  deleteService(serviceId: string): void {
    this.isLoading = true;
    this.connectionsService.deleteService(serviceId).subscribe({
      error: (err) => {
        this.isLoading = false;
        this.notificationService.error(
          err?.error?.error?.message || err?.message,
          5000
        );
      },
      complete: () => {
        this.isLoading = false;
        this.notificationService.success(
          this.translate.instant('notifications.service_deleted'),
          5000
        );
        this.getServiceList();
      },
    });
  }

  checkOwnership(service: Service): boolean {
    return (
      service?.owners?.some(() =>
        service?.owners?.includes(this.currentUser._id as string)
      ) || false
    );
  }

  applyFilter(event: KeyboardEvent): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const filterValue: string = target?.value;
    this.servicesDataSource.filter = filterValue?.trim()?.toLowerCase();
    if (this.servicesDataSource.paginator) {
      this.servicesDataSource.paginator.firstPage();
    }

    this.setDataForCardCarousel(this.servicesDataSource.filteredData);
  }

  private setDataForCardCarousel(data: Service[]): void {
    const labels: CardLabel[] = [
      {
        key: 'logo',
        label: this.translate.instant(
          'shared.dialogs.manage_credentials.services_table.platform'
        ),
      },
      {
        key: 'userEmail',
        label: this.translate.instant(
          'shared.dialogs.manage_credentials.services_table.email'
        ),
      },
      {
        key: 'lastUpdate',
        label: this.translate.instant(
          'shared.dialogs.manage_credentials.services_table.last_updated'
        ),
      },

      {
        key: 'status',
        label: this.translate.instant(
          'shared.dialogs.manage_credentials.services_table.status'
        ),
      },
    ];

    let elements: Service[] = data || [];
    elements = elements.map((item: Service): Service => {
      item.logo = this.setLogo(String(item.type));
      item.buttons = [];

      // Shared
      if (
        this.currentUser?.isAdmin &&
        this.currentUser?.id !== item?.owners![0]
      ) {
        item.buttons.push({
          action: 'share',
          label: this.translate.instant(
            'shared.dialogs.manage_credentials.services_table.shared'
          ),
          type: 'checkbox',
          checked: item?.orgWide,
        });
      }

      // Update Token
      if (
        item.status !== 'ERROR' &&
        (this.checkOwnership(item) || this.currentUser.isAdmin) &&
        item?.type?.startsWith('call')
      ) {
        item.buttons.push({
          action: 'token',
          class: 'wsm-btn wsm-btn-outline w-100 mb-3',
          label: this.translate.instant(
            'shared.dialogs.manage_credentials.update_token_button'
          ),
          type: 'button',
        });
      }

      // Refresh
      if (
        item.status !== 'ERROR' &&
        (this.checkOwnership(item) || this.currentUser.isAdmin) &&
        !item?.type?.startsWith('call')
      ) {
        item.buttons.push({
          action: 'refresh',
          class: 'wsm-btn wsm-btn-outline w-100 mb-3',
          label: this.translate.instant(
            'shared.dialogs.manage_credentials.refresh_list_button'
          ),
          type: 'button',
          disabled: item.status === 'RUNNING' || item.status === 'PENDING',
        });
      }

      // Error
      if (item.status === 'ERROR') {
        item.buttons.push({
          action: 'authenticate',
          class: 'wsm-btn wsm-btn-outline w-100 mb-3',
          label: this.translate.instant(
            'shared.dialogs.manage_credentials.authenticate_button'
          ),
          type: 'button',
        });
      }

      // Delete
      if (
        this.currentUser?.isAdmin ||
        this.currentUser?.isSuperAdmin ||
        item?.owners?.includes(this.currentUser?._id as string)
      ) {
        item.buttons.push({
          action: 'remove',
          extraClass: 'w-100',
          label: this.translate.instant(
            'shared.dialogs.manage_credentials.remove_connection'
          ),
          type: 'button',
        });
      }
      return item;
    });

    this.cardData = {labels, elements};
  }

  onCardHandler(event: any): void {
    switch (event?.action) {
      case 'remove':
        this.openConfirmDialog(event?.element?._id);
        break;
      case 'share':
        this.toggleShared(event?.element);
        break;
      case 'token':
        this.updateToken(event?.element);
        break;
      case 'refresh':
        this.refreshAccounts(event?.element);
        break;
      case 'authenticate':
        this.addNewAccount();
        break;
    }
  }

  onClose(emitValue?: any): void {
    this.dialogService.closeDialog(this.dialogRef, emitValue);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
