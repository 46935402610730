import {Component, Input, OnInit} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Observable, debounceTime, distinctUntilChanged, filter, map, of, startWith, switchMap, tap} from 'rxjs';
import {WidgetService} from 'src/app/core/services';
import {FontawesomeIcon, FontawesomeService} from 'src/app/core/services/fontawesome.service';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorInputDirective } from '../../directives/behavior-input.directive';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatOption } from '@angular/material/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-fontawesome-icon-search',
    templateUrl: './fontawesome-icon-search.component.html',
    standalone: true,
    imports: [FormsModule, MatAutocompleteTrigger, BehaviorInputDirective, ReactiveFormsModule, NgxIntlTelInputModule, MatAutocomplete, MatOption, AsyncPipe, TranslateModule]
})

export class FontawesomeIconSearchComponent implements OnInit {

  @Input() iconControl: FormControl;

  public filteredIcons$: Observable<Array<FontawesomeIcon>>;
  public inputStatus: 'edit' | 'loading' | 'error' | 'success' | 'default' = 'default';

  constructor(private faService: FontawesomeService) {
  }

  ngOnInit(): void {
    this.filteredIcons$ = this.iconControl.valueChanges.pipe(
      filter(val => typeof val === 'string'),
      startWith(''),
      tap((val) => {
        if (typeof val === 'string' && val.trim() !== '') {
          this.inputStatus = 'loading';
        } else {
          this.inputStatus = 'default';
        }
      }),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val): Observable<Array<any>> => {
        if (typeof val === 'string' && val.trim() === '') {
          this.inputStatus = 'default';
          return of([]);
        }
        this.inputStatus = 'default';
        return this.faService.searchFontAwesomeIcons(val);
      })
    );
  }

  displayIcon(fa: FontawesomeIcon): string {
    return fa ? fa.label : '';
  }
}
