<div
  class="wsm-alert-message custom-alert animate__animated"
  [ngClass]="{'custom-margin': !isMobile()}"
  id="top-ad-notification"
>
  <div class="d-flex justify-content-between flex-row align-items-center">
    <span>{{ message() }}</span>
    <div>
      @if (onlyButton()) {
        <button class="wsm-btn wsm-btn-text my-2" (click)="onClose()">
          {{ "common.understood" | translate }}
        </button>
      } @else {
        <mat-checkbox
          class="me-3"
          [(ngModel)]="checked"
          (change)="handleToggle($event)"
        >
          {{ "common.don_t_show_again" | translate }}
        </mat-checkbox>
        <button
          class="wsm-btn wsm-btn-outline-icon wsm-btn-small-icon"
          (click)="onCloseNotification()"
        >
          <i class="fa-regular fa-xmark"></i>
        </button>
      }
    </div>
  </div>
</div>
