@if (codeValidity === "invalid") {
  <div class="invalid-info text-center mb-2">
    {{ "authentication.two_factor.incorrect_code" | translate }}
  </div>
}
<form
  id="code-form"
  class="mb-3"
  [formGroup]="codeForm"
  action=""
  (ngSubmit)="emitFormValue()"
>
  @for (
    digit of (this.codeForm?.get("digits") ?? {})["controls"] ?? [];
    track digit;
    let i = $index
  ) {
    <input
      [formControl]="digit"
      #tfInput
      type="number"
      class="wsm-input wsm-input-sm me-1 custom-input-sm"
      autocomplete="off"
      appMaxLength="1"
      (paste)="$event.preventDefault()"
      (keydown.enter)="$event.preventDefault(); emitFormValue()"
    />
  }
</form>
<div class="d-flex justify-content-center mb-4">
  <button
    type="button"
    class="wsm-btn wsm-btn-link"
    (click)="sendVerificationCode()"
  >
    <span>{{ "authentication.two_factor.resend_code" | translate }}</span>
  </button>
</div>

<div class="d-flex justify-content-center">
  <button
    class="wsm-btn wsm-btn-primary w-75 mb-2"
    type="submit"
    form="code-form"
  >
    @if (!isLoading) {
      <span>{{ "authentication.two_factor.verify" | translate }}</span>
    }

    @if (isLoading) {
      <div class="spinner-border spinner-border-sm text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    }
  </button>
</div>
