import {Component, Inject} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { AccountSelectorComponent } from '../../../components/connections/account-selector/account-selector.component';

@Component({
    selector: 'app-mobile-account-selector',
    templateUrl: './mobile-account-selector.component.html',
    styleUrls: ['./mobile-account-selector.component.scss'],
    standalone: true,
    imports: [AccountSelectorComponent],
})
export class MobileAccountSelectorComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<MobileAccountSelectorComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  onPanelClosed(action: string): void {
    this.bottomSheetRef.dismiss({action});
  }
}
