<section>
  <small class="d-flex wsm-text-grayed">
    {{ "common.fields_required" | translate }}
  </small>
  <form [formGroup]="toCustomReportForm" class="row mt-3">
    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
      <label for="report-name"
        >{{ "reporting.create_report.form.report_name" | translate
        }}<span class="required-mark fw-bolder">*</span></label
      >
      <input
        formControlName="name"
        type="text"
        class="wsm-input w-100"
        data-cy-namereport="namereport"
        id="report-name"
        placeholder="{{
          'reporting.create_report.form.report_name_placeholder' | translate
        }}"
      />
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
      <label for="orientation"
        >{{ "reporting.create_report.form.layout" | translate }}&nbsp;<span
          class="required-mark fw-bolder"
          >*</span
        ></label
      >
      <mat-radio-group formControlName="orientation" id="orientation">
        <mat-radio-button
          value="horizontal"
          class="me-3"
          data-cy-radiobh="horizontalradio"
        >
          <i class="fak fa-landscape-report"></i>&nbsp;
          {{ "reporting.create_report.form.layout_landscape" | translate }}
        </mat-radio-button>
        <mat-radio-button value="vertical" data-cy-radiobv="verticalradio">
          <i class="fak fa-portrait-report portrait-icon"></i>&nbsp;
          {{ "reporting.create_report.form.layout_portrait" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
      <label for="fontSize"
        >{{ "reporting.create_report.form.font_size" | translate }}&nbsp;<span
          class="required-mark fw-bolder"
          >*</span
        ></label
      >
      <mat-select
        disableOptionCentering
        formControlName="fontSize"
        class="wsm-input"
        id="fontSize"
        [panelClass]="'wsm-custom-select'"
        data-cy-fontsize="fontsize"
      >
        @for (fontSize of fontSizes(); track fontSize) {
          <mat-option [value]="fontSize">
            {{ fontSize?.label }}
          </mat-option>
        }
      </mat-select>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
      <div class="d-flex align-items-baseline justify-content-between">
        <label class="me-2"
          >{{
            "reporting.create_report.form.select_theme" | translate
          }}&nbsp;<span class="required-mark fw-bolder">*</span></label
        >
      </div>
      @if (themeSelected?.value) {
        <a
          (click)="onShowThemeDialog()"
          style="border-radius: 10px"
          class="colors-container current-theme-card wsm-bg-secondary d-flex flex-column rounded-md mb-2 p-3 text-decoration-none gap-2"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span class="wsm-text-white">{{ themeSelected.value.name }}</span>
            <i class="fa-regular fa-fill-drip wsm-text-white"></i>
          </span>
          <span class="d-flex gap-2">
            @for (
              color of themeSelected?.value?.colors;
              track color;
              let i = $index
            ) {
              <span
                class="nr-circle-input rounded-circle cursor-default"
                [style]="'background-color: ' + color"
              ></span>
            }
          </span>
        </a>
      }

      @if (themes() && themes().length > 0) {
        <div class="wsm-input-group-prepend w-100">
          @if (pickANewTheme()) {
            <label for="">{{
              "reporting.create_report.form.pick_a_new_theme" | translate
            }}</label>
          }
          <input
            type="text"
            [formControl]="themeControl"
            class="wsm-input h-100 w-100"
            [matAutocomplete]="themeAutoComplete"
            data-cy-themeselect="selecttheme"
            placeholder="{{
              'reporting.create_report.form.select_theme_placeholder'
                | translate
            }}"
          />
          <mat-autocomplete
            class="mat-menu-gray"
            autoActiveFirstOption
            #themeAutoComplete="matAutocomplete"
            (optionSelected)="onChangeThemeFromList($event)"
          >
            @for (item of filteredThemes | async; track item) {
              <mat-option class="pt-1 pb-1" style="height: 4rem" [value]="item">
                {{ item.name }}
                <div class="colors-container d-flex gap-2 mt-1">
                  @for (color of item.colors; track i; let i = $index) {
                    <div
                      class="nr-circle-input rounded-circle"
                      [style]="'background-color: ' + color"
                    ></div>
                  }
                </div>
              </mat-option>
            }
          </mat-autocomplete>
        </div>
      }
    </div>
  </form>
</section>
