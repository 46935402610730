import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ReportTheme, Widget} from '../../../models';
import { NgClass, NgStyle } from '@angular/common';
import { WidgetPopoverComponent } from '../widget-popover/widget-popover.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';

@Component({
    selector: 'app-ad-preview',
    templateUrl: './ad-preview.component.html',
    styleUrls: ['./ad-preview.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        WidgetPopoverComponent,
        TranslateModule,
        SafeHtmlPipe,
    ],
})
export class AdPreviewComponent implements OnChanges {
  // Inputs / Outputs
  @Input() widget: Widget;
  @Input() theme: ReportTheme = {};
  @Input() isExternalUser: boolean;
  @Input() helpUrl: string;
  @Input() ad: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget) {
      this.widget = changes.widget.currentValue;
    }
  }
}
