<section>
  <mat-accordion>
    <mat-expansion-panel
      hideToggle
      class="custom-help-panel"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      (expandedChange)="onPanelStateChange($event)"
    >
      <mat-expansion-panel-header class="panel-help-header">
        <mat-panel-title>
          <i class="fa-solid fa-circle-info me-1"></i>
          @if (panelOpenState) {
            {{ "shared.dialogs.connection_login.all_right_label" | translate }}
          } @else {
            {{ "shared.dialogs.connection_login.need_help_label" | translate }}
          }
          <button class="wsm-btn wsm-btn-link ms-2">
            @if (panelOpenState) {
              {{
                "shared.dialogs.connection_login.close_video_label" | translate
              }}
            } @else {
              {{
                "shared.dialogs.connection_login.watch_demo_label" | translate
              }}
            }
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        @if (panelOpenState) {
          <div class="text-center">
            @for (video of videos; track video.provider) {
              @if (provider() === video.provider) {
                <iframe
                  class="video-skeleton"
                  width="305"
                  height="197"
                  [src]="video.url | safeResource"
                  title="Connection Setting"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                >
                </iframe>
              }
            }
          </div>
          <hr class="my-1" />
        }
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</section>
