import {CdkDragDrop, CdkDropList, CdkDrag} from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  Component,
  computed,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  Output,
  QueryList,
  Signal,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {delay, map, Observable, startWith, Subscription} from 'rxjs';
import {ReportPage} from 'src/app/shared/models';
import {TabItemComponent} from '../tab-item/tab-item.component';
import {MatDialog} from '@angular/material/dialog';
import {
  NgClass,
  NgStyle,
  NgTemplateOutlet,
  AsyncPipe,
  JsonPipe,
} from '@angular/common';
import {MatMenuTrigger, MatMenu, MatMenuItem} from '@angular/material/menu';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {MatRipple} from '@angular/material/core';
import {TranslateModule} from '@ngx-translate/core';
import {
  ConnectedPosition,
  OverlayConfig,
  OverlayModule,
} from '@angular/cdk/overlay';
import {MatListModule} from '@angular/material/list';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TruncatePipe} from 'src/app/shared/pipes';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    CdkScrollable,
    CdkDropList,
    MatRipple,
    CdkDrag,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
    OverlayModule,
    JsonPipe,
    MatListModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TruncatePipe,
  ],
})
export class TabsComponent
  implements OnInit, AfterContentInit, OnChanges, OnDestroy
{
  // Children
  @ContentChildren(TabItemComponent) tabs: QueryList<TabItemComponent>;
  @ViewChild('tabList') tabListElement: ElementRef;

  // Inputs / Outputs
  @Input() isPresentationMode;
  @Input() refreshPageIndex;
  @Input() isEditMode: boolean;
  @Input() hideBody: boolean;
  @Input() selectedIndex: number;
  @Input() from: 'template' | 'report' | 'sharing' | 'other';
  @Input() disableDrag: boolean;
  @Input() disableTabs: boolean;
  @Input() isMobile: boolean;
  @Input() sticky: boolean;
  @Input() reportInfo: {
    reportPages: ReportPage[];
    selectedPage: ReportPage;
    originalPageState: ReportPage;
  };
  showMenuPageList = input<boolean>(false);
  pageItems = input<ReportPage[]>([]);
  isExternalUser = input<boolean>(false);
  @Output() tabSelected = new EventEmitter();
  @Output() tabsReordered = new EventEmitter();
  @Output() tabAdded = new EventEmitter();
  changeVisibility = output<ReportPage>();

  private dialog: MatDialog = inject(MatDialog);

  // State Variables
  showScroll = false;

  // Properties
  tabArray: TabItemComponent[];
  subs: Subscription = new Subscription();
  scrollPixels = 50;
  scrollLimitAt: string;
  selectedTab: TabItemComponent;
  isScrolling: any;
  tabItems$: Observable<TabItemComponent[]>;
  previousSelectedIndex: number;
  showPageList: boolean;
  overlayPosition = signal<ConnectedPosition[]>([
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ]);

  overlayConfig = signal<OverlayConfig>({
    minWidth: '300px',
    minHeight: '400px',
    width: '300px',
    height: '400px',
  });

  pageSearchControl = new FormControl('');
  @ViewChild('pageSearchInput') inputElement!: ElementRef;
  pageSearchValue = signal<string | null>('');
  pageItemsFiltered = computed(() => {
    const keyword = this.pageSearchValue();
    return keyword && keyword.trim().length > 0
      ? this.pageItems().filter((el) =>
          el.name.toLowerCase().includes(keyword.toLowerCase())
        )
      : this.pageItems();
  });

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.dialog.openDialogs.length !== 0) {
      return;
    }
    if (this.from === 'report' || this.from === 'sharing') {
      if (event.code === 'ArrowRight') {
        this.onScroll();
      } else if (event.code === 'ArrowLeft') {
        this.onScroll();
      }
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.pageSearchControl.valueChanges.subscribe((val) => {
      this.pageSearchValue.set(val);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedIndex) {
      this.previousSelectedIndex = changes.selectedIndex.firstChange
        ? changes.selectedIndex.currentValue
        : changes.selectedIndex.previousValue;

      if (this.tabArray?.length > 0) {
        this.selectTab(
          this.tabArray[changes?.selectedIndex?.currentValue],
          true
        );
      }
    }
  }

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(''))
      .pipe(delay(0))
      .pipe(
        map(() => {
          return this.tabs.toArray();
        })
      );
    this.tabItems$.subscribe((val: TabItemComponent[]): void => {
      this.tabArray = val;
      setTimeout(this.showScrollControls.bind(this));
      this.selectTab(this.tabArray[this.selectedIndex]);
    });
  }

  showScrollControls(): void {
    const element: HTMLElement = this.tabListElement.nativeElement;
    if (element.scrollWidth === element.clientWidth) {
      return;
    }
    this.showScroll = true;
  }

  drop(event: CdkDragDrop<string[]>): void {
    /*
     * Instead of moving the item inside an array, the tabItem observable is again reassigned the value
     * of the tab elements that detect a change when they are dragged and dropped, setting the new
     * tab order.
     */
    this.tabItems$ = this.tabs.changes.pipe(startWith('')).pipe(
      map(() => {
        return this.tabs.toArray();
      })
    );

    this.selectedTab.index = event.currentIndex;
    this.tabsReordered.emit(event);
  }

  selectTab(tab: TabItemComponent, isChanged?: boolean): void {
    if (!tab || this.selectedTab === tab) {
      return;
    }

    if (this.selectedTab) {
      this.selectedTab.isActive = false;
    }

    tab.isActive = true;
    this.selectedTab = tab;

    let tabElement: HTMLElement;
    setTimeout((): void => {
      tabElement = document.getElementById('tab' + tab.index)!;
      switch (this.selectedTab.index) {
        case 0:
          this.scrollPixels = 0;
          this.scrollLimitAt = 'left';
          this.tabListElement.nativeElement.scroll({
            left: this.scrollPixels,
            behavior: 'smooth',
          });
          break;
        case this.tabArray.length - 1:
          this.scrollPixels =
            this.tabListElement.nativeElement.scrollWidth -
            this.tabListElement.nativeElement.clientWidth;
          this.scrollLimitAt = 'right';
          this.tabListElement.nativeElement.scroll({
            left: this.scrollPixels,
            behavior: 'smooth',
          });
          break;
        default:
          tabElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest',
          });
      }
      if (!isChanged) {
        this.tabSelected.emit(tab.index);
      }
    });
  }

  addTab(type: string): void {
    this.tabAdded.emit(type);
  }

  scrollList(direction?: string, scrollPixels = 300): void {
    const element = this.tabListElement.nativeElement;
    const maxScrollLeft = element.scrollWidth - element.clientWidth;

    switch (direction) {
      case 'left':
        this.scrollPixels -= scrollPixels;
        if (this.scrollPixels < 0) {
          this.scrollPixels = 0;
          this.scrollLimitAt = 'left';
        } else {
          this.scrollLimitAt = '';
        }
        break;
      case 'right':
        this.scrollPixels += scrollPixels;
        if (this.scrollPixels > maxScrollLeft) {
          this.scrollPixels = maxScrollLeft;
          this.scrollLimitAt = 'right';
        } else {
          this.scrollLimitAt = '';
        }
        break;
      default:
        this.scrollPixels = scrollPixels - 2;
        if (this.scrollPixels >= maxScrollLeft) {
          this.scrollPixels = maxScrollLeft;
          this.scrollLimitAt = 'right';
        } else if (this.scrollPixels <= 0) {
          this.scrollPixels = 0;
          this.scrollLimitAt = 'left';
        } else {
          this.scrollLimitAt = '';
        }
    }

    element.scroll({
      left: this.scrollPixels,
      behavior: 'smooth',
    });
  }

  onScroll(): void {
    if (this.isMobile) {
      return;
    }
    const element = this.tabListElement.nativeElement;
    this.isScrolling;

    // Clear our timeout throughout the scroll
    window.clearTimeout(this.isScrolling);

    // Set a timeout to run after scrolling ends
    this.isScrolling = setTimeout(() => {
      // Run the callback
      const scroll = element.scrollLeft;
      const maxScrollLeft = element.scrollWidth - element.clientWidth;
      const leftButton = document.getElementById('leftButton') as any;
      switch (scroll) {
        case 0:
          this.scrollLimitAt = 'left';
          if (leftButton) {
            leftButton.disabled = true;
          }
          document
            .getElementById('leftButton')
            ?.classList.add('pagination-disabled');
          (document.getElementById('rightButton') as any).disabled = false;
          document
            .getElementById('rightButton')
            ?.classList.remove('pagination-disabled');
          break;
        case maxScrollLeft:
          this.scrollLimitAt = 'right';
          (document.getElementById('leftButton') as any).disabled = false;
          document
            .getElementById('leftButton')
            ?.classList.remove('pagination-disabled');
          (document.getElementById('rightButton') as any).disabled = true;
          document
            .getElementById('rightButton')
            ?.classList.add('pagination-disabled');
          break;
        default:
          this.scrollLimitAt = '';
          (document.getElementById('leftButton') as any).disabled = false;
          document
            .getElementById('leftButton')
            ?.classList.remove('pagination-disabled');
          (document.getElementById('rightButton') as any).disabled = false;
          document
            .getElementById('rightButton')
            ?.classList.remove('pagination-disabled');
          break;
      }
    }, 66);
  }

  onShowPageList(): void {
    this.showPageList = !this.showPageList;
    setTimeout(() => {
      this.setFocus();
    }, 0);
    setTimeout(() => {
      const activePageMenuItem = document.getElementById(
        `page-item-list-${this.selectedTab.index}`
      );
      if (activePageMenuItem) {
        activePageMenuItem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }, 0);
  }

  selectTabFromPageList(item: ReportPage): void {
    const index = this.tabArray.findIndex(
      (tab: TabItemComponent) => tab.id === item.id
    );
    this.selectTab(this.tabArray[index]);
    this.pageSearchControl.reset();
    this.showPageList = false;
  }

  onChangeVisibility(item: ReportPage): void {
    this.changeVisibility.emit(item);
  }

  setFocus(): void {
    this.inputElement && this.inputElement.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
