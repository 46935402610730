import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { MatSelectChange, MatSelect } from '@angular/material/select';

import {
  ClientData,
  Industry,
  Report,
  ReportClientQuery,
  User,
} from 'src/app/shared/models';
import {NotificationService} from '../../../../../core/services';
import {ClientService, ApiService} from 'src/app/core/services';
import {IndustryGroup} from 'src/app/modules/companies/components/company-info/company-info.component';
import { NgxIntlTelInputModule } from '@whiteshark-media/ngx-intl-tel-input-app';
import { MatOption, MatOptgroup } from '@angular/material/core';
import { NgClass, AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HighlightTextPipe } from '../../../../pipes/highlight.pipe';
import { AsPipe } from '../../../../pipes/as-type.pipe';

@Component({
    selector: 'app-criteria',
    templateUrl: './criteria.component.html',
    styleUrls: ['./criteria.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatSelect,
        NgxIntlTelInputModule,
        MatOption,
        NgClass,
        MatAutocompleteTrigger,
        MatAutocomplete,
        MatOptgroup,
        AsyncPipe,
        TranslateModule,
        HighlightTextPipe,
        AsPipe,
    ],
})
export class CriteriaComponent implements OnInit, OnDestroy, OnChanges {
  @Output() sendCondition = new EventEmitter<any[]>();
  @Input() report?: Report;
  @Input() conditionsFromWizard?: Array<any> = [];

  //Subs
  public subs: Subscription = new Subscription();
  private destroy$ = new Subject();

  //Properties
  public criteriaForm: FormGroup;
  public conditions: FormArray;
  public selectedFields: any[] = [];
  public fields: {label: string; name: string; dataType: string}[];
  public logicOperators: {
    operatorLabel: string;
    operatorName: string;
    dataType: string;
  }[];

  //Autocomplete Variables
  public filteredOptions: Observable<
    User[] | ClientData[] | Industry[] | IndustryGroup[]
  >[] = [];
  UserModel!: User;
  ClientDataModel!: ClientData;
  IndustryModel!: Industry;
  IndustryGroupModel!: IndustryGroup;
  public parentCompaniesLoading: boolean;
  public ownersLoading: boolean;
  public industriesLoading: boolean;
  public parentCompaniesNoResults: boolean;
  public ownersNoResults: boolean;
  public industriesNoResults: boolean;
  public toHighlight: string;
  selectedIndustry: Industry;
  industries: Industry[];

  constructor(
    public clientService: ClientService,
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.fields = this.clientService.getCriteriaFields();
    this.logicOperators = this.clientService.getCriteriaLogicOperators();
    if (this.conditionsFromWizard && this.conditionsFromWizard?.length !== 0) {
      if (this.conditionsFromWizard[0].operator) {
        this.conditionsFromWizard?.forEach((el) => {
          this.addCondition(el);
          this.selectedFields.push(el.field);
        });
      }
    }
    if (
      !this.report &&
      this.conditions &&
      this.conditions.controls.length === 0
    ) {
      this.addCondition();
    }
    this.criteriaForm.valueChanges
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        catchError(() => {
          this.removeLoading();
          return of([]);
        })
      )
      .subscribe({
        next: () => {
          this.buildConditions();
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      setTimeout((): void => {
        if (!this.conditionsFromWizard && this.report && this.report?._id) {
          if (this.report.companiesMatching[0].operator) {
            this.report.companiesMatching.forEach((el: ReportClientQuery) => {
              this.addCondition(el);
              this.selectedFields.push(el.field);
            });
          } else {
            this.addCondition();
          }
        }
      }, 0);
    }
  }

  buildForm(): void {
    this.criteriaForm = new FormGroup({
      conditions: new FormArray([]),
    });
    this.conditions = this.criteriaForm.get('conditions') as FormArray;
  }

  createCondition(element?: any): FormGroup {
    let value = element?.value;
    const operator = element?.operator;

    if (element?.field === 'industry' && operator !== 'like') {
      if (typeof element?.value === 'object') {
        this.selectedIndustry = element.value;
        this.selectedIndustry._id = this.selectedIndustry?.id;
        value = `${element.value.category} | ${element.value.subcategory}`;
      }
    }

    return new FormGroup({
      fieldOne: new FormControl(element?.field, Validators.required),
      operator: new FormControl(operator, Validators.required),
      fieldTwo: new FormControl(value, Validators.required),
    });
  }

  disableOptionFromList(event: MatSelectChange, index: number): void {
    const selectedFields: any[] = [];
    this.conditions.controls.forEach((condition: AbstractControl): void => {
      condition.get('fieldOne') &&
        selectedFields.push(condition.get('fieldOne')?.value);
    });

    const toResetCondition: AbstractControl = this.conditions.controls[index];
    toResetCondition.get('operator')?.reset();
    toResetCondition.get('fieldTwo')?.reset();

    this.selectedFields = selectedFields;
  }

  onChangeOperator(index: number): void {
    const toResetCondition: AbstractControl = this.conditions.controls[index];
    toResetCondition.get('fieldTwo')?.reset();
  }

  getLogicOperators(
    condition
  ): Array<{operatorLabel: string; operatorName: string; dataType: string}> {
    const field = this.fields.find(
      (field) => field.name === condition.get('fieldOne').value
    );
    return this.logicOperators.filter(
      (operator) =>
        operator.dataType === field?.dataType ||
        operator.operatorName === 'like'
    );
  }

  //Dynamics condition forms methods
  addCondition(element?: any): void {
    const cdt = this.createCondition(element);
    this.conditions.push(cdt);

    //initialize autocomplete for Owner, Industry and Parent Company
    this.filteredOptions[this.conditions.length - 1] = cdt
      .get('fieldTwo')
      ?.valueChanges.pipe(
        filter((val) => Boolean(val)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          switch (cdt.get('fieldOne')?.value) {
            case 'parentName':
              this.parentCompaniesLoading = true;
              break;
            case 'owner':
              this.ownersLoading = true;
              break;
            case 'industry':
              this.industriesLoading = true;
              break;
            default:
              this.removeLoading();
              break;
          }
        }),
        switchMap(
          (
            val
          ): Observable<
            User[] | ClientData[] | Industry[] | IndustryGroup[]
          > => {
            if (val.trim() === '') {
              this.removeLoading();
              return of([]);
            }
            return this.filter(val, cdt.get('fieldOne')?.value) || of([]);
          }
        ),
        catchError((error) => {
          this.notificationService.error(error, 5000);
          this.removeLoading();
          return of([]);
        })
      ) as Observable<User[] | ClientData[] | Industry[] | IndustryGroup[]>;
  }

  filter(
    val: string,
    type
  ):
    | Observable<User[] | ClientData[] | Industry[] | IndustryGroup[]>
    | undefined {
    this.toHighlight = val;
    const industryGroup: IndustryGroup[] = [];
    // Call the service which makes the http-request
    switch (type) {
      case 'parentName':
        return this.clientService.getClientsTypeAhead(val).pipe(
          map((response) => {
            this.parentCompaniesLoading = false;
            this.parentCompaniesNoResults = response.clients.length === 0;
            return response.clients.filter((option) =>
              option.name.toLowerCase().includes(val.toLocaleLowerCase())
            );
          })
        );
      case 'owner':
        return this.apiService.getUsersTypeAhead(val, true).pipe(
          map((response) => {
            this.ownersLoading = false;
            this.ownersNoResults = response.length === 0;
            return response.filter((option) =>
              option.name?.toLowerCase().includes(val.toLocaleLowerCase())
            );
          })
        );
      case 'industry':
        return this.clientService.getIndustriesTypeAhead(val).pipe(
          map((industryArray) => {
            this.industriesLoading = false;
            this.industries = industryArray;
            this.industriesNoResults = industryArray.length === 0;
            industryArray.forEach((option) => {
              let newObject: IndustryGroup;
              if (
                !industryGroup.some(
                  (item: any) => item.category === option.category
                )
              ) {
                newObject = {
                  category: option.category as string,
                  subcategories: [option.subcategory] as Array<string>,
                };
                industryGroup.push(newObject);
              } else {
                const categoryIndex = industryGroup.indexOf(
                  industryGroup.find(
                    (item) => item.category === option.category
                  ) as IndustryGroup
                );
                industryGroup[categoryIndex].subcategories.push(
                  option.subcategory as string
                );
              }
            });

            return industryGroup
              .map((group) => ({
                category: group.category,
                subcategories: group.subcategories,
              }))
              .filter((group) => group.subcategories.length > 0);
          })
        );
    }
  }

  removeCondition(index): void {
    const selectedValueIndex = this.selectedFields.indexOf(
      this.conditions.controls[index].get('fieldOne')?.value
    );
    if (selectedValueIndex) {
      this.selectedFields.splice(selectedValueIndex, 1);
    }
    this.conditions.removeAt(index);
    this.buildConditions();
    this.filteredOptions.splice(index, 1);
  }

  buildConditions(): void {
    const conditions: Array<object> = [];
    this.conditions.controls.forEach((control) => {
      const cond = {
        field: control.get('fieldOne')?.value,
        operator: control.get('operator')?.value,
        value: control.get('fieldTwo')?.value,
      };

      if (cond.field === 'industry' && this.selectedIndustry) {
        cond.value = this.selectedIndustry?._id;
      }

      if (typeof cond.value === 'object') {
        cond.operator = 'in';
      }

      conditions.push(cond);
    });
    if (this.conditions.valid) {
      this.sendCondition.emit(conditions);
    }
  }

  onIndustrySelect(
    event: MatAutocompleteSelectedEvent,
    conditionIndex: number
  ): void {
    this.selectedIndustry = this.industries.find((industry) => {
      return (
        industry.category === event.option.value.category &&
        industry.subcategory === event.option.value.subcategory
      );
    }) as Industry;
    this.conditions.controls[conditionIndex]
      .get('fieldTwo')
      ?.setValue(
        `${this.selectedIndustry.category} | ${this.selectedIndustry.subcategory}`
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  removeLoading(): void {
    this.industriesNoResults = false;
    this.ownersNoResults = false;
    this.parentCompaniesNoResults = false;
    this.industriesLoading = false;
    this.ownersLoading = false;
    this.parentCompaniesLoading = false;
  }
}
